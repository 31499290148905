import React from 'react';
import './index.scss'
import Introduction from '@/components/home/Introduction';

function ContactUs() {
    return (
        <>
            <div className="Contact-Us">
                <div className="contactUs-top-title">
                    <h1>南京魔笔信息科技有限公司</h1>
                </div>
            </div>
            <Introduction sougousw={ true }/>
            <div className="contactUs-text-info">
                <p>公司介绍：</p>
                <p>MOBI平台是南京魔笔信息科技有限公司旗下的游戏交流平台，现主打CSGO和DOTA2游戏社群平台交流。</p>
                <p>网站依托steam提供的API，为用户提供便捷与舒适的交流体验。我们更有贴心客服全方位为用户服务。</p>
                <p>网站发放海量游戏福利，更有独家福利等待您的加入！</p>
                <br/>
                <br/>
                <h3>QQ群: 860316044</h3>
                <h3>办公地址：南京市江北新区研创园团结路99号孵鹰大厦D座310室</h3>
                <h3>电话：13921305810</h3>
            </div>
        </>
    );
}

export default ContactUs;
