import React, { useState, useEffect, $tools } from 'react';
import './index.scss'

const navData = [
    {
        title: '自售购买',
        icon: require('@/images/inventory/guashou.png'),
        select_icon: require('@/images/inventory/guashous.png'),
        type: 'onSale'
    },
    // {
    //     title: '中介购买', icon:
    //         require('@/images/inventory/zhongjie.png'), select_icon:
    //         require('@/images/inventory/zhongjies.png'),
    //     type: 'intermediary'
    // },
    {
        title: '购买记录', icon:
            require('@/images/inventory/paimai.png'), select_icon:
            require('@/images/inventory/paimais.png'),
        type: 'record'
    },
    {
        title: '求购管理', icon:
            require('@/images/inventory/faqiqiugou@2x.png'), select_icon:
            require('@/images/inventory/faqiqiugouselect@2x.png'),
        type: 'buying'
    }
]


function SaleNav() {
    const { getUrlQueryValue } = $tools
    let game = getUrlQueryValue('game') || '730'
    let nav = getUrlQueryValue('nav') || 'onSale'
    const [ currentNav, setNav ] = useState(nav);
    const [ currentGame, setGame ] = useState(game);
    const [ init, setInit ] = useState(false)
    const [ gameCategory, setGameCategory ] = useState([
        {
            title: 'CSGO饰品',
            icon: require('@/images/home/csgoweixuanzhong@2x.png'),
            select_icon: require('@/images/profile/csgo_c.png'),
            type: '730'
        },
        {
            title: 'DOTA2饰品',
            icon: require('@/images/home/dota2.png'),
            select_icon: require('@/images/profile/dota2_c.png'),
            type: '570'
        },
    ])
    
    useEffect(() => {
        if (currentNav === 'buying') {
            setGameCategory([ {
                title: 'CSGO饰品',
                icon: require('@/images/home/csgoweixuanzhong@2x.png'),
                select_icon: require('@/images/profile/csgo_c.png'),
                type: '730'
            } ])
        } else {
            setGameCategory([ {
                title: 'CSGO饰品',
                icon: require('@/images/home/csgoweixuanzhong@2x.png'),
                select_icon: require('@/images/profile/csgo_c.png'),
                type: '730'
            },
                {
                    title: 'DOTA2饰品',
                    icon: require('@/images/home/dota2.png'),
                    select_icon: require('@/images/profile/dota2_c.png'),
                    type: '570'
                }, ])
        }
        if (! init) {
            setInit(true)
        } else {
            window.location.href = `/myPur?game=${ currentGame }&nav=${ currentNav }`
            if (currentNav === 'buying') window.location.href = `/myPur?game=730&nav=${ currentNav }`
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame, currentNav ])
    
    return (
        <div className="my-sale-nav">
            <div className="sale-nav-container">
                { navData.map((v, i) => {
                    let _cur = v.type === currentNav,
                        _cn = _cur ? 'nav-item current' : 'nav-item';
                    return (
                        <div key={ i }
                             onClick={ () => setNav(v.type) }
                             className={ _cn }>
                            <img className="sale-nav-icon" src={ _cur ? v.select_icon : v.icon } alt=""/>
                            { v.title }
                        </div>
                    )
                }) }
            </div>
            <div className="sale-game-container">
                { gameCategory.map((v, i) => {
                    let _cur = v.type === currentGame,
                        _cn = _cur ? 'nav-item current' : 'nav-item';
                    return (
                        <div key={ i }
                             style={ { marginLeft: i === 0 && '15px' } }
                             onClick={ () => setGame(v.type) }
                             className={ _cn }>
                            <div className="sale-game-box">
                                <img style={ { position: i === 0 && 'relative', left: i === 0 && '2.5px' } }
                                     className="sale-game-icon" src={ _cur ? v.select_icon : v.icon } alt=""/>
                            </div>
                            <p className="game-name">{ v.title }</p>
                        </div>
                    )
                }) }
            </div>
        </div>
    );
}

export default SaleNav;
