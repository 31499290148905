import React, { useState } from 'react';
import './index.scss'
import RollRoomContentNav from './comp/nav.js'
import RollContentContainer from './comp/contentContainer.js'

function RollRoomContent() {
    const _nav = localStorage.getItem('rollRoomNav')
    const [ nav, setNav ] = useState(_nav || 'roll')
    
    return (
        <div className="roll-room-content">
            <RollRoomContentNav nav={ { nav, setNav } }></RollRoomContentNav>
            <RollContentContainer nav={ nav }></RollContentContainer>
        </div>
    )
}

export default RollRoomContent;
