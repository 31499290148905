import React from 'react';
import './scss/promote.scss';
import { connect } from 'react-redux';
import { upDateUserAssets } from '@/store/actions/userInfo'

const promptData = [
    { text: '推广说明' },
    { text: '1.推广收益是指，当用户在注册的时候填写了你的推广码，他在交易时候产生的手续费会按照推广等级的比例分发到推广人的推广钱包里。举例，被推广人交易产生了30元手续费，推广等级一级时，你即可获得1.5元手续费收益。' },
    { text: '2.当产生一笔交易时，卖家和买家的推广人双方都可以获得当前推广收益的奖励。' },
    { text: '3.推广返利是不可提现余额，用于购买游戏视频等。' }
];

const tabData = [
    { title: '推广等级', rules: [ '一级', '二级', '三级', '四级', '五级' ] },
    { title: '推广收益(手续费率)', rules: [ '5%', '6%', '6.5%', '7%', '7.5%' ] },
    { title: '推广收益', rules: [ '0-10000', '10000-20000', '20000-30000', '30000-40000', '40000-50000' ] }
]


function Promote() {
    return (
        <div className="promote-manag">
            <div className="promote-container">
                <div className="head-title">
                    <img src={ require('@/images/profile/mainPromote.png') } alt=""/>
                    <div style={ { marginLeft: "40px", textAlign: 'center' } }>
                        <h3>已获得MOBI的推广收益(非代币分红)</h3>
                        <h2> ￥0.00</h2>
                    </div>
                </div>
                
                <div className="promote-table">
                    { tabData.map((v, i) => {
                        let _cls = i & 1 ? 'tab-list plural' : 'tab-list';
                        return (
                            <div className={ _cls } key={ i }>
                                <div className="tab-item title">{ v.title }</div>
                                { v.rules.map((item, index) => {
                                    return (
                                        <div key={ index } className='tab-item'>
                                            { item }
                                        </div>
                                    )
                                }) }
                            </div>
                        )
                    }) }
                </div>
                
                <div
                    className="promote-prompt">
                    { promptData.map((v, i) => <p key={ i } className="prompt-v">{ v.text }</p>) }
                </div>
            </div>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(Promote);

