import React, { $tools } from 'react';
import './index.scss'
import { connect } from "react-redux";

const promptData = [
    { text: "温馨提示:" },
    { text: '当您充值的时候，支付宝，微信，银联等充值渠道会抽取一定比例作为手续费，所以这个手续费表格已经不能更低了。 ' },
    { text: '交易费率中的交易金额范围为开闭区间，比如对于信誉度优良的用户出售饰品价格小于等于10元，交易手续费为0.75%，大于10元且小于等于50元，交易手续费为1%，大于50元且小于等于150元，交易手续费为1.25%，以此类推。' },
    { text: '感谢信任MOBI交易平台' }
]


const TableTitle = { width: 140 }
const tableSpanLine = {
    position: "absolute", height: 1, right: 1, bottom: -25,
    backgroundColor: "#E5E5E5", transformOrigin: "right bottom"
}
const tableTitleText = { position: "absolute", fontSize: 12, color: "#222" }
const tabData = [
    {
        title: <div style={ { ...TableTitle, position: 'relative' } }>
            <p style={ { ...tableTitleText, top: -17, right: 6 } }>交易金额</p>
            <span style={ { ...tableSpanLine, width: 108, transform: "rotate(39deg)" } }></span>
            <p style={ { ...tableTitleText, top: -2, left: 31 } }>手续费率</p>
            <span style={ { ...tableSpanLine, width: 145, transform: "rotate(15deg)" } }></span>
            <p style={ { ...tableTitleText, bottom: -23, left: 10 } }>信誉等级</p>
        </div>,
        rules: [ '0-10', '10-50', '50-150', '150-2000', '2000-5000', '5000以上', '代币奖励' ]
    },
    { title: <p style={ TableTitle }>极好</p>, rules: [ '0.75%', '1%', '1.25%', '1.5%', '1.5%', '1.5%', '享受' ] },
    { title: <p style={ TableTitle }>优良</p>, rules: [ '0.75%', '1%', '1.25%', '1.5%', '1.75%', '2%', '享受' ] },
    { title: <p style={ TableTitle }>中</p>, rules: [ '0.75%', '1%', '1.25%', '1.5%', '1.75%', '2%', '不享受' ] },
    { title: <p style={ TableTitle }>差</p>, rules: [ '2%', '2%', '2%', '2%', '2%', '2%', '不享受' ] }
]


function TransactionFeeRate({ userInfo }) {
    const creditLevelData = $tools.getCredibilityInfoData(userInfo?.reputationPoints)
    return (
        <div className="transaction-fee">
            <div className="title">
                <h1>交易费率</h1>
            </div>
            
            <div className="transaction-fee-container">
                <div className="transaction-fee-title flex">
                    <div className="credit-score">
                        <div className="credit-val">
                            <h4 style={ { fontSize: 14, color: "#fff" } }>当前用户信誉值</h4>
                            <img src={ $tools.getCredibilityIcon(userInfo?.reputationPoints) } alt=""/>
                        </div>
                        <div className="credit-bar-container">
                            <h1 style={ { fontSize: 32, color: "#fff" } }>{ userInfo?.reputationPoints } </h1>
                            <div className="credit-bar-box">
                                <div className="credit-bar">
                                    <div
                                        style={ {
                                            width: `${ (
                                                userInfo?.reputationPoints - creditLevelData?.all)
                                            / creditLevelData?.quality * 100 }%`
                                        } }
                                        className="credit-bar-content"></div>
                                </div>
                                <div className="credit-bar-text">
                                    <p>{ creditLevelData?.text[0] }</p><p
                                    style={ { position: 'relative', left: 25 } }>{ creditLevelData?.text[1] }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="discount-amount">
                        <h4 style={ { fontSize: 14, color: "#663800" } }>已优惠手续费总额</h4>
                        <div className="flex" style={ { marginTop: 8, alignItems: "end" } }>
                            <span style={ {
                                position: 'relative',
                                top: -12,
                                fontSize: "14px",
                                color: "#663800"
                            } }>¥</span>
                            <h1 style={
                                { marginLeft: "4px", fontSize: 32, fontWeight: "bold", color: "#663800" }
                            }>189</h1>
                        </div>
                    </div>
                </div>
                
                <p style={ {
                    marginBottom: 10,
                    color: "orange",
                    fontWeight: "bold"
                } }>早鸟期，元旦，春季期间所有用户任意交易金额的交易费率统一低至0.75%，提现手续费低至0%</p>
                
                <div className="transaction-fee-table">
                    { tabData.map((v, i) => {
                        const _cls = i !== 0 ? 'tab-list plural' : 'tab-list';
                        const changeRow = creditLevelData?.text[0] === v?.title?.props?.children
                        const changeRowStyle = changeRow ? {
                            background: "linear-gradient(-88deg, rgba(255,239,204,.5) 0%, rgba(255,255,255,.5) 100%)",
                            border: "2px solid #F99B2B", color: "#DB7109", transform: "scale(1.02)",
                        } : { border: "1px solid #e5e5e5" }
                        return (
                            <div className={ _cls }
                                 style={ {
                                     borderTop: i === 0 ? "1px solid #e5e5e5" : "none",
                                     borderBottom: "1px solid #e5e5e5", ...changeRowStyle
                                 } } key={ i }>
                                <div className="tab-item title">{ v.title }</div>
                                { v.rules.map((item, index) =>
                                    <div key={ index } className='tab-item'
                                         style={ { borderLeft: changeRow ? "1px solid rgba(229,229,229,.5)" : "1px solid #E5E5E5" } }>
                                        { item }
                                    </div>)
                                }
                            </div>
                        )
                    }) }
                </div>
                
                <div
                    className="transaction-fee-prompt">
                    { promptData.map((v, i) => <p key={ i }
                                                  className="prompt-v">{ v.text }</p>) }
                </div>
                
                <div className="transaction-fee-text-link">
                    <p>MOBI平台交易信誉系统：全新交易信用系统，让买卖饰品变得更加舒心和人性化，</p>
                    <a style={ { textIndent: 0 } } className="transaction-fee-link"
                       href="/profile/reputation">点此查看具体准则。</a>
                </div>
            </div>
        
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return { userInfo: state.user.userInfo }
    }
)(TransactionFeeRate);
