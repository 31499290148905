import React, { antdUI, useState } from 'react';
import './index.scss'
import CsgoItems from './items/csgo';
import Dota2Items from './items/dota2'
import CsgoItems2DModal from '@/components/csgoItems2D'

function ItemContainer(props) {
    let {
        setInventory, itemType, handleCacheInventoryData, selectLength, itemSelect,
        setItemSelect, itemData, inventoryBoxType, setMaterialData, materialData,
        setRarity
    } = props;
    const [ CsgoItems2DModalView, setView ] = useState(false)
    const [ CsgoItems2DModalData, setModalData ] = useState({})
    const setItemDetail = (newIData, assetid) => {
        let data = itemData.map((item) => {
            if (item.assetid === assetid) {
                item.market_item_detail = newIData
            }
            return item
        })
        setInventory(handleCacheInventoryData(data))
    }
    
    const changeItem = (cItem, type) => {
        let data = JSON.parse(JSON.stringify(itemSelect)) || [];
        if (type === 'cancel') {
            data.forEach((item, index, arr) => {
                if (item.assetid === cItem.assetid) {
                    arr.splice(index, 1)
                }
            })
        } else if (type === 'select') {
            if (data.length >= selectLength) {
                antdUI.message.warning(`至多选择${ selectLength }件物品上架`)
            } else {
                data.push(cItem)
            }
        }
        setItemSelect(data.splice(0, selectLength))
    }
    return (
        <div className="item-container">
            { itemData ? itemData[0] !== 'no' ? itemData === 'loading' || itemData.map((v, i) => {
                return Number(itemType) === 570 ?
                    <Dota2Items
                        changeItem={ changeItem }
                        selected={ itemSelect && itemSelect.filter(item => item.assetid === v.assetid) }
                        style={ { marginLeft: i % 5 ? '1.5%' : '' } } key={ i } item={ v } index={ i + 1 }/> :
                    Number(itemType) === 730 ?
                        <CsgoItems changeItem={ changeItem } setMaterialData={ setMaterialData }
                                   materialData={ materialData } setRarity={ setRarity }
                                   inventoryBoxType={ inventoryBoxType } setModalData={ setModalData }
                                   setItemDetail={ setItemDetail } setView={ setView }
                                   selected={ itemSelect && itemSelect.filter(item => item.assetid === v.assetid) }
                                   style={ { marginLeft: i % 5 ? '.5%' : '' } } key={ i } item={ v } index={ i + 1 }/>
                        : ''
            }) : <antdUI.Empty description="暂无数据" style={ { width: '100%', margin: '100px auto 0 auto' } }/> : '' }
            { CsgoItems2DModalView && <CsgoItems2DModal data={ CsgoItems2DModalData }
                                                        view={ CsgoItems2DModalView }
                                                        setView={ setView }></CsgoItems2DModal> }
        </div>
    );
}


export default ItemContainer;
