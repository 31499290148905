import React, { $tools } from 'react';
import './index.scss';

function Sticker(props) {
    const { stickers, type } = props;
    const { BASE_CSGO_STICKER } = $tools;
    return (
        <div className={ [ type === 'onShelf' ? 'acc-stickers onShelf-sticker' : 'acc-stickers' ] }>
            {
                stickers?.[0] && stickers.map((item, index) => {
                    return (
                        <div key={ index } className="acc-stickers-unit">
                            <div className='acc-sticker-img-c'>
                                <img
                                    className="acc-sticker-img"
                                    src={ BASE_CSGO_STICKER + item.material + ".png" }
                                    alt=""
                                />
                            </div>
                            
                            { type === 'onShelf' ? '' : <span className="acc-abrasion">
                { item.wear ? ~~((1 - item.wear) * 100) : 100 }%
              </span> }
                        </div>
                    );
                })
                
            }
        </div>
    )
}


export default Sticker;
