import React, { antdUI, useState, useEffect, $tools, useRef } from 'react';
import './search.scss'

function SearchFilter(props) {
  let rememberFilterData = JSON.parse(localStorage.getItem('rememberFilter')) || {};
  const { searchContent } = rememberFilterData;
  const [searchVal, setSearchVal] = useState(searchContent || '');
  const { searchChange, type, currentGame } = props;
  const { debounce,
    // inputValueLimit 
  } = $tools;
  let searchStyle = {
    width: '158px',
    borderRadius: '4px 0px 0px 4px',
    height: '30px'
  },
    filterStyle = {},
    holderText = '请输入物品名称';
  switch (type) {
    case 'buy':
      searchStyle.width = '400px';
      holderText = '请输入订单号';
      filterStyle.position = 'static'
      break;
    default: break;
  }
  useEffect(() => {
    props.setSearchVal && props.setSearchVal(searchVal, currentGame)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])


  const delaySearchChange = useRef(debounce(searchChange, 1500, true)).current

  return (
    <div className="filter-search" style={filterStyle}>
      <antdUI.Input onKeyDown={_ => _.keyCode === 13 && delaySearchChange(searchVal.toUpperCase())} value={searchVal}
        onChange={e => setSearchVal(
          // inputValueLimit()
          e.target.value
        )} style={searchStyle}
        placeholder={holderText} />
      <div onClick={() => delaySearchChange(searchVal.toUpperCase(), currentGame)}
        className="search-btn">搜索</div>
    </div>
  )
}

export default SearchFilter;
