import React, { useEffect, useState, $api, $tools, antdUI } from 'react';
import RollRoomBoxContainer from './RollRoomBoxContainer.js'

const rollNavData = [
    { title: '普通房', value: 'ordinary' },
    { title: '限定房', value: 'qualified' },
    { title: '大型推广房', value: "promote" }
]
const rollTypeData = [
    { label: '全部', value: '' },
    { label: '未开始', value: 'notStart' },
    { label: '进行中', value: "start" },
    { label: '我参与的', value: "join" },
    { label: '已中奖', value: "prize" },
    { label: '已结束', value: "end" }
]

function RollContentContainer({ nav }) {
    const pageSize = 9
    const [ rollNav, setRollNav ] = useState('')
    const [ rollRoomData, setRollRoomData ] = useState([])
    const [ curPage, setCurPage ] = useState(1)
    const [ total, setTotal ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ roomType, setRoomType ] = useState('')
    useEffect(_ => {
        if (nav === 'roll') {
            setRollNav('ordinary')
        } else if (nav === 'moe') {
            setRollNav('welfare')
        }
    }, [ nav ])
    const getRollRoomData = async (_curPage) => {
        setLoading(true)
        setRollRoomData([])
        setTotal(0)
        const params = { curPage: _curPage || curPage, state: roomType, categories: rollNav, pageSize }
        rollNav === "welfare" && (params.pageSize = 6);
        const result = await $api.rollRoom.getRollRoomData(params)
        setLoading(false)
        if ($tools.checkResult(result)) {
            setRollRoomData(result.data.data)
            setTotal(result.data.total)
        }
    }
    useEffect(_ => {
        (rollNav || roomType) && getRollRoomData().then(r => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rollNav, roomType, curPage ])
    
    return (
        <>
            <div className="roll-content-container">
                {
                    [ 'roll' ].includes(nav) && <div className="rollRoom-container">
                        <div className="rollRoom-nav">
                            {
                                rollNavData.map((_, idx) => {
                                    let cls = rollNav === _.value ? 'nav-item current flex jtyct ait' : 'nav-item flex jtyct ait'
                                    return <div style={ { border: idx === 0 ? 'none' : "" } } onClick={ () => {
                                        setRollNav(_.value)
                                        setRollRoomData([])
                                        setTotal('')
                                    } } key={ _.value } className={ cls }>{ _.title }</div>
                                })
                            }
                            
                            <div className="filter-room-select">
                                <antdUI.Select
                                    defaultValue="全部"
                                    style={ { width: 120 } }
                                    onChange={ _ => {
                                        setRoomType(_)
                                    } }
                                    options={ rollTypeData }
                                />
                            </div>
                        </div>
                        { loading ? <antdUI.Spin
                                style={ { display: "block", transform: "scale(1.5)", margin: '50px auto' } }/> :
                            <RollRoomBoxContainer
                                rollRoomData={ rollRoomData } rollNav={ rollNav }></RollRoomBoxContainer> }
                    </div>
                }
                {
                    [ 'moe' ].includes(nav) && <div className="rollRoom-container">
                        <div className="rollRoom-nav">
                            {
                                [ { title: '魔能(MOE)兑换', value: 'welfare' } ].map((_, idx) => {
                                    let cls = rollNav === _.value ? 'nav-item current flex jtyct ait' : 'nav-item flex jtyct ait'
                                    return <div style={ { width: 130, border: idx === 0 ? 'none' : "" } } onClick={ () => {
                                        setRollNav(_.value)
                                        setRollRoomData([])
                                        setTotal('')
                                    } } key={ _.value } className={ cls }>{ _.title }</div>
                                })
                            }
                        </div>
                        { loading ? <antdUI.Spin
                                style={ { display: "block", transform: "scale(1.5)", margin: '50px auto' } }/> :
                            <RollRoomBoxContainer
                                rollRoomData={ rollRoomData } rollNav={ rollNav }></RollRoomBoxContainer> }
                    </div>
                }
            </div>
            <div className="flex jtyct" style={ { margin: '30px 0' } }>
                { nav === 'roll' && <antdUI.Pagination
                    onChange={ (currentPage, pageSize) => {
                        setCurPage(currentPage)
                    } }
                    showSizeChanger={ false } current={ curPage } defaultCurrent={ 1 } total={ total }
                    pageSize={ pageSize } hideOnSinglePage={ true }/> }
            </div>
        </>
    )
}

export default RollContentContainer;
