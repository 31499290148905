import React, { useEffect } from 'react';
import BuyNav from '@/components/myPur/BuyNav'
import BuyContainer from '@/components/myPur/BuyContainer'
import './index.scss'
import { connect } from "react-redux";

function MyPur(props) {
    const { userInfo } = props
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
    }, [ userInfo ])
    return (
        <div className="myPur">
            <BuyNav/>
            <BuyContainer/>
        </div>
    )
    
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(MyPur);
