import React, { antdUI, useEffect, useState } from "react";
import './index.scss'
import { csgo } from '@/data/classification.js';

const CascaderStyle = {
    width: '104px',
    height: '33px',
    marginRight: '20px'
};
const searchStyle = {
    width: '158px',
    borderRadius: '4px 4px 4px 4px',
    height: '30px'
}
const navData = [
    {
        title: '我的配方',
        icon: require('@/images/formulaSquare/wodepeifang.png'),
        select_icon: require('@/images/formulaSquare/wodepeifangxuanzhong.png'),
        type: 'main'
    },
    {
        title: '汰换模拟', icon:
            require('@/images/formulaSquare/taihuanmoni.png'), select_icon:
            require('@/images/formulaSquare/taihuanmonixuanzhong.png'),
        type: 'replacingAnalog'
    },
    {
        title: '配方广场', icon:
            require('@/images/formulaSquare/peifangguangchang.png'), select_icon:
            require('@/images/formulaSquare/peifangguangchangxuanzhong.png'),
        type: 'square'
    },
    {
        title: '配方收藏', icon:
            require('@/images/formulaSquare/wodeshoucang.png'), select_icon:
            require('@/images/formulaSquare/wodeshoucangxuanzhong.png'),
        type: 'collection'
    }
]

function FormulaSquareNav(props) {
    const {
        initFilterVal, nav: { currentNav, setNav },
        navFilterValue: { itemType, rarity, exterior, collection, quality, classification },
        navFilterChange: {
            setItemType,
            setRarity,
            setExterior,
            setCollection,
            setQuality,
            setClassification,
            setSearchVal
        }
    } = props
    
    const [ _csgoData, set_csgoData ] = useState([])
    const [ sv, setSv ] = useState('')
    const filterNavDataSet = (currentNav) => {
        let _csdata = JSON.parse(JSON.stringify(csgo))
        _csdata.forEach((item, i, ar) => {
            if ([ "quality", "concentration", "dataRanking" ].indexOf(item.value) !== -1) {
                delete ar[i]
            } else {
                if (item.children) {
                    item.children.forEach((v, ind, arr) => {
                        if (item.value === 'itemType' &&
                            [ "CSGO_Type_Knife", "Type_Hands", "CSGO_Tool_Sticker", "other" ].indexOf(v.value) !== -1) {
                            delete arr[ind]
                        } else if (item.value === "rarity" &&
                            [ "Rarity_Mythical", "Rarity_Rare", "Rarity_Ancient", "Rarity_Legendary",
                                "Rarity_Common", "Rarity_Contraband", "Type_CustomPlayer" ].indexOf(v.value) !== -1) {
                            delete arr[ind]
                        } else if (item.value === 'exterior' && [ "WearCategoryNA" ].indexOf(v.value) !== -1) {
                            delete arr[ind]
                        } else {
                            v.label = <span style={ { color: v.color || '#222', } }>{ v.label }</span>
                            if (Object.prototype.toString.call(v.children) === '[object Array]') {
                                v.children.forEach(_ => _.label =
                                    <span style={ { color: _.color || '#222', } }>{ _.label }</span>)
                            }
                        }
                    })
                }
            }
        })
        set_csgoData(_csdata)
    }
    useEffect(() => {
        filterNavDataSet()
    }, [])
    
    useEffect(_ => {
        filterNavDataSet(currentNav)
    }, [ currentNav ])
    
    const _Cascader = (v, i) => (
        <antdUI.Cascader
            key={ i }
            dropdownRender={ menus => <div className="mobi-item-filter-select">{ menus }</div> }
            changeOnSelect={ v.value !== 'collection' }
            displayRender={ label => label[label.length - 1] }
            expandTrigger="hover"
            style={ CascaderStyle } options={ v.children }
            value={ (() => {
                switch (v.value) {
                    case "itemType":
                        return itemType;
                    case "rarity":
                        return rarity;
                    case "exterior":
                        return exterior;
                    case "collection":
                        return collection;
                    default:
                        break;
                }
            })() }
            onChange={ value => {
                let _v = value;
                switch (v.value) {
                    case "itemType":
                        setItemType(_v);
                        break;
                    case "rarity":
                        setRarity(_v);
                        break;
                    case "exterior":
                        setExterior(_v);
                        break;
                    case "collection":
                        setCollection(_v);
                        break;
                    default:
                        break;
                }
            } } placeholder={ v.label }
        />
    )
    return (
        <div className="formula-square-nav">
            <div className="formulaSquare-nav-container">
                { navData.map((v, i) => {
                    let _cur = v.type === currentNav,
                        _cn = _cur ? 'nav-item current' : 'nav-item';
                    return (
                        <div key={ i }
                             onClick={ () => {
                                 window.location.href = `/formulaSquare#${ v.type }`;
                                 setNav(v.type)
                                 initFilterVal()
                             } }
                             className={ _cn }>
                            <img className="formulaSquare-nav-icon" src={ _cur ? v.select_icon : v.icon } alt=""/>
                            { v.title }
                        </div>
                    )
                }) }
            </div>
            <div className="formulaSquare-nav-con-container">
                {
                    _csgoData.map((v, i) => _Cascader(v, i))
                }
                { currentNav === 'square' &&
                    <antdUI.Cascader style={ CascaderStyle } placeholder="排行" value={ classification }
                                     options={ [ { value: "latest", label: "最新" }, {
                                         value: "hottest",
                                         label: "最热"
                                     } ] }
                                     onChange={ _ => setClassification(_) }/> }
                <antdUI.Switch style={ { width: '90px' } } checkedChildren="StatTrak™" unCheckedChildren="普通"
                               onChange={ _ => setQuality(_ ? 'strange' : 'normal') }
                               checked={ quality === 'strange' }/>
                <div className="filter-search" style={ { right: '42px' } }>
                    <antdUI.Input
                        value={ sv }
                        onKeyDown={ _ => _.keyCode === 13 && setSearchVal(_.target.value) }
                        onBlur={ e => setSearchVal(e.target.value) }
                        onChange={ e => setSv(e.target.value) }
                        style={ searchStyle }
                        placeholder="输入物品名称"/>
                    {/*<div onClick={ () => delaySearchChange(searchVal.toUpperCase()) }*/ }
                    {/*     className="search-btn">搜索*/ }
                    {/*</div>*/ }
                </div>
            </div>
        </div>
    )
}

export default FormulaSquareNav
