import React, { $api, $login, $tools, antdUI, useState } from 'react';
import { connect } from "react-redux";
import { setUserSignIn } from '@/store/actions/userInfo';
import './index.css'

function MobiModal(props) {
    const displayNone = { display: 'none' }
    const { userInfo, setUserSignIn } = props;
    const [ bindSteam, setSt ] = useState(false)
    const [ real, setReal ] = useState(false)
    const [ link, setLink ] = useState(false)
    const [ apiKey, setApiKey ] = useState(false)
    const [ inputVal, setInpVal ] = useState('')
    const { debounce, checkResult } = $tools
    const saveSteamInfo = async (type) => {
        let val, result;
        if (! userInfo.uid) return $login()
        if (! userInfo.steam.steamid) return antdUI.message.error('请先绑定steam账号')
        switch (type) {
            case 'transactionLink':
                (val = inputVal);
                if (! val) return antdUI.message.error('您输入的信息有误');
                result = await $api.steam.upDateSteamTransactionLink({ transactionLink: val })
                setLink(false)
                break;
            case 'apiKey':
                (val = inputVal);
                if (! val) return antdUI.message.error('您输入的信息有误');
                if (val.length !== 32) return antdUI.message.error('您输入的 ApiKey 格式有误');
                let _val = String(userInfo.steam?.apiKey).split('')
                String(val).split('').forEach((item, idx) => {
                    if (item.match(/[0-9A-Z]/)) {
                        _val[idx] = item
                    }
                })
                result = await $api.steam.upDateSteamApiKey({ apiKey: _val.join('') })
                setApiKey(false)
                break;
            default:
                break;
        }
        setInpVal('')
        if (checkResult(result)) {
            if (result.data.error) {
                antdUI.message.error('无效的 ApiKey!')
                setUserSignIn()
            } else if (result.data.apiKey || result.data.transactionLink) {
                antdUI.message.success('设置成功');
                setUserSignIn()
                setTimeout(window.location.reload, 300)
            }
        }
    }
    
    return (
        <>
            <div style={ displayNone } id="to-bind-steam" onClick={ _ => setSt(true) }></div>
            <antdUI.Modal
                title="请先完善Steam绑定"
                visible={ bindSteam }
                onOk={ _ => window.location.href = '/profile/userConfig' }
                onCancel={ _ => setSt(false) }
                okText="前往绑定"
                bodyStyle={ { minHeight: '180px' } }
                cancelText="取消"
                maskClosable={ false }
            >
                <h3>前往个人中心的交易信息设置绑定Steam</h3>
            </antdUI.Modal>
            <div id="to-real" style={ displayNone } onClick={ _ => setReal(true) }></div>
            <antdUI.Modal
                title="请先完成实名认证"
                visible={ real }
                onOk={ _ => window.location.href = '/profile/userConfig' }
                onCancel={ _ => setReal(false) }
                okText="前往认证"
                bodyStyle={ { minHeight: '180px' } }
                cancelText="取消"
                maskClosable={ false }>
                <h3>前往个人中心的账号信息设置完成实名认证</h3>
            </antdUI.Modal>
            
            <div id="bind-steam-link" onClick={ _ => setLink(true) } style={ displayNone }></div>
            <antdUI.Modal
                title="请先绑定Steam交易链接"
                visible={ link }
                onOk={ debounce(saveSteamInfo.bind(null, 'transactionLink'), 2000, true) }
                onCancel={ _ => {
                    setLink(false)
                    setInpVal('')
                } }
                okText="保存"
                bodyStyle={ { minHeight: '180px' } }
                cancelText="取消"
                maskClosable={ false }
            >
                <div style={ { display: 'grid', justifyContent: 'center', paddingBottom: '40px' } }>
                    <h3>当前检测到您未绑定Steam交易链接，请先绑定</h3>
                    <antdUI.Input
                        style={ { marginTop: '10px' } }
                        value={ inputVal }
                        onChange={ e => setInpVal(e.target.value.replace(/\s+/g, '')) }
                        placeholder="请输入交易链接"/>
                    <div className="flex jtyct ait" style={ {
                        position: 'absolute', width: '100%', textAlign: 'center',
                        bottom: '53px', left: '0', padding: '10px 0', backgroundColor: '#f0f0f0',
                        color: '#999'
                    } }>
                        <p className="mobi-modal-to-steam-info" onClick={ _ => {
                            if (userInfo.steam.steamid) {
                                window.open(`https://steamcommunity.com/profiles/${ userInfo.steam.steamid }/tradeoffers/privacy`)
                            } else {
                                antdUI.message.warning('请先绑定steam账号')
                            }
                        } } style={ { cursor: 'pointer' } }>前往获取交易链接
                            <i style={ { fontSize: '12px' } }
                               className="iconfont icon-arrow-right"></i>
                        </p>
                    </div>
                </div>
            </antdUI.Modal>
            
            
            <div id="bind-steam-apiKey" onClick={ _ => setApiKey(true) } style={ displayNone }></div>
            <antdUI.Modal
                title="请先绑定ApiKey"
                visible={ apiKey }
                onOk={ debounce(saveSteamInfo.bind(null, 'apiKey'), 2000, true) }
                onCancel={ _ => {
                    setApiKey(false)
                    setInpVal('')
                } }
                okText="保存"
                bodyStyle={ { minHeight: '180px' } }
                cancelText="取消"
                maskClosable={ false }
            >
                <div style={ { display: 'grid', justifyContent: 'center', paddingBottom: '40px' } }>
                    <h3>当前检测到您未设置ApiKey，请先设置</h3>
                    <antdUI.Input
                        style={ { marginTop: '10px' } }
                        value={ inputVal }
                        onChange={ e => setInpVal(e.target.value.replace(/\s+/g, '')) }
                        placeholder="请输入您的Steam Api Key"/>
                    <div className="flex jtyct ait" style={ {
                        position: 'absolute', width: '100%', textAlign: 'center',
                        bottom: '53px', left: '0', padding: '10px 0', backgroundColor: '#f0f0f0',
                        color: '#999'
                    } }>
                        <p className="mobi-modal-to-steam-info"
                           onClick={ _ => window.open(`https://steamcommunity.com/dev/apikey`) }
                           style={ { cursor: 'pointer' } }>前往获取Api Key
                            <i style={ { fontSize: '12px' } }
                               className="iconfont icon-arrow-right"></i>
                        </p>
                    </div>
                </div>
            </antdUI.Modal>
        </>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(MobiModal);
