import React, { $api, $tools, antdUI, useEffect, useState } from "react";
import './index.scss';
import { allCustomItemData } from '@/data/replacingAnalogData.js'

const rarityData = [
    {
        "label": "隐秘",
        "value": "Rarity_Ancient_Weapon",
        "color": "#EB4B4B"
    },
    {
        "label": "保密",
        "value": "Rarity_Legendary_Weapon",
        "color": "#D32CE6"
    },
    {
        "label": "受限",
        "value": "Rarity_Mythical_Weapon",
        "color": "#8847FF"
    },
    {
        "label": "军规级",
        "value": "Rarity_Rare_Weapon",
        "color": "#4B69FF"
    },
    {
        "label": "工业级",
        "value": "Rarity_Uncommon_Weapon",
        "color": "#5E98D9"
    }
]

function MainFormula(props) {
    const wearData = React.$wearData2
    const {
        nav: { setNav },
        navFilterValue: { itemType, rarity, exterior, collection, quality, searchVal },
    } = props;
    const { getUrlQueryValue } = $tools
    const locaHref = window.location.href.match(/\?nav=(\S+)/g)?.[0]
    const _nav = getUrlQueryValue('nav', locaHref)
    const [ squareData, setSquareData ] = useState([])
    const pageSize = 10
    const [ curPage, setCurPage ] = useState(1)
    const [ total, setTotal ] = useState()
    const [ dataSource, setDataSource ] = useState([])
    const [ tipsShow, setTipsShow ] = useState({ view: false });
    const [ fNav, setFNav ] = useState(_nav ? Number(_nav) : 0)
    const getData = async ({ _curPage, itemType, rarity, exterior, collection, quality, searchVal }) => {
        if (searchVal && ! searchVal.replace(/\s+/g, '')) antdUI.message.warning('请输入正确的搜索内容！')
        let para = { pageSize }
        ! _curPage && (_curPage = curPage)
        if (itemType[0]) {
            para.type = itemType[0]
            if (itemType[1]) (para.weapon = itemType[1])
        }
        if (rarity[0]) (para.rarity = rarity[0])
        if (exterior[0]) (para.exterior = exterior[0])
        if (collection[0] && collection[1]) (para.itemSet = collection[1])
        if (quality) (para.quality = quality)
        para.curPage = _curPage
        searchVal && searchVal.replace(/\s+/g, '') && (para.searchVal = searchVal)
        const result = await $api.formula.getMainFormulaData({ ...para, state: fNav }).then()
        if ($tools.checkResult(result)) {
            setSquareData(result.data.data)
            setTotal(result.data.total)
        }
    }
    useEffect(_ => {
        getData({
            _curPage: curPage, itemType, rarity,
            exterior, collection, quality, searchVal
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ itemType, rarity, exterior, collection, quality, searchVal, curPage, fNav ])
    
    const deleteFormula = async () => {
        const result = await $api.formula.deleteFormula({ id: tipsShow.formulaId });
        
        if ($tools.checkResult(result)) {
            antdUI.message.success(`删除成功`)
            setTipsShow({ ...tipsShow, view: false })
            getData({
                _curPage: curPage, itemType, rarity,
                exterior, collection, quality, searchVal
            })
        }
    }
    useEffect(_ => {
        let _dataSource = [], list = {}, tWear;
        squareData[0] && squareData.forEach(item => {
            list = allCustomItemData.filter(_ => _.name === item.name)[0]
            wearData.forEach((_, idx, arr) => {
                if (Number(item.wearValue) <= _.ratio && idx === 0) tWear = _
                if (Number(item.wearValue) >= _.ratio && Number(item.wearValue) <= arr[idx + 1].ratio) tWear = arr[idx + 1]
            })
            _dataSource.push({
                key: item.id,
                formulaName: (
                    <div className="formula-name text-ov1">
                        <h4>
                            { item.state ? <span className="pub">公开</span> :
                                <span className="pri">{ item.isDraft ? '草稿' : '私有' }</span> }
                            { item.formulaName }
                        </h4>
                        <div className="flex ait jtyct">
                            <i className="iconfont icon-dianzan"></i>&nbsp;{ item.praise }
                            &nbsp;  &nbsp;
                            <i className="iconfont icon-31pinglun"></i>&nbsp;{ item.commentsCounts }
                        </div>
                    </div>
                ),
                planCover: (
                    <div className="plan-cover"
                         style={ { backgroundImage: fNav === 2 && `url(${ require('@/images/formulaSquare/draft.png') })` } }>
                        <h4 className="item-quality" style={ { alignItems: "start" } }>
                            { fNav !== 2 && <><p
                                value={ list.en_rarity }
                                style={ { color: `${ rarityData.filter(e => e.value === list.en_rarity)?.[0]?.color }`, } }
                                className="item_quality"
                            >{ list.rarity }</p>
                                <p
                                    style={ { color: '#fff', backgroundColor: `${ tWear?._bcc }`, } }
                                    className="item_quality"
                                >{ tWear?.title === '略有磨损' ? '略磨' : tWear?.title.slice(0, 2) }</p></> }
                        </h4>
                        { fNav !== 2 && <h4 className="replacing-chance">
                            { item.chance }%
                        </h4> }
                        { fNav !== 2 && <img src={ list.cover } alt={ item.name }/> }
                        { fNav === 2 && <p style={ { color: '#fff', textAlign: 'center' } }></p> }
                    </div>
                ),
                createPeople: (
                    <div className="create-people">
                        { item?.UserSteam?.avatarfull ?
                            <img src={ item?.UserSteam?.avatarfull } alt=""/>
                            : <img className="avatar-icon"
                                   src={ require('@/images/home/moren touxiang@2x.png') } alt=""/> }
                        <h4 className="text-ov1">{ item?.user?.nickname }</h4>
                    </div>
                ),
                updateTime: (
                    <div className="update-time">
                        { item.updateTime }
                    </div>
                ),
                operating: (
                    <div className="operating">
                        { fNav === 2 ? <>
                            <antdUI.Button type="primary" style={ { marginRight: '10px' } } onClick={ _ => {
                                window.location.href = `/formulaSquare#replacingAnalog?id=${ item.id }`
                            } }>编辑
                            </antdUI.Button>
                            <antdUI.Button type="primary" danger onClick={ _ => setTipsShow({
                                view: true,
                                type: 'delete',
                                formulaId: item.id
                            }) }>删除
                            </antdUI.Button>
                        </> : <>
                            <antdUI.Button type="primary" style={ { marginRight: '10px' } } onClick={ _ => {
                                setNav('detail')
                                window.location.href = `/formulaSquare#detail?id=${ item.id }&type=main`
                            } }>查看
                            </antdUI.Button>
                            <antdUI.Button type="primary" danger onClick={ _ => setTipsShow({
                                view: true,
                                type: 'delete',
                                formulaId: item.id
                            }) }>删除
                            </antdUI.Button>
                        </> }
                    </div>
                )
            })
        })
        setDataSource(_dataSource)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ squareData ])
    return (
        <div className="main-formula-sq">
            <div className="sq-title">
                <div className="flex">{
                    [ { title: '私有', v: 0 }, { title: '公开', v: 1 }, { title: '草稿', v: 2 } ].map(_ => {
                        return (
                            <h3 key={ _.v } onClick={ () => {
                                setFNav(_.v);
                                window.location.href = `/formulaSquare#main?nav=${ _.v }`
                            } }
                                className={ [ _.v === fNav ? 'sq-title-nav-item current' : 'sq-title-nav-item' ] }>{ _.title }</h3>)
                    })
                }</div>
                <button onClick={ _ => setNav('replacingAnalog') }>新建配方</button>
            </div>
            <div className="sq-box">
                <antdUI.Table
                    pagination={ false }
                    dataSource={ dataSource }
                    columns={ columns }
                >
                </antdUI.Table>
                
                { total ? <div className="flex jtyct" style={ { margin: '30px 0' } }>
                    <antdUI.Pagination
                        onChange={ (currentPage, pageSize) => {
                            setCurPage(currentPage)
                        } }
                        showSizeChanger={ false } current={ curPage } defaultCurrent={ 1 }
                        total={ total }
                        pageSize={ pageSize }/>
                </div> : '' }
            </div>
            <antdUI.Modal
                title={ "提示" }
                maskClosable={ false }
                visible={ tipsShow.view }
                okText={ "删除" }
                cancelText="取消"
                onOk={ $tools.debounce(deleteFormula.bind(null), 4000, true) }
                onCancel={ () => setTipsShow({ ...tipsShow, view: false }) }
                width="400px"
            >
                <div className="flex jtyct">
                    <h2 style={ { fontWeight: '700', padding: "30px 0" } }>是否删除该配方？</h2>
                </div>
            </antdUI.Modal>
        </div>
    )
}

const titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
const columns = [
    {
        title: <p style={ titleStyle }>配方名称</p>,
        dataIndex: 'formulaName',
        key: 'formulaName',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>方案封面</p>,
        dataIndex: 'planCover',
        key: 'planCover',
        render: text => text
    },
    // {
    //     title: <p style={ titleStyle }>创建人</p>,
    //     dataIndex: 'createPeople',
    //     key: 'createPeople',
    //     render: text => text
    // },
    {
        title: <p style={ titleStyle }>创建(更新)时间</p>,
        dataIndex: 'updateTime',
        key: 'updateTime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        render: text => text
    }
]
export default MainFormula
