import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './banner.scss';
import { Link } from "react-router-dom";

const bannerData = [
    {
        src: 'https://static.mobi2077.com/information/banner1.png',
        url: "/rollRoom/detail",
        search: "?roomID=1"
    },
    { src: 'https://static.mobi2077.com/information/banner2.png' },
    { src: 'https://static.mobi2077.com/information/banner3.png', url: "/help" }
]

function HomeBanner() {
    const [ announcement, setAnnouncement ] = useState("")
    
    const getAnnouncement = async () => {
        const result = await $api.siteSetting.getAnnouncement()
        if ($tools.checkResult(result)) {
            setAnnouncement(result.data)
        }
    }
    
    useEffect(_ => {
        getAnnouncement().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div className="home-banner">
            {/* effect="fade" */ }
            <antdUI.Carousel autoplay>
                { bannerData.map((v, i) => {
                    return (
                        <Link key={ i } to={ { pathname: v.url, search: v.search } }><img src={ v.src } alt=""/></Link>
                    )
                }) }
            </antdUI.Carousel>
            <div className="banner-bottom-tips flex ait">
                <i style={ { fontSize: '22px', fontWeight: 'bold' } }
                   className="iconfont icon-31pinglun"> </i> ： { announcement.msg }
            </div>
        </div>
    );
}


export default HomeBanner;
