import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './scss/mobi.scss';
import { connect } from 'react-redux';
import { upDateUserAssets } from '@/store/actions/userInfo'
import BonusTips from './bonusTips';

const promptData = [
    { text: 'MOE代币相关须知' },
    { text: '1.分红是根据你在福利Roll房中，投入MOE代币数量所占房间所需投入MOE总数量，按比例进行奖金分配。' },
    { text: '2.分红将在福利Roll房开奖后，通过奖品方式发放到“我的库存”—“MOBI背包”中，点击取回即可直接到你的账号余额。' },
    { text: '3.持有代币将长期享受MOBI平台的分红福利。' },
    { text: '4.未来其它福利，请持续关注MOBI平台。' }
];

function tabItem({ type, text, item }) {
    let _st = { textAlign: 'center' }
    switch (type) {
        case 'amount':
            console.log(item)
            if (item.balance_before_change > item.balance_after_change) {
                _st.color = '#FF4D4F'
                text = `-${ text }`;
            } else {
                _st.color = '#2EB062';
                text = `+${ text }`;
            }
            break;
        case 'token_type':
            const cfg = {
                "first_promoted": "一级推广所得",
                "second_promoted": "二级推广所得",
                "transaction": "销售激励所得",
                "promoted_transaction": "推广销售激励所得",
                "mobiBackpack": "背包所得",
                "rollRoomCost": "投入Roll房花费",
                "purchase": "购买激励所得"
            }
            text = cfg[text];
            break
        default:
            break;
    }
    
    return < p style={ _st }>{ text }</p>
}

const columns = [
    {
        title: <p style={ { textAlign: 'center' } }>代币名称</p>,
        dataIndex: 'token_name',
        key: 'token_name',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>变动方式</p>,
        dataIndex: 'token_type',
        key: 'token_type',
        render: text => tabItem({ text, type: 'token_type' })
    },
    {
        title: <p style={ { textAlign: 'center' } }>变动数量(个)</p>,
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, item, index) => {
            return tabItem({ text, item, type: 'amount' })
        }
    },
    
    {
        title: <p style={ { textAlign: 'center' } }>变动之前数量(个)</p>,
        dataIndex: 'balance_before_change',
        key: 'balance_before_change',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>变动之后数量(个)</p>,
        dataIndex: 'balance_after_change',
        key: 'balance_after_change',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>获得时间</p>,
        dataIndex: 'create_time',
        key: 'create_time',
        render: text => tabItem({ text })
    },
];


const mobiRewardData = [
    { bacLogo: require('@/images/profile/mobizongshu@2x.png'), title: '未发放MOE总数量' },
    { bacLogo: require('@/images/profile/nichiyoumobizhishu@2x.png'), title: '你持有MOE数量' },
    { bacLogo: require('@/images/profile/yipaifafenhong@2x.png'), title: '通过MOBI福利房获取余额' }
]

function Mobi(props) {
    const { userAssets } = props;
    const [ rRecord, setrRecord ] = useState(false);
    const [ userMobiTokens, setUserMobiTokens ] = useState([]),
        [ tokenRecord, setTokenRecord ] = useState({ record: [] }),
        [ currentNav ] = useState('promotionalIncentives'),
        [ finalColumns ] = useState(columns);
    
    const { getUserTokensRecord, getIssuance } = $api.user;
    const [ issuance, setIssuance ] = useState([]);
    
    const getRecord = async (currentPage = 1, pageSize = 5) => {
        setTokenRecord({ record: [] });
        let result = await getUserTokensRecord({ currentPage, pageSize });
        
        if ($tools.checkResult(result)) {
            setTokenRecord({
                total: result.data?.total,
                record: result.data?.record?.filter((item, i) => (item.key = i + 1))
            })
        }
        
        
    }
    
    useEffect(() => {
        // setrRecord({record: []});
        getRecord()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentNav ])
    
    useEffect(() => {
        getIssuance({ token_name: 'MOBI' }).then(res => {
            if ($tools.checkResult(res)) {
                setIssuance(res.data)
            }
        })
        //eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        setUserMobiTokens([
            { val: userAssets?.allTokens || 0 },
            { val: userAssets?.mobi_tokens || 0 },
            { val: userAssets?.allWelfareAmount || 0, money: true } ])
    }, [ userAssets ])
    
    return (
        <div className="mobi-manag">
            <BonusTips userData={ userAssets } issuance={ issuance }></BonusTips>
            <div className="mobi-container">
                <div className="mobi-reward">
                    { userMobiTokens.map((v, i) => {
                        return (
                            <div key={ i }
                                 style={ {
                                     marginLeft: i % 5 ? '2.5%' : '',
                                     backgroundImage: `url(${ mobiRewardData[i].bacLogo })`
                                 } }
                                 className="mobi-item">
                                <p className="title">{ mobiRewardData[i].title }</p>
                                <h2 className="mobi-value">{ v.money && '￥' }{ v.val }</h2>
                            </div>
                        )
                    }) }
                </div>
                
                <div
                    className="mobi-prompt">
                    { promptData.map((v, i) => <p key={ i } className="prompt-v">{ v.text }</p>) }
                </div>
                
                <div className="mobi-link">
                    {/*<a style={{ color: `#fff` }} href="/equityPlan">*/ }
                    {/*  <div className="mobi-btn">查看MOBI联盟计划</div>*/ }
                    {/*</a>*/ }
                    
                    {/*<a style={{ color: `#fff` }} href="/equityPlan">*/ }
                    {/*  <div className="mobi-btn"> 查看MOBI权益计划</div>*/ }
                    {/*</a>*/ }
                    <div onClick={ () => setrRecord(true) } className="mobi-record">
                        代币记录
                        <i className="iconfont icon-arrow-right"></i>
                        <i className="iconfont icon-arrow-right"></i>
                    </div>
                
                </div>
                
                <antdUI.Modal
                    title="代币记录"
                    visible={ rRecord }
                    footer={ false }
                    onCancel={ () => setrRecord(false) }
                    width="68%"
                    destroyOnClose={ true }
                >
                    
                    <div className='mobi-table-c'>
                        <antdUI.Table
                            pagination={ {
                                pageSize: 5, total: tokenRecord.total, showSizeChanger: false,
                                onChange: (currentPage, pageSize) => getRecord(currentPage)
                            } }
                            dataSource={ tokenRecord.record } columns={ finalColumns }>
                        </antdUI.Table>
                    </div>
                
                </antdUI.Modal>
            </div>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(Mobi);



