import { get, post } from '@/utils/request.js';

export default {
    getSellerPendingOffer() {
        return get({ url: '/market/tradeoffer/received/seller/list/' })
    },
    getBuyerPendingOffer() {
        return get({ url: '/market/tradeoffer/received/buyer/list/' })
    },
    
    checkTradeOffer(params) {
        return get({ url: '/tradeoffer/check/', params })
    },
    
    manualCheckSaleOrder(data) {
        return post({ url: 'tradeoffer/manual/end/', data })
    }
}
