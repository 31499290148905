import React, { $api, $tools, antdUI, useState } from 'react';
import './index.scss'
import { connect } from "react-redux";

const promptData = [
    { text: '1、每位用户注册时初始信誉为90分，信誉分上限为120分，', dom: true },
    { text: '100分以上为极好', dom: true, _color: '#FFA800' },
    { text: '，', dom: true },
    { text: '86-100为优良', dom: true, _color: '#2DAF61' },
    { text: '，', dom: true },
    { text: '70-85分为中', dom: true, _color: '#EF7F12' },
    { text: '，', dom: true },
    { text: '70分以下为差', dom: true, _color: '#FF3600' },
    { text: '。', dom: true },
    { text: "2、信誉等级为极好和优良的用户，在交易时会匹配低交易费率并获得网站代币奖励；当用户交易信誉度比较低时，则无法获得上述奖励。" },
    { text: "3、每成功销售1件3000元以上饰品或者销售额每增加10000元，加一分。" },
    { text: "4、卖家超时未发报价，超时未接报价、取消拒绝报价或者其它违规操作，扣一分。" },
];

const levelsData = [
    {
        cover: require("@/images/profile/reputationLevel/jihao@3x.png"),
        _cover: require("@/images/profile/reputationLevel/jihaoxuanzhong@3x.png"),
        infoText: "交易费率至高1.5%,提现手续费低至0.3%",
        color: "#996639", showColor: "#ffb863"
    },
    {
        cover: require("@/images/profile/reputationLevel/youliang@3x.png"),
        _cover: require("@/images/profile/reputationLevel/youliangxuanzhong@3x.png"),
        infoText: "享受当前手续费和销售所得代币奖励",
        color: "#438852", showColor: "#79e090"
    },
    {
        cover: require("@/images/profile/reputationLevel/zhong@3x.png"),
        _cover: require("@/images/profile/reputationLevel/zhongxuanzhong@3x.png"),
        infoText: "享受当前手续费，但是没有销售所得代币奖励",
        color: "#845739", showColor: "#cb9c7e"
    },
    {
        cover: require("@/images/profile/reputationLevel/cha@3x.png"),
        _cover: require("@/images/profile/reputationLevel/chaxuanzhong@3x.png"),
        infoText: "所有饰品交易手续费2%，且无销售所得代币奖励",
        color: "#a42c28", showColor: "#ee7d4b"
    }
]


function tabItem({ text, type, quantity }) {
    let _st = { textAlign: 'center' }
    if (quantity) {
        if (type === 1) {
            _st.color = 'rgb(46, 176, 98)'
            text = `+${ quantity }`
        }
        if (type === 2) {
            _st.color = 'red'
            text = `-${ quantity }`
        }
    }
    return < p style={ _st }>{ text }</p>
}

const columns = [
    {
        title: <p style={ { textAlign: 'center' } }>详情</p>,
        dataIndex: 'describe',
        key: 'describe',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>积分变动</p>,
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, { type, quantity }) => tabItem({ text, type, quantity })
    },
    {
        title: <p style={ { textAlign: 'center' } }>时间</p>,
        dataIndex: 'createTime',
        key: 'createTime',
        render: text => tabItem({ text })
    },
];

function Reputation({ userInfo }) {
    const creditLevelData = $tools.getCredibilityInfoData(userInfo?.reputationPoints)
    const reputationLevel = creditLevelData?.level
    const [ record, setRecord ] = useState(false)
    const [ rechRecord, setRechRecord ] = useState({ total: 0, items: [] });
    
    const getCreditRecord = async (page = 1, pageSize = 10) => {
        let result = await $api.user.creditRecord({ page, pageSize })
        if ($tools.checkResult(result)) {
            setRechRecord({
                total: result.data.total, items: result.data.items.map((_, i) => {
                    _.key = i;
                    return _
                })
            })
        }
    }
    
    return (
        <div className="reputation">
            <div className="title">
                <h1>信誉系统</h1>
            </div>
            
            <div className="reputation-container">
                <div className="reputation-title-box">
                    <div className="credit-score">
                        <div className="credit-val">
                            <h4 style={ { fontSize: 14, color: "#fff" } }>当前用户信誉值</h4>
                            {/*<img src={ $tools.getCredibilityIcon(userInfo?.reputationPoints) } alt=""/> */ }
                        </div>
                        <div className="credit-bar-container">
                            <h1 style={ { fontSize: 32, color: "#fff" } }>{ userInfo?.reputationPoints } </h1>
                            <div className="credit-bar-box">
                                <div className="credit-bar">
                                    <div
                                        style={ {
                                            width: `${ (
                                                userInfo?.reputationPoints - creditLevelData?.all)
                                            / creditLevelData?.quality * 100 }%`
                                        } }
                                        className="credit-bar-content"></div>
                                </div>
                                <div className="credit-bar-text">
                                    <p>{ creditLevelData?.text[0] }</p><p
                                    style={ { position: 'relative', left: 25 } }>{ creditLevelData?.text[1] }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="reputation-level-box">
                    {
                        levelsData.map((_, i) => {
                            const levelCur = reputationLevel === i
                            const cls = levelCur ? 'reputation-level-item current' : 'reputation-level-item'
                            return (
                                <div className={ cls } key={ i }
                                     style={ {
                                         backgroundImage: levelCur ? `url(${ _._cover })` : `url(${ _.cover })`,
                                         boxShadow: levelCur && `${ _.color } 0 0 10px`
                                     } }>
                                    <p style={ {
                                        color: levelCur && _.color,
                                        top: levelCur && "145px"
                                    } }>{ _.infoText }</p>
                                </div>
                            )
                        })
                    }
                </div>
                
                
                <div
                    className="reputation-prompt">
                    <p style={ { fontSize: 16, fontWeight: "bold", marginBottom: 6 } }>积分规则</p>
                    { promptData.map((v, i) => {
                        if (v.dom) {
                            return <p key={ i }
                                      style={ { display: "inline-block", color: v?._color } }
                                      className="prompt-v">{ v.text }</p>
                        } else {
                            return <p key={ i } className="prompt-v">{ v.text }</p>
                        }
                    }) }
                </div>
                
                <div onClick={ () => {
                    getCreditRecord().then();
                    setRecord(true)
                } } className="credit-link">
                    信誉积分变动记录 >
                    {/*<i className="iconfont icon-arrow-right"></i>*/ }
                    {/*<i className="iconfont icon-arrow-right"></i>*/ }
                </div>
            </div>
            
            <antdUI.Modal
                title="信誉积分变动记录"
                maskClosable={ false }
                visible={ record }
                footer={ false }
                onCancel={ () => setRecord(false) }
                width="700px"
            >
                <antdUI.Table pagination={ {
                    pageSize: 10, showSizeChanger: false, total: rechRecord.total, onChange: (page) => {
                        getCreditRecord(page, 10).then()
                    }
                } } dataSource={ rechRecord.items } columns={ columns }>
                </antdUI.Table>
            </antdUI.Modal>
        
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return { userInfo: state.user.userInfo }
    }
)(Reputation);

