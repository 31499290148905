import React, { antdUI, $api, useState, useEffect } from 'react';
import Prompt from './prompt'
import OnShelfContainer from './onShelf'
import sd from 'silly-datetime'

function OnShelf(props = {}) {
    let {
        title, modalShow, onShelfType, setCurrentPage,
        setGame, setOnShelfTitle, onShelfData,
        setModalShow, handleCacheInventoryData, currentGame,
        setInventory, itemData, setSelectAllCur, handlePutSu
    } = props;
    const curDate = sd.format(new Date(), 'YYYYMMDD'),
        locShelf = window.localStorage.getItem(`putTips-${ onShelfType }`);
    const initShelf = locShelf === curDate ? 'onShelf' : 'prompt';
    const [ shelf, setShelf ] = useState(initShelf),
        [ isInt ] = useState(''),
        [ putSw, setPutSw ] = useState(true),
        [ _onShelfData, set_OnShelfData ] = useState(onShelfData);
    
    const setItemDetail = (newIData) => {
        let _data = _onShelfData.map((item) => {
            newIData[item.assetid] && (item.market_item_detail = newIData[item.assetid])
            return item
        })
        const assetidList = _data.map(_ => _.assetid)
        set_OnShelfData(_data)
        let l, data = itemData.map((item) => {
            l = assetidList.indexOf(item.assetid)
            if (l !== -1) (item.market_item_detail = _data[l].market_item_detail)
            return item
        })
        setInventory(handleCacheInventoryData(data))
    }
    // useEffect(_ => {
    //   console.log(putSw);
    // }, [putSw])
    const getMarketItemDetail = async (tarData) => {
        let op = []
        tarData.forEach(item => {op.push({ inspecturl: item.actions[0].link, assetid: item.assetid })})
        if (op.length < 1) return setPutSw(false);
        $api.steam.getMarketItemDetail({ tarData: op }).then(res => {
            if (res && res.status === 200 && JSON.stringify(res.data) !== '{}') setPutSw(false);
            let market_item_detail = res?.data || [];
            setItemDetail(market_item_detail)
        })
    };
    
    useEffect(() => {
        let notDetail = onShelfData.filter(item => item.actions && ! item.market_item_detail)
        getMarketItemDetail(notDetail)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isInt ])
    
    
    const onSuccess = ({ type, protocol }) => {
        if (protocol) window.localStorage.setItem(`putTips-${ type }`, sd.format(new Date(), 'YYYYMMDD'))
        setOnShelfTitle('上架饰品');
        setShelf('onShelf');
    }
    
    const process = () => {
        switch (shelf.toLowerCase()) {
            case 'prompt':
                return <Prompt onShelfType={ onShelfType }
                               onSuccess={ onSuccess }/>;
            case 'onshelf':
                return <OnShelfContainer
                    setSelectAllCur={ setSelectAllCur } handlePutSu={ handlePutSu }
                    setCurrentPage={ setCurrentPage } putSw={ putSw }
                    setPutSw={ setPutSw } setGame={ setGame }
                    setModalShow={ setModalShow } onShelfType={ onShelfType }
                    currentGame={ currentGame } onShelfData={ _onShelfData }/>;
            default:
                break;
        }
    }
    
    return (
        <div className="on-shelf">
            <antdUI.Modal
                title={ title }
                visible={ modalShow }  // 是否展示的布尔值
                footer={ false } // 是否需要按钮
                onCancel={ () => {
                    set_OnShelfData(onShelfData.map(item => {
                        item.actualPrice = ''
                        item.sellingPrice = ''
                        item.onShelfDesc = ''
                        return item
                    }))
                    setModalShow(false);
                } }
                maskClosable={ false }  // 点击蒙层是否可以关闭
                destroyOnClose={ true } // 关闭之后销毁子节点
                centered={ true } // 居中
                width="1100px"
            >
                { process() }
            </antdUI.Modal>
        </div>
    )
}

export default OnShelf;
