import React from 'react';
import './index.scss'
import RollRoomContent from '@/components/rollRoom/index.js';

function RollRoom() {
    return (
        <div className="Roll-room">
            <img
                style={ { width: '100%', position: 'absolute', top: 0, zIndex: 1 } }
                className="tranding-market-banner"
                src={ require("@/images/rollRoom/roll shouye beijing@2x.png") }
                alt=""/>
            <div className="Roll-room-content-container">
                <RollRoomContent></RollRoomContent>
            </div>
        </div>
    )
}

export default RollRoom;
