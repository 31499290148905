import { get, post } from "@/utils/request.js";

export default {
    // 保存配方
    saveFormula(data) {
        return post({ url: "/formula/save/", data });
    },
    // 我的配方
    getMainFormulaData(data) {
        return post({ url: '/formula/data/main/', data })
    },
    
    // 炼金配方数据
    getFormulaData(data) {
        return post({ url: '/formula/data/', data })
    },
    
    //  配方详情
    getFormulaDetail(params) {
        return get({ url: '/formula/data/detail/', params })
    },
    
    // 配方发布和取消
    formulaState(params) {
        return get({ url: '/formula/state/pubpri/', params })
    },
    
    // 编辑(更新)配方
    updateFormula(data) {
        return post({ url: '/formula/update/', data })
    },
    
    // 复制配方
    copyFormula(data) {
        return post({ url: '/formula/copy/', data })
    },
    
    // 删除配方
    deleteFormula(data) {
        return post({ url: '/formula/status/delete/', data })
    },
    // 收藏配方
    collectionFormula(data) {
        return post({ url: '/formula/collection/', data })
    },
    // 收藏配方数据
    formulaCollectionList(data) {
        return post({ url: '/formula/collection/list/', data })
    },
    // 配方发布评论
    formulaComments(params) {
        return get({ url: '/formula/comments/', params })
    },
    // 配方发布评论
    formulaCommentsSend(data, file) {
        return post({ url: '/formula/comments/send/', data, file })
    },
    
    delComment(data) {
        return post({ url: '/formula/comments/del/', data })
    },
    reportComment(data) {
        return post({ url: '/formula/comments/report/', data })
    },
    
    changePraise(data) {
        return post({ url: '/formula/praise/', data })
    }
};
