import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './scss/flowrecord.scss';
import { connect } from "react-redux";
import { upDateUserAssets, } from "@/store/actions/userInfo";

let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' }

function tabItem({ type, text }) {
    let _st = { textAlign: 'center' }
    switch (type) {
        case 'balance_before_change':
            _st.color = '#5491cf';
            break;
        case 'balance_after_change':
            _st.color = '#02AAF0';
            break;
        case 'origin':
            _st.width = '100px';
            break;
        default:
            break;
    }
    if (text[0] === '{') {
        text = JSON.parse(text)
        return <p style={ { ..._st, color: text.color } }>{ type === 'amount' ||
        type === 'balance' ? '￥' : '' } { text.text }</p>
    } else {
        return <p style={ _st }>{ type === 'amount' || type === 'balance' ? '￥' : '' } { text }</p>
    }
}

const columns = [
    {
        title: <p style={ titleStyle }>余额类型</p>,
        dataIndex: 'balance_type',
        key: 'balance_type',
        render: text => tabItem({ text })
    },
    // {
    //     title: <p style={ titleStyle }>来源</p>,
    //     dataIndex: 'origin',
    //     key: 'origin',
    //     render: text => tabItem({ text, type: 'origin' })
    // },
    {
        title: <p style={ titleStyle }>变动方式</p>,
        dataIndex: 'typeText',
        key: 'typeText',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ titleStyle }>变动前余额(￥)</p>,
        dataIndex: 'balance_before_change',
        key: 'balance_before_change',
        render: text => tabItem({ text, type: 'balance_before_change' })
    },
    {
        title: <p style={ titleStyle }>变动金额(￥)</p>,
        dataIndex: 'quantity',
        key: 'quantity',
        render: text => tabItem({ text, type: 'quantity' })
    },
    {
        title: <p style={ titleStyle }>变动后余额(￥)</p>,
        dataIndex: 'balance_after_change',
        key: 'balance_after_change',
        render: text => tabItem({ text, type: 'balance_after_change' })
    },
    {
        title: <p style={ titleStyle }>变动时间</p>,
        dataIndex: 'create_time',
        key: 'create_time',
        render: text => tabItem({ text, type: 'create_time' })
    }
];

function Flowrecord({ upDateUserAssets }) {
    const [ dataSource, setDataSource ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ balanceType, setBalanceType ] = useState(1)
    const [ curPage, setCurPage ] = useState(1)
    const [ isInit, setInit ] = useState(false)
    const pageSize = 10
    const getFlowRecord = async ({ page = 1, balanceType = 1 } = {}) => {
        let params = { page, pageSize, balanceType }
        const result = await $api.user.getFlowRecord(params)
        if (! $tools.checkResult(result)) return
        let { data: { total, record } } = result
        setTotal(total)
        setDataSource(record.map((_, i) => {
            _.key = i;
            _.quantity = Number(_.balance_after_change) > Number(_.balance_before_change) ?
                JSON.stringify({ text: `+${ _.quantity }`, color: 'rgb(46, 176, 98)' }) :
                JSON.stringify({ text: `-${ _.quantity }`, color: 'red' });
            switch (Number(_.type)) {
                case 1:
                    _.typeText = '充值';
                    break;
                case 2:
                    _.typeText = '购买退款';
                    break;
                case 3:
                    _.typeText = '充值退款';
                    break;
                case 4:
                    _.typeText = '提现';
                    break;
                case 5:
                    _.typeText = '销售所得';
                    break;
                case 6:
                    _.typeText = '购买饰品';
                    break;
                case 7:
                    _.typeText = '提现失败';
                    break;
                case 8:
                    _.typeText = '推广激励所得';
                    break;
                case 9:
                    _.typeText = '背包所得';
                    break;
                case 10:
                    _.typeText = '求购扣款';
                    break;
                case 11:
                    _.typeText = '发布求购';
                    break;
                case 12:
                    _.typeText = '取消求购';
                    break;
                case 14:
                    _.typeText = `求购失败${ balanceType === 1 ? '(买家责任)' : '' }`;
                    break;
                case 15:
                    _.typeText = '供应成功';
                    break;
                case 16:
                    _.typeText = '交易补偿';
                    break;
                case 17:
                    _.typeText = '退款失败';
                    break;
                case 20:
                    _.typeText = "协商退款"
                    break
                case 23:
                    _.typeText = "换货订单锁定金额"
                    break
                case 24:
                    _.typeText = "换货方案锁定金额"
                    break;
                case 25:
                    _.typeText = "饰品方案所得"
                    break;
                case 27:
                    _.typeText = "换货订单金额退回"
                    break
                case 28:
                    _.typeText = "换货方案金额退回"
                    break
                case 33:
                    _.typeText = "还价"
                    break
                case 34:
                    _.typeText = "还价失败"
                    break
                default:
                    _.typeText = '其他所得';
                    break;
            }
            switch (Number(_.balance_type)) {
                case 1:
                    _.balance_type = '[MOBI余额]';
                    break;
                case 2:
                    _.balance_type = '[其他余额]';
                    break;
                case 3:
                    _.balance_type = '[求购锁定余额]';
                    break;
                default:
                    break;
            }
            return _
        }))
    }
    useEffect(_ => {
        if (balanceType) {
            isInit ? upDateUserAssets() : setInit(true)
            getFlowRecord({ balanceType, page: curPage }).then(_ => {})
        }
        // eslint-disable-next-line
    }, [ balanceType, curPage ])
    
    return (
        <div className="flowrecord">
            <antdUI.Select style={ { marginBottom: '12px', width: 140 } } value={ balanceType }
                           placeholder={ '余额类型' }
                           onChange={ val => {
                               setBalanceType(val);
                               setCurPage(1)
                           } }>
                <antdUI.Select.Option value={ 1 }>MOBI余额</antdUI.Select.Option>
                <antdUI.Select.Option value={ 3 }>求购锁定余额</antdUI.Select.Option>
            </antdUI.Select>
            <antdUI.Table
                pagination={ {
                    pageSize, total, showSizeChanger: false,
                    onChange: page => setCurPage(page),
                    current: curPage
                } }
                dataSource={ dataSource } columns={ columns }>
            </antdUI.Table>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        };
    }
)(Flowrecord);
