import React, { $api, $CodeLogin, $tools, $toReal, antdUI, useEffect, useState } from 'react';
import './scss/withdraw.scss';
import { connect } from "react-redux";
import { upDateUserAssets } from '@/store/actions/userInfo'
import BIN from 'bankcardinfo'

const promptData = [
    { text: '早鸟期，元旦，春季期间所有用户任意交易金额的交易费率统一低至0.75%，提现手续费低至0%', color: "orange" },
    { text: '提现小提示' },
    { text: '1.由于每笔充值提现，支付宝和银行都会根据金额大小按比例收取手续费，所以提现将产生1%手续费。' },
    { text: '2.提现服务费为1%，最低0.1元，向上取整。单笔提现限额为1-10000元，每天最多提现3次。' },
    { text: '3.用户出售所得为可提现余额，只有可提现余额才能提现至用户支付宝或者银行储蓄卡。' },
    { text: "4.用户用于求购的金额，会在用户求购锁定余额中。" },
    { text: "5.提现到账时间根据支付宝，银行决定。" },
];

const typeData = [
    { type: 'zfb', icon: require('@/images/profile/txzhifubao@2x.png') },
    { type: 'bank', icon: require('@/images/profile/txyinlian@2x.png') },
];

// const tj = require('@/images/profile/tuijian@2x.png')
function tabItem({ type, text }) {
    let _st = { textAlign: 'center' }
    switch (type) {
        case 'amount':
            _st.color = '#2EB062';
            break;
        case 'state':
            switch (text) {
                case 1:
                    text = ' 提现成功';
                    _st.color = '#2EB062';
                    break;
                case 2:
                    text = ' 正在提现';
                    break;
                case 3:
                    text = ' 提现失败';
                    _st.color = 'red';
                    break;
                default:
                    break;
            }
            
            break;
        case 'withdrawalType':
            switch (text) {
                case 1:
                    text = '【支付宝】';
                    break;
                case 2:
                    text = '【银行卡】';
                    break;
                default:
                    break;
            }
            
            break;
        case 'handlingFee':
            _st.color = '#FF9E40';
            break;
        default:
            break;
    }
    
    return <p style={ _st }>{ text }</p>
}

const columns = [
    {
        title: <p style={ { textAlign: 'center' } }>订单号</p>,
        dataIndex: 'tradeNumber',
        key: 'tradeNumber',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>提现金额(元)</p>,
        dataIndex: 'amount',
        key: 'amount',
        render: text => tabItem({ text, type: 'amount' })
    },
    {
        title: <p style={ { textAlign: 'center' } }>提现手续费(元)</p>,
        dataIndex: 'handlingfee',
        key: 'handlingfee',
        render: text => tabItem({ text, type: 'handlingFee' })
    },
    {
        title: <p style={ { textAlign: 'center' } }>提现时间</p>,
        dataIndex: 'withdrawalTime',
        key: 'withdrawalTime',
        render: text => tabItem({ text })
    },
    {
        title: <p style={ { textAlign: 'center' } }>提现方式</p>,
        dataIndex: 'withdrawalType',
        key: 'withdrawalType',
        render: text => tabItem({ text, type: 'withdrawalType' })
    },
    {
        title: <p style={ { textAlign: 'center' } }>进度</p>,
        dataIndex: 'state',
        key: 'state',
        render: text => tabItem({ text, type: 'state' })
    }
];

function Withdraw(props) {
    const { userAssets, upDateUserAssets, userInfo } = props;
    const [ amount, setAmount ] = useState(0),
        [ handlingFee, setHandleFee ] = useState(0),
        [ rRecord, setrRecord ] = useState(false),
        [ isChange, setIsChange ] = useState(false),
        [ currentType, setType ] = useState('zfb'),   //zfb 支付宝   bank 银行
        [ zfbAccount, setZfbAccount ] = useState(''),
        [ payee, setPayee ] = useState(''),
        [ bankCardNumber, setBankCardNumber ] = useState(''),
        [ bankName, setBankName ] = useState(""),
        [ bank, setBank ] = useState(''),
        [ whLoa, setWhLoa ] = useState(false),
        [ whRecord, setWhRecord ] = useState({ total: 0, items: [] });
    
    const onChangeType = (e) => {
        let _type = e.target.value
        if (Object.keys(userAssets).length !== 0) {
            if (_type === 'zfb') {
                if (! userAssets.zfbAccount || ! userAssets.zfbName) {
                    setIsChange(true)
                } else {
                    setIsChange(false);
                }
            }
            if (_type === 'bank') {
                if (! userAssets.bankCardNumber || ! userAssets.bankName) {
                    setIsChange(true)
                } else {
                    setIsChange(false);
                }
            }
        }
        setType(_type);
        
    }
    
    const inputChange = (e) => { setAmount(e.target.value) }
    
    const computeHandleFee = (amount, fixed) => {
        if (fixed) {
            if (amount === 0) return
            let _amount
            if (amount < 10) {
                _amount = Math.round((amount - 0.1) * 100) / 100
            } else {
                _amount = ~~(amount * 99) / 100
            }
            setHandleFee(Math.round((amount - _amount) * 100) / 100);
        }
    }
    useEffect(() => {
        computeHandleFee(amount); // 计算提现手续费
    }, [ amount ])
    
    
    const saveAccountHandle = async (val) => {
        if (! val) {
            setIsChange(true);
        } else {
            if (! zfbAccount || ! payee) {
                antdUI.message.warning('请将支付宝账号或收款人信息填写完整！');
                return;
            }
            const res = await $api.pay.changeWithdrawAccount({
                type: currentType,
                zfbAccount: zfbAccount,
                zfbName: payee
            })
            if ($tools.checkResult(res)) {
                upDateUserAssets()
                antdUI.message.success('已保存！');
            }
            setIsChange(false);
        }
    }
    
    const saveBankHandle = async (val) => {
        if (! val) {
            setBank("");
            setIsChange(true);
        } else {
            if (! bankCardNumber || ! bankName) {
                antdUI.message.warning('请将银行卡号或收款人信息填写完整！');
                return;
            }
            const res = await $api.pay.changeWithdrawAccount({
                type: currentType, bankCardNumber, bankName
            })
            if ($tools.checkResult(res)) {
                upDateUserAssets()
                antdUI.message.success('已保存！');
            }
            setIsChange(false);
        }
    }
    
    const withdrawHandle = async () => {
        if (! userInfo.uid) return antdUI.message.warning('请先完善绑手机绑定！');
        if (userInfo?.loginType !== 'code') {
            antdUI.message.warning('您必须使用手机验证码登录，才可进行当前操作！')
            return $CodeLogin()
        }
        if (! userInfo.real_name_status) {
            $toReal()
            return antdUI.message.warning('请先完成实名认证！');
        }
        setWhLoa(true)
        const res = await $api.pay.widthDrawAccount({
            amount: amount, type: currentType
        })
        setWhLoa(false)
        if ($tools.checkResult(res)) {
            if (currentType === 'bank') {
                antdUI.message.success('提现正在处理，预计2小时内到账！');
            } else {
                antdUI.message.success('提现发起成功！');
            }
            upDateUserAssets();
            setAmount(0)
        }
    }
    
    const getWithdrawRecord = async (page = 1) => {
        let result = await $api.user.withdrawRecord({ page, pageSize: 10 })
        if ($tools.checkResult(result)) {
            setWhRecord({
                total: result.data.total, items: result.data.items.map((_, i) => {
                    _.key = i;
                    return _
                })
            })
        }
    }
    
    function getFillIn() {
        if (currentType === 'bank') {
            return (
                <div className="yl-fill">
                    <div className="has-fill">
                        <div className="fill-item" style={ { width: "25%" } }>
                            <span>银行:</span><p>{ bank }</p>
                        </div>
                        <div className="fill-item" style={ { width: "40%" } }>
                            <span>卡号:</span>
                            {
                                isChange ? <antdUI.Input
                                        onChange={ e => setBankCardNumber(String(e.target.value).replace(/\s+/g, '')) }
                                        value={ bankCardNumber }
                                        style={ { width: "200px" } }
                                        onBlur={ () => {
                                            BIN.getBankBin(bankCardNumber).then(function (data) {
                                                if (data && data.bankName) setBank(data.bankName)
                                            }).catch(function (err) { })
                                        } }
                                    ></antdUI.Input>
                                    : <p>{ userAssets.bankCardNumber || bankCardNumber }</p>
                            }
                        </div>
                        <div className="fill-item" style={ { width: "25%" } }>
                            <span>收款人:</span>
                            {
                                isChange ? <antdUI.Input style={ { width: "110px" } } value={ bankName }
                                                         onChange={ e => setBankName(e.target.value) }></antdUI.Input>
                                    : <p>{ userAssets.bankName || bankName }</p>
                            }
                        </div>
                        <span onClick={ () => saveBankHandle(isChange) }>{ isChange ? '保存' : '修改' }</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="zfb-fill">
                    <div className="has-fill">
                        <div className="fill-item">
                            <span>支付宝账号：</span>
                            {
                                isChange ?
                                    <antdUI.Input onChange={ e => setZfbAccount(e.target.value) }
                                                  value={ zfbAccount }></antdUI.Input>
                                    : <p>{ userAssets.zfbAccount || zfbAccount }</p>
                            }
                        </div>
                        <div className="fill-item">
                            <span>收款人：</span>
                            {
                                isChange ? <antdUI.Input value={ payee }
                                                         onChange={ e => setPayee(e.target.value) }></antdUI.Input>
                                    : <p>{ userAssets.zfbName || payee }</p>
                            }
                        </div>
                        <span onClick={ () => saveAccountHandle(isChange) }>{ isChange ? '保存' : '修改' }</span>
                    </div>
                </div>
            )
        }
    }
    
    useEffect(() => {
        if (Object.keys(userAssets).length !== 0) {
            if (userAssets.bankCardNumber) {
                BIN.getBankBin(userAssets.bankCardNumber).then(function (data) {
                    if (data && data.bankName) setBank(data.bankName)
                }).catch(function (err) { })
            }
            if (currentType === 'zfb') {
                if (! userAssets.zfbAccount || ! userAssets.zfbName) setIsChange(true)
            }
            if (currentType === 'bank') {
                if (! userAssets.bankCardNumber || ! userAssets.bankName) setIsChange(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userAssets ])
    
    return (
        <div className="withdraw">
            <div className="withdraw-amount">
                <div className="withdraw-line">
                    <div className="line-left" style={ { marginTop: '-55px' } }>
                        收款账户
                    </div>
                    <div className="line-right" style={ { display: 'block' } }>
                        <antdUI.Radio.Group onChange={ onChangeType } className="flex" value={ currentType }>
                            {
                                typeData.map((v, i) => {
                                    return (
                                        <antdUI.Radio value={ v.type } className="flex ait"
                                                      style={ { marginLeft: "15px" } }
                                                      key={ v.type }>
                                            <div className="type-item">
                                                <img className="icon-type" src={ v.icon } alt={ v.type }/>
                                                { v.type === 'zfb' ? <span className="icon-zfb-tj">推荐</span> : "" }
                                            </div>
                                        
                                        </antdUI.Radio>
                                    )
                                })
                            }
                        </antdUI.Radio.Group>
                        <div className="yl-con">
                            { getFillIn() }
                        </div>
                    </div>
                </div>
                <div className="withdraw-line">
                    <div className="line-left">
                        提现金额
                    </div>
                    <div className="line-right">
                        <antdUI.Input
                            onBlur={ _ => {
                                const val = Math.round(_.target.value * 100) / 100
                                setAmount(val)
                            } }
                            value={ amount } min="0" type="number"
                            onChange={ inputChange } style={ { width: '246px' } } placeholder="请输入提现金额"
                        ></antdUI.Input>
                        <span
                            style={ { marginLeft: '20px' } }>可提现余额￥{ Number(userAssets.withdrawalAmount) > userAssets.alipay_amount ?
                            userAssets.alipay_amount : userAssets.withdrawalAmount }（单笔限额￥10000）</span>
                    </div>
                </div>
            </div>
            
            <div className="withdraw-operating">
                <div className="op-fee">
                    <p className="title">提现手续费 <span>￥{ handlingFee.toFixed(2) }</span></p>
                    <p className="title">实际到账 <span>￥{ Math.round((amount - handlingFee < 0 ? 0 : amount - handlingFee) * 100) / 100 }</span>
                    </p>
                </div>
                
                <antdUI.Button
                    loading={ whLoa }
                    type="primary" style={ { marginTop: '20px' } }
                    onClick={ async () => { await withdrawHandle() } }>确认提现
                </antdUI.Button>
            </div>
            
            <div
                className="withdraw-prompt">
                { promptData.map((v, i) => <p key={ i } style={ { color: v.color } }
                                              className="prompt-v">{ v.text }</p>) }
            </div>
            
            <div onClick={ () => {
                getWithdrawRecord();
                setrRecord(true);
            } } className="withdraw-link">
                提现记录
                <i className="iconfont icon-arrow-right"></i>
                <i className="iconfont icon-arrow-right"></i>
            </div>
            
            
            <antdUI.Modal
                title="提现记录"
                maskClosable={ false }
                visible={ rRecord }
                footer={ false }
                onCancel={ () => setrRecord(false) }
                width="60%"
            >
                <antdUI.Table pagination={ {
                    pageSize: 10, showSizeChanger: false, total: whRecord.total,
                    onChange: (page) => {
                        getWithdrawRecord(page)
                    }
                } }
                              dataSource={ whRecord.items }
                              columns={ columns }>
                </antdUI.Table>
            </antdUI.Modal>
        
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(Withdraw);
