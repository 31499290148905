import React, { useEffect } from 'react';
import { InventoryNav, InventoryContainer } from '@/components/mySale'
import './index.scss'
import { connect } from "react-redux";

function MySale(props) {
    const { userInfo } = props
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
    }, [ userInfo ])
    return (
        <div className="mySale">
            <InventoryNav/>
            <InventoryContainer/>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(MySale);
