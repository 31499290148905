import React, { $api, $tools, antdUI, useEffect, useState } from "react";
import './index.scss';
import { allCustomItemData, allCustomItemDataTree } from '@/data/replacingAnalogData.js'
import AnalogResultBox from "../Container/replacingAnalog/items/analogReBox.js";
import { connect } from "react-redux";
import FormulaContainsItems from '../ContainsItems'
import CommentsSection from './comments.js'
import sd from 'silly-datetime'
import BASEURL from '@/utils/base_url.js'

const { fetchUrl } = BASEURL
const detailNavData = [
    { title: '配方', value: 'formula' },
    { title: '评论', value: 'comments' }
]


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => resolve(reader.result);
        
        reader.onerror = (error) => reject(error);
    });

function FormulaDetail(props) {
    const wearData = React.$wearData2
    const { userInfo, updateCollectionData, formulaCollectionData } = props;
    const { getUrlQueryValue, checkResult, debounce } = $tools
    const locaHref = window.location.href.match(/\?id=(\S+)/g)?.[0]
    const formulaId = getUrlQueryValue('id', locaHref)
    const detailType = getUrlQueryValue('type', locaHref)
    const [ nav, setNav ] = useState('formula')
    const [ formulaData, setFormulaData ] = useState({})
    const [ tipsShow, setTipsShow ] = useState({ view: false });
    const [ isCol, setIsCol ] = useState(false);
    const { Collapse, Modal, Upload } = antdUI, { Panel } = Collapse;
    const [ containsItemsShow, setConItemsShow ] = useState(false)
    const [ boxValue, setBoxValue ] = useState('')
    
    const [ commentsTotal, setCommentsTotal ] = useState(0);
    const [ comContent, setComContent ] = useState('')
    const [ sendComLoading, setSendComLoading ] = useState(false)
    const [ commentsData, setCommentsData ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ curPage, setCurPage ] = useState(1)
    
    const [ changeTarget, setChangeTarget ] = useState({})
    const [ curIdx, setCurIdx ] = useState("")
    const [ open, setOpen ] = useState(false);
    const [ commentChild, setCommentChild ] = useState([])
    
    const [ praiseLoading, setPraiseLoading ] = useState(false)
    
    const getFormulaData = async _formulaId => {
        const result = await $api.formula.getFormulaDetail({ id: _formulaId || formulaId })
        if (checkResult(result)) {
            setFormulaData(result.data)
            setCommentsTotal(result.data.commentsCounts)
        }
    }
    useEffect(() => {
        (async _ => getFormulaData())()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const changePraise = async () => {
        if (praiseLoading) return
        setPraiseLoading(true)
        setTimeout(_ => setPraiseLoading(false), 2000)
        const result = await $api.formula.changePraise({ fid: formulaId })
        if (! checkResult(result)) return
        const { data = {} } = result
        const { isPraise } = data
        if (isPraise) {
            antdUI.message.success('点赞成功')
            setFormulaData({ ...formulaData, praise: formulaData.praise + 1, isPraise: true })
        } else {
            antdUI.message.success('取消点赞')
            setFormulaData({ ...formulaData, praise: formulaData.praise - 1, isPraise: false })
        }
    }
    
    
    const formulaState = async state => {
        const result = await $api.formula.formulaState({ status: state, id: formulaId });
        
        if (checkResult(result)) {
            antdUI.message.success(`${ ! state ? '取消' : '' }发布成功`)
            setTipsShow({ view: false })
            await getFormulaData()
        }
    }
    const collectionFormula = async _isCol => {
        const result = await $api.formula.collectionFormula({ id: formulaId, type: _isCol ? 'cancel' : 'collection' })
        if (checkResult(result)) {
            antdUI.message.success(`${ _isCol ? '取消' : '' }收藏成功！`)
            await updateCollectionData()
            setTipsShow({ view: false })
        }
    }
    const copyFormula = async () => {
        const result = await $api.formula.copyFormula({ id: formulaId })
        if (checkResult(result)) {
            const _formulaId = result?.data?.formulaId
            antdUI.message.success(`复制成功！`)
            setTipsShow({ view: false })
            await getFormulaData(_formulaId)
            window.location.href = `/formulaSquare#detail?id=${ _formulaId }&type=main`
        }
    }
    const deleteFormula = async () => {
        const result = await $api.formula.deleteFormula({ id: formulaId });
        
        if (checkResult(result)) {
            antdUI.message.success(`删除成功`)
            switch (detailType) {
                case 'square':
                    return window.location.href = '/formulaSquare#square'
                case 'main':
                    return window.location.href = '/formulaSquare#main'
                default:
                    break;
            }
        }
    }
    
    const pageSize = 10
    const formulaCommentsSend = async () => {
        const content = comContent
        if (! content.replace(/\s+/g, '')) return antdUI.message.warning('请输入评论内容！')
        const commentsPData = { fid: formulaId, content }
        const avatar = userInfo?.steam?.avatarfull
        const nickname = userInfo?.nickname
        let result
        setSendComLoading(true)
        let file
        if (! changeTarget?.fCid) {
            fileList[0] && (file = fileList[0])
            const formData = new FormData()
            formData.append("fid", formulaId)
            formData.append("content", content)
            file && (formData.append("file", fileList[0].originFileObj))
            result = await $api.formula.formulaCommentsSend(formData)
        } else {
            if (changeTarget?.fCid) commentsPData.fCid = changeTarget.fCid
            if (changeTarget?.fCCid) commentsPData.fCCid = changeTarget.fCCid
            result = await $api.formula.formulaCommentsSend(commentsPData)
        }
        setSendComLoading(false)
        setComContent('');
        if (checkResult(result)) {
            antdUI.message.success('发布评论成功！')
            setFileList([])
            setCommentsData([])
            setCommentsTotal(commentsTotal + 1)
            const _data = JSON.parse(JSON.stringify(commentsData))
            const addMsg = {
                avatar, content, createTime: sd.format(new Date()), isMe: true, nickname,
                id: result.data.commentId
            }
            let list
            if (changeTarget?.fCid) {
                addMsg.fCid = changeTarget?.fCid
                if (changeTarget?.fCCid) {
                    addMsg.fCCid = changeTarget?.fCCid
                    addMsg.target = { nickname: changeTarget?.nickname }
                    _data.forEach(item => {
                        if (item.id === changeTarget.fCid) {
                            if (item?.commentsChild?.[0]) {
                                list = JSON.parse(JSON.stringify(item.commentsChild))
                                item.commentsChild.forEach((_, idx) => {
                                    if (_.id === changeTarget?.fCCid) list.splice(idx + 1, 0, addMsg)
                                })
                                setCommentChild(list)
                                item.commentsChild = list
                            } else {
                                item.commentsChild = [ addMsg ] // 此处不可能存在打开了评论详情 但 评论发布成功成为该详情 "第一条" 的情况 所以不用 setCommentChild
                            }
                        }
                    })
                } else {
                    _data.forEach(item => {
                        if (item.id === changeTarget.fCid) {
                            if (item?.commentsChild?.[0]) {
                                list = JSON.parse(JSON.stringify(item.commentsChild)) || []
                                list.unshift(addMsg)
                                setCommentChild(list)
                                item.commentsChild = list
                            } else {
                                item.commentsChild = [ addMsg ] // 此处不可能存在打开了评论详情 但 评论发布成功成为该详情 "第一条" 的情况 所以不用 setCommentChild
                            }
                        }
                    })
                }
            } else {
                file && (addMsg.picture = file.thumbUrl)
                _data.unshift(addMsg)
            }
            setCommentsData(_data)
            setChangeTarget({})
            setCurIdx('')
        }
    }
    const getFormulaCommentsData = async page => {
        setTotal(0)
        setCommentsData([])
        setChangeTarget({})
        setCurIdx('')
        const result = await $api.formula.formulaComments({ fid: formulaId, pageSize, curPage: page || curPage })
        if (checkResult(result)) {
            setCommentsData(result.data.data)
            setTotal(result.data.total)
            setCommentsTotal(result.data.allCount)
        }
    }
    const changePage = async (currentPage) => {
        setCurPage(currentPage)
        await getFormulaCommentsData(currentPage)
    }
    
    const [ previewOpen, setPreviewOpen ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');
    const [ fileList, setFileList ] = useState([])
    const handleCancel = () => setPreviewOpen(false);
    
    const handlePreview = async (file) => {
        if (! file.url && ! file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    
    const handleChange = ({ fileList: newFileList }) => {
        const file = newFileList?.[0]
        if (! file) return
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (isJpgOrPng && isLt10M) setFileList(newFileList)
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (! isJpgOrPng) antdUI.message.error('您只能上传 JPG/PNG 格式的图片!');
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (! isLt10M) antdUI.message.error('图片太大，请上传小于10MB的图片!');
        return isJpgOrPng && isLt10M;
    };
    const uploadButton = (
        <div>
            <i className="iconfont icon-web-switch"></i>
            <div style={ { marginTop: 8 } }>上传文件</div>
        </div>
    );
    
    useEffect(() => {
        if (! formulaCollectionData[0]) setIsCol(false)
        if (formulaCollectionData[0] && formulaData.id) {
            if (formulaCollectionData.filter(_ => Number(_.formulaId) === Number(formulaData.id))?.[0]) {
                setIsCol(true)
            } else {
                setIsCol(false)
            }
        }
    }, [ formulaCollectionData, formulaData ])
    
    useEffect(() => {
        if (nav === 'comments') {
            getFormulaCommentsData().then()
        } else {
            setComContent('')
            setCurPage(1)
            setTotal(0)
            setCommentsData([])
            setChangeTarget({})
            setCurIdx('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ nav ])
    return (
        <div className="formula-detail-container">
            { formulaData.id ? <>
                    <div className="top">
                        {
                            (() => {
                                let list
                                switch (detailType) {
                                    case 'square':
                                        list = (<div className="top-con">
                                            <h3 onClick={ _ => window.location.href = 'formulaSquare#square' }
                                                className="curpo">配方广场</h3>
                                            <i className="iconfont icon-arrow-right"></i>
                                            <h3>配方详情</h3>
                                        </div>);
                                        break;
                                    case 'main':
                                        list = (<div className="top-con">
                                            <h3 onClick={ _ => window.location.href = '/formulaSquare#main' }
                                                className="curpo">我的配方</h3>
                                            <i className="iconfont icon-arrow-right"></i>
                                            <h3>配方详情</h3>
                                        </div>);
                                        break;
                                    case 'collection':
                                        list = (<div className="top-con">
                                            <h3 onClick={ _ => window.location.href = '/formulaSquare#collection' }
                                                className="curpo">配方收藏</h3>
                                            <i className="iconfont icon-arrow-right"></i>
                                            <h3>配方详情</h3>
                                        </div>);
                                        break;
                                    default:
                                        break;
                                }
                                return list
                            })()
                        }
                    </div>
                    { detailType === 'square' ? <div className="detail-nav">
                        {
                            detailNavData.map((_, key) => {
                                return (<div key={ key } onClick={ () => setNav(_.value) }
                                             className={ [ _.value === nav ? 'nav-item current' : 'nav-item' ] }>
                                    { _.title }{ _.value === 'comments' ? commentsTotal > 0 ? `(${ commentsTotal })` : '' : '' }
                                </div>)
                            })
                        }
                    </div> : '' }
                    {
                        nav === 'formula' && <>
                            <div className="formula-title">
                                <h1>{ formulaData.formulaName }</h1>
                                <div className="title-info flex ait">
                                    <div className="title-info-box flex ait">
                                        { formulaData?.UserSteam?.avatarfull ?
                                            <img src={ formulaData?.UserSteam?.avatarfull } alt=""/>
                                            : <img className="avatar-icon"
                                                   src={ require('@/images/home/moren touxiang@2x.png') } alt=""/> }
                                        <h4 className="text-ov1">{ formulaData?.user?.nickname }</h4>
                                        <p className="update-time">{ formulaData.updateTime }</p>
                                        <div onClick={ $tools.debounce(changePraise, 2000, true) }
                                             style={ { cursor: 'pointer', color: formulaData.isPraise && '#2DAF61' } }><i
                                            className="iconfont icon-dianzan"></i>&nbsp;{ formulaData.praise }
                                            &nbsp;  &nbsp;
                                        </div>
                                        
                                        <i className="iconfont icon-31pinglun"></i>&nbsp;{ formulaData.commentsCounts }
                                    </div>
                                    <div className="title-info-btn">
                                        <button className="btn-item"
                                                onClick={ _ => setTipsShow({ view: true, type: 'copy' }) }>复制
                                        </button>
                                        { (detailType === 'main' || userInfo.uid === formulaData.uid) && <>
                                            <button className="btn-item"
                                                    onClick={ _ => setTipsShow({ view: true, type: 'delete' }) }>删除
                                            </button>
                                            <button className="btn-item"
                                                    onClick={ _ => window.location.href = `/formulaSquare#replacingAnalog?id=${ formulaData.id }` }>编辑
                                            </button>
                                        </> }
                                        { (detailType === 'main' || userInfo.uid === formulaData.uid) ? formulaData.state ?
                                            <button className="btn-item"
                                                    onClick={ _ => setTipsShow({
                                                        view: true,
                                                        type: 'cancelRelease'
                                                    }) }>取消发布</button> :
                                            <button className="btn-item release"
                                                    onClick={ _ => setTipsShow({
                                                        view: true, type: 'release'
                                                    }) }> 发布</button> : '' }
                                        { [ 'square', 'collection' ].indexOf(detailType) !== -1 && userInfo.uid !== formulaData.uid &&
                                            <div className="formula-collection flex ait">
                                                <i onClick={ _ => setTipsShow({
                                                    view: true,
                                                    type: isCol ? 'cancelCollection' : 'collection'
                                                }) } className="iconfont icon-shixinxingxing"
                                                   style={ { color: isCol && '#FFCD1F' } }
                                                > </i>
                                                <h4>{ `${ isCol ? '已' : '' }收藏` }</h4>
                                            </div> }
                                    </div>
                                </div>
                            </div>
                            <Collapse defaultActiveKey={ [ '1', '2' ] } bordered={ false }>
                                <Panel header={ <h4 className="material-t">材料（{ formulaData?.formulaMaterial?.length }/10）
                                    预计成本 <span
                                        style={ { color: '#EEA20E' } }>
                                        {
                                            (_ => {
                                                const priceList = formulaData?.formulaMaterial?.map(_ => _.price)
                                                if (priceList.filter(_ => _)?.length !== 10) return ""
                                                let totalPrice = 0
                                                priceList.reduce((a, b) => {
                                                    totalPrice = $tools.Sum(a, b)
                                                    return totalPrice
                                                })
                                                return `¥${ totalPrice }`
                                            })()
                                        }
                                        
                                        
                                    </span>
                                    <i className="iconfont icon-arrow-left"></i>
                                </h4> } showArrow={ false } key="1">
                                    <div className="formula-material-container">
                                        {
                                            formulaData.formulaMaterial?.[0] && (() => {
                                                let list = [], index, material = [];
                                                const { quality } = formulaData
                                                formulaData.formulaMaterial.forEach(item => {
                                                    material.push({
                                                        ...allCustomItemData.filter(_ => Number(_.itemid) === Number(item.id))[0],
                                                        wearValue: item.value,
                                                        price: item.price
                                                    })
                                                })
                                                material.forEach((item, key) => {
                                                    index = list.map(_ => _.box_value).indexOf(item.target_box)
                                                    if (index === -1) {
                                                        list.push({
                                                            box_value: item.target_box,
                                                            name: allCustomItemDataTree.filter(_ => item.target_box === _.box_value)?.[0]?.name,
                                                            children: [ { ...item, key } ]
                                                        })
                                                    } else {
                                                        list[index].children.push({ ...item, key })
                                                    }
                                                })
                                                return <>{
                                                    list.map((item, id) => {
                                                        return (
                                                            <div className="material-box-item" key={ id }>
                                                                <h3 className="flex ait" onClick={ () => {
                                                                    setBoxValue(item.box_value)
                                                                    setConItemsShow(true)
                                                                } }
                                                                    value={ item.box_value }>{ item.name }（{ item.children.length }）
                                                                    <i className="iconfont icon-arrow-right"></i>
                                                                </h3>
                                                                <div className="material-box-item-container">
                                                                    { item.children.map(_ => {
                                                                        let tWear
                                                                        wearData.forEach((w, idx, arr) => {
                                                                            if (Number(_.wearValue) <= w.ratio && idx === 0) tWear = w
                                                                            if (Number(_.wearValue) >= w.ratio && Number(_.wearValue) <= arr[idx + 1].ratio) tWear = arr[idx + 1]
                                                                        })
                                                                        return (
                                                                            <div style={ { marginBottom: '8px' } }
                                                                                 key={ _.key }>
                                                                                <AnalogResultBox
                                                                                    quality={ quality }
                                                                                    itemData={ { ..._, ...tWear } }
                                                                                    type={ "detail" }
                                                                                />
                                                                                <div className="reAnCustom-item-box">
                                                                                    <p
                                                                                        title={ quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                                        className="reAnCustom-item-name">
                                                                                        { quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                                    </p>
                                                                                    { _.price ? <h3 className="price"
                                                                                                    style={ {
                                                                                                        color: '#eea20e',
                                                                                                        fontWeight: '700',
                                                                                                        fontSize: '14px'
                                                                                                    } }>¥{ _.price }</h3> : '' }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) }</div>
                                                            </div>
                                                        )
                                                    })
                                                }</>
                                            })()
                                        }
                                    </div>
                                </Panel>
                                <Panel header={ <h4 className="analog-result-t">汰换模拟结果
                                    <i className="iconfont icon-arrow-left"></i>
                                </h4> } showArrow={ false } key="2">
                                    <div className="analog-result-container-box">
                                        {
                                            formulaData.formulaAnalogResult?.[0] && (() => {
                                                let list = [], it,
                                                    AnalogResult = JSON.parse(JSON.stringify(formulaData.formulaAnalogResult));
                                                const { quality } = formulaData
                                                AnalogResult.forEach(item => {
                                                    list = allCustomItemDataTree.filter(_ => item.box_value === _.box_value)[0]
                                                    item.name = list.name
                                                    item.children.forEach((el, i, arr) => {
                                                        it = allCustomItemData.filter(_ => Number(_.itemid) === Number(el.id))[0]
                                                        if (it) arr[i] = { ...it, wearValue: el.value, price: el.price }
                                                    })
                                                })
                                                return AnalogResult.map((item, i) => {
                                                    return (
                                                        <div className="material-box-item" key={ i }>
                                                            <h3 className="flex ait" onClick={ () => {
                                                                setBoxValue(item.box_value)
                                                                setConItemsShow(true)
                                                            } }
                                                                value={ item.box_value }>{ item.name }（{ item.children.length }）
                                                                <i className="iconfont icon-arrow-right"></i>
                                                            </h3>
                                                            <div className="material-box-item-container">
                                                                { item.children.map((_, i) => {
                                                                    let tWear
                                                                    wearData.forEach((item, idx, arr) => {
                                                                        if (Number(_.wearValue) <= item.ratio && idx === 0) tWear = item
                                                                        if (Number(_.wearValue) >= item.ratio && Number(_.wearValue) <= arr[idx + 1].ratio) tWear = arr[idx + 1]
                                                                    })
                                                                    return (
                                                                        <div
                                                                            className={ [ formulaData.name === _.name ? 'analog-result-box-b current' : 'analog-result-box-b' ] }
                                                                            
                                                                            style={ { marginBottom: '8px' } } key={ i }>
                                                                            <AnalogResultBox
                                                                                quality={ quality }
                                                                                itemData={ {
                                                                                    ..._, ...tWear,
                                                                                    chance: item.chance
                                                                                } }
                                                                            />
                                                                            <div className="reAnCustom-item-box"
                                                                                 style={ { margin: '2px auto' } }>
                                                                                <p
                                                                                    title={ quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                                    className="reAnCustom-item-name">
                                                                                    { quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                                </p>
                                                                                { _.price ? <h3 className="price"
                                                                                                style={ {
                                                                                                    color: '#eea20e',
                                                                                                    fontWeight: '700',
                                                                                                    fontSize: '14px'
                                                                                                } }>¥{ _.price }</h3> : '' }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) }</div>
                                                        </div>
                                                    )
                                                })
                                            })()
                                        }
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                title={ "提示" } maskClosable={ false } visible={ tipsShow.view }
                                cancelText="取消" width="400px"
                                okText={ (_ => {
                                    switch (tipsShow.type) {
                                        case 'copy':
                                            return '复制'
                                        case 'delete':
                                            return '删除'
                                        case 'release':
                                            return '发布'
                                        case 'cancelRelease':
                                            return '取消发布'
                                        case 'collection':
                                            return '收藏'
                                        case 'cancelCollection':
                                            return '取消收藏'
                                        default:
                                            return ''
                                    }
                                })() }
                                onOk={ (_ => {
                                    switch (tipsShow.type) {
                                        case 'copy':
                                            return debounce(copyFormula.bind(null), 4000, true)
                                        case 'delete':
                                            return debounce(deleteFormula.bind(null), 4000, true)
                                        case 'release':
                                            return debounce(formulaState.bind(null, 1), 4000, true)
                                        case 'cancelRelease':
                                            return debounce(formulaState.bind(null, 0), 4000, true)
                                        case 'collection':
                                            return debounce(collectionFormula.bind(null, isCol), 4000, true)
                                        case 'cancelCollection':
                                            return debounce(collectionFormula.bind(null, isCol), 4000, true)
                                        default:
                                            return function () {}
                                    }
                                })() }
                                onCancel={ () => setTipsShow({ ...tipsShow, view: false }) }
                            >
                                <div className="flex jtyct">
                                    <h2 style={ { fontWeight: '700', padding: "30px 0" } }>{
                                        (_ => {
                                            switch (tipsShow.type) {
                                                case 'copy':
                                                    return '是否确认复制该配方？'
                                                case 'delete':
                                                    return '是否删除该配方？'
                                                case 'release':
                                                    return '是否确认发布配方？'
                                                case 'cancelRelease':
                                                    return '是否确认取消发布？'
                                                case 'collection':
                                                    return '是否确认收藏该配方？'
                                                case 'cancelCollection':
                                                    return '是否确认取消收藏该配方？'
                                                default:
                                                    return ''
                                            }
                                        })()
                                    }</h2>
                                </div>
                            </Modal>
                        </>
                    }
                    
                    {
                        nav === 'comments' && <>
                            <div className="formula-comments-container"
                                 style={ {
                                     position: (open && curIdx) ? 'fixed' : 'sticky',
                                     marginTop: (open && curIdx) && '26px',
                                     zIndex: (open && curIdx) && '1001',
                                     paddingBottom: curIdx && 0
                                 } }>
                                { curIdx && <i title="取消回复" className="iconfont icon-cha" onClick={ () => {
                                    setCurIdx("")
                                    setChangeTarget({})
                                } }></i> }
                                { curIdx && <span>回复 { changeTarget.nickname }</span> }
                                <antdUI.Input.TextArea allowClear
                                                       value={ comContent } showCount autoSize maxLength={ 300 }
                                                       onChange={ _ => setComContent(_.target.value) }
                                                       placeholder={ changeTarget.nickname ? `回复@${ changeTarget.nickname }` : "留下你的精彩评论吧" }/>
                                { ! curIdx && <Upload
                                    maxCount={ 1 } beforeUpload={ beforeUpload }
                                    accept="image/png, image/jpeg"
                                    action={ `${ fetchUrl }/formula/comment/upload/` }
                                    listType="picture-card" fileList={ fileList }
                                    onPreview={ handlePreview }
                                    onChange={ handleChange }>
                                    { fileList.length >= 1 ? null : uploadButton }
                                </Upload> }
                                <Modal visible={ previewOpen } title={ previewTitle } footer={ null }
                                       onCancel={ handleCancel }>
                                    <img alt="example"
                                         style={ { width: '100%', } }
                                         src={ previewImage }/>
                                </Modal>
                                <antdUI.Button onClick={ formulaCommentsSend } loading={ sendComLoading }
                                               type="primary" shape="round">发布
                                </antdUI.Button>
                            </div>
                            <div className="formula-comments-section-container">
                                <div className="title flex ait">精彩评论</div>
                                
                                <CommentsSection cur={ { curIdx, setCurIdx } } _open={ { open, setOpen } }
                                                 setData={ setCommentsData }
                                                 comTotal={ { commentsTotal, setCommentsTotal } }
                                                 drawerData={ { commentChild, setCommentChild } }
                                                 target={ { changeTarget, setChangeTarget } } data={ commentsData }/>
                            </div>
                            { total > 0 && <div className="pagination flex jtyct" style={ { margin: "30px auto 0" } }>
                                <antdUI.Pagination
                                    showSizeChanger={ false } showQuickJumper={ true }
                                    onChange={ (currentPage, pageSize) => changePage(currentPage, pageSize) }
                                    hideOnSinglePage={ true } current={ curPage } defaultCurrent={ 1 }
                                    total={ total } pageSize={ pageSize }/>
                            </div> }
                        </>
                    }
                </> :
                <antdUI.Skeleton active/> }
            { containsItemsShow ? <FormulaContainsItems
                boxValue={ boxValue }
                showOp={ {
                    show: containsItemsShow,
                    setShow: setConItemsShow
                } }/> : '' }
        </div>
    )
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(FormulaDetail);
