import React from 'react';
import './index.scss'
import RollRoomDetailContent from '@/components/rollRoom/detail.js';

function RollRoom() {
    return (
        <div className="Roll-room">
            <img
                style={ { width: '100%', position: 'absolute', top: 0, zIndex: 1 } }
                className="tranding-market-banner"
                src={ require("@/images/rollRoom/roll shouye beijing@2x.png") }
                alt=""
            />
            <div className="Roll-room-content-container">
                <RollRoomDetailContent></RollRoomDetailContent>
            </div>
        </div>
    )
}

export default RollRoom;
