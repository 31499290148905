import $api from '@/api'
const SET_USER_INFO = 'SET_USER_INFO'
const CHECK_USER_SIGNIN = 'CHECK_USER_SIGNIN'
const UP_DATE_USER_ASSETS = 'UP_DATE_USER_ASSETS'


function setLocalMobiUid(target) {
  if (target.uid || target.steam.steamid || target.check) {
    localStorage.setItem('mobi_uid', 'check');
  } else { localStorage.setItem('mobi_uid', '') }
}

function checkUser(userInfo = { steam: {} }) {
  !userInfo.steam && (userInfo.steam = {})
  setLocalMobiUid(userInfo)
  let target = { type: CHECK_USER_SIGNIN }
  if (!userInfo.uid && !userInfo.steam.steamid && userInfo.check !== 'success') { target.userInfo = userInfo }
  return target
}

function setUser(userInfo = { steam: {} }) {
  !userInfo.steam && (userInfo.steam = {})

  setLocalMobiUid(userInfo)
  return { type: SET_USER_INFO, userInfo }
}

function upDateAssets(userAssets = {}) {
  return { type: UP_DATE_USER_ASSETS, userAssets }
}

const setUserSignIn = () => dispatch => $api.user.checklogin({ type: "set" }).then(res => dispatch(setUser(res.data)))
const checkUserSignIn = () => dispatch => $api.user.checklogin({ type: 'check' }).then(res => dispatch(checkUser(res.data)))
const upDateUserAssets = () => dispatch => $api.user.getUserAssets().then(res => dispatch(upDateAssets(res.data)))

export { setUserSignIn, checkUserSignIn, upDateUserAssets }