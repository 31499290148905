import React from 'react';
import './index.scss'
import { Doppler, GammaDoppler, _MarbleFade } from '@/data/dopplerData.js'

const steamBase = "https://steamcommunity-a.akamaihd.net/economy/image/";
const wearData = [
    { titles: '崭新出场', title: '崭新', ratio: 0.07, _bcc: 'green', wear: 'WearCategory0' },
    { titles: '略有磨损', title: '略磨', ratio: 0.15, _bcc: '#5cb85c', wear: 'WearCategory1' },
    { titles: '久经沙场', title: '久经', ratio: 0.38, _bcc: '#f0ad4e', wear: 'WearCategory2' },
    { titles: '破损不堪', title: '破损', ratio: 0.45, _bcc: '#d9534f', wear: 'WearCategory3' },
    { titles: '战痕累累', title: '战痕', ratio: 1, _bcc: '#993A38', wear: 'WearCategory4' }
]
const cs_rarityData = [
    { "label": "卓越", "value": "Rarity_Mythical", "color": "#8847ff" },
    { "label": "军规级", "value": "Rarity_Rare_Weapon", "color": "#4B69FF" },
    { "label": "消费级", "value": "Rarity_Common_Weapon", "color": "#B0C3D9" },
    { "label": "受限", "value": "Rarity_Mythical_Weapon", "color": "#8847FF" },
    { "label": "高级", "value": "Rarity_Rare", "color": "#4b69ff" },
    { "label": "隐秘", "value": "Rarity_Ancient_Weapon", "color": "#EB4B4B" },
    { "label": "保密", "value": "Rarity_Legendary_Weapon", "color": "#D32CE6" },
    { "label": "非凡", "value": "Rarity_Ancient", "color": "#eb4b4b" },
    { "label": "工业级", "value": "Rarity_Uncommon_Weapon", "color": "#5E98D9" },
    { "label": "奇异", "value": "Rarity_Legendary", "color": "#d32ce6" },
    { "label": "普通级", "value": "Rarity_Common", "color": "#B0C3D9" },
    { "label": "违禁", "value": "Rarity_Contraband", "color": "#E4AE39" },
    { "label": "探员品质", "value": "Type_CustomPlayer", }
]
const d2_rarityData = [
    { label: "普通", color: "#fff", value: "Rarity_Common" },
    { label: "稀有", color: "#7C8FF5", value: "Rarity_Rare" },
    { label: "不朽", color: "#F2B166", value: "Rarity_Immortal" },
    { label: "传说", color: "#DC5EEA", value: "Rarity_Legendary" },
    { label: "至宝", color: "#9ACC4C", value: "Rarity_Arcana" },
    // { label: "赛季", color: "#3c3", value: "4" }, { label: "远古", color: "#E06B6A", value: "5" },
    { label: "罕见", color: "#82BDFF", value: "Rarity_Uncommon" },
    { label: "神话", color: "#A876F9", value: "Rarity_Mythical" },
]

function handleDoppler(v = {}) {
    if (! v.market_name) return
    let tStyle, itemsStyle;
    if (v.market_name.match(' 多普勒 ')) {
        tStyle = Doppler.children;
    } else if (v.market_name.match(' 伽玛多普勒 ')) {
        tStyle = GammaDoppler.children;
    } else if (v.market_name.match('爪子刀（★） | 渐变大理石 ') || v.market_name.match('刺刀（★） | 渐变大理石 ')
        || v.market_name.match('折叠刀（★） | 渐变大理石 ') || v.market_name.match('穿肠刀（★） | 渐变大理石 ')) {
        tStyle = _MarbleFade.children;
    }
    if (tStyle) {
        if (tStyle[0].value[0] === '[') {
            itemsStyle = tStyle.filter(_ => _.value === v.paintseed)[0]
        } else {
            itemsStyle = tStyle.filter(_ => _.value === Number(v.paintindex))[0]
        }
        return itemsStyle
    } else {
        return ''
    }
}

function handleRarity(list, appid) {
    switch (appid) {
        case 730:
            return cs_rarityData.filter(_ => list?.rarity === _.value)[0]
        case 570:
            return d2_rarityData.filter(_ => list?.rarity === _.value)[0];
        default:
            return {}
    }
}

function PrizeItems({ prizeData, type }) {
    const handleItemData = (item, type) => {
        let list, appid
        let { attribute, prizeType } = item
        if ([ 'prize', 'my' ].indexOf(type) !== -1) attribute = prizeType
        switch (attribute) {
            case 'items':
                list = item?.CsgoItem || item?.Dota2Item;
                appid = Number(item.WhiteList.appid)
                return {
                    itemInfo: {
                        ...list,
                        appid,
                        price: `¥${ list?.price || 0 }`,
                        icon_url: list.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ list.img_name }.png`
                            : steamBase + list?.icon_url,
                        rarity: handleRarity(list, appid),
                        exterior: wearData.filter(_ => list?.exterior === _.wear)[0]
                    }
                };
            case 'token':
                return {
                    itemInfo: {
                        market_name: 'MOBI', price: item.mobiTokens,
                        icon_url: type === 'my' ? require('@/images/rollRoom/mobi@2x.png') : require('@/images/rollRoom/mobi xiao@2x.png')
                    }
                };
            case 'money' :
                return {
                    itemInfo: {
                        market_name: '购物金（不可提现）', price: item.mobiMoney,
                        icon_url: type === 'my' ? require('@/images/rollRoom/buketixianyue@2x.png') : require('@/images/rollRoom/buketixianyue xiao@2x.png')
                    }
                };
            default:
                return {};
        }
    }
    prizeData = prizeData.map(_ => {return { ..._, ...handleItemData(_, type) }})
    return (
        <div className="roll-room-prize-items-container"
             style={ { gridTemplateColumns: type === 'my' && 'repeat(5, 1fr)' } }>
            {
                prizeData.map(({ itemInfo, WhiteList, prizeType, User = {} }, idx) => {
                    const isSpecial = itemInfo ? handleDoppler({ ...itemInfo, ...WhiteList }) : ''
                    itemInfo = itemInfo || {}
                    const appid = Number(itemInfo?.appid)
                    const { exterior, rarity } = itemInfo
                    return (
                        <li key={ idx } className="item" style={ { cursor: type === "my" ? "pointer" : "" } }
                            onClick={ _ => {
                                if (type === "my") {
                                    const fg = {
                                        'items': 730,
                                        'token': 77,
                                        'money': 88
                                    }
                                    localStorage.setItem('cacheGame', fg[prizeType])
                                    localStorage.setItem('cacheIType', 'backpack')
                                    window.location.href = "/inventory"
                                }
                            } }>
                            <div className="item-cover-box"
                                 style={ { height: type === 'my' && '136px' } }>
                                <div className="cover"
                                     style={ { background: appid === 730 && 'linear-gradient(180deg, #353C5A, #6874A0)' } }>
                                    
                                    <div className="cover-top-info" style={ { transform: type === 'my' && 'none' } }>
                                        { exterior &&
                                            <h4 className="exterior"
                                                style={ { background: exterior?._bcc } }>{ type === 'my' ? exterior?.titles : exterior?.title }</h4> }
                                        { rarity &&
                                            <h4 className="rarity"
                                                style={ { color: rarity?.color } }>{ rarity?.label }</h4> }
                                    </div>
                                    { isSpecial &&
                                        <h4 className="special"
                                            style={ { color: isSpecial?.color } }>{ isSpecial.label }</h4> }
                                    <img style={ { width: appid === 730 && 'auto' } } src={ itemInfo.icon_url } alt=""/>
                                </div>
                            </div>
                            <div className="item-text-info">
                                <h4 style={ { width: type === 'my' && '190px' } } title={ itemInfo.market_name }
                                    className="text-ov1">{ itemInfo.market_name }</h4>
                                <p style={ { width: type === 'my' && '190px' } }
                                   className="text-ov1">{ itemInfo.price || 0 }</p>
                            </div>
                            { type === 'prize' && <div className="item-user-info">
                                <img
                                    src={ User?.UserSteam?.avatarfull || require('@/images/home/moren touxiang@2x.png') }
                                    alt=""/>
                                <p className="text-ov1">{ User?.nickname }</p>
                            </div> }
                        </li>
                    )
                })
            }
        </div>)
}

export default PrizeItems;
