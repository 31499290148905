import React, { $login, Component } from 'react';
import HomeBanner from '@/components/home/Banner';
import Introduction from '@/components/home/Introduction';
// import PopularItem from '@/components/home/PopularItem'
import './index.scss'
import { Link } from "react-router-dom";

(async () => {
    const mobi_uid = window?.opener?.localStorage?.mobi_uid
    const pathname = window.location.pathname
    if (mobi_uid || mobi_uid === '') {
        let token, locSearch = window.location.search;
        if (locSearch.match('authorization')) {
            locSearch.replace(/\?authorization=(.*?);/, (_, key) => { token = key })
            window.localStorage.setItem('token', unescape(token))
            window.opener.location.reload();
        }
        if (locSearch.match('bindst')) {
            // window.opener.location.href = window.opener.location.pathname
            window.opener.location.href = "/profile/userConfig"
            window.localStorage.setItem('bindSteam', 1)
        }
        if (! pathname.match('ContactUs')) window.close()
    }
})()

const titleInfoData = [
    {
        icon: require('@/images/home/xueshengdangfuli@2x.png'),
        title: '新手福音',
        text: '全新用户信誉系统，提升发货速率及发货成功率；灵活的阶梯手续费，交易费率低至0.75%，早鸟期，元旦，春节期间交易费率统一低至0.75%，提现手续费低至0%',
        hoverIcon: require('@/images/home/xinshoufuyin @2x.png'),
        btn: { link: "/profile/rate", text: "查看费率" }
    },
    {
        icon: require('@/images/home/jiaoyijizhuanqian@2x.png'),
        title: '魔能(MOE)大地球起飞计划',
        text: '平台一共会发放总计1000万MOE，通过交易和邀请新用户获得MOE用于赚取更多的CSGO饰品。',
        hoverIcon: require('@/images/home/mobidadiqiu @2x.png'),
        btn: { link: "/moeGo", text: "查看计划" }
    },
    {
        icon: require('@/images/home/tuiguangjizhuanqian@2x.png'),
        title: '邀请好友，每月多赚3000元',
        text: '邀请好友注册，可分享好友交易产生的部分手续费，至多可达10%，配合大地球起飞计划双倍起飞。',
        hoverIcon: require('@/images/home/yaoqinghaoyou @2x.png'),
        btn: { link: "/promoted", text: "查看邀请" }
    },
    {
        icon: require('@/images/home/quanxian csgo pingtai@2x.png'),
        title: '一站式全新CSGO交易社区',
        text: '在这你除了可以探索发现你喜欢的饰品，还可以和网友分享有趣的故事，和MOBI一起创造全新元社区(MOBIverse)。',
        hoverIcon: require('@/images/home/shequn @2x.png'),
    }
]

export default class Home extends Component {
    render() {
        if (window.location.href.match('home#login')) {
            setTimeout($login)
            window.history.pushState('', '', '/home')
        }
        return (
            <div className="home">
                <HomeBanner/>
                <div className="home-title-info">
                    {
                        titleInfoData.map((item, key) => {
                            return (
                                <div key={ key } className="info-item-box">
                                    <div className="info-item">
                                        <div className="icon flex ait jtyct">
                                            <img style={ { marginRight: 10 } }
                                                 src={ item.icon } alt=""/>
                                            <h2 style={ { color: '#2DAF61', fontSize: '20px' } }>{ item.title }</h2>
                                        </div>
                                        <div className="text"
                                             style={ { maxWidth: key === 0 ? 350 : 322, margin: "8px auto 0 auto" } }>
                                            <p style={ {
                                                userSelect: "none",
                                                color: '#666666',
                                                fontSize: '14px'
                                            } }>{ item.text }</p>
                                        </div>
                                    </div>
                                    
                                    <div className="item-hove-info">
                                        <div className="icon flex ait jtyct">
                                            <img style={ { marginRight: 10 } }
                                                 src={ item.hoverIcon } alt=""/>
                                            <h2 style={ { color: '#fff', fontSize: '20px' } }>{ item.title }</h2>
                                        </div>
                                        <div className="text"
                                             style={ { maxWidth: key === 0 ? 350 : 322, margin: "8px auto 0 auto" } }>
                                            <p style={ {
                                                userSelect: "none",
                                                color: '#fff',
                                                fontSize: '14px'
                                            } }>{ item.text }</p>
                                        </div>
                                        { item.btn ?
                                            <Link to={ item.btn.link }>
                                                <button className="btn">
                                                    { item.btn.text }
                                                </button>
                                            </Link>
                                            : "" }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Introduction/>
                {/*<PopularItem/>*/ }
            </div>
        )
    }
}
