module.exports = [
    {
        "countryEN": "China",
        "countryCN": "中国",
        "id": "CN",
        "countryCode": "86"
    },
    {
        "countryEN": "Taiwan",
        "countryCN": "中国台湾",
        "id": "TW",
        "countryCode": "886"
    },
    {
        "countryEN": "Hong Kong",
        "countryCN": "中国香港",
        "id": "HK",
        "countryCode": "852"
    },
    {
        "countryEN": "Macau",
        "countryCN": "中国澳门",
        "id": "MO",
        "countryCode": "853"
    },
    {
        "countryEN": "United States",
        "countryCN": "美国",
        "id": "US",
        "countryCode": "1"
    },
    {
        "countryEN": "Canada",
        "countryCN": "加拿大",
        "id": "CA",
        "countryCode": "1"
    },
    {
        "countryEN": "Singapore",
        "countryCN": "新加坡",
        "id": "SG",
        "countryCode": "65"
    },
    {
        "countryEN": "Malaysia",
        "countryCN": "马来西亚",
        "id": "MY",
        "countryCode": "60"
    },
    {
        "countryEN": "Japan",
        "countryCN": "日本",
        "id": "JP",
        "countryCode": "81"
    },
    {
        "countryEN": "South Korea",
        "countryCN": "韩国",
        "id": "KR",
        "countryCode": "82"
    },
    {
        "countryEN": "Vietnam",
        "countryCN": "越南",
        "id": "VN",
        "countryCode": "84"
    },
    {
        "countryEN": "Australia",
        "countryCN": "澳大利亚",
        "id": "AU",
        "countryCode": "61"
    },
    {
        "countryEN": "Afghanistan",
        "countryCN": "阿富汗",
        "id": "AF",
        "countryCode": "93"
    },
    {
        "countryEN": "Albania",
        "countryCN": "阿尔巴尼亚",
        "id": "AL",
        "countryCode": "355"
    },
    {
        "countryEN": "Algeria",
        "countryCN": "阿尔及利亚",
        "id": "DZ",
        "countryCode": "213"
    },
    {
        "countryEN": "Argentina",
        "countryCN": "阿根廷",
        "id": "AR",
        "countryCode": "54"
    },
    {
        "countryEN": "United Arab Emirates",
        "countryCN": "阿拉伯联合酋长国",
        "id": "UAE",
        "countryCode": "971"
    },
    {
        "countryEN": "Azerbaijan",
        "countryCN": "阿塞拜疆",
        "id": "AZ",
        "countryCode": "994"
    },
    {
        "countryEN": "Aruba",
        "countryCN": "阿鲁巴",
        "id": "AW",
        "countryCode": "297"
    },
    {
        "countryEN": "Oman",
        "countryCN": "阿曼",
        "id": "OM",
        "countryCode": "968"
    },
    {
        "countryEN": "Austria",
        "countryCN": "奥地利",
        "id": "AT",
        "countryCode": "43"
    },
    {
        "countryEN": "Andorra",
        "countryCN": "安道尔",
        "id": "AD",
        "countryCode": "376"
    },
    {
        "countryEN": "Angola",
        "countryCN": "安哥拉",
        "id": "AO",
        "countryCode": "244"
    },
    {
        "countryEN": "Anguilla",
        "countryCN": "安圭拉",
        "id": "AI",
        "countryCode": "1264"
    },
    {
        "countryEN": "Antigua and Barbuda",
        "countryCN": "安提瓜和巴布达",
        "id": "AG",
        "countryCode": "1268"
    },
    {
        "countryEN": "Egypt",
        "countryCN": "埃及",
        "id": "EG",
        "countryCode": "20"
    },
    {
        "countryEN": "Ethiopia",
        "countryCN": "埃塞俄比亚",
        "id": "ET",
        "countryCode": "251"
    },
    {
        "countryEN": "Ireland",
        "countryCN": "爱尔兰",
        "id": "IE",
        "countryCode": "353"
    },
    {
        "countryEN": "Estonia",
        "countryCN": "爱沙尼亚",
        "id": "EE",
        "countryCode": "372"
    },
    {
        "countryEN": "Peru",
        "countryCN": "秘鲁",
        "id": "PE",
        "countryCode": "51"
    },
    {
        "countryEN": "Bahamas",
        "countryCN": "巴哈马",
        "id": "BS",
        "countryCode": "1242"
    },
    {
        "countryEN": "Bahrain",
        "countryCN": "巴林",
        "id": "BH",
        "countryCode": "973"
    },
    {
        "countryEN": "Barbados",
        "countryCN": "巴巴多斯",
        "id": "BB",
        "countryCode": "1246"
    },
    {
        "countryEN": "Belarus",
        "countryCN": "白俄罗斯",
        "id": "BY",
        "countryCode": "375"
    },
    {
        "countryEN": "Belgium",
        "countryCN": "比利时",
        "id": "BE",
        "countryCode": "32"
    },
    {
        "countryEN": "Belize",
        "countryCN": "伯利兹",
        "id": "BZ",
        "countryCode": "501"
    },
    {
        "countryEN": "Benin",
        "countryCN": "贝宁",
        "id": "BJ",
        "countryCode": "229"
    },
    {
        "countryEN": "Bermuda",
        "countryCN": "百慕大群岛",
        "id": "BM",
        "countryCode": "1441"
    },
    {
        "countryEN": "Bhutan",
        "countryCN": "不丹",
        "id": "BT",
        "countryCode": "975"
    },
    {
        "countryEN": "Bolivia",
        "countryCN": "玻利维亚",
        "id": "BO",
        "countryCode": "591"
    },
    {
        "countryEN": "Bosnia and Herzegovina",
        "countryCN": "波斯尼亚和黑塞哥维那",
        "id": "BA",
        "countryCode": "387"
    },
    {
        "countryEN": "Botswana",
        "countryCN": "博茨瓦纳",
        "id": "BW",
        "countryCode": "267"
    },
    {
        "countryEN": "Brazil",
        "countryCN": "巴西",
        "id": "BR",
        "countryCode": "55"
    },
    {
        "countryEN": "Bulgaria",
        "countryCN": "保加利亚",
        "id": "BG",
        "countryCode": "359"
    },
    {
        "countryEN": "Burkina Faso",
        "countryCN": "布基纳法索",
        "id": "BF",
        "countryCode": "226"
    },
    {
        "countryEN": "Burundi",
        "countryCN": "布隆迪",
        "id": "BI",
        "countryCode": "257"
    },
    {
        "countryEN": "Papua New Guinea",
        "countryCN": "巴布亚新几内亚",
        "id": "PG",
        "countryCode": "675"
    },
    {
        "countryEN": "Pakistan",
        "countryCN": "巴基斯坦",
        "id": "PK",
        "countryCode": "92"
    },
    {
        "countryEN": "Paraguay",
        "countryCN": "巴拉圭",
        "id": "PY",
        "countryCode": "595"
    },
    {
        "countryEN": "Palestine",
        "countryCN": "巴勒斯坦",
        "id": "BL",
        "countryCode": "970"
    },
    {
        "countryEN": "Iceland",
        "countryCN": "冰岛",
        "id": "IS",
        "countryCode": "354"
    },
    {
        "countryEN": "Puerto Rico",
        "countryCN": "波多黎各",
        "id": "PR",
        "countryCode": "1787"
    },
    {
        "countryEN": "Panama",
        "countryCN": "巴拿马",
        "id": "PA",
        "countryCode": "507"
    },
    {
        "countryEN": "Poland",
        "countryCN": "波兰",
        "id": "PL",
        "countryCode": "48"
    },
    {
        "countryEN": "Equatorial Guinea",
        "countryCN": "赤道几内亚",
        "id": "GQ",
        "countryCode": "240"
    },
    {
        "countryEN": "Denmark",
        "countryCN": "丹麦",
        "id": "DK",
        "countryCode": "45"
    },
    {
        "countryEN": "Germany",
        "countryCN": "德国",
        "id": "DE",
        "countryCode": "49"
    },
    {
        "countryEN": "Timor-Leste",
        "countryCN": "东帝汶",
        "id": "TL",
        "countryCode": "670"
    },
    {
        "countryEN": "Togo",
        "countryCN": "多哥",
        "id": "TG",
        "countryCode": "228"
    },
    {
        "countryEN": "Dominica",
        "countryCN": "多米尼加",
        "id": "DM",
        "countryCode": "1767"
    },
    {
        "countryEN": "Dominican Republic",
        "countryCN": "多米尼加共和国",
        "id": "DO",
        "countryCode": "1809"
    },
    {
        "countryEN": "Russia",
        "countryCN": "俄罗斯",
        "id": "RU",
        "countryCode": "7"
    },
    {
        "countryEN": "Ecuador",
        "countryCN": "厄瓜多尔",
        "id": "EC",
        "countryCode": "593"
    },
    {
        "countryEN": "Eritrea",
        "countryCN": "厄立特里亚",
        "id": "ER",
        "countryCode": "291"
    },
    {
        "countryEN": "France",
        "countryCN": "法国",
        "id": "FR",
        "countryCode": "33"
    },
    {
        "countryEN": "French Guiana",
        "countryCN": "法属圭亚那",
        "id": "GF",
        "countryCode": "594"
    },
    {
        "countryEN": "French Polynesia",
        "countryCN": "法属波利尼西亚",
        "id": "PF",
        "countryCode": "689"
    },
    {
        "countryEN": "Faroe Islands",
        "countryCN": "法罗群岛",
        "id": "FO",
        "countryCode": "298"
    },
    {
        "countryEN": "Fiji",
        "countryCN": "斐济",
        "id": "FJ",
        "countryCode": "679"
    },
    {
        "countryEN": "Finland",
        "countryCN": "芬兰",
        "id": "FI",
        "countryCode": "358"
    },
    {
        "countryEN": "Philippines",
        "countryCN": "菲律宾",
        "id": "PH",
        "countryCode": "63"
    },
    {
        "countryEN": "Cabo Verde",
        "countryCN": "佛得角",
        "id": "CV",
        "countryCode": "238"
    },
    {
        "countryEN": "Gambia",
        "countryCN": "冈比亚",
        "id": "GM",
        "countryCode": "220"
    },
    {
        "countryEN": "Georgia",
        "countryCN": "格鲁吉亚",
        "id": "GE",
        "countryCode": "995"
    },
    {
        "countryEN": "Greenland",
        "countryCN": "格陵兰岛",
        "id": "GL",
        "countryCode": "299"
    },
    {
        "countryEN": "Grenada",
        "countryCN": "格林纳达",
        "id": "GD",
        "countryCode": "1473"
    },
    {
        "countryEN": "Guadeloupe",
        "countryCN": "瓜德罗普岛",
        "id": "GP",
        "countryCode": "590"
    },
    {
        "countryEN": "Guam",
        "countryCN": "关岛",
        "id": "GU",
        "countryCode": "1671"
    },
    {
        "countryEN": "Republic Of The Congo",
        "countryCN": "刚果共和国",
        "id": "CG",
        "countryCode": "242"
    },
    {
        "countryEN": "Colombia",
        "countryCN": "哥伦比亚",
        "id": "CO",
        "countryCode": "57"
    },
    {
        "countryEN": "Costa Rica",
        "countryCN": "哥斯达黎加",
        "id": "CR",
        "countryCode": "506"
    },
    {
        "countryEN": "Cuba",
        "countryCN": "古巴",
        "id": "CU",
        "countryCode": "53"
    },
    {
        "countryEN": "Guyana",
        "countryCN": "圭亚那",
        "id": "GY",
        "countryCode": "592"
    },
    {
        "countryEN": "Kazakhstan",
        "countryCN": "哈萨克斯坦",
        "id": "KZ",
        "countryCode": "7"
    },
    {
        "countryEN": "Haiti",
        "countryCN": "海地",
        "id": "HT",
        "countryCode": "509"
    },
    {
        "countryEN": "Honduras",
        "countryCN": "洪都拉斯",
        "id": "HN",
        "countryCode": "504"
    },
    {
        "countryEN": "Netherlands",
        "countryCN": "荷兰",
        "id": "NL",
        "countryCode": "31"
    },
    {
        "countryEN": "Netherlands Antilles",
        "countryCN": "荷属安德列斯群岛",
        "id": "AN",
        "countryCode": "599"
    },
    {
        "countryEN": "Montenegro",
        "countryCN": "黑山",
        "id": "ME",
        "countryCode": "382"
    },
    {
        "countryEN": "Djibouti",
        "countryCN": "吉布提",
        "id": "DJ",
        "countryCode": "253"
    },
    {
        "countryEN": "Kyrgyzstan",
        "countryCN": "吉尔吉斯斯坦",
        "id": "KG",
        "countryCode": "996"
    },
    {
        "countryEN": "Kiribati",
        "countryCN": "基里巴斯",
        "id": "KI",
        "countryCode": "686"
    },
    {
        "countryEN": "Guinea",
        "countryCN": "几内亚",
        "id": "GN",
        "countryCode": "224"
    },
    {
        "countryEN": "Guinea-Bissau",
        "countryCN": "几内亚比绍共和国",
        "id": "GW",
        "countryCode": "245"
    },
    {
        "countryEN": "Ghana",
        "countryCN": "加纳",
        "id": "GH",
        "countryCode": "233"
    },
    {
        "countryEN": "Gabon",
        "countryCN": "加蓬",
        "id": "GA",
        "countryCode": "241"
    },
    {
        "countryEN": "Cambodia",
        "countryCN": "柬埔寨",
        "id": "KH",
        "countryCode": "855"
    },
    {
        "countryEN": "Czech",
        "countryCN": "捷克",
        "id": "CZ",
        "countryCode": "420"
    },
    {
        "countryEN": "Zimbabwe",
        "countryCN": "津巴布韦",
        "id": "ZW",
        "countryCode": "263"
    },
    {
        "countryEN": "Cameroon",
        "countryCN": "喀麦隆",
        "id": "CM",
        "countryCode": "237"
    },
    {
        "countryEN": "Cape Verde",
        "countryCN": "开普",
        "id": "CV",
        "countryCode": "238"
    },
    {
        "countryEN": "Cayman Islands",
        "countryCN": "开曼群岛",
        "id": "KY",
        "countryCode": "1345"
    },
    {
        "countryEN": "Comoros",
        "countryCN": "科摩罗",
        "id": "KM",
        "countryCode": "269"
    },
    {
        "countryEN": "Cook Islands",
        "countryCN": "库克群岛",
        "id": "CK",
        "countryCode": "682"
    },
    {
        "countryEN": "Côte d'Ivoire",
        "countryCN": "科特迪瓦",
        "id": "CI",
        "countryCode": "225"
    },
    {
        "countryEN": "Croatia",
        "countryCN": "克罗地亚",
        "id": "HR",
        "countryCode": "385"
    },
    {
        "countryEN": "Curacao",
        "countryCN": "库拉索",
        "id": "CW",
        "countryCode": "599"
    },
    {
        "countryEN": "Qatar",
        "countryCN": "卡塔尔",
        "id": "QA",
        "countryCode": "974"
    },
    {
        "countryEN": "Kuwait",
        "countryCN": "科威特",
        "id": "KW",
        "countryCode": "965"
    },
    {
        "countryEN": "Kenya",
        "countryCN": "肯尼亚",
        "id": "KE",
        "countryCode": "254"
    },
    {
        "countryEN": "Laos",
        "countryCN": "老挝",
        "id": "LA",
        "countryCode": "856"
    },
    {
        "countryEN": "Latvia",
        "countryCN": "拉脱维亚",
        "id": "LV",
        "countryCode": "371"
    },
    {
        "countryEN": "Lebanon",
        "countryCN": "黎巴嫩",
        "id": "LB",
        "countryCode": "961"
    },
    {
        "countryEN": "Lesotho",
        "countryCN": "莱索托",
        "id": "LS",
        "countryCode": "266"
    },
    {
        "countryEN": "Liberia",
        "countryCN": "利比里亚",
        "id": "LR",
        "countryCode": "231"
    },
    {
        "countryEN": "Libya",
        "countryCN": "利比亚",
        "id": "LY",
        "countryCode": "218"
    },
    {
        "countryEN": "Liechtenstein",
        "countryCN": "列支敦士登",
        "id": "LI",
        "countryCode": "423"
    },
    {
        "countryEN": "Lithuania",
        "countryCN": "立陶宛",
        "id": "LT",
        "countryCode": "370"
    },
    {
        "countryEN": "Luxembourg",
        "countryCN": "卢森堡",
        "id": "LU",
        "countryCode": "352"
    },
    {
        "countryEN": "Rwanda",
        "countryCN": "卢旺达",
        "id": "RW",
        "countryCode": "250"
    },
    {
        "countryEN": "Réunion Island",
        "countryCN": "留尼汪",
        "id": "RE",
        "countryCode": "262"
    },
    {
        "countryEN": "Romania",
        "countryCN": "罗马尼亚",
        "id": "RO",
        "countryCode": "40"
    },
    {
        "countryEN": "Myanmar",
        "countryCN": "缅甸",
        "id": "MM",
        "countryCode": "95"
    },
    {
        "countryEN": "Macedonia",
        "countryCN": "马其顿",
        "id": "MK",
        "countryCode": "389"
    },
    {
        "countryEN": "Madagascar",
        "countryCN": "马达加斯加",
        "id": "MG",
        "countryCode": "261"
    },
    {
        "countryEN": "Malawi",
        "countryCN": "马拉维",
        "id": "MW",
        "countryCode": "265"
    },
    {
        "countryEN": "Maldives",
        "countryCN": "马尔代夫",
        "id": "MV",
        "countryCode": "960"
    },
    {
        "countryEN": "Mali",
        "countryCN": "马里",
        "id": "ML",
        "countryCode": "223"
    },
    {
        "countryEN": "Malta",
        "countryCN": "马耳他",
        "id": "MT",
        "countryCode": "356"
    },
    {
        "countryEN": "Martinique",
        "countryCN": "马提尼克",
        "id": "MQ",
        "countryCode": "596"
    },
    {
        "countryEN": "Mariana lslands",
        "countryCN": "马里亚纳群岛",
        "id": "MI",
        "countryCode": "1670"
    },
    {
        "countryEN": "Mauritania",
        "countryCN": "毛里塔尼亚",
        "id": "MR",
        "countryCode": "222"
    },
    {
        "countryEN": "Mauritius",
        "countryCN": "毛里求斯",
        "id": "MU",
        "countryCode": "230"
    },
    {
        "countryEN": "Mayotte",
        "countryCN": "马约特",
        "id": "YT",
        "countryCode": "269"
    },
    {
        "countryEN": "Mexico",
        "countryCN": "墨西哥",
        "id": "MX",
        "countryCode": "52"
    },
    {
        "countryEN": "Moldova",
        "countryCN": "摩尔多瓦",
        "id": "MD",
        "countryCode": "373"
    },
    {
        "countryEN": "Monaco",
        "countryCN": "摩纳哥",
        "id": "MC",
        "countryCode": "377"
    },
    {
        "countryEN": "Mongolia",
        "countryCN": "蒙古",
        "id": "MN",
        "countryCode": "976"
    },
    {
        "countryEN": "Montserrat",
        "countryCN": "蒙特塞拉特岛",
        "id": "MS",
        "countryCode": "1664"
    },
    {
        "countryEN": "Morocco",
        "countryCN": "摩洛哥",
        "id": "MA",
        "countryCode": "212"
    },
    {
        "countryEN": "Mozambique",
        "countryCN": "莫桑比克",
        "id": "MZ",
        "countryCode": "258"
    },
    {
        "countryEN": "Bangladesh",
        "countryCN": "孟加拉国",
        "id": "BD",
        "countryCode": "880"
    },
    {
        "countryEN": "American Samoa",
        "countryCN": "美属萨摩亚",
        "id": "AS",
        "countryCode": "1684"
    },
    {
        "countryEN": "Virgin Islands, US",
        "countryCN": "美属维尔京群岛",
        "id": "VI",
        "countryCode": "1284"
    },
    {
        "countryEN": "Nicaragua",
        "countryCN": "尼加拉瓜",
        "id": "NI",
        "countryCode": "505"
    },
    {
        "countryEN": "Niger",
        "countryCN": "尼日尔",
        "id": "NE",
        "countryCode": "227"
    },
    {
        "countryEN": "Nigeria",
        "countryCN": "尼日利亚",
        "id": "NG",
        "countryCode": "234"
    },
    {
        "countryEN": "Nepal",
        "countryCN": "尼泊尔",
        "id": "NP",
        "countryCode": "977"
    },
    {
        "countryEN": "Namibia",
        "countryCN": "纳米比亚",
        "id": "NA",
        "countryCode": "264"
    },
    {
        "countryEN": "Norway",
        "countryCN": "挪威",
        "id": "NO",
        "countryCode": "47"
    },
    {
        "countryEN": "South Africa",
        "countryCN": "南非",
        "id": "ZA",
        "countryCode": "27"
    },
    {
        "countryEN": "Portugal",
        "countryCN": "葡萄牙",
        "id": "PT",
        "countryCode": "351"
    },
    {
        "countryEN": "Palau",
        "countryCN": "帕劳",
        "id": "PW",
        "countryCode": "680"
    },
    {
        "countryEN": "Sweden",
        "countryCN": "瑞典",
        "id": "SE",
        "countryCode": "46"
    },
    {
        "countryEN": "Switzerland",
        "countryCN": "瑞士",
        "id": "CH",
        "countryCode": "41"
    },
    {
        "countryEN": "El Salvador",
        "countryCN": "萨尔瓦多",
        "id": "SV",
        "countryCode": "503"
    },
    {
        "countryEN": "Samoa",
        "countryCN": "萨摩亚",
        "id": "WS",
        "countryCode": "685"
    },
    {
        "countryEN": "Saudi Arabia",
        "countryCN": "沙特阿拉伯",
        "id": "SA",
        "countryCode": "966"
    },
    {
        "countryEN": "Senegal",
        "countryCN": "塞内加尔",
        "id": "SN",
        "countryCode": "221"
    },
    {
        "countryEN": "Serbia",
        "countryCN": "塞尔维亚",
        "id": "RS",
        "countryCode": "381"
    },
    {
        "countryEN": "Seychelles",
        "countryCN": "塞舌尔",
        "id": "SC",
        "countryCode": "248"
    },
    {
        "countryEN": "Sierra Leone",
        "countryCN": "塞拉利昂",
        "id": "SL",
        "countryCode": "232"
    },
    {
        "countryEN": "Cyprus",
        "countryCN": "塞浦路斯",
        "id": "CY",
        "countryCode": "357"
    },
    {
        "countryEN": "Saint Kitts and Nevis",
        "countryCN": "圣基茨和尼维斯",
        "id": "KN",
        "countryCode": "1869"
    },
    {
        "countryEN": "Saint Lucia",
        "countryCN": "圣露西亚",
        "id": "LC",
        "countryCode": "1758"
    },
    {
        "countryEN": "Saint Pierre and Miquelon",
        "countryCN": "圣彼埃尔和密克隆岛",
        "id": "PM",
        "countryCode": "508"
    },
    {
        "countryEN": "Saint Vincent and The Grenadines",
        "countryCN": "圣文森特和格林纳丁斯",
        "id": "VC",
        "countryCode": "1784"
    },
    {
        "countryEN": "Saint Kitts and Nevis",
        "countryCN": "圣克里斯托弗和尼维斯",
        "id": "KN",
        "countryCode": "1869"
    },
    {
        "countryEN": "San Marino",
        "countryCN": "圣马力诺",
        "id": "SM",
        "countryCode": "378"
    },
    {
        "countryEN": "Sao Tome and Principe",
        "countryCN": "圣多美和普林西比",
        "id": "ST",
        "countryCode": "239"
    },
    {
        "countryEN": "Saint Maarten (Dutch Part)",
        "countryCN": "圣马丁岛（荷兰部分）",
        "id": "SX",
        "countryCode": "1721"
    },
    {
        "countryEN": "Slovakia",
        "countryCN": "斯洛伐克",
        "id": "SK",
        "countryCode": "421"
    },
    {
        "countryEN": "Slovenia",
        "countryCN": "斯洛文尼亚",
        "id": "SI",
        "countryCode": "386"
    },
    {
        "countryEN": "Solomon Islands",
        "countryCN": "所罗门群岛",
        "id": "SB",
        "countryCode": "677"
    },
    {
        "countryEN": "Somalia",
        "countryCN": "索马里",
        "id": "SO",
        "countryCode": "252"
    },
    {
        "countryEN": "Sri Lanka",
        "countryCN": "斯里兰卡",
        "id": "LK",
        "countryCode": "94"
    },
    {
        "countryEN": "Sudan",
        "countryCN": "苏丹",
        "id": "SD",
        "countryCode": "249"
    },
    {
        "countryEN": "Suriname",
        "countryCN": "苏里南",
        "id": "SR",
        "countryCode": "597"
    },
    {
        "countryEN": "Swaziland",
        "countryCN": "斯威士兰",
        "id": "SZ",
        "countryCode": "268"
    },
    {
        "countryEN": "Tajikistan",
        "countryCN": "塔吉克斯坦",
        "id": "TJ",
        "countryCode": "992"
    },
    {
        "countryEN": "Tanzania",
        "countryCN": "坦桑尼亚",
        "id": "TZ",
        "countryCode": "255"
    },
    {
        "countryEN": "Thailand",
        "countryCN": "泰国",
        "id": "TH",
        "countryCode": "66"
    },
    {
        "countryEN": "Tonga",
        "countryCN": "汤加",
        "id": "TO",
        "countryCode": "676"
    },
    {
        "countryEN": "Trinidad and Tobago",
        "countryCN": "特立尼达和多巴哥",
        "id": "TT",
        "countryCode": "1868"
    },
    {
        "countryEN": "Tunisia",
        "countryCN": "突尼斯",
        "id": "TN",
        "countryCode": "216"
    },
    {
        "countryEN": "Turkey",
        "countryCN": "土耳其",
        "id": "TR",
        "countryCode": "90"
    },
    {
        "countryEN": "Turkmenistan",
        "countryCN": "土库曼斯坦",
        "id": "TM",
        "countryCode": "993"
    },
    {
        "countryEN": "Turks and Caicos Islands",
        "countryCN": "特克斯和凯科斯群岛",
        "id": "TC",
        "countryCode": "1649"
    },
    {
        "countryEN": "Vanuatu",
        "countryCN": "瓦努阿图",
        "id": "VU",
        "countryCode": "678"
    },
    {
        "countryEN": "Venezuela",
        "countryCN": "委内瑞拉",
        "id": "VE",
        "countryCode": "58"
    },
    {
        "countryEN": "Guatemala",
        "countryCN": "危地马拉",
        "id": "GT",
        "countryCode": "502"
    },
    {
        "countryEN": "Uganda",
        "countryCN": "乌干达",
        "id": "UG",
        "countryCode": "256"
    },
    {
        "countryEN": "Ukraine",
        "countryCN": "乌克兰",
        "id": "UA",
        "countryCode": "380"
    },
    {
        "countryEN": "Uruguay",
        "countryCN": "乌拉圭",
        "id": "UY",
        "countryCode": "598"
    },
    {
        "countryEN": "Uzbekistan",
        "countryCN": "乌兹别克斯坦",
        "id": "UZ",
        "countryCode": "998"
    },
    {
        "countryEN": "Brunei",
        "countryCN": "文莱",
        "id": "BN",
        "countryCode": "673"
    },
    {
        "countryEN": "Spain",
        "countryCN": "西班牙",
        "id": "ES",
        "countryCode": "34"
    },
    {
        "countryEN": "Greece",
        "countryCN": "希腊",
        "id": "GR",
        "countryCode": "30"
    },
    {
        "countryEN": "Ivory Coast",
        "countryCN": "象牙海岸",
        "id": "CI",
        "countryCode": "225"
    },
    {
        "countryEN": "New Caledonia",
        "countryCN": "新喀里多尼亚",
        "id": "NC",
        "countryCode": "687"
    },
    {
        "countryEN": "New Zealand",
        "countryCN": "新西兰",
        "id": "NZ",
        "countryCode": "64"
    },
    {
        "countryEN": "Syria",
        "countryCN": "叙利亚",
        "id": "SY",
        "countryCode": "963"
    },
    {
        "countryEN": "Hungary",
        "countryCN": "匈牙利",
        "id": "HU",
        "countryCode": "36"
    },
    {
        "countryEN": "Jamaica",
        "countryCN": "牙买加",
        "id": "JM",
        "countryCode": "1876"
    },
    {
        "countryEN": "Armenia",
        "countryCN": "亚美尼亚",
        "id": "AM",
        "countryCode": "374"
    },
    {
        "countryEN": "Yemen",
        "countryCN": "也门",
        "id": "YE",
        "countryCode": "967"
    },
    {
        "countryEN": "Iran",
        "countryCN": "伊朗",
        "id": "IR",
        "countryCode": "98"
    },
    {
        "countryEN": "Iraq",
        "countryCN": "伊拉克",
        "id": "IQ",
        "countryCode": "964"
    },
    {
        "countryEN": "Israel",
        "countryCN": "以色列",
        "id": "IL",
        "countryCode": "972"
    },
    {
        "countryEN": "Italy",
        "countryCN": "意大利",
        "id": "IT",
        "countryCode": "39"
    },
    {
        "countryEN": "India",
        "countryCN": "印度",
        "id": "IN",
        "countryCode": "91"
    },
    {
        "countryEN": "Indonesia",
        "countryCN": "印度尼西亚",
        "id": "ID",
        "countryCode": "62"
    },
    {
        "countryEN": "United Kingdom",
        "countryCN": "英国",
        "id": "GB",
        "countryCode": "44"
    },
    {
        "countryEN": "Virgin Islands, British",
        "countryCN": "英属维尔京群岛",
        "id": "VG",
        "countryCode": "1340"
    },
    {
        "countryEN": "Jordan",
        "countryCN": "约旦",
        "id": "JO",
        "countryCode": "962"
    },
    {
        "countryEN": "Zambia",
        "countryCN": "赞比亚",
        "id": "ZM",
        "countryCode": "260"
    },
    {
        "countryEN": "Central African Republic",
        "countryCN": "中非共和国",
        "id": "CF",
        "countryCode": "236"
    },
    {
        "countryEN": "Chad",
        "countryCN": "乍得",
        "id": "TD",
        "countryCode": "235"
    },
    {
        "countryEN": "Chile",
        "countryCN": "智利",
        "id": "CL",
        "countryCode": "56"
    },
    {
        "countryEN": "Gibraltar",
        "countryCN": "直布罗陀",
        "id": "GI",
        "countryCode": "350"
    }
]
