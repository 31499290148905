import React from 'react';

const navData = [
    { title: 'ROLL房', value: 'roll' },
    { title: '魔能(MOE)激励活动', value: 'moe' },
]

function RollRoomContentNav({ nav: { nav, setNav } }) {
    return (
        <div className="roll-room-content-nav">
            {
                navData.map(_ => {
                    let cls = nav === _.value ? 'nav-item current flex jtyct ait' : 'nav-item flex jtyct ait'
                    return <div onClick={ () => {
                        setNav(_.value)
                        localStorage.setItem('rollRoomNav', _.value)
                    } } key={ _.value } className={ cls }>{ _.title }</div>
                })
            }
        </div>
    )
}

export default RollRoomContentNav;
