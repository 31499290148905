import { get } from '@/utils/request.js';

export default {
    getHelp(params) {
        return get({ url: '/admin/siteSetting/help/get/', params })
    },
    getAnnouncement() {
        return get({ url: "/webres/announcement/" })
    },
    getSignUpCount() {
        return get({ url: "/webres/info/n/" })
    }
}
