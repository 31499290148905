import React, { antdUI, Component } from 'react';
import './index.scss';

const problemData = [
    {
        title: '关于我们', path: '/help#tranding', children: [
            { title: "关于我们", path: "/ContactUs" },
            { title: "用户协议", path: "https://www.mobi2077.com/public/help/userAgreement.html" },
            { title: "隐私政策", path: "https://www.mobi2077.com/public/help/privacyPolicy.html" }
        ]
    },
    {
        title: 'MOBI服务', path: '/help#tranding', children: [
            { title: "饰品互换" },
            { title: "我的库存", path: "/inventory" },
            { title: "炼金配方(精确)", path: "/formulaSquare" },
        ]
    },
    {
        title: '社群', path: '/', children: [
            {
                title: '抖音账号',
                logo: require('@/images/home/douyin@2x.png'),
                path: "https://v.douyin.com/2R5oL7f/"
            },
            {
                title: 'BiliBili账号',
                logo: require('@/images/home/bilibili@2x.png'),
                path: "https://space.bilibili.com/1737938969"
            },
            {
                title: '860316044 (官方群)',
                logo: require('@/images/home/qq@2x.png'),
                path: "https://jq.qq.com/?_wv=1027&k=2FuHyKTo"
            },
        ]
    },
    // {
    //     title: '帮助中心', path: '/', children: [
    //         { title: "交易防骗指南", path: "/help#tranding" },
    //         { title: "交易准备工作", path: "/help" },
    //         { title: "查看更多", path: "/help" }
    //     ]
    // },
    // {
    //     title: '支付方式', path: '/', children: [
    //         { title: "支付宝", logo: require('@/images/home/zhifubao@2x.png') },
    //         { title: "微信", logo: require('@/images/home/weixin@2x.png') },
    //         { title: "银联", logo: require('@/images/home/yinlian@2x.png') },
    //         { title: "云闪付", logo: require('@/images/home/yunshanfu@2x.png') },
    //     ]
    // },
    // {
    //     title: 'MOBI服务', path: '/help#tranding', children: [
    //         { title: "CSGO饰品交易", path: "" },
    //         { title: "DOTA2饰品交易", path: "" },
    //         { title: "推广交易奖励饰品", path: "" },
    //         { title: "推广奖购物金", path: "" }
    //     ]
    // },
];
const downloadData = [
    {
        title: 'APP下载',
        code: require('@/images/home/mobiDownLoadLogo.png'),
        dueTime: '2022-01-04',
        version: '1.0.3',
        detail: 'https://www.mobi2077.com/public/help/iosDetail.html',
        download: "https://apps.apple.com/cn/app/id1570072019"
    },
]
const payIcon = [
    { title: "微信", logo: require('@/images/home/weixin@2x.png') },
    { title: "支付宝", logo: require('@/images/home/zhifubao@2x.png') },
    { title: "云闪付", logo: require('@/images/home/yunshanfu@2x.png') },
    { title: "银联", logo: require('@/images/home/yinlian@2x.png') }
]


class BottomBar extends Component {
    render() {
        return (
            <div className="bottom-bar">
                <div className="content">
                    <div className="opt" style={ { position: 'relative' } }>
                        <div className="logo">
                            <img className="lg" src={ require('@/images/home/logo@2x.png') } alt=""/>
                            
                            <div className="problem">
                                {
                                    problemData.map((v, i) => {
                                        return (
                                            <div key={ i } style={ { marginRight: (i === 0 || i === 1) && '40px' } }
                                                 className="pro-item">
                                                <div style={ {
                                                    marginBottom: "10px",
                                                    textAlign: 'center'
                                                } }> { v.title }</div>
                                                { v.children.map((_, _i) => {
                                                    return <div key={ _i } style={ { textAlign: 'center' } }
                                                                className={ [ (i === 0 || i === 1 || i === 2) ? 'child-item J_s' : 'child-item' ] }
                                                                onClick={ () => {
                                                                    switch (i) {
                                                                        case 0:
                                                                            _.path && window.open(_.path);
                                                                            break;
                                                                        case 1:
                                                                            if (_.title === "饰品互换") return antdUI.message.success("请前往MOBI APP，使用该功能")
                                                                            window.location.href = _.path;
                                                                            break;
                                                                        case 2:
                                                                            _.path && window.open(_.path);
                                                                            // window.location.href = _.path;
                                                                            // setTimeout(() => window.location.reload(), 50);
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                } }>
                                                        { _.logo ?
                                                            <img style={ { width: "25px", marginRight: '4px' } }
                                                                 src={ _.logo } alt=""/> : '' }
                                                        { _.title }
                                                    </div>
                                                }) }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                        
                        <div className="website-information">
                            <div className="download">
                                { downloadData.map((v, i) => {
                                    return <div key={ i } className="dw-item">
                                        
                                        <img src={ v.code } alt=""/>
                                        <p style={ { display: 'block', marginTop: '5px' } }
                                           rel="noopener noreferrer">{ v.title }</p>
                                    </div>
                                }) }
                            </div>
                        </div>
                    </div>
                    <div className="line-separated"></div>
                    
                    <p className="bt-bar" style={ { marginTop: "25px" } }>健康游戏公告： 抵制不良游戏 拒绝盗版游戏 注意自我保护
                        谨防受骗上当 适度游戏益脑
                        沉迷游戏伤身 合理安排时间 享受健康生活</p>
                    
                    
                    <div className="bt-bar" style={ { position: 'relative', marginTop: "16px" } }>
                        <p>COPYRIGHT</p>
                        <p>2020-2022</p>
                        <p>南京魔笔信息科技有限公司 Nanjing Mobi Information Technology Co., Ltd.</p>
                    </div>
                    <div className="bt-bar" style={ { margin: '12px auto 0 auto', padding: '0' } }>
                        <a target="_blank" rel="noopener noreferrer" style={ {
                            display: 'inline-block',
                            textDecoration: 'none', height: '20px',
                            lineHeight: '20px'
                        } }
                           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011202000741"
                        >
                            <img src={ require('../../images/wangan.png') } alt=""
                                 style={ { width: "20px", float: 'left' } }/>
                            <p style={ {
                                float: 'left', height: '20px', lineHeight: '20px', margin: '0 0 0 5px',
                                // color: '#939393'
                            } }
                            >苏公网安备 32011202000741号</p>
                        </a>
                        <a style={ { marginLeft: '10px' } } href="https://beian.miit.gov.cn/" rel="noopener noreferrer"
                           target="_blank">苏ICP备2021000380号-1</a>
                        <p>经营许可证编号：苏B2-20210126</p>
                    </div>
                    
                    
                    <div className="bottom-bar-icon-list">
                        {
                            payIcon.map((item, index) => {
                                return <img key={ index } style={ { width: index === 3 && '39px' } } src={ item.logo }
                                            alt={ item.title }/>
                            })
                        }
                        <a target="cyxyv" style={ { marginLeft: "10px" } }
                           href="https://v.yunaq.com/certificate?domain=www.mobi2077.com&from=label&code=90030">
                            <img style={ { width: '70px' } }
                                 src="https://aqyzmedia.yunaq.com/labels/label_lg_90030.png" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBar;


