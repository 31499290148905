import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

class FrontendAuth extends React.Component {
    async componentDidMount() {
        this.props.setUserSignIn()
        let result = await React.$api.user.checklogin()
        this.props.upDateFavoritesData()
        result.data && result.data.uid && this.props.upDateUserAssets()
    }

    componentDidUpdate(nextProps) {

        if (nextProps.location.pathname !== '/' && nextProps.location.pathname !== '/profile' && nextProps.location.pathname !== this.props.location.pathname) {
            this.props.checkUserSignIn()
        }
        if (window.location.pathname !== '/marketPlace' && window.location.pathname !== '/marketPlace/accessoriesList') {
            localStorage.removeItem('rememberFilter');
            localStorage.removeItem('rememberPrice');
            localStorage.removeItem('rememberFilterd2');
            localStorage.removeItem('curNav');
        }
    }

    render() {
        const { location, config } = this.props;
        const { pathname } = location;
        const isLogin = this.props.userInfo.uid || this.props.userInfo.steam.steamid || localStorage.getItem('mobi_uid');

        // if (pathname === '/') {
        //     return <Redirect to="/home"/>
        // }
        // 如果该路由不用进行权限校验，登录状态下登录页除外
        // 因为登录后，无法跳转到登录页
        // 这部分代码，是为了在非登录状态下，访问不需要权限校验的路由
        const targetRouterConfig = config.find((v) => v.path === pathname);
        if (targetRouterConfig && ! targetRouterConfig.auth && ! isLogin) {
            const { component } = targetRouterConfig;
            return <Route exact path={ pathname } component={ component }/>
        }

        if (isLogin) {
            // 如果是登录状态，想要跳转到登录，重定向到主页
            // if (pathname === '/login') {
            //   return <Redirect to='/' />
            // } else {
            // 如果路由合法，就跳转到相应的路由
            if (targetRouterConfig) {
                if (targetRouterConfig.path === '/profile') {
                    return <Redirect to='/profile/userConfig'/>
                } else {
                    return <Route path={ pathname } component={ targetRouterConfig.component }/>
                }
            } else {
                // 如果路由不合法，重定向到 404 页面
                return <Redirect to='/home'/>
            }
            // }
        } else {
            // 非登录状态下，当路由合法时且需要权限校验时，跳转到登录页面，要求登录
            if (targetRouterConfig && targetRouterConfig.auth) {
                return <Redirect to='/home#login'/>
            } else {
                // 非登录状态下，路由不合法时，重定向至 404
                return <Redirect to='/home'/>
            }
        }
    }
}

export default FrontendAuth
