import React, { $api, $bindSteamLink, $csgoWearColor, $tools, antdUI, useState } from 'react';
import { _MarbleFade, Doppler, GammaDoppler } from '@/data/dopplerData.js'
import { dota2, tQualityObj, tRarityObj } from '@/data/classification.js'
import { Link } from "react-router-dom";
import { connect } from "react-redux";


const icon_url_config = {
    '88': require('@/images/rollRoom/buketixianyue xiao@2x.png'),
    '77': require('@/images/rollRoom/mobi xiao@2x.png')
}

function CsgoBox({ itemData, userInfo }) {
    const { obj2strUrl, checkResult, debounce } = $tools
    const { appid, itemid, paintindex, paintseed, CsgoItem, Dota2Item, price, state: _state } = itemData
    const tItem = CsgoItem || Dota2Item || {};
    const [ loading, setLoading ] = useState(false)
    const [ state, setState ] = useState(_state)
    const img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    let tStyle, itemsStyle, exterior, exterior_bcc, quality, rarity;
    if (Number(appid) === 730) {
        if (tItem.market_name.match(' 多普勒 ')) {
            tStyle = Doppler.children;
        } else if (tItem.market_name.match(' 伽玛多普勒 ')) {
            tStyle = GammaDoppler.children;
        } else if (tItem.market_name.match('爪子刀（★） | 渐变大理石 ') || tItem.market_name.match('刺刀（★） | 渐变大理石 ')
            || tItem.market_name.match('折叠刀（★） | 渐变大理石 ') || tItem.market_name.match('穿肠刀（★） | 渐变大理石 ')) {
            tStyle = _MarbleFade.children;
        }
        if (tStyle && tStyle[0]) {
            if (tStyle[0].value[0] === '[') {
                itemsStyle = tStyle.filter(_ => _.value === paintseed)[0]
            } else {
                itemsStyle = tStyle.filter(_ => _.value === Number(paintindex))[0]
            }
        }
        exterior = $csgoWearColor(tItem.exterior);
        exterior_bcc = $csgoWearColor(exterior.name);
        quality = tQualityObj.filter(_ => _.value === tItem.quality)[0]
        rarity = tRarityObj.filter(_ => _.value === tItem.rarity)[0]
    } else if (Number(appid) === 570) {
        rarity = dota2[2].children.filter(_ => _.value === tItem.rarity)[0]
    }
    const retrieveItem = async () => {
        if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
        setLoading(true)
        const result = await $api.backpack.retrieveItem({ itembid: itemData.id })
        if (checkResult(result)) {
            if ([ 77, 88 ].indexOf(appid) !== -1) {
                antdUI.message.success('取回成功！')
                setState(2)
            } else if ([ 570, 730 ].indexOf(appid) !== -1) {
                antdUI.message.success('取回提交成功！')
                setState(1)
            }
        }
        setLoading(false)
    }
    
    return (
        <div className="backpack-item-box">
            <div className="_img"
                 style={ {
                     backgroundImage: [ 77, 88, 570 ].indexOf(Number(appid)) !== -1 && 'none',
                     padding: [ 77, 88, 570 ].indexOf(Number(appid)) !== -1 && '0'
                 } }
                 onLoad={ (e) => (e.currentTarget.style.opacity = 1) }>
                <div className="item-quality" style={ { alignItems: "start" } }>
                    { quality &&
                        <p style={ { color: quality?.color } } value={ quality?.value } className="item_quality">
                            { quality?.label }
                        </p> }
                    { rarity &&
                        <p style={ { color: rarity?.color, padding: Number(appid) === 570 && '0px 8px' } }
                           value={ rarity?.value } className="item_quality">
                            { rarity?.label }
                        </p> }
                    { itemsStyle?.value && tStyle && (
                        <p className="item_special_logo"
                           style={ { color: itemsStyle?.color } }>{ itemsStyle?.label }</p>
                    ) }
                </div>
                
                { exterior && (
                    <div style={ { backgroundColor: exterior_bcc, } } className="subscript">{ exterior?.name }</div>
                ) }
                { [ 570, 730 ].indexOf(Number(appid)) !== -1 &&
                    <img style={ { width: Number(appid) === 570 && '100%', marginLeft: Number(appid) === 570 && '0' } }
                         className="item-img"
                         src={ `${ img_bs + tItem?.icon_url }` } alt={ Number(appid) }/> }
                
                { [ 88, 77 ].indexOf(Number(appid)) !== -1 &&
                    <img style={ { width: '100%' } }
                         className="item-img"
                         src={ icon_url_config[Number(appid)] } alt={ Number(appid) }/> }
            </div>
            
            
            <Link to={
                {
                    pathname: '/marketPlace/accessoriesList',
                    search: obj2strUrl({
                        id: itemid,
                        curGame: appid,
                        curType: [ 'unusual_strange', 'strange' ].indexOf(tItem.quality) !== -1 ? 2 : 1,
                    }, '?')
                }
            } target='_blank'>
                <p className="item-name">{
                    (_ => {
                        switch (Number(appid)) {
                            case 730:
                                return tItem.market_name
                            case 570:
                                return tItem.market_name
                            case 88:
                                return '购物金（不可提现）'
                            case 77:
                                return 'MOE代币'
                            default:
                                break;
                        }
                    })()
                }</p>
            </Link>
            <div className="flex ait" style={ { justifyContent: "space-between" } }>
                {
                    price ? (
                        <p className="price">
                            <small>￥</small>
                            { parseInt(price) }
                            <small>
                                { String(price).indexOf(".") > 0 &&
                                    String(price).slice(String(price).indexOf(".")) }
                            </small>
                        </p>
                    ) : <p className="price"></p>
                }
                { state === 0 ?
                    <antdUI.Button loading={ loading } style={ { marginLeft: '10px' } }
                                   onClick={ debounce(retrieveItem, 2000, true) }>取回
                    </antdUI.Button> : state === 1 ? "取回中..." : state === 2 ? '已取回' : ''
                }
            </div>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(CsgoBox);
