import { get, post } from '@/utils/request.js';

export default {
    // 获取Roll房列表数据
    getRollRoomData(params) {
        return get({ url: '/rollRoom/list/', params })
    },
    getRollRoomDetail(params) {
        return get({ url: '/rollRoom/detail/', params })
    },
    
    getRollRoomDetailItems(params) {
        return get({ url: '/rollRoom/detail/items/', params })
    },
    
    getRollRoomDetailJoin(params) {
        return get({ url: '/rollRoom/detail/join/', params })
    },
    
    getRollRoomDetailPrize(params) {
        return get({ url: '/rollRoom/detail/prize/', params })
    },
    
    getRollRoomDetailMyPrize(params) {
        return get({ url: '/rollRoom/detail/myPrize/', params })
    },
    
    joinRollRoom(data) {
        return post({ url: '/rollRoom/join/', data })
    },
    joinWelfareRollRoom(data) {
        return post({ url: '/rollRoom/welfare/join/', data })
    },
    inputWelfareRollRoom(data) {
        return post({ url: '/rollRoom/welfare/input/', data })
    },
    
}
