import React, { useState, useEffect } from 'react';
import './menuItem.scss';
import { NavLink } from 'react-router-dom'

function MenuItem(props) {
  let { item: v } = props
  const myRef = React.createRef();
  const [s_icon, setS_icon] = useState(false);
  useEffect(() => {
    myRef.current.className.match('current') ? setS_icon(true) : setS_icon(false)
  }, [myRef])
  return (
    <NavLink to={v.path} ref={myRef} activeClassName="current" className="menu-item">
      <img className="menu-icon" src={s_icon ? v.select_icon : v.icon} alt="" />
      <p>{v.title}</p>
    </NavLink>
  )
}

export default MenuItem;