import initialState from '../states/userInfo';
import $tools from '@/utils/tools.js'

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      let { userInfo } = action;
      userInfo.bindStatus = $tools.checkUserBindInfo(userInfo)
      return { ...state, userInfo }
    case 'CHECK_USER_SIGNIN':
      let _state = { ...state }
      action.userInfo && (_state.userInfo = action.userInfo)
      return _state
    case 'UP_DATE_USER_ASSETS':
      return {
        ...state,
        userAssets: action.userAssets
      }
    default:
      return state;
  }
}
