import React, { antdUI } from 'react';
import './topMessage.scss';

function TopMessage() {
  return (
    <div className="top-message">
      <p className="t-text">处理[发货]报价时，请核对对方steam注册时间，确保一致后，才可确认报价</p>
      <div className="join-steam-tips">
        <antdUI.Popconfirm icon={false} title={(<p
          style={{ width: '200px', fontSize: '14px' }}>
          请使用UU加速器加速steam社区则可以查看交易报价
        </p>)} placement="top" >
          <div className="t-title">  <i className="iconfont icon-jinggao"></i> 无法前往Steam</div>
        </antdUI.Popconfirm>
      </div>
    </div>
  )
}

export default TopMessage