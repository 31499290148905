import React, { antdUI, $tools, $api } from 'react';
import './orderDetail.scss'
import Sticker from '@/components/accessoriesList/accessoriesContainer/typePage/sticker'

function OnShelfContainer(props) {
    let { orderDetailData, orderDetailView, setOrderDetailView, type, getData, currentGame } = props
    
    const { BASE_STEAM_IMG, Sum, checkResult, debounce } = $tools
    
    let dataSource = [];
    orderDetailData.IntermediaryListings.forEach((item, index) => {
        dataSource.push({
            key: index,
            item: (
                <div style={ favoritesItemStyle } className="favorites-item">
                    <img style={ {
                        ...tabImg,
                        backgroundImage: `url(${ require('@/images/items/item_bg.png') })`,
                        width: '75px'
                    } } src={ BASE_STEAM_IMG + item.icon_url } alt=""/>
                </div>
            ),
            name: (
                <div style={ { width: '280px' } } className="on-shelf-item-name">
                    <p style={ tabItemName } className="item-name one-text-flow">{ item.market_name }</p>
                    { item.market_item_detail && item.market_item_detail?.msg && item.market_item_detail?.msgfloatvalue !== '0' &&
                    <p style={ floatval } className="floatval">磨损:{ item.market_item_detail?.msg.floatvalue }</p> }
                    { item.market_item_detail?.msg.stickers &&
                    <Sticker type="onShelf" stickers={ item.market_item_detail?.msg.stickers }/> }
                </div>
            ),
            sellingPrice: <div style={ tabBox }>￥<h1 style={ tabH }>{ item.sellingPrice }</h1></div>,
            description: <div style={ tabBox }><p>{ item.onShelfDesc }</p></div>
        })
    })
    
    const acceptOrder = async (orderNumber) => {
        let result = await $api.order.acceptIntOrder({ orderNumber, appid: currentGame })
        if (checkResult(result)) {
            antdUI.message.success('购买成功');
            getData();
            setTimeout(_ => setOrderDetailView(false), 100);
        }
    }
    
    return (
        <antdUI.Modal
            title="订单详情"
            visible={ orderDetailView }  // 是否展示的布尔值
            footer={ false } // 是否需要按钮
            onCancel={ () => {
                setOrderDetailView(false);
            } }
            maskClosable={ false }  // 点击蒙层是否可以关闭
            destroyOnClose={ true } // 关闭之后销毁子节点
            centered={ true } // 居中
            width="800px"
        >
            <div className="order-detail-container">
                <div className="order-detail-table-container">
                    <antdUI.Table
                        pagination={ false }
                        dataSource={ dataSource }
                        // locale={{ emptyText: '暂无数据' }}
                        columns={ columns }
                        scroll={ { y: 400 } }
                    >
                    </antdUI.Table>
                </div>
                
                <div className="order-detail-bottom-bar">
                    <div className="income">
                        预计支付：
                        <p className="all-price">{ Sum(orderDetailData.totalPrice, orderDetailData.handlingFee) }元
                            
                            <span className="item-num">/共{ dataSource.length }件</span>
                            <span className="item-num"
                                  style={ { marginLeft: '20px' } }>含手续费{ orderDetailData.handlingFee }元</span>
                        </p>
                    </div>
                    
                    { type === 'buy' && <antdUI.Button type="primary"
                                                       onClick={ debounce(acceptOrder.bind(null, orderDetailData.orderNumber), 2000, true) }
                    >购买订单</antdUI.Button> }
                </div>
            </div>
        </antdUI.Modal>
    )
}


export default OnShelfContainer;


let tabBox = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgb(240, 147, 41)'
    },
    tabH = {
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgb(240, 147, 41)',
    },
    favoritesItemStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        transform: 'scale(1.1)'
    },
    tabImg = {
        width: '65px'
    },
    tabItemName = {
        maxWidth: '180px',
        fontSize: '14px',
        color: '#222222',
    },
    floatval = {
        maxWidth: '180px',
        fontSize: '12px',
        color: '#999999'
    };
let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
let columns = [
    {
        title: <p style={ titleStyle }>图片</p>,
        dataIndex: 'item',
        key: 'item',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: 'name',
        key: 'name',
        render: text => text
    },
    
    {
        title: <p style={ titleStyle }>实付金额</p>,
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
        render: text => text
    }, {
        title: <p style={ titleStyle }>描述</p>,
        dataIndex: 'description',
        key: 'description',
        render: text => text
    }
];
