import React, { antdUI } from 'react';
import "./index.scss"

function HelpCenterContent(props) {
  const { helpContent, articleTitle, updateTime, loading } = props
  
  return (
      <div className="help-center-content">
        { loading ? <antdUI.Skeleton active/> : helpContent !== 'not' ? <>
              <div className="help-center-title">
                <h1>{ articleTitle }</h1>
                <p style={ { color: "#949494", marginTop: "14px", fontSize: "15px" } }>最后更新于 { updateTime }</p>
              </div>
              <div className="help-content ql-editor" dangerouslySetInnerHTML={ {
                __html: helpContent
              } }/>
            </>
            : <h1 style={ { fontSize: '26px' } }>未找到该文档</h1> }
      </div>
  )
}


export default HelpCenterContent;
