import $api from '@/api'
const UP_DATE_FAVORITES_DATA = 'UP_DATE_FAVORITES_DATA'

function upDateFavorites(data) {
  return { type: UP_DATE_FAVORITES_DATA, data }
}

const upDateFavoritesData = () => dispatch => $api.user.getFavorites().then(res => {
  if (res.status === 200) {
    let dota2 = [], csgo = [];
    for (const item of res.data) {
      switch (item.appid) {
        case 730: csgo.push(item); break;
        case 570: dota2.push(item); break;
        default: break;
      }
    }
    dispatch(upDateFavorites({ dota2, csgo }))
  } else {
    dispatch(upDateFavorites({ csgo: [], dota2: [] }))
  }
})


export { upDateFavoritesData }