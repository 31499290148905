import React, { $api, $CodeLogin, $tools, antdUI, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';
import MobiLoginModal from '@/components/login'
import ChangeBindPhone from './changeBindPhone.js'

let emailReg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/;
const changePasswordTips = (
    <div className="apikey-box-text" style={ {
        padding: '14px', width: '260px', backgroundColor: '#191919',
        boxShadow: `0px 0px 6px 1px #191919`
    } }>
        <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>修改密码：</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>APP在“我的”页面右上角设置中的“账号与安全”中修改</p>
        <p style={
            { color: '#fff', fontSize: '14px' }
        }>电脑网页端在登录弹窗中选择使用密码验证登录后，点击忘记密码，进行修改</p>
    </div>
)

function AccountInfo(props) {
    const { userInfo, setUserSignIn } = props;
    const { inputValueLimit, checkResult, getUrlQueryValue } = $tools
    
    const [ visible, setVisible ] = useState(false),
        [ modalTitle, setModalTitle ] = useState('支付宝身份验证(推荐)'),
        [ modalType, setModalType ] = useState(''),
        [ email, setEmail ] = useState(''),
        [ userName, setUserName ] = useState(''),
        [ identifyNum, setIdentifyNum ] = useState(''),
        [ logon_id, setLogon_id ] = useState(''),
        [ modalBtnLoad, setModalBtnLoad ] = useState(false),
        [ protocol, setProtocol ] = useState(false);
    
    const [ loginMo, setLoginM ] = useState(false),
        [ loginTitle, setLoginTitle ] = useState('登录/注册');
    
    const [ changeBindPhoneView, setChangeBindPhoneView ] = useState(false)
    
    useEffect(_ => {
        const failReason = getUrlQueryValue('failReason')
        if (getUrlQueryValue('failReason')) {
            antdUI.message.warning(failReason)
            window.history.pushState('', '', 'https://www.mobi2077.com/profile/userConfig')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const iconOk = <i className="iconfont icon-gou" style={ {
        fontSize: '28px', marginRight: "10px", color: '#2DAF61',
        position: 'relative', left: '-2px'
    } }></i>
    const iconNo = <i className="iconfont icon-cha"
                      style={ { fontSize: '24px', marginRight: "12px", color: '#FF4C20' } }></i>
    
    const { real_name_status, uid, phone } = userInfo;
    const showModal = (type) => {
        if (! uid) {
            antdUI.message.error('请先绑定手机号')
        } else {
            switch (type) {
                case 'email':
                    setModalTitle('输入邮箱');
                    break;
                case 'zfbReal':
                    if (userInfo?.loginType !== 'code') {
                        antdUI.message.warning('您必须使用手机验证码登录，才可进行当前操作！')
                        return $CodeLogin()
                    }
                    setModalTitle('支付宝身份验证(推荐)');
                    break;
                default:
                    break;
            }
            setVisible(true)
            setModalType(type)
        }
        
    };
    const deleteData = () => {
        setIdentifyNum('')
        setUserName('')
        setEmail('')
        setLogon_id('')
        setProtocol(false)
    }
    const handleOk = async () => {
        switch (modalType) {
            case 'email':
                let emailResult = email.match(emailReg)
                if (emailResult) {
                    let result = await $api.user.setUpEmail({ mailbox: email });
                    if (checkResult(result)) {
                        antdUI.message.success('修改成功')
                        deleteData()
                        setUserSignIn()
                        setVisible(false)
                    }
                } else {
                    antdUI.message.error('邮箱格式有误')
                }
                break;
            case 'real':
                if (! protocol) return antdUI.message.warning('请先同意用户协议和隐私政策');
                if (userName && identifyNum) {
                    setModalType('realStart')
                } else {
                    antdUI.message.error('请把信息填写完整')
                }
                break;
            case 'zfbReal':
                if (! protocol) return antdUI.message.warning('请先同意用户协议和隐私政策');
                if (userName && identifyNum && logon_id) {
                    setModalType('realStart')
                } else {
                    antdUI.message.error('请把信息填写完整')
                }
                break;
            case  'realStart':
                let result
                switch (modalTitle) {
                    case '实名认证' :
                        setModalBtnLoad(true)
                        result = await $api.user.mobiRealName({ userName, identifyNum });
                        setModalBtnLoad(false)
                        if (checkResult(result)) {
                            antdUI.message.success('实名认证成功')
                            deleteData();
                            setUserSignIn()
                            setVisible(false)
                        }
                        break;
                    case '支付宝身份验证(推荐)':
                        setModalBtnLoad(true)
                        result = await $api.user.mobiZfbRealName({
                            user_name: userName,
                            cert_no: identifyNum,
                            logon_id, type: 'PC'
                        });
                        setModalBtnLoad(false)
                        if (checkResult(result)) {
                            let { authorizedUrl } = result.data
                            deleteData();
                            // setUserSignIn()
                            window.location.href = authorizedUrl
                            // window.open(
                            //     authorizedUrl,
                            //     "_blank",
                            //     `top=0,left=0,width=800,height=1000,menubar=no,resizable=no,toolbar=no,status=no,scrollbars=no`
                            // );
                            setVisible(false)
                        }
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };
    
    const cancelModel = () => {
        setModalTitle('支付宝身份验证(推荐)')
        setVisible(false)
        deleteData()
    }
    
    
    return (
        <div className="account-info">
            <ChangeBindPhone view={ {
                changeBindPhoneView, setChangeBindPhoneView
            } }/>
            <antdUI.Modal
                title={ modalTitle }
                visible={ visible }
                onOk={ handleOk }
                onCancel={ cancelModel }
                width="550px"
                footer={ [
                    <antdUI.Button onClick={ cancelModel }>
                        取消
                    </antdUI.Button>,
                    <antdUI.Button type="primary" loading={ modalBtnLoad } onClick={ handleOk }>
                        确认
                    </antdUI.Button>
                ] }
                maskClosable={ false }>
                { [ 'real', 'realStart', 'zfbReal' ].indexOf(modalType) !== -1 ? <div
                    style={ {
                        position: 'absolute', width: '550px', textAlign: 'center',
                        top: '55px', left: '0', padding: '10px 0', backgroundColor: '#f3f7f3',
                        color: '#59ae6a'
                    } }
                >个人信息用于实名认证，一旦认证不能修改，请认真填写。
                </div> : '' }
                { (modalType === 'real' || modalType === 'zfbReal') ? <>
                    <div className="flex jtyct ait"
                         style={ { width: '100%', margin: '50px 0 20px 0' } }>
                        <p style={ { width: '15%', textAlign: 'right', marginRight: '10px' } }>真实姓名</p>
                        <antdUI.Input style={ { width: '70%' } }
                                      value={ userName }
                                      onChange={ (e) => setUserName(e.target.value) }/>
                    </div>
                    <div className="flex jtyct ait" style={ { width: '100%', marginBottom: '20px' } }>
                        <p style={ { width: '15%', textAlign: 'right', marginRight: '10px' } }>身份证号</p>
                        <antdUI.Input style={ { width: '70%' } }
                                      value={ identifyNum }
                                      onChange={ (e) => setIdentifyNum(e.target.value) }/>
                    </div>
                    { modalType === 'zfbReal' ?
                        < div className="flex jtyct ait" style={ { width: '100%', marginBottom: '20px' } }>
                            <p style={ { width: '15%', textAlign: 'right', marginRight: '10px' } }>支付宝账号</p>
                            <antdUI.Input style={ { width: '70%' } }
                                          value={ logon_id }
                                          onChange={ (e) => setLogon_id(e.target.value) }/>
                        </div> : '' }
                    { modalTitle === '实名认证' ? <div className="flex jtyct ait" style={ { marginBottom: '40px' } }>
                        <antdUI.Checkbox style={ { margin: '0' } } onChange={ (e) => setProtocol(e.target.checked) }
                                         defaultChecked={ false }>
                            <p style={ { display: 'inline', color: '#999', fontSize: '12px' } }>我同意</p>
                        </antdUI.Checkbox>
                        <p>
                            <a href="https://www.mobi2077.com/public/help/privacyPolicy.html" rel="noopener noreferrer"
                               target="_blank">《隐私政策》</a>和
                            <a href="https://www.mobi2077.com/public/help/userAgreement.html" rel="noopener noreferrer"
                               target="_blank">《用户协议》</a>
                        </p>
                    </div> : '' }
                    { modalTitle === '支付宝身份验证(推荐)' ?
                        <div className="flex jtyct ait" style={ { marginBottom: '40px' } }>
                            <antdUI.Checkbox style={ { margin: '0' } } onChange={ (e) => setProtocol(e.target.checked) }
                                             defaultChecked={ false }>
                                <p style={ { display: 'inline', color: '#999', fontSize: '12px' } }>我同意</p>
                            </antdUI.Checkbox>
                            <p>
                                <a href="https://www.mobi2077.com/public/help/privacyPolicy.html"
                                   rel="noopener noreferrer"
                                   target="_blank">《隐私政策》</a>和
                                <a href="https://www.mobi2077.com/public/help/userAgreement.html"
                                   rel="noopener noreferrer"
                                   target="_blank">《用户协议》</a>
                            </p>
                        </div> : '' }
                    { [ 'real', 'zfbReal' ].indexOf(modalType) !== -1 ? <div className="flex jtyct ait" style={ {
                        position: 'absolute', width: '550px', textAlign: 'center',
                        bottom: '53px', left: '0', padding: '10px 0', backgroundColor: '#f0f0f0',
                        color: '#999'
                    } }>
                        { modalType === 'real' ? <p style={ { cursor: 'pointer' } } onClick={ _ => {
                            setModalType('zfbReal')
                            setModalTitle('支付宝身份验证(推荐)')
                            deleteData()
                        } }>支付宝身份验证(推荐) <i style={ { fontSize: '12px' } }
                                                    className="iconfont icon-arrow-right"></i>
                        </p> : '' }
                        {
                            // { modalType === 'zfbReal' ? <p style={ { cursor: 'pointer' } } onClick={ _ => {
                            //     setModalType('real')
                            //     setModalTitle('实名认证')
                            //     deleteData()
                            // } }>普通二要素实名认证 <i style={ { fontSize: '12px' } }
                            //                           className="iconfont icon-arrow-right"></i>
                            // </p> : '' }
                        }
                    </div> : '' }
                </> : '' }
                { modalType === 'realStart' ? <>
                    <p style={ { width: '60%', margin: '50px auto 0 auto' } }>{
                        `姓名：${ userName }`
                    }</p><p style={ { width: '60%', margin: '10px auto 10px auto' } }>{
                    `身份证号：${ identifyNum }`
                }</p>{ modalTitle === '支付宝身份验证(推荐)' ?
                    <p style={ { width: '60%', margin: '0px auto 10px auto' } }>{
                        `支付宝账号：${ logon_id }`
                    }</p> : '' }
                    { modalTitle === '实名认证' ? <p style={ { textAlign: 'center', fontWeight: '900' } }>
                        请确认填写的姓名及身份证号是你本人的且正确无误，错误信息将导致认证不通过，非本人信息会影响网站功能使用（如提现）。
                    </p> : '' }
                    { modalTitle === '支付宝身份验证(推荐)' ? <p style={ { textAlign: 'center', fontWeight: '900' } }>
                        信息仅用于支付宝身份验证。请确认填写的姓名、身份证号和支付宝账号是你本人的且正确无误，点击确认将跳转支付宝，请使用上方填写的支付宝账号进行登录验证，错误或者不匹配的信息将导致认证不通过。认证通过后，该支付宝账号将作为默认提现账号（可使用大额提现功能）。
                    </p> : '' }
                </> : '' }
                { modalType === 'email' ? <antdUI.Input
                    value={ email }
                    onChange={ (e) => setEmail(inputValueLimit(e.target.value)) }
                    placeholder={ modalTitle }/> : '' }
            </antdUI.Modal>
            <div className="title">
                <h1>账号信息设置</h1>
            </div>
            {/*<div className="email">*/ }
            {/*    <p className="title">邮箱</p>*/ }
            {/*    <div className="bind">*/ }
            {/*        <div className="status">*/ }
            {/*            { mailbox ? iconOk : iconNo }*/ }
            {/*            { mailbox ? <p>已绑定</p> : <p style={ { color: '#FF4C20' } }>未绑定</p> }*/ }
            {/*        </div>*/ }
            {/*        { mailbox && <div className="content">*/ }
            {/*            <p>绑定邮箱 { mailbox }</p>*/ }
            {/*        </div> }*/ }
            {/*        <div onClick={ () => showModal('email') }*/ }
            {/*             className="bind-btn">{ mailbox ? '修改' : '绑定' }</div>*/ }
            {/*    </div>*/ }
            {/*</div>*/ }
            
            <div className="phone">
                <p className="title">手机号</p>
                <div className="bind">
                    <div className="status">
                        { phone ? iconOk : iconNo }
                        { phone ? <p>已绑定</p> : <p style={ { color: '#FF4C20' } }>未绑定</p> }
                    </div>
                    { phone && <div className="content">
                        <p>绑定手机号 {
                            // userInfo.phone.split('').map((_, i) => [3, 4, 5, 6].indexOf(i) !== -1 ? '*' : _).join('')
                            phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
                        }</p>
                    </div> }
                    {
                        (userInfo.uid || userInfo.steam.steamid) ? uid ?
                            <antdUI.Button
                                className="antd-button-sm" type="primary"
                                onClick={ () => setChangeBindPhoneView(true) }>更换
                            </antdUI.Button>
                            : <antdUI.Button
                                className="antd-button-sm" type="primary"
                                onClick={ () => {
                                    setLoginTitle('登录/注册')
                                    setLoginM(true)
                                } }>绑定
                            </antdUI.Button> : ''
                    }
                </div>
            </div>
            
            <div className="real-name">
                <p className="title">实名认证</p>
                <div className="bind">
                    <div className="status">
                        { real_name_status ? iconOk : iconNo }
                        { real_name_status ? <p className="ok-bind">已认证</p> :
                            <p className="not-bind">实名认证填写身份证</p> }
                    </div>
                    { (userInfo.uid || userInfo.steam.steamid) ? real_name_status ? '' :
                        <antdUI.Button className="antd-button-sm" style={ { marginLeft: '36px' } }
                                       type="primary" onClick={ showModal.bind(this, 'zfbReal') }>认证
                        </antdUI.Button> : ''
                    }
                </div>
            </div>
            <div className="real-name">
                <p className="title ">修改密码
                    <antdUI.Popover placement="right" content={ changePasswordTips }>
                        <i style={ { marginLeft: 10, color: "#000" } } className="iconfont icon-wenhao"></i>
                    </antdUI.Popover>
                </p>
            </div>
            <MobiLoginModal title={ loginTitle } loginMo={ loginMo } setLoginM={ setLoginM }
                            setLoginTitle={ setLoginTitle }/>
        
        
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(AccountInfo);
