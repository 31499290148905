import React, { $api, $tools, antdUI, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';
import CountryCode from '@/data/countryCode.js'

function ChangeBindPhone(props) {
    const { userInfo, setUserSignIn, view: { changeBindPhoneView, setChangeBindPhoneView } } = props
    
    const { checkResult, debounce, inputValueLimit } = $tools
    const {
        changeBindPhoneSendCodeOld, changeBindOldCheck,
        changeBindPhoneSendCodeNew, changeBindNewPhone
    } = $api.user
    const { Modal, Button, Input, Select, message } = antdUI
    
    const [ steps, setSteps ] = useState('secondaryConfirmation') //  => ["secondaryConfirmation", "oldCheck", "bindNewPhone", "success"]
    
    const [ btnText, setBtnText ] = useState('获取验证码')
    const [ codeLoa, setCodeLoa ] = useState(false)
    const [ oldPhoneCode, setOldPhoneCode ] = useState('')
    
    const [ selectVal, setSelectVal ] = useState('+86')
    const [ newBtnText, setNewBtnText ] = useState('获取验证码')
    const [ newCodeLoa, setNewCodeLoa ] = useState(false)
    const [ newPhone, setNewPhone ] = useState('')
    const [ newPhoneCode, setNewPhoneCode ] = useState('')
    const [ newPhoneState, setNewPhoneState ] = useState(false)
    
    const oldNext = async () => {
        if (! oldPhoneCode || String(oldPhoneCode).length !== 4) return message.warning('请填写正确的手机验证码！')
        const result = await changeBindOldCheck({ code: oldPhoneCode })
        if (! checkResult(result)) return
        
        setSteps('bindNewPhone')
    }
    
    const changeBindPhone = async () => {
        if (! newPhoneCode || String(newPhoneCode).length !== 4) return message.warning('请填写正确的手机验证码！')
        
        const result = await changeBindNewPhone({
            phone: newPhone,
            countryCode: selectVal.replace('+', ''),
            code: newPhoneCode
        })
        if (! checkResult(result)) return
        
        setUserSignIn()
        setSteps('success')
    }
    const { updatePhone } = userInfo
    return (
        <div className="change-bind-phone-modal-box">
            <Modal
                className="change-bind-phone-modal-box"
                title={ "更换手机号" } visible={ changeBindPhoneView }
                onCancel={ () => {
                    setChangeBindPhoneView(false)
                    
                    setSteps('secondaryConfirmation')
                    setOldPhoneCode('')
                    
                    setSelectVal('+86')
                    setNewPhone('')
                    setNewPhoneCode('')
                    setNewPhoneState(false)
                } }
                width="470px" footer={ [] } maskClosable={ false }
            >
                <div className="change-bind-phone-modal-content-box">
                    { (_ => {
                        switch (steps) {
                            case 'secondaryConfirmation':
                                return <div style={ { textAlign: "center" } }>
                                    <img style={ { width: 60 } }
                                         src={ require('@/images/profile/zhuyi@2x.png') }
                                         alt=""/>
                                    
                                    {
                                        (updatePhone && new Date(updatePhone).getTime() >= new Date().getTime()) ?
                                            <div style={ { margin: "25px 0 25px 25px" } }>45天内最多可更换一次手机号，请勿频繁更换手机号，<br/> 您可以在
                                                <span style={ { color: "#29a757" } }> { updatePhone } </span> 后再次更换
                                            </div> :
                                            <>
                                                <div className="text-content" style={ {
                                                    width: 300, margin: "24px 0 25px 65px", textAlign: 'center'
                                                } }>
                                                    <p>更换手机流程仅用于用户本人手机号码变更，原手机及新手机都需要接受短信验证码进行验证，45天内可更换一次</p>
                                                </div>
                                                <Button style={ { width: "100%", borderRadius: 4 } }
                                                        size="large" type="primary"
                                                        onClick={ _ => setSteps('checkOld') }>确定</Button>
                                            </>
                                    }
                                </div>;
                            case 'checkOld':
                                return <div style={ { textAlign: "center", marginTop: 11 } }>
                                    <h3 style={ { fontSize: 18 } }>发送验证码至手机: <span
                                        style={ { color: "#2DAF61" } }>
                                        (+{ userInfo.countryCode }) { userInfo.phone }
                                    </span></h3>
                                    <p style={
                                        { color: "#999", marginTop: 14 }
                                    }>为了您的账号安全,换绑前请先验证身份</p>
                                    
                                    <antdUI.Input.Search
                                        style={ { marginTop: 26 } }
                                        placeholder="请输入验证码" value={ oldPhoneCode } size="large"
                                        onChange={ (e) => setOldPhoneCode(inputValueLimit(e.target.value, 4).replace(/[^0-9]/g, "")) }
                                        onSearch={ async _ => {
                                            if (btnText !== '获取验证码') return
                                            let countdown = 60, timer = null;
                                            setCodeLoa(true)
                                            const result = await changeBindPhoneSendCodeOld();
                                            
                                            if (! checkResult(result)) return setCodeLoa(false)
                                            antdUI.message.success("发送成功");
                                            setBtnText(`重新发送(${ countdown }s)`);
                                            timer = setInterval(() => {
                                                countdown--
                                                if (countdown === 0) {
                                                    clearInterval(timer)
                                                    setBtnText('获取验证码');
                                                    setCodeLoa(false)
                                                } else {
                                                    setBtnText(`重新发送(${ countdown }s)`);
                                                }
                                            }, 1000)
                                        } }
                                        enterButton={
                                            <Button
                                                style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                                loading={ codeLoa }>{ btnText }</Button>
                                        }/>
                                    
                                    <Button style={ { width: "100%", marginTop: 28, borderRadius: 4 } }
                                            size="large" type="primary"
                                            onClick={ debounce(oldNext, 2000, true) }>下一步</Button>
                                </div>;
                            case 'bindNewPhone':
                                return <div style={ { textAlign: "center", marginTop: 10 } }>
                                    {
                                        newPhoneState ? <>
                                                <h3 style={ { fontSize: 18 } }>发送验证码至新的手机号:
                                                    <span style={ { color: "#2DAF61" } }>
                                                        ({ selectVal }) { newPhone }
                                                    </span>
                                                </h3>
                                                <p style={ { color: "#999", marginTop: 14 } }>
                                                    为了您的账号安全,换绑前请先验证身份
                                                </p>
                                            </>
                                            : <><h3 style={ { fontSize: 18, marginBottom: 39 } }>请输入新的手机号</h3>
                                                <Input.Group size="large" compact>
                                                    <Select onChange={ val => {
                                                        setSelectVal(`+${ val }`)
                                                        if (val !== 86) {
                                                            setNewPhoneCode('')
                                                        }
                                                    } } style={ { width: '120px', fontSize: '16px' } }
                                                            value={ selectVal }>
                                                        { CountryCode.map(({ countryCN, countryCode }, idx) => {
                                                            return <Select.Option key={ idx } value={ countryCode }
                                                            > { countryCN } +{ countryCode }
                                                            </Select.Option>
                                                        }) }
                                                    </Select>
                                                    <antdUI.Input
                                                        value={ newPhone } style={ { width: '290px' } }
                                                        onChange={ e => setNewPhone(inputValueLimit(e.target.value, 15).replace(/[^0-9]/g, "")) }
                                                        placeholder="请输入手机号"
                                                    />
                                                </Input.Group></>
                                    }
                                    
                                    
                                    <antdUI.Input.Search
                                        style={ { marginTop: 26 } }
                                        placeholder="请输入验证码" value={ newPhoneCode } size="large"
                                        onChange={ e => setNewPhoneCode(inputValueLimit(e.target.value, 4).replace(/[^0-9]/g, "")) }
                                        onSearch={ async _ => {
                                            if (! newPhone || ! selectVal) return message.error("请输入手机号");
                                            if (newBtnText !== '获取验证码') return
                                            let countdown = 60, timer = null;
                                            setNewCodeLoa(true)
                                            const result = await changeBindPhoneSendCodeNew({
                                                phone: newPhone, countryCode: selectVal.replace('+', '')
                                            });
                                            
                                            if (! checkResult(result)) return setNewCodeLoa(false)
                                            
                                            setNewPhoneState(true)
                                            
                                            antdUI.message.success("发送成功");
                                            setNewBtnText(`重新发送(${ countdown }s)`);
                                            timer = setInterval(() => {
                                                countdown--
                                                if (countdown === 0) {
                                                    clearInterval(timer)
                                                    setNewBtnText('获取验证码');
                                                    setNewCodeLoa(false)
                                                } else {
                                                    setNewBtnText(`重新发送(${ countdown }s)`);
                                                }
                                            }, 1000)
                                        } }
                                        enterButton={
                                            <antdUI.Button
                                                style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                                loading={ newCodeLoa }>{ newBtnText }</antdUI.Button>
                                        }/>
                                    
                                    <Button style={ { width: "100%", marginTop: 28, borderRadius: 4 } }
                                            size="large" type="primary"
                                            onClick={ _ => changeBindPhone() }>确认绑定</Button>
                                </div>;
                            case 'success':
                                return <div style={ { textAlign: "center" } }>
                                    <h3 style={ { fontSize: 18, color: '#333', marginBottom: 18 } }>更换手机号成功</h3>
                                    
                                    <i className="iconfont icon-gou"
                                       style={ { fontSize: 58, color: "#2DAF61" } }></i>
                                    
                                    <Button style={ { width: "100%", marginTop: 30, borderRadius: 4, } }
                                            size="large" type="primary"
                                            onClick={ _ => {
                                                setChangeBindPhoneView(false)
                                        
                                                setSteps('secondaryConfirmation')
                                                setOldPhoneCode('')
                                        
                                                setSelectVal('+86')
                                                setNewPhone('')
                                                setNewPhoneCode('')
                                                setNewPhoneState(false)
                                            } }>确定</Button>
                                </div>
                            default:
                                break;
                        }
                    })() }
                </div>
            </Modal>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(ChangeBindPhone);
