import React from 'react';
import './index.scss'

function NotFound() {
  return (
    <div className="not-found">
      <h1>没有该页面</h1>
    </div>
  )
}

export default NotFound;