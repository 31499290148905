import React, { antdUI, $api } from 'react';
import './index.scss'

function HelpCenterNav(props) {
    const { helpNavdata, handleNavData, setHelpContent, setArticleTitle, setUpdateTime, setLoading } = props;
    const { Panel } = antdUI.Collapse;
    const typeTitle = (title, cur) => {
        let cls = `f16 help-hover-nav ${ cur ? 'help-type-cur' : '' }`
        return <h2 className={ cls }>{ title }</h2>
    }
    const changeNav = async (nav, type) => {
        handleNavData(nav, type, helpNavdata)
        window.location.href = `/help#${ nav }`;
        setLoading(true)
        let res = await $api.siteSetting.getHelp({ nav })
        setLoading(false)
        setHelpContent(res.data?.content)
        setArticleTitle(res.data?.articleTitle)
        setUpdateTime(res.data?.updateTime)
    }
    
    return (
        <div className="help-center-nav-box">
            { helpNavdata[0] ? <antdUI.Collapse
                accordion={ true }
                defaultActiveKey={ [ helpNavdata.filter(_ => _.current)[0]?.type ] }
            >
                {
                    helpNavdata.map(item => {
                        return (
                            <Panel showArrow={ false } header={ typeTitle(item.type_title, item.current) }
                                   key={ item.type }>
                                {
                                    item.children.length ?
                                        item.children.map(el => {
                                            return (
                                                <p className={ `f14 text-ov1 pint help-nav ${ el.current ? "help-nav-cur" : '' }` }
                                                   style={ { margin: '10px 0', textIndent: "8px" } }
                                                   onClick={ _ => changeNav(el.nav, item.type) }
                                                   key={ el.nav }>
                                                    { el.current ? <i className="iconfont icon-web-switch"></i> : '' }
                                                    { el.nav_title }
                                                </p>
                                            )
                                        }) : ''
                                }
                            </Panel>
                        )
                    })
                }
            </antdUI.Collapse> : '' }
        </div>
    )
}


export default HelpCenterNav;

