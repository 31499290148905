import React, { useEffect, $tools } from 'react'
import './index.scss';
import ItemWear from '../itemContainer/itemWear';

const csgoOtherData = [ "CSGO_Tool_Sticker", "CSGO_Type_Spray", "CSGO_Type_Tool", "CSGO_Type_Collectible", "CSGO_Type_Collectible", "CSGO_Type_Ticket", "CSGO_Tool_GiftTag", "CSGO_Type_MusicKit", "CSGO_Type_WeaponCase", "Type_CustomPlayer", "CSGO_Tool_Patch" ]

function filterTagAttribute(target = [], type) {
    return target.filter(_ => _.category.toLowerCase() === type)[0] ? target.filter(_ => _.category.toLowerCase() === type)[0] : {}
}

function ItemDetailPopover(props) {
    const { BASE_STEAM_IMG, BASE_CSGO_STICKER } = $tools;
    const { csData, index, type, firstType = '' } = props
    const { customname } = csData || {}
    const marketDetail = csData.market_item_detail ? Number(csData.market_item_detail.error) === 0 ?
        csData.market_item_detail : {} : {};
    const user_info = csData.user_info
    
    useEffect(() => {
        type !== 'onSale' && filterTagAttribute(csData.tags, [ 'type', 'Quality' ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    let cls = 'checkView'
    if (index && index !== 0) {
        if (index % 5 === 0) {
            cls = 'checkView right'
        }
    }
    return (
        <div className={ cls }>
            <div className='gun'>
                { marketDetail?.msg?.screenshot?.result ?
                    <img style={ { width: '100%' } } src={ marketDetail?.msg?.screenshot?.result } alt=""/> :
                    <img style={ { width: csData.appid === 570 && '80%', margin: csData.appid === 570 && '10px 0' } }
                         src={ BASE_STEAM_IMG + csData.icon_url } alt=""/> }
            </div>
            <div className='gun-msg'>
                { Boolean(csData.sellingPrice || csData.price) && <div className='buy-msg'>
                    <p>价格<span className='item price'>￥{ csData.sellingPrice || csData.price }</span></p>
                    { type !== 'onSale' && <div className="buy-btn-box">
                        {/* <button style={{ color: '#AAAAAA', background: '#383D46' }}>还价</button>
                         <button style={{ color: 'white', background: '#4773C8', marginLeft: '12px' }}>购买</button> */ }
                    </div> }
                </div> }
                
                <h3 style={ { color: 'white', margin: '5px 0 15px 0' } }>{ csData.market_name }</h3>
                { customname &&
                    <div className="customname flex ait"><i className="iconfont icon-zhuyi"
                                                            style={ { marginRight: 10 } }></i>{ customname }</div> }
                
                { type !== 'onSale' && <p>{ filterTagAttribute(csData.tags, 'type').localized_tag_name }</p> }
                { csgoOtherData.indexOf(firstType) === -1 && csData.market_item_detail?.msg && csData.market_item_detail?.msg.paintseed &&
                    <p>图案模板(paint seed)：{ csData.market_item_detail?.msg.paintseed }</p> }
                { csgoOtherData.indexOf(firstType) === -1 && csData.market_item_detail?.msg && csData.market_item_detail?.msg.paintindex &&
                    <p>皮肤编号(paint index)：{ csData.market_item_detail?.msg.paintindex }</p> }
                
                { csgoOtherData.indexOf(firstType) === -1 && marketDetail.msg && marketDetail.msg.floatvalue &&
                    <ItemWear
                        type="detailPopover"
                        wearValue={ marketDetail.msg.floatvalue } market_item_detail={ marketDetail }/> }
            </div>
            { type !== 'onSale' && user_info && <div className='seller-msg'>
                <div className='base'>
                    { user_info && user_info.avatar &&
                        <div className="user-box"><img className='avatar' src={ user_info.avatar } alt='卖家头像'/>
                            <p style={ { width: '110px', color: 'white' } } className='text-ov1'>{ user_info.name }</p>
                            <img style={ { height: '26px', width: 'auto' } }
                                 src={ $tools.getCredibilityIcon(csData?.userInfo.reputationPoints || 0) }
                                 alt={ csData?.userInfo.reputationPoints || 0 }/>
                        </div>
                    }
                    {/*<div className='base-tab'>*/ }
                    {/*  <button onClick={() => setIsMonth(true)} className={isMonth ? 'normalBtn' : 'activeBtn'} >7天</button>*/ }
                    {/*  <button onClick={() => setIsMonth(false)} className={!isMonth ? 'normalBtn' : 'activeBtn'}>30天</button>*/ }
                    {/*</div>*/ }
                
                </div>
                <div className='detail'>
                    <div>
                        <p>信誉积分</p>
                        <p>{ csData?.userInfo?.reputationPoints || 0 }分</p>
                    </div>
                </div>
            </div> }
            
            
            <div className='describe'>
                <div style={ {
                    display: 'flex',
                    margin: '4px 0 10px 0',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                } }>
                    <h3 style={ { color: 'white' } }>属性描述</h3>
                    {/* <button style={{ background: '#696969', color: 'white' }}>刷新印花</button> */ }
                </div>
                
                {
                    csData.descriptions && csData.descriptions.map((item, idx) => {
                        let htmlContent = item.value;
                        if (htmlContent.match('sticker_info')) {
                            return ''
                        }
                        return <p key={ idx } style={ { color: `#${ item.color }` } }
                                  dangerouslySetInnerHTML={ { __html: htmlContent } }/>
                    })
                }
                {
                    csgoOtherData.indexOf(firstType) === -1 && marketDetail.msg && marketDetail.msg.stickers && marketDetail.msg.stickers[0] &&
                    <>
                        <h3 style={ { marginTop: '10px', fontWeight: '600' } }>包含印花</h3>
                        { marketDetail.msg.stickers.map((item, index) => {
                            return (
                                <div key={ index } className='printing'>
                                    <div className='img'>
                                        <img src={ BASE_CSGO_STICKER + item.material + '.png' } alt=""/>
                                    </div>
                                    <div className='msg'>
                                        <p className='name text-ov2'>{ item.name }</p>
                                        <p style={ { color: 'white', fontSize: "12px" } }
                                           className='Wear'>印花磨损: { item.wear ? ~~((1 - item.wear) * 100) : 100 }%
                                            <span style={ { fontSize: "12px" } }></span>
                                        </p>
                                    </div>
                                </div>
                            )
                        }) }
                    </>
                }
            </div>
        </div>
    
    )
    
}


export default ItemDetailPopover
