import React, { $api, $csgoWearColor, $tools, antdUI, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ItemWear from "@/components/itemContainer/itemWear";
import ItemDetailPopover from "@/components/itemDetailPopover";
import Rune from './rune';
import { _MarbleFade, Doppler, GammaDoppler } from '@/data/dopplerData.js'

function CsD2Box(props) {
    let { csData: v = {}, style: csgo_st, index, selected: _sel, changeItem, currentGame, d2Config = {} } = props;
    const { setModalData, setView } = d2Config
    const {
        descriptions,
        sellingPrice,
        floatvalue,
        paintindex,
        paintseed,
        market_name,
        actions,
        icon_url,
        stickers,
        customname,
        CsgoItem = {}
    } = v;
    const { type, weapon, rarity, quality, exterior, img_name } = CsgoItem;
    const { obj2strUrl } = $tools
    const img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    const [ popoverData, setPopoverData ] = useState({}); //弹出详情以及磨损需要的数据
    const [ itemCls, setItemCls ] = useState('csgo-items')
    let tStyle;
    if (market_name.match(' 多普勒 ')) {
        tStyle = Doppler.children;
    } else if (market_name.match(' 伽玛多普勒 ')) {
        tStyle = GammaDoppler.children;
    } else if (market_name.match('爪子刀（★） | 渐变大理石 ') || market_name.match('刺刀（★） | 渐变大理石 ')
        || market_name.match('折叠刀（★） | 渐变大理石 ') || market_name.match('穿肠刀（★） | 渐变大理石 ')) {
        tStyle = _MarbleFade.children;
    }
    let itemsStyle = {}
    if (v.market_item_detail &&
        v.market_item_detail?.msg &&
        v.market_item_detail?.msg.paintindex && tStyle) {
        if (tStyle[0].value[0] === '[') {
            itemsStyle = tStyle.filter(_ => JSON.parse(_.value).indexOf(Number(v.market_item_detail?.msg.paintindex)) !== -1)[0]
        } else {
            itemsStyle = tStyle.filter(_ => _.value === Number(v.market_item_detail?.msg.paintindex))[0]
        }
    }
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2
        } else {
            return 1
        }
    }
    
    
    let quality_name, quality_color = '#b0c3d9';
    
    const setPoData = () => {
        let obj = {
            descriptions,
            sellingPrice,
            icon_url,
            market_name,
            customname,
            market_item_detail: {
                error: 0,
                msg: { floatvalue, paintseed, paintindex, stickers },
            },
        };
        setPopoverData(obj);
    };
    
    useEffect(() => {
        setPoData();
        // eslint-disable-next-line
    }, [ v ]);
    
    const selectChange = async (e) => {
        const targName = e.target.className;
        if (targName === "parsing-btn") {
            const data = await $api.steam.getMarketItemScreenshot({ inspecturl: actions });
            if (! $tools.checkResult(data)) return
            const screenshot = data?.data || {}
            if (screenshot?.status === 'DONE') {
                setModalData({
                    ...v, screenshot: screenshot?.result, weapon,
                    type, rarity, quality
                })
                setView(true)
            } else {
                return antdUI.message.success('检视图生成中，请耐心等待!')
            }
        } else if (targName === 'd3-btn') {
            const result_3d = await $api.steam.texture3D({ url: actions })
            if (! result_3d?.data) return antdUI.message.warning('监视失败！')
            const { body, code } = result_3d.data
            if (code === 0) {
                if (body.status === 'DONE') {
                    window.open(`https://www.mobi2077.com/item_3d/?url=${ v.actions }`)
                } else {
                    return antdUI.message.success('检视图生成中，请耐心等待!')
                }
            } else {
                return antdUI.message.warning('监视失败')
            }
        } else if (Number(v.orderItemsStatus) === 5) {
            _sel ? changeItem(v, "cancel") : changeItem(v, "select");
        }
    };
    
    const { color: exterior_bcc, name: exterior_name } = $csgoWearColor(exterior)
    
    switch (quality) {
        case 'normal':
            quality_name = "普通";
            break;
        case 'strange':
            quality_name = 'StatTrak™';
            quality_color = 'rgb(207,106,50)';
            break;
        case 'tournament':
            quality_name = '纪念品';
            quality_color = 'rgb(255,215,0)';
            break;
        case 'unusual':
            quality_name = '★';
            quality_color = 'rgb(134, 80, 172)';
            break;
        case 'unusual_strange':
            quality_name = '★ StatTrak™';
            quality_color = 'rgb(134, 80, 172)';
            break;
        default:
            break;
    }
    useEffect(() => {
        Number(currentGame) === 730 && setItemCls('csgo-items')
        Number(currentGame) === 570 && setItemCls('dota2-items')
    }, [ currentGame ])
    return (
        <div style={ csgo_st } className={ itemCls } onClick={ selectChange }>
            <div
                className="_img"
                style={ {
                    backgroundImage: Number(currentGame) !== 730 && 'none',
                    padding: Number(currentGame) !== 730 && '0',
                    minHeight: Number(currentGame) !== 730 && '140px'
                } }
                onLoad={ (e) => (e.currentTarget.style.opacity = 1) }
            >
                <div className="item-quality">
                    { Number(currentGame) === 730 && <p
                        value={ quality }
                        style={ {
                            color: `#${ quality_color }`,
                            padding: "0px 2px",
                            height: " 18px",
                            lineHeight: "18px"
                        } }
                        className="item_quality"
                    >
                        { quality_name }
                    </p> }
                    { paintseed && <p className="item_paint_seed" style={ {
                        padding: "0px 2px",
                        height: " 18px",
                        lineHeight: "18px"
                    } }>{ paintseed }</p> }
                    
                    { itemsStyle?.value && tStyle && (
                        <p className="item_paint_index" style={ {
                            padding: "0 2px",
                            height: "18px",
                            lineHeight: "18px",
                            color: itemsStyle?.color
                        } }>{ itemsStyle?.label }
                        </p>
                    ) }
                    
                    { Number(currentGame) === 730 && <img
                        className="link-icon"
                        onClick={ () => window.open(`${ actions }`) }
                        src={ require("@/images/inventory/yanjing@2x.png") }
                        alt="actions"
                    /> }
                </div>
                
                { exterior_bcc && (
                    <div
                        style={ {
                            backgroundColor: exterior_bcc,
                            padding: "0px 2px",
                            height: " 18px",
                            lineHeight: "18px"
                        } }
                        className="subscript"
                    >
                        { exterior_name }
                    </div>
                ) }
                <antdUI.Popover
                    placement="rightTop"
                    // trigger="click"
                    content={
                        <ItemDetailPopover type="onSale" index={ index } csData={ popoverData }/>
                    }
                >{ Number(currentGame) !== 730 ? <img
                    className="item-img"
                    style={ { transform: 'none' } }
                    src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                        : img_bs + icon_url }
                    alt={ market_name }
                /> : <img
                    className="item-img"
                    src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                        : img_bs + icon_url }
                    alt={ market_name }
                /> }
                </antdUI.Popover>
                
                
                { floatvalue && Number(floatvalue) > 0 && (
                    <div className="item-wear-container">
                        <ItemWear
                            wearValue={ floatvalue }
                            market_item_detail={ popoverData.market_item_detail }
                        />
                    </div>
                ) }
                { [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',
                        'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].includes(type) &&
                    <div className="d3-btn">3D</div> }
                
                {
                    [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',
                        'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].includes(type) &&
                    <div style={ {
                        height: "18px",
                        lineHeight: "16px",
                        padding: "0 3px"
                    } } className="parsing-btn">2D</div>
                }
                
                {
                    v && v.descriptions && <Rune descriptions={ descriptions }></Rune>
                }
            </div>
            <Link
                to={
                    {
                        pathname: '/marketPlace/accessoriesList',
                        search: obj2strUrl({
                            id: v.itemid,
                            curGame: v.appid,
                            curType: getPageType(quality),
                        }, '?')
                    }
                }
                target='_blank'
            >
                <p className="item-name">{ market_name }</p>
            </Link>
            { sellingPrice && (
                <p className="price">
                    <small>￥</small>
                    { parseInt(sellingPrice) }
                    <small>
                        { String(sellingPrice).indexOf(".") > 0 &&
                            String(sellingPrice).slice(String(sellingPrice).indexOf(".")) }
                    </small>
                </p>
            ) }
            { Number(v.orderItemsStatus) === 5 ? <div className={ [ `item-select ${ _sel ? "selected" : "" }` ] }>
                <i className="iconfont icon-hook-selected"></i>
            </div> : <div className="end-time">
                <p>{ v.orderType === 'buying' ? '供应中' : '已购买' }</p>
            </div> }
        </div>
    );
}

export default CsD2Box;
