import React, { antdUI, useState, useEffect } from 'react';
import { csgo } from '@/data/classification.js';

const CascaderStyle = {
    width: '104px',
    height: '33px',
    marginRight: '20px'
};

function CsgoFilter({ fv, setFv }) {
    const { itemType, quality, exterior, rarity, itemSet } = fv
    const { setItemType, setQuality, setExterior, setRarity, setItemSet } = setFv
    const [ _csgoData, set_csgoData ] = useState([]);
    useEffect(() => {
        let _csdata = JSON.parse(JSON.stringify(csgo)).filter(_ => _.value !== 'concentration' && _.value !== 'dataRanking')
        _csdata.forEach(item => {
            item.children && item.children.forEach(v => {
                v.label = <span style={ { color: v.color || '#222', } }>{ v.label }</span>
                if (Object.prototype.toString.call(v.children) === '[object Array]') {
                    v.children.forEach(_ => _.label = <span style={ { color: _.color || '#222', } }>{ _.label }</span>)
                }
            })
        })
        set_csgoData(_csdata)
    }, [])
    
    const _Cascader = (v, i) => (
        <antdUI.Cascader
            key={ i }
            dropdownRender={ menus => <div className="mobi-item-filter-select">{ menus }</div> }
            changeOnSelect={ v.value !== 'rarity' && v.value !== 'collection' }
            displayRender={ label => label[label.length - 1] }
            expandTrigger="hover"
            style={ CascaderStyle } options={ v.children }
            value={ (() => {
                switch (v.value) {
                    case "itemType":
                        return itemType;
                    case "quality":
                        return quality;
                    case "exterior":
                        return exterior;
                    case "rarity":
                        return rarity;
                    case "collection":
                        return itemSet;
                    default:
                        break;
                }
            })() }
            onChange={ value => {
                let _v = value;
                switch (v.value) {
                    case "itemType":
                        setItemType(_v);
                        break;
                    case "quality":
                        setQuality(_v);
                        break;
                    case "exterior":
                        setExterior(_v);
                        break;
                    case "rarity":
                        setRarity(_v);
                        break;
                    case "collection":
                        setItemSet(_v);
                        break;
                    default:
                        break;
                }
            } } placeholder={ v.label }
        />
    )
    return (
        <div className="backpack-filter-box-csgo">
            { _csgoData.map((v, i) => {
                return _Cascader(v, i)
            }) }
        </div>
    )
}

export default CsgoFilter;
