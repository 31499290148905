import { get, post } from '@/utils/request.js';

export default {
    // 查看背包数据
    getBackpackData(data) {
        return post({ url: '/users/backpack/get/data/', data })
    },
    
    // 取回物品
    retrieveItem(data) {
        return post({ url: '/users/backpack/retrieve/', data })
    },
    
    // 取回物品激励
    retrieveItemRecord(params) {
        return get({ url: '/users/backpack/retrieve/record/', params })
    },
    
    
}
