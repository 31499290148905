const allCustomItemData = [
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/07ded6f137699473fce3f1873ffb3f4b1875a49fd99d7b5305a46a535eb75ab6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 一发入魂",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "box_value": "set_community_32",
        "itemid": 1103,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Head Shot"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e5c95f42b25da8c3ceb3bed2d52e7ca4656fb3bbf4a7977ae027bf357572def0.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 反冲精英",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "box_value": "set_community_32",
        "itemid": 1104,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Temukau"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/fecf1f1a65d407a022cdedcc5380907bb4673f132ceee091dac0f533f2089d3c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P2000 | 变态杀戮",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "box_value": "set_community_32",
        "itemid": 1105,
        "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
        "len": 2,
        "hash_name": "P2000 | Wicked Sick"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5e005da7ffb3666d8360dcfa613b15d599a4586df73fb214e7c6b5dc0cbaf270.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "UMP-45 | 野孩子",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "box_value": "set_community_32",
        "itemid": 1106,
        "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
        "len": 2,
        "hash_name": "UMP-45 | Wild Child"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/e2e5c70732a29ffe3878b9a855e400686af67c1f81ef8ff5c1f016afc80cf909.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 金粉肆蛇",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "box_value": "set_community_32",
        "itemid": 1107,
        "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
        "len": 2,
        "hash_name": "AWP | Duality"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/83cc48752bcba79436d0d9e9036f9b752888ac80c4b96b1711191fc09f9ca11c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 元女王",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "box_value": "set_community_32",
        "itemid": 1108,
        "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
        "len": 3,
        "hash_name": "P90 | Neoqueen"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/34dcefacb43b442824f5ae587c3869bad9581cb78fbdd2cb6afd3ab10f2ebddb.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "R8 左轮手枪 | 蕉农炮",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "box_value": "set_community_32",
        "itemid": 1109,
        "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
        "len": 3,
        "hash_name": "R8 Revolver | Banana Cannon"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2b17de3aef66395693046ff66ba953079421f4895a1ee9a6154fb325ee5be460.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 错觉",
        "target_box": "set_community_32",
        "min": 0.21,
        "max": 0.79,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "box_value": "set_community_32",
        "itemid": 1110,
        "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
        "len": 3,
        "hash_name": "MAC-10 | Sakkaku"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/adcbb382b7a23e49105c1ec2cf6f77d0ed879a3d1ae58a16d765524e622e66a6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 圆影玉兔",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.75,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "box_value": "set_community_32",
        "itemid": 1111,
        "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
        "len": 3,
        "hash_name": "Glock-18 | Umbral Rabbit"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/aad8c91f0099fdb054977f6f4ecf74b2a99abcb49296bbd31bf81d7d3e33fb52.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 隐伏帝王龙",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "box_value": "set_community_32",
        "itemid": 1112,
        "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
        "len": 3,
        "hash_name": "M4A1-S | Emphorosaur-S"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5c03cc809f9d17f9642f7e11d8675c794faed5ea2364383fe2d08b82acdfb89d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 叛逆",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "box_value": "set_community_32",
        "itemid": 1113,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "Tec-9 | Rebel"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bbf7497f929719eb7868fb50532d1571054908000b816068ce89eb3431eefbc6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 赛博之力",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.9,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "box_value": "set_community_32",
        "itemid": 1114,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "SG 553 | Cyberforce"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/82bc9a43c6c623f8f82ad0d21efabd1fb5f98489002422e8b23e547bb1500e5e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 液化",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "box_value": "set_community_32",
        "itemid": 1115,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "MP5-SD | Liquidation"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/905910367a57afdebc2ec2e4b692f69e9843035838ad12c660a6cf7f8e6dbfd7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 重构",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.9,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "box_value": "set_community_32",
        "itemid": 1116,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "P250 | Re.built"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/dda65d76148d2c71f1a85080f938ae4956d7be04b46ef2a4139d93880efaaf7d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 碎片",
        "target_box": "set_community_32",
        "min": 0,
        "max": 0.78,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "box_value": "set_community_32",
        "itemid": 1117,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "SCAR-20 | Fragments"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/750df3275d5dea9c18c48b50b15f5d938d08fad9c327cb1ac4a533e8e7c46710.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 羽量级",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "box_value": "set_community_32",
        "itemid": 1118,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "MP9 | Featherweight"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/66dbbafb0dcd5af84d77d17281c83bf5baf5753b98ce62bfa0b5dc106c1b3269.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 鱼长梦短",
        "target_box": "set_community_32",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "box_value": "set_community_32",
        "itemid": 1119,
        "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
        "len": 5,
        "hash_name": "MAG-7 | Insomnia"
    },
    
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/bd36c57304dc7f695ee758ebae79a779dbebb6613a79b223b9abf0a4dcff31bc.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 迷人眼",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "box_value": "set_community_31",
        "itemid": 1086,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Chromatic Aberration"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/f2ac0d50c5f8a820718f64e0b46629d778c578beebd016addcd13ef626100475.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "USP 消音版 | 印花集",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.85,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "box_value": "set_community_31",
        "itemid": 1085,
        "list": "",
        "len": 0,
        "hash_name": "USP-S | Printstream"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f92ea07dc83d35d757417f23a52903a8b1db7913a2e049bec0a07564768e5fa1.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "截短霰弹枪 | 么么",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "box_value": "set_community_31",
        "itemid": 1087,
        "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
        "len": 2,
        "hash_name": "Sawed-Off | Kiss♥Love"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a2766ae5dc71c6542f7a6e2547520a95a955f1144e5149739631d33e6975652e.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 迷人幻象",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "box_value": "set_community_31",
        "itemid": 1088,
        "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
        "len": 2,
        "hash_name": "P250 | Visions"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/354254b67c433ff0ea5d3253fa751e4573df953e151509bf8a98d8d1096e3d46.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 可燃冰",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.77,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "box_value": "set_community_31",
        "itemid": 1089,
        "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
        "len": 2,
        "hash_name": "AK-47 | Ice Coaled"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/02b23f4d271e2d1142e176272dce765f8fe44a3dd333941eff868eca733899c1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 食人花",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "box_value": "set_community_31",
        "itemid": 1090,
        "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
        "len": 3,
        "hash_name": "Dual Berettas | Flora Carnivora"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7287921678ccfaa5af23c567b662b63b12816d963d5b813d1d5b259809257483.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 给爷冲",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "box_value": "set_community_31",
        "itemid": 1091,
        "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
        "len": 3,
        "hash_name": "P90 | Vent Rush"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8119c17c7bfb7e8023cf40b691544d8f6b5f1b272bef76c2d5ef7ea77696f7f6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 青龙",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "box_value": "set_community_31",
        "itemid": 1092,
        "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
        "len": 3,
        "hash_name": "SG 553 | Dragon Tech"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e2ce8f1f3dc425ab2ce0b7a2f162a11ba0a7505caa4a64e25be0973308506a20.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M249 | 闹市区",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.65,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "box_value": "set_community_31",
        "itemid": 1093,
        "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
        "len": 3,
        "hash_name": "M249 | Downtown"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/382ba634dc71d0c4f401684c0cf1758d09f1f2a94288510d093b48bf61a99f48.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "R8 左轮手枪 | 疯狂老八",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "box_value": "set_community_31",
        "itemid": 1094,
        "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
        "len": 3,
        "hash_name": "R8 Revolver | Crazy 8"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/81b0dbbb0cacf1e33291891607ab4e90e81d8b24daafa93db7b9d376f7a91e45.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 冬季战术",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "box_value": "set_community_31",
        "itemid": 1095,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "Glock-18 | Winterized"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e1b252541db8ed38437316bd1f647e31bcbe82c24c7a50a448e2fd2e4cabc750.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 路障",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "box_value": "set_community_31",
        "itemid": 1096,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "UMP-45 | Roadblock"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d4be5d073cfa54f70beb225ab19a6e3cbfcc4d18367ff7ece6cc9cb740f5c063.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 丢把枪",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "box_value": "set_community_31",
        "itemid": 1097,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "Negev | Drop Me"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8b71608ed330f8666a3a7bec2c8dfcecb691b62c6dc4500b2ef0e8c7e2e45b66.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 萌猴迷彩",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "box_value": "set_community_31",
        "itemid": 1098,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "MAC-10 | Monkeyflage"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fafe5e96e2d53b89158a11b6ead8b29739fb94485b828f27fc0ec3c46bedf0df.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 透明弹匣",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "box_value": "set_community_31",
        "itemid": 1099,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "M4A4 | Poly Mag"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2a53120ba772121c2e0c9e1579515c4f92441e4bd1db562a8621d1a5c66ac510.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 毁灭者",
        "target_box": "set_community_31",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "box_value": "set_community_31",
        "itemid": 1100,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "Galil AR | Destroyer"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/94c21c31cd03d3b35fc3411dccba44dfaff624cd6f58543c0be699b6e995d8b9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 喵喵36",
        "target_box": "set_community_31",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "box_value": "set_community_31",
        "itemid": 1084,
        "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
        "len": 5,
        "hash_name": "FAMAS | Meow 36"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/5845abbf8dc6338081d1bf54e869cd19ec650d2aa99ca929781e607d17be03d8.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 夜愿",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "box_value": "set_community_30",
        "itemid": 1067,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Nightwish"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/55dccdd81d0234ae16e0590dfd0ee981064351486934dddaba66e77a124833fa.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "MP9 | 星使",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "box_value": "set_community_30",
        "itemid": 1068,
        "list": "",
        "len": 0,
        "hash_name": "MP9 | Starlight Protector"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/bbdb0bc408bfe182167deea6f240df45756863040215d2747e7f40fd5b564c5f.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP7 | 幽幻深渊",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "box_value": "set_community_30",
        "itemid": 1069,
        "list": "\nAK-47 | 夜愿\nMP9 | 星使",
        "len": 2,
        "hash_name": "MP7 | Abyssal Apparition"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/229c759436aaec4e19ee9e73b1a3cd62ed572768b2100c7bf855817468cc47e1.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 目皆转睛",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "box_value": "set_community_30",
        "itemid": 1070,
        "list": "\nAK-47 | 夜愿\nMP9 | 星使",
        "len": 2,
        "hash_name": "FAMAS | Rapid Eye Movement"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/0a057520e8f2cb53b11753e04aa79a5ec3a9b671448868fe8732bab93b3f2541.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "双持贝瑞塔 | 瓜瓜",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "box_value": "set_community_30",
        "itemid": 1071,
        "list": "\nAK-47 | 夜愿\nMP9 | 星使",
        "len": 2,
        "hash_name": "Dual Berettas | Melondrama"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a522088a1244fb6d4ef3cd6599d0adccf1e1e192fb20619f14353e52f6a928bc.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 地狱门票",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.76,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "box_value": "set_community_30",
        "itemid": 1072,
        "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
        "len": 3,
        "hash_name": "USP-S | Ticket to Hell"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/726c0725fc14ae7d8ee1bd0ca73b7cd2050f03f53561f73b1b4adb841ee2f10c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 行尸攻势",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "box_value": "set_community_30",
        "itemid": 1073,
        "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
        "len": 3,
        "hash_name": "XM1014 | Zombie Offensive"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5b8344b9e2e5a36dc459fb3d9cf0784111c7f13158d470f7c0c557cbf89446d5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 夜无眠",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "box_value": "set_community_30",
        "itemid": 1074,
        "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
        "len": 3,
        "hash_name": "M4A1-S | Night Terror"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d7371959e5f81afec652bad155a6e925da3328a7e6e054dedadd14b3d9dcd285.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "G3SG1 | 梦之林地",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "box_value": "set_community_30",
        "itemid": 1075,
        "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
        "len": 3,
        "hash_name": "G3SG1 | Dream Glade"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9097af03a80e0f17dfecc94d4c90d51bde559eed0ab4f1506fc37a1616c6c7f5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 太空猫",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.67,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "box_value": "set_community_30",
        "itemid": 1076,
        "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
        "len": 3,
        "hash_name": "PP-Bizon | Space Cat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3ee92ad92437d20e78087b7bcf78164fcd78ea0daf9fab4bc54cd6f3daebf257.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 灵应牌",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "box_value": "set_community_30",
        "itemid": 1077,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "Sawed-Off | Spirit Board"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/caf2dfe8914db15025e1e9df4152fa5e708374dcc03018cfeb6c73e8abea9430.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 暗夜活死鸡",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.57,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "box_value": "set_community_30",
        "itemid": 1078,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "SCAR-20 | Poultrygeist"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f906570133b131fb5fce66bfbe58c455878f7ff52b9c7bff63f530a4e27a5ac1.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 升天",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "box_value": "set_community_30",
        "itemid": 1079,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "P2000 | Lifted Spirits"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/992ff21f82f88189460a3ca806a303cc15e0c533954adafdb60013d1ac5dd92b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 小小噩梦",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "box_value": "set_community_30",
        "itemid": 1080,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "MP5-SD | Necro Jr."
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/41cde24a9438571b3af62f546a64e617e383003e21ab9abb0f6db67a8cdb2529.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 先见之明",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "box_value": "set_community_30",
        "itemid": 1081,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "MAG-7 | Foresight"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/61fb91a042be19e0969470db01747619150ef46b2dc61535d521aac9ea8b9d74.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 坐牢",
        "target_box": "set_community_30",
        "min": 0,
        "max": 0.9,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "box_value": "set_community_30",
        "itemid": 1082,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "MAC-10 | Ensnared"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0a5608f06392bc18fb6477188c28195072b74631adeb620ad3484ab341dafb0b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 涂鸦潦草",
        "target_box": "set_community_30",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "box_value": "set_community_30",
        "itemid": 1083,
        "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
        "len": 5,
        "hash_name": "Five-SeveN | Scrawl"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/5cf5ab7fb4e339bb80dd679af7f005ca98ac339727932787305847e85f9ea5a9.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 抽象派 1337",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.65,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 1,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Leet Museo"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/0c7b5de8a810c90fb4e34f36bfcf6794fe415c0f04c9d8a5b9f70f4bd314c10c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "沙漠之鹰 | 纵横波涛",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 2,
        "list": "",
        "len": 0,
        "hash_name": "Desert Eagle | Ocean Drive"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3c03cdab7c250262b14ef3efff3781c864e8ba69e28143bbb0686558bc859c4a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SSG 08 | 速度激情",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 3,
        "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
        "len": 2,
        "hash_name": "SSG 08 | Turbo Peek"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f3f56c37dfe4eec19fb7224b8b0e9d580241e636f83b438fbbf1a8f613c713bc.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "格洛克 18 型 | 零食派对",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 4,
        "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
        "len": 2,
        "hash_name": "Glock-18 | Snack Attack"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/eaec579e4e734c18190861e317c419fd732a1b66f72dd1a92537cd805cc27ecc.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAC-10 | 玩具盒子",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 5,
        "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
        "len": 2,
        "hash_name": "MAC-10 | Toybox"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2d07dcdcb73ca4d954d90a9973ac21cab6dfffd47cd6668e799f8f28e09ec2e1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 彼岸花",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 6,
        "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
        "len": 3,
        "hash_name": "M4A4 | Spider Lily"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a566fdd42b344b7353c99bffe147d074a9ecfd95c0c24576fb18af4d9d6b944c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 富士山",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 7,
        "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
        "len": 3,
        "hash_name": "MP9 | Mount Fuji"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/fa04eb18d3f713243bf4b56a559d494dd329e258df9d906cb7413fa06a2aafbb.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 同步力场",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.41,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 8,
        "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
        "len": 3,
        "hash_name": "Five-SeveN | Boost Protocol"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b3b6c6f8cd574efce5a5337767aee8e1105ba045550cbafdb6c23152c6bf6f27.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | ZX81 彩色",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 9,
        "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
        "len": 3,
        "hash_name": "FAMAS | ZX Spectron"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0b3565d4dca56a2e44a7ca32cd819eac07b6d896697e14403fabb9fc08149e49.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 铋晶体",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 10,
        "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
        "len": 3,
        "hash_name": "MAG-7 | BI83 Spectrum"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/53eb826dc1c60a201dd871804b65ad7f55667a5c8ce10ef6deee53105fe4d6b2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 狻猊",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 11,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "XM1014 | Watchdog"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4394facea3e67e6d883bbc2228d35ec9ad446e5f2d4494937900234237844ade.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 黑莲花",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.9,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 12,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "USP-S | Black Lotus"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bd0444601c4ba013aa5f4bf3f1ae45928a7a2e6bedfa84e71cf9184c77ff17b0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 战术手电",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.77,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 13,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "PP-Bizon | Lumen"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/917f972b0ffc79ccf8ca8c5a0910b68a0526d26ba8797107497fd319b0a5639c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 游击队",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 14,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "MP7 | Guerrilla"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/37382222b0748c623906174019c57807c3197441c5376a878b2c067670c1c1b2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 特训地图",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 15,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "G3SG1 | Keeping Tabs"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b76966287e6e5007e04c4f89f5cf53acb15eed549b760c4d84eb3d04db58f66b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 胶面花纹",
        "target_box": "set_community_29",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 16,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "Dual Berettas | Tread"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d0a1ff1affc3ae5f9a1e6d8e24233da8d2eb06a3a112bfa731a69ebca4b3ae7f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 瘟疫",
        "target_box": "set_community_29",
        "min": 0,
        "max": 0.76,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 17,
        "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
        "len": 5,
        "hash_name": "AUG | Plague"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/36610819c35954122e436da85cdcd9f2b367f67edba95a9b4a9acc553fd216ad.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 紧迫危机",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 18,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Imminent Danger"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/23d81788b3bf09229bc247dd0c135ca4ca6444a8a0e588a400e7bdba37077220.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SG 553 | 玩命职场",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.48,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 19,
        "list": "\nM4A1 消音型 | 紧迫危机",
        "len": 1,
        "hash_name": "SG 553 | Hazard Pay"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/8605bb90f4d84962fd333486ad4682b038c549a1a0cf210bc35ae5447b9dfb44.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "FN57 | 坠落危险",
        "target_box": "set_vertigo_2021",
        "min": 0.01,
        "max": 0.52,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 20,
        "list": "\nM4A1 消音型 | 紧迫危机",
        "len": 1,
        "hash_name": "Five-SeveN | Fall Hazard"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0647a423cde3fd69f91c8cec38d7f1f23d0c0d266151345d0014cef64a93eb20.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 数字架构师",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.49,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 21,
        "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
        "len": 2,
        "hash_name": "P250 | Digital Architect"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a57c0a2f7668798154bef9c8817d6c37f367aa4b5eba1c362a09e9491475b5d1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 棱彩阶梯",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.37,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 22,
        "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
        "len": 2,
        "hash_name": "MAG-7 | Prism Terrace"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/67d8be0274f2dd2da590726e867969ce8c6c9bfcf683295e19a7ba0a5e9e7eca.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 警告！",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 23,
        "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
        "len": 2,
        "hash_name": "Galil AR | CAUTION!"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3e64eb1507ac37b2f8865931da5b4e0d577dbfeb9bdcf12439d2815de6190fb7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 基础建设",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.49,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 24,
        "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
        "len": 3,
        "hash_name": "Negev | Infrastructure"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6bdbf1a350e86b752889b520e5cbfeafc5b5f58d88826f1e2c56a7cfeb579089.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 致幻时刻",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 25,
        "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
        "len": 3,
        "hash_name": "Nova | Interlock"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b5b5e2bd7a417d048b8a62bdd1feab7f34456f14e345c4bd8462f7cdcc8f5ead.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 解视图",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 26,
        "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
        "len": 3,
        "hash_name": "P90 | Schematic"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3df8582ac2abfa0eb8deadf5f2f5256537f879880598050e21f3793134c7160b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AK-47 | 绿色层压板",
        "target_box": "set_vertigo_2021",
        "min": 0.02,
        "max": 0.4,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 27,
        "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
        "len": 3,
        "hash_name": "AK-47 | Green Laminate"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/2b6054fad06c4656a6dbe03dc26d0167331af56b51a845ae9f129aa7b0a90896.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "PP-野牛 | 开关箱",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.44,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 28,
        "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
        "len": 4,
        "hash_name": "PP-Bizon | Breaker Box"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/5d7821224bbcb02bb2eedaeab105a5a988cb7ad39cad4aab3ae1a3949822bb53.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SSG 08 | 碳素纤维",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.12,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 29,
        "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
        "len": 4,
        "hash_name": "SSG 08 | Carbon Fiber"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/6fc76f314c7a09eaffe0ed3a23c7eea75fdffa012fbdd17e50ab97b0cc38cc9f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "UMP-45 | 机械装置",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.49,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 30,
        "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
        "len": 4,
        "hash_name": "UMP-45 | Mechanism"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b856e8c4e1654967980bcda7770a32de1a3c415888e95d195e0c6e8f23439fc7.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 红色轮胎",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 31,
        "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
        "len": 4,
        "hash_name": "Glock-18 | Red Tire"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/62c3f4c94364b7bb7ebe06c9312ca71baf201344eeacd46f918ad8bb86bcab99.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 更换机油",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 32,
        "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
        "len": 4,
        "hash_name": "Dual Berettas | Oil Change"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/518eb7fb2d17ede4214c055d04a62eb69be6c3f56578b60014b556034c00ae2e.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "CZ75 | 幻光构架",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 33,
        "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
        "len": 4,
        "hash_name": "CZ75-Auto | Framework"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/b2f2ba5dec73b6e0a4ebc8354c909e7bae80ca90fcf858339e269fa2202447a1.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 蓝色轮胎",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 34,
        "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
        "len": 4,
        "hash_name": "XM1014 | Blue Tire"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6fcc5241bbf98d0b42c16c960da459e99675a86ca16ee669fbf6dcf3854123d9.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "法玛斯 | 线路故障",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 35,
        "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
        "len": 4,
        "hash_name": "FAMAS | Faulty Wiring"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8d71b86b57ca6ffa8715414a864c719f13731a73c46a7174fdf6bfb7a9a1af07.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 策略",
        "target_box": "set_vertigo_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 36,
        "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
        "len": 4,
        "hash_name": "MAC-10 | Strats"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e49be245ace0063ae8c05f147c5bc9ba84dd2fe4c9a7fd93a19c7eaa8f96b96a.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 黄金藤蔓",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 37,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Gold Arabesque"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/2b7a97f7149e6b37b1e639c4ef6b9d01e05a0e64fa757bbd010a013e0325a854.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SSG 08 | 致命一击",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 38,
        "list": "\nAK-47 | 黄金藤蔓",
        "len": 1,
        "hash_name": "SSG 08 | Death Strike"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d1adf4951cfad704be07b985aa33207b4a11637628329813f69a4e4d2377d7a6.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "UMP-45 | 渐变之色",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.08,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 39,
        "list": "\nAK-47 | 黄金藤蔓",
        "len": 1,
        "hash_name": "UMP-45 | Fade"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/fcecb0566be32bb2a620361354c6df528155809f8b0bb2dfda19aea605e254b8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 表面淬火",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 40,
        "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
        "len": 2,
        "hash_name": "MAC-10 | Case Hardened"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/91ca2eb942470d4d48aead3d0354ebd975a990e21f8eed9ca491df7c263a1b68.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 红色 DDPAT",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 41,
        "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
        "len": 2,
        "hash_name": "M4A4 | Red DDPAT"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/90ae6cbcc4133aa88e49f60cbbd912e56e083bac3a230757680b7e8fa1cde97e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 橙红安乐蜥",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.37,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 42,
        "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
        "len": 2,
        "hash_name": "USP-S | Orange Anolis"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/39aeac77bea41ae2ae2284069a3b24fafcbbef75ac509afe901973f8d4927675.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 渐变琥珀",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 43,
        "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
        "len": 3,
        "hash_name": "Galil AR | Amber Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fbb4b8280b31a91bba711c5ed91a3030c8b7135cd0e3f5d8a4f9378c22842304.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 碧藤青翠",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 44,
        "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
        "len": 3,
        "hash_name": "G3SG1 | New Roots"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e8b0d57782af5fd1fc3ad51c41822093fcd048c8ece248d19aca5c5bb6482ff4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 流沙",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 45,
        "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
        "len": 3,
        "hash_name": "Nova | Quick Sand"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/01fb2e17e000f4b2f539fdd2399396d769990957a091ae06447f49aed507c28e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 潜藏者",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 46,
        "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
        "len": 3,
        "hash_name": "P250 | Black & Tan"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/63d4726faaa5974d66281d4f3d6bbea01a270d3866b7c86280985c529181bcdd.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M249 | 夜半棕榈",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 47,
        "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
        "len": 4,
        "hash_name": "M249 | Midnight Palm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/482e6cb073a132ddad1bddf4972d93ff9a0a69a91876106cbdd9ff2b122e4552.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "FN57 | 紫藤老树",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 48,
        "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
        "len": 4,
        "hash_name": "Five-SeveN | Withered Vine"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/49d3f44c67d2bb10446f5e36d9f48e1617446f519fee86d0cfec5cbb8adf36b0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP9 | 残花败藤",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 49,
        "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
        "len": 4,
        "hash_name": "MP9 | Old Roots"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/3940d3736546f9b17ceb5dc1ccbebea1b6bbadf4142cf6b7a5ddf1912917b48c.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AUG | 朽木",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.48,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 50,
        "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
        "len": 4,
        "hash_name": "AUG | Spalted Wood"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/4c40a7d4e8dff4b43969ae6ac81f09e35c74a64d5d331d772c31456c97632f33.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 旱地之花",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.49,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 51,
        "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
        "len": 4,
        "hash_name": "Sawed-Off | Parched"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0137c0bddf8e558efe569bcbf56435b8d4035e83d5074abaa72390a92f6c7a16.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 掠夺者",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.48,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 52,
        "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
        "len": 4,
        "hash_name": "MP7 | Prey"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/ea8ae0c7d46b09aece5442fa1941ac33071123eaea85f1dd5385baa2da199153.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 白骨",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 53,
        "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
        "len": 4,
        "hash_name": "SG 553 | Bleached"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/967d916257ca0a9f44555c5bb3c6fcc9d1d908483c78f267337897c6c39a94f9.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P90 | 沙漠 DDPAT",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 54,
        "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
        "len": 4,
        "hash_name": "P90 | Desert DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e18c9986c2c1de2f8d4575d542101675a07ee076706754ae8b406e306c8903f0.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "R8 左轮手枪 | 沙漠伪装",
        "target_box": "set_dust_2_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 55,
        "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
        "len": 4,
        "hash_name": "R8 Revolver | Desert Brush"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/2a8614819d572a86ce5ca0f6c39980b84654c0bed47bcbce6cdeb2f57d849beb.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 九头金蛇",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 56,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Desert Hydra"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/cb351761e01e63e0a5181ab24e432e6fff20df1f7e72edc48b657723589b14fd.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 沙漠之狐",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.8,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 57,
        "list": "\nAWP | 九头金蛇",
        "len": 1,
        "hash_name": "Desert Eagle | Fennec Fox"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/abe20c592854dc202e8256fea25a9ab86cfb6a1ab9feb0d752c366ce6b448c9e.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP5-SD | 幻化绿洲",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 58,
        "list": "\nAWP | 九头金蛇",
        "len": 1,
        "hash_name": "MP5-SD | Oxide Oasis"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6359e854ee864198c3ed2b2a2dab3e24d811c48152955a6cde2e797849a8a160.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 夜空沙暴",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 59,
        "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
        "len": 2,
        "hash_name": "AUG | Sand Storm"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e579f367022b1ff11f3295a1c9704e41e6e2dd9c01c33d9c36c655a9459d1a77.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 粉红 DDPAT",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 60,
        "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
        "len": 2,
        "hash_name": "Glock-18 | Pink DDPAT"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/de03feaebe5f0fdc7b9d1fca636dbfb2095747cc8128e9b66fe005625856ce3c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 雅藤如嫣",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.37,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 61,
        "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
        "len": 2,
        "hash_name": "XM1014 | Elegant Vines"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/41bd1a4cf0b3b364ae908ddf491bbdc6bbc726a309b35e2cb80520fa94ed6b97.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 沙漠之花",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.49,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 62,
        "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
        "len": 3,
        "hash_name": "SG 553 | Desert Blossom"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/72df3ce19911cd94c308b35ee4c3ef3c0812d400facf80c9b5b3e012b902fc1c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 雪茄盒",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 63,
        "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
        "len": 3,
        "hash_name": "M249 | Humidor"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ce39e0481f0ea672d06b25bd027b966fcd237bb116795e2db517b1500504f9cb.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 八音盒",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 64,
        "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
        "len": 3,
        "hash_name": "MP9 | Music Box"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0ed532cbf742c0b3c2f839997f26189a2599e858971d13b7e1fc1cc7c94c3d54.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 紫色 DDPAT",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 65,
        "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
        "len": 3,
        "hash_name": "USP-S | Purple DDPAT"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/f6b89130008db92d1444d3f946b3f84a9886f52ce86527775264206df110f0ca.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P90 | 碧绿升藤",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.47,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 66,
        "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
        "len": 4,
        "hash_name": "P90 | Verdant Growth"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8aff3b20434e3999766e9ff0e3faa876a7a83b4213f1078e4bd96ecc2802da2f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "CZ75 | 夜半棕榈",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 67,
        "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
        "len": 4,
        "hash_name": "CZ75-Auto | Midnight Palm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/aa09643e62dafac18d2461725887cc85f9516b8ee09eba97a48c9d79c595659c.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "法玛斯 | 加州迷彩",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 68,
        "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
        "len": 4,
        "hash_name": "FAMAS | CaliCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/471f425f4da6d0078f7d2f94a83f47851a3ac8abb3ea46e72dce81618d356447.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "双持贝瑞塔 | 浮木",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 69,
        "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
        "len": 4,
        "hash_name": "Dual Berettas | Drift Wood"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/4bc9c0bfff6404c77383e1f9119d3b733fce15b188b6e645c25c8e9f2de8e493.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 掠夺者",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.48,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 70,
        "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
        "len": 4,
        "hash_name": "SSG 08 | Prey"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/588b315e32137439fff2332388c525d40d86deb7d2846ee2a698c44935912e9a.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 赭色大马士革",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 71,
        "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
        "len": 4,
        "hash_name": "MAC-10 | Sienna Damask"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/92f7b389b3f8ca0f63c1427f01d4298e3485c601629eec2e2978b4b89fc2cd86.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 海军之辉",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 72,
        "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
        "len": 4,
        "hash_name": "MAG-7 | Navy Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/9fd3d8bf50b2ae381a841d0988f48713f6cfc4f8d07b53a6637f1fb15c457478.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 安乐蜥",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 73,
        "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
        "len": 4,
        "hash_name": "PP-Bizon | Anolis"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d10df3aa42dc721fcd4db01a138bff418525f22a645279942f3a85619a7770a7.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 天旱",
        "target_box": "set_mirage_2021",
        "min": 0,
        "max": 0.44,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 74,
        "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
        "len": 4,
        "hash_name": "P250 | Drought"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/45a2143309ca0b7190d28cbd11188ac5bf674489360354ed8dde8c2e432dd78b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 合纵",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 75,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | The Coalition"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/68bcf8b7848d844482b8b331540bb5eafde3a8ba346ba5ef92bdcee99865c088.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "格洛克 18 型 | 伽玛多普勒",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 76,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Gamma Doppler"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/6524beb08c9544096d34e35b44030f97fb526c4ad4125183f762acdcaa48d428.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 银装素裹",
        "target_box": "set_train_2021",
        "min": 0.06,
        "max": 0.8,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 77,
        "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
        "len": 2,
        "hash_name": "USP-S | Whiteout"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/426742013c0b86777ef5f20e916ec2fae02e92c97b60bf6c27cb6238bbabd68b.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAC-10 | 凝视之眼",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.62,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 78,
        "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
        "len": 2,
        "hash_name": "MAC-10 | Propaganda"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b0c62f693990d93b4abf5ee06619425ca7cd42e77f176a8057e2fa0e6095c723.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 熔化",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 79,
        "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
        "len": 2,
        "hash_name": "FAMAS | Meltdown"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b8abf8a1f37aa6115c4451a32f0edeb74fa527397ece89d51f9ba6470200d48f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 晶红石英",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 80,
        "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
        "len": 3,
        "hash_name": "Nova | Red Quartz"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e2fd9e92baea2d609edde828a66a3a53bc4ba9ea61b6174b7b27be36448927a8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP5-SD | 秋季方巾",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.58,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 81,
        "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
        "len": 3,
        "hash_name": "MP5-SD | Autumn Twilly"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/184ac8f6623cd9213997770ead2d2424be5cd6267575faffa9993390cae60dd0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 太空竞赛",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 82,
        "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
        "len": 3,
        "hash_name": "P2000 | Space Race"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b4132bf91372342d6de4a1ffd600669d3c7e9bf1a200db4df5fdda620d431a06.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 复古流行",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.44,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 83,
        "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
        "len": 3,
        "hash_name": "AWP | POP AWP"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8f4d91026fc7917868773ff1bc003f00a5621d4e5937a0bc57bf97914c184ccb.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 辛迪加",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 84,
        "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
        "len": 3,
        "hash_name": "CZ75-Auto | Syndicate"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d998b5b0b933665479f6de8078d144c6a507cbb2e78a57826dff296cd17ae408.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 炽烈之炎",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.08,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 85,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "R8 Revolver | Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e071cad321b38882231b02e66d8adeed678e74c4d5c5faa6fa9ceeb15d818105.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 安全网",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 86,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "Tec-9 | Safety Net"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d6a5f48a8959cfc1c306e30e5a07ce140911840e090a009fe0774f26b1d52fc7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 逮捕者",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 87,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "UMP-45 | Full Stop"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4a3211adafa9ff17d852716d5b28cc486b81057476d8505e4c6a87d9916b414f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 渐变琥珀",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 88,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "AUG | Amber Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4700a2b64ca71889e96bc20fc3482ebe1d4628cd31d15d45307d6fa3ebce2899.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 春季方巾",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 89,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "SSG 08 | Spring Twilly"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a2c538b151c5261340995c24b0ba3be977320385343b3732b9dd5b66c24a6df4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A1 消音型 | 气泡流行",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.57,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 90,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "M4A1-S | Fizzy POP"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8daf2edfa35a32ef8d2eb35f623d3d2f1bc60f483b16ce4760d1d353904adf53.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 人造卫星",
        "target_box": "set_train_2021",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 91,
        "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
        "len": 5,
        "hash_name": "Desert Eagle | Sputnik"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/98c517e7d4f52c41209afaa7c4773db7374a29681792b4ee22e27da5655ea066.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 冒险家乐园",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 92,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Welcome to the Jungle"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/514ce74f128f1418fe880e203330898f4cdf1128d68bc2544b905d08dfdbb152.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 豹走",
        "target_box": "set_op10_ancient",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 93,
        "list": "\nM4A1 消音型 | 冒险家乐园",
        "len": 1,
        "hash_name": "P90 | Run and Hide"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/932b768d17b207ce27564f959205c44357c3685e2a4df5913ff8b2e99b989591.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 美洲豹",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 94,
        "list": "\nM4A1 消音型 | 冒险家乐园",
        "len": 1,
        "hash_name": "AK-47 | Panthera onca"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/948ec614a6cc0452a8c139e91e1a2ff10753fc534732d06280ecedab65d2f456.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 太古传说",
        "target_box": "set_op10_ancient",
        "min": 0,
        "max": 0.5,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 95,
        "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
        "len": 2,
        "hash_name": "XM1014 | Ancient Lore"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f1be890e0a711750a0e5e750888f9dd6a0d841502d20404f6453f0a65989df44.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 远古幻想",
        "min": 0,
        "max": 0.45,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 96,
        "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
        "len": 2,
        "hash_name": "USP-S | Ancient Visions"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/67ad329f3fe6a83b131b1094207349a995b4716935c6b5284608fa4adb7934e8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 金砖",
        "min": 0,
        "max": 0.7,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 97,
        "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
        "len": 2,
        "hash_name": "MAC-10 | Gold Brick"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2748bf0b5d2e44155857140e9b36b7d59ef5bb0d3e09d4350f8c0d4af629ca3c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 上古图腾",
        "target_box": "set_op10_ancient",
        "min": 0,
        "max": 0.65,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 98,
        "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
        "len": 3,
        "hash_name": "Tec-9 | Blast From the Past"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/68dd1a40fdcc67047eeb7bd730e78eddb54468ad77320b1e27c680127e788089.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 黑水",
        "min": 0.1,
        "max": 0.26,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 99,
        "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
        "len": 3,
        "hash_name": "FAMAS | Dark Water"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1be279932b219fda9132f34d5b758d83711e4f9362acf7dffd458779434c7cca.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 璞玉",
        "min": 0,
        "max": 0.2,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 100,
        "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
        "len": 3,
        "hash_name": "AUG | Carved Jade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/45cf52335b5492c08ff7bb85650e61547f3a32a775559b5a35aa40279b429261.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 废墟黄昏",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 101,
        "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
        "len": 3,
        "hash_name": "Galil AR | Dusk Ruins"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/487abb0be5eeeccf96fb3f3ef2be1c32dcd0624299f3e170b3516069f8f1418f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "CZ75 | 银质",
        "min": 0,
        "max": 0.08,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 102,
        "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
        "len": 4,
        "hash_name": "CZ75-Auto | Silver"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/151c444e7b80bc3285034840e2fb095c79b7769de41a1a859f8ac6fd198f187c.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 豹纹迷彩",
        "min": 0,
        "max": 0.5,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 103,
        "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
        "len": 4,
        "hash_name": "P2000 | Panther Camo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ff6606ee9a91744c84be9415f5c87f23778f0ed31036586ad36b1466d2ea4544.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "G3SG1 | 远古仪式",
        "min": 0,
        "max": 0.76,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 104,
        "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
        "len": 4,
        "hash_name": "G3SG1 | Ancient Ritual"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/82e4fd0490537b269039665e4ea97d29017998458e3fb718bc0f400862bbec74.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP7 | 绿野迷踪",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 105,
        "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
        "len": 4,
        "hash_name": "MP7 | Tall Grass"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/90548555e486129613852b713159ee9311263dae003b6cf198e21140fbffa4d0.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 废墟丛生",
        "min": 0,
        "max": 0.7,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 106,
        "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
        "len": 4,
        "hash_name": "SG 553 | Lush Ruins"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/65e04026af26b89ee8fce8a10274128e3584c8020e2680faecef79e68bff32da.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 107,
        "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
        "len": 4,
        "hash_name": "Nova | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/293541e691aada6980848595d8bd94569a7efebf50d7c42c5fad97a26a9ed7ba.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P90 | 古老星球",
        "min": 0,
        "max": 0.5,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 108,
        "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
        "len": 4,
        "hash_name": "P90 | Ancient Earth"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/98b95bf7e80041f8d2ea56d32ff3e4fb482d165b0e0e9bcaed6904c09dcc2608.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "R8 左轮手枪 | 噩梦之夜",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 109,
        "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
        "len": 4,
        "hash_name": "R8 Revolver | Night"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/fe3f94e79f5ffd74909523c7496611a607ae6d96c61e9179bce2970087a582ad.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 丛林虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ancient",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 110,
        "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
        "len": 4,
        "hash_name": "SSG 08 | Jungle Dashed"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/a10f0eafbca3a4dd19f920d45a672b22f060699355056fd5c8b4085cf0a29fde.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | X 射线",
        "target_box": "set_op10_t",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 111,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | X-Ray"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f0f550638252dd49cf1fbd51287aad7da49b6525a8ba0ac334d87c3718341d21.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAC-10 | 狂蟒之灾",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 112,
        "list": "\nAK-47 | X 射线",
        "len": 1,
        "hash_name": "MAC-10 | Hot Snakes"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b9b720060fcde05a03763e9248e6c427d9dd7511039ea5a782abab3e7cfb4624.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 锦虎",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 113,
        "list": "\nAK-47 | X 射线",
        "len": 1,
        "hash_name": "AWP | Silk Tiger"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8d6034fbe3c48a2caafe2d49af8b026e1c9604ced850e0f2d21466e59136788c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 蛊惑之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 114,
        "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
        "len": 2,
        "hash_name": "SG 553 | Hypnotic"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c0ded8bd2318be6a26dc45da2e04d3c3795db4c955b2e37dc4920d09ebcd0822.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 凤凰冥灯",
        "min": 0,
        "max": 0.7,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 115,
        "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
        "len": 2,
        "hash_name": "Galil AR | Phoenix Blacklight"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7533a37bad838f960cc86706fc3ae9452c9ad8a5b37714764aef565a9430110d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 富兰克林",
        "min": 0,
        "max": 0.4,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 116,
        "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
        "len": 2,
        "hash_name": "Glock-18 | Franklin"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0b30f171b129eed95066ad3259f4d12c29b4f5e3994fe84efcd9e842b378f49e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 凤凰徽记",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 117,
        "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
        "len": 3,
        "hash_name": "Negev | Phoenix Stencil"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/72deb68968274ed0835016d7d339db46b1767e124fbb88c4c3ee441013403f90.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 困兽之斗",
        "min": 0,
        "max": 0.5,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 118,
        "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
        "len": 3,
        "hash_name": "P90 | Tiger Pit"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/85bc555497297168a3afd4752b9ece64c9239b6871228d40c23d691924a10a32.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 午夜凶匪",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 119,
        "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
        "len": 3,
        "hash_name": "Desert Eagle | Night Heist"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d9d59332e29835000653e39f8e3589968b9bba775473cf63ea24c48c1b438b87.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 孟加拉猛虎",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 120,
        "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
        "len": 3,
        "hash_name": "P250 | Bengal Tiger"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/dbd8b1b4d583faf9c143d51f15a303fcf4fc78e804c52085cc80708a32fa9a4f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "R8 左轮手枪 | 凤凰手迹",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 121,
        "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
        "len": 4,
        "hash_name": "R8 Revolver | Phoenix Marker"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c33aa437a3998fd826d19f16b3da10e25626281f9cfd8a1f5a370f3287746a0a.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP7 | 金库悍匪",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 122,
        "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
        "len": 4,
        "hash_name": "MP7 | Vault Heist"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/3527a494619b2d5b12492701d6e594829cd4f825160366d8669f48c247102b5d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "UMP-45 | 犬牙",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 123,
        "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
        "len": 4,
        "hash_name": "UMP-45 | Houndstooth"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c62c44d39885a5654c2b30e304074d82e4af139f1fc74f59531bee2df0f0ab8a.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "新星 | 外表生锈",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 124,
        "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
        "len": 4,
        "hash_name": "Nova | Rust Coat"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/aa634bb316b646e33aaa40ce6bcf4860183f794191c61791d563baa9a9497dda.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 凤凰涂鸦",
        "min": 0,
        "max": 0.8,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 125,
        "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
        "len": 4,
        "hash_name": "Tec-9 | Phoenix Chalk"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/1acab6f7b846318e18aa76957b5b03a689bbbabe7a82d3d654df976252d4d4af.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 夺命撼响",
        "min": 0.08,
        "max": 0.5,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 126,
        "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
        "len": 4,
        "hash_name": "PP-Bizon | Death Rattle"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/17cecfbf4e07b172215327d5fef694d18b12ece804ad3c9933da23dd1eec79b3.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 伏击者",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 127,
        "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
        "len": 4,
        "hash_name": "Sawed-Off | Clay Ambush"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/4d4a16c240565be1a5bb0d617961ecff0fdae08775f539f9b257a0bf39f75844.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 街头抢匪",
        "min": 0,
        "max": 0.7,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 128,
        "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
        "len": 4,
        "hash_name": "Dual Berettas | Heist"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/880b766ab7c0c163eb5e0725f5b5d4b5c41148269146929a3f95197b136aca7f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "M249 | 捕食者",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_t",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 129,
        "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
        "len": 4,
        "hash_name": "M249 | Predator"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/06775d2a81dc9f4bd4566e610d295064dc360f36499366cc0e99eed5418df55c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 渐变之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 130,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Fade"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/56f0e951de8f2a8463c3b50d34405e7ea352043fbf3d76a9c9037843343a21d2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 澜磷",
        "min": 0,
        "max": 0.08,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 131,
        "list": "\nAWP | 渐变之色",
        "len": 1,
        "hash_name": "M4A1-S | Blue Phosphor"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/cf48cf57da886473ff5e5d0b1c1b2f7cb4f9b54b05950cc0a3da041dc1917ce7.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 锁定",
        "min": 0,
        "max": 0.5,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 132,
        "list": "\nAWP | 渐变之色",
        "len": 1,
        "hash_name": "USP-S | Target Acquired"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7f31552dd83f0cc8545e667c6cdb6fc7accc04210de00bd93b7ce1b19d1ca64f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 蓝莓樱桃",
        "min": 0,
        "max": 0.1,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 133,
        "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
        "len": 2,
        "hash_name": "Five-SeveN | Berries And Cherries"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/738b4972ff35cbec532a8e5bfce67f871b590eecfbcf8b80c1eb10943f43ca83.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 犯罪现场",
        "min": 0,
        "max": 0.75,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 134,
        "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
        "len": 2,
        "hash_name": "UMP-45 | Crime Scene"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1d24c62414b4b0c643ebcbc8544156245eb04320865d3bc49e1470aee045dd31.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 幕后主谋",
        "min": 0,
        "max": 0.55,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 135,
        "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
        "len": 2,
        "hash_name": "FAMAS | Prime Conspiracy"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1e76a6a98b44de8c27b21faef9c38c41f3836ffbbaae61c97750d36373d16d6f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 侦测",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 136,
        "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
        "len": 3,
        "hash_name": "SSG 08 | Threat Detected"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0606e83ba9e57b65b4674099946f4480641446928044b4679b9cc270b59a2400.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 大宪章",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 137,
        "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
        "len": 3,
        "hash_name": "SCAR-20 | Magna Carta"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a902a24753f3f6688209493c2fe7b65c714b7280f3ed7b31bf79ae131b425d28.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 绝命密电",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 138,
        "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
        "len": 3,
        "hash_name": "P2000 | Dispatch"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2638806b4f7aad5c21aa7d6fa1de65f05fe12112e47d38f44ddcf7911ee0b362.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 全球攻势",
        "min": 0,
        "max": 0.7,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 139,
        "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
        "len": 3,
        "hash_name": "M4A4 | Global Offensive"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b9a6548f83887ac7b7eaeb4266bc17020972074ddfa5424cd30f09b4f4b79b0e.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAG-7 | 碳素纤维",
        "min": 0,
        "max": 0.12,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 140,
        "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
        "len": 4,
        "hash_name": "MAG-7 | Carbon Fiber"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8c55b476283c96c1a2acce27dce6d5eaf29bb86f4bb23b6949b6eef71c45e636.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "双持贝瑞塔 | 暗网总机",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 141,
        "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
        "len": 4,
        "hash_name": "Dual Berettas | Switch Board"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/74628b64613474a2e76e75736b189e77782beb2150a3e59b37ecbbef94610102.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "沙漠之鹰 | 古铜密码",
        "min": 0,
        "max": 0.3,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 142,
        "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
        "len": 4,
        "hash_name": "Desert Eagle | The Bronze"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/0a263d3dd7813a89cce4f210b25dbff1aac811dac3fd81dc14f178e9639ad2c9.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP5-SD | 氮化处理",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 143,
        "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
        "len": 4,
        "hash_name": "MP5-SD | Nitro"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6bbbe760cfca0986321e292a3f7aaa3b3b7ba85c3762970c0e86eb7d5b73fa4a.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 搜索",
        "min": 0,
        "max": 0.6,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 144,
        "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
        "len": 4,
        "hash_name": "AUG | Surveillance"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6734cd0c2d6413b2017548fd5696aa91f5beaa9488383960d2f6c92091198442.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 旧宪章",
        "min": 0,
        "max": 0.65,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 145,
        "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
        "len": 4,
        "hash_name": "XM1014 | Charter"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e1b6bf45e651ba29b6071583e91fa3647a06637013217356ea5655d14d9583bd.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 146,
        "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
        "len": 4,
        "hash_name": "MP9 | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3e6aa8bbf79674b6cf812f769e38edf872f0fb50cc32c5c71c4fe419b44c90ee.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 暮色森林",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 147,
        "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
        "len": 4,
        "hash_name": "P250 | Forest Night"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/869ee3da97d258ef18eccde705e3f4d3c8831cc61bdb00f05c108b7a056c6894.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "CZ75 | 丛林虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_op10_ct",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 148,
        "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
        "len": 4,
        "hash_name": "CZ75-Auto | Jungle Dashed"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/0dfcdc049efc9680697823b703c83799fff39a75be4ea87f91855619f5d18695.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 永恒之枪",
        "min": 0,
        "max": 0.6,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 149,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Gungnir"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d2525f42b99e513b0893b2827d1f4aabf10c1a49f5e7ab33e66d83fafef2c8e3.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "内格夫 | 雷神之锤",
        "min": 0,
        "max": 0.6,
        "target_box": "set_norse",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 150,
        "list": "\nAWP | 永恒之枪",
        "len": 1,
        "hash_name": "Negev | Mjölnir"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4befb2a59d1d5ca157e03c688e6c00bda775ed3543e88c9bcdf48c65778ea8d0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 翡翠巨蟒",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 151,
        "list": "\n内格夫 | 雷神之锤",
        "len": 1,
        "hash_name": "Desert Eagle | Emerald Jörmungandr"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c38d6c110a43f9e8be7092c25962a2cb9ade154045a1d6194562e9443985807f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 星辰巨蟒",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 152,
        "list": "\n内格夫 | 雷神之锤",
        "len": 1,
        "hash_name": "P90 | Astral Jörmungandr"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2531af1d507a11a5a6a7f703b7cf8d0e182f538d9daf4d100be0051c77e117a1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 烈焰巨蟒",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 153,
        "list": "\n内格夫 | 雷神之锤",
        "len": 1,
        "hash_name": "AUG | Flame Jörmungandr"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cb0fb9e63f11e7600b3f924770cb15ae4eaf9d937075479f99657b664c3e752b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 寒霜锁链",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 154,
        "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
        "len": 3,
        "hash_name": "XM1014 | Frost Borre"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/375333ec27bbd018fe3cf24285d678d20113a0e461fe0ddb0dd85c11fcc80165.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 黄铜",
        "min": 0,
        "max": 1,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 155,
        "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
        "len": 3,
        "hash_name": "SCAR-20 | Brass"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3593464a677957f38a0799e3ed312974944c3de7b4cb12f2fb1e76a973240191.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 赤金锁链",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 156,
        "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
        "len": 3,
        "hash_name": "MAC-10 | Copper Borre"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/216f4db18a9cb46f98be4d193bc32acdc5d09f3c55080c69f4936001bc184049.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 翡翠石英",
        "min": 0,
        "max": 0.7,
        "target_box": "set_norse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 157,
        "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
        "len": 3,
        "hash_name": "CZ75-Auto | Emerald Quartz"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8be1f098a7d80ac2df43ed582c2de247db07d00cc4b1dba56ecbc148447f712a.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "双持贝瑞塔 | 熊熊烈焰",
        "min": 0.06,
        "max": 0.55,
        "target_box": "set_norse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 158,
        "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
        "len": 4,
        "hash_name": "Dual Berettas | Pyre"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/5c0ac5fe2370089984a25fe6309982a18177216d9ec068b811b9c40fd11d4f5b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "USP 消音版 | 寻路者",
        "min": 0,
        "max": 0.35,
        "target_box": "set_norse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 159,
        "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
        "len": 4,
        "hash_name": "USP-S | Pathfinder"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/19e355124489f8fe322e2aa8982c0fcb55e484aaaecd58a11e885d2d9c1b63f0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A1 消音型 | 苔藓石英",
        "min": 0,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 160,
        "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
        "len": 4,
        "hash_name": "M4A1-S | Moss Quartz"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c1acfe906c8e4774dedaa9f063fcbe217b2befe4642f3864d7926b0f8be0a410.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAG-7 | 坚固链甲",
        "min": 0,
        "max": 0.22,
        "target_box": "set_norse",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 161,
        "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
        "len": 4,
        "hash_name": "MAG-7 | Chainmail"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/a2662cd01107cf32592ee5002798716be44c1cb8f807138dec3605ed89167075.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 枯焦之色",
        "min": 0,
        "max": 1,
        "target_box": "set_norse",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 162,
        "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
        "len": 4,
        "hash_name": "MP7 | Scorched"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/4ade130db2efbfc587ff48c920c704944fb73690a22b031a7e18175f8f80f1f8.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "加利尔 AR | 狂哮飓风",
        "min": 0,
        "max": 1,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 163,
        "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
        "len": 4,
        "hash_name": "Galil AR | Tornado"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/b8859b85e88c222a1acead334fa6fc912cf0c5c96644e6884f519d7c7fc3b19a.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 血染风采",
        "min": 0.06,
        "max": 0.5,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 164,
        "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
        "len": 4,
        "hash_name": "SSG 08 | Red Stone"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d11547f17fbc8a950c4a56bbac6e7b847edd7eb6c052e3bcdcf7432028273ace.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "法玛斯 | 暗夜锁链",
        "min": 0,
        "max": 0.55,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 165,
        "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
        "len": 4,
        "hash_name": "FAMAS | Night Borre"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6ab04994348998f5d47e7b8cac9550ee3c78c0bf6c4c1350731609fdf032533f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 路障",
        "min": 0,
        "max": 0.65,
        "target_box": "set_norse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 166,
        "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
        "len": 4,
        "hash_name": "SG 553 | Barricade"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/8e91e23ef7648ffa4be9d9436c500a622ac7a253112e42a4144f00f4d1fd8a48.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 王子",
        "min": 0,
        "max": 0.6,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 167,
        "list": "",
        "len": 0,
        "hash_name": "AWP | The Prince"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/7edc578cb4dd6bd43459a06247615482a5d091f2800c10fed61e80a3b3d009d0.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAG-7 | 五指短剑",
        "min": 0,
        "max": 1,
        "target_box": "set_canals",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 168,
        "list": "\nAWP | 王子",
        "len": 1,
        "hash_name": "MAG-7 | Cinquedea"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/16772456925da02237d1b10178cc2731bc52bea71c563695ab6ef6c0cd3c17a5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 彩绘玻璃",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 169,
        "list": "\nMAG-7 | 五指短剑",
        "len": 1,
        "hash_name": "MP9 | Stained Glass"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/844f0a822eeb018190d10cccdc69cfbb9b9cf417f888a70fabd6616fb2892df9.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 巴洛克之橙",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 170,
        "list": "\nMAG-7 | 五指短剑",
        "len": 1,
        "hash_name": "Nova | Baroque Orange"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3c5cfea58327d713372fb085e5042ba0509b5d32249690c1423bf099bc2a80cd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 绯红镂刻",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 171,
        "list": "\nMAG-7 | 五指短剑",
        "len": 1,
        "hash_name": "MAC-10 | Red Filigree"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3299c99d9a161c3a2f41c0fa10e43e1659901fa1f9e65d8e8a369b92d2cc93ce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 橙黄镂刻",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 172,
        "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
        "len": 3,
        "hash_name": "SSG 08 | Orange Filigree"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d5a62b53763aa4e4ad8b3a5265ea19cef7b2b4688358f70db66af3d90f900d9e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 穆拉诺之紫",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 173,
        "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
        "len": 3,
        "hash_name": "G3SG1 | Violet Murano"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3edcd06702b8d4b4014a57fb3f611b3fc915ab4a9babc6dcaad759aa3744073e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 翡翠色调",
        "min": 0,
        "max": 0.08,
        "target_box": "set_canals",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 174,
        "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
        "len": 3,
        "hash_name": "Dual Berettas | Emerald"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6431a21f8db72e48a5fe2c8f05c00869e2d52dc45eaa58862120aad5b3ac8c15.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 巴洛克之红",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 175,
        "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
        "len": 3,
        "hash_name": "P90 | Baroque Red"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c5ab20cfbb2f2be6440fecbc8a7db18de7ce0743deacb3fc63608c5bff436131.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AK-47 | 巴洛克之紫",
        "min": 0,
        "max": 1,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 176,
        "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
        "len": 4,
        "hash_name": "AK-47 | Baroque Purple"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/926e5fe295257742857e82f03b5a5e7f98535d1758e6307cfc1f48fd4077fd05.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SG 553 | 红苹果",
        "min": 0,
        "max": 0.7,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 177,
        "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
        "len": 4,
        "hash_name": "SG 553 | Candy Apple"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ec70e38a0771c2ecf4cba4dfe8699e4067224f14b3710883dae72b571acacde0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 暗之镂刻",
        "min": 0,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 178,
        "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
        "len": 4,
        "hash_name": "P250 | Dark Filigree"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ff69f6a732046f68050e95b80f663ac40d7fc38fe61431963f4293aff9e5f301.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "Tec-9 | 穆拉诺之橙",
        "min": 0,
        "max": 1,
        "target_box": "set_canals",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 179,
        "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
        "len": 4,
        "hash_name": "Tec-9 | Orange Murano"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/2e31bac854a388901b6f36f3b93357fe6872d6d798a0e785ae1b7f7dc30433a4.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 穆拉诺之蓝",
        "min": 0,
        "max": 1,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 180,
        "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
        "len": 4,
        "hash_name": "AUG | Navy Murano"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cc3ca8f43efa91abd762f650bb84e98197ac5ecfe910687cc81e11d018170c82.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SCAR-20 | 石砌",
        "min": 0.06,
        "max": 0.5,
        "target_box": "set_canals",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 181,
        "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
        "len": 4,
        "hash_name": "SCAR-20 | Stone Mosaico"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3cd1c49256a151150cb404fdbd5a94ce2e43b11c9abb76147e3eb0ed4ac96ac9.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "R8 左轮手枪 | 水都泛波",
        "min": 0.06,
        "max": 0.6,
        "target_box": "set_canals",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 182,
        "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
        "len": 4,
        "hash_name": "R8 Revolver | Canal Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/39fddffd75633a7c226974d23b868bd35e23ee38010aa61028e18e46d8aa7c61.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "CZ75 | 紫青之色",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_canals",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 183,
        "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
        "len": 4,
        "hash_name": "CZ75-Auto | Indigo"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8a5a1d13dd292f42a9d30d0786e87abd8797682cc418256807c6cc95cc30e3a5.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "内格夫 | 巴洛克之沙",
        "min": 0,
        "max": 1,
        "target_box": "set_canals",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 184,
        "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
        "len": 4,
        "hash_name": "Negev | Boroque Sand"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/974ee8ff33bb126e92b73b037e6a00fd7dd5ed13f9d2662df9c54aaf2814006a.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 野荷",
        "target_box": "set_stmarc",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 185,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Wild Lotus"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/cdc601745236b88db058d46bd61bcabc8768c2fc2f4d51b81824b0ab39ea5f28.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP9 | 野百合",
        "min": 0,
        "max": 0.7,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 186,
        "list": "\nAK-47 | 野荷",
        "len": 1,
        "hash_name": "MP9 | Wild Lily"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8bd350463d3b82fedb6879515896c242aa76d0ed51623e7cb18c069796a7cc94.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 午夜百合",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 187,
        "list": "\nMP9 | 野百合",
        "len": 1,
        "hash_name": "AUG | Midnight Lily"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f69c7364ebc404a3ce4873eba4808e62cad5a1032babd334117e4e8eb0d28464.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SSG 08 | 海滨印花",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 188,
        "list": "\nMP9 | 野百合",
        "len": 1,
        "hash_name": "SSG 08 | Sea Calico"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/68b878afc54d840bc7289f04f55c6766f66740eb59e0c0773e29750dab7badf8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 合成叶",
        "min": 0,
        "max": 0.7,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 189,
        "list": "\nMP9 | 野百合",
        "len": 1,
        "hash_name": "Glock-18 | Synth Leaf"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/28c4bb1d177cd7a39f44457f0227b8acb27d9d7c0c97c1fdd1a76398923c0fe5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 青之绽放",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 190,
        "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
        "len": 3,
        "hash_name": "MP7 | Teal Blossom"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b21329a33db27c01c7983f29577430f180d1861936ae3894821d03174d5ec12e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 忘忧草",
        "min": 0,
        "max": 0.55,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 191,
        "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
        "len": 3,
        "hash_name": "UMP-45 | Day Lily"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6cb50bb87a99185a76a3f10b3ad980b0d10ff3b5639e97123fd045409d6cbfa7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 日暮",
        "min": 0,
        "max": 0.6,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 192,
        "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
        "len": 3,
        "hash_name": "FAMAS | Sundown"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f4f098549bb4e4a2d7c405c095238203a277c9fa8f136c8931879c4779573881.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 绛之绽放",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 193,
        "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
        "len": 3,
        "hash_name": "Five-SeveN | Crimson Blossom"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/57429b56582cc333db64a03b1eacc94c6fad952d56dc9ce89a11f29a5ba4128c.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "Tec-9 | 锈叶",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 194,
        "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
        "len": 4,
        "hash_name": "Tec-9 | Rust Leaf"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/e2e530485c19da4d1133d9ffcb34b12a61ff1205dc5b1ac660bad0809abe6fcb.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 暗之绽放",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 195,
        "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
        "len": 4,
        "hash_name": "M4A4 | Dark Blossom"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d4e6d71aa8fde9e81e8d59b8a9b58b8eba9404b3804a8456c3dae8cfea06376e.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "XM1014 | 芭蕉叶",
        "min": 0,
        "max": 0.6,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 196,
        "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
        "len": 4,
        "hash_name": "XM1014 | Banana Leaf"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ae17a91681b861e754b3e77f2f6eed3462c15b4ec5cbfa65d17dd924794cf340.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P90 | 日落百合",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 197,
        "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
        "len": 4,
        "hash_name": "P90 | Sunset Lily"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/2811da2934b17b275d7027ebcffabdc97e8fd17f07d868aabdd30094d1dcf465.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "M249 | 狂野丛林",
        "min": 0,
        "max": 1,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 198,
        "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
        "len": 4,
        "hash_name": "M249 | Jungle"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3717ebc4cce050029cae3fcb552d11695bf3bae0519d60d3956dda6c79414a6d.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 海鸟",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 199,
        "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
        "len": 4,
        "hash_name": "PP-Bizon | Seabird"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0f1a8fd8e6f8d13a73f1331bc013b4ec521979a657b86bd194fcd80e795946d6.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP5-SD | 茂竹之园",
        "min": 0,
        "max": 0.55,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 200,
        "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
        "len": 4,
        "hash_name": "MP5-SD | Bamboo Garden"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3482d7e5dc8458ccd3ebc0bf1af38e66a644165d97953f0a8443bd8aaaf2366d.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 冲浪木",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 201,
        "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
        "len": 4,
        "hash_name": "MAC-10 | Surfwood"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/47d25fa1e97851dc84dce9181d55e883e59dda214de5bde57e800d5134db14f1.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 灌木丛",
        "min": 0,
        "max": 0.5,
        "target_box": "set_stmarc",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 202,
        "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
        "len": 4,
        "hash_name": "Sawed-Off | Jungle Thicket"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ec8da48ab8aeadf10024a82ce2688f0fc3657df8b4cf749d056023cc56690492.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SG 553 | 意式拉力",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 203,
        "list": "",
        "len": 0,
        "hash_name": "SG 553 | Integrale"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/7bc196f62090f06e2b5201f299f7b10b6d804998977556c47f6a8eef7f29d732.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "双持贝瑞塔 | 双涡轮",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 204,
        "list": "",
        "len": 0,
        "hash_name": "Dual Berettas | Twin Turbo"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9d719acd57c316008eab6b0f38cb2d02ce8587b3098c30cc2a1fd19d5808f61f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 安全网",
        "min": 0,
        "max": 0.6,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 205,
        "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
        "len": 2,
        "hash_name": "AK-47 | Safety Net"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/436850ae88217677f44c16a5f191dfd4461c1204264cdffef39da115090e6a24.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 渐变之色",
        "min": 0,
        "max": 0.25,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 206,
        "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
        "len": 2,
        "hash_name": "MP7 | Fade"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5aee993f3c35c6bd50d87e27d52abfede39a1313baeca1082cbaa47fb3386f95.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 葡萄酒",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 207,
        "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
        "len": 2,
        "hash_name": "P250 | Vino Primo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/adbccb3c8ad1be06dddea8cc96e7f3eb1509ad0cb83145215ec524464ee58713.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 刹车灯",
        "min": 0,
        "max": 0.08,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 208,
        "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
        "len": 3,
        "hash_name": "Sawed-Off | Brake Light"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/57fab8c97a60d3733b48a8df3597cf7864909102fbd79e68856fff6057c929fe.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 引擎故障灯",
        "min": 0,
        "max": 0.6,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 209,
        "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
        "len": 3,
        "hash_name": "USP-S | Check Engine"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/18a60343772e7f708b1de64a64d2686428c0f53e0362b2eb707b56550ddaf937.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 变频器",
        "min": 0,
        "max": 0.4,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 210,
        "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
        "len": 3,
        "hash_name": "M4A4 | Converter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/17ad29714cc72c78ebc30e3aa0adf6bc83963e03bf0fde07adb14f3d7c5a46ae.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 手刹",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 211,
        "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
        "len": 3,
        "hash_name": "SSG 08 | Hand Brake"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/6b0b042b488388f3e9a2df020e68a301df233f7b7daa9be55b7ee6b5e509cb39.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 远光灯",
        "min": 0,
        "max": 0.08,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 212,
        "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
        "len": 4,
        "hash_name": "Glock-18 | High Beam"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/dd5ed561fc4c9b799ebe6661cb1383e32195475a6e9fadf9b2617d2384796309.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "R8 左轮手枪 | 氮化处理",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 213,
        "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
        "len": 4,
        "hash_name": "R8 Revolver | Nitro"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8c0c3917663bf56f4023e91b05a5e25780e3a3badd4a1fa020b81cb86dc1d843.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAC-10 | 小牛皮",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 214,
        "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
        "len": 4,
        "hash_name": "MAC-10 | Calf Skin"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/12069430be3b0cba66de00370ce7f55e28967938a175c6fe24180ce542402fa2.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "PP-野牛 | 红苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 215,
        "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
        "len": 4,
        "hash_name": "PP-Bizon | Candy Apple"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e011345efe76017c9d55e5b33a74a697fb4698166cb0000a31568bee465489c4.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 外表生锈",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 216,
        "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
        "len": 4,
        "hash_name": "MAG-7 | Rust Coat"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e66f9b57df3350825fbe5bf3a80414fbd3d994d7d65c71401b5a9edb5f1573ee.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 扫频仪",
        "min": 0,
        "max": 0.6,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 217,
        "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
        "len": 4,
        "hash_name": "AUG | Sweeper"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6aa73883e463d6cca717e3de05a89a460e89b2593192b060077bf60aa75d2a10.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 滑移",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 218,
        "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
        "len": 4,
        "hash_name": "MP9 | Slide"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/05c866722239cc2b0e5d4869b643d06d157a4bd62cc6e9a16227cf7f7dc4a208.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP5-SD | 越野",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 219,
        "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
        "len": 4,
        "hash_name": "MP5-SD | Dirt Drop"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/02ab02396b23c6f54af185347aab25ca671f9c2b6d533cc8db47e4187da21441.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 泥地杀手",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 220,
        "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
        "len": 4,
        "hash_name": "UMP-45 | Mudder"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3da20930e8a076104fed8c46a3d8485ca818505d9bae9b3e7bb211c345b74890.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 控制台",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 221,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Control Panel"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/7e634af9915b44d34e9d5ce58d9f2b7a1944fcf14a23d46db1b345192e97295c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "Tec-9 | 遥控",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 222,
        "list": "",
        "len": 0,
        "hash_name": "Tec-9 | Remote Control"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e6f0ac46a4b2123a205361824f9e59d024fe7781bb150a06d38deadb8821f28e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 核子花园",
        "min": 0,
        "max": 0.7,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 223,
        "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
        "len": 2,
        "hash_name": "Glock-18 | Nuclear Garden"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4a47c6b6e76a6353ebb46a9e7932c32b7051d7985ad2380ff6b444ca145280cc.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 核芯破裂",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 224,
        "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
        "len": 2,
        "hash_name": "MAG-7 | Core Breach"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/784e72a85bd6c23a40a7f5b8bcbf2e99d40b8ba86d36500b1c1c60c51ace22b0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 随机存取",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 225,
        "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
        "len": 2,
        "hash_name": "AUG | Random Access"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/54d9bf8729fc788d1a01a8df164e9b3a0f50e6cf775ee334b03c81cacd171d3e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AWP | 冥界之河",
        "min": 0,
        "max": 0.83,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 226,
        "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
        "len": 3,
        "hash_name": "AWP | Acheron"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/eea3ffb8aee3db6a5e52af7fef50a139c7eba51de1eb094321adf624de9a7152.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 交换机",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 227,
        "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
        "len": 3,
        "hash_name": "P250 | Exchanger"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b699bce38764b741f91d3aae8670ea2e1974cd1d61af545c11892feb3ad6ee0d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 协处理器",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 228,
        "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
        "len": 3,
        "hash_name": "MP5-SD | Co-Processor"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bc1bd0a1585871b91f5aa7e343e21604347ab8c50dd709fc3383a161697f5352.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 设施系列·底片图",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 229,
        "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
        "len": 3,
        "hash_name": "P90 | Facility Negative"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c8c685b19fda025447d49b83b4ae05bf7e9072c8ec49d0514fec1fcfe5357aae.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "加利尔 AR | 冰核聚变",
        "min": 0,
        "max": 0.83,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 230,
        "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
        "len": 4,
        "hash_name": "Galil AR | Cold Fusion"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/3621652b5fa24f3392f33353d1336c0911457ff4b338fc7ec999e1511ccbd6f0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "内格夫 | 舱壁",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 231,
        "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
        "len": 4,
        "hash_name": "Negev | Bulkhead"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/cb6dbea961d9b66f9874a18c1604450c269b0880ba2124a8c1dd9de6c02a73ff.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP7 | 主板",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 232,
        "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
        "len": 4,
        "hash_name": "MP7 | Motherboard"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/579fa6357cf88c495ed1c74e858f491fbd3eb692a535dab48a56485e023266fd.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 主机",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 233,
        "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
        "len": 4,
        "hash_name": "M4A4 | Mainframe"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/32df78668e72e7c06ca1aefe590a93db1b86923dd883d00e3b7125ec222769c5.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 芯轴",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 234,
        "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
        "len": 4,
        "hash_name": "Nova | Mandrel"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3779b6e2fead191c1a0df55f7358b5ba3cb73ba90df33d4f0c032d4319fbdc6f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "FN57 | 冷却剂",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 235,
        "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
        "len": 4,
        "hash_name": "Five-SeveN | Coolant"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/21cd723acbbd05e9a91fb7269eb696c144a9ae12a195208124a2dcbedbe9a1b0.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 设施系列·深色图",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 236,
        "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
        "len": 4,
        "hash_name": "UMP-45 | Facility Dark"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/97f114ac303cf6a323b0184960092818022dd6dbf702ee32aa81df26d781206f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 设施系列·草图",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 237,
        "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
        "len": 4,
        "hash_name": "P250 | Facility Draft"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/7031d6dc4a771df8568e6a1bf20981f057cf8b6fd259e463da48ce65f300539f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 设施系列·速写图",
        "min": 0,
        "max": 0.5,
        "target_box": "set_nuke_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 238,
        "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
        "len": 4,
        "hash_name": "PP-Bizon | Facility Sketch"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/80d9dc963e94239510956b5de27363810bf834b2fee99f54c66f4b634c7afbb0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 爆破能手",
        "min": 0.26,
        "max": 0.6,
        "target_box": "set_vertigo",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 239,
        "list": "",
        "len": 0,
        "hash_name": "Dual Berettas | Demolition"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e4bf9f0ad82b1f80ba5f955b5149f525ce1171fa7cdf9825bd193a77adb9b33a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AK-47 | 黑色层压板",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_vertigo",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 240,
        "list": "\n双持贝瑞塔 | 爆破能手",
        "len": 1,
        "hash_name": "AK-47 | Black Laminate"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/18544f7e6ae9775eef7d9a51597514e9ba1a6fe7e28f9d7e2a4b70ea6ab10c2a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 冰川网格",
        "min": 0,
        "max": 1,
        "target_box": "set_vertigo",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 241,
        "list": "\n双持贝瑞塔 | 爆破能手",
        "len": 1,
        "hash_name": "P90 | Glacier Mesh"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/895732d2db8da276497dee87aa788777cb8ec7d42bfee62058dfd494fbb32dfe.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "PP-野牛 | 碳素纤维",
        "min": 0,
        "max": 0.12,
        "target_box": "set_vertigo",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 242,
        "list": "\nAK-47 | 黑色层压板\nP90 | 冰川网格",
        "len": 2,
        "hash_name": "PP-Bizon | Carbon Fiber"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/83bfdce2ff055c81cc6468b6ce0d22d7b6fa3a7e9bd4560c526d001928ce32a8.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 都市 DDPAT",
        "target_box": "set_vertigo",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 243,
        "list": "\nPP-野牛 | 碳素纤维",
        "len": 1,
        "hash_name": "MAC-10 | Urban DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3289d4cea4e1e6b9c5c0ccd6060bbbcce4558df4cc14b9ebdbab1b76f8b88696.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 都市穿孔",
        "min": 0,
        "max": 1,
        "target_box": "set_vertigo",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 244,
        "list": "\nPP-野牛 | 碳素纤维",
        "len": 1,
        "hash_name": "XM1014 | Urban Perforated"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/65e1e6a47ca903b04362a82f1ce1ae5e44983dc28c1c429f4abfae626545fb24.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 晶红石英",
        "min": 0,
        "max": 0.4,
        "target_box": "set_train",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 245,
        "list": "",
        "len": 0,
        "hash_name": "Tec-9 | Red Quartz"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d074cc227d5b016d7cb88c545a1c1501c44fb0ec9555eb49d1da212991e5e732.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 都市瓦砾",
        "min": 0,
        "max": 0.5,
        "target_box": "set_train",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 246,
        "list": "\nTec-9 | 晶红石英",
        "len": 1,
        "hash_name": "Desert Eagle | Urban Rubble"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4bd3cf0defd221b8f67ea2f0e6770a91224f1ee5997536fecf973c4c0117c644.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 渐变琥珀",
        "min": 0,
        "max": 0.4,
        "target_box": "set_train",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 247,
        "list": "\nTec-9 | 晶红石英",
        "len": 1,
        "hash_name": "Sawed-Off | Amber Fade"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/496c3fd8e4cfa044d3a18cd7ca148174bf10c27aea9f80a66e30787c9c53cd28.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 金属 DDPAT",
        "target_box": "set_train",
        "min": 0,
        "max": 0.08,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 248,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "P250 | Metallic DDPAT"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d30c94d6af923d25d9a2380dc2baaa49a54281d5476d9ee773bcadb6fb043d55.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAG-7 | 金属 DDPAT",
        "target_box": "set_train",
        "min": 0,
        "max": 0.08,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 249,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "MAG-7 | Metallic DDPAT"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/f79a2b240290a88062f64f522aa489463409c0646530f18165f30c693c8d3c24.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SCAR-20 | 碳素纤维",
        "min": 0,
        "max": 0.12,
        "target_box": "set_train",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 250,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "SCAR-20 | Carbon Fiber"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/e72b1bd1be23e3e1fda71c9cc60926f6c1155ceb3b769113dc89769cd5bdd4df.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P90 | 白蜡木",
        "min": 0,
        "max": 0.45,
        "target_box": "set_train",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 251,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "P90 | Ash Wood"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/5e3024e7100c3edf2b7ab7137bd123bdb0b48e42c2d779d79259452cb93dfd5b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAC-10 | 红苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_train",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 252,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "MAC-10 | Candy Apple"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/84b810b58d69b0219ea677d77e80ab1d682bed426c2f777f018b4ed61ac6581d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 都市 DDPAT",
        "target_box": "set_train",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 253,
        "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
        "len": 2,
        "hash_name": "M4A4 | Urban DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/bbe5597eebb221f672f58050589c716b03a632ddd99a9829502454843e5a7e49.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 都市 DDPAT",
        "target_box": "set_train",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 254,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "UMP-45 | Urban DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/7319ead144341716894a589f8b42208c57b8b46506c528d1f3784de90f846772.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 殖民侵略者",
        "min": 0,
        "max": 1,
        "target_box": "set_train",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 255,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "Dual Berettas | Colony"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/61e0495b589c88179e285f7dcdc9029b6e752aa6ab66091d171f9ffe362e1808.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 极地迷彩",
        "min": 0,
        "max": 1,
        "target_box": "set_train",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 256,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "G3SG1 | Polar Camo"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e00f1f5390d378cb515106833eedac542d522eada7c0f9a4d209b01a0037054a.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "FN57 | 暮色森林",
        "min": 0,
        "max": 1,
        "target_box": "set_train",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 257,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "Five-SeveN | Forest Night"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/7b7b9e3a63d1aab21e128bb5dc254c9b3f1b8e62543c208e4cddf1a1e3edb125.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 极地网格",
        "min": 0,
        "max": 1,
        "target_box": "set_train",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 258,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "Nova | Polar Mesh"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/2aca37f13a4df0daf24a66fa8d48ec9a9edcac34026e77862c1390c85edd1ecf.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 都市虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_train",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 259,
        "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
        "len": 5,
        "hash_name": "PP-Bizon | Urban Dashed"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f52011b247f5997b81f50254f3194a60574b7ee9e573d5cc14e504936697138f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 氮化处理",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 260,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Nitro"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1c0c1c2999315327c63c03ecd514d3e84f70408df864e63cbccaabebf1cfed37.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 渐变强酸",
        "min": 0,
        "max": 0.03,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 261,
        "list": "\nM4A1 消音型 | 氮化处理",
        "len": 1,
        "hash_name": "SSG 08 | Acid Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/eaf2a707aae16b6b26072aace7b9e2608219f9d7850e35a15b1f594fd82b8aeb.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 银白石英",
        "min": 0,
        "max": 0.4,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 262,
        "list": "\nM4A1 消音型 | 氮化处理",
        "len": 1,
        "hash_name": "Five-SeveN | Silver Quartz"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ee1d2e4eeec15080d64c4e8dc70e5f6464c1d4d974228287c8458eaf18bf98c2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 摧枯拉朽",
        "min": 0,
        "max": 0.6,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 263,
        "list": "\nM4A1 消音型 | 氮化处理",
        "len": 1,
        "hash_name": "FAMAS | Teardown"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/f8f20ef096702ea84009bf51da4e01bbe8aae493c8c848cee56e86b544047cfc.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "G3SG1 | 多变迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 264,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "G3SG1 | VariCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/0d06fb4448a766e995ea68f08aef15d0c43f6a60703edb96ca6962aaf30be464.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M249 | 鳄鱼网格",
        "min": 0,
        "max": 0.6,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 265,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "M249 | Gator Mesh"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/0b54c484e3a36abf5e9f0d311a06f41f357d935a0dc50f64f62ece8fded70e38.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "加利尔 AR | 多变迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 266,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "Galil AR | VariCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/1ed7a1d195a5285fa6783d34080003f467e993967b339ac191812bc0475fd05d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "USP 消音版 | 森林之叶",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 267,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "USP-S | Forest Leaves"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/36b2ee59c1c7e80b340b399cf64e73ba6876cb9fd17617383e72d20c2c9799c4.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AUG | 众枪之的",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 268,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "AUG | Condemned"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/259cd28670b2617f599ed03ca81c3d59d3f5e11cf358169b2520bbc42cc13670.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP9 | 橘皮涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 269,
        "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
        "len": 3,
        "hash_name": "MP9 | Orange Peel"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d897140d1befb9604113c3e36495f01297fa7ee79d93f312aab6f62753db043e.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 雇佣兵",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 270,
        "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
        "len": 5,
        "hash_name": "Dual Berettas | Contractor"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/313446ba89e757ef91f17c79f912db644196cb30eba5df6db0802c243afa0a3f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SCAR-20 | 雇佣兵",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 271,
        "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
        "len": 5,
        "hash_name": "SCAR-20 | Contractor"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/58720bb1e7d30bd794e156e16e3f9690fcfb86d7fb14ec48fbd765b03d0b4efb.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 蓝色云杉",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 272,
        "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
        "len": 5,
        "hash_name": "SSG 08 | Blue Spruce"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/08d62df7d279c7048940c5b7a2ba95da0aba8ded303e5ef480515a305355c1db.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 陆军网格",
        "min": 0,
        "max": 0.6,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 273,
        "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
        "len": 5,
        "hash_name": "Tec-9 | Army Mesh"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d7c14d1e1db6548a5dcce064dace83c64715d5faaf43725c035ab2527f4aed71.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 陆军斥候",
        "min": 0,
        "max": 1,
        "target_box": "set_safehouse",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 274,
        "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
        "len": 5,
        "hash_name": "MP7 | Army Recon"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/38bdbf02e0b71cff6cafdeee8fb07b5edf16010b587e48397b8c62f0f8ea541f.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AUG | 秋叶原之选",
        "min": 0,
        "max": 1,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 275,
        "list": "",
        "len": 0,
        "hash_name": "AUG | Akihabara Accept"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ee1f079ec4a0922e085ddced768497b197fdb6fb4e1ed4acf5117e442652b985.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 水栽竹",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 276,
        "list": "\nAUG | 秋叶原之选",
        "len": 1,
        "hash_name": "AK-47 | Hydroponic"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/00b6ff99710a0186c6030c0fc245cd98cbc08f85d1d5e4a7ae632938cdda87dc.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 日式荧光涂装",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 277,
        "list": "\nAK-47 | 水栽竹",
        "len": 1,
        "hash_name": "Five-SeveN | Neon Kimono"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4747999ff9c314025294d50d482927ac1054324f6e4e2dfc0f2ac760db540df7.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 日落风暴 壱",
        "target_box": "set_kimono",
        "min": 0,
        "max": 0.75,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 278,
        "list": "\nAK-47 | 水栽竹",
        "len": 1,
        "hash_name": "Desert Eagle | Sunset Storm 壱"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0e925dc0a1b04046f65d608d4069fb0b362808b5492967a8eeba796ddf32a9a6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 日落风暴 弐",
        "target_box": "set_kimono",
        "min": 0,
        "max": 0.75,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 279,
        "list": "\nAK-47 | 水栽竹",
        "len": 1,
        "hash_name": "Desert Eagle | Sunset Storm 弐"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d0bc2faf2d492973d5ebd3d8917f15944a79476bb4acddad2cd4d1afac47927a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 破晓",
        "min": 0,
        "max": 0.6,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 280,
        "list": "\nAK-47 | 水栽竹",
        "len": 1,
        "hash_name": "M4A4 | Daybreak"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4c46338f743e36af7812aecaad8c1ebaaa8c71020beb8dc6b40537f4eba69c57.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 水蓝阶地",
        "min": 0,
        "max": 0.7,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 281,
        "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
        "len": 3,
        "hash_name": "Galil AR | Aqua Terrace"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3bfa3901f38b6b9cf18e2a21032421314b505df7e5514ddc9a6c68e6540a156c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 反梗精英",
        "min": 0,
        "max": 0.65,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 282,
        "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
        "len": 3,
        "hash_name": "MAG-7 | Counter Terrace"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/da906fdf3b6e1f889ae05edef8471bb0881d93f6262a11aadf2b15e79a762721.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 梗怖分子",
        "min": 0,
        "max": 1,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 283,
        "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
        "len": 3,
        "hash_name": "Tec-9 | Terrace"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/2e8687278cc89507b1a7d964cf8e0b932889931344b7e171e6cdfe70e262221b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 日式深红涂装",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 284,
        "list": "\n加利尔 AR | 水蓝阶地\nMAG-7 | 反梗精英\nTec-9 | 梗怖分子",
        "len": 3,
        "hash_name": "P250 | Crimson Kimono"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/21a1c04bf433fb0f8e582f3be5ff7023a1cbcdb72584fd3aaed5ece97bd7d8bd.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "沙漠之鹰 | 午夜风暴",
        "min": 0,
        "max": 0.75,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 285,
        "list": "\n加利尔 AR | 水蓝阶地\nMAG-7 | 反梗精英\nTec-9 | 梗怖分子",
        "len": 3,
        "hash_name": "Desert Eagle | Midnight Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/b5537ee98a72c3c6dd1465cd7b909acf20e64dd1e980dad553ad33af6f59cf0b.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 墨竹",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 286,
        "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
        "len": 2,
        "hash_name": "PP-Bizon | Bamboo Print"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/bb101135fe9298a59ebcb58355c1a18811f66b30770c88921f8ba80f028b040f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 竹影",
        "min": 0,
        "max": 0.6,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 287,
        "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
        "len": 2,
        "hash_name": "Sawed-Off | Bamboo Shadow"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/79f062e08a6d7c994a82f0a1ddd285e74a1ec56eb4ae5c11f06a72e3bff925e5.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 竹林",
        "min": 0,
        "max": 0.6,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 288,
        "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
        "len": 2,
        "hash_name": "Tec-9 | Bamboo Forest"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6abcbd9ae5edcb33f09c650cda965b43dbadffd09d959197721c6009f263c6b6.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 日式橙色涂装",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 289,
        "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
        "len": 2,
        "hash_name": "G3SG1 | Orange Kimono"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/80b692074f7a3bc5cb2fd515c504d21e11ca57c56df4853087a7bff9f465a8d8.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 日式薄荷涂装",
        "min": 0,
        "max": 0.8,
        "target_box": "set_kimono",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 290,
        "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
        "len": 2,
        "hash_name": "P250 | Mint Kimono"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/6b807f342b4f938b25ffb962ed1b191313feb7f9621a79062da8d9c2864f8301.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 神来之作",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 291,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Master Piece"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/750b20601690da9c2cff1ec1609432000675c20d3d1440a6f29f4491805a845f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 粉红 DDPAT",
        "target_box": "set_overpass",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 292,
        "list": "\nM4A1 消音型 | 神来之作",
        "len": 1,
        "hash_name": "AWP | Pink DDPAT"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/bdd5881df6fd0f00e2c76fd6cb7b18879425456df80b0e2577b69817817ea4e0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 公路杀手",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 293,
        "list": "\nM4A1 消音型 | 神来之作",
        "len": 1,
        "hash_name": "USP-S | Road Rash"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/196d364aaa9678f3582e2d56aa80b4d0c3edf480852431ba1b67c6a8a3f92f08.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 氮化处理",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 294,
        "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
        "len": 2,
        "hash_name": "CZ75-Auto | Nitro"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4e1edfb6b56249ed13c2546f40675b56efb0757d24a6880be3ea8b7f1bbd5c61.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 蔚蓝多变迷彩",
        "min": 0,
        "max": 0.5,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 295,
        "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
        "len": 2,
        "hash_name": "XM1014 | VariCamo Blue"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cd03a3d1a14a7aa15817ff47fd653a8f13c7ff3e5e995c6b34bb0ac5df5cb6da.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 迂回路线",
        "min": 0,
        "max": 0.43,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 296,
        "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
        "len": 2,
        "hash_name": "SSG 08 | Detour"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/07f017dbee16a49814ab524b8338920626e98d25ecc7640b1588b9318a79cfb7.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "沙漠之鹰 | 都市 DDPAT",
        "target_box": "set_overpass",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 297,
        "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
        "len": 3,
        "hash_name": "Desert Eagle | Urban DDPAT"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/a7c460111ef267bb712df3ce93b1d07ac41d5c53bd11a2032efa3d513bf3fad1.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP7 | 硝烟",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 298,
        "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
        "len": 3,
        "hash_name": "MP7 | Gunsmoke"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/87f5b191126a37f7fcc6658294fe76ab6d9500a4ff1e8f015f75b9b41469591f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 噩梦之夜",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 299,
        "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
        "len": 3,
        "hash_name": "Glock-18 | Night"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/fd7b435bc14fda453d5374bb8749faced37c5fb77e4307d028dcea292da76542.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 工业牧草",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 300,
        "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
        "len": 3,
        "hash_name": "P2000 | Grassland"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cb19e4de792ccad083c06c3483ee662de2f9df8861f741c923b2f7005fe4c10b.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 贤者涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 301,
        "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
        "len": 4,
        "hash_name": "Sawed-Off | Sage Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/09041355765803f42b26bacaa9f55a0afdb0bc02f619794630543a88a16c6d26.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 枯焦之色",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 302,
        "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
        "len": 4,
        "hash_name": "UMP-45 | Scorched"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/eeeaaa9d27f8bff3ed5ed02761670dc038a41be73a61d8c9b5ec75212fce1471.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "M249 | 对比涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 303,
        "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
        "len": 4,
        "hash_name": "M249 | Contrast Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/253cdc41eedb5026c383ddc7175e0f894418006b99888a62c837db2850f742f3.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 暴风呼啸",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 304,
        "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
        "len": 4,
        "hash_name": "MAG-7 | Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/e7659da15a9402404d08c7e4eaf15c006f92b99936be61a6308267861904d1c1.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 暴风呼啸",
        "min": 0,
        "max": 1,
        "target_box": "set_overpass",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 305,
        "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
        "len": 4,
        "hash_name": "MP9 | Storm"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f4bf72a17de19a55942cc5a6ad70b77a78aa36e2a53176aba7118fdd28bdf057.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 银装素裹",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_office",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 306,
        "list": "",
        "len": 0,
        "hash_name": "MP7 | Whiteout"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6dda53583d7b81684900d507667e2f7f904eed22002bacdc11d733a432cc1004.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 银质",
        "min": 0,
        "max": 0.08,
        "target_box": "set_office",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 307,
        "list": "",
        "len": 0,
        "hash_name": "P2000 | Silver"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/095d01464a7610610336d055cd9ead07c5535d193204118b35d8568912bf0261.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M249 | 暴雪大理石纹",
        "min": 0,
        "max": 1,
        "target_box": "set_office",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 308,
        "list": "\nMP7 | 银装素裹\nP2000 | 银质",
        "len": 2,
        "hash_name": "M249 | Blizzard Marbleized"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/acdceaec460e1205fa066277946de3bcccd302c4a208073f812a8a91173606cf.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "G3SG1 | 极寒迷彩",
        "min": 0,
        "max": 1,
        "target_box": "set_office",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 309,
        "list": "\nMP7 | 银装素裹\nP2000 | 银质",
        "len": 2,
        "hash_name": "G3SG1 | Arctic Camo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/1c9fa47cc9aae7bd0efe11102e308519d542d8bab462b45968e2f983f5e27a8b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "加利尔 AR | 冬之森林",
        "min": 0,
        "max": 1,
        "target_box": "set_office",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 310,
        "list": "\nMP7 | 银装素裹\nP2000 | 银质",
        "len": 2,
        "hash_name": "Galil AR | Winter Forest"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/b024728b63071daba909f702579a9c919108b36e88d2e0d00f53703e3c850194.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "法玛斯 | 对比涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_office",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 311,
        "list": "\nM249 | 暴雪大理石纹\nG3SG1 | 极寒迷彩\n加利尔 AR | 冬之森林",
        "len": 3,
        "hash_name": "FAMAS | Contrast Spray"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/645a6f5143ccb96d6d0675e6310b2b46f69620c3099c763d0f5bd0d583cdd80f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 核子威慑",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 312,
        "list": "",
        "len": 0,
        "hash_name": "Tec-9 | Nuclear Threat"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3662c49ad78679145ff7725646954d9b601663c049010a79b3ce37218ef11de0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 核子威慑",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 313,
        "list": "",
        "len": 0,
        "hash_name": "P250 | Nuclear Threat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6b5ac4f4b56920ee2253f47fb88469105e0aadf2b2b798bec58d3c1464ccd858.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 辐射危机",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 314,
        "list": "\nTec-9 | 核子威慑",
        "len": 1,
        "hash_name": "M4A4 | Radiation Hazard"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/85f82bae0ec36807a89c7276430b5f4d4dcf014a35fab6fcde58a1f51b845633.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "XM1014 | 辐射警告",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 315,
        "list": "\nM4A4 | 辐射危机",
        "len": 1,
        "hash_name": "XM1014 | Fallout Warning"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/3d40be9f531c0329baf206247845332fa6f30a051fc2fdc6f3876f1e2fc3e6c7.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "UMP-45 | 辐射警告",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 316,
        "list": "\nM4A4 | 辐射危机",
        "len": 1,
        "hash_name": "UMP-45 | Fallout Warning"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/5771c52915ff2273311f037048565cb7535ce755cb9038bdcfcdf83087487748.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P90 | 辐射警告",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 317,
        "list": "\nM4A4 | 辐射危机",
        "len": 1,
        "hash_name": "P90 | Fallout Warning"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8e0f37582cdee5089167a5b716068ab2890bc0ee88acb1b2dfadd43fce8af958.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 辐照警报",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 318,
        "list": "\nXM1014 | 辐射警告",
        "len": 1,
        "hash_name": "PP-Bizon | Irradiated Alert"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8c030a4109d96b32b5d79737505a78b0b0ba9cc70b7dbcd47f87481a9e7a1ebc.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 辐照警报",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 319,
        "list": "\nXM1014 | 辐射警告",
        "len": 1,
        "hash_name": "Sawed-Off | Irradiated Alert"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/48f92686879d817568261a54fd9cef376bb255b4f322bf5f7afa69b9d1b5f248.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 辐照警报",
        "min": 0,
        "max": 1,
        "target_box": "set_nuke",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 320,
        "list": "\nXM1014 | 辐射警告",
        "len": 1,
        "hash_name": "MAG-7 | Irradiated Alert"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3564b4269c4474744b3888ec00d6f11704529f7480306d7048db3f25c59f9d3d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 威吓者",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 321,
        "list": "",
        "len": 0,
        "hash_name": "MAG-7 | Bulldozer"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5adb8945a1a5649d09b8375d573ea27081fa4d4761957aad9a05841fb379d5ce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 渐变琥珀",
        "min": 0,
        "max": 0.4,
        "target_box": "set_mirage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 322,
        "list": "\nMAG-7 | 威吓者",
        "len": 1,
        "hash_name": "MAC-10 | Amber Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f9738a23d33554c5ffd1dc3e0bda7135e2fea40bfefa92e47714a72dc56dfe7a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 炽烈之炎",
        "min": 0,
        "max": 0.08,
        "target_box": "set_mirage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 323,
        "list": "\nMAG-7 | 威吓者",
        "len": 1,
        "hash_name": "UMP-45 | Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bd38da3106dac0c133e0189a553fa3f11f317b92204175a54f02863fb57dfd7b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 赤红新星",
        "min": 0,
        "max": 0.08,
        "target_box": "set_mirage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 324,
        "list": "\nMAG-7 | 威吓者",
        "len": 1,
        "hash_name": "MP9 | Hot Rod"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8ab1bb28ae39b9cdd22e8b95629a4a33e58f4152df99126de804d7b7b5ea8908.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "内格夫 | 加州迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 325,
        "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
        "len": 3,
        "hash_name": "Negev | CaliCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/1bf41194d14be879b0624f9f6182e675b51d82f99492395ed8a9e1c9cc39c8e9.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SSG 08 | 热带风暴",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 326,
        "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
        "len": 3,
        "hash_name": "SSG 08 | Tropical Storm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/f87128467fe634044a86707d9ccd8c5032bf95367fa31196d6efa901e005fe9f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SG 553 | 鳄鱼网格",
        "min": 0,
        "max": 0.6,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 327,
        "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
        "len": 3,
        "hash_name": "SG 553 | Gator Mesh"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/43661d6c024c6dfdf85be24528fd486b1b333b88dc19743bd7ea3b74b9941566.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 地下水",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 328,
        "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
        "len": 3,
        "hash_name": "Glock-18 | Groundwater"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/bcb061355a1073ebd79c90c1eb3405d35b9ad087a2116e07b1bc7db3508b736a.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MP7 | 橘皮涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 329,
        "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
        "len": 3,
        "hash_name": "MP7 | Orange Peel"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/9a138b1ec336d7541e80dd26802299495857ca75781bd20bc32a61127db9edee.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 骸骨外罩",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 330,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "P250 | Bone Mask"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/fec79b8d6138af12171ddd9d1d326acb29b5ee168ac302b2cefd4ca29e5736d9.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "FN57 | 雇佣兵",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 331,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "Five-SeveN | Contractor"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/f2f61a85f9fe9c9e0c5898d38e2f75cff5152b266e60cb22fc692ae06981acae.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 殖民侵略者",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 332,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "AUG | Colony"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/2177ed7b9e55a90f093a936e54ee0b77ebfb56b4dfc0a747e5561874ce361da4.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 狩猎网格",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 333,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "G3SG1 | Safari Mesh"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6f318a4e255acb4b2b9d99ec143474b6a476916e4debe4a99b38afaf95082dfa.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P90 | 枯焦之色",
        "min": 0,
        "max": 1,
        "target_box": "set_mirage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 334,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "P90 | Scorched"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/ecb32af994571bb302c41e2ea652cc7b2cfdce4317a1a5dac72255cb10309952.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "加利尔 AR | 隐蔽猎手",
        "min": 0,
        "max": 0.6,
        "target_box": "set_mirage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 335,
        "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
        "len": 5,
        "hash_name": "Galil AR | Hunting Blind"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/e5a39c7a5938e4664053f559c224b93819eff2f72e229c723e537f361b89bab8.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SCAR-20 | 溅射果酱",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_militia",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 336,
        "list": "",
        "len": 0,
        "hash_name": "SCAR-20 | Splash Jam"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/27776682bc9b60376abb9eb453b112dc536b5f3fd4659a1981de0d032eebf42e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 现代猎手",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 337,
        "list": "\nSCAR-20 | 溅射果酱",
        "len": 1,
        "hash_name": "M4A4 | Modern Hunter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/29ac74264c111c372a8ad108b7057c87d0feb96f41190807493b98390b6f87b9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 现代猎手",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 338,
        "list": "\nM4A4 | 现代猎手",
        "len": 1,
        "hash_name": "Nova | Modern Hunter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/974c800975544a5c921dd0f6f270707379db99ccc2b03d4be572a67c5d3b8d6b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 现代猎手",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 339,
        "list": "\nM4A4 | 现代猎手",
        "len": 1,
        "hash_name": "PP-Bizon | Modern Hunter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/881d3aa1f6e9af507d2046a97c60f58f076481b17671acabec8578dd17f569c0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 火焰橙",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 340,
        "list": "\nM4A4 | 现代猎手",
        "len": 1,
        "hash_name": "XM1014 | Blaze Orange"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1f657f958b8d55c580ae41b02fce7130665e6a5e778eaaac0e48b79959b3c96f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 现代猎手",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 341,
        "list": "\nM4A4 | 现代猎手",
        "len": 1,
        "hash_name": "P250 | Modern Hunter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fd3f749026a0f6efcc0b90030cf6512403f4da841edf0cc60680d394692e7847.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 火焰橙",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 342,
        "list": "\nM4A4 | 现代猎手",
        "len": 1,
        "hash_name": "Nova | Blaze Orange"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/50f8aba2859d024b08edfe99ad511115f3add63feb2121de919cfbd0321f34e0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 草原落叶",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 343,
        "list": "\n新星 | 现代猎手\nXM1014 | 火焰橙",
        "len": 2,
        "hash_name": "P2000 | Grassland Leaves"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/6cebcce2a9c25a244edc696d494249f774a424306887d8da4a338f7d2008f37d.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 森林之叶",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 344,
        "list": "\nP2000 | 草原落叶",
        "len": 1,
        "hash_name": "PP-Bizon | Forest Leaves"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/56526e96e182849baf44ed37280ed99557e5c782fc5fa97086d02e2a0bd45389.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 狂哮飓风",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 345,
        "list": "\nP2000 | 草原落叶",
        "len": 1,
        "hash_name": "MAC-10 | Tornado"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/1fe762559fe79e661869d67f5a2e758bd2d1490d474a88c090d609b57cc2d286.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 工业牧草",
        "min": 0,
        "max": 1,
        "target_box": "set_militia",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 346,
        "list": "\nP2000 | 草原落叶",
        "len": 1,
        "hash_name": "XM1014 | Grassland"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a2ff0c5de885a2eba49055c59e1352f3712bfd36e268445e9a92962282516488.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 钴蓝石英",
        "min": 0,
        "max": 0.4,
        "target_box": "set_lake",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 347,
        "list": "",
        "len": 0,
        "hash_name": "Dual Berettas | Cobalt Quartz"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7bf17bb42af78077b0bc27df1a4f4224b7005415443a7990673498168ea0225c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 午夜行动",
        "min": 0,
        "max": 0.6,
        "target_box": "set_lake",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 348,
        "list": "\n双持贝瑞塔 | 钴蓝石英",
        "len": 1,
        "hash_name": "USP-S | Night Ops"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d9c9ecb2713384d42f0c72def17bc32b538e1ce25ae8697365e52efa147cc94a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 摧枯拉朽",
        "min": 0,
        "max": 0.6,
        "target_box": "set_lake",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 349,
        "list": "\n双持贝瑞塔 | 钴蓝石英",
        "len": 1,
        "hash_name": "P90 | Teardown"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a2a34849d0874384bc7f225d2e79733498854a9315f97319760ae2e355128b76.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 深蓝电镀处理",
        "min": 0,
        "max": 0.08,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 350,
        "list": "\n双持贝瑞塔 | 钴蓝石英",
        "len": 1,
        "hash_name": "SG 553 | Anodized Navy"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ddc5f615dad8d20386a40f5adbd655654784c17ccea6d87100791c4eed11dea9.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "沙漠之鹰 | 泥地杀手",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 351,
        "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "Desert Eagle | Mudder"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/252c9e209de1708d6505d4d8e0457dd232e268a9be85aad84a649c0442f8a08b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AWP | 狩猎网格",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 352,
        "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "AWP | Safari Mesh"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d47b2ad48721e0a5711f5a51075b03bec3f33759103016f9d17d64ab7a49c357.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "PP-野牛 | 午夜行动",
        "min": 0,
        "max": 0.6,
        "target_box": "set_lake",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 353,
        "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "PP-Bizon | Night Ops"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/a373321fdf8e1cc13433e35e7627796881ce99e52f605f9ed0e8489187f4f0b3.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "法玛斯 | 湖蓝涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 354,
        "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "FAMAS | Cyanospatter"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/2d0f113309df362b6e85650fddc02bb460e600c10bd6109ce031a5331536676a.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "XM1014 | 蓝钢",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 355,
        "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "XM1014 | Blue Steel"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3f3a49f8b5ab285f63512b43d5ea27abc0c7222af5daa93b119a6b8d10e15da2.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 北方森林",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 356,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "P250 | Boreal Forest"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/7da643084ee19c2d7123b64ab7875f7431aab7ae19829649f339efae2a7df688.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 蓝色云杉",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 357,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "XM1014 | Blue Spruce"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/4362f754f7a64cc007a9e9e94740e91e79fb0dbe454cce393d5a381a4efa36ea.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 暴风呼啸",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 358,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "AUG | Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/221dcc0128825cdeff070ad772868239f0b57adc7c72dfabea025a00dd72c135.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "加利尔 AR | 贤者涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 359,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "Galil AR | Sage Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0eebdafb550cc81a693664e72062c12c0c4b627f2ee5416f149bb0966d79c34f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 浪花穿孔",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 360,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "SG 553 | Waves Perforated"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/a544731821904fa889020e42172f5e4b883ea839e4df9193d7c6088f98c19f1c.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 丛林虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_lake",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 361,
        "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
        "len": 5,
        "hash_name": "G3SG1 | Jungle Dashed"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3749432a227bb3e56ec77a111d5b3edea289e00ddfd180114433fd8db9362e59.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 响尾蛇",
        "min": 0.08,
        "max": 0.5,
        "target_box": "set_italy",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 362,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Pit Viper"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/99605b694ab99ad6d22df89a0a0edc6f1f6ed46ca02ec7575732597823e45621.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 逮捕者",
        "min": 0,
        "max": 0.6,
        "target_box": "set_italy",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 363,
        "list": "\nAWP | 响尾蛇",
        "len": 1,
        "hash_name": "Sawed-Off | Full Stop"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5a9b698e29e02d8bb4e52a7540ee993a9c18c5517103868633dbe9674346268e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 红苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_italy",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 364,
        "list": "\nAWP | 响尾蛇",
        "len": 1,
        "hash_name": "Glock-18 | Candy Apple"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cf78fa10b89f3fd7338dd9a988430022f50d20dda4e291df3bfadbe028cc47b2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 深蓝电镀处理",
        "min": 0,
        "max": 0.08,
        "target_box": "set_italy",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 365,
        "list": "\nAWP | 响尾蛇",
        "len": 1,
        "hash_name": "MP7 | Anodized Navy"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/576c8e68259253d0b3482f22a2c34bbb4594b2e9ea62cadd598eff6dc54fdd40.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "XM1014 | 加州迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_italy",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 366,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "XM1014 | CaliCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/9f7921c9c059fcf3360f954898d974fb193263238eb461dbf32226cde2f6c912.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A1 消音型 | 北方森林",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 367,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "M4A1-S | Boreal Forest"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/4ca656b33e5ccd64b7ca65b49a2ca661f546859607898ed4ad48062b251253a6.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "UMP-45 | 硝烟",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 368,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "UMP-45 | Gunsmoke"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/27f85741674a7c4ad321f5fbf9749a92d0c0c62265ba1a18f7765197b87c4ca3.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 坚毅大理石纹",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 369,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "P2000 | Granite Marbleized"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/e9309145222773e9a6c4e52fcb249253befe57f7775d48277deecddb8bde2a27.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "双持贝瑞塔 | 人工染色",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 370,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "Dual Berettas | Stained"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/562443b979a50c51bbaf3f6484abb3b0f1e079cb271bc1e5beee4882755704f0.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "新星 | 红苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_italy",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 371,
        "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
        "len": 3,
        "hash_name": "Nova | Candy Apple"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3b0598e0494c36fbadcbe6b4a1580e4df2e4c2b7f3049f7214992367839c2cdf.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 地下水",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 372,
        "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
        "len": 6,
        "hash_name": "Tec-9 | Groundwater"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/06190a06a40e197c09b326d881d310444677086a73ac7d976913eb14a97fb7ed.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 雇佣兵",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 373,
        "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
        "len": 6,
        "hash_name": "AUG | Contractor"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8fc88d209536bfd6a4e21d9b9404c2aced86c53cea235d44a4aa5b76ab6531f6.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "法玛斯 | 殖民侵略者",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 374,
        "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
        "len": 6,
        "hash_name": "FAMAS | Colony"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/a3c35c0a446c4914c26354600809813dfe726866c4a8efe5875f0c9e28d13c15.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 沙丘之黄",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 375,
        "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
        "len": 6,
        "hash_name": "Nova | Sand Dune"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cfa742525fe251a84be8d3aa9f80281aa0a3e496c282e8aa37c7eb7e1fd30821.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "PP-野牛 | 沙漠虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_italy",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 376,
        "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
        "len": 6,
        "hash_name": "PP-Bizon | Sand Dashed"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7cd3ff663d32cde298321f9faa88f46693eac0f3c62455d95b4caab6d75e6bdc.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 黄铜",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 377,
        "list": "",
        "len": 0,
        "hash_name": "Tec-9 | Brass"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6d85ef208061f5c9ef5e9063c433218e40fb4f776ebea85c725371b6ae047b09.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 深蓝电镀处理",
        "min": 0,
        "max": 0.08,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 378,
        "list": "",
        "len": 0,
        "hash_name": "Dual Berettas | Anodized Navy"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/749a6d3efef71f32656942a505eea5d5ce47596f7bfcc4f0aa70aeca058aba46.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 狂哮飓风",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 379,
        "list": "\nTec-9 | 黄铜\n双持贝瑞塔 | 深蓝电镀处理",
        "len": 2,
        "hash_name": "M4A4 | Tornado"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/42c589cc962fe4e254a1fe2bf061c82984f792b4923bddac337dd87fd591c7ce.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 硝烟",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 380,
        "list": "\nTec-9 | 黄铜\n双持贝瑞塔 | 深蓝电镀处理",
        "len": 2,
        "hash_name": "P250 | Gunsmoke"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/c391ddc6a4936c988d1ba481773a0407408c80f6454138ecffa4df196c91185c.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 胡桃木",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 381,
        "list": "\nM4A4 | 狂哮飓风\nP250 | 硝烟",
        "len": 2,
        "hash_name": "Nova | Walnut"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0ffea55beaec5c1e09ecb9b86b8a2409821002faa9a3fd140a178622af07b970.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 沙丘之黄",
        "min": 0,
        "max": 1,
        "target_box": "set_inferno",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 382,
        "list": "\nM4A4 | 狂哮飓风\nP250 | 硝烟",
        "len": 2,
        "hash_name": "MAG-7 | Sand Dune"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/883faeb24aa67aa0a27d5276b8c0b56000d3dee142a129aa241a2d737d86e08b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 美杜莎",
        "min": 0,
        "max": 1,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 383,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Medusa"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5856184044643fb185af6e89723c32b0bfb2d7bc77a74f9a90f29bdafee05c70.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 波塞冬",
        "min": 0,
        "max": 0.33,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 384,
        "list": "\nAWP | 美杜莎",
        "len": 1,
        "hash_name": "M4A4 | Poseidon"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9261ae6b9963e85ff53e4ef44c516733d3830832b4d30f2a079221c5ffd683f5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "G3SG1 | 柯罗诺斯",
        "min": 0,
        "max": 0.4,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 385,
        "list": "\nM4A4 | 波塞冬",
        "len": 1,
        "hash_name": "G3SG1 | Chronos"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/98fa97be7eab4930dd3fea89c376e5ff4a1ebd1a90953a9a1c864c5c78a84daf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 伊卡洛斯殒落",
        "min": 0,
        "max": 0.1,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 386,
        "list": "\nM4A4 | 波塞冬",
        "len": 1,
        "hash_name": "M4A1-S | Icarus Fell"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e3afd9fdabf53a0823792aac05860c73d407cdad90eca54dfe9a4efc070c43a2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 弥诺陶洛斯迷宫",
        "min": 0,
        "max": 0.39,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 387,
        "list": "\nG3SG1 | 柯罗诺斯\nM4A1 消音型 | 伊卡洛斯殒落",
        "len": 2,
        "hash_name": "UMP-45 | Minotaur's Labyrinth"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d92d7684b859a074d90ff6e7ca1b281f556c75f594068627a3b9b76285c7ce47.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 潘多拉魔盒",
        "min": 0,
        "max": 0.3,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 388,
        "list": "\nG3SG1 | 柯罗诺斯\nM4A1 消音型 | 伊卡洛斯殒落",
        "len": 2,
        "hash_name": "MP9 | Pandora's Box"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/44afbdd4b41ccd4db19c072d2aa4df3331fbb1561c282271d6a4f2e835994adf.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "Tec-9 | 哈迪斯",
        "min": 0,
        "max": 1,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 389,
        "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
        "len": 2,
        "hash_name": "Tec-9 | Hades"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8cd601032ba968c3a15e35815cfa712e38078bd3be4a37cdb56e19ff49ff7d38.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 寻路者",
        "min": 0,
        "max": 0.35,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 390,
        "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
        "len": 2,
        "hash_name": "P2000 | Pathfinder"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/9f4f5eb55f9b930f1134ea3df851ce918dab7f2830a76c4f1fa8432a0fa99721.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AWP | 狮子之日",
        "min": 0,
        "max": 0.5,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 391,
        "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
        "len": 2,
        "hash_name": "AWP | Sun in Leo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/1088349f135ed612234b91425beb129f3318e6734bfa3c9cff797fd7497f487d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M249 | 海滨预测者",
        "min": 0,
        "max": 0.5,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 392,
        "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
        "len": 2,
        "hash_name": "M249 | Shipping Forecast"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/48c9212b96fed3999b4060c34776e626168f2d9ec4b13a5bfc3b9bfccf359013.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 星点",
        "min": 0,
        "max": 0.55,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 393,
        "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
        "len": 4,
        "hash_name": "MP7 | Asterion"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cbcd3f52d6fbaefe79ab265dfef6ba880030f101f7835fcd93f290282e1e0293.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "AUG | 代达罗斯之殇",
        "min": 0,
        "max": 0.55,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 394,
        "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
        "len": 4,
        "hash_name": "AUG | Daedalus"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/542ab2c2258a62385f3d917d36a9d8fead404ff4f6c38594f7d36ce5f7550144.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 天秤之月",
        "min": 0,
        "max": 0.5,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 395,
        "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
        "len": 4,
        "hash_name": "Dual Berettas | Moon in Libra"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3356826d02f75ebf1feb206c82ff8e4f4a35086c47624f485323037d79ffe43f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 天秤之月",
        "min": 0,
        "max": 0.5,
        "target_box": "set_gods_and_monsters",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 396,
        "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
        "len": 4,
        "hash_name": "Nova | Moon in Libra"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d41cb0c79c72bfdb95fedf63917e27cb7e574065d2dc26d1035557d3177c90d2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "R8 左轮手枪 | 渐变琥珀",
        "min": 0,
        "max": 0.4,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 397,
        "list": "",
        "len": 0,
        "hash_name": "R8 Revolver | Amber Fade"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ec36fd4bd595514151ca2d19cb93e30b6fee945c32bfbd860de6798b7db802e5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 渐变琥珀",
        "min": 0,
        "max": 0.4,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 398,
        "list": "\nR8 左轮手枪 | 渐变琥珀",
        "len": 1,
        "hash_name": "P2000 | Amber Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/46a7bda842ee3024ca1f42330e6ac2187d6ece28a83446a23f7d208f145cbcee.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 大马士革钢",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 399,
        "list": "\nP2000 | 渐变琥珀",
        "len": 1,
        "hash_name": "SG 553 | Damascus Steel"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2b97a573bb6fc677b19605f99dd9a379de8eafdc196fb1dedafe7aa025638ca9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 黄铜",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 400,
        "list": "\nP2000 | 渐变琥珀",
        "len": 1,
        "hash_name": "PP-Bizon | Brass"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3977683992a8818e364c1917b08373160af86f99007f9009b17646b48e827029.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A1 消音型 | 多变迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 401,
        "list": "\nP2000 | 渐变琥珀",
        "len": 1,
        "hash_name": "M4A1-S | VariCamo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/38256691445cf4bad431b26121f8dea4bb743ebe46f71c660163496b3ddd5d9e.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "截短霰弹枪 | 蝮蛇迷彩",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 402,
        "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
        "len": 3,
        "hash_name": "Sawed-Off | Snake Camo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d200a52c516643d791cd8fc5acea6e6834462da6721e4a8e02c0c0a249348376.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AK-47 | 狩猎网格",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 403,
        "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
        "len": 3,
        "hash_name": "AK-47 | Safari Mesh"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b17602b469489fffd493018429c148e80dda010cacc6443325e7a013b83d1e37.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "FN57 | 橘皮涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 404,
        "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
        "len": 3,
        "hash_name": "Five-SeveN | Orange Peel"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/f38b13b5f04ff6fcd22e1d0981398150f347cce61684d63800f3943ca68e845f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAC-10 | 棕榈色",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 405,
        "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
        "len": 3,
        "hash_name": "MAC-10 | Palm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/9f896f27167c2f465b4cfbac900507d42b537969081e35df16bbda7a7576b57e.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "Tec-9 | 多变迷彩",
        "min": 0,
        "max": 0.6,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 406,
        "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
        "len": 3,
        "hash_name": "Tec-9 | VariCamo"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/9c66e312182905e291b85d433bb19ea6a86af081c16d935bac21655468c79bff.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 沙漠风暴",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 407,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "G3SG1 | Desert Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/ead7a2a1a9afbf425693f118774fad1f74c1ecb99f457febc1bc6b22d6fc5305.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P250 | 沙丘之黄",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 408,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "P250 | Sand Dune"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/62608b10a2b626839e2d8b8a054cd429095942db63fc9a695b84cdb7a3505704.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SCAR-20 | 沙漠网格",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 409,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "SCAR-20 | Sand Mesh"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/5b5496dd4ee18b1a8db5f3ba11f64682baca8db3cac61cce4d21dd692e3c5cab.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P90 | 沙漠涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 410,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "P90 | Sand Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0e0f40db3ad1545db10dcc4ed6fec0b4c7721cb4598117edc97de36c680fdf7b.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 沙漠虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 411,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "MP9 | Sand Dashed"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/1c785fe747d9f09cfcb8f6a3745b481377c2b24846262957be94af844f0f0f06.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 捕食者",
        "min": 0,
        "max": 1,
        "target_box": "set_dust_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 412,
        "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
        "len": 5,
        "hash_name": "Nova | Predator"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d789553b1fc771a5403356cc2d8c6d7f0ac1f2736db08593d85c6aa4859db0ac.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 黄铜",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 413,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Brass"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/13bb0f6919894fe187f1da4546f5643c96ddbff15a2d08d7d38f4395f5f6e8f5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 致命红蝎",
        "min": 0,
        "max": 0.08,
        "target_box": "set_dust",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 414,
        "list": "",
        "len": 0,
        "hash_name": "P2000 | Scorpion"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/dc64fd6ebf7e2f7e91baeb44bf5748d15c1758f7974ad55aa112aff2e7d31c63.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 炽烈之炎",
        "min": 0,
        "max": 0.08,
        "target_box": "set_dust",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 415,
        "list": "",
        "len": 0,
        "hash_name": "Desert Eagle | Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/efe3f1c385d9ee89469d1cc12613117b9665ee3db458a1277f6d5c597e09e7dc.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 镀铜",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 416,
        "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
        "len": 3,
        "hash_name": "Sawed-Off | Copper"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cc75fbe77ab268872492a639de2758525740590a9bd7181ce87365d005b7291d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 铜斑蛇",
        "min": 0.12,
        "max": 0.38,
        "target_box": "set_dust",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 417,
        "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
        "len": 3,
        "hash_name": "AUG | Copperhead"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/282d4e1c3ee8249b69a61e49bcd37b3d49742d6f7edb456d31a222326abfdcef.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AWP | 蝮蛇迷彩",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 418,
        "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
        "len": 3,
        "hash_name": "AWP | Snake Camo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c5690bab0ad89f7f26caf41214e3088a08b58da29c9928e88f9fb7d8476752ce.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AK-47 | 捕食者",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 419,
        "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
        "len": 3,
        "hash_name": "AK-47 | Predator"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d47798aa8a056441f5f8cb2bdf0616ae2851de094133a92de818c0eb8b906518.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SCAR-20 | 棕榈色",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 420,
        "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
        "len": 3,
        "hash_name": "SCAR-20 | Palm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/6dd4cdf5e4c25b559b40498e0e3bb62dd58195024d57ef95d4e3cbd30fca65b2.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 沙漠风暴",
        "min": 0,
        "max": 1,
        "target_box": "set_dust",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 421,
        "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
        "len": 3,
        "hash_name": "M4A4 | Desert Storm"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/3be5ab2c33c6723569f7a2189a237ae9c3e03966854106b213e844fc92a0d32b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 巨龙传说",
        "min": 0,
        "max": 0.7,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 422,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Dragon Lore"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/029cc835ea1527ce39c456ff42113f6778e4d55d17fac7e63c832fd6f032588c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 骑士",
        "min": 0,
        "max": 0.1,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 423,
        "list": "\nAWP | 巨龙传说",
        "len": 1,
        "hash_name": "M4A1-S | Knight"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6f611bb85d3aa13ca6f5e2f56d156da96aa2f1e3c2cb8dff92b8495330b3516b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 手上加农炮",
        "min": 0.01,
        "max": 0.7,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 424,
        "list": "\nM4A1 消音型 | 骑士",
        "len": 1,
        "hash_name": "Desert Eagle | Hand Cannon"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5109ecef0e4c7d5368d5e0b2f06e88305b48a3671c6bd5faa508015dd5402003.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 复古圣杯",
        "min": 0,
        "max": 0.1,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 425,
        "list": "\nM4A1 消音型 | 骑士",
        "len": 1,
        "hash_name": "CZ75-Auto | Chalice"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1c0f459050b66fdf6e2d61660a441c05565ae4ecd0204f96d710ac2dbe64d2c0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 黑暗时代",
        "min": 0,
        "max": 0.22,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 426,
        "list": "\n沙漠之鹰 | 手上加农炮\nCZ75 | 复古圣杯",
        "len": 2,
        "hash_name": "MP9 | Dark Age"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/73ca43dd5b0eb0c43969121613aa0db14afe7e0f865a8d1b3b9b1674d774b92b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 坚固链甲",
        "min": 0,
        "max": 0.22,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 427,
        "list": "\n沙漠之鹰 | 手上加农炮\nCZ75 | 复古圣杯",
        "len": 2,
        "hash_name": "P2000 | Chainmail"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d6234f255e8d6f74e3ccaa9740cf360c416852771ff04dad670e1f3c79e311cb.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "USP 消音版 | 宝蓝之色",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 428,
        "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
        "len": 2,
        "hash_name": "USP-S | Royal Blue"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d19d2b05cfabe62c45e6071ec9612bd1e467c8bdff5026407032b055863727be.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "新星 | 绿苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 429,
        "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
        "len": 2,
        "hash_name": "Nova | Green Apple"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/666782d8712ff0eb2081f10da6685d33b6ab6d9e48082bdb28f3cbedebd380a3.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAG-7 | 银质",
        "min": 0,
        "max": 0.08,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 430,
        "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
        "len": 2,
        "hash_name": "MAG-7 | Silver"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/e55ea3fb2f416be58e00161185cc4fa82cb5100cedc4c4e8048a1779313f4724.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "截短霰弹枪 | 外表生锈",
        "min": 0,
        "max": 1,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 431,
        "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
        "len": 2,
        "hash_name": "Sawed-Off | Rust Coat"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/f68a8b2e4a49afccc709bb8515e01f16df92279c51d8a51c08791968dfd9637f.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "P90 | 暴风呼啸",
        "min": 0,
        "max": 1,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 432,
        "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
        "len": 4,
        "hash_name": "P90 | Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/b5f0b2cf88d03e57c1fc244f3ba9795153c800505f4f63dc19070d9e978e7e85.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 紫青之色",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 433,
        "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
        "len": 4,
        "hash_name": "UMP-45 | Indigo"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0cf8fceae5113b52e024c08a79d69ed57ac760d5a3465204d820ffc415c4dad7.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAC-10 | 紫青之色",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 434,
        "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
        "len": 4,
        "hash_name": "MAC-10 | Indigo"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3d63c133957c82b18383098bb345f2e0bbdce59735df1db8286d34cdbe3f889e.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SCAR-20 | 暴风呼啸",
        "min": 0,
        "max": 1,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 435,
        "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
        "len": 4,
        "hash_name": "SCAR-20 | Storm"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/32674e1e10cd782e5f4e6132107c6bb56874e152a6637a7659d4e29fc0d71453.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "双持贝瑞塔 | 蔷薇",
        "min": 0,
        "max": 0.22,
        "target_box": "set_cobblestone",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 436,
        "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
        "len": 4,
        "hash_name": "Dual Berettas | Briar"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/8012651188458e9f8ce64918b1c7d27b12079e5c2fc576a3c64f353f6f8b02cc.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "格洛克 18 型 | 暮光星系",
        "min": 0,
        "max": 0.2,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 437,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Twilight Galaxy"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/71d186b52fe66a32df75e9c37268545517e45b80575f0d70fb2b9599b5a88502.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 赤红新星",
        "min": 0,
        "max": 0.08,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 438,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Hot Rod"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/eb6c0856b7bf7baaf890b129f8c0363098389d55b5cf16a5ccc61101e583da8b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 威吓者",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 439,
        "list": "\n格洛克 18 型 | 暮光星系\nM4A1 消音型 | 赤红新星",
        "len": 2,
        "hash_name": "SG 553 | Bulldozer"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0c4fbb739a6050084b39abeec8dcd4969b5ca4fa5ff3f9c2c4fb9f77d084a37a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 决斗家",
        "min": 0,
        "max": 1,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 440,
        "list": "\n格洛克 18 型 | 暮光星系\nM4A1 消音型 | 赤红新星",
        "len": 2,
        "hash_name": "Dual Berettas | Duelist"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/08141d3ae5f77d3512e5d72ff001351eb2e202f696355d58bd9ca56aa0d28471.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 渐变之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 441,
        "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
        "len": 2,
        "hash_name": "MAC-10 | Fade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8d9843578f881c288733a30361ae152d7603e8c73dc510f81e58bc88d07e02f8.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 银装素裹",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 442,
        "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
        "len": 2,
        "hash_name": "P250 | Whiteout"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6b8f8883f44f3c2952c439016e797f834f465e0595458d85261b18d54ef72813.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 逮捕者",
        "min": 0,
        "max": 0.6,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 443,
        "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
        "len": 2,
        "hash_name": "MP7 | Full Stop"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/28356b65a48d135eee98f04b9980a9133bd9186cdfb65c40f96ec328c362cc86.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 氮化处理",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 444,
        "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
        "len": 2,
        "hash_name": "Five-SeveN | Nitro"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a1e060ed0d1e011e83306a7d5fbd304ecdc083536afdb37f4ca01c93548b994f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 翡翠色调",
        "min": 0,
        "max": 0.08,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 445,
        "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
        "len": 2,
        "hash_name": "CZ75-Auto | Emerald"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/be5737c28dbe4c87e93b9849b0a098181ac4d6315b78b58d531313968eddbd81.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "沙漠之鹰 | 噩梦之夜",
        "min": 0,
        "max": 1,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 446,
        "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
        "len": 5,
        "hash_name": "Desert Eagle | Night"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c6e4c653edcf2bc274866805ff706dc53cd16e9b0c25151e6f9e3959e22d5693.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "加利尔 AR | 都市瓦砾",
        "min": 0,
        "max": 0.5,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 447,
        "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
        "len": 5,
        "hash_name": "Galil AR | Urban Rubble"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/7381864a1b212b142e959add057f9047c6ad268e1f8519f411c1b4f36352d4c2.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "USP 消音版 | 绿色伞兵",
        "min": 0,
        "max": 0.8,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 448,
        "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
        "len": 5,
        "hash_name": "USP-S | Para Green"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cef6f3fe99c76358212e058863c565264e13b9e472238437d49c9b9b6e2e95a0.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SCAR-20 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 449,
        "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
        "len": 3,
        "hash_name": "SCAR-20 | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/09d8aed9bc9e036c92fac414c3c3dfa7c3731ee552bafbdbb437204889cdcdf0.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "CZ75 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 450,
        "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
        "len": 3,
        "hash_name": "CZ75-Auto | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0a0185aee3dd57d784740fec831d0c280845405d418e793d89e804bd8d61e51d.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "M249 | 冲击钻",
        "min": 0,
        "max": 1,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 451,
        "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
        "len": 3,
        "hash_name": "M249 | Impact Drill"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/090fcffc69999ea5dc6ff325b3bfd8ff6f1b245e49313db77f5aee220d613e64.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MAG-7 | 海鸟",
        "min": 0,
        "max": 1,
        "target_box": "set_chopshop",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 452,
        "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
        "len": 3,
        "hash_name": "MAG-7 | Seabird"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/181a69ba092d0dad2d9bfd96abf4bc1ae126f41e1aeda49b748122dec20c1384.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 地狱看门犬",
        "min": 0,
        "max": 0.9,
        "target_box": "set_cache",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 453,
        "list": "",
        "len": 0,
        "hash_name": "Galil AR | Cerberus"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/252f50314e36b412ced6027692e08c583254558ab8ab7938e041264268b2f0e7.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 冥界之憎",
        "min": 0,
        "max": 0.6,
        "target_box": "set_cache",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 454,
        "list": "",
        "len": 0,
        "hash_name": "FAMAS | Styx"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/70e6bc583260ec27166794e54839cc08ea4667affd32b454a819516efcd5690a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 核子剧毒",
        "min": 0,
        "max": 0.7,
        "target_box": "set_cache",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 455,
        "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
        "len": 2,
        "hash_name": "Tec-9 | Toxic"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/838260b9451634c9c1d08ae8707149258b6a0dd0f9dbd5dc0b3ca05aa9d5a3a9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 核子反应",
        "min": 0,
        "max": 1,
        "target_box": "set_cache",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 456,
        "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
        "len": 2,
        "hash_name": "Glock-18 | Reactor"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a95c915db78381b8d6704511e88a0c485c1854ae439d67cd60e3701e9a9ed31e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 碾骨机",
        "min": 0,
        "max": 0.6,
        "target_box": "set_cache",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 457,
        "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
        "len": 2,
        "hash_name": "XM1014 | Bone Machine"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fc659a9c7f355ee7aec297913c8dcd3ee3180f48bf7d5df5793e96fd78aecb1a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 核子花园",
        "min": 0,
        "max": 0.7,
        "target_box": "set_cache",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 458,
        "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
        "len": 2,
        "hash_name": "MAC-10 | Nuclear Garden"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/466ac1f3ba16dc9198da77843b90c84d6f74aaacae1e9f82845816d67bc1b4df.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 落日",
        "min": 0,
        "max": 1,
        "target_box": "set_cache",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 459,
        "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
        "len": 2,
        "hash_name": "MP9 | Setting Sun"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/685ac9d1d28c1220084fb1a26a85ee41d40e90030ee71de7fc0516815bf975f2.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AUG | 辐射危机",
        "min": 0,
        "max": 0.55,
        "target_box": "set_cache",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 460,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "AUG | Radiation Hazard"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/62a4e82dd96e86dffb016afd3088ef2b866fc1dd92995d39c69fdb9580f9718b.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "PP-野牛 | 化工之绿",
        "min": 0,
        "max": 0.9,
        "target_box": "set_cache",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 461,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "PP-Bizon | Chemical Green"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/ff49a25598602a9f3740f41b34a487cfa825e1a59de589307bb21a7ff80acdf1.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "内格夫 | 核子废渣",
        "min": 0,
        "max": 0.4,
        "target_box": "set_cache",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 462,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "Negev | Nuclear Waste"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/10411e22f3dfca52d1704bf8b99d4512947f473a3688f89d8ae5e80edda37ab5.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 核子污染",
        "min": 0,
        "max": 0.83,
        "target_box": "set_cache",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 463,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "P250 | Contamination"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c2851a9f09d8fc1e5d1db9af2815f8acab7f35f04ae10a0bfec633160651775d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "FN57 | 热火朝天",
        "min": 0,
        "max": 1,
        "target_box": "set_cache",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 464,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "Five-SeveN | Hot Shot"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b9336f9c6e705d4990b6d29bd97a5a5a990581c99d0e518cb75336f79883da04.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SG 553 | 辐射警告",
        "min": 0,
        "max": 0.8,
        "target_box": "set_cache",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 465,
        "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
        "len": 5,
        "hash_name": "SG 553 | Fallout Warning"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1b33146759766d7758b2959e4450175fd2176dcc4f89d8323079b1ec4411c246.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 富兰克林",
        "min": 0,
        "max": 0.4,
        "target_box": "set_bank",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 466,
        "list": "",
        "len": 0,
        "hash_name": "P250 | Franklin"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4befdd8a307e530305863a8b74e8cf408af21c5c93fecf4b65ddc97aecbff36e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 翡翠细条纹",
        "min": 0,
        "max": 1,
        "target_box": "set_bank",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 467,
        "list": "\nP250 | 富兰克林",
        "len": 1,
        "hash_name": "AK-47 | Emerald Pinstripe"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/782a1ebb8e20f024a7afcbaef9ee7b55b54fac32e483da41c53dcf37aba1f5c0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 燕尾",
        "min": 0,
        "max": 0.8,
        "target_box": "set_bank",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 468,
        "list": "\nAK-47 | 翡翠细条纹",
        "len": 1,
        "hash_name": "CZ75-Auto | Tuxedo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4b732a194b9f3d54f998132ce7bcc006e43e6776b2a554bd0e3700e182db319a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 陨星",
        "min": 0,
        "max": 0.18,
        "target_box": "set_bank",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 469,
        "list": "\nAK-47 | 翡翠细条纹",
        "len": 1,
        "hash_name": "Desert Eagle | Meteorite"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/605fedcb8357bd678ea0a0e3a20d24bb756126f2f69cb6ada5abc8a326b8de34.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 燕尾",
        "min": 0,
        "max": 0.8,
        "target_box": "set_bank",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 470,
        "list": "\nAK-47 | 翡翠细条纹",
        "len": 1,
        "hash_name": "Galil AR | Tuxedo"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/134843cec1855323f14b27be831c96a104dcc01fefe4c2f5195d2f0b797be0bb.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "G3SG1 | 绿苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_bank",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 471,
        "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
        "len": 2,
        "hash_name": "G3SG1 | Green Apple"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/9dd7ba08031ef08290179562bd10e8ae10f39472616de53ad2080f9f7cb78cd8.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 夺命撼响",
        "min": 0.08,
        "max": 0.5,
        "target_box": "set_bank",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 472,
        "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
        "len": 2,
        "hash_name": "Glock-18 | Death Rattle"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b738583f189b0ab5f7b9a06ef5d7028f8ae0831ed8af0d1cf1b63352a8f78258.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAC-10 | 银质",
        "min": 0,
        "max": 0.08,
        "target_box": "set_bank",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 473,
        "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
        "len": 2,
        "hash_name": "MAC-10 | Silver"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/802f162f232c88d4de8448239b139a6f4b984b4cda8b14a84b439fa871566999.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "新星 | 钢笼",
        "min": 0,
        "max": 0.2,
        "target_box": "set_bank",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 474,
        "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
        "len": 2,
        "hash_name": "Nova | Caged Steel"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/3e91e9afd7d8e41d26ea6c7e0239b43f1eadcb083eeeaee8d4d4e296e065dd8d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "UMP-45 | 碳素纤维",
        "min": 0,
        "max": 0.12,
        "target_box": "set_bank",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 475,
        "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
        "len": 2,
        "hash_name": "UMP-45 | Carbon Fiber"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3dec85c328ba8602cbfae899ceb8f61e56d68748bfd148f7915528350c07f83b.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "R8 左轮手枪 | 骸骨外罩",
        "min": 0,
        "max": 1,
        "target_box": "set_bank",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 476,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "R8 Revolver | Bone Mask"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8000c1d3bb6eebe8617e747047a466b9142fca983be13a5278fb040d2b72af88.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 森林 DDPAT",
        "target_box": "set_bank",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 477,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "MP7 | Forest DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0474dfe82f9f9914c71bf6376dbe40c98970a824caf59cd3b1ad526356f14968.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "内格夫 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_bank",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 478,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "Negev | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/8a4be69d67dd0b374c40109695f1ecafea1263be2552e2cb6d6f4e6083515302.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "截短霰弹枪 | 森林 DDPAT",
        "target_box": "set_bank",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 479,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "Sawed-Off | Forest DDPAT"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/0aecbbdc220ac2d13f116e8ff7345a5c81d0c87de4c14ff5a71cc80dda4a28d8.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 军队之辉",
        "min": 0,
        "max": 0.3,
        "target_box": "set_bank",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 480,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "SG 553 | Army Sheen"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/bd2da27c3dc31bb9185f82c7e24f8b4e474948daa80868af6568815e789fd239.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 都市 DDPAT",
        "target_box": "set_bank",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 481,
        "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
        "len": 5,
        "hash_name": "Tec-9 | Urban DDPAT"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d5b44700a5d7d651eaca3191f0f8e899b54b83c8d8f70571629e4f7b52068037.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 酷炫涂鸦皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 482,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Jet Set"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1e4c9bd13a3dc40e221c6d9c066d2c25b3afc9f00f88a023814cc36ed4addb44.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 飞行员",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 483,
        "list": "\nAK-47 | 酷炫涂鸦皮革",
        "len": 1,
        "hash_name": "Desert Eagle | Pilot"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/013437ca988f56d25f30a82d638209a3138ed895a7739fe6af2f23051ca2ce81.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 至高皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 484,
        "list": "\nAK-47 | 酷炫涂鸦皮革",
        "len": 1,
        "hash_name": "AK-47 | First Class"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/512d4f1f6ce6f92ecca9a7cce254642296a73fea9179b49de4fa2b0662938ec5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 至高皮革",
        "min": 0,
        "max": 0.5,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 485,
        "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
        "len": 2,
        "hash_name": "Sawed-Off | First Class"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a645b633386c595536954e2c67f6d6da658064436910abc756cdf9fd93312f18.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 商业皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 486,
        "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
        "len": 2,
        "hash_name": "USP-S | Business Class"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/87168e1d52633f686a54afe2c22a543d92e83cf33f6d2c9e924ad886e1629ba2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 红色皮革",
        "min": 0,
        "max": 0.56,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 487,
        "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
        "len": 2,
        "hash_name": "XM1014 | Red Leather"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/5eeed4c14cb435bd771a46bd65916dc323e18ab3729721e4cb025742f8462a60.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P90 | 棕色皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 488,
        "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
        "len": 3,
        "hash_name": "P90 | Leather"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/4137926fabbc2b27242221d64fa8c456c31098c633e73f06490ed7a484a3e483.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "MAC-10 | 通勤者皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 489,
        "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
        "len": 3,
        "hash_name": "MAC-10 | Commuter"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/8b44354b6cf63c6178bfca6478404df168ca78bf1dcaa480b559ba4c1b1449c2.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P2000 | 廉价皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 490,
        "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
        "len": 3,
        "hash_name": "P2000 | Coach Class"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/731777dc8e96bd8aa94456cff9b3ff3658791fe5960c593c1e5835fd10747d99.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SG 553 | 旅行者皮革",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 491,
        "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
        "len": 3,
        "hash_name": "SG 553 | Traveler"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/42890db7a1cd87cdbcaf818311e2fb23d8f54b2cbfd8e932ba5d3e15e4ff138d.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "G3SG1 | 雇佣兵",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 492,
        "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
        "len": 4,
        "hash_name": "G3SG1 | Contractor"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/589e5fa10acccf3163c8bdd9a93564dc013cf48d129b14e5fa7a932c2ee573fd.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 橄榄格纹",
        "min": 0,
        "max": 0.58,
        "target_box": "set_baggage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 493,
        "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
        "len": 4,
        "hash_name": "MP7 | Olive Plaid"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/34f7177a1ae26cccd9f4eeb5102de74f95fcd397b0e499fb759e31d9aa60895e.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "CZ75 | 绿色格纹",
        "min": 0,
        "max": 0.58,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 494,
        "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
        "len": 4,
        "hash_name": "CZ75-Auto | Green Plaid"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/a247dc613d66074ee6fb494b20000c9a444305f428d6ea99d7a6cca296fb8e0e.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 绿色格纹",
        "min": 0,
        "max": 0.58,
        "target_box": "set_baggage",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 495,
        "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
        "len": 4,
        "hash_name": "MP9 | Green Plaid"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/cfb7450dfaca98b7619adfb0565332118a31fbb0512e334e8c199bfa4a20a632.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 沙丘之黄",
        "min": 0,
        "max": 1,
        "target_box": "set_baggage",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 496,
        "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
        "len": 4,
        "hash_name": "SSG 08 | Sand Dune"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/56a10e9ba6d64fd4c22a11cc17d00eec61b451ec1448ebeecf4ef121b8b2e541.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 骨化之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 497,
        "list": "",
        "len": 0,
        "hash_name": "Tec-9 | Ossified"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c15cc7e9dde976b839ba9947ea7afaa3129e0ee58ee3c2d984384367b0d8d65e.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "M4A4 | 丛林虎",
        "min": 0,
        "max": 1,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 498,
        "list": "\nTec-9 | 骨化之色",
        "len": 1,
        "hash_name": "M4A4 | Jungle Tiger"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/4fb95a363279db99f9c45cd6661fc8fb7cfd05871ee4c860b951e9ad46727b9f.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "AK-47 | 丛林涂装",
        "min": 0,
        "max": 1,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 499,
        "list": "\nTec-9 | 骨化之色",
        "len": 1,
        "hash_name": "AK-47 | Jungle Spray"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d6f83c3e3ea13f5a56ffce112b2e44f12b252e4f1550dabe6eaa2d4d26d82bc2.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SSG 08 | 青苔虚线",
        "min": 0,
        "max": 1,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 500,
        "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
        "len": 2,
        "hash_name": "SSG 08 | Lichen Dashed"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/1e39542042ffabffcddd096d00f362f8432fbc4bf8b9a763378949d93b22c073.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "FN57 | 狂野丛林",
        "min": 0,
        "max": 1,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 501,
        "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
        "len": 2,
        "hash_name": "Five-SeveN | Jungle"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/ad4c7803f2db28c85f9c2af0ed7fdf0dc22b887fd2d1c8345f63b1576e202654.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "新星 | 森林之叶",
        "min": 0,
        "max": 1,
        "target_box": "set_aztec",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 502,
        "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
        "len": 2,
        "hash_name": "Nova | Forest Leaves"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5eac6f9e347a8ae35a94c8d75d643c72381f139c801307344fe1a5d0cf3310ef.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 渐变之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_assault",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 503,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Fade"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/93e8b4a060713bea08f3642cbf7e2526846115c9ebbbaeda1e46f6faa42b323c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 威吓者",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_assault",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 504,
        "list": "",
        "len": 0,
        "hash_name": "MP9 | Bulldozer"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4c0866443cf4397e0956bea0a67377a09743a2571aebb1a8c607033873fed3a6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 赤红新星",
        "min": 0,
        "max": 0.08,
        "target_box": "set_assault",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 505,
        "list": "\n格洛克 18 型 | 渐变之色\nMP9 | 威吓者",
        "len": 2,
        "hash_name": "AUG | Hot Rod"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3d1e9ff7012cc9b8430e2b4dfa9effcd328f04ab7baaffc94ac2c1fa25c35581.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 深蓝电镀处理",
        "min": 0,
        "max": 0.08,
        "target_box": "set_assault",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 506,
        "list": "\n格洛克 18 型 | 渐变之色\nMP9 | 威吓者",
        "len": 2,
        "hash_name": "Negev | Anodized Navy"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/2ac0d97ae67dd3d0f9a77c21a999a749974e9d26b9a1e6330bceec0149c590f1.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "FN57 | 红苹果",
        "min": 0,
        "max": 0.3,
        "target_box": "set_assault",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 507,
        "list": "\nAUG | 赤红新星\n内格夫 | 深蓝电镀处理",
        "len": 2,
        "hash_name": "Five-SeveN | Candy Apple"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/3d192ab13a175219ceb911c49205841b28322faf9c387253cfa54bab15f795e8.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "UMP-45 | 色如焦糖",
        "min": 0,
        "max": 1,
        "target_box": "set_assault",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 508,
        "list": "\nFN57 | 红苹果",
        "len": 1,
        "hash_name": "UMP-45 | Caramel"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/964882a82ef027d1ba38aafa310168ffd919e2c9cdbc37c5e68e4e10822c58bb.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "SG 553 | 狂哮飓风",
        "min": 0,
        "max": 1,
        "target_box": "set_assault",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 509,
        "list": "\nFN57 | 红苹果",
        "len": 1,
        "hash_name": "SG 553 | Tornado"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4a417f912d57cf0df5bd821e8027c2a975968a3ea7d5add6dd78737d77607299.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SCAR-20 | 翡翠色调",
        "min": 0,
        "max": 0.08,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 510,
        "list": "",
        "len": 0,
        "hash_name": "SCAR-20 | Emerald"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7db5d68525ea7b61d84277693d9cfb1e2141cb51b9b7f83ec2f947d2f7e0bed6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 喷焰者",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 511,
        "list": "",
        "len": 0,
        "hash_name": "FAMAS | Spitfire"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b2390c3ee3c6e1d3c7a466329ca87ff70731f9ba2395a3faae974b29d95db9e3.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 深蓝电镀处理",
        "min": 0,
        "max": 0.08,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 512,
        "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
        "len": 2,
        "hash_name": "AUG | Anodized Navy"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/464e3ee812742e7d6ea8b6351ac68ec08a283b8b4f7e44a882e2c249838ac94e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 外表生锈",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 513,
        "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
        "len": 2,
        "hash_name": "PP-Bizon | Rust Coat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/152dceb51dcd8bbc1cd944aeb8694fe7bc4318d9490b6236b14b6197f8bbcb50.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 危机色调",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 514,
        "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
        "len": 2,
        "hash_name": "MAG-7 | Hazard"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/b2fa25b38a9aab267b534aa450780b017fe00acbc126ea6b7999f20769b85708.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "P250 | 多面体",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 515,
        "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
        "len": 3,
        "hash_name": "P250 | Facets"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/c7fa504d2b0b8d82e20bfd21451dea140b13a0e29247d6e8f10b5711ea4618e8.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "截短霰弹枪 | 马赛克",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 516,
        "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
        "len": 3,
        "hash_name": "Sawed-Off | Mosaico"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/386ab607ecf945ba16cc91ec1bf19b6ebecd4e0aad0cc4e38c54334a1d3c8c18.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "内格夫 | 棕榈色",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 517,
        "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
        "len": 3,
        "hash_name": "Negev | Palm"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/1f6e7601f48c2ffe4578bfb90b85efb098f02417ab1f217d2134ceadce6fdb8d.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "SSG 08 | 玛雅之梦",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 518,
        "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
        "len": 3,
        "hash_name": "SSG 08 | Mayan Dreams"
    },
    {
        "rarity": "工业级",
        "cover": "https://static.mobi2077.com/market-csgo/d77d086f260ccd06476ac9cac7c9deeb70db60c7ce8c9fbd297c14c4ac027100.png",
        "en_rarity": "Rarity_Uncommon_Weapon",
        "name": "格洛克 18 型 | 沙丘之黄",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 519,
        "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
        "len": 3,
        "hash_name": "Glock-18 | Sand Dune"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/ee3ccc07a2304526acec043670fd97fc2490248ae7092638643e9b87a226b693.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP7 | 地下水",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 520,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "MP7 | Groundwater"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/d9c6e0083e0eefc9ea2d2bbc9bc670ab5e8557503c51c459d207c3928c1f44bc.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "XM1014 | 狂野丛林",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 521,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "XM1014 | Jungle"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/da267dc0e690003fa3897f3996c4c906f58a29a0f80c6dd6283c7156c9542b65.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "FN57 | 电镀青铜",
        "min": 0,
        "max": 0.08,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 522,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "Five-SeveN | Anodized Gunmetal"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/c44e8e3758a0efaf6e85578d06c9d907b60f75ea438cf3574976160ad0836618.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "MP9 | 干旱季节",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 523,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "MP9 | Dry Season"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/9752c1abbbcccf46e0195f99e10da4f38132043e81580b3b6d26c45a1dadb626.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "Tec-9 | 狂哮飓风",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 524,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "Tec-9 | Tornado"
    },
    {
        "rarity": "消费级",
        "cover": "https://static.mobi2077.com/market-csgo/45ac628092956304c8aa5e7af206af048efc906fe513536257e42a535a01668b.png",
        "en_rarity": "Rarity_Common_Weapon",
        "name": "M249 | 丛林 DDPAT",
        "target_box": "set_bravo_ii",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 525,
        "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
        "len": 5,
        "hash_name": "M249 | Jungle DDPAT"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/f6fada9e8deca5038ce38dccc223527c14963a21191a7a88d635c67d113c1b39.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 活色生香",
        "min": 0,
        "max": 0.79,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 526,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | In Living Color"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/3881f0d55b6f00b1de7e0716c5d5fd8d30f035d88801ec2a85a599881449b193.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "USP 消音版 | 倒吊人",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 527,
        "list": "",
        "len": 0,
        "hash_name": "USP-S | The Traitor"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/9e7e4b28728dde47dadf5e226ecff3286678df39ee4c441d0fbf8c982327d961.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "加利尔 AR | 迷人眼",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 528,
        "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
        "len": 2,
        "hash_name": "Galil AR | Chromatic Aberration"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/c3b304ca37058fb9a117e31e4d3daf0b1d9e711a3ec209ad9436d1a11e4ab943.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "XM1014 | 要抱抱",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 529,
        "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
        "len": 2,
        "hash_name": "XM1014 | XOXO"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a7def8782c0a9e71728b6c6172f5e0c9bde4fa78aad81ac93e3adc48021e842b.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP9 | 爆裂食物链",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 530,
        "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
        "len": 2,
        "hash_name": "MP9 | Food Chain"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/97c45cadb0a0ea93d8bb60cf5149cb66513cba21ba7f7b247d219465ee3c1d9b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 墨岩",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 531,
        "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
        "len": 3,
        "hash_name": "AK-47 | Slate"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c8a36239b8c157110281b0106ff36f9aa159ef349ab8e4687de60a45a9563988.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 后发制人",
        "min": 0,
        "max": 0.97,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 532,
        "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
        "len": 3,
        "hash_name": "Desert Eagle | Trigger Discipline"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e94969e13c5b1ecd9f78204bd1cad50100f30ba819b6d46299d528822a815211.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 战争手柄",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 533,
        "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
        "len": 3,
        "hash_name": "MAC-10 | Button Masher"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/649b2faa10fe80a7ad8c58809ba80cea4506de39ac4404bab40913c1d2371be7.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "内格夫 | 橙灰之名",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 534,
        "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
        "len": 3,
        "hash_name": "Negev | dev_texture"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ffa28ce8ed5b431d1df8a7ec09d314246aab2bc19a8cd4f8fe81880dacb3eccf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 赛博先锋",
        "min": 0,
        "max": 0.85,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 535,
        "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
        "len": 3,
        "hash_name": "P250 | Cyber Shell"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/79e5d7b33550d0e5c0e8ac0c0fcb49c408206767728597d251bc28ae3ebbdf89.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 随风",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 536,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "Nova | Windblown"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a7a75513aa99cfecdb30ab214646881311cb5183386fb3624fa5fe938d250cd2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 废物王",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 537,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "R8 Revolver | Junk Yard"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9ff2172e73912fd8b07f3e3e9847f9acd0717ea98c3a4f61034b96fce5a88b66.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 动摇",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_28",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 538,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "UMP-45 | Oscillator"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/69b51d9ec5f3c0f8715e0b6eaee0efa4c9d686f6cf128b1969b949c7e632e2e7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 短趾雕",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 539,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "CZ75-Auto | Circaetus"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c53e4183fdf1e33a6977bd039489f1db2a311af5e984ec24243f8d6fadf86a41.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | O.S.I.P.R.",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 540,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "M249 | O.S.I.P.R."
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5278090e6069f7c71badc991e5342e45734977a04f4a17819164f3f878a0d5aa.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 一目了然",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 541,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "Glock-18 | Clear Polymer"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9ab89f1fd5aebd146b18f53929b49a3bacb6b6f13a77294fc64a45de5bbe664f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 重金属摇滚",
        "min": 0,
        "max": 1,
        "target_box": "set_community_28",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 542,
        "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
        "len": 5,
        "hash_name": "SG 553 | Heavy Metal"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/724a77067df47420b68bb5b297fc0717ae5e19352d3018ccdaa1decff8f84d3b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "格洛克 18 型 | 黑色魅影",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 543,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Neo-Noir"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/ac05c77acfb0cea493dee55556d33845e9af0f09a80d5603ec6176479a67af73.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 印花集",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 1101,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Printstream"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/15d1bcf4cc21a01ab0d864edc9e2a309ad77c30d7cf19fe39008ff34e8c17c46.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 小绿怪",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 544,
        "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
        "len": 2,
        "hash_name": "USP-S | Monster Mashup"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a1f2a3a4cf97f2955978d6a9d0ebbef1d3b4496eea5d0448765ac7d868b15bf3.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 赛博",
        "target_box": "set_community_27",
        "min": 0,
        "max": 0.98,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 545,
        "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
        "len": 2,
        "hash_name": "M4A4 | Cyber Security"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f68a866f06f9ab92cd48d2d2d763738c1c0ae6cfbb57c0f36d82ec59b747a214.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "FN57 | 童话城堡",
        "min": 0.02,
        "max": 0.9,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 546,
        "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
        "len": 2,
        "hash_name": "Five-SeveN | Fairy Tale"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/dc91b05b14051eb3812e291a23227fe3ecfa1605eab1348e57db0e3410a4e54f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 金铋辉煌",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_27",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 547,
        "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
        "len": 3,
        "hash_name": "UMP-45 | Gold Bismuth"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b8de8f85bb3a40fc1390b6889b7c806756508c5bcccc48cd449c5419300fe270.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SSG 08 | 抖枪",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 548,
        "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
        "len": 3,
        "hash_name": "SSG 08 | Parallax"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/25d5a4b0b2c9eba70965fd3f99b7d55712fd7548f5a298f9767a312afb462751.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 一见青心",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 549,
        "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
        "len": 3,
        "hash_name": "Nova | Clear Polymer"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5b700620b7d898986e114b5d7cffcd858278490c44dbe1513d01e23158461adf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 灾难",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 550,
        "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
        "len": 3,
        "hash_name": "Dual Berettas | Dezastre"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/780c21ca1185bccaba7f77a9dec10de032939ecb6bddac43a086e36db1bdfffd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 亡灵之主",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 551,
        "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
        "len": 3,
        "hash_name": "AWP | Exoskeleton"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/078acd5be2c3721ccf850fbb5d4781d641dabc0948b4406011badeee5463e3bc.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 零点行动",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 552,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "MP5-SD | Condition Zero"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b98435bf1e5253d73d86a5a3d8c812ca058727ab284755bb6f1f2a9ca4f0adb2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 等高线",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 553,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "M249 | Deep Relief"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b55b6663775ee14a5bb0a57f8d84ac4eeb4a31ad4921fa9a6892cc0cd7215b69.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 污染物",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 554,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "P250 | Contaminant"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e7faedb775925770bc1aa2224b04d8db3695d1a673aadb71bf93adc8131bd4db.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 破坏者",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 555,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "Galil AR | Vandal"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9aefa026440dc1fe0b857e80ad023199d030d4cda21e5b0f47abc79ee7da0f76.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 血腥迷彩",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 556,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "G3SG1 | Digital Mesh"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1b062648969c9603b12151d895807dae55e38073de3f12ec3502da558fd88321.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 大怪兽RUSH",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_27",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 557,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "P90 | Cocoa Rampage"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a9a7b200bf8cb17cc72ad49cd22f68097e4565232f2974f21ed9d7f18586e102.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 世仇",
        "min": 0,
        "max": 1,
        "target_box": "set_community_27",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 558,
        "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
        "len": 5,
        "hash_name": "CZ75-Auto | Vendetta"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/4c6bfe5963f31af93b3ff36421b1da1cad36b7d9350511b38d08b0d9f3ee3afc.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 阿努比斯军团",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 559,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Legion of Anubis"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/9d3bb5a06de64bfba0ad5017fdaadf56e02175056ccfd4c68b5f4bbceac68bef.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "沙漠之鹰 | 印花集",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 560,
        "list": "",
        "len": 0,
        "hash_name": "Desert Eagle | Printstream"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ff3f2aba614c9639282f2fb9e5bd3ebac94d565ca7e7f81ca24bb8e595d1fea8.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "XM1014 | 埋葬之影",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 561,
        "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
        "len": 2,
        "hash_name": "XM1014 | Entombed"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/8e796a0cd241787ab43bb3d73db4dc7182e36c2a0005954002aad250f2d327b2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "格洛克 18 型 | 摩登时代",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 562,
        "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
        "len": 2,
        "hash_name": "Glock-18 | Vogue"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a29758e2235f3bb881d375e371d9ca989f2db1806bdf9f172262aa2aa69f7b6d.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 齿仙",
        "min": 0,
        "max": 0.73,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 563,
        "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
        "len": 2,
        "hash_name": "M4A4 | Tooth Fairy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f2a86e648c197f671b387bfb8f7963ef661883116e1a0fd00978c55de3420d08.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP5-SD | 猛烈冲锋",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_26",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 564,
        "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
        "len": 3,
        "hash_name": "MP5-SD | Kitbash"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9b9b297f795e1fcd5329e44513aa8e9c28ee3b47bbe9ae432cf20f10a1a4ae08.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 凤凰商号",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 565,
        "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
        "len": 3,
        "hash_name": "Galil AR | Connexion"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1399c35e784b4376e77e788ad34ad1c415c07c1b8fcd70e47e90b86130588fee.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 魅惑",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 566,
        "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
        "len": 3,
        "hash_name": "MAC-10 | Allure"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7c9b0f67536a5a6ef66fa31ab8382799f55bea6d5458bed335aa6fc99d3d9bfd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 兄弟连",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 567,
        "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
        "len": 3,
        "hash_name": "Tec-9 | Brother"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/38a9390f7610a477a6feba4952fa2538e4f979b3a218c09bea74309e0c42c36f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 北冥有鱼",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 568,
        "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
        "len": 3,
        "hash_name": "MAG-7 | Monster Call"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/20fc62d1462465a08119c0ff85f4b6ba4d11753d86712e6dfca1602a5dde03dc.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 神秘碑文",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 569,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "PP-Bizon | Runic"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2d1f7e24702db8203d0f28a4e50c9793c259497d8d60104b75024b7e25c80c96.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 集装箱",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 570,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "P90 | Freight"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3e491414a973092002e7f75b6acedbef62aa28e38a88826b6dd56e3860d6e9de.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 卡带",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 571,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "P250 | Cassette"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/438f576cf73967423b1c1e23fe89f6e67b8d863ab5a754fcae2f9b6910d0462e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 主机001",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 572,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "SSG 08 | Mainframe 001"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/758460d2a9a691d7695d70c3f7cd3b60f96d79d245027cf468c58e0d9a366e2d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 锈蚀之刃",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 573,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "SG 553 | Ol' Rusty"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4f90093eadd3f31d3c7a613c89598942085f763721e2ff730e63bc754fef5578.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 盘根错节",
        "min": 0,
        "max": 1,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 574,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "P2000 | Gnarled"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cbc3cf3671578efbb625a62fe3086e7b5e170420f228a5d87510de7742385cbf.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 飞羽",
        "min": 0,
        "max": 0.79,
        "target_box": "set_community_26",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 575,
        "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
        "len": 5,
        "hash_name": "Negev | Ultralight"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/a659410c66a2902a720fe2dcdeba7c5c0898020c1824c937c0fc07d19616297b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "格洛克 18 型 | 子弹皇后",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 576,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Bullet Queen"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/70766b570da807039694ab4032feeec6860eb57cf07539305ef349d1dac1bad4.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 二号玩家",
        "min": 0,
        "max": 0.84,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 577,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Player Two"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ca484e6063adf2b30225abcfdd376a66d45bfaf80edecda5ccf64e5570be91d2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAG-7 | 正义",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 578,
        "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
        "len": 2,
        "hash_name": "MAG-7 | Justice"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a0fe494360f507a48e1f774ea583a6953948535a32187454d1180e824c7f03bf.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MAC-10 | 渐变迪斯科",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 579,
        "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
        "len": 2,
        "hash_name": "MAC-10 | Disco Tech"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/c2f31d86add4ce2eaf8deb6767c2b8b8fab34e0cba483b187fab3c8e01f1fe2f.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 幻影破坏者",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 580,
        "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
        "len": 2,
        "hash_name": "AK-47 | Phantom Disruptor"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e06db3b421fb03cf3e2c04e09042284caff88512500e55e140b585dccfa14ebd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SSG 08 | 浮生如梦",
        "min": 0,
        "max": 0.72,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 581,
        "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
        "len": 3,
        "hash_name": "SSG 08 | Fever Dream"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e36ed95146507e17fa596a385dc34fbd0e48e93ef53de4d550898ae44e59b1af.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 黯翼",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 582,
        "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
        "len": 3,
        "hash_name": "SG 553 | Darkwing"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/538d3668bfd1c01a27986cb186995cb797963a6730734b72ef1b3dcb5e9c779c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SCAR-20 | 执行者",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 583,
        "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
        "len": 3,
        "hash_name": "SCAR-20 | Enforcer"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7eb64cdbc2674defe936514c46a8767ccc1cd81c831f937a535744e1289b754a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "截短霰弹枪 | 启示录",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 584,
        "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
        "len": 3,
        "hash_name": "Sawed-Off | Apocalypto"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/aa68367d486a0d24c7bcd5f725b188958c0cbbfb3d7ffa63e79ce58698d0a25d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 酸蚀",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 585,
        "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
        "len": 3,
        "hash_name": "P2000 | Acid Etched"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/54df94e8a27e53b04aab02b7b82387700519a869b9027c695c822a238a27d545.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 骸骨锻造",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 586,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "R8 Revolver | Bone Forged"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/549941d4633cab8a262e7e5fce788cd5121002398d390a54c19bacce4cb954f7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 原型机",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 587,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "Negev | Prototype"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0745b78aacffaaa3a9b327022dddb2d40d701a09f0349c0f92627e422709fc6c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 沙漠精英",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 588,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "MP5-SD | Desert Strike"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d8141c8e48c6a79337eb7c0a6fdb650de07e4439f5fc57d4d5a6a170ff61736f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 蓝色层压板",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 589,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "Desert Eagle | Blue Ply"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bcb6995983cf0eba7d79697f4054aca876bf3749ad0a93846bed22986d8063d0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 做旧手艺",
        "min": 0,
        "max": 1,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 590,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "CZ75-Auto | Distressed"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3f9025fa4dd8f6a08216d074d793115a911a4f560ef98aade989c2fe62747b2e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AWP | 毛细血管",
        "min": 0.05,
        "max": 0.7,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 591,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "AWP | Capillary"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9fd229c2d641d250b533d3d3fba4f081c9acd8282405570ecc0d87a168728c93.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 汤姆猫",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_25",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 592,
        "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
        "len": 5,
        "hash_name": "AUG | Tom Cat"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/a84ab987a22cd14535fe6d35f5825d57306fd7fe183d00600b31ecbae38e4f48.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "MAC-10 | 潜行者",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 593,
        "list": "",
        "len": 0,
        "hash_name": "MAC-10 | Stalker"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/32608882f7f2ef72b9c6b8493571eed5873c6901c70c3521bbfdedfa50e6da11.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 冲出重围",
        "target_box": "set_community_23",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 594,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Containment Breach"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/113588b8fd07c63ead0abd02c798c028820570c7a536a8308bbc2c66d79624bb.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SSG 08 | 喋血战士",
        "min": 0.14,
        "max": 0.6,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 595,
        "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
        "len": 2,
        "hash_name": "SSG 08 | Bloodshot"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1812f38413e6a52cf3e9a93243ed902e9ee5f9fb2c3b6c3ad0435dfc921cadc8.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SG 553 | 四号栖息地",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 596,
        "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
        "len": 2,
        "hash_name": "SG 553 | Colony IV"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/e93f1885b4a9327e22bcf4201587ab60e46124fe7cd8af3ef9e84ef0c52fea1c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "Tec-9 | 屠杀者",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 597,
        "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
        "len": 2,
        "hash_name": "Tec-9 | Decimator"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/84b20a6048e53afb9451bd120900cb52b027aa92b945c7cb938b93e87254c5ca.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 黑曜石",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 598,
        "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
        "len": 3,
        "hash_name": "P2000 | Obsidian"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/37fdecd177b56db5751ef3d6d6c8c5b449a84e95d2eb2f457db680a1b8907b35.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 七彩斑斓",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 599,
        "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
        "len": 3,
        "hash_name": "MP7 | Neon Ply"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9bc4923e6e439b58b4c124261939b572d9ebd18e93896318e273427903c289c3.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 极地孤狼",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 600,
        "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
        "len": 3,
        "hash_name": "AUG | Arctic Wolf"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1d6c3053ace7cf0d2a85bd0d6536a229473aeb93d4a20a5195504eb957a7fe14.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 复古浪潮",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 601,
        "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
        "len": 3,
        "hash_name": "AK-47 | Rat Rod"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/68cc82b80d4267c525f15813f9909ad3393165030ec5531ff73e3e5084fcc99a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 路霸",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 602,
        "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
        "len": 3,
        "hash_name": "PP-Bizon | Embargo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3569a22df5fb320a63fa125583a05694d43d6bb2121ffbd024fb201d86a16404.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 战隼",
        "min": 0.05,
        "max": 0.65,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 603,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "M249 | Warbird"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c241ac7dc03887660d1804c03cfb721b64df4d4b812cfd25d9c4bf9fb649f471.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 撕起来",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 604,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "SCAR-20 | Torn"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cf01820e85c2c90ca93c4cf9197f252eba7e348c28a8ee286aa239c3f7a602bc.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 左右开花",
        "min": 0,
        "max": 1,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 605,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "Dual Berettas | Balance"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f40a10bece984c79cd722cb1807352d49ec97ad903c959349f60f99c626ec793.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 记忆碎片",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 606,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "R8 Revolver | Memento"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d75f8d59be651b342e95ab1fe4b29f73b68831d92e99b7ac6e6b8878a9798dd6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 黑砂",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 607,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "G3SG1 | Black Sand"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4a783c2d3c8c93edd9f4dc4575ced2de70ade5f90afaaaa1ca15acb397baf31e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 风卷残云",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_23",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 608,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "Nova | Plume"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/553b15667fadd70c50114a4bfc73bff7e10c54f370424a192364660aa4633590.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP5-SD | 鼻青脸肿",
        "min": 0,
        "max": 0.68,
        "target_box": "set_community_23",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 609,
        "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
        "len": 5,
        "hash_name": "MP5-SD | Acid Wash"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/fd97557e36b74c76b796d07c066c55ab00ecfd901c626824bf8e0a2de7a16c63.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "法玛斯 | 纪念碑",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 610,
        "list": "",
        "len": 0,
        "hash_name": "FAMAS | Commemoration"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e2820dde1a8eecbcc378d05dcaae445b65c6c22ad5178b204ef2ec871051e7dd.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 野火",
        "min": 0.01,
        "max": 0.7,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 611,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Wildfire"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/45f7a9cb0c7be01c81a52a659092235a8ae8c20b1e0435a5f3e44993b8201937.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 汪之萌杀",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 612,
        "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
        "len": 2,
        "hash_name": "AUG | Death by Puppy"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/63314fcb242432453a3261971c46ce6dcad2738140b215a8d8727bf0a1f8827a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 往日行动",
        "min": 0,
        "max": 0.57,
        "target_box": "set_community_24",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 613,
        "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
        "len": 2,
        "hash_name": "P90 | Nostalgia"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f95931af2dd27cefe414d966d0843b74d10dcd832bc549a63a8e9c00396479ab.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP9 | 九头蛇",
        "min": 0,
        "max": 1,
        "target_box": "set_community_24",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 614,
        "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
        "len": 2,
        "hash_name": "MP9 | Hydra"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/76adb302d51842f2fbea49c2953c94ab8ff962617533631fae0e1957eabaecfd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 塑胶炸弹",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_24",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 615,
        "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
        "len": 3,
        "hash_name": "UMP-45 | Plastique"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8dc96c9dcf6527968477e9a018e5e0a200a53905eafdcc2da833306fb8777363.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 炼狱小镇",
        "min": 0,
        "max": 0.68,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 616,
        "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
        "len": 3,
        "hash_name": "P250 | Inferno"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/89c2aee562fcdfb75c11420e493a4d666f1c9adcf99cc3ccd7d44f2e3e7ba15a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 好兄弟",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 617,
        "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
        "len": 3,
        "hash_name": "Five-SeveN | Buddy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3ec7981aa5cb44283a2057832803522023643ec38da8a99d70605bc3ecbf844e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP5-SD | 探员",
        "min": 0,
        "max": 1,
        "target_box": "set_community_24",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 618,
        "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
        "len": 3,
        "hash_name": "MP5-SD | Agent"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/02d93ea88cc821974fe8dc0d271006b78bd3bd86f029eb2b84d6e2b4ad100221.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M249 | 阿兹特克",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 619,
        "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
        "len": 3,
        "hash_name": "M249 | Aztec"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8a86b73084bc4773f0f301f06781021a86a48afc9f36c96e9d38574a0a85423f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 烈焰天使",
        "min": 0,
        "max": 1,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 620,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "Glock-18 | Sacrifice"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/03dec41bacd632b1ed6b7ab2e2fb84718a11fc6d2a5ea75be31155eb7a1c216f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 退役",
        "min": 0,
        "max": 1,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 621,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "FAMAS | Decommissioned"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/567b256f83d794798bf758014d19574eb90f08fe958905d2e660d400f76835d0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 仓库突击",
        "min": 0,
        "max": 0.62,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 622,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "SCAR-20 | Assault"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b186628cc5d89ccc1f7f2456c3f0c94cd5de75b2c27c01544df99db271cc0f5a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 碰碰狗",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 623,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "MAG-7 | Popdog"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/82fe052da6332334763987153d1d0423614145b6c4e3d78447f716ebcfd70d37.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 板条箱",
        "min": 0,
        "max": 1,
        "target_box": "set_community_24",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 624,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "MAC-10 | Classic Crate"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bfdb7dd6aa909c9c0ae076cce187d7512d6d08c186b731bec52af5a96ebaccdf.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 闪光舞步",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 625,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "Tec-9 | Flash Out"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bdc566754f0e4c803d24f70009f6bb8e43bdbca5674737b4c3c77266940587d6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 1.6精英",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_24",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 626,
        "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
        "len": 5,
        "hash_name": "Dual Berettas | Elite 1.6"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/ad94ebd7f6f90f49d859305109a5c0863d7279c49c4d5894394329b93e7c852f.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 皇帝",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 627,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | The Emperor"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/4947563bd39073cfc34a6d0e8400c55e4d6086d2a455ae6a7bdc478289a0e6a7.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "FN57 | 怒氓",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 628,
        "list": "",
        "len": 0,
        "hash_name": "Five-SeveN | Angry Mob"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/177c994682208f0619565d2a12cb379c08b85d3e6c4c2e367ef706c8c00a06cf.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "XM1014 | 焚烬之鳄",
        "min": 0.14,
        "max": 0.65,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 629,
        "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
        "len": 2,
        "hash_name": "XM1014 | Incinegator"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/392ad433ea4ef376746c70dbaa7dd8f63ac46bf4772fff945e3a1d81ad2ced82.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 动量",
        "min": 0.05,
        "max": 1,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 630,
        "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
        "len": 2,
        "hash_name": "AUG | Momentum"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3e5cccf59f5ed885b7e8817ee3506fe1c704ee0763ea19dd365e6adca613a936.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "R8 左轮手枪 | 头骨粉碎者",
        "min": 0.25,
        "max": 0.8,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 631,
        "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
        "len": 2,
        "hash_name": "R8 Revolver | Skull Crusher"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c82c0681be6c8d260cc4e61ad15fd58e4d34b25cd7cfdea1b2a54fc6481ad01d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 城里的月光",
        "target_box": "set_community_22",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 632,
        "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
        "len": 3,
        "hash_name": "UMP-45 | Moonrise"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8352b1cba591f091b950cd010219aa2e343a3653bbd4ce9e93b4a90f02c4a902.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP5-SD | 高斯",
        "min": 0,
        "max": 1,
        "target_box": "set_community_22",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 633,
        "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
        "len": 3,
        "hash_name": "MP5-SD | Gauss"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ad79e2d85cd7c6640c04200bd90713632f524e263f5fa7dc8aa1335f73fdf322.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 轻轨",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 634,
        "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
        "len": 3,
        "hash_name": "Desert Eagle | Light Rail"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/34c998044fcec7217a0ca623b283d777cde14da3b311635bac4ad53b13715740.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 青竹伪装",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 635,
        "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
        "len": 3,
        "hash_name": "Tec-9 | Bamboozle"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7c01be97d574ac68f145566ae1cd1f71fc8d0e6300f563c5c666bf879eee673b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 树蝰",
        "min": 0,
        "max": 1,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 636,
        "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
        "len": 3,
        "hash_name": "AWP | Atheris"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/54bdb955f3828c7735034cc2a9f4519919e5265d2b041d45e1ad3ec8f8e02ded.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 异星世界",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_22",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 637,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "P90 | Off World"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/845bc80231ddadc2f37de27aa91cb69ecf1cde86f004fafd3622c7a82f7afe3f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 铜绿",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 638,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "P250 | Verdigris"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fdb2d1e25f4bd5d0bbf0ba2726790b111bab563c681f1a85e32fe1f6b09d4e9b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 恶作剧",
        "min": 0.1,
        "max": 1,
        "target_box": "set_community_22",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 639,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "MP7 | Mischief"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c00704f8dbcce76928b70d6b82fcd24434ec4ee8e7374189d9665eaed0d6843f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 战吼斑纹",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 640,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "Galil AR | Akoben"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3e197441c78cbe288d480327d08836fbb4a33be67066d522968210b693b6964d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 白鲑鱼",
        "min": 0,
        "max": 1,
        "target_box": "set_community_22",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 641,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "MAC-10 | Whitefish"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/81757487ecf20d8d10aa18a25bf6e0564bc9d88cc3ead9447ae6afef27729e15.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AK-47 | 迷踪秘境",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 642,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "AK-47 | Uncharted"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e0a62130d9792e78a2004f90c80b423275e11b3717b022c3a168785db7096e20.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 保护色",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_22",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 643,
        "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
        "len": 5,
        "hash_name": "FAMAS | Crypsis"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/79da639a06c07b24a3dc0b1f44e656f15c6136c4244e9a0dff2214de197d67cc.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 黑色魅影",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 644,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Neo-Noir"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/9f7ea1e78e0b30470a39484dc0ca4dd8862c36166c77a6fa9c57301c1629f1a8.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 二西莫夫",
        "min": 0.05,
        "max": 0.7,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 645,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Asiimov"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ed45fa8182dbaae12e27384e8aa5760c16727d1b8e216bf4e63264c98d3e9557.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP5-SD | 磷光体",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_21",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp5sd",
        "itemid": 646,
        "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
        "len": 2,
        "hash_name": "MP5-SD | Phosphor"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/55aebefe10af77a971696006ff5967d11ad4c2d531ce1faeb9823d0f5c9dfc44.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 机械工业",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 647,
        "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
        "len": 2,
        "hash_name": "Desert Eagle | Mecha Industries"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/dfdb26ef106681173cf45a7663e9fc022eec547e59ee7aceb16087c603202819.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "UMP-45 | 动量",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_21",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 648,
        "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
        "len": 2,
        "hash_name": "UMP-45 | Momentum"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c967c6fdab56eb5757227d02d7b10823ecd88fbfcde18c0d408f4425ae3a191f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 闪回",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 649,
        "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
        "len": 3,
        "hash_name": "USP-S | Flashback"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4c4736bcecf6fd2e835082e387c21616855392d12b4d09a9ad3261357a9570ad.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 影魔",
        "target_box": "set_community_21",
        "min": 0,
        "max": 0.4,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 650,
        "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
        "len": 3,
        "hash_name": "P250 | Nevermore"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d070b34489cf90ec1e292414d9bb547ac350970e32bf80b6573261ab8aa1dbe9.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 销声",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_21",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 651,
        "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
        "len": 3,
        "hash_name": "MAC-10 | Pipe Down"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/dcd9971da95044f6e10a3791ad50691acba86cca2cb9c3a4375f6e2b1f2a7a7a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 信号灯",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 652,
        "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
        "len": 3,
        "hash_name": "Galil AR | Signal"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/66c51a344c83fc8d7c4586b5d67700a05adef9466094e79a8b6ae7e1279eba43.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "G3SG1 | 净化者",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 653,
        "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
        "len": 3,
        "hash_name": "G3SG1 | Scavenger"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3438257d1211f9aa12480cc0df1f80da608614a55b95d4326f21809aaacc3d88.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 破铜烂铁",
        "min": 0.14,
        "max": 1,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 654,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "Tec-9 | Fubar"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0f8f2537e458924aeb4ec59488b6b5aeb9103f01959a580640bcbd715772fe6f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 危险距离",
        "min": 0.02,
        "max": 0.8,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 655,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "SG 553 | Danger Close"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6aaafe869d4823ccfc7882f42664e3eb539cde7a64fb6ee54f9110b008ab643c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 黑砂",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 656,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "Sawed-Off | Black Sand"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fe34934748d4e3b4a43559717857d8abc7e310c8bcb20ee39cacda66273c4857.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 镁元素",
        "min": 0,
        "max": 1,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 657,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "M4A4 | Magnesium"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4d08c0c431286018b39407121338d75af2a3023492c1290dff4228588b425bfb.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 灼木",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 658,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "Nova | Wood Fired"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6d5856c1e8ae5475c0c91245984a0b25d1e1cd7149b727dd218b7654937b4da5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 锈蚀烈焰",
        "min": 0,
        "max": 0.85,
        "target_box": "set_community_21",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 659,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "Glock-18 | Oxide Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/30f685b06b69dc00d4fc7ef4b2d378141bac582eb35c2f046fc154e781cc849e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 中度威胁",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_21",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 660,
        "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
        "len": 5,
        "hash_name": "MP9 | Modest Threat"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/fab4e2a987f3a69db4e8d3116761e1ca1823a26511ee709d025d2fd713dc4146.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "沙漠之鹰 | 红色代号",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 661,
        "list": "",
        "len": 0,
        "hash_name": "Desert Eagle | Code Red"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/d6be8b51de096c041764903c3dc55374c005dc85df274df992201ca742992202.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 霓虹骑士",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 662,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Neon Rider"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/9fb1c80b5aa9bdea7473026dd248fd98baf59824aad8a9ac7c9663274f6350da.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 雅典娜之眼",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 663,
        "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
        "len": 2,
        "hash_name": "FAMAS | Eye of Athena"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/331c651e04461f72668cdc62467c54c667494fb15f92b7d0c0cfffa510bc85bd.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "截短霰弹枪 | 吞噬",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 664,
        "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
        "len": 2,
        "hash_name": "Sawed-Off | Devourer"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b1e83497ec133fc05589e892bbb9147a810fce93e693dbd66043ddb37beaf2b6.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 梦魇",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 665,
        "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
        "len": 2,
        "hash_name": "M4A1-S | Nightmare"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f6623db9f56cccc80e3ecfbe71537d563462f0e6aa27ea56e1584f7de7ec9a39.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 权力之心",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_20",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 666,
        "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
        "len": 3,
        "hash_name": "MP7 | Powercore"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/04bf96419730e41c4e24f0eddd73835356c8c05f9de40af03e82e31fa6fb534b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 猫猫狗狗",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 667,
        "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
        "len": 3,
        "hash_name": "AWP | PAW"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/cd72bad9dbc12b375d0a32d66110bda837eb530c1299f0a71889b09d404ed75b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 玩具士兵",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 668,
        "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
        "len": 3,
        "hash_name": "Nova | Toy Soldier"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/04fb5494d7c411cb2a60735485bf24edf2c03583dd2233db9ffeb7c4677b0898.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "G3SG1 | 公海",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 669,
        "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
        "len": 3,
        "hash_name": "G3SG1 | High Seas"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6e2ca5d868e16dd6266d981dcc98d9a378848a14a49bfe3c4c988d2890bb1c0c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 经济",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 670,
        "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
        "len": 3,
        "hash_name": "CZ75-Auto | Eco"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0a0a49cd9d93f6e93b92368d5e1ff92403670aa86733bc516f10cb1343e20288.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 蛇-9",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 671,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "Tec-9 | Snek-9"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/797f4b2e68809866c9dbaa3ebc03e499aadd5c4c1e455cfcb18f63494388df49.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 生存主义者",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 672,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "R8 Revolver | Survivalist"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1b8d56dd76afae99fd243b017235f16e8dbe110ff29260e71b984efae9c752ee.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 牵引力",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 673,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "P90 | Traction"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/435423a8be62ca4d56571453d711edf7f70974b8b2cfad4f0456f601e0c38697.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 毛细血管",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_20",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 674,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "MP9 | Capillary"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/4803946d38576006dd667940749d767493fa253c17f28a3c36fa306e9aaca640.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 战鹰",
        "min": 0,
        "max": 1,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 675,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "Glock-18 | Warhawk"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b6070941372847145fd38402e39672fd5c97477da368d2aa9d4edc832c03a626.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 碎片",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 676,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "Dual Berettas | Shred"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/712772dcfb742b23e7617017b686dcf7dea364543011cff6c3d59b5630e02e17.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 琥珀冲流",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_20",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 677,
        "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
        "len": 5,
        "hash_name": "AUG | Amber Slipstream"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/0200622ffc9802ab51d263e351e7f3c749b0315d082659166f64e7ffdb76cf9b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "MP7 | 血腥运动",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_19",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 678,
        "list": "",
        "len": 0,
        "hash_name": "MP7 | Bloodsport"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/5662391c2c7f01ff12966835044ee643ee69d6fe47863eff8b8f96a5e8e5e4b6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 黑色魅影",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 679,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Neo-Noir"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/7f125c482a22708a86ad054f188590e20bf67a19566131456519d38323797e52.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 脑洞大开",
        "min": 0,
        "max": 1,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 680,
        "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
        "len": 2,
        "hash_name": "USP-S | Cortex"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/290dbdfe56fb435f5887f637b52671e3dc95c7806222b6d95846e56243f2f900.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 死神",
        "min": 0,
        "max": 0.64,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 681,
        "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
        "len": 2,
        "hash_name": "AWP | Mortis"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/862661a6597f8be22be9556c65e0d82c50ff2493f0a9f970d8c583bc745029b2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 湖怪鸟",
        "min": 0,
        "max": 0.63,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 682,
        "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
        "len": 2,
        "hash_name": "AUG | Stymphalian"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/827b357133f13d1b4cceaec0506072f1f0b0304d077c8deeec6065736b58f632.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 白狼",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_19",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 683,
        "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
        "len": 3,
        "hash_name": "UMP-45 | Arctic Wolf"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/cd9fda6b798ee30bf47cd40db7e6082d96cde07e514377852ed4ca81886310af.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | SWAG-7",
        "min": 0,
        "max": 0.92,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 684,
        "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
        "len": 3,
        "hash_name": "MAG-7 | SWAG-7"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/37c7d730cbd57e3a06a86956693fcb6bf55a935329e73cb6c5b86aea50d69038.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 狂野六号",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 685,
        "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
        "len": 3,
        "hash_name": "Nova | Wild Six"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3ca8e362373e17f0c6fbecb26445cfdb78557914cc5771e295ec5d9de06daddb.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "内格夫 | 狮子鱼",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 686,
        "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
        "len": 3,
        "hash_name": "Negev | Lionfish"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2a8b60f70e563198436453796191f123c49c7550e3b06ba221e5fbd3a3fa524d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 城里的月光",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 687,
        "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
        "len": 3,
        "hash_name": "Glock-18 | Moonrise"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5611cfba300e7ae9775fcb901f54eb0512b458ca71a8f6fd5fcbb02a4ceedbb4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 锈蚀烈焰",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 688,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "XM1014 | Oxide Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/68a76ede2523431eb47bb5bdd3710260fc248f8636bb488fb3b82f4eb6772eb8.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 阿罗哈",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 689,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "SG 553 | Aloha"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/181b8305222ab45d83f099c9354f665f9b61182a75b656b81a457523a99462d2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 稳",
        "min": 0,
        "max": 1,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 690,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "R8 Revolver | Grip"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a62ad4ab8ddac952ddda7ae52465740f9b567b0f8e60adbab4b8eb3bd367736e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 都市危机",
        "min": 0,
        "max": 1,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 691,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "P2000 | Urban Hazard"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/72ed969c428ee69a167bb666a7df88dc808cd085a2a8ad3ffcd4767f36cc8fb4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 黑砂",
        "min": 0,
        "max": 1,
        "target_box": "set_community_19",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 692,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "MP9 | Black Sand"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/79001b0edfc4482e2584f0394a982d0eddd8fe72fe165731522c0b7b0b2f0b27.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 焰色反应",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_19",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 693,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "Five-SeveN | Flame Test"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ab891ac966985c8fb86ec007c4a979d73efbd29a9c7f3775623849bd67f70af4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 黑夜暴乱",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_19",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 694,
        "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
        "len": 5,
        "hash_name": "PP-Bizon | Night Riot"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e165228eb24647e27491598a13b1c004c21ece92bcc36ad7047e7b3f2ede6a2b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "P250 | 生化短吻鳄",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 695,
        "list": "",
        "len": 0,
        "hash_name": "P250 | See Ya Later"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e0493efc502129ce099509adf18fa964b62e365ab93148dbdc4f3c7069b7ab9b.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 皇后",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 696,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | The Empress"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a9a2b58aa6000fec9ef6c74784301c21d62fa85fb225aaa7c18cec4db76553a7.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "R8 左轮手枪 | 美洲驼炮",
        "min": 0.03,
        "max": 0.7,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 697,
        "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
        "len": 2,
        "hash_name": "R8 Revolver | Llama Cannon"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/40c1aef1a26a6eedc6035fe0161997a1a734aec2600afe35f80fc9c9fb047364.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 破碎铅秋",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 698,
        "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
        "len": 2,
        "hash_name": "M4A1-S | Leaded Glass"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/449e06e681db4caaeded811be3a09cd6b27925ee2e813ed9ea01cb84dde24b77.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "PP-野牛 | 买定离手",
        "target_box": "set_community_18",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 699,
        "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
        "len": 2,
        "hash_name": "PP-Bizon | High Roller"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c5078c7a9143df7c478190b857856745c08f8af3f4accab18188c266f4ae21db.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 五彩斑驳",
        "min": 0,
        "max": 0.72,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 700,
        "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
        "len": 3,
        "hash_name": "XM1014 | Ziggy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/75de342710a31ca3c93b854cd6ab0af8260c25e957a8ffaf01a3311f9a8eaa33.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 曝光",
        "target_box": "set_community_18",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 701,
        "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
        "len": 3,
        "hash_name": "UMP-45 | Exposure"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8c07fec3749b9313854d074eb0c455f3664da5bfb67f4d17615ce4f94a5e8dc8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 战术高手",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 702,
        "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
        "len": 3,
        "hash_name": "CZ75-Auto | Tacticat"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/387075f4bda7ddda2747e2772912aa5726bc79d51319e1d639dcb6a1f7d4825c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 幻影",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 703,
        "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
        "len": 3,
        "hash_name": "SG 553 | Phantom"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/da4340cc84c1db94d3e49a7ff44d18d4e23abc4cc4e796f7d94fdc62476ceb7b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 焦油缠绕",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_18",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 704,
        "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
        "len": 3,
        "hash_name": "MP9 | Goo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b3a6d90caafeb5bc6bbc688e3ee4953fa430bad56b87dc1b6d8e71d5772b03ce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 丛林滑流",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 705,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "SCAR-20 | Jungle Slipstream"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a58abda1b532f0f2057ec609dcbcec151c1dac4e68e4b835349963d85e17ba51.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 碎蛋白石",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 706,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "Tec-9 | Cracked Opal"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e87e11465e85c352f338081e0147379ddad9a0d374989aa1ac51572c052a70c7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 海洋",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_18",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 707,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "MAC-10 | Oceanic"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/df640261dd930db7af16f870c2003ea19c6f930791e18f8d484921ee13a020be.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 异星世界",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 708,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "Glock-18 | Off World"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/bc8a1bf8caa18d942b337d4dd08f498eedb880c98b3ab9db3b484ea7142bb28d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 猎人",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 709,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "G3SG1 | Hunter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/941437c6239905417375b28f0d479d6338f7633f7595f28144538bceb1031cb6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 三角战术",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 710,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "AUG | Triqua"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7e5880adf04898d176552d032a76788abc0e065ebddfb51bfb48355d69787455.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 夜百合",
        "min": 0,
        "max": 1,
        "target_box": "set_community_18",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 711,
        "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
        "len": 5,
        "hash_name": "Sawed-Off | Morris"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/d19c4b36c8e4b5ea20bbf567e5dd5e357ee99d969e7ae334d75b37e063e0abc6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 鬼退治",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 712,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Oni Taiji"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/ee707b5572a2c01171434785ba4dad7a7adf9cafcb095c4c26c50d5ac5af61fe.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "FN57 | 暴怒野兽",
        "min": 0,
        "max": 1,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 713,
        "list": "",
        "len": 0,
        "hash_name": "Five-SeveN | Hyper Beast"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/27dcd364b81667ac38410b5e96860c3dceb223bd763f2c48cf21c83601e41ff0.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 地狱烈焰",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 714,
        "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
        "len": 2,
        "hash_name": "M4A4 | Hellfire"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/828a24af8e66ba81f2c2d0efcdec562e810628b7918ec9d7ce5fc607cb5ac4d5.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "加利尔 AR | ~甜甜的~",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 715,
        "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
        "len": 2,
        "hash_name": "Galil AR | Sugar Rush"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3d75ab327e3d624c165edc3f317fedf2f6354c7d0b38632ecf5f4b5d30007777.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "双持贝瑞塔 | 毒蛇袭击",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 716,
        "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
        "len": 2,
        "hash_name": "Dual Berettas | Cobra Strike"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ec42f4064f75e77fe29945f516c1333a6f4521d1e949e0a96c80b9db15ff423b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SSG 08 | 鬼脸天蛾",
        "min": 0,
        "max": 0.51,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 717,
        "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
        "len": 3,
        "hash_name": "SSG 08 | Death's Head"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2d9cefd1c5a2078b2abf6efd604aad1f4bf8ac9ac9ec800091521a3e7862ec29.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 死亡之握",
        "min": 0,
        "max": 1,
        "target_box": "set_community_17",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 718,
        "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
        "len": 3,
        "hash_name": "P90 | Death Grip"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ab30f751e666e3efb14d18dabf07dbff3cf4ccf39ae4dbdb256e45a01065ff6d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 红岩",
        "min": 0,
        "max": 1,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 719,
        "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
        "len": 3,
        "hash_name": "P250 | Red Rock"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2043d8e469038b00e0075fbb7222dd0b0cf6602a5f059dbe548730ce4d43e9d8.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 林间猎者",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 720,
        "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
        "len": 3,
        "hash_name": "P2000 | Woodsman"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0531deac492ecc2e2dd79fd74bdfc15eb9c292f55435e80fdec54aafa7615a39.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 轨道 Mk01",
        "target_box": "set_community_17",
        "min": 0,
        "max": 0.55,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 721,
        "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
        "len": 3,
        "hash_name": "AK-47 | Orbit Mk01"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f00fb5232f8aa8a47724065dc2c373ad2eff8edb95af47ccf5c94a3fbc6b1af4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 合金绽放",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_17",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 722,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "UMP-45 | Metal Flowers"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/545637ab2aa09ce653a6fb43812268fa7a7217ee3570a920cb45f3b3a5624382.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 剪纸",
        "min": 0,
        "max": 1,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 723,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "Tec-9 | Cut Out"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0e3a96414ee8f22d09df44b46cdf16e5043a65bd08c436b9503f6a24eafe8b39.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 硬水",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 724,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "MAG-7 | Hard Water"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b81d71903d4e2cdba980459382543e0f8335a9fa17f93d6fe3807ddae2190027.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 阿罗哈",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_17",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 725,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "MAC-10 | Aloha"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/26743424d30aa41a58c323b24f5d075567e2004efbd9f70354bf6da63b5ddb5e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A1 消音型 | 简报",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 726,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "M4A1-S | Briefing"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/67c482335085552d145c784d9d9dd30cb3d497f85c2270021ccc90e63606af4b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 死亡之舞",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 727,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "FAMAS | Macabre"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e6dbe6b9f598e4d1d1edc50b186e711cdf4c1e97df9e330cd52682c386ea7c2a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 蓝图",
        "min": 0,
        "max": 0.86,
        "target_box": "set_community_17",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 728,
        "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
        "len": 5,
        "hash_name": "USP-S | Blueprint"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/deb7ad5853ca0bb6a882502044625db1dc7878e5a8ba5672804e2019c1332e8c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "USP 消音版 | 黑色魅影",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 729,
        "list": "",
        "len": 0,
        "hash_name": "USP-S | Neo-Noir"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/77d7098db3ad25e59c52f48248449abfe95dd868933c6b7aae9da899c4cb7794.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 血腥运动",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 730,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Bloodsport"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/747d1626179e419e958460e4597b81f287cdc5da81188cc35545437171f84f3a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 毁灭者 2000",
        "target_box": "set_community_16",
        "min": 0,
        "max": 0.85,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 731,
        "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
        "len": 2,
        "hash_name": "M4A1-S | Decimator"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f05824308f88d454b0585068ab9fb8276a392af99d15c57733c2af82e40cb7b5.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "CZ75 | 相柳",
        "min": 0,
        "max": 0.56,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 732,
        "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
        "len": 2,
        "hash_name": "CZ75-Auto | Xiangliu"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/9495e2cdcbe4adacb908843fcc49f1163d6aaa2a641dadb3e58b884da30c0b1d.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 浮生如梦",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 733,
        "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
        "len": 2,
        "hash_name": "AWP | Fever Dream"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/bd4a5ead5bf10ad0652300332d3bc4a19a6215a13207b032816058e457041f06.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 四季",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 734,
        "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
        "len": 3,
        "hash_name": "XM1014 | Seasons"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/32fe9c2ea755a8e765dd81e482d424f277ce2965ff76ae628db4e7c4eadf5f25.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 支架",
        "min": 0,
        "max": 1,
        "target_box": "set_community_16",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 735,
        "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
        "len": 3,
        "hash_name": "UMP-45 | Scaffold"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f49a644a5ae784f8aa4c3b7593c5a88ccd1a4237b972aeed47d844936ba20cec.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 绝界之行",
        "min": 0,
        "max": 0.52,
        "target_box": "set_community_16",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 736,
        "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
        "len": 3,
        "hash_name": "MAC-10 | Last Dive"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2e05b5f9171a863e38fbadc9a6b60a01f581e176e53ad9bf64e48b463dbc0052.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M249 | 翠绿箭毒蛙",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 737,
        "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
        "len": 3,
        "hash_name": "M249 | Emerald Poison Dart"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ea593fc434818bdee9f9f4dd3e80cea81079ba75ff7999399e5faedaf997ea3e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 深红海啸",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 738,
        "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
        "len": 3,
        "hash_name": "Galil AR | Crimson Tsunami"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6deaa8a3c14280db127ec7caa3a9fa765fc8fd9bf66c20675fb665aabb6c697f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 梭鲈",
        "min": 0.05,
        "max": 1,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 739,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "Sawed-Off | Zander"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fd1751dbf1c71d9d5cefe29792d0fbc843bdae801fd744e642d6c325397d4821.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 涟漪",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 740,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "P250 | Ripple"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cdd67878e71e2e3110e49773fe1dca5a77e00eb5930c3ef2bdc5428090802492.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 非洲部落",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_16",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 741,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "MP7 | Akoben"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d27f35860d28bc8d22a50c793a97107749964c612da360e2493ab2221bfb882d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 毛细血管",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 742,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "Five-SeveN | Capillary"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/de3858f11a616c09d6cf0e3c28da30b1b73308645a91a9f6b475c1f413ad4e2a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 锈蚀烈焰",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 743,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "Desert Eagle | Oxide Blaze"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ae0dbba7faaf4baab9251e12d15af369ae747b7ce6060f3d7b575285e452979b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 蓝图",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_16",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 744,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "SCAR-20 | Blueprint"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/30bb1cbe9a27933d9c97c08d0e53721d935b4f4e0443f7c3ea38a3e390606566.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 丛林滑流",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_16",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 745,
        "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
        "len": 5,
        "hash_name": "PP-Bizon | Jungle Slipstream"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/12150612f195bb8385394cdca52d74e0afc5271534d1f87c470a95db5105872a.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 喧嚣杀戮",
        "target_box": "set_community_15",
        "min": 0,
        "max": 0.7,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 746,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Buzz Kill"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/7738daf958c3ae0afd04e10bb012af060f769c09fb4fa2a82ba92dcc49d78ffd.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "SSG 08 | 炎龙之焰",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 747,
        "list": "",
        "len": 0,
        "hash_name": "SSG 08 | Dragonfire"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/bea59c15ca3a39b6d5273c06ff8e298579aeb0fa7102a7474517b2f316a394c0.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "截短霰弹枪 | 荒野公主",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 748,
        "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
        "len": 2,
        "hash_name": "Sawed-Off | Wasteland Princess"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f970e280488e606afe0e08e05bc2837cd392ec9856e15b48f46c03cad5e05146.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 浅坟",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_15",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 749,
        "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
        "len": 2,
        "hash_name": "P90 | Shallow Grave"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b3a468484f0f08e1f3d18071e5cdf9b4724a2fd8b2bf9d2aa6ba89445484d82a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 机械工业",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 750,
        "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
        "len": 2,
        "hash_name": "FAMAS | Mecha Industries"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9f0534e2e7be174f04b34f765b91a8d698b0e79a76fca969579800af41105645.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 次时代",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 751,
        "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
        "len": 3,
        "hash_name": "USP-S | Cyrex"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/fb29e3a48eea3ad46b244acbae6188eef8a1a2a6bd4f75bcbdb3339b18fc0ed4.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 毒蜥",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 752,
        "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
        "len": 3,
        "hash_name": "Nova | Gila"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8b69fa3127cb3e3377ad6e26b3de2f2f121a12099975279f3233cfc43a85d716.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 闪回",
        "min": 0,
        "max": 1,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 753,
        "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
        "len": 3,
        "hash_name": "M4A1-S | Flashback"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8c19ff19e4598b28f06db7c8989f12fe85b739847ab6ce0434b8782fb850ec73.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "G3SG1 | 毒刺",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 754,
        "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
        "len": 3,
        "hash_name": "G3SG1 | Stinger"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/00c287a9b349cc77b575532e85fd81b68b5314dafc3d226e0d2c6499368a8d42.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 皇室伴侣",
        "min": 0,
        "max": 1,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 755,
        "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
        "len": 3,
        "hash_name": "Dual Berettas | Royal Consorts"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c8da836d7e8ef8ac7c24baeac57bfbfe9a1ee04e3af80d08f19cb2de12cc7d78.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 草皮",
        "min": 0,
        "max": 1,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 756,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "P2000 | Turf"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/76124a7d73bf07d7173009de927ac3d4533b2b5dd32630692ec12459c948939f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 声纳",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 757,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "MAG-7 | Sonar"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c6b72ce737b450e380fddaea61f4e94adab6215743e19f0fdd1433d6392edfde.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 砂垢",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_15",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 758,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "MP9 | Sand Scale"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7df876d17f47291a655619faaf8392e0daa37798d7c54a4310a2852758bbd8a8.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 黑砂",
        "min": 0,
        "max": 1,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 759,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "Galil AR | Black Sand"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/078057d8ed1d9111ddf8646a1437acf2c3547f773a781f073bb0599ca72eb1e5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 卷云",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_15",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 760,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "MP7 | Cirrus"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/59f49d7b314c531565259ccbfe1303a1ae8a736cfab0a242bcacf4dac71b17c7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 铁之作",
        "target_box": "set_community_15",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 761,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "Glock-18 | Ironwork"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1969f0d647f1fefa637faa238129724fff94cdcaf1c2b92a848fed31aed2760b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 聚合物",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_15",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 762,
        "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
        "len": 5,
        "hash_name": "CZ75-Auto | Polymer"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/1ad5e51e3cc2bcfe3f2b0ed9882515f4ff575c25025cf7aba9d5e03aba683e99.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "法玛斯 | 防滚架",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 763,
        "list": "",
        "len": 0,
        "hash_name": "FAMAS | Roll Cage"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/736517ccf145a08705f2115eb184afb3a051e83b54b082f40275239d98a4b8b2.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 霓虹革命",
        "min": 0,
        "max": 0.8,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 764,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Neon Revolution"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/9c4b6217388036967513a2c71ef3b7d2554f32ad0cb8191b764eac9ec1c5b364.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "Tec-9 | 燃料喷射器",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 765,
        "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
        "len": 2,
        "hash_name": "Tec-9 | Fuel Injector"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5f174646f43b6b32187a3ec6b7e8ef228f75f48640b6238d21d45da2b0e4ab1f.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP9 | 气密",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 766,
        "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
        "len": 2,
        "hash_name": "MP9 | Airlock"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5a8b78b79a0cf36a34a945cb516561b18c2bf7912b7f0b173f0d36e0e8e0d0d9.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 席德.米德",
        "min": 0,
        "max": 0.8,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 767,
        "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
        "len": 2,
        "hash_name": "AUG | Syd Mead"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/e82fd6399e8459dd82ed96a83f19f1b0238d2c71cdd65efd58155c584a44c251.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 三巨头",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 768,
        "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
        "len": 3,
        "hash_name": "SG 553 | Triarch"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/337777902c6c2617b6230f22c3fed1cab74b04bf0a7f95be23fd2b8a1cafbbdc.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SCAR-20 | 权力之心",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 769,
        "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
        "len": 3,
        "hash_name": "SCAR-20 | Powercore"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/bfc2e250b956753513dfbe8abbcf2bddecadc10600eabfefeadfddfa770cccff.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 石雕",
        "min": 0,
        "max": 0.44,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 770,
        "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
        "len": 3,
        "hash_name": "MAG-7 | Petroglyph"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/045816b0004d8735939d1b21beab1e1dfd70af3a8227a9d6ddd16348ab29f69c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 鼬鼠",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 771,
        "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
        "len": 3,
        "hash_name": "Glock-18 | Weasel"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/35e79b65c9e95674d0da36fa1e67e11e739e59ada1e64fd0d502f502711aa0cf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 指挥",
        "min": 0.06,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 772,
        "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
        "len": 3,
        "hash_name": "Desert Eagle | Directive"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8a08a4dcdf41cf0bf796914be8a6f2f8e066bbc852bb9c55d77d5eaadb0ade48.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 滑流",
        "min": 0,
        "max": 0.5,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 773,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "XM1014 | Slipstream"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/86944186122afb7041a9cd460d52a3eb637e43a27124c6daab4dfecc9cb36c98.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 简报",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 774,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "UMP-45 | Briefing"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/506adaa21774ef0ca98733950f701f2bfbd8ab323bcafb1c1c50c0a6ef6ad15a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 冷血无情",
        "min": 0,
        "max": 0.8,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 775,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "P90 | Grim"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/53f68919454b2374448f30f98c1699e46c63fdda7a8fed859f902d9186c7f739.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 眩目",
        "min": 0.1,
        "max": 0.65,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 776,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "Negev | Dazzle"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3d040679cbb8ffe0400fdf23754615a161b8594633f8794f854464797c4fc4e4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 通风机",
        "min": 0,
        "max": 0.45,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 777,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "G3SG1 | Ventilator"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d1c5c9a6cb07ae56902712cdc34816de09a954c2af6378f8e73bf8bd0fbea381.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 斯康里娅",
        "target_box": "set_gamma_2",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 778,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "Five-SeveN | Scumbria"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/94343a51e7c5caaac82a00c97797e7c6da4fd42afc8f4a9701ee4afd35174508.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 印花板",
        "min": 0,
        "max": 1,
        "target_box": "set_gamma_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 779,
        "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
        "len": 5,
        "hash_name": "CZ75-Auto | Imprint"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/e559c5b9f04f7fa8b1952a0d1c068ccf8648ae68fc9218bf29b06c69ee93bbab.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 机械工业",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 780,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Mecha Industries"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/69904cb5810b5c10cdfec0906259bfb29690095095079daedc62eaece1aa02f4.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "格洛克 18 型 | 荒野反叛",
        "min": 0,
        "max": 0.54,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 781,
        "list": "",
        "len": 0,
        "hash_name": "Glock-18 | Wasteland Rebel"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1402019baf625f812945a51a1bd79b299dc475049c7c0a2f293528fdd0c254ec.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SCAR-20 | 血腥运动",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 782,
        "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
        "len": 2,
        "hash_name": "SCAR-20 | Bloodsport"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/2ab1d773950634dceb8b8afcfe8bff4fec6cad3001cade442c206dfbf78f44f0.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P2000 | 至尊威龙",
        "min": 0,
        "max": 0.63,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 783,
        "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
        "len": 2,
        "hash_name": "P2000 | Imperial Dragon"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3aae525bbdb4d233bba6ddff8c5bab0f53b214f552bff5f2089667a302f89f9a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 死寂空间",
        "min": 0,
        "max": 1,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 784,
        "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
        "len": 2,
        "hash_name": "M4A4 | Desolate Space"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/edb4643e70cd365a32fad6d43a64bc27c9f2ae1631db0777bd6e1277e915ad94.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "截短霰弹枪 | 聚光灯",
        "min": 0,
        "max": 1,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 785,
        "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
        "len": 3,
        "hash_name": "Sawed-Off | Limelight"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f0cd7c51fd4716d4fd45ae7b8277599fb42aa213a218c5aef0c8b094ae02f6d1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "R8 左轮手枪 | 重新启动",
        "min": 0,
        "max": 1,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 786,
        "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
        "len": 3,
        "hash_name": "R8 Revolver | Reboot"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/24e71354e4391b6aa09e33ddac39e964aada3be2758dbdcb0579c887209c4eeb.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 夺命器",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_13",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 787,
        "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
        "len": 3,
        "hash_name": "P90 | Chopper"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b66baa1946de2cc94b3fcd84d1aa1391ac2773d6e424ff4d95eed10bacbea130.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 火卫一",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 788,
        "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
        "len": 3,
        "hash_name": "AWP | Phobos"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4004e324f3185481b0c6eed3e59cb6f48ab146be8109767556f1e07e946181a5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 贵族",
        "min": 0,
        "max": 0.66,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 789,
        "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
        "len": 3,
        "hash_name": "AUG | Aristocrat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f71f98f9a9e0961b252b87c3dabdc39e3e34c0f3c375bc9bcac1d93aeee02a53.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 冰冠",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 790,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "Tec-9 | Ice Cap"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/369d8afad57301a5d93777043815400d1c3eab7b2f5099178f02e029c8043a11.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 轻空",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 791,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "SG 553 | Aerial"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/38b3e11181d14963699319deed46d26ff8a9a06a8f096771854fdb4fe22f56e3.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 收割机",
        "min": 0,
        "max": 1,
        "target_box": "set_community_13",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 792,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "PP-Bizon | Harvester"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/715f07824fd85b5bf2d3b0a73b2eca325841f73949fc8ee62640b8423ca60c41.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 铠甲",
        "min": 0.05,
        "max": 0.8,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 793,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "P250 | Iron Clad"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f4772ed52caa18cb225088c6131c724806e18b46aa5c990755308e8e4c6a6eb3.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | Exo",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 794,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "Nova | Exo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/be5bc2741f38def4635624b4bf2c0d76d01156034efa8a4de63aa5bc903077e0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 捕猎者",
        "min": 0,
        "max": 1,
        "target_box": "set_community_13",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 795,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "MAC-10 | Carnivore"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7c6257e711c54f27890840b74dd2b5d5487cff08d880c3dca6bb8420095bac49.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 霸意大名",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_13",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 796,
        "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
        "len": 5,
        "hash_name": "Five-SeveN | Violent Daimyo"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/dd7467aa7ca1d4b9960f434503a786113c4c5f14dd5a0676b08577125c88a909.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 女火神之炽焰",
        "min": 0,
        "max": 0.99,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 797,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Chantico's Fire"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/edf5cd192003b0ec3778443df97bc6d69162c9d71225fa64f76b1f21417aaa46.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "PP-野牛 | 阿努比斯之审判",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_12",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 798,
        "list": "",
        "len": 0,
        "hash_name": "PP-Bizon | Judgement of Anubis"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/c3738c4f9b98f22a97f36cb5e9353926c4c65659e20974c142494d8f391ee707.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "UMP-45 | 野蛮剑齿虎",
        "min": 0,
        "max": 0.77,
        "target_box": "set_community_12",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 799,
        "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
        "len": 2,
        "hash_name": "UMP-45 | Primal Saber"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/72a320726a98e4ef6f527ad1de75bfdbe44bd3a7dc7ed77daed7007a8149e750.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 二西莫夫",
        "min": 0.1,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 800,
        "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
        "len": 2,
        "hash_name": "P250 | Asiimov"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f9856d5570751e408015d766671b1659aeb3eeaf6266f5d3c46a8d90aee5ddd5.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 燕群",
        "min": 0,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 801,
        "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
        "len": 2,
        "hash_name": "AUG | Fleet Flock"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/053eb57ec8beeacf243041fdf8253c02024a37067e342d66871cbaadb959714e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 西装革履",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 802,
        "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
        "len": 3,
        "hash_name": "XM1014 | Black Tie"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1dfc1ef5059a81c89039cffc288872df2d044440fa9f3e135beb964858e76586.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 战火重燃",
        "target_box": "set_community_12",
        "min": 0,
        "max": 0.43,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 803,
        "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
        "len": 3,
        "hash_name": "Tec-9 | Re-Entry"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/89b4ab9fbdf61544af4e9a8a07621cab8d0b499f79d1dcd8839506427c0122c2.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SSG 08 | 幽灵战士",
        "min": 0,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 804,
        "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
        "len": 3,
        "hash_name": "SSG 08 | Ghost Crusader"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d95b8f261ed54b2af678ba5d5540c43e94452e6ff7e406863372a0e6926e181a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 火线冲锋",
        "min": 0,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 805,
        "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
        "len": 3,
        "hash_name": "Galil AR | Firefight"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/dec2b3cfc58042ae5073766c2b1cd8f84e6fd036e0cff9fee9b2eb295ff9365f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 红鹰",
        "min": 0,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 806,
        "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
        "len": 3,
        "hash_name": "CZ75-Auto | Red Astor"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/42b12103273f8c0f2c7b94ecdd517ba7c3c4c47adafc70328b1f3f744ae19635.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 擎天神",
        "min": 0,
        "max": 0.81,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 807,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "SG 553 | Atlas"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d9a936cf20d3ec69c70e89955680dcf8ae26d9ca1050578dac4f511851e6afa1.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 破铜烂铁",
        "min": 0.4,
        "max": 1,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 808,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "Sawed-Off | Fubar"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a62f381e8311247257552e7a8f0564d5366fbc9589192fa564b3df94729185f2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 海洋",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 809,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "P2000 | Oceanic"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/49895fbb60dcbe73cc788e582b3d331db4b68cfc54a10506883338ec1450cc8d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 生化泄漏",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_12",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 810,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "MP9 | Bioleak"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f2a86b3c399ab0faa812f5a6f12f5a69a1fb81ed82d10a855211de7ce401a0df.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 鬼影",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 811,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "M249 | Spectre"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/95c82e5bd8b2435fcb0a287fb56dd949617d1bdb1efab121a116d1713fc970ac.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 橙光冲击",
        "min": 0,
        "max": 0.52,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 812,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "G3SG1 | Orange Crash"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a1e2f5661953b68e4d597523fd9e18dc4108750bfeff2bd25a9247ce21fa5bb0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 通风机",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_12",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 813,
        "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
        "len": 5,
        "hash_name": "Dual Berettas | Ventilators"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/eb6105e93ff298b9d47235514773e68ded66688c6d6c41554ae619d56446ee3f.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 战场之星",
        "min": 0,
        "max": 0.64,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 814,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | The Battlestar"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/1868afb4e698d11a950a1e250c84a2b51ba7c85d366e102ac0e42356bbd4a194.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 燃料喷射器",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 815,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Fuel Injector"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/4a2a99a9116a5efe2d89d8f0a2dd023870e242abb9b3c32482d8f2f9f7f18479.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "新星 | 暴怒野兽",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 816,
        "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
        "len": 2,
        "hash_name": "Nova | Hyper Beast"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/2ea1930899d24adf17ff0e090bc4df03088353de60c5ff918aae896dee8b229b.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 大佬龙",
        "min": 0,
        "max": 0.76,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 817,
        "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
        "len": 2,
        "hash_name": "Desert Eagle | Kumicho Dragon"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/2b10ed284783631da75c98f53e8424380076644837924b71c00e485a18ec84fb.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 精英之作",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 818,
        "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
        "len": 2,
        "hash_name": "AWP | Elite Build"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d6af3302796ac2def630bb439be53dd9aa2a7154686f5e1912e8c5e27bec789b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 帝国",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_11",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 819,
        "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
        "len": 3,
        "hash_name": "MP7 | Impire"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/cb83269ae423a9ca5f0f2f8bdd44c24521881c1d31187c5bfe8572cfc9ab481a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 禁卫军",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 820,
        "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
        "len": 3,
        "hash_name": "MAG-7 | Praetorian"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a1bc7ed3320cefcb4611d8db1d011b9781c2d49f7dcc39feeecb5feec8723b6a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 皇家军团",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 821,
        "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
        "len": 3,
        "hash_name": "Glock-18 | Royal Legion"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d81b730e1d384bbb7fe4c1989a1f9a66f15afe6fa94cf60ef5d8c7a92fa76da6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 三位一体",
        "target_box": "set_community_11",
        "min": 0,
        "max": 0.61,
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 822,
        "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
        "len": 3,
        "hash_name": "Five-SeveN | Triumvirate"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/736e42abda962dbe240308a962a43545bd2d96ca5ba12df66e6c15c603754646.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 元素轮廓",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 823,
        "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
        "len": 3,
        "hash_name": "FAMAS | Valence"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8184e1d6461f779277644304be761ac4f19bae107508556a69c31dca994c3326.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 铅管",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 824,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "USP-S | Lead Conduit"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7393af13e50b392363fb61a9ed1d762dbb19f9305cc590cb9f0951b855baa3c5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 贾姆比亚",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 825,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "Tec-9 | Jambiya"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/96ab25768c9293cd8fc632a04db68459f3161840ae9f617e3713775bfa45fb65.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 通灵者",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 826,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "SSG 08 | Necropos"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d788aec31e1d59e6d17f91ab1e040e3d18621b6e2fa408670d50bc12804b07af.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 青金鳄皮",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_11",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 827,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "MAC-10 | Lapis Gator"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e6fb42f8ad51f01777583277d1e0ecbdf5ff2ed2ea96e0d274a12c12eec7dd95.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 卡特尔",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 828,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "Dual Berettas | Cartel"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2125eaa3109a322d1ae0bdf849a2de3216123764a912eeb1a869faf7f2300316.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 透光区",
        "min": 0,
        "max": 1,
        "target_box": "set_community_11",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 829,
        "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
        "len": 5,
        "hash_name": "PP-Bizon | Photic Zone"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/9cb964057db143d329a6a52d4825df2a41aae8f408d1959e94a69cf31b142f5c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "R8 左轮手枪 | 渐变之色",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 830,
        "list": "",
        "len": 0,
        "hash_name": "R8 Revolver | Fade"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/3a3f7801bd78968fe902c3208f1904e3d32633da14043e3d2de6471f18b88a78.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 皇家圣骑士",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 831,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Royal Paladin"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/bb5cf94b421e749112d3682e5e2fbac1203e458f996d0ea188d58c5481f4fdd6.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 精雕木刻",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 832,
        "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
        "len": 2,
        "hash_name": "P90 | Shapewood"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d70225e1ffb9dd63f79718e8611af2f926c7d97bb3e3dc0d0f5ef72664589017.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "G3SG1 | 行刑者",
        "min": 0.14,
        "max": 0.85,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 833,
        "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
        "len": 2,
        "hash_name": "G3SG1 | The Executioner"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/e7297212c7aee6fac1e85a66d15181010562e2c6b0eba839afedb9ba0a5f9221.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 混沌点阵",
        "min": 0,
        "max": 0.67,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 834,
        "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
        "len": 2,
        "hash_name": "AK-47 | Point Disarray"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a8ccf0a5b30fc037a206122d69fcc8cd533c1c4a65ba00f1f949255e7732085e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 特克卢喷射者",
        "min": 0,
        "max": 0.65,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 835,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "XM1014 | Teclu Burner"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d7c9de8d63e993c732b93edbce99c61c8030cdf35bda2e8554c8227ac47923d2.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 坍雪寒裘",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 836,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "Tec-9 | Avalanche"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/06d7fc9d7028543472af61c4c3d88cc92ba4543060097ec0b433dd15eec394a9.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 豹灯蛾",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 837,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "SG 553 | Tiger Moth"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/dcc4ff56978e15b6a0a2bc35a995290a0caa9e6621a9503602b6a68c3692fdf2.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "内格夫 | 动力装载机",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 838,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "Negev | Power Loader"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8a5951999d6c39e36c8a792dc12f33766f3afe7fa40782b2501520e793ff4361.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 朝枪夕拾",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 839,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "Five-SeveN | Retrobution"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f231f8aa217fe4a3dcfd140e76d284b7de73e410f2e5a7e1cff1234f4242ea45.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 核燃料棒",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 840,
        "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
        "len": 3,
        "hash_name": "PP-Bizon | Fuel Rod"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/87e6d548adf22ad59550bb646f146a06db7245d4ace406eaaec7b467d8e89a54.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 丛林爆发",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 841,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "SCAR-20 | Outbreak"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/dd7e5e658b641664a900b01c5efbde474a37ce0ff0001dc68fd3930b7cc159f2.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 弄臣之颅",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 842,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "Sawed-Off | Yorick"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f2056cae8a21e1f02f79ff1273b4981cf9c38c6e1fccbbe782141ba65331e22a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 至高帝皇",
        "min": 0,
        "max": 0.2,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 843,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "P2000 | Imperial"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ee702fc9d1c4c228b6be096124e30c90b36161f7edf1cc69a5d1186ff5346f49.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 科林斯遗产",
        "min": 0,
        "max": 0.44,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 844,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "Desert Eagle | Corinthian"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/78392641b1771c40e48729cf1e7b3c61d37f2d590168a2afa0ca25553a486287.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 弹跳线条",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 845,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "AUG | Ricochet"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0f2dd9314d2a9b49fe652c4352f69f48a78040db6765318354b178a415d6c3cd.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "R8 左轮手枪 | 深红之网",
        "min": 0,
        "max": 1,
        "target_box": "set_community_10",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_revolver",
        "itemid": 846,
        "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
        "len": 6,
        "hash_name": "R8 Revolver | Crimson Web"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/9c866dee1298d9756762990fe1040d91519ee80847a996485a570f7479acd852.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "USP 消音版 | 枪响人亡",
        "min": 0,
        "max": 1,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 847,
        "list": "",
        "len": 0,
        "hash_name": "USP-S | Kill Confirmed"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/ba92f1fecbcb57f8aedcacd029552518e4ce22c7d3d5c313c164cec1b8da3809.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 金蛇缠绕",
        "min": 0,
        "max": 1,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 848,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Golden Coil"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/975be2b5842e63d6d802eaf21f88f2f5ec116f2aca7b10592212b46a2564ddcf.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SSG 08 | 巨铁",
        "min": 0,
        "max": 0.64,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 849,
        "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
        "len": 2,
        "hash_name": "SSG 08 | Big Iron"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b5406ce180aea5f76678657e23e874b99b44d90fa37cc34bfe48d4d1e171cc9b.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "G3SG1 | 弗卢克斯",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 850,
        "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
        "len": 2,
        "hash_name": "G3SG1 | Flux"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/db7f29f2edd512ce2c2465f2a698a8b7b1b68828b0528dd0a093ecd87b06137f.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 前线迷雾",
        "min": 0.02,
        "max": 0.87,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 851,
        "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
        "len": 2,
        "hash_name": "AK-47 | Frontside Misty"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/8bf0d8bb77675c99c9af940386464522c842242e60627c84d0fab118f295aa93.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 翼击",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 852,
        "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
        "len": 3,
        "hash_name": "P250 | Wingshot"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6549d33d16c062abc5b5c33cbdd23c1e4c5f43528e3fc6f208ff7a60e39c1a5a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 速递",
        "min": 0,
        "max": 0.62,
        "target_box": "set_community_9",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 853,
        "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
        "len": 3,
        "hash_name": "MP7 | Special Delivery"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7cb21a23cbdc821cd0309ad301cdca714479b411642f7d2cf0310756acb00c06.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M249 | 星云十字军",
        "min": 0,
        "max": 1,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 854,
        "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
        "len": 3,
        "hash_name": "M249 | Nebula Crusader"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/13bac5fc1d3f43e80de15f073c2ac6656b3cc079aff00721224570d95dca8ee0.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 冷石",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 855,
        "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
        "len": 3,
        "hash_name": "Galil AR | Stone Cold"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3697c962df8eabac36a49031880a7554b544418674b425c7f303cc6eec9546f6.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 斯康里娅",
        "min": 0,
        "max": 1,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 856,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "XM1014 | Scumbria"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/21161ce895f2c1beb7091a7658bef67528ca6a8fa83d3b10340a1adc4414d17c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 绿色陆战队",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 857,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "SCAR-20 | Green Marine"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9109f70d9028ae2ec0aaf2aea0d20a354d8a2000251737973580f7ffc1d0592e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 钴核",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 858,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "MAG-7 | Cobalt Core"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2c8b29f8af493eee509250b0fb3f72f7638d844a1c631d0e548ee5d042a84d7b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 冉吉",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_9",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 859,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "MAC-10 | Rangeen"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ae2d7ec02694fc8f4172a7abf91daf2099e8e9af4a74e84cf08bd8f8b80bd548.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 幻影冥魂",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 860,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "Glock-18 | Wraiths"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1c671f9fda369fdfa90d7a85a5f103a3120fe4c9dcea4ef037f4599152c5a8eb.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 幸存者 Z",
        "target_box": "set_community_9",
        "min": 0,
        "max": 0.6,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 861,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "FAMAS | Survivor Z"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d96a3406efde6f62f58a5c09737f409357aeb55588df5528d91097720b77b3b1.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 龙之双子",
        "min": 0,
        "max": 1,
        "target_box": "set_community_9",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 862,
        "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
        "len": 4,
        "hash_name": "Dual Berettas | Dualing Dragons"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/967ddc9ff9fcbacbb95b9e1b1126d5e37721461a3aa7f4fc5691850445be9c5c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 暴怒野兽",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 863,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Hyper Beast"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/a97265768a0231abb4675ab4b338390f5d27e514d711695edb3ee7ad0734ebb7.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 深海复仇",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 864,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Aquamarine Revenge"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d45304c4ad7e0b28c4a43392ce84d7e62db5d70c2c09891bac419172771e9d08.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SG 553 | 次时代",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 865,
        "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
        "len": 2,
        "hash_name": "SG 553 | Cyrex"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/a9b17b530a31fc6c33bb2c7b15ecb09ed6d0d1d8e10cf0e0e625e9877a3873b3.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "MP7 | 复仇者",
        "min": 0,
        "max": 0.32,
        "target_box": "set_community_8",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 866,
        "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
        "len": 2,
        "hash_name": "MP7 | Nemesis"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/64b1b5483833b79031e84d28120b56b3f0eee9e963c5fe61a392b37eca3be511.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "CZ75 | 黄夹克",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 867,
        "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
        "len": 2,
        "hash_name": "CZ75-Auto | Yellow Jacket"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f3c1f1e6bf6eac68d3e24d14967f0ff425aa66c149f598e0dad3a84351920f57.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P2000 | 手炮",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 868,
        "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
        "len": 3,
        "hash_name": "P2000 | Handgun"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7fe179eaa537c96908155842e6991ef1fad6d956047c67f95777cdcf58d61289.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "内格夫 | 大嘴巴",
        "min": 0.14,
        "max": 0.65,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 869,
        "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
        "len": 3,
        "hash_name": "Negev | Loudmouth"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/88eec601f7de7897d069cb6a0b641f798ab6165658339a991f460c5169957bdf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 红宝石毒镖",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_8",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 870,
        "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
        "len": 3,
        "hash_name": "MP9 | Ruby Poison Dart"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ad0c1107b482d90afcb04485f3acb93fd0a37081f680e9b138087d3de3889a4d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 杀意大名",
        "target_box": "set_community_8",
        "min": 0,
        "max": 0.52,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 871,
        "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
        "len": 3,
        "hash_name": "M4A4 | Evil Daimyo"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/1be775aa8e36a7e785de2ff0bc866cd47977957a5bce62dbbf879a573c451e0c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 神经网",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 872,
        "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
        "len": 3,
        "hash_name": "FAMAS | Neural Net"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/26c3b9514e55031c7436ecf7198d8ede9cd37cbb2db0a69f966e9ed0bbdf3136.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 力矩",
        "min": 0,
        "max": 0.46,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 873,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "USP-S | Torque"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/92bfaa3cdc439217edcba5fc69818b4675fbd874ca50d7a2b8f91395d6b236c4.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 暴乱",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_8",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 874,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "UMP-45 | Riot"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f2e7f471fb35778aae6e49272c7c50e55230091050200c0f59723540bd546e24.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 精英之作",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 875,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "P90 | Elite Build"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/872ffb27e8f095c47482f47979ca44f0fde37f3d215100b2e5b2a36049f94b3a.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 游侠",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 876,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "Nova | Ranger"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/58745ce3c9964fe731ea3d0b936a763431cd0205ef763dad5ec8a090d905f64b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 本生灯",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 877,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "Glock-18 | Bunsen Burner"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0445cc64babfced848357e6149827040578908086cccc3aed06a2db673e4323e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 火箭冰棒",
        "min": 0,
        "max": 1,
        "target_box": "set_community_8",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 878,
        "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
        "len": 5,
        "hash_name": "Galil AR | Rocket Pop"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/b0307543a56202e20bec05e2588d52d2fe14ef22addbc2566f0b0745980244ff.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "MAC-10 | 霓虹骑士",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_7",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 879,
        "list": "",
        "len": 0,
        "hash_name": "MAC-10 | Neon Rider"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/5dfc73ce6100cb615ba3911261f868a7b3330d1df0bc3362502f0944ce5baa99.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 暴怒野兽",
        "min": 0,
        "max": 1,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 880,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Hyper Beast"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d17de8c8090400c32f235da681b50a1a825ee1259c0a4eefa35a4553439687f7.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 灯神",
        "min": 0,
        "max": 1,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 881,
        "list": "\nMAC-10 | 霓虹骑士\nM4A1 消音型 | 暴怒野兽",
        "len": 2,
        "hash_name": "FAMAS | Djinn"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3657646e7a9cd972a9e2147c06fad95e885c2ee0591f6d0588ad3a63388e5b59.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "加利尔 AR | 经济",
        "target_box": "set_community_7",
        "min": 0.1,
        "max": 0.85,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 882,
        "list": "",
        "len": 0,
        "hash_name": "Galil AR | Eco"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/596ebfa198aac5049e976bef257927f4daea38df74624a835b086ff450e830c1.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "FN57 | 耍猴把戏",
        "min": 0.1,
        "max": 0.9,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 883,
        "list": "\nMAC-10 | 霓虹骑士\nM4A1 消音型 | 暴怒野兽",
        "len": 2,
        "hash_name": "Five-SeveN | Monkey Business"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/039c1a41b11aed9007c6927ce5a5a82c17c7eda158fa82794f7aee83fc132526.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "UMP-45 | 车王",
        "min": 0.25,
        "max": 0.35,
        "target_box": "set_community_7",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 884,
        "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
        "len": 3,
        "hash_name": "UMP-45 | Grand Prix"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/186c2a9712ec96a4b79fdc43060d6ca3912eecdb9dc77f608c78f4c8a835b439.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 先驱",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 885,
        "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
        "len": 3,
        "hash_name": "CZ75-Auto | Pole Position"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c12512a6feaf0de7c73ce090fbcc0ac9efe0ef2b38ee42d36a21f0ddbcb0ab3b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAG-7 | 炽热",
        "min": 0,
        "max": 1,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 886,
        "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
        "len": 3,
        "hash_name": "MAG-7 | Heat"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/129f24d29a8febd1f7c6df17e7d8b5e533aebb745469f41b7275cdc79301b8e6.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AWP | 蠕虫之神",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 887,
        "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
        "len": 3,
        "hash_name": "AWP | Worm God"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1e2f4d447b24bd2d1db92ae874622c3d90ef7ee4182827fc1f46aa97be1fb87e.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "截短霰弹枪 | 千纸鹤",
        "min": 0,
        "max": 0.55,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 888,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "Sawed-Off | Origami"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b5622bec477706d7e397855d03177668eddaa784a441b747f5011dbcd595ea19.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 无畏战神",
        "min": 0.1,
        "max": 0.2,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 889,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "Negev | Man-o'-war"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f6c98d936528e2876d0d60e642aaf800529a5544fcac78a7bb7fb217868c7546.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 元素轮廓",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 890,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "P250 | Valence"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a99db206b27125566d38fad76f1a4e3e3ca7ac09c3b13a6381cea0591664305d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "沙漠之鹰 | 青铜装饰",
        "min": 0,
        "max": 0.46,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 891,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "Desert Eagle | Bronze Deco"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/64c5f65a0f68c0ee06b64944a875645177c6d59e58c382f019406f878c3b42b8.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 装甲核心",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_7",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 892,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "MP7 | Armor Core"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7b6df330b1beca30256d3b91ae6df9cf74a9eb405c8dbad9573a000b559cbe18.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AK-47 | 精英之作",
        "min": 0,
        "max": 1,
        "target_box": "set_community_7",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 893,
        "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
        "len": 4,
        "hash_name": "AK-47 | Elite Build"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/4fca8b881f96dcabc0f7dff05d991655559015e25b7684c4102e4e6603c1cbb7.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "加利尔 AR | 喧闹骷髅",
        "min": 0.35,
        "max": 0.85,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 894,
        "list": "",
        "len": 0,
        "hash_name": "Galil AR | Chatterbox"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/408d6e4e0d544f469562c55f2091c2a292eafbd16fa859f0eae71645e366b65f.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 无畏战神",
        "min": 0.1,
        "max": 0.2,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 895,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Man-o'-war"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/fc1590de2a1521a42bdecb44bc8f572d38795c090a10a72fc261ebd3ae6667b0.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 死亡轮回",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 896,
        "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
        "len": 2,
        "hash_name": "P250 | Muertos"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/48fc6cf8e4e0c0bd1bb4126feac5c7049079d22ea1c1ee48b18541cab782d9a8.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A4 | 龙王",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 897,
        "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
        "len": 2,
        "hash_name": "M4A4 | 龍王"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f802ef3307d38c8c4998ff9e048fca6ae85af7cfdd908a229685b7f3f6aa70ed.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 卡特尔",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 898,
        "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
        "len": 2,
        "hash_name": "AK-47 | Cartel"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4c6ae35165fd978b1d3d7b9e93b19f607c720d5876802f0de191f027d42ed37d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "截短霰弹枪 | 祥和之翼",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 899,
        "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
        "len": 3,
        "hash_name": "Sawed-Off | Serenity"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/292bb2f7bdf187a9280086683d3de176f67ecbc2efdaad03fe76e1824e9e7754.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 孔雀石",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_6",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 900,
        "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
        "len": 3,
        "hash_name": "MAC-10 | Malachite"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/cd8a07e7a054628d5d475204f333a3d344b99ac83854159244c76e5ee235d1d5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 纳迦蛇神",
        "min": 0,
        "max": 1,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 901,
        "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
        "len": 3,
        "hash_name": "Desert Eagle | Naga"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/5f0628e241252c364d3a9dfb1f0c1696cda01d1a04b1c87df708d4d2e9552617.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 都市冲击",
        "min": 0,
        "max": 0.47,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 902,
        "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
        "len": 3,
        "hash_name": "Dual Berettas | Urban Shock"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c2cfd8980ac8100891414bea37e7ec0bba3e12c03bc7c01ca9c5b455e6e583f0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 剧毒水银",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 903,
        "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
        "len": 4,
        "hash_name": "XM1014 | Quicksilver"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2771be65e37c79948444e6f3652880a37a928ed58a485dccde58efaf2047725c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 蓝洞",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 904,
        "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
        "len": 4,
        "hash_name": "SCAR-20 | Grotto"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ab2783467287fc23d527ca5cc7bc9ae910b964121924ee2a564b670b9a9b9389.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 致命毒药",
        "min": 0,
        "max": 1,
        "target_box": "set_community_6",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 905,
        "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
        "len": 4,
        "hash_name": "MP9 | Deadly Poison"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/15f3139eda1b49d5f40d5e05832f3a53522f459c8c4b97a07b521bc62d8fa752.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 系统锁定",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 906,
        "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
        "len": 4,
        "hash_name": "M249 | System Lock"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e2dd91b91657a3cbd9712d8566b4e74a566d82405ae012b37688956697af1105.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 亡者之寝",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_6",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 907,
        "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
        "len": 4,
        "hash_name": "Glock-18 | Catacombs"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/5b77ba94846777c8266d83437699603ce24b4728d79994828aaa587072d46079.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "P2000 | 火灵",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 908,
        "list": "",
        "len": 0,
        "hash_name": "P2000 | Fire Elemental"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/a79b084e50b900267cf5a8c9ae3f6a65a169a365e77cf2e730465025ca282cd6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 荒野反叛",
        "min": 0.05,
        "max": 0.7,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 909,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Wasteland Rebel"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/4f88a76e6c89086775bf7962329e47ab2b9d4a1da061c251a2c8055dfe7df1d1.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "XM1014 | 宁静",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 910,
        "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
        "len": 2,
        "hash_name": "XM1014 | Tranquility"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5f66613ae20770d0958102c4bd1d06139be2df10323b164b88ae436df140c0e9.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SCAR-20 | 心脏打击",
        "min": 0,
        "max": 1,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 911,
        "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
        "len": 2,
        "hash_name": "SCAR-20 | Cardiac"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f597d716145a279c6e5652983890fda3c51db5774a2818cb6c5f0dc74618eea2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 卡特尔",
        "min": 0,
        "max": 0.75,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 912,
        "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
        "len": 2,
        "hash_name": "P250 | Cartel"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/25227dc11d332c01835f054693565cd0cc8281b6b419ba113d93f652f7fcb2dd.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "截短霰弹枪 | 路霸",
        "min": 0,
        "max": 1,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 913,
        "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
        "len": 3,
        "hash_name": "Sawed-Off | Highwayman"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/3b062941931e7427ad387f7509853b4c0a56ea2878a73be8dcaf9f7508dc9b9c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 狮鹫",
        "min": 0,
        "max": 0.8,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 914,
        "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
        "len": 3,
        "hash_name": "M4A4 | Griffin"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/d585b7cb4ea0509183363952bdd1ade0952849bb891a1940203ec8b6c86ef320.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 翼蜥",
        "min": 0,
        "max": 0.68,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 915,
        "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
        "len": 3,
        "hash_name": "M4A1-S | Basilisk"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/216571e710555b551471d6b6824fae6648114e6611127b28048d2afafcef2c9b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 粉碎者",
        "min": 0.02,
        "max": 0.25,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 916,
        "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
        "len": 3,
        "hash_name": "Glock-18 | Grinder"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/43233bebf58832078acdabec418c3fc92350b0de5c21a2cd75d355aef4481dc7.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 迷幻",
        "min": 0.06,
        "max": 0.35,
        "target_box": "set_community_5",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 917,
        "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
        "len": 4,
        "hash_name": "UMP-45 | Delusion"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c4f7925936fbcc00190cb2edfd669247d0a6bc39bec17a0014c4c2e766ee661b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 都市危机",
        "min": 0,
        "max": 0.25,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 918,
        "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
        "len": 4,
        "hash_name": "Five-SeveN | Urban Hazard"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7f9e744c5ba22a242f8022733bda6273923deaf9dd6bcf178f56a4433cd25332.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP9 | 飞驰",
        "min": 0,
        "max": 0.46,
        "target_box": "set_community_5",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 919,
        "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
        "len": 4,
        "hash_name": "MP9 | Dart"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1558eb7c1422cff6facf60bde2ac975b4182f770876fc6d4531467f9a5e2fd72.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 灼烧之痕",
        "min": 0.06,
        "max": 0.49,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 920,
        "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
        "len": 4,
        "hash_name": "MAG-7 | Firestarter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c600ac9ebe55ea610381a12600912a7f91ddbcae39d81a32d8ef05270e1760c0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 黑暗豹纹",
        "min": 0,
        "max": 0.25,
        "target_box": "set_community_5",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 921,
        "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
        "len": 4,
        "hash_name": "G3SG1 | Murky"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/4b9c5a619d1a1f0131ed7fdc1b9c34174bb0d3655a1783646b528c42c58d1526.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 美洲猛虎",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 922,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Jaguar"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/3ca9729cf6eeb7400680df37c96d7aabdabf07772ac44bb0dc5cc6c8309bbbc2.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 弹雨",
        "min": 0.02,
        "max": 0.46,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 923,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Bullet Rain"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f882a4c57091f0819f7631de178b2ba879586c35b79a7abe373c7255da7acea6.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P2000 | 珊瑚树",
        "min": 0,
        "max": 0.3,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 924,
        "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
        "len": 2,
        "hash_name": "P2000 | Corticera"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5912f7241ff1d64d04dd9a3523d0456200a940b878715bf8621996b0055d429f.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 珊瑚树",
        "min": 0,
        "max": 0.3,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 925,
        "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
        "len": 2,
        "hash_name": "AWP | Corticera"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f891a5a1ccedfb08d223549476caaeef0b5a1ab2ce12e61df4bd3b10b5ae01bf.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "新星 | 樱花之绚烂",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 926,
        "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
        "len": 2,
        "hash_name": "Nova | Bloomstick"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/d30e42474b4f2a959c88319a8c7c59f46b73aa12780cd778767b09ee194263be.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AUG | 孟加拉猛虎",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 927,
        "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
        "len": 2,
        "hash_name": "AUG | Bengal Tiger"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/03ad2abb89c4dacd46d5b0823f0b989d4d021a3e2d16709170bbd2609ccc391f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 深红之网",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 928,
        "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
        "len": 3,
        "hash_name": "Desert Eagle | Crimson Web"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c52fc0224957c395b91f32f3acc725112bf60d2ab8e2b5ef75ff6faf4d02405a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 钢铁禁锢",
        "min": 0,
        "max": 0.2,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 929,
        "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
        "len": 3,
        "hash_name": "Glock-18 | Steel Disruption"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c76d7f7f795eea8d0d0a31beb8d2b4af509f0db7f2e9aea426a42c20a51db3ef.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP7 | 海之泡沫",
        "min": 0,
        "max": 0.08,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 930,
        "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
        "len": 3,
        "hash_name": "MP7 | Ocean Foam"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/04b98bdffb613dc2f6cee38167b728dd3486c9bbd7335fdb81fac20acff9176d.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 病毒危机",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 931,
        "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
        "len": 3,
        "hash_name": "P90 | Virus"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/21102ae211052f6b3f473a53113e880cc5a82c60998fde4aa080c7649176d8ce.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 水蓝条纹",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 932,
        "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
        "len": 3,
        "hash_name": "PP-Bizon | Blue Streak"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e31ba5fac37c04eeb2421a91119c2085137ad02e71f5d09afb1afac32032955b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "XM1014 | 血红巨蟒",
        "min": 0.08,
        "max": 0.5,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 933,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "XM1014 | Red Python"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/327c07e2abcb05733b14097b24d096fbb40a46c5fb5d8fa492fff9f3b52b9e58.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | *哒哒哒*",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 934,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "Negev | Bratatat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a8299d6124b29172686d7cad5d8996ec9bf02cff42df97c457c89502bcaab04d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 蓝巢",
        "min": 0,
        "max": 0.4,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 935,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "CZ75-Auto | Hexane"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ed7772499c4245330c9adae8024c7152d8576cdb714498a3f64cc98d8735da41.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 血虎",
        "min": 0,
        "max": 0.3,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 936,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "USP-S | Blood Tiger"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/377a3172a22c4269acd8c49d4f2787470f7cae0d1de55dd2c788dfc9c4a0da5b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAC-10 | 致命紫罗兰",
        "min": 0,
        "max": 1,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 937,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "MAC-10 | Ultraviolet"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e72efc0f21d71d43e44ef63970f5426a6aa519b3643e89c6b843548d7342c3b1.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 黑水",
        "min": 0.1,
        "max": 0.26,
        "target_box": "set_esports_iii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 938,
        "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
        "len": 5,
        "hash_name": "SSG 08 | Dark Water"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/7c9d9399d9eee6654e65e713e62f91630119bedb0f55a6b2759e467c272124e5.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A1 消音型 | 次时代",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 939,
        "list": "",
        "len": 0,
        "hash_name": "M4A1-S | Cyrex"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/1de56a133102e8dd36f69082d1f298cdd34d76b10a4db00c87b5301b823ab5d3.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "P90 | 二西莫夫",
        "min": 0,
        "max": 0.92,
        "target_box": "set_community_4",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 940,
        "list": "",
        "len": 0,
        "hash_name": "P90 | Asiimov"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/b3166213c5de749d1c9f4378b54ea14332a612862494f00bd2740582df95c97a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "格洛克 18 型 | 水灵",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 941,
        "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
        "len": 2,
        "hash_name": "Glock-18 | Water Elemental"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1c4b13241fd1bdd02b38d224897399783f81cf60ce32fca32e3a11ad2d9ee67a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "FN57 | 狩猎利器",
        "min": 0,
        "max": 1,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 942,
        "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
        "len": 2,
        "hash_name": "Five-SeveN | Fowl Play"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/aa6d0c8fac011676fed915a661fa246e18bd5a36842afe4e9df333073643c43c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 阴谋者",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 943,
        "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
        "len": 2,
        "hash_name": "Desert Eagle | Conspiracy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2b5babcb6c256d13a22e4cddfbe57d1b442abc0d62f8718767b37bfc3a5747fa.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 超新星",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 944,
        "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
        "len": 3,
        "hash_name": "P250 | Supernova"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6c49864fce11edf15217ee66f0cc5a5108e5f6ec6244ceeec0f9b1e076b2cc25.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 锦鲤",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 945,
        "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
        "len": 3,
        "hash_name": "Nova | Koi"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/70d2281d2a244322132e91b777c82e3f7d57cfa34fb29c14958eb79b49c1c4a5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 猛虎",
        "min": 0,
        "max": 1,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 946,
        "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
        "len": 3,
        "hash_name": "CZ75-Auto | Tigris"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/755d40dce21c9d4e22facec3bfe2d877c4b123dd72f9b6178c1f15814c03203c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 死亡主宰者",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_4",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 947,
        "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
        "len": 3,
        "hash_name": "PP-Bizon | Osiris"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/000e0d367d2d960b3fde5a3370780c4b54f46212b621f70629cb3ea8a7faa643.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 迷之宫",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_4",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 948,
        "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
        "len": 4,
        "hash_name": "UMP-45 | Labyrinth"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/09ec9be89aeaf93d2283d95fdef5eed6865b4dd8529428116b81394a3e612ae9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 无尽深海",
        "min": 0,
        "max": 1,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 949,
        "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
        "len": 4,
        "hash_name": "SSG 08 | Abyss"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f2eee2883f31012f7fb1f3d11f44dac458a6502eb8835b0abcaed6da84a4e946.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 乳白象牙",
        "min": 0,
        "max": 1,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 950,
        "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
        "len": 4,
        "hash_name": "P2000 | Ivory"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7ce9137c0769a6a8b43e18eda479e7819089d2ec34088f1958cdc594587cb603.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 沙漠精英",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_4",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 951,
        "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
        "len": 4,
        "hash_name": "Negev | Desert-Strike"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/9517e99913db87e96d664a1d14a575bb4f4492dee4f22ace5b50184833e79a08.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 都市危机",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_4",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 952,
        "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
        "len": 4,
        "hash_name": "MP7 | Urban Hazard"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/b6b5618d574b1aeb1b64fe0b7f17ace5fde8fa63cbc4605120225fee0b1668ba.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 沙漠精英",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 953,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Desert-Strike"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/97ff991bc1cf10f9c4988154e6c8d4d4f4567cf103c8914131f19a8e117dc04c.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 火神",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 954,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Vulcan"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/f9fb6cc3a5ef1657e1aa6c6f1dfe5e65d09738f317db265e354f4f9dd455ad3d.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 猎户",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 955,
        "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
        "len": 2,
        "hash_name": "USP-S | Orion"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/ceb597038f9a5e2039f8432124e1db1d6b9baea35a366deb6bbaf0a22f4b8f27.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 凯门鳄",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 956,
        "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
        "len": 2,
        "hash_name": "USP-S | Caiman"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/3414b1c06abaa04b74f873406eac31d22e843d9f1ab89a9180ca8e69eb9ece76.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "SCAR-20 | 次时代",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 957,
        "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
        "len": 2,
        "hash_name": "SCAR-20 | Cyrex"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/758c6f2862e2f0a8878a8cdc87a854894e06cf3463b48b23216541ca8eb6640d.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 原子合金",
        "min": 0,
        "max": 0.9,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 958,
        "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
        "len": 2,
        "hash_name": "M4A1-S | Atomic Alloy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b4e7f92684266c21097bed7ac8034aa117e5a634cff31760ae1ca633951a51f1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 诅咒",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 1102,
        "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
        "len": 3,
        "hash_name": "MAC-10 | Curse"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a3b5360f9cfb6dce84c18765a487720702eb7c8e8f72c929d5199090a3aa8991.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 烧尽",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 959,
        "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
        "len": 3,
        "hash_name": "MAC-10 | Tatter"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0f38257faafa12db422905a66af11e5c01a8015cdfa3f3c27e2ce2bf15c7a1f1.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "XM1014 | 天空守卫",
        "min": 0.03,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_xm1014",
        "itemid": 960,
        "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
        "len": 3,
        "hash_name": "XM1014 | Heaven Guard"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/7af6aae03def77dee745074177a6a2e8b1c0eb9e02c6f26d5a713c1bdc817242.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "PP-野牛 | 古董枪",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 961,
        "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
        "len": 3,
        "hash_name": "PP-Bizon | Antique"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/70ab1eb8da677f60347b1c6dcfb9b0d478659f1832c8a9dc38b50baec96a7a78.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AUG | 力矩",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 962,
        "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
        "len": 3,
        "hash_name": "AUG | Torque"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/5f0d58718df3e51ee81d1a25a52d5e588cdbb41b9cf6763c8b2d7b5d7dbcab97.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 电子脉冲",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 963,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "P2000 | Pulse"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/201abbcdc4bc96f6061cf4a77cc0e01ff45b8c6f12fe484f0a806eee048378ce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 深蓝组件",
        "min": 0,
        "max": 0.35,
        "target_box": "set_community_3",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 964,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "P90 | Module"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6ab3eba4f58d427fde4f0ea5c246f1c9bcdc30456c74f404feed982731cffa7b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 螺形扭转",
        "min": 0,
        "max": 1,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 965,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "CZ75-Auto | Twist"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/be200378a679a5960f848dc6d7ec60c31d727135065944311b6d85aa9a6619ed.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 神祗",
        "min": 0,
        "max": 0.6,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 966,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "Galil AR | Kami"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a4a27c7fa4cf65d618f110c8d06954bb2bfd3584efb5ead541cea95a918ed4d3.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SSG 08 | 裂痕",
        "min": 0.15,
        "max": 0.8,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 967,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "SSG 08 | Slashed"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/0a56e70661f855563c12c9c32372423f0b8a11ea22990b05d5b6ca2b879738ad.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 报应",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 968,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "Dual Berettas | Retribution"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/42ba41b09cf3b23c3e2bd1b01c2524ddb8b42fe3a4fa483efdd4ea25c2903214.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P90 | 沙漠战争",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_3",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 969,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "P90 | Desert Warfare"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/f5e1ea9967642f12859332eaf4c9a25b4f9891fd570381635695d99836229f03.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 毒镖",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 970,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "CZ75-Auto | Poison Dart"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/109b0a736f61da5d3f8e67792d977534252d5962cb936d66dda2b807828e9c2c.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 艾萨克",
        "min": 0,
        "max": 1,
        "target_box": "set_community_3",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 971,
        "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
        "len": 5,
        "hash_name": "Tec-9 | Isaac"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/751c3b3639e9fb2a6d4f814d297306a6e412664a81a816097cac05527ae87dc1.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AUG | 变色龙",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 972,
        "list": "",
        "len": 0,
        "hash_name": "AUG | Chameleon"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/4fd4d7b52d2754b8485e7b8d86a4ab3102f8dbce504356a93501a5c9f3eb8bf6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 二西莫夫",
        "min": 0.18,
        "max": 1,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 973,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Asiimov"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/97468bc9ddcb9dabd24f67b33e6672e5c114c301e239484138d24d58bad8cc93.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "新星 | 古董枪",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 974,
        "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
        "len": 2,
        "hash_name": "Nova | Antique"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/6a6bc530194aade9a6ae748fb6743e595838761ba848ece8aea9ebc13797c9de.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 三角",
        "min": 0.08,
        "max": 0.75,
        "target_box": "set_community_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 975,
        "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
        "len": 2,
        "hash_name": "P90 | Trigon"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5b61dd4538c2696391008762adfe5ee12279686f5ac949e2d266cb4cebfa5b42.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 红线",
        "min": 0.1,
        "max": 0.7,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 976,
        "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
        "len": 2,
        "hash_name": "AK-47 | Redline"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/70bca39cd40e83da41a821ac0818a91062597ccbbc8177a20496f17d040f60d7.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 守护者",
        "min": 0,
        "max": 0.38,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 977,
        "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
        "len": 3,
        "hash_name": "USP-S | Guardian"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/fe884c6ce8070c70d5c4c7d7305d3f2969775b385d4f75577b6b9e224663b31b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 中士",
        "min": 0.1,
        "max": 1,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 978,
        "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
        "len": 3,
        "hash_name": "FAMAS | Sergeant"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b42a3a0f2f15b4c8b4ea439b0d0ccdedd8198fbb144a75ee89ab2284c7c3e34e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "SG 553 | 电子脉冲",
        "min": 0.1,
        "max": 0.6,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 979,
        "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
        "len": 3,
        "hash_name": "SG 553 | Pulse"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/ed53719e70a2eea19fc1e6336550118bcddb389a2bc9ecf96983fc3bf5ede46e.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 炽热",
        "min": 0,
        "max": 1,
        "target_box": "set_community_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 980,
        "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
        "len": 3,
        "hash_name": "MAC-10 | Heat"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8b54c8308723beef8ff33da5270427b34317aba20e04804a54d470693f51f449.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 天空守卫",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 981,
        "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
        "len": 4,
        "hash_name": "MAG-7 | Heaven Guard"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c4adfe22482b8ac276f00c582e0b211c89554b593f792539316a2bd2062e3ec5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 沙尘暴",
        "min": 0.1,
        "max": 0.7,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 982,
        "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
        "len": 4,
        "hash_name": "Tec-9 | Sandstorm"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c14bb709b292898837fa92033c59466b914991fc972dc43f84550f7385b2d819.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "内格夫 | 青绿地形",
        "min": 0,
        "max": 0.45,
        "target_box": "set_community_2",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_negev",
        "itemid": 983,
        "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
        "len": 4,
        "hash_name": "Negev | Terrain"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/aabe302d4702dca5b66fd6b3648c732f955e4257dd6cd78827ced0bca247e293.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 下士",
        "min": 0.05,
        "max": 0.75,
        "target_box": "set_community_2",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 984,
        "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
        "len": 4,
        "hash_name": "UMP-45 | Corporal"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/c58aa27cf84bfb2a20094947032646deb8ed7ccc7fb89eb36fe7ea45187dda30.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "CZ75 | 维多利亚",
        "min": 0,
        "max": 0.75,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 985,
        "list": "",
        "len": 0,
        "hash_name": "CZ75-Auto | Victoria"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/799d2634b244c4b374bac5f1ebd35213abae62764b555b4974e0412dce24c83e.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 暗潮",
        "min": 0,
        "max": 0.2,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 986,
        "list": "\nCZ75 | 维多利亚",
        "len": 1,
        "hash_name": "P250 | Undertow"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/c0975aab16e8487e7623d91565cd7858e618868b94a0e644ee818776a4f942eb.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "CZ75 | 梅红时刻",
        "min": 0,
        "max": 0.4,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 987,
        "list": "\nCZ75 | 维多利亚",
        "len": 1,
        "hash_name": "CZ75-Auto | The Fuschia Is Now"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/b0024ee1bea05b81aa27d1e6c93d83e226ad3180b205747cbb3210997d8ebb0a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 铜色星系",
        "min": 0,
        "max": 0.2,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 988,
        "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
        "len": 2,
        "hash_name": "Five-SeveN | Copper Galaxy"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c9305c0c23cd0cb7473ed3657cb4db2fad69f8a067d8520cc2feafb54b06e1af.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "沙漠之鹰 | 遗产",
        "min": 0,
        "max": 0.8,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 989,
        "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
        "len": 2,
        "hash_name": "Desert Eagle | Heirloom"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/272644e58d4305d3cad44acf6f7209e8c36d0228d6c497debd9da3946e2b8934.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "Tec-9 | 钛片",
        "min": 0,
        "max": 0.2,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 990,
        "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
        "len": 2,
        "hash_name": "Tec-9 | Titanium Bit"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/949d5b064766238107bac27287d57da84a128d6984145207c1a223354cd15834.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "CZ75 | 花纹钢板",
        "min": 0,
        "max": 0.2,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 991,
        "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
        "len": 2,
        "hash_name": "CZ75-Auto | Tread Plate"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/62b07405646f02347b20b1129a30fe3c8f25abf17152f49e5d25df7ab045548b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "格洛克 18 型 | 蓝色裂纹",
        "min": 0.06,
        "max": 0.58,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 992,
        "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
        "len": 4,
        "hash_name": "Glock-18 | Blue Fissure"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/2a98b453cb42538dfdd5650e5774176699a77165d750f27879241a69f249292d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "USP 消音版 | 不锈钢",
        "min": 0,
        "max": 0.8,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 993,
        "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
        "len": 4,
        "hash_name": "USP-S | Stainless"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/56dd14c56c6d989fdeb25db452ba41ffb51daf125c850e3a7e29e7b7183be285.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 黑豹",
        "min": 0,
        "max": 0.58,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 994,
        "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
        "len": 4,
        "hash_name": "Dual Berettas | Panther"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/241640b816ebb1d217a2ad5684d328ef4f4514da2dc4330162df4fddb8e17cd1.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P2000 | 红色碎片迷彩",
        "min": 0,
        "max": 0.7,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 995,
        "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
        "len": 4,
        "hash_name": "P2000 | Red FragCam"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7b740b8a948a18a73e660ff3d1684ae969543013e7821941f2111832c81ffd36.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "CZ75 | 深红之网",
        "min": 0,
        "max": 1,
        "target_box": "set_weapons_iii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_cz75a",
        "itemid": 996,
        "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
        "len": 4,
        "hash_name": "CZ75-Auto | Crimson Web"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/3af8fe3e09982990a006907ee4d2edd46a5c266533d02004cbbc6213c5510221.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "截短霰弹枪 | 北海巨妖",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 997,
        "list": "",
        "len": 0,
        "hash_name": "Sawed-Off | The Kraken"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/bb79a8ad8dd9d191eeeeb9e7e476c20c97466973f37abc023fb9cae16ecf68b6.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | 二西莫夫",
        "min": 0.18,
        "max": 1,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 998,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | Asiimov"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/6a384991021b29900191a742dd2057e41979a4e9b2402e6a971adb4623f5fbec.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 红线",
        "min": 0.1,
        "max": 0.4,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 999,
        "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
        "len": 2,
        "hash_name": "AWP | Redline"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/68b8b1b9b3143357741a4bb3fc93a1e28b88cd1eb48435e9c71d7abfcf897c4a.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P250 | 曼海蒂",
        "min": 0,
        "max": 1,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 1000,
        "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
        "len": 2,
        "hash_name": "P250 | Mehndi"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/57dce3b6cfb954fb53e816a903ba6916153a3555c554226ac13057760299270e.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "M4A1 消音型 | 守护者",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 1001,
        "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
        "len": 2,
        "hash_name": "M4A1-S | Guardian"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f5859a02ce62a2c4c681d815a55c7a53ab41b16e0c3264d674c97eeb04f68002.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 惊惧骷髅",
        "min": 0,
        "max": 0.5,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 1002,
        "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
        "len": 3,
        "hash_name": "Nova | Rising Skull"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/0145a643be6c67945f068792995aa7dac53566239523af3efcb51176c2c29f7c.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 铁血玫瑰",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_1",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 1003,
        "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
        "len": 3,
        "hash_name": "MP9 | Rose Iron"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/fa708a99ecc06ed87d9e2ad8a81b8019f822f6c62d175b11b31a48045ad1be3b.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 海斗士",
        "min": 0.05,
        "max": 0.5,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 1004,
        "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
        "len": 3,
        "hash_name": "Dual Berettas | Marina"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/941be5278e0894005c376fa0ab0be56d872e173b8d873449b11a747b9bed4385.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "法玛斯 | 电子脉冲",
        "min": 0,
        "max": 0.4,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 1005,
        "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
        "len": 3,
        "hash_name": "FAMAS | Pulse"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/e8b49f65af56aaea9aafd05ab9cddc10b9c29db2b1c95c26f67963168212c27d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 钻蓝半调",
        "min": 0.05,
        "max": 0.45,
        "target_box": "set_community_1",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 1006,
        "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
        "len": 4,
        "hash_name": "PP-Bizon | Cobalt Halftone"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c87b4380d481e611e168aa6bf4e2d258e5b6fb18400eb3009b1b4bb9bee990ab.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M249 | 岩浆",
        "min": 0,
        "max": 0.7,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Machinegun",
        "weapon": "weapon_m249",
        "itemid": 1007,
        "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
        "len": 4,
        "hash_name": "M249 | Magma"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/576a153ddf2707123c0276c167e912abd3d79ccf2cfe4c7c9d146a1f7cacee25.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 神祗",
        "min": 0,
        "max": 0.3,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 1008,
        "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
        "len": 4,
        "hash_name": "Five-SeveN | Kami"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8fe477b9cb18e6b0fd6b6b9cf092e8843d8e92e831003a5b1f3c1972402cd7c5.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 沙尘暴",
        "min": 0.1,
        "max": 0.6,
        "target_box": "set_community_1",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 1009,
        "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
        "len": 4,
        "hash_name": "Galil AR | Sandstorm"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/bb82b10298e75983fd70bf8491443860eee301232cccf5e5cbc32b2ead75566d.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "M4A4 | X 射线",
        "target_box": "set_esports_ii",
        "min": 0,
        "max": 0.3,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 1010,
        "list": "",
        "len": 0,
        "hash_name": "M4A4 | X-Ray"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1adaf79a434c813e9af608cc3504b9dd61ee884ba4cb014f84a7e9addd973d53.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 钴蓝禁锢",
        "min": 0,
        "max": 0.2,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 1011,
        "list": "\nM4A4 | X 射线",
        "len": 1,
        "hash_name": "Desert Eagle | Cobalt Disruption"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/fa2fec040aa6250436abbb3e0a60e26912778d63949be2541968ddc413fcfa88.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 电子蜂巢",
        "min": 0,
        "max": 0.4,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 1012,
        "list": "\nM4A4 | X 射线",
        "len": 1,
        "hash_name": "AWP | Electric Hive"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/71ef95a8fd6d3cf852a6992b7296646d1a11fe75113df0535addc32e03dddcad.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "法玛斯 | 残影",
        "min": 0.02,
        "max": 0.4,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 1013,
        "list": "\nM4A4 | X 射线",
        "len": 1,
        "hash_name": "FAMAS | Afterimage"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/69c1d8d31620673f52ea8dddd1d376a931e862493fb536e8542b16a01215101a.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P90 | 盲点",
        "min": 0,
        "max": 0.5,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 1014,
        "list": "\n沙漠之鹰 | 钴蓝禁锢\nAWP | 电子蜂巢\n法玛斯 | 残影",
        "len": 3,
        "hash_name": "P90 | Blind Spot"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a79d8c097ca4ce65a007e39abcd4068cc8a0c56ef7ca43331ecc2f70e3146d5f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "AK-47 | 蓝色层压板",
        "min": 0.02,
        "max": 0.4,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 1015,
        "list": "\n沙漠之鹰 | 钴蓝禁锢\nAWP | 电子蜂巢\n法玛斯 | 残影",
        "len": 3,
        "hash_name": "AK-47 | Blue Laminate"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b1731d8862c508c5a0390a264ab306fa90fc05c043d14ae1ee6928b5025ed3ce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 钢铁禁锢",
        "min": 0,
        "max": 0.2,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 1016,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "P250 | Steel Disruption"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/1b4f723fca344e9eabcb5778383ea53e6a0656093ca5e3b59cc69b11fae7ab38.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 碧蓝斑纹",
        "min": 0,
        "max": 0.28,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 1017,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "G3SG1 | Azure Zebra"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/8d1595725129b5a764ac8877a6b5cb699cbe5baf9d47df5bca8cedb423eeb8fa.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 幽灵迷彩",
        "min": 0,
        "max": 0.4,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 1018,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "Nova | Ghost Camo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/828cec3860825bf220f11719b389869c1ade136594f872fa4fb389c831c29fa9.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "PP-野牛 | 水纹之印",
        "min": 0,
        "max": 0.5,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_bizon",
        "itemid": 1019,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "PP-Bizon | Water Sigil"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d9969422143371ae8e5c0fb0307caade070ca9ad19872481aa7b630d22b16ccd.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "FN57 | 夜影",
        "min": 0,
        "max": 0.5,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 1020,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "Five-SeveN | Nightshade"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/7aab3f0e10c46cc62ec82c39389afb78144f1327671b21487a52d52ff2768031.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 蓝钛",
        "min": 0,
        "max": 0.04,
        "target_box": "set_esports_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 1021,
        "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
        "len": 2,
        "hash_name": "Galil AR | Blue Titanium"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/6938f6440f40149c89ae5ce96301a2fb7d2cf8204b154961a4ec136e8aaa3439.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "SSG 08 | 水中之血",
        "min": 0.06,
        "max": 0.2,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ssg08",
        "itemid": 1022,
        "list": "",
        "len": 0,
        "hash_name": "SSG 08 | Blood in the Water"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/fe5367b5fdf87edd4bf0c3ad03a6518cdfcfc0d38e941830204fa385ef9192f2.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "USP 消音版 | 血清",
        "min": 0,
        "max": 0.25,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 1023,
        "list": "\nSSG 08 | 水中之血",
        "len": 1,
        "hash_name": "USP-S | Serum"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/5ffc1ee0501a1142d75ee50328562e0ea9195e3b5eb05cb8b48b1742a821ef1c.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 冷血杀手",
        "target_box": "set_weapons_ii",
        "min": 0,
        "max": 0.08,
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 1024,
        "list": "\nSSG 08 | 水中之血",
        "len": 1,
        "hash_name": "P90 | Cold Blooded"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/34ab99f3e031a062bbd9ae3f577553a5bf5a0c7d4e7a85405a343799b1daaeaf.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "双持贝瑞塔 | 血红蛋白",
        "min": 0,
        "max": 0.2,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 1025,
        "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
        "len": 2,
        "hash_name": "Dual Berettas | Hemoglobin"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/641bb21efddd7bfa1bff512f2aa0223a08a972579d15aeaf19a6961573ea7448.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "新星 | 石墨黑",
        "min": 0,
        "max": 0.12,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 1026,
        "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
        "len": 2,
        "hash_name": "Nova | Graphite"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a9e0e771bce13f8567c94b6fa0690c7f609480aab7ae9cf1c1f408a66de59208.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MP9 | 蛊惑之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp9",
        "itemid": 1027,
        "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
        "len": 2,
        "hash_name": "MP9 | Hypnotic"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f6d5e517fed63d7d439ff092f730a805378d17d868b16a33ccbdbb0ac23e8b61.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "FN57 | 表面淬火",
        "min": 0,
        "max": 1,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_fiveseven",
        "itemid": 1028,
        "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
        "len": 2,
        "hash_name": "Five-SeveN | Case Hardened"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3817e500c036cf98d5b8499acd5506c67ce7f448fe2458c58dab855881dc189b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SCAR-20 | 深红之网",
        "min": 0,
        "max": 1,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_scar20",
        "itemid": 1029,
        "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
        "len": 4,
        "hash_name": "SCAR-20 | Crimson Web"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/31006fb3af46df1e22f0e1ae2c4231ce2287d61171ad50d08dc6980664a018f8.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "P250 | 红巢",
        "min": 0,
        "max": 0.3,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 1030,
        "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
        "len": 4,
        "hash_name": "P250 | Hive"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/d2cd25523c5f94313f557123eb59224616108728a58b9e780b224eeecc9e03b0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 蓝巢",
        "min": 0,
        "max": 0.4,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 1031,
        "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
        "len": 4,
        "hash_name": "FAMAS | Hexane"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/966c6f1af679bf75336b6c17afd24bde9a758621b5f237840a7f6192de2d791f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A1 消音型 | 血虎",
        "min": 0,
        "max": 0.3,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 1032,
        "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
        "len": 4,
        "hash_name": "M4A1-S | Blood Tiger"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/cf065f6bb42c9ba5e6d1248c274aa08684e47f6d6c0a05f7c57b68f31b9dcb87.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "Tec-9 | 蓝钛",
        "min": 0,
        "max": 0.04,
        "target_box": "set_weapons_ii",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_tec9",
        "itemid": 1033,
        "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
        "len": 4,
        "hash_name": "Tec-9 | Blue Titanium"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/178047207ef9daeb09612a61325ed69e390840c0511d4c144f8e33423e2c7855.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "沙漠之鹰 | 黄金锦鲤",
        "min": 0,
        "max": 0.12,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 1034,
        "list": "",
        "len": 0,
        "hash_name": "Desert Eagle | Golden Koi"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/f65cf5bccfc004e60194bb36ddd0516ba50ced870537190f057e64959bcf9356.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AK-47 | 火蛇",
        "min": 0.06,
        "max": 0.76,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 1035,
        "list": "",
        "len": 0,
        "hash_name": "AK-47 | Fire Serpent"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/1225ab30507a5a46e2c5045e0fd9e6fba165a6c72638ced0072c95f367d9301e.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | 石墨黑",
        "min": 0,
        "max": 0.12,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 1036,
        "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
        "len": 2,
        "hash_name": "AWP | Graphite"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/84dd73ec6dbe282d39d7f0ac6a5a04a7494469baf5de86a629e561634a723e89.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P2000 | 海之泡沫",
        "min": 0,
        "max": 0.12,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_hkp2000",
        "itemid": 1037,
        "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
        "len": 2,
        "hash_name": "P2000 | Ocean Foam"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/4be1043ff7694f22ac5331057b827f310c0b93d075909147ab0d06efea17371d.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "P90 | 翡翠之龙",
        "min": 0,
        "max": 0.52,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 1038,
        "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
        "len": 2,
        "hash_name": "P90 | Emerald Dragon"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/22d340a9430f86179ca3108f7b5b712e2b381245e42821b5b9b0d76da7eea5da.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 澄澈之水",
        "min": 0.1,
        "max": 0.22,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 1039,
        "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
        "len": 3,
        "hash_name": "M4A1-S | Bright Water"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/a7b886a2858d0e7605b8ac36a9575b11e690446e9bcda8305e8ef2623f0d1a6f.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "MAC-10 | 不可磨灭",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mac10",
        "itemid": 1040,
        "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
        "len": 3,
        "hash_name": "MAC-10 | Graven"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/6fb3f7adb02d9d4cbae13c223388b915e979215ab53f2b23d1ea812eec455b31.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A4 | 星级",
        "min": 0,
        "max": 0.42,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 1041,
        "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
        "len": 3,
        "hash_name": "M4A4 | Zirka"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/c70523f7f15ad216a7ec7d908301877da3f40f6d5be7079dfe5731cfa279a2da.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 疯狂蔓延",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 1042,
        "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
        "len": 3,
        "hash_name": "USP-S | Overgrowth"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c4ac7ea0a6c831404852b53a858f31d6dbb5191b65ac7d40b259a9e17ee9ca77.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "G3SG1 | 丰饶女神",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_g3sg1",
        "itemid": 1043,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "G3SG1 | Demeter"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c88a946056ca45c050b42f4e5f0ce708a657964265eb9287794fc0f36b6ed216.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "UMP-45 | 白骨之堆",
        "min": 0,
        "max": 0.34,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_ump45",
        "itemid": 1044,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "UMP-45 | Bone Pile"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/ca3ce3d08b2b4cad82a5f344d88956cd23665f95db6b646167086bc02a46cfd0.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "加利尔 AR | 支离破碎",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 1045,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "Galil AR | Shattered"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/b7b81ddde14f2fa14c4b81e090bfe5578f81dc5d6c54492060d1a01b746c8d3b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "新星 | 兴风作浪",
        "min": 0,
        "max": 0.22,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_nova",
        "itemid": 1046,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "Nova | Tempest"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/a0580aaa7998fd27488f1a93bbf9a0737bb7b980ed100460da27dd2fc67a8cce.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "双持贝瑞塔 | 黑榄仁木",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_elite",
        "itemid": 1047,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "Dual Berettas | Black Limba"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/543a73855b1a7cfa3495388d63e665d3b4364e67110b15074595d5578bc9df71.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 浪花喷漆",
        "min": 0,
        "max": 1,
        "target_box": "set_bravo_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 1048,
        "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
        "len": 4,
        "hash_name": "SG 553 | Wave Spray"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/0189c5f1a5933c1796a35d6e3143373cc596ac11a8d4c110da1c43dade320934.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "P90 | 喵之萌杀",
        "min": 0.08,
        "max": 0.32,
        "target_box": "set_esports",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_p90",
        "itemid": 1049,
        "list": "",
        "len": 0,
        "hash_name": "P90 | Death by Kitty"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/152ff6ba2eb69e886a5997a85eb72de13c504e84d771a9eed36777060fdad1ae.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AWP | *嘣*",
        "min": 0.06,
        "max": 0.28,
        "target_box": "set_esports",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 1050,
        "list": "\nP90 | 喵之萌杀",
        "len": 1,
        "hash_name": "AWP | BOOM"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/4241013f899fd1c168c725de9d1d4c7fa0a49f55b5699ffc6ca319fe4c0c6d47.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 红色层压板",
        "min": 0.06,
        "max": 0.8,
        "target_box": "set_esports",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 1051,
        "list": "\nP90 | 喵之萌杀",
        "len": 1,
        "hash_name": "AK-47 | Red Laminate"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/17e273defbcd70a6dbbfe0a9c0f39d1b4837b96528c268f5dc53ba441caf13f7.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "P250 | 狂野飞溅",
        "min": 0,
        "max": 0.18,
        "target_box": "set_esports",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_p250",
        "itemid": 1052,
        "list": "\nAK-47 | 红色层压板",
        "len": 1,
        "hash_name": "P250 | Splash"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/9c2c8bf72473108bef5338b9ceaba5d65e53146c894c380d26172b4663b0ebd5.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "截短霰弹枪 | 橙黄 DDPAT",
        "target_box": "set_esports",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_sawedoff",
        "itemid": 1053,
        "list": "\nAK-47 | 红色层压板",
        "len": 1,
        "hash_name": "Sawed-Off | Orange DDPAT"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/2fd76f377239391141aa6de3b22496b5aa8d0ead660d6668607ac300583ebd41.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "加利尔 AR | 橙黄 DDPAT",
        "target_box": "set_esports",
        "min": 0,
        "max": 1,
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_galilar",
        "itemid": 1054,
        "list": "\nAK-47 | 红色层压板",
        "len": 1,
        "hash_name": "Galil AR | Orange DDPAT"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/fd7d9a28bf2c4f4ea713d151c49f07d420cbe42f16b4f6b48649392399941498.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "法玛斯 | 厄运之喵",
        "min": 0.08,
        "max": 0.22,
        "target_box": "set_esports",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_famas",
        "itemid": 1055,
        "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
        "len": 2,
        "hash_name": "FAMAS | Doomkitty"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c2f18c5af2347a00190dea5fd92ce62f4d9045498e7e301ac49a3a75fe47290d.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MAG-7 | 记忆碎片",
        "min": 0.02,
        "max": 0.18,
        "target_box": "set_esports",
        "type": "CSGO_Type_Shotgun",
        "weapon": "weapon_mag7",
        "itemid": 1056,
        "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
        "len": 2,
        "hash_name": "MAG-7 | Memento"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/dd2898407e28e00185ae2b3f4b88e1a22446bc1e341ae1951cdb05add2a4e40b.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "M4A4 | 渐变斑纹",
        "min": 0,
        "max": 1,
        "target_box": "set_esports",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1",
        "itemid": 1057,
        "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
        "len": 2,
        "hash_name": "M4A4 | Faded Zebra"
    },
    {
        "rarity": "隐秘",
        "cover": "https://static.mobi2077.com/market-csgo/debb065e954292db77a4aeae50f183d5004d6e6668aaa129e5981afbc03c0b7a.png",
        "en_rarity": "Rarity_Ancient_Weapon",
        "name": "AWP | 雷击",
        "min": 0,
        "max": 0.08,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_awp",
        "itemid": 1058,
        "list": "",
        "len": 0,
        "hash_name": "AWP | Lightning Strike"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/539b3202f2351d6d512fca0fb78856f95acbd0153557729ebba70267a66a2756.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "沙漠之鹰 | 蛊惑之色",
        "min": 0,
        "max": 0.08,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_deagle",
        "itemid": 1059,
        "list": "\nAWP | 雷击",
        "len": 1,
        "hash_name": "Desert Eagle | Hypnotic"
    },
    {
        "rarity": "保密",
        "cover": "https://static.mobi2077.com/market-csgo/6de69fd9e4d1f2f9af4ba12de86488085b979de147451d2d5a19e08589fe8413.png",
        "en_rarity": "Rarity_Legendary_Weapon",
        "name": "AK-47 | 表面淬火",
        "min": 0,
        "max": 1,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_ak47",
        "itemid": 1060,
        "list": "\nAWP | 雷击",
        "len": 1,
        "hash_name": "AK-47 | Case Hardened"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/094b6ad4e253cce32d88cccd5a44e5f0697700b68720dc2fdd3cbb07c846aa99.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "M4A1 消音型 | 黑水",
        "min": 0.1,
        "max": 0.26,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_m4a1_silencer",
        "itemid": 1061,
        "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
        "len": 2,
        "hash_name": "M4A1-S | Dark Water"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/4231b473acd41c3d72ab199cb7fc3c44c1787c0d0124653f041cabd5caaf3155.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "USP 消音版 | 黑水",
        "min": 0.1,
        "max": 0.26,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_usp_silencer",
        "itemid": 1062,
        "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
        "len": 2,
        "hash_name": "USP-S | Dark Water"
    },
    {
        "rarity": "受限",
        "cover": "https://static.mobi2077.com/market-csgo/f49fda722cae0422dc3cd9264f82732d1a78ebae0e77173f233c4e9c5a09cb88.png",
        "en_rarity": "Rarity_Mythical_Weapon",
        "name": "格洛克 18 型 | 黑龙纹身",
        "min": 0,
        "max": 0.08,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Pistol",
        "weapon": "weapon_glock",
        "itemid": 1063,
        "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
        "len": 2,
        "hash_name": "Glock-18 | Dragon Tattoo"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/c77e5468c2a225e557de1efbc011ebcfdad2e79e7a10ceab66beb907bce37f42.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "SG 553 | 致命紫罗兰",
        "min": 0,
        "max": 1,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_sg556",
        "itemid": 1064,
        "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
        "len": 3,
        "hash_name": "SG 553 | Ultraviolet"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/6528b637fc96e9739c12169769c6dbda0fe21b95631b5591060e532452bef189.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "AUG | 鹰翼",
        "min": 0,
        "max": 0.14,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_Rifle",
        "weapon": "weapon_aug",
        "itemid": 1065,
        "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
        "len": 3,
        "hash_name": "AUG | Wings"
    },
    {
        "rarity": "军规级",
        "cover": "https://static.mobi2077.com/market-csgo/3d0742fa94c4c7b4e5ad8797078995ea53af1859c9864a6652f0319c4c9be50f.png",
        "en_rarity": "Rarity_Rare_Weapon",
        "name": "MP7 | 死亡骷髅",
        "min": 0.1,
        "max": 0.26,
        "target_box": "set_weapons_i",
        "type": "CSGO_Type_SMG",
        "weapon": "weapon_mp7",
        "itemid": 1066,
        "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
        "len": 3,
        "hash_name": "MP7 | Skulls"
    }
]
const allCustomItemDataTree = [
    {
        "name": "变革武器箱",
        "en_name": "Revolution Case",
        "cover": "https://static.mobi2077.com/market-csgo/f9287a4d9a715eab5232f5ab37f35a0a3d5e085b12bdcf7e36a86d2b679e749b.png",
        "type": 2,
        "box_value": "set_community_32",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/07ded6f137699473fce3f1873ffb3f4b1875a49fd99d7b5305a46a535eb75ab6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 一发入魂",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "box_value": "set_community_32",
                "itemid": 1103,
                "list": "",
                "len": 0,
                "hash_name": "AK-47 | Head Shot"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e5c95f42b25da8c3ceb3bed2d52e7ca4656fb3bbf4a7977ae027bf357572def0.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 反冲精英",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.8,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "box_value": "set_community_32",
                "itemid": 1104,
                "list": "",
                "len": 0,
                "hash_name": "M4A4 | Temukau"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/fecf1f1a65d407a022cdedcc5380907bb4673f132ceee091dac0f533f2089d3c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P2000 | 变态杀戮",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "box_value": "set_community_32",
                "itemid": 1105,
                "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
                "len": 2,
                "hash_name": "P2000 | Wicked Sick"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5e005da7ffb3666d8360dcfa613b15d599a4586df73fb214e7c6b5dc0cbaf270.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "UMP-45 | 野孩子",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "box_value": "set_community_32",
                "itemid": 1106,
                "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
                "len": 2,
                "hash_name": "UMP-45 | Wild Child"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/e2e5c70732a29ffe3878b9a855e400686af67c1f81ef8ff5c1f016afc80cf909.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 金粉肆蛇",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.8,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "box_value": "set_community_32",
                "itemid": 1107,
                "list": "\nAK-47 | 一发入魂\nM4A4 | 反冲精英",
                "len": 2,
                "hash_name": "AWP | Duality"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/83cc48752bcba79436d0d9e9036f9b752888ac80c4b96b1711191fc09f9ca11c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 元女王",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.6,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "box_value": "set_community_32",
                "itemid": 1108,
                "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
                "len": 3,
                "hash_name": "P90 | Neoqueen"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/34dcefacb43b442824f5ae587c3869bad9581cb78fbdd2cb6afd3ab10f2ebddb.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "R8 左轮手枪 | 蕉农炮",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "box_value": "set_community_32",
                "itemid": 1109,
                "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
                "len": 3,
                "hash_name": "R8 Revolver | Banana Cannon"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2b17de3aef66395693046ff66ba953079421f4895a1ee9a6154fb325ee5be460.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 错觉",
                "target_box": "set_community_32",
                "min": 0.21,
                "max": 0.79,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "box_value": "set_community_32",
                "itemid": 1110,
                "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
                "len": 3,
                "hash_name": "MAC-10 | Sakkaku"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/adcbb382b7a23e49105c1ec2cf6f77d0ed879a3d1ae58a16d765524e622e66a6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 圆影玉兔",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.75,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "box_value": "set_community_32",
                "itemid": 1111,
                "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
                "len": 3,
                "hash_name": "Glock-18 | Umbral Rabbit"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/aad8c91f0099fdb054977f6f4ecf74b2a99abcb49296bbd31bf81d7d3e33fb52.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 隐伏帝王龙",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.8,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "box_value": "set_community_32",
                "itemid": 1112,
                "list": "\nP2000 | 变态杀戮\nUMP-45 | 野孩子\nAWP | 金粉肆蛇",
                "len": 3,
                "hash_name": "M4A1-S | Emphorosaur-S"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5c03cc809f9d17f9642f7e11d8675c794faed5ea2364383fe2d08b82acdfb89d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 叛逆",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "box_value": "set_community_32",
                "itemid": 1113,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "Tec-9 | Rebel"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bbf7497f929719eb7868fb50532d1571054908000b816068ce89eb3431eefbc6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 赛博之力",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.9,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "box_value": "set_community_32",
                "itemid": 1114,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "SG 553 | Cyberforce"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/82bc9a43c6c623f8f82ad0d21efabd1fb5f98489002422e8b23e547bb1500e5e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 液化",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "box_value": "set_community_32",
                "itemid": 1115,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "MP5-SD | Liquidation"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/905910367a57afdebc2ec2e4b692f69e9843035838ad12c660a6cf7f8e6dbfd7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 重构",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.9,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "box_value": "set_community_32",
                "itemid": 1116,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "P250 | Re.built"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/dda65d76148d2c71f1a85080f938ae4956d7be04b46ef2a4139d93880efaaf7d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 碎片",
                "target_box": "set_community_32",
                "min": 0,
                "max": 0.78,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "box_value": "set_community_32",
                "itemid": 1117,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "SCAR-20 | Fragments"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/750df3275d5dea9c18c48b50b15f5d938d08fad9c327cb1ac4a533e8e7c46710.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 羽量级",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "box_value": "set_community_32",
                "itemid": 1118,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "MP9 | Featherweight"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/66dbbafb0dcd5af84d77d17281c83bf5baf5753b98ce62bfa0b5dc106c1b3269.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 鱼长梦短",
                "target_box": "set_community_32",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "box_value": "set_community_32",
                "itemid": 1119,
                "list": "\nP90 | 元女王\nR8 左轮手枪 | 蕉农炮\nMAC-10 | 错觉\n格洛克 18 型 | 圆影玉兔\nM4A1 消音型 | 隐伏帝王龙",
                "len": 5,
                "hash_name": "MAG-7 | Insomnia"
            }
        ]
    },
    {
        "name": "反冲武器箱",
        "en_name": "Recoil Case",
        "cover": "https://static.mobi2077.com/market-csgo/8897c101eae6f395ab3235a9280511d5d7d9ae956fd5f133f13c50f2574857c1.png",
        "type": 2,
        "box_value": "set_community_31",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/bd36c57304dc7f695ee758ebae79a779dbebb6613a79b223b9abf0a4dcff31bc.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 迷人眼",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "box_value": "set_community_31",
                "itemid": 1086,
                "list": "",
                "len": 0,
                "hash_name": "AWP | Chromatic Aberration"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/f2ac0d50c5f8a820718f64e0b46629d778c578beebd016addcd13ef626100475.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "USP 消音版 | 印花集",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.85,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "box_value": "set_community_31",
                "itemid": 1085,
                "list": "",
                "len": 0,
                "hash_name": "USP-S | Printstream"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f92ea07dc83d35d757417f23a52903a8b1db7913a2e049bec0a07564768e5fa1.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "截短霰弹枪 | 么么",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "box_value": "set_community_31",
                "itemid": 1087,
                "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
                "len": 2,
                "hash_name": "Sawed-Off | Kiss♥Love"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a2766ae5dc71c6542f7a6e2547520a95a955f1144e5149739631d33e6975652e.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 迷人幻象",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "box_value": "set_community_31",
                "itemid": 1088,
                "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
                "len": 2,
                "hash_name": "P250 | Visions"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/354254b67c433ff0ea5d3253fa751e4573df953e151509bf8a98d8d1096e3d46.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 可燃冰",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.77,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "box_value": "set_community_31",
                "itemid": 1089,
                "list": "\nAWP | 迷人眼\nUSP 消音版 | 印花集",
                "len": 2,
                "hash_name": "AK-47 | Ice Coaled"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/02b23f4d271e2d1142e176272dce765f8fe44a3dd333941eff868eca733899c1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 食人花",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "box_value": "set_community_31",
                "itemid": 1090,
                "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
                "len": 3,
                "hash_name": "Dual Berettas | Flora Carnivora"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7287921678ccfaa5af23c567b662b63b12816d963d5b813d1d5b259809257483.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 给爷冲",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "box_value": "set_community_31",
                "itemid": 1091,
                "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
                "len": 3,
                "hash_name": "P90 | Vent Rush"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8119c17c7bfb7e8023cf40b691544d8f6b5f1b272bef76c2d5ef7ea77696f7f6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 青龙",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "box_value": "set_community_31",
                "itemid": 1092,
                "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
                "len": 3,
                "hash_name": "SG 553 | Dragon Tech"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e2ce8f1f3dc425ab2ce0b7a2f162a11ba0a7505caa4a64e25be0973308506a20.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M249 | 闹市区",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.65,
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "box_value": "set_community_31",
                "itemid": 1093,
                "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
                "len": 3,
                "hash_name": "M249 | Downtown"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/382ba634dc71d0c4f401684c0cf1758d09f1f2a94288510d093b48bf61a99f48.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "R8 左轮手枪 | 疯狂老八",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "box_value": "set_community_31",
                "itemid": 1094,
                "list": "\n截短霰弹枪 | 么么\nP250 | 迷人幻象\nAK-47 | 可燃冰",
                "len": 3,
                "hash_name": "R8 Revolver | Crazy 8"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/81b0dbbb0cacf1e33291891607ab4e90e81d8b24daafa93db7b9d376f7a91e45.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 冬季战术",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "box_value": "set_community_31",
                "itemid": 1095,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "Glock-18 | Winterized"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e1b252541db8ed38437316bd1f647e31bcbe82c24c7a50a448e2fd2e4cabc750.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 路障",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "box_value": "set_community_31",
                "itemid": 1096,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "UMP-45 | Roadblock"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d4be5d073cfa54f70beb225ab19a6e3cbfcc4d18367ff7ece6cc9cb740f5c063.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 丢把枪",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "box_value": "set_community_31",
                "itemid": 1097,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "Negev | Drop Me"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8b71608ed330f8666a3a7bec2c8dfcecb691b62c6dc4500b2ef0e8c7e2e45b66.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 萌猴迷彩",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "box_value": "set_community_31",
                "itemid": 1098,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "MAC-10 | Monkeyflage"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fafe5e96e2d53b89158a11b6ead8b29739fb94485b828f27fc0ec3c46bedf0df.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 透明弹匣",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "box_value": "set_community_31",
                "itemid": 1099,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "M4A4 | Poly Mag"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2a53120ba772121c2e0c9e1579515c4f92441e4bd1db562a8621d1a5c66ac510.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 毁灭者",
                "target_box": "set_community_31",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "box_value": "set_community_31",
                "itemid": 1100,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "Galil AR | Destroyer"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/94c21c31cd03d3b35fc3411dccba44dfaff624cd6f58543c0be699b6e995d8b9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 喵喵36",
                "target_box": "set_community_31",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "box_value": "set_community_31",
                "itemid": 1084,
                "list": "\n双持贝瑞塔 | 食人花\nP90 | 给爷冲\nSG 553 | 青龙\nM249 | 闹市区\nR8 左轮手枪 | 疯狂老八",
                "len": 5,
                "hash_name": "FAMAS | Meow 36"
            }
        ]
    },
    {
        "name": "梦魇武器箱",
        "en_name": "Dreams & Nightmares Case",
        "cover": "https://static.mobi2077.com/market-csgo/b5de2e66bb212baac0c81a3aae8bfab51e6531fa7e46a407d5ea565b6e675ac1.png",
        "type": 2,
        "box_value": "set_community_30",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/5845abbf8dc6338081d1bf54e869cd19ec650d2aa99ca929781e607d17be03d8.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 夜愿",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "box_value": "set_community_30",
                "itemid": 1067,
                "list": "",
                "len": 0,
                "hash_name": "AK-47 | Nightwish"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/55dccdd81d0234ae16e0590dfd0ee981064351486934dddaba66e77a124833fa.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "MP9 | 星使",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.8,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "box_value": "set_community_30",
                "itemid": 1068,
                "list": "",
                "len": 0,
                "hash_name": "MP9 | Starlight Protector"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/bbdb0bc408bfe182167deea6f240df45756863040215d2747e7f40fd5b564c5f.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP7 | 幽幻深渊",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "box_value": "set_community_30",
                "itemid": 1069,
                "list": "\nAK-47 | 夜愿\nMP9 | 星使",
                "len": 2,
                "hash_name": "MP7 | Abyssal Apparition"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/229c759436aaec4e19ee9e73b1a3cd62ed572768b2100c7bf855817468cc47e1.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 目皆转睛",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "box_value": "set_community_30",
                "itemid": 1070,
                "list": "\nAK-47 | 夜愿\nMP9 | 星使",
                "len": 2,
                "hash_name": "FAMAS | Rapid Eye Movement"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/0a057520e8f2cb53b11753e04aa79a5ec3a9b671448868fe8732bab93b3f2541.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "双持贝瑞塔 | 瓜瓜",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "box_value": "set_community_30",
                "itemid": 1071,
                "list": "\nAK-47 | 夜愿\nMP9 | 星使",
                "len": 2,
                "hash_name": "Dual Berettas | Melondrama"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a522088a1244fb6d4ef3cd6599d0adccf1e1e192fb20619f14353e52f6a928bc.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 地狱门票",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.76,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "box_value": "set_community_30",
                "itemid": 1072,
                "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
                "len": 3,
                "hash_name": "USP-S | Ticket to Hell"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/726c0725fc14ae7d8ee1bd0ca73b7cd2050f03f53561f73b1b4adb841ee2f10c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 行尸攻势",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.5,
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "box_value": "set_community_30",
                "itemid": 1073,
                "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
                "len": 3,
                "hash_name": "XM1014 | Zombie Offensive"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5b8344b9e2e5a36dc459fb3d9cf0784111c7f13158d470f7c0c557cbf89446d5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 夜无眠",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "box_value": "set_community_30",
                "itemid": 1074,
                "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
                "len": 3,
                "hash_name": "M4A1-S | Night Terror"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d7371959e5f81afec652bad155a6e925da3328a7e6e054dedadd14b3d9dcd285.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "G3SG1 | 梦之林地",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "box_value": "set_community_30",
                "itemid": 1075,
                "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
                "len": 3,
                "hash_name": "G3SG1 | Dream Glade"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9097af03a80e0f17dfecc94d4c90d51bde559eed0ab4f1506fc37a1616c6c7f5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 太空猫",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.67,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "box_value": "set_community_30",
                "itemid": 1076,
                "list": "\nMP7 | 幽幻深渊\n法玛斯 | 目皆转睛\n双持贝瑞塔 | 瓜瓜",
                "len": 3,
                "hash_name": "PP-Bizon | Space Cat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3ee92ad92437d20e78087b7bcf78164fcd78ea0daf9fab4bc54cd6f3daebf257.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 灵应牌",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "box_value": "set_community_30",
                "itemid": 1077,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "Sawed-Off | Spirit Board"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/caf2dfe8914db15025e1e9df4152fa5e708374dcc03018cfeb6c73e8abea9430.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 暗夜活死鸡",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.57,
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "box_value": "set_community_30",
                "itemid": 1078,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "SCAR-20 | Poultrygeist"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f906570133b131fb5fce66bfbe58c455878f7ff52b9c7bff63f530a4e27a5ac1.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 升天",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "box_value": "set_community_30",
                "itemid": 1079,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "P2000 | Lifted Spirits"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/992ff21f82f88189460a3ca806a303cc15e0c533954adafdb60013d1ac5dd92b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 小小噩梦",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "box_value": "set_community_30",
                "itemid": 1080,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "MP5-SD | Necro Jr."
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/41cde24a9438571b3af62f546a64e617e383003e21ab9abb0f6db67a8cdb2529.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 先见之明",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.7,
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "box_value": "set_community_30",
                "itemid": 1081,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "MAG-7 | Foresight"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/61fb91a042be19e0969470db01747619150ef46b2dc61535d521aac9ea8b9d74.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 坐牢",
                "target_box": "set_community_30",
                "min": 0,
                "max": 0.9,
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "box_value": "set_community_30",
                "itemid": 1082,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "MAC-10 | Ensnared"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0a5608f06392bc18fb6477188c28195072b74631adeb620ad3484ab341dafb0b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 涂鸦潦草",
                "target_box": "set_community_30",
                "min": 0,
                "max": 1,
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "box_value": "set_community_30",
                "itemid": 1083,
                "list": "\nUSP 消音版 | 地狱门票\nXM1014 | 行尸攻势\nM4A1 消音型 | 夜无眠\nG3SG1 | 梦之林地\nPP-野牛 | 太空猫",
                "len": 5,
                "hash_name": "Five-SeveN | Scrawl"
            }
        ]
    },
    {
        "name": "激流大行动武器箱",
        "en_name": "Operation Riptide Case",
        "cover": "https://static.mobi2077.com/market-csgo/fa8590e49b4b4914693bd0690f92fd189aefc027bc3fb3932b67578567aa7f6d.png",
        "type": 2,
        "box_value": "set_community_29",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/5cf5ab7fb4e339bb80dd679af7f005ca98ac339727932787305847e85f9ea5a9.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 抽象派 1337",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.65,
                "itemid": 1,
                "list": "",
                "len": 0,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/0c7b5de8a810c90fb4e34f36bfcf6794fe415c0f04c9d8a5b9f70f4bd314c10c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "沙漠之鹰 | 纵横波涛",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 2,
                "list": "",
                "len": 0,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Ocean Drive"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3c03cdab7c250262b14ef3efff3781c864e8ba69e28143bbb0686558bc859c4a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SSG 08 | 速度激情",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.6,
                "itemid": 3,
                "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
                "len": 2,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Turbo Peek"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f3f56c37dfe4eec19fb7224b8b0e9d580241e636f83b438fbbf1a8f613c713bc.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "格洛克 18 型 | 零食派对",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 4,
                "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
                "len": 2,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Snack Attack"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/eaec579e4e734c18190861e317c419fd732a1b66f72dd1a92537cd805cc27ecc.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAC-10 | 玩具盒子",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 5,
                "list": "\nAK-47 | 抽象派 1337\n沙漠之鹰 | 纵横波涛",
                "len": 2,
                "box_value": "set_community_29",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Toybox"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2d07dcdcb73ca4d954d90a9973ac21cab6dfffd47cd6668e799f8f28e09ec2e1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 彼岸花",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 6,
                "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
                "len": 3,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Spider Lily"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a566fdd42b344b7353c99bffe147d074a9ecfd95c0c24576fb18af4d9d6b944c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 富士山",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.55,
                "itemid": 7,
                "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
                "len": 3,
                "box_value": "set_community_29",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Mount Fuji"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/fa04eb18d3f713243bf4b56a559d494dd329e258df9d906cb7413fa06a2aafbb.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 同步力场",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.41,
                "itemid": 8,
                "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
                "len": 3,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Boost Protocol"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b3b6c6f8cd574efce5a5337767aee8e1105ba045550cbafdb6c23152c6bf6f27.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | ZX81 彩色",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 9,
                "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
                "len": 3,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0b3565d4dca56a2e44a7ca32cd819eac07b6d896697e14403fabb9fc08149e49.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 铋晶体",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.6,
                "itemid": 10,
                "list": "\nSSG 08 | 速度激情\n格洛克 18 型 | 零食派对\nMAC-10 | 玩具盒子",
                "len": 3,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | BI83 Spectrum"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/53eb826dc1c60a201dd871804b65ad7f55667a5c8ce10ef6deee53105fe4d6b2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 狻猊",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 11,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Watchdog"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4394facea3e67e6d883bbc2228d35ec9ad446e5f2d4494937900234237844ade.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 黑莲花",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.9,
                "itemid": 12,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Black Lotus"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bd0444601c4ba013aa5f4bf3f1ae45928a7a2e6bedfa84e71cf9184c77ff17b0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 战术手电",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.77,
                "itemid": 13,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Lumen"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/917f972b0ffc79ccf8ca8c5a0910b68a0526d26ba8797107497fd319b0a5639c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 游击队",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 14,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Guerrilla"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/37382222b0748c623906174019c57807c3197441c5376a878b2c067670c1c1b2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 特训地图",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.7,
                "itemid": 15,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Keeping Tabs"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b76966287e6e5007e04c4f89f5cf53acb15eed549b760c4d84eb3d04db58f66b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 胶面花纹",
                "target_box": "set_community_29",
                "min": 0,
                "max": 1,
                "itemid": 16,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Tread"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d0a1ff1affc3ae5f9a1e6d8e24233da8d2eb06a3a112bfa731a69ebca4b3ae7f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 瘟疫",
                "target_box": "set_community_29",
                "min": 0,
                "max": 0.76,
                "itemid": 17,
                "list": "\nM4A4 | 彼岸花\nMP9 | 富士山\nFN57 | 同步力场\n法玛斯 | ZX81 彩色\nMAG-7 | 铋晶体",
                "len": 5,
                "box_value": "set_community_29",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Plague"
            }
        ]
    },
    {
        "name": "2021 殒命大厦收藏品",
        "en_name": "The 2021 Vertigo Collection",
        "cover": "https://static.mobi2077.com/market-csgo/029e633554cfb16cbe2aed846d5bca67670599483bc712bb867d62e9b968af9d.png",
        "type": 1,
        "box_value": "set_vertigo_2021",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/36610819c35954122e436da85cdcd9f2b367f67edba95a9b4a9acc553fd216ad.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 紧迫危机",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.8,
                "itemid": 18,
                "list": "",
                "len": 0,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Imminent Danger"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/23d81788b3bf09229bc247dd0c135ca4ca6444a8a0e588a400e7bdba37077220.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SG 553 | 玩命职场",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.48,
                "itemid": 19,
                "list": "\nM4A1 消音型 | 紧迫危机",
                "len": 1,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Hazard Pay"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/8605bb90f4d84962fd333486ad4682b038c549a1a0cf210bc35ae5447b9dfb44.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "FN57 | 坠落危险",
                "target_box": "set_vertigo_2021",
                "min": 0.01,
                "max": 0.52,
                "itemid": 20,
                "list": "\nM4A1 消音型 | 紧迫危机",
                "len": 1,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Fall Hazard"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0647a423cde3fd69f91c8cec38d7f1f23d0c0d266151345d0014cef64a93eb20.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 数字架构师",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.49,
                "itemid": 21,
                "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
                "len": 2,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Digital Architect"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a57c0a2f7668798154bef9c8817d6c37f367aa4b5eba1c362a09e9491475b5d1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 棱彩阶梯",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.37,
                "itemid": 22,
                "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
                "len": 2,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Prism Terrace"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/67d8be0274f2dd2da590726e867969ce8c6c9bfcf683295e19a7ba0a5e9e7eca.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 警告！",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 23,
                "list": "\nSG 553 | 玩命职场\nFN57 | 坠落危险",
                "len": 2,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | CAUTION!"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3e64eb1507ac37b2f8865931da5b4e0d577dbfeb9bdcf12439d2815de6190fb7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 基础建设",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.49,
                "itemid": 24,
                "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
                "len": 3,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Infrastructure"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6bdbf1a350e86b752889b520e5cbfeafc5b5f58d88826f1e2c56a7cfeb579089.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 致幻时刻",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 25,
                "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
                "len": 3,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Interlock"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b5b5e2bd7a417d048b8a62bdd1feab7f34456f14e345c4bd8462f7cdcc8f5ead.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 解视图",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 26,
                "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
                "len": 3,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Schematic"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3df8582ac2abfa0eb8deadf5f2f5256537f879880598050e21f3793134c7160b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AK-47 | 绿色层压板",
                "target_box": "set_vertigo_2021",
                "min": 0.02,
                "max": 0.4,
                "itemid": 27,
                "list": "\nP250 | 数字架构师\nMAG-7 | 棱彩阶梯\n加利尔 AR | 警告！",
                "len": 3,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Green Laminate"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/2b6054fad06c4656a6dbe03dc26d0167331af56b51a845ae9f129aa7b0a90896.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "PP-野牛 | 开关箱",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.44,
                "itemid": 28,
                "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Breaker Box"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/5d7821224bbcb02bb2eedaeab105a5a988cb7ad39cad4aab3ae1a3949822bb53.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SSG 08 | 碳素纤维",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.12,
                "itemid": 29,
                "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Carbon Fiber"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/6fc76f314c7a09eaffe0ed3a23c7eea75fdffa012fbdd17e50ab97b0cc38cc9f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "UMP-45 | 机械装置",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.49,
                "itemid": 30,
                "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Mechanism"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b856e8c4e1654967980bcda7770a32de1a3c415888e95d195e0c6e8f23439fc7.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 红色轮胎",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 31,
                "list": "\n内格夫 | 基础建设\n新星 | 致幻时刻\nP90 | 解视图\nAK-47 | 绿色层压板",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Red Tire"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/62c3f4c94364b7bb7ebe06c9312ca71baf201344eeacd46f918ad8bb86bcab99.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 更换机油",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 32,
                "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Oil Change"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/518eb7fb2d17ede4214c055d04a62eb69be6c3f56578b60014b556034c00ae2e.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "CZ75 | 幻光构架",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.8,
                "itemid": 33,
                "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Framework"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/b2f2ba5dec73b6e0a4ebc8354c909e7bae80ca90fcf858339e269fa2202447a1.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 蓝色轮胎",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 34,
                "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Blue Tire"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6fcc5241bbf98d0b42c16c960da459e99675a86ca16ee669fbf6dcf3854123d9.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "法玛斯 | 线路故障",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 35,
                "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Faulty Wiring"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8d71b86b57ca6ffa8715414a864c719f13731a73c46a7174fdf6bfb7a9a1af07.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 策略",
                "target_box": "set_vertigo_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 36,
                "list": "\nPP-野牛 | 开关箱\nSSG 08 | 碳素纤维\nUMP-45 | 机械装置\n格洛克 18 型 | 红色轮胎",
                "len": 4,
                "box_value": "set_vertigo_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Strats"
            }
        ]
    },
    {
        "name": "2021 炙热沙城 II 收藏品",
        "en_name": "The 2021 Dust 2 Collection",
        "cover": "https://static.mobi2077.com/market-csgo/8626cc5dc315a012eab8c21d8ff69bd6a68b8de3db0377ceac846d9629b2cef7.png",
        "type": 1,
        "box_value": "set_dust_2_2021",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e49be245ace0063ae8c05f147c5bc9ba84dd2fe4c9a7fd93a19c7eaa8f96b96a.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 黄金藤蔓",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.7,
                "itemid": 37,
                "list": "",
                "len": 0,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Gold Arabesque"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/2b7a97f7149e6b37b1e639c4ef6b9d01e05a0e64fa757bbd010a013e0325a854.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SSG 08 | 致命一击",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.8,
                "itemid": 38,
                "list": "\nAK-47 | 黄金藤蔓",
                "len": 1,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Death Strike"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d1adf4951cfad704be07b985aa33207b4a11637628329813f69a4e4d2377d7a6.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "UMP-45 | 渐变之色",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.08,
                "itemid": 39,
                "list": "\nAK-47 | 黄金藤蔓",
                "len": 1,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Fade"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/fcecb0566be32bb2a620361354c6df528155809f8b0bb2dfda19aea605e254b8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 表面淬火",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 1,
                "itemid": 40,
                "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
                "len": 2,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Case Hardened"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/91ca2eb942470d4d48aead3d0354ebd975a990e21f8eed9ca491df7c263a1b68.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 红色 DDPAT",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 41,
                "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
                "len": 2,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Red DDPAT"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/90ae6cbcc4133aa88e49f60cbbd912e56e083bac3a230757680b7e8fa1cde97e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 橙红安乐蜥",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.37,
                "itemid": 42,
                "list": "\nSSG 08 | 致命一击\nUMP-45 | 渐变之色",
                "len": 2,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Orange Anolis"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/39aeac77bea41ae2ae2284069a3b24fafcbbef75ac509afe901973f8d4927675.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 渐变琥珀",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.4,
                "itemid": 43,
                "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
                "len": 3,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Amber Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fbb4b8280b31a91bba711c5ed91a3030c8b7135cd0e3f5d8a4f9378c22842304.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 碧藤青翠",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 44,
                "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
                "len": 3,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | New Roots"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e8b0d57782af5fd1fc3ad51c41822093fcd048c8ece248d19aca5c5bb6482ff4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 流沙",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 45,
                "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
                "len": 3,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Quick Sand"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/01fb2e17e000f4b2f539fdd2399396d769990957a091ae06447f49aed507c28e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 潜藏者",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 46,
                "list": "\nMAC-10 | 表面淬火\nM4A4 | 红色 DDPAT\nUSP 消音版 | 橙红安乐蜥",
                "len": 3,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Black & Tan"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/63d4726faaa5974d66281d4f3d6bbea01a270d3866b7c86280985c529181bcdd.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M249 | 夜半棕榈",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 47,
                "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Midnight Palm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/482e6cb073a132ddad1bddf4972d93ff9a0a69a91876106cbdd9ff2b122e4552.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "FN57 | 紫藤老树",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 48,
                "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Withered Vine"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/49d3f44c67d2bb10446f5e36d9f48e1617446f519fee86d0cfec5cbb8adf36b0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP9 | 残花败藤",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 49,
                "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Old Roots"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/3940d3736546f9b17ceb5dc1ccbebea1b6bbadf4142cf6b7a5ddf1912917b48c.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AUG | 朽木",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.48,
                "itemid": 50,
                "list": "\n加利尔 AR | 渐变琥珀\nG3SG1 | 碧藤青翠\n新星 | 流沙\nP250 | 潜藏者",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Spalted Wood"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/4c40a7d4e8dff4b43969ae6ac81f09e35c74a64d5d331d772c31456c97632f33.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 旱地之花",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.49,
                "itemid": 51,
                "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Parched"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0137c0bddf8e558efe569bcbf56435b8d4035e83d5074abaa72390a92f6c7a16.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 掠夺者",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.48,
                "itemid": 52,
                "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Prey"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/ea8ae0c7d46b09aece5442fa1941ac33071123eaea85f1dd5385baa2da199153.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 白骨",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 53,
                "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Bleached"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/967d916257ca0a9f44555c5bb3c6fcc9d1d908483c78f267337897c6c39a94f9.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P90 | 沙漠 DDPAT",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 54,
                "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Desert DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e18c9986c2c1de2f8d4575d542101675a07ee076706754ae8b406e306c8903f0.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "R8 左轮手枪 | 沙漠伪装",
                "target_box": "set_dust_2_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 55,
                "list": "\nM249 | 夜半棕榈\nFN57 | 紫藤老树\nMP9 | 残花败藤\nAUG | 朽木",
                "len": 4,
                "box_value": "set_dust_2_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Desert Brush"
            }
        ]
    },
    {
        "name": "2021 荒漠迷城收藏品",
        "en_name": "The 2021 Mirage Collection",
        "cover": "https://static.mobi2077.com/market-csgo/a0548c3679cf3e1fce51439ae852dbb72c887c4e7283a5f2646fc26dd520b858.png",
        "type": 1,
        "box_value": "set_mirage_2021",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/2a8614819d572a86ce5ca0f6c39980b84654c0bed47bcbce6cdeb2f57d849beb.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 九头金蛇",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 56,
                "list": "",
                "len": 0,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Desert Hydra"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/cb351761e01e63e0a5181ab24e432e6fff20df1f7e72edc48b657723589b14fd.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 沙漠之狐",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.8,
                "itemid": 57,
                "list": "\nAWP | 九头金蛇",
                "len": 1,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Fennec Fox"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/abe20c592854dc202e8256fea25a9ab86cfb6a1ab9feb0d752c366ce6b448c9e.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP5-SD | 幻化绿洲",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 58,
                "list": "\nAWP | 九头金蛇",
                "len": 1,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Oxide Oasis"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6359e854ee864198c3ed2b2a2dab3e24d811c48152955a6cde2e797849a8a160.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 夜空沙暴",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 59,
                "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
                "len": 2,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Sand Storm"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e579f367022b1ff11f3295a1c9704e41e6e2dd9c01c33d9c36c655a9459d1a77.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 粉红 DDPAT",
                "target_box": "set_mirage_2021",
                "itemid": 60,
                "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
                "len": 2,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "min": 0,
                "max": 1,
                "hash_name": "Glock-18 | Pink DDPAT"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/de03feaebe5f0fdc7b9d1fca636dbfb2095747cc8128e9b66fe005625856ce3c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 雅藤如嫣",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.37,
                "itemid": 61,
                "list": "\n沙漠之鹰 | 沙漠之狐\nMP5-SD | 幻化绿洲",
                "len": 2,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Elegant Vines"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/41bd1a4cf0b3b364ae908ddf491bbdc6bbc726a309b35e2cb80520fa94ed6b97.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 沙漠之花",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.49,
                "itemid": 62,
                "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
                "len": 3,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Desert Blossom"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/72df3ce19911cd94c308b35ee4c3ef3c0812d400facf80c9b5b3e012b902fc1c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 雪茄盒",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 63,
                "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
                "len": 3,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Humidor"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ce39e0481f0ea672d06b25bd027b966fcd237bb116795e2db517b1500504f9cb.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 八音盒",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 64,
                "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
                "len": 3,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Music Box"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0ed532cbf742c0b3c2f839997f26189a2599e858971d13b7e1fc1cc7c94c3d54.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 紫色 DDPAT",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 65,
                "list": "\nAUG | 夜空沙暴\n格洛克 18 型 | 粉红 DDPAT\nXM1014 | 雅藤如嫣",
                "len": 3,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Purple DDPAT"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/f6b89130008db92d1444d3f946b3f84a9886f52ce86527775264206df110f0ca.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P90 | 碧绿升藤",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.47,
                "itemid": 66,
                "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Verdant Growth"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8aff3b20434e3999766e9ff0e3faa876a7a83b4213f1078e4bd96ecc2802da2f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "CZ75 | 夜半棕榈",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 67,
                "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Midnight Palm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/aa09643e62dafac18d2461725887cc85f9516b8ee09eba97a48c9d79c595659c.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "法玛斯 | 加州迷彩",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 68,
                "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | CaliCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/471f425f4da6d0078f7d2f94a83f47851a3ac8abb3ea46e72dce81618d356447.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "双持贝瑞塔 | 浮木",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 69,
                "list": "\nSG 553 | 沙漠之花\nM249 | 雪茄盒\nMP9 | 八音盒\nUSP 消音版 | 紫色 DDPAT",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Drift Wood"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/4bc9c0bfff6404c77383e1f9119d3b733fce15b188b6e645c25c8e9f2de8e493.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 掠夺者",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.48,
                "itemid": 70,
                "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Prey"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/588b315e32137439fff2332388c525d40d86deb7d2846ee2a698c44935912e9a.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 赭色大马士革",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 71,
                "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Sienna Damask"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/92f7b389b3f8ca0f63c1427f01d4298e3485c601629eec2e2978b4b89fc2cd86.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 海军之辉",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 72,
                "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Navy Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/9fd3d8bf50b2ae381a841d0988f48713f6cfc4f8d07b53a6637f1fb15c457478.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 安乐蜥",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 73,
                "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Anolis"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d10df3aa42dc721fcd4db01a138bff418525f22a645279942f3a85619a7770a7.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 天旱",
                "target_box": "set_mirage_2021",
                "min": 0,
                "max": 0.44,
                "itemid": 74,
                "list": "\nP90 | 碧绿升藤\nCZ75 | 夜半棕榈\n法玛斯 | 加州迷彩\n双持贝瑞塔 | 浮木",
                "len": 4,
                "box_value": "set_mirage_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Drought"
            }
        ]
    },
    {
        "name": "2021 列车停放站收藏品",
        "en_name": "The 2021 Train Collection",
        "cover": "https://static.mobi2077.com/market-csgo/f3567fb2496464ba21b21a1518768467a1d470f4f904550b24f326e11148961c.png",
        "type": 1,
        "box_value": "set_train_2021",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/45a2143309ca0b7190d28cbd11188ac5bf674489360354ed8dde8c2e432dd78b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 合纵",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 1,
                "itemid": 75,
                "list": "",
                "len": 0,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | The Coalition"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/68bcf8b7848d844482b8b331540bb5eafde3a8ba346ba5ef92bdcee99865c088.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "格洛克 18 型 | 伽玛多普勒",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 76,
                "list": "",
                "len": 0,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Gamma Doppler"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/6524beb08c9544096d34e35b44030f97fb526c4ad4125183f762acdcaa48d428.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 银装素裹",
                "target_box": "set_train_2021",
                "min": 0.06,
                "max": 0.8,
                "itemid": 77,
                "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
                "len": 2,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Whiteout"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/426742013c0b86777ef5f20e916ec2fae02e92c97b60bf6c27cb6238bbabd68b.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAC-10 | 凝视之眼",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.62,
                "itemid": 78,
                "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
                "len": 2,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Propaganda"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b0c62f693990d93b4abf5ee06619425ca7cd42e77f176a8057e2fa0e6095c723.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 熔化",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.4,
                "itemid": 79,
                "list": "\nM4A4 | 合纵\n格洛克 18 型 | 伽玛多普勒",
                "len": 2,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Meltdown"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b8abf8a1f37aa6115c4451a32f0edeb74fa527397ece89d51f9ba6470200d48f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 晶红石英",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.4,
                "itemid": 80,
                "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
                "len": 3,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Red Quartz"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e2fd9e92baea2d609edde828a66a3a53bc4ba9ea61b6174b7b27be36448927a8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP5-SD | 秋季方巾",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.58,
                "itemid": 81,
                "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
                "len": 3,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Autumn Twilly"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/184ac8f6623cd9213997770ead2d2424be5cd6267575faffa9993390cae60dd0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 太空竞赛",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 82,
                "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
                "len": 3,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Space Race"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b4132bf91372342d6de4a1ffd600669d3c7e9bf1a200db4df5fdda620d431a06.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 复古流行",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.44,
                "itemid": 83,
                "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
                "len": 3,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | POP AWP"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8f4d91026fc7917868773ff1bc003f00a5621d4e5937a0bc57bf97914c184ccb.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 辛迪加",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.7,
                "itemid": 84,
                "list": "\nUSP 消音版 | 银装素裹\nMAC-10 | 凝视之眼\n法玛斯 | 熔化",
                "len": 3,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Syndicate"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d998b5b0b933665479f6de8078d144c6a507cbb2e78a57826dff296cd17ae408.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 炽烈之炎",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.08,
                "itemid": 85,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e071cad321b38882231b02e66d8adeed678e74c4d5c5faa6fa9ceeb15d818105.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 安全网",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 86,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Safety Net"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d6a5f48a8959cfc1c306e30e5a07ce140911840e090a009fe0774f26b1d52fc7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 逮捕者",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.6,
                "itemid": 87,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Full Stop"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4a3211adafa9ff17d852716d5b28cc486b81057476d8505e4c6a87d9916b414f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 渐变琥珀",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.4,
                "itemid": 88,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Amber Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4700a2b64ca71889e96bc20fc3482ebe1d4628cd31d15d45307d6fa3ebce2899.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 春季方巾",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.55,
                "itemid": 89,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Spring Twilly"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a2c538b151c5261340995c24b0ba3be977320385343b3732b9dd5b66c24a6df4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A1 消音型 | 气泡流行",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.57,
                "itemid": 90,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Fizzy POP"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8daf2edfa35a32ef8d2eb35f623d3d2f1bc60f483b16ce4760d1d353904adf53.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 人造卫星",
                "target_box": "set_train_2021",
                "min": 0,
                "max": 0.5,
                "itemid": 91,
                "list": "\n新星 | 晶红石英\nMP5-SD | 秋季方巾\nP2000 | 太空竞赛\nAWP | 复古流行\nCZ75 | 辛迪加",
                "len": 5,
                "box_value": "set_train_2021",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Sputnik"
            }
        ]
    },
    {
        "name": "远古收藏品",
        "en_name": "The Ancient Collection",
        "cover": "https://static.mobi2077.com/market-csgo/ab64da1e0e444b0ae498bfa5619b46cb85bf74d376787d3c739bfba4e40827c5.png",
        "type": 1,
        "box_value": "set_op10_ancient",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/98c517e7d4f52c41209afaa7c4773db7374a29681792b4ee22e27da5655ea066.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 冒险家乐园",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ancient",
                "itemid": 92,
                "list": "",
                "len": 0,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Welcome to the Jungle"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/514ce74f128f1418fe880e203330898f4cdf1128d68bc2544b905d08dfdbb152.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 豹走",
                "target_box": "set_op10_ancient",
                "itemid": 93,
                "list": "\nM4A1 消音型 | 冒险家乐园",
                "len": 1,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "min": 0,
                "max": 1,
                "hash_name": "P90 | Run and Hide"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/932b768d17b207ce27564f959205c44357c3685e2a4df5913ff8b2e99b989591.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 美洲豹",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ancient",
                "itemid": 94,
                "list": "\nM4A1 消音型 | 冒险家乐园",
                "len": 1,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Panthera onca"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/948ec614a6cc0452a8c139e91e1a2ff10753fc534732d06280ecedab65d2f456.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 太古传说",
                "target_box": "set_op10_ancient",
                "itemid": 95,
                "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
                "len": 2,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "min": 0,
                "max": 0.5,
                "hash_name": "XM1014 | Ancient Lore"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f1be890e0a711750a0e5e750888f9dd6a0d841502d20404f6453f0a65989df44.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 远古幻想",
                "min": 0,
                "max": 0.45,
                "target_box": "set_op10_ancient",
                "itemid": 96,
                "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
                "len": 2,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Ancient Visions"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/67ad329f3fe6a83b131b1094207349a995b4716935c6b5284608fa4adb7934e8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 金砖",
                "min": 0,
                "max": 0.7,
                "target_box": "set_op10_ancient",
                "itemid": 97,
                "list": "\nP90 | 豹走\nAK-47 | 美洲豹",
                "len": 2,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Gold Brick"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2748bf0b5d2e44155857140e9b36b7d59ef5bb0d3e09d4350f8c0d4af629ca3c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 上古图腾",
                "target_box": "set_op10_ancient",
                "itemid": 98,
                "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
                "len": 3,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "min": 0,
                "max": 0.65,
                "hash_name": "Tec-9 | Blast From the Past"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/68dd1a40fdcc67047eeb7bd730e78eddb54468ad77320b1e27c680127e788089.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 黑水",
                "min": 0.1,
                "max": 0.26,
                "target_box": "set_op10_ancient",
                "itemid": 99,
                "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
                "len": 3,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Dark Water"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1be279932b219fda9132f34d5b758d83711e4f9362acf7dffd458779434c7cca.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 璞玉",
                "min": 0,
                "max": 0.2,
                "target_box": "set_op10_ancient",
                "itemid": 100,
                "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
                "len": 3,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Carved Jade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/45cf52335b5492c08ff7bb85650e61547f3a32a775559b5a35aa40279b429261.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 废墟黄昏",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_ancient",
                "itemid": 101,
                "list": "\nXM1014 | 太古传说\nUSP 消音版 | 远古幻想\nMAC-10 | 金砖",
                "len": 3,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Dusk Ruins"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/487abb0be5eeeccf96fb3f3ef2be1c32dcd0624299f3e170b3516069f8f1418f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "CZ75 | 银质",
                "min": 0,
                "max": 0.08,
                "target_box": "set_op10_ancient",
                "itemid": 102,
                "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Silver"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/151c444e7b80bc3285034840e2fb095c79b7769de41a1a859f8ac6fd198f187c.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 豹纹迷彩",
                "min": 0,
                "max": 0.5,
                "target_box": "set_op10_ancient",
                "itemid": 103,
                "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Panther Camo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ff6606ee9a91744c84be9415f5c87f23778f0ed31036586ad36b1466d2ea4544.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "G3SG1 | 远古仪式",
                "min": 0,
                "max": 0.76,
                "target_box": "set_op10_ancient",
                "itemid": 104,
                "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Ancient Ritual"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/82e4fd0490537b269039665e4ea97d29017998458e3fb718bc0f400862bbec74.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP7 | 绿野迷踪",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_ancient",
                "itemid": 105,
                "list": "\nTec-9 | 上古图腾\n法玛斯 | 黑水\nAUG | 璞玉\n加利尔 AR | 废墟黄昏",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Tall Grass"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/90548555e486129613852b713159ee9311263dae003b6cf198e21140fbffa4d0.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 废墟丛生",
                "min": 0,
                "max": 0.7,
                "target_box": "set_op10_ancient",
                "itemid": 106,
                "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Lush Ruins"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/65e04026af26b89ee8fce8a10274128e3584c8020e2680faecef79e68bff32da.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_op10_ancient",
                "itemid": 107,
                "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/293541e691aada6980848595d8bd94569a7efebf50d7c42c5fad97a26a9ed7ba.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P90 | 古老星球",
                "min": 0,
                "max": 0.5,
                "target_box": "set_op10_ancient",
                "itemid": 108,
                "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Ancient Earth"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/98b95bf7e80041f8d2ea56d32ff3e4fb482d165b0e0e9bcaed6904c09dcc2608.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "R8 左轮手枪 | 噩梦之夜",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ancient",
                "itemid": 109,
                "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Night"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/fe3f94e79f5ffd74909523c7496611a607ae6d96c61e9179bce2970087a582ad.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 丛林虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ancient",
                "itemid": 110,
                "list": "\nCZ75 | 银质\nP2000 | 豹纹迷彩\nG3SG1 | 远古仪式\nMP7 | 绿野迷踪",
                "len": 4,
                "box_value": "set_op10_ancient",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Jungle Dashed"
            }
        ]
    },
    {
        "name": "浩劫收藏品",
        "en_name": "The Havoc Collection",
        "cover": "https://static.mobi2077.com/market-csgo/6ce05686b9397c7c2ca2a02e0b509c0d51ea105eb9c9d3273b895e1589ca8b68.png",
        "type": 1,
        "box_value": "set_op10_t",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/a10f0eafbca3a4dd19f920d45a672b22f060699355056fd5c8b4085cf0a29fde.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | X 射线",
                "target_box": "set_op10_t",
                "itemid": 111,
                "list": "",
                "len": 0,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "min": 0,
                "max": 1,
                "hash_name": "AK-47 | X-Ray"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f0f550638252dd49cf1fbd51287aad7da49b6525a8ba0ac334d87c3718341d21.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAC-10 | 狂蟒之灾",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_t",
                "itemid": 112,
                "list": "\nAK-47 | X 射线",
                "len": 1,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Hot Snakes"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b9b720060fcde05a03763e9248e6c427d9dd7511039ea5a782abab3e7cfb4624.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 锦虎",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_t",
                "itemid": 113,
                "list": "\nAK-47 | X 射线",
                "len": 1,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Silk Tiger"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8d6034fbe3c48a2caafe2d49af8b026e1c9604ced850e0f2d21466e59136788c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 蛊惑之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_op10_t",
                "itemid": 114,
                "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
                "len": 2,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Hypnotic"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c0ded8bd2318be6a26dc45da2e04d3c3795db4c955b2e37dc4920d09ebcd0822.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 凤凰冥灯",
                "min": 0,
                "max": 0.7,
                "target_box": "set_op10_t",
                "itemid": 115,
                "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
                "len": 2,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Phoenix Blacklight"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7533a37bad838f960cc86706fc3ae9452c9ad8a5b37714764aef565a9430110d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 富兰克林",
                "min": 0,
                "max": 0.4,
                "target_box": "set_op10_t",
                "itemid": 116,
                "list": "\nMAC-10 | 狂蟒之灾\nAWP | 锦虎",
                "len": 2,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Franklin"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0b30f171b129eed95066ad3259f4d12c29b4f5e3994fe84efcd9e842b378f49e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 凤凰徽记",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_t",
                "itemid": 117,
                "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
                "len": 3,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Phoenix Stencil"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/72deb68968274ed0835016d7d339db46b1767e124fbb88c4c3ee441013403f90.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 困兽之斗",
                "min": 0,
                "max": 0.5,
                "target_box": "set_op10_t",
                "itemid": 118,
                "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
                "len": 3,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Tiger Pit"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/85bc555497297168a3afd4752b9ece64c9239b6871228d40c23d691924a10a32.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 午夜凶匪",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_t",
                "itemid": 119,
                "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
                "len": 3,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Night Heist"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d9d59332e29835000653e39f8e3589968b9bba775473cf63ea24c48c1b438b87.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 孟加拉猛虎",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_op10_t",
                "itemid": 120,
                "list": "\nSG 553 | 蛊惑之色\n加利尔 AR | 凤凰冥灯\n格洛克 18 型 | 富兰克林",
                "len": 3,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Bengal Tiger"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/dbd8b1b4d583faf9c143d51f15a303fcf4fc78e804c52085cc80708a32fa9a4f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "R8 左轮手枪 | 凤凰手迹",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_t",
                "itemid": 121,
                "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Phoenix Marker"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c33aa437a3998fd826d19f16b3da10e25626281f9cfd8a1f5a370f3287746a0a.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP7 | 金库悍匪",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_t",
                "itemid": 122,
                "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Vault Heist"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/3527a494619b2d5b12492701d6e594829cd4f825160366d8669f48c247102b5d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "UMP-45 | 犬牙",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_t",
                "itemid": 123,
                "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Houndstooth"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c62c44d39885a5654c2b30e304074d82e4af139f1fc74f59531bee2df0f0ab8a.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "新星 | 外表生锈",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_t",
                "itemid": 124,
                "list": "\n内格夫 | 凤凰徽记\nP90 | 困兽之斗\n沙漠之鹰 | 午夜凶匪\nP250 | 孟加拉猛虎",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Rust Coat"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/aa634bb316b646e33aaa40ce6bcf4860183f794191c61791d563baa9a9497dda.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 凤凰涂鸦",
                "min": 0,
                "max": 0.8,
                "target_box": "set_op10_t",
                "itemid": 125,
                "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Phoenix Chalk"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/1acab6f7b846318e18aa76957b5b03a689bbbabe7a82d3d654df976252d4d4af.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 夺命撼响",
                "min": 0.08,
                "max": 0.5,
                "target_box": "set_op10_t",
                "itemid": 126,
                "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Death Rattle"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/17cecfbf4e07b172215327d5fef694d18b12ece804ad3c9933da23dd1eec79b3.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 伏击者",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_t",
                "itemid": 127,
                "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Clay Ambush"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/4d4a16c240565be1a5bb0d617961ecff0fdae08775f539f9b257a0bf39f75844.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 街头抢匪",
                "min": 0,
                "max": 0.7,
                "target_box": "set_op10_t",
                "itemid": 128,
                "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Heist"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/880b766ab7c0c163eb5e0725f5b5d4b5c41148269146929a3f95197b136aca7f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "M249 | 捕食者",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_t",
                "itemid": 129,
                "list": "\nR8 左轮手枪 | 凤凰手迹\nMP7 | 金库悍匪\nUMP-45 | 犬牙\n新星 | 外表生锈",
                "len": 4,
                "box_value": "set_op10_t",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Predator"
            }
        ]
    },
    {
        "name": "控制收藏品",
        "en_name": "The Control Collection",
        "cover": "https://static.mobi2077.com/market-csgo/cbd9494ac8b7f5f5de8401fdfb2b20bccf238bb7284d5f46bc5c5e0393e97d1b.png",
        "type": 1,
        "box_value": "set_op10_ct",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/06775d2a81dc9f4bd4566e610d295064dc360f36499366cc0e99eed5418df55c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 渐变之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_op10_ct",
                "itemid": 130,
                "list": "",
                "len": 0,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Fade"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/56f0e951de8f2a8463c3b50d34405e7ea352043fbf3d76a9c9037843343a21d2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 澜磷",
                "min": 0,
                "max": 0.08,
                "target_box": "set_op10_ct",
                "itemid": 131,
                "list": "\nAWP | 渐变之色",
                "len": 1,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Blue Phosphor"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/cf48cf57da886473ff5e5d0b1c1b2f7cb4f9b54b05950cc0a3da041dc1917ce7.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 锁定",
                "min": 0,
                "max": 0.5,
                "target_box": "set_op10_ct",
                "itemid": 132,
                "list": "\nAWP | 渐变之色",
                "len": 1,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Target Acquired"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7f31552dd83f0cc8545e667c6cdb6fc7accc04210de00bd93b7ce1b19d1ca64f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 蓝莓樱桃",
                "min": 0,
                "max": 0.1,
                "target_box": "set_op10_ct",
                "itemid": 133,
                "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
                "len": 2,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Berries And Cherries"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/738b4972ff35cbec532a8e5bfce67f871b590eecfbcf8b80c1eb10943f43ca83.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 犯罪现场",
                "min": 0,
                "max": 0.75,
                "target_box": "set_op10_ct",
                "itemid": 134,
                "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
                "len": 2,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Crime Scene"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1d24c62414b4b0c643ebcbc8544156245eb04320865d3bc49e1470aee045dd31.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 幕后主谋",
                "min": 0,
                "max": 0.55,
                "target_box": "set_op10_ct",
                "itemid": 135,
                "list": "\nM4A1 消音型 | 澜磷\nUSP 消音版 | 锁定",
                "len": 2,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Prime Conspiracy"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1e76a6a98b44de8c27b21faef9c38c41f3836ffbbaae61c97750d36373d16d6f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 侦测",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_ct",
                "itemid": 136,
                "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
                "len": 3,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Threat Detected"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0606e83ba9e57b65b4674099946f4480641446928044b4679b9cc270b59a2400.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 大宪章",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_ct",
                "itemid": 137,
                "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
                "len": 3,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Magna Carta"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a902a24753f3f6688209493c2fe7b65c714b7280f3ed7b31bf79ae131b425d28.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 绝命密电",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_ct",
                "itemid": 138,
                "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
                "len": 3,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Dispatch"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2638806b4f7aad5c21aa7d6fa1de65f05fe12112e47d38f44ddcf7911ee0b362.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 全球攻势",
                "min": 0,
                "max": 0.7,
                "target_box": "set_op10_ct",
                "itemid": 139,
                "list": "\nFN57 | 蓝莓樱桃\nUMP-45 | 犯罪现场\n法玛斯 | 幕后主谋",
                "len": 3,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Global Offensive"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b9a6548f83887ac7b7eaeb4266bc17020972074ddfa5424cd30f09b4f4b79b0e.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAG-7 | 碳素纤维",
                "min": 0,
                "max": 0.12,
                "target_box": "set_op10_ct",
                "itemid": 140,
                "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Carbon Fiber"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8c55b476283c96c1a2acce27dce6d5eaf29bb86f4bb23b6949b6eef71c45e636.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "双持贝瑞塔 | 暗网总机",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_ct",
                "itemid": 141,
                "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Switch Board"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/74628b64613474a2e76e75736b189e77782beb2150a3e59b37ecbbef94610102.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "沙漠之鹰 | 古铜密码",
                "min": 0,
                "max": 0.3,
                "target_box": "set_op10_ct",
                "itemid": 142,
                "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | The Bronze"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/0a263d3dd7813a89cce4f210b25dbff1aac811dac3fd81dc14f178e9639ad2c9.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP5-SD | 氮化处理",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_op10_ct",
                "itemid": 143,
                "list": "\nSSG 08 | 侦测\nSCAR-20 | 大宪章\nP2000 | 绝命密电\nM4A4 | 全球攻势",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Nitro"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6bbbe760cfca0986321e292a3f7aaa3b3b7ba85c3762970c0e86eb7d5b73fa4a.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 搜索",
                "min": 0,
                "max": 0.6,
                "target_box": "set_op10_ct",
                "itemid": 144,
                "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Surveillance"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6734cd0c2d6413b2017548fd5696aa91f5beaa9488383960d2f6c92091198442.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 旧宪章",
                "min": 0,
                "max": 0.65,
                "target_box": "set_op10_ct",
                "itemid": 145,
                "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Charter"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e1b6bf45e651ba29b6071583e91fa3647a06637013217356ea5655d14d9583bd.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_op10_ct",
                "itemid": 146,
                "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3e6aa8bbf79674b6cf812f769e38edf872f0fb50cc32c5c71c4fe419b44c90ee.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 暮色森林",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ct",
                "itemid": 147,
                "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Forest Night"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/869ee3da97d258ef18eccde705e3f4d3c8831cc61bdb00f05c108b7a056c6894.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "CZ75 | 丛林虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_op10_ct",
                "itemid": 148,
                "list": "\nMAG-7 | 碳素纤维\n双持贝瑞塔 | 暗网总机\n沙漠之鹰 | 古铜密码\nMP5-SD | 氮化处理",
                "len": 4,
                "box_value": "set_op10_ct",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Jungle Dashed"
            }
        ]
    },
    {
        "name": "挪威人收藏品",
        "en_name": "The Norse Collection",
        "cover": "https://static.mobi2077.com/market-csgo/e336fafe9736e89d6fbc9c153d63ded2ac3c1027584179b2f1969a41e17d7a89.png",
        "type": 1,
        "box_value": "set_norse",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/0dfcdc049efc9680697823b703c83799fff39a75be4ea87f91855619f5d18695.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 永恒之枪",
                "min": 0,
                "max": 0.6,
                "target_box": "set_norse",
                "itemid": 149,
                "list": "",
                "len": 0,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Gungnir"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d2525f42b99e513b0893b2827d1f4aabf10c1a49f5e7ab33e66d83fafef2c8e3.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "内格夫 | 雷神之锤",
                "min": 0,
                "max": 0.6,
                "target_box": "set_norse",
                "itemid": 150,
                "list": "\nAWP | 永恒之枪",
                "len": 1,
                "box_value": "set_norse",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Mjölnir"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4befb2a59d1d5ca157e03c688e6c00bda775ed3543e88c9bcdf48c65778ea8d0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 翡翠巨蟒",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 151,
                "list": "\n内格夫 | 雷神之锤",
                "len": 1,
                "box_value": "set_norse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Emerald Jörmungandr"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c38d6c110a43f9e8be7092c25962a2cb9ade154045a1d6194562e9443985807f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 星辰巨蟒",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 152,
                "list": "\n内格夫 | 雷神之锤",
                "len": 1,
                "box_value": "set_norse",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Astral Jörmungandr"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2531af1d507a11a5a6a7f703b7cf8d0e182f538d9daf4d100be0051c77e117a1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 烈焰巨蟒",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 153,
                "list": "\n内格夫 | 雷神之锤",
                "len": 1,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Flame Jörmungandr"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cb0fb9e63f11e7600b3f924770cb15ae4eaf9d937075479f99657b664c3e752b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 寒霜锁链",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 154,
                "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
                "len": 3,
                "box_value": "set_norse",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Frost Borre"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/375333ec27bbd018fe3cf24285d678d20113a0e461fe0ddb0dd85c11fcc80165.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 黄铜",
                "min": 0,
                "max": 1,
                "target_box": "set_norse",
                "itemid": 155,
                "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
                "len": 3,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Brass"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3593464a677957f38a0799e3ed312974944c3de7b4cb12f2fb1e76a973240191.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 赤金锁链",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 156,
                "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
                "len": 3,
                "box_value": "set_norse",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Copper Borre"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/216f4db18a9cb46f98be4d193bc32acdc5d09f3c55080c69f4936001bc184049.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 翡翠石英",
                "min": 0,
                "max": 0.7,
                "target_box": "set_norse",
                "itemid": 157,
                "list": "\n沙漠之鹰 | 翡翠巨蟒\nP90 | 星辰巨蟒\nAUG | 烈焰巨蟒",
                "len": 3,
                "box_value": "set_norse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Emerald Quartz"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8be1f098a7d80ac2df43ed582c2de247db07d00cc4b1dba56ecbc148447f712a.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "双持贝瑞塔 | 熊熊烈焰",
                "min": 0.06,
                "max": 0.55,
                "target_box": "set_norse",
                "itemid": 158,
                "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Pyre"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/5c0ac5fe2370089984a25fe6309982a18177216d9ec068b811b9c40fd11d4f5b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "USP 消音版 | 寻路者",
                "min": 0,
                "max": 0.35,
                "target_box": "set_norse",
                "itemid": 159,
                "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Pathfinder"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/19e355124489f8fe322e2aa8982c0fcb55e484aaaecd58a11e885d2d9c1b63f0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A1 消音型 | 苔藓石英",
                "min": 0,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 160,
                "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Moss Quartz"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c1acfe906c8e4774dedaa9f063fcbe217b2befe4642f3864d7926b0f8be0a410.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAG-7 | 坚固链甲",
                "min": 0,
                "max": 0.22,
                "target_box": "set_norse",
                "itemid": 161,
                "list": "\nXM1014 | 寒霜锁链\nSCAR-20 | 黄铜\nMAC-10 | 赤金锁链\nCZ75 | 翡翠石英",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Chainmail"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/a2662cd01107cf32592ee5002798716be44c1cb8f807138dec3605ed89167075.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 枯焦之色",
                "min": 0,
                "max": 1,
                "target_box": "set_norse",
                "itemid": 162,
                "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Scorched"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/4ade130db2efbfc587ff48c920c704944fb73690a22b031a7e18175f8f80f1f8.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "加利尔 AR | 狂哮飓风",
                "min": 0,
                "max": 1,
                "target_box": "set_norse",
                "itemid": 163,
                "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Tornado"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/b8859b85e88c222a1acead334fa6fc912cf0c5c96644e6884f519d7c7fc3b19a.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 血染风采",
                "min": 0.06,
                "max": 0.5,
                "target_box": "set_norse",
                "itemid": 164,
                "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Red Stone"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d11547f17fbc8a950c4a56bbac6e7b847edd7eb6c052e3bcdcf7432028273ace.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "法玛斯 | 暗夜锁链",
                "min": 0,
                "max": 0.55,
                "target_box": "set_norse",
                "itemid": 165,
                "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Night Borre"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6ab04994348998f5d47e7b8cac9550ee3c78c0bf6c4c1350731609fdf032533f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 路障",
                "min": 0,
                "max": 0.65,
                "target_box": "set_norse",
                "itemid": 166,
                "list": "\n双持贝瑞塔 | 熊熊烈焰\nUSP 消音版 | 寻路者\nM4A1 消音型 | 苔藓石英\nMAG-7 | 坚固链甲",
                "len": 4,
                "box_value": "set_norse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Barricade"
            }
        ]
    },
    {
        "name": "运河水城收藏品",
        "en_name": "The Canals Collection",
        "cover": "https://static.mobi2077.com/market-csgo/c75523654255a7bcb8f3c89e46fb7622d8ba63a29303b40769e6ae62c4bac51a.png",
        "type": 1,
        "box_value": "set_canals",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/8e91e23ef7648ffa4be9d9436c500a622ac7a253112e42a4144f00f4d1fd8a48.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 王子",
                "min": 0,
                "max": 0.6,
                "target_box": "set_canals",
                "itemid": 167,
                "list": "",
                "len": 0,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | The Prince"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/7edc578cb4dd6bd43459a06247615482a5d091f2800c10fed61e80a3b3d009d0.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAG-7 | 五指短剑",
                "min": 0,
                "max": 1,
                "target_box": "set_canals",
                "itemid": 168,
                "list": "\nAWP | 王子",
                "len": 1,
                "box_value": "set_canals",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Cinquedea"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/16772456925da02237d1b10178cc2731bc52bea71c563695ab6ef6c0cd3c17a5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 彩绘玻璃",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 169,
                "list": "\nMAG-7 | 五指短剑",
                "len": 1,
                "box_value": "set_canals",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Stained Glass"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/844f0a822eeb018190d10cccdc69cfbb9b9cf417f888a70fabd6616fb2892df9.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 巴洛克之橙",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 170,
                "list": "\nMAG-7 | 五指短剑",
                "len": 1,
                "box_value": "set_canals",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Baroque Orange"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3c5cfea58327d713372fb085e5042ba0509b5d32249690c1423bf099bc2a80cd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 绯红镂刻",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 171,
                "list": "\nMAG-7 | 五指短剑",
                "len": 1,
                "box_value": "set_canals",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Red Filigree"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3299c99d9a161c3a2f41c0fa10e43e1659901fa1f9e65d8e8a369b92d2cc93ce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 橙黄镂刻",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 172,
                "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
                "len": 3,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Orange Filigree"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d5a62b53763aa4e4ad8b3a5265ea19cef7b2b4688358f70db66af3d90f900d9e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 穆拉诺之紫",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 173,
                "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
                "len": 3,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Violet Murano"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3edcd06702b8d4b4014a57fb3f611b3fc915ab4a9babc6dcaad759aa3744073e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 翡翠色调",
                "min": 0,
                "max": 0.08,
                "target_box": "set_canals",
                "itemid": 174,
                "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
                "len": 3,
                "box_value": "set_canals",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Emerald"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6431a21f8db72e48a5fe2c8f05c00869e2d52dc45eaa58862120aad5b3ac8c15.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 巴洛克之红",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 175,
                "list": "\nMP9 | 彩绘玻璃\n新星 | 巴洛克之橙\nMAC-10 | 绯红镂刻",
                "len": 3,
                "box_value": "set_canals",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Baroque Red"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c5ab20cfbb2f2be6440fecbc8a7db18de7ce0743deacb3fc63608c5bff436131.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AK-47 | 巴洛克之紫",
                "min": 0,
                "max": 1,
                "target_box": "set_canals",
                "itemid": 176,
                "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Baroque Purple"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/926e5fe295257742857e82f03b5a5e7f98535d1758e6307cfc1f48fd4077fd05.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SG 553 | 红苹果",
                "min": 0,
                "max": 0.7,
                "target_box": "set_canals",
                "itemid": 177,
                "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Candy Apple"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ec70e38a0771c2ecf4cba4dfe8699e4067224f14b3710883dae72b571acacde0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 暗之镂刻",
                "min": 0,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 178,
                "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Dark Filigree"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ff69f6a732046f68050e95b80f663ac40d7fc38fe61431963f4293aff9e5f301.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "Tec-9 | 穆拉诺之橙",
                "min": 0,
                "max": 1,
                "target_box": "set_canals",
                "itemid": 179,
                "list": "\nSSG 08 | 橙黄镂刻\nG3SG1 | 穆拉诺之紫\n双持贝瑞塔 | 翡翠色调\nP90 | 巴洛克之红",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Orange Murano"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/2e31bac854a388901b6f36f3b93357fe6872d6d798a0e785ae1b7f7dc30433a4.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 穆拉诺之蓝",
                "min": 0,
                "max": 1,
                "target_box": "set_canals",
                "itemid": 180,
                "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Navy Murano"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cc3ca8f43efa91abd762f650bb84e98197ac5ecfe910687cc81e11d018170c82.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SCAR-20 | 石砌",
                "min": 0.06,
                "max": 0.5,
                "target_box": "set_canals",
                "itemid": 181,
                "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Stone Mosaico"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3cd1c49256a151150cb404fdbd5a94ce2e43b11c9abb76147e3eb0ed4ac96ac9.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "R8 左轮手枪 | 水都泛波",
                "min": 0.06,
                "max": 0.6,
                "target_box": "set_canals",
                "itemid": 182,
                "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Canal Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/39fddffd75633a7c226974d23b868bd35e23ee38010aa61028e18e46d8aa7c61.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "CZ75 | 紫青之色",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_canals",
                "itemid": 183,
                "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Indigo"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8a5a1d13dd292f42a9d30d0786e87abd8797682cc418256807c6cc95cc30e3a5.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "内格夫 | 巴洛克之沙",
                "min": 0,
                "max": 1,
                "target_box": "set_canals",
                "itemid": 184,
                "list": "\nAK-47 | 巴洛克之紫\nSG 553 | 红苹果\nP250 | 暗之镂刻\nTec-9 | 穆拉诺之橙",
                "len": 4,
                "box_value": "set_canals",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Boroque Sand"
            }
        ]
    },
    {
        "name": "圣马克镇收藏品",
        "en_name": "The St. Marc Collection",
        "cover": "https://static.mobi2077.com/market-csgo/5bf2813583cd0b44c8ce9a221909aec8a4effdf77ed4ff93ee93465416ead8f1.png",
        "type": 1,
        "box_value": "set_stmarc",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/974ee8ff33bb126e92b73b037e6a00fd7dd5ed13f9d2662df9c54aaf2814006a.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 野荷",
                "target_box": "set_stmarc",
                "itemid": 185,
                "list": "",
                "len": 0,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "min": 0,
                "max": 1,
                "hash_name": "AK-47 | Wild Lotus"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/cdc601745236b88db058d46bd61bcabc8768c2fc2f4d51b81824b0ab39ea5f28.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP9 | 野百合",
                "min": 0,
                "max": 0.7,
                "target_box": "set_stmarc",
                "itemid": 186,
                "list": "\nAK-47 | 野荷",
                "len": 1,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Wild Lily"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8bd350463d3b82fedb6879515896c242aa76d0ed51623e7cb18c069796a7cc94.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 午夜百合",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 187,
                "list": "\nMP9 | 野百合",
                "len": 1,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Midnight Lily"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f69c7364ebc404a3ce4873eba4808e62cad5a1032babd334117e4e8eb0d28464.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SSG 08 | 海滨印花",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 188,
                "list": "\nMP9 | 野百合",
                "len": 1,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Sea Calico"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/68b878afc54d840bc7289f04f55c6766f66740eb59e0c0773e29750dab7badf8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 合成叶",
                "min": 0,
                "max": 0.7,
                "target_box": "set_stmarc",
                "itemid": 189,
                "list": "\nMP9 | 野百合",
                "len": 1,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Synth Leaf"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/28c4bb1d177cd7a39f44457f0227b8acb27d9d7c0c97c1fdd1a76398923c0fe5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 青之绽放",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 190,
                "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
                "len": 3,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Teal Blossom"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b21329a33db27c01c7983f29577430f180d1861936ae3894821d03174d5ec12e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 忘忧草",
                "min": 0,
                "max": 0.55,
                "target_box": "set_stmarc",
                "itemid": 191,
                "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
                "len": 3,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Day Lily"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6cb50bb87a99185a76a3f10b3ad980b0d10ff3b5639e97123fd045409d6cbfa7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 日暮",
                "min": 0,
                "max": 0.6,
                "target_box": "set_stmarc",
                "itemid": 192,
                "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
                "len": 3,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Sundown"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f4f098549bb4e4a2d7c405c095238203a277c9fa8f136c8931879c4779573881.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 绛之绽放",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 193,
                "list": "\nAUG | 午夜百合\nSSG 08 | 海滨印花\n格洛克 18 型 | 合成叶",
                "len": 3,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Crimson Blossom"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/57429b56582cc333db64a03b1eacc94c6fad952d56dc9ce89a11f29a5ba4128c.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "Tec-9 | 锈叶",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 194,
                "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Rust Leaf"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/e2e530485c19da4d1133d9ffcb34b12a61ff1205dc5b1ac660bad0809abe6fcb.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 暗之绽放",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 195,
                "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Dark Blossom"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d4e6d71aa8fde9e81e8d59b8a9b58b8eba9404b3804a8456c3dae8cfea06376e.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "XM1014 | 芭蕉叶",
                "min": 0,
                "max": 0.6,
                "target_box": "set_stmarc",
                "itemid": 196,
                "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Banana Leaf"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ae17a91681b861e754b3e77f2f6eed3462c15b4ec5cbfa65d17dd924794cf340.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P90 | 日落百合",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 197,
                "list": "\nMP7 | 青之绽放\nUMP-45 | 忘忧草\n法玛斯 | 日暮\nFN57 | 绛之绽放",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Sunset Lily"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/2811da2934b17b275d7027ebcffabdc97e8fd17f07d868aabdd30094d1dcf465.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "M249 | 狂野丛林",
                "min": 0,
                "max": 1,
                "target_box": "set_stmarc",
                "itemid": 198,
                "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Jungle"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3717ebc4cce050029cae3fcb552d11695bf3bae0519d60d3956dda6c79414a6d.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 海鸟",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 199,
                "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Seabird"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0f1a8fd8e6f8d13a73f1331bc013b4ec521979a657b86bd194fcd80e795946d6.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP5-SD | 茂竹之园",
                "min": 0,
                "max": 0.55,
                "target_box": "set_stmarc",
                "itemid": 200,
                "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Bamboo Garden"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3482d7e5dc8458ccd3ebc0bf1af38e66a644165d97953f0a8443bd8aaaf2366d.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 冲浪木",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 201,
                "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Surfwood"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/47d25fa1e97851dc84dce9181d55e883e59dda214de5bde57e800d5134db14f1.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 灌木丛",
                "min": 0,
                "max": 0.5,
                "target_box": "set_stmarc",
                "itemid": 202,
                "list": "\nTec-9 | 锈叶\nM4A4 | 暗之绽放\nXM1014 | 芭蕉叶\nP90 | 日落百合",
                "len": 4,
                "box_value": "set_stmarc",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Jungle Thicket"
            }
        ]
    },
    {
        "name": "炼狱小镇收藏品2018",
        "en_name": "The 2018 Inferno Collection",
        "cover": "https://static.mobi2077.com/market-csgo/6561649d8dfff0e9388705fcb85061e12e565f3eec89ab16eba6acffd4ee85c3.png",
        "type": 1,
        "box_value": "set_inferno_2",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ec8da48ab8aeadf10024a82ce2688f0fc3657df8b4cf749d056023cc56690492.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SG 553 | 意式拉力",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 203,
                "list": "",
                "len": 0,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Integrale"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/7bc196f62090f06e2b5201f299f7b10b6d804998977556c47f6a8eef7f29d732.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "双持贝瑞塔 | 双涡轮",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 204,
                "list": "",
                "len": 0,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Twin Turbo"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9d719acd57c316008eab6b0f38cb2d02ce8587b3098c30cc2a1fd19d5808f61f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 安全网",
                "min": 0,
                "max": 0.6,
                "target_box": "set_inferno_2",
                "itemid": 205,
                "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
                "len": 2,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Safety Net"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/436850ae88217677f44c16a5f191dfd4461c1204264cdffef39da115090e6a24.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 渐变之色",
                "min": 0,
                "max": 0.25,
                "target_box": "set_inferno_2",
                "itemid": 206,
                "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
                "len": 2,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Fade"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5aee993f3c35c6bd50d87e27d52abfede39a1313baeca1082cbaa47fb3386f95.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 葡萄酒",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 207,
                "list": "\nSG 553 | 意式拉力\n双持贝瑞塔 | 双涡轮",
                "len": 2,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Vino Primo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/adbccb3c8ad1be06dddea8cc96e7f3eb1509ad0cb83145215ec524464ee58713.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 刹车灯",
                "min": 0,
                "max": 0.08,
                "target_box": "set_inferno_2",
                "itemid": 208,
                "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
                "len": 3,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Brake Light"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/57fab8c97a60d3733b48a8df3597cf7864909102fbd79e68856fff6057c929fe.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 引擎故障灯",
                "min": 0,
                "max": 0.6,
                "target_box": "set_inferno_2",
                "itemid": 209,
                "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
                "len": 3,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Check Engine"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/18a60343772e7f708b1de64a64d2686428c0f53e0362b2eb707b56550ddaf937.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 变频器",
                "min": 0,
                "max": 0.4,
                "target_box": "set_inferno_2",
                "itemid": 210,
                "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
                "len": 3,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Converter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/17ad29714cc72c78ebc30e3aa0adf6bc83963e03bf0fde07adb14f3d7c5a46ae.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 手刹",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 211,
                "list": "\nAK-47 | 安全网\nMP7 | 渐变之色\nP250 | 葡萄酒",
                "len": 3,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Hand Brake"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/6b0b042b488388f3e9a2df020e68a301df233f7b7daa9be55b7ee6b5e509cb39.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 远光灯",
                "min": 0,
                "max": 0.08,
                "target_box": "set_inferno_2",
                "itemid": 212,
                "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | High Beam"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/dd5ed561fc4c9b799ebe6661cb1383e32195475a6e9fadf9b2617d2384796309.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "R8 左轮手枪 | 氮化处理",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_inferno_2",
                "itemid": 213,
                "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Nitro"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8c0c3917663bf56f4023e91b05a5e25780e3a3badd4a1fa020b81cb86dc1d843.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAC-10 | 小牛皮",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 214,
                "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Calf Skin"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/12069430be3b0cba66de00370ce7f55e28967938a175c6fe24180ce542402fa2.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "PP-野牛 | 红苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_inferno_2",
                "itemid": 215,
                "list": "\n截短霰弹枪 | 刹车灯\nUSP 消音版 | 引擎故障灯\nM4A4 | 变频器\nSSG 08 | 手刹",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Candy Apple"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e011345efe76017c9d55e5b33a74a697fb4698166cb0000a31568bee465489c4.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 外表生锈",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 216,
                "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Rust Coat"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e66f9b57df3350825fbe5bf3a80414fbd3d994d7d65c71401b5a9edb5f1573ee.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 扫频仪",
                "min": 0,
                "max": 0.6,
                "target_box": "set_inferno_2",
                "itemid": 217,
                "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Sweeper"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6aa73883e463d6cca717e3de05a89a460e89b2593192b060077bf60aa75d2a10.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 滑移",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_inferno_2",
                "itemid": 218,
                "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Slide"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/05c866722239cc2b0e5d4869b643d06d157a4bd62cc6e9a16227cf7f7dc4a208.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP5-SD | 越野",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_inferno_2",
                "itemid": 219,
                "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Dirt Drop"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/02ab02396b23c6f54af185347aab25ca671f9c2b6d533cc8db47e4187da21441.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 泥地杀手",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno_2",
                "itemid": 220,
                "list": "\n格洛克 18 型 | 远光灯\nR8 左轮手枪 | 氮化处理\nMAC-10 | 小牛皮\nPP-野牛 | 红苹果",
                "len": 4,
                "box_value": "set_inferno_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Mudder"
            }
        ]
    },
    {
        "name": "核子危机收藏品2018",
        "en_name": "The 2018 Nuke Collection",
        "cover": "https://static.mobi2077.com/market-csgo/164ce4ff281a39bd8345318e3bea0d5812bdc0c1fcc26b1c536d862b29750c12.png",
        "type": 1,
        "box_value": "set_nuke_2",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3da20930e8a076104fed8c46a3d8485ca818505d9bae9b3e7bb211c345b74890.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 控制台",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke_2",
                "itemid": 221,
                "list": "",
                "len": 0,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Control Panel"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/7e634af9915b44d34e9d5ce58d9f2b7a1944fcf14a23d46db1b345192e97295c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "Tec-9 | 遥控",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke_2",
                "itemid": 222,
                "list": "",
                "len": 0,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Remote Control"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e6f0ac46a4b2123a205361824f9e59d024fe7781bb150a06d38deadb8821f28e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 核子花园",
                "min": 0,
                "max": 0.7,
                "target_box": "set_nuke_2",
                "itemid": 223,
                "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
                "len": 2,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Nuclear Garden"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4a47c6b6e76a6353ebb46a9e7932c32b7051d7985ad2380ff6b444ca145280cc.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 核芯破裂",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke_2",
                "itemid": 224,
                "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
                "len": 2,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Core Breach"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/784e72a85bd6c23a40a7f5b8bcbf2e99d40b8ba86d36500b1c1c60c51ace22b0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 随机存取",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 225,
                "list": "\nM4A1 消音型 | 控制台\nTec-9 | 遥控",
                "len": 2,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Random Access"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/54d9bf8729fc788d1a01a8df164e9b3a0f50e6cf775ee334b03c81cacd171d3e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AWP | 冥界之河",
                "min": 0,
                "max": 0.83,
                "target_box": "set_nuke_2",
                "itemid": 226,
                "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
                "len": 3,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Acheron"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/eea3ffb8aee3db6a5e52af7fef50a139c7eba51de1eb094321adf624de9a7152.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 交换机",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 227,
                "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
                "len": 3,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Exchanger"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b699bce38764b741f91d3aae8670ea2e1974cd1d61af545c11892feb3ad6ee0d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 协处理器",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 228,
                "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
                "len": 3,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Co-Processor"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bc1bd0a1585871b91f5aa7e343e21604347ab8c50dd709fc3383a161697f5352.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 设施系列·底片图",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 229,
                "list": "\n格洛克 18 型 | 核子花园\nMAG-7 | 核芯破裂\nAUG | 随机存取",
                "len": 3,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Facility Negative"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c8c685b19fda025447d49b83b4ae05bf7e9072c8ec49d0514fec1fcfe5357aae.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "加利尔 AR | 冰核聚变",
                "min": 0,
                "max": 0.83,
                "target_box": "set_nuke_2",
                "itemid": 230,
                "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Cold Fusion"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/3621652b5fa24f3392f33353d1336c0911457ff4b338fc7ec999e1511ccbd6f0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "内格夫 | 舱壁",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 231,
                "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Bulkhead"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/cb6dbea961d9b66f9874a18c1604450c269b0880ba2124a8c1dd9de6c02a73ff.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP7 | 主板",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 232,
                "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Motherboard"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/579fa6357cf88c495ed1c74e858f491fbd3eb692a535dab48a56485e023266fd.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 主机",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 233,
                "list": "\nAWP | 冥界之河\nP250 | 交换机\nMP5-SD | 协处理器\nP90 | 设施系列·底片图",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Mainframe"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/32df78668e72e7c06ca1aefe590a93db1b86923dd883d00e3b7125ec222769c5.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 芯轴",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 234,
                "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Mandrel"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3779b6e2fead191c1a0df55f7358b5ba3cb73ba90df33d4f0c032d4319fbdc6f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "FN57 | 冷却剂",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 235,
                "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Coolant"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/21cd723acbbd05e9a91fb7269eb696c144a9ae12a195208124a2dcbedbe9a1b0.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 设施系列·深色图",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 236,
                "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Facility Dark"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/97f114ac303cf6a323b0184960092818022dd6dbf702ee32aa81df26d781206f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 设施系列·草图",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 237,
                "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Facility Draft"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/7031d6dc4a771df8568e6a1bf20981f057cf8b6fd259e463da48ce65f300539f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 设施系列·速写图",
                "min": 0,
                "max": 0.5,
                "target_box": "set_nuke_2",
                "itemid": 238,
                "list": "\n加利尔 AR | 冰核聚变\n内格夫 | 舱壁\nMP7 | 主板\nM4A4 | 主机",
                "len": 4,
                "box_value": "set_nuke_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Facility Sketch"
            }
        ]
    },
    {
        "name": "殒命大厦收藏品",
        "en_name": "The Vertigo Collection",
        "cover": "https://static.mobi2077.com/market-csgo/d232ea803fc440e7f4992979ddbc2a8647545536ce89efab824144bbf1fa0bd2.png",
        "type": 1,
        "box_value": "set_vertigo",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/80d9dc963e94239510956b5de27363810bf834b2fee99f54c66f4b634c7afbb0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 爆破能手",
                "min": 0.26,
                "max": 0.6,
                "target_box": "set_vertigo",
                "itemid": 239,
                "list": "",
                "len": 0,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Demolition"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e4bf9f0ad82b1f80ba5f955b5149f525ce1171fa7cdf9825bd193a77adb9b33a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AK-47 | 黑色层压板",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_vertigo",
                "itemid": 240,
                "list": "\n双持贝瑞塔 | 爆破能手",
                "len": 1,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Black Laminate"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/18544f7e6ae9775eef7d9a51597514e9ba1a6fe7e28f9d7e2a4b70ea6ab10c2a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 冰川网格",
                "min": 0,
                "max": 1,
                "target_box": "set_vertigo",
                "itemid": 241,
                "list": "\n双持贝瑞塔 | 爆破能手",
                "len": 1,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Glacier Mesh"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/895732d2db8da276497dee87aa788777cb8ec7d42bfee62058dfd494fbb32dfe.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "PP-野牛 | 碳素纤维",
                "min": 0,
                "max": 0.12,
                "target_box": "set_vertigo",
                "itemid": 242,
                "list": "\nAK-47 | 黑色层压板\nP90 | 冰川网格",
                "len": 2,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Carbon Fiber"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/83bfdce2ff055c81cc6468b6ce0d22d7b6fa3a7e9bd4560c526d001928ce32a8.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 都市 DDPAT",
                "target_box": "set_vertigo",
                "itemid": 243,
                "list": "\nPP-野牛 | 碳素纤维",
                "len": 1,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "min": 0,
                "max": 1,
                "hash_name": "MAC-10 | Urban DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3289d4cea4e1e6b9c5c0ccd6060bbbcce4558df4cc14b9ebdbab1b76f8b88696.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 都市穿孔",
                "min": 0,
                "max": 1,
                "target_box": "set_vertigo",
                "itemid": 244,
                "list": "\nPP-野牛 | 碳素纤维",
                "len": 1,
                "box_value": "set_vertigo",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Urban Perforated"
            }
        ]
    },
    {
        "name": "列车停放站收藏品",
        "en_name": "The Train Collection",
        "cover": "https://static.mobi2077.com/market-csgo/1cf3ee66623d63a55792ba7552a76da128896fd4e7fad2bd8e730bfced01ba3d.png",
        "type": 1,
        "box_value": "set_train",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/65e1e6a47ca903b04362a82f1ce1ae5e44983dc28c1c429f4abfae626545fb24.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 晶红石英",
                "min": 0,
                "max": 0.4,
                "target_box": "set_train",
                "itemid": 245,
                "list": "",
                "len": 0,
                "box_value": "set_train",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Red Quartz"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d074cc227d5b016d7cb88c545a1c1501c44fb0ec9555eb49d1da212991e5e732.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 都市瓦砾",
                "min": 0,
                "max": 0.5,
                "target_box": "set_train",
                "itemid": 246,
                "list": "\nTec-9 | 晶红石英",
                "len": 1,
                "box_value": "set_train",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Urban Rubble"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4bd3cf0defd221b8f67ea2f0e6770a91224f1ee5997536fecf973c4c0117c644.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 渐变琥珀",
                "min": 0,
                "max": 0.4,
                "target_box": "set_train",
                "itemid": 247,
                "list": "\nTec-9 | 晶红石英",
                "len": 1,
                "box_value": "set_train",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Amber Fade"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/496c3fd8e4cfa044d3a18cd7ca148174bf10c27aea9f80a66e30787c9c53cd28.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 金属 DDPAT",
                "target_box": "set_train",
                "itemid": 248,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "min": 0,
                "max": 0.08,
                "hash_name": "P250 | Metallic DDPAT"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d30c94d6af923d25d9a2380dc2baaa49a54281d5476d9ee773bcadb6fb043d55.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAG-7 | 金属 DDPAT",
                "target_box": "set_train",
                "itemid": 249,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "min": 0,
                "max": 0.08,
                "hash_name": "MAG-7 | Metallic DDPAT"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/f79a2b240290a88062f64f522aa489463409c0646530f18165f30c693c8d3c24.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SCAR-20 | 碳素纤维",
                "min": 0,
                "max": 0.12,
                "target_box": "set_train",
                "itemid": 250,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Carbon Fiber"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/e72b1bd1be23e3e1fda71c9cc60926f6c1155ceb3b769113dc89769cd5bdd4df.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P90 | 白蜡木",
                "min": 0,
                "max": 0.45,
                "target_box": "set_train",
                "itemid": 251,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Ash Wood"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/5e3024e7100c3edf2b7ab7137bd123bdb0b48e42c2d779d79259452cb93dfd5b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAC-10 | 红苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_train",
                "itemid": 252,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Candy Apple"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/84b810b58d69b0219ea677d77e80ab1d682bed426c2f777f018b4ed61ac6581d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 都市 DDPAT",
                "target_box": "set_train",
                "itemid": 253,
                "list": "\n沙漠之鹰 | 都市瓦砾\n截短霰弹枪 | 渐变琥珀",
                "len": 2,
                "box_value": "set_train",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "min": 0,
                "max": 1,
                "hash_name": "M4A4 | Urban DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/bbe5597eebb221f672f58050589c716b03a632ddd99a9829502454843e5a7e49.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 都市 DDPAT",
                "target_box": "set_train",
                "itemid": 254,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "min": 0,
                "max": 1,
                "hash_name": "UMP-45 | Urban DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/7319ead144341716894a589f8b42208c57b8b46506c528d1f3784de90f846772.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 殖民侵略者",
                "min": 0,
                "max": 1,
                "target_box": "set_train",
                "itemid": 255,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Colony"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/61e0495b589c88179e285f7dcdc9029b6e752aa6ab66091d171f9ffe362e1808.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 极地迷彩",
                "min": 0,
                "max": 1,
                "target_box": "set_train",
                "itemid": 256,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Polar Camo"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e00f1f5390d378cb515106833eedac542d522eada7c0f9a4d209b01a0037054a.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "FN57 | 暮色森林",
                "min": 0,
                "max": 1,
                "target_box": "set_train",
                "itemid": 257,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Forest Night"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/7b7b9e3a63d1aab21e128bb5dc254c9b3f1b8e62543c208e4cddf1a1e3edb125.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 极地网格",
                "min": 0,
                "max": 1,
                "target_box": "set_train",
                "itemid": 258,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Polar Mesh"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/2aca37f13a4df0daf24a66fa8d48ec9a9edcac34026e77862c1390c85edd1ecf.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 都市虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_train",
                "itemid": 259,
                "list": "\nP250 | 金属 DDPAT\nSCAR-20 | 碳素纤维\nP90 | 白蜡木\nMAC-10 | 红苹果\nM4A4 | 都市 DDPAT",
                "len": 5,
                "box_value": "set_train",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Urban Dashed"
            }
        ]
    },
    {
        "name": "安全处所收藏品",
        "en_name": "The Safehouse Collection",
        "cover": "https://static.mobi2077.com/market-csgo/9413b0b8fc28dddce3ceffbbee11af4a8de42f44263b18294dbd418e4194cc7f.png",
        "type": 1,
        "box_value": "set_safehouse",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f52011b247f5997b81f50254f3194a60574b7ee9e573d5cc14e504936697138f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 氮化处理",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_safehouse",
                "itemid": 260,
                "list": "",
                "len": 0,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Nitro"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1c0c1c2999315327c63c03ecd514d3e84f70408df864e63cbccaabebf1cfed37.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 渐变强酸",
                "min": 0,
                "max": 0.03,
                "target_box": "set_safehouse",
                "itemid": 261,
                "list": "\nM4A1 消音型 | 氮化处理",
                "len": 1,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Acid Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/eaf2a707aae16b6b26072aace7b9e2608219f9d7850e35a15b1f594fd82b8aeb.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 银白石英",
                "min": 0,
                "max": 0.4,
                "target_box": "set_safehouse",
                "itemid": 262,
                "list": "\nM4A1 消音型 | 氮化处理",
                "len": 1,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Silver Quartz"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ee1d2e4eeec15080d64c4e8dc70e5f6464c1d4d974228287c8458eaf18bf98c2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 摧枯拉朽",
                "min": 0,
                "max": 0.6,
                "target_box": "set_safehouse",
                "itemid": 263,
                "list": "\nM4A1 消音型 | 氮化处理",
                "len": 1,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Teardown"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/f8f20ef096702ea84009bf51da4e01bbe8aae493c8c848cee56e86b544047cfc.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "G3SG1 | 多变迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_safehouse",
                "itemid": 264,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | VariCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/0d06fb4448a766e995ea68f08aef15d0c43f6a60703edb96ca6962aaf30be464.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M249 | 鳄鱼网格",
                "min": 0,
                "max": 0.6,
                "target_box": "set_safehouse",
                "itemid": 265,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Gator Mesh"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/0b54c484e3a36abf5e9f0d311a06f41f357d935a0dc50f64f62ece8fded70e38.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "加利尔 AR | 多变迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_safehouse",
                "itemid": 266,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | VariCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/1ed7a1d195a5285fa6783d34080003f467e993967b339ac191812bc0475fd05d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "USP 消音版 | 森林之叶",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 267,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Forest Leaves"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/36b2ee59c1c7e80b340b399cf64e73ba6876cb9fd17617383e72d20c2c9799c4.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AUG | 众枪之的",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 268,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Condemned"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/259cd28670b2617f599ed03ca81c3d59d3f5e11cf358169b2520bbc42cc13670.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP9 | 橘皮涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 269,
                "list": "\nSSG 08 | 渐变强酸\nFN57 | 银白石英\n法玛斯 | 摧枯拉朽",
                "len": 3,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Orange Peel"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d897140d1befb9604113c3e36495f01297fa7ee79d93f312aab6f62753db043e.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 雇佣兵",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 270,
                "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
                "len": 5,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Contractor"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/313446ba89e757ef91f17c79f912db644196cb30eba5df6db0802c243afa0a3f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SCAR-20 | 雇佣兵",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 271,
                "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
                "len": 5,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Contractor"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/58720bb1e7d30bd794e156e16e3f9690fcfb86d7fb14ec48fbd765b03d0b4efb.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 蓝色云杉",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 272,
                "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
                "len": 5,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Blue Spruce"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/08d62df7d279c7048940c5b7a2ba95da0aba8ded303e5ef480515a305355c1db.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 陆军网格",
                "min": 0,
                "max": 0.6,
                "target_box": "set_safehouse",
                "itemid": 273,
                "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
                "len": 5,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Army Mesh"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d7c14d1e1db6548a5dcce064dace83c64715d5faaf43725c035ab2527f4aed71.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 陆军斥候",
                "min": 0,
                "max": 1,
                "target_box": "set_safehouse",
                "itemid": 274,
                "list": "\nG3SG1 | 多变迷彩\nM249 | 鳄鱼网格\nUSP 消音版 | 森林之叶\nAUG | 众枪之的\nMP9 | 橘皮涂装",
                "len": 5,
                "box_value": "set_safehouse",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Army Recon"
            }
        ]
    },
    {
        "name": "旭日收藏品",
        "en_name": "The Rising Sun Collection",
        "cover": "https://static.mobi2077.com/market-csgo/53ecf8f81a67871453bf8b3e7d9f69a0712226c78c8d7bc04794336c690c1222.png",
        "type": 1,
        "box_value": "set_kimono",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/38bdbf02e0b71cff6cafdeee8fb07b5edf16010b587e48397b8c62f0f8ea541f.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AUG | 秋叶原之选",
                "min": 0,
                "max": 1,
                "target_box": "set_kimono",
                "itemid": 275,
                "list": "",
                "len": 0,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Akihabara Accept"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ee1f079ec4a0922e085ddced768497b197fdb6fb4e1ed4acf5117e442652b985.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 水栽竹",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 276,
                "list": "\nAUG | 秋叶原之选",
                "len": 1,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Hydroponic"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/00b6ff99710a0186c6030c0fc245cd98cbc08f85d1d5e4a7ae632938cdda87dc.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 日式荧光涂装",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 277,
                "list": "\nAK-47 | 水栽竹",
                "len": 1,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Neon Kimono"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4747999ff9c314025294d50d482927ac1054324f6e4e2dfc0f2ac760db540df7.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 日落风暴 壱",
                "target_box": "set_kimono",
                "itemid": 278,
                "list": "\nAK-47 | 水栽竹",
                "len": 1,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "min": 0,
                "max": 0.75,
                "hash_name": "Desert Eagle | Sunset Storm 壱"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0e925dc0a1b04046f65d608d4069fb0b362808b5492967a8eeba796ddf32a9a6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 日落风暴 弐",
                "target_box": "set_kimono",
                "itemid": 279,
                "list": "\nAK-47 | 水栽竹",
                "len": 1,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "min": 0,
                "max": 0.75,
                "hash_name": "Desert Eagle | Sunset Storm 弐"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d0bc2faf2d492973d5ebd3d8917f15944a79476bb4acddad2cd4d1afac47927a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 破晓",
                "min": 0,
                "max": 0.6,
                "target_box": "set_kimono",
                "itemid": 280,
                "list": "\nAK-47 | 水栽竹",
                "len": 1,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Daybreak"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4c46338f743e36af7812aecaad8c1ebaaa8c71020beb8dc6b40537f4eba69c57.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 水蓝阶地",
                "min": 0,
                "max": 0.7,
                "target_box": "set_kimono",
                "itemid": 281,
                "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
                "len": 3,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Aqua Terrace"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3bfa3901f38b6b9cf18e2a21032421314b505df7e5514ddc9a6c68e6540a156c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 反梗精英",
                "min": 0,
                "max": 0.65,
                "target_box": "set_kimono",
                "itemid": 282,
                "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
                "len": 3,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Counter Terrace"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/da906fdf3b6e1f889ae05edef8471bb0881d93f6262a11aadf2b15e79a762721.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 梗怖分子",
                "min": 0,
                "max": 1,
                "target_box": "set_kimono",
                "itemid": 283,
                "list": "\nFN57 | 日式荧光涂装\n沙漠之鹰 | 日落风暴 壱\nM4A4 | 破晓",
                "len": 3,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Terrace"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/2e8687278cc89507b1a7d964cf8e0b932889931344b7e171e6cdfe70e262221b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 日式深红涂装",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 284,
                "list": "\n加利尔 AR | 水蓝阶地\nMAG-7 | 反梗精英\nTec-9 | 梗怖分子",
                "len": 3,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Crimson Kimono"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/21a1c04bf433fb0f8e582f3be5ff7023a1cbcdb72584fd3aaed5ece97bd7d8bd.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "沙漠之鹰 | 午夜风暴",
                "min": 0,
                "max": 0.75,
                "target_box": "set_kimono",
                "itemid": 285,
                "list": "\n加利尔 AR | 水蓝阶地\nMAG-7 | 反梗精英\nTec-9 | 梗怖分子",
                "len": 3,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Midnight Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/b5537ee98a72c3c6dd1465cd7b909acf20e64dd1e980dad553ad33af6f59cf0b.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 墨竹",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 286,
                "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
                "len": 2,
                "box_value": "set_kimono",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Bamboo Print"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/bb101135fe9298a59ebcb58355c1a18811f66b30770c88921f8ba80f028b040f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 竹影",
                "min": 0,
                "max": 0.6,
                "target_box": "set_kimono",
                "itemid": 287,
                "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
                "len": 2,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Bamboo Shadow"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/79f062e08a6d7c994a82f0a1ddd285e74a1ec56eb4ae5c11f06a72e3bff925e5.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 竹林",
                "min": 0,
                "max": 0.6,
                "target_box": "set_kimono",
                "itemid": 288,
                "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
                "len": 2,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Bamboo Forest"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6abcbd9ae5edcb33f09c650cda965b43dbadffd09d959197721c6009f263c6b6.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 日式橙色涂装",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 289,
                "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
                "len": 2,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Orange Kimono"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/80b692074f7a3bc5cb2fd515c504d21e11ca57c56df4853087a7bff9f465a8d8.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 日式薄荷涂装",
                "min": 0,
                "max": 0.8,
                "target_box": "set_kimono",
                "itemid": 290,
                "list": "\nP250 | 日式深红涂装\n沙漠之鹰 | 午夜风暴",
                "len": 2,
                "box_value": "set_kimono",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Mint Kimono"
            }
        ]
    },
    {
        "name": "死亡游乐园收藏品",
        "en_name": "The Overpass Collection",
        "cover": "https://static.mobi2077.com/market-csgo/fd8531fbf5c13d3f6996f7f2e7d3b7f681d5600dcd6a7df1f8bbe7608cf24088.png",
        "type": 1,
        "box_value": "set_overpass",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/6b807f342b4f938b25ffb962ed1b191313feb7f9621a79062da8d9c2864f8301.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 神来之作",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 291,
                "list": "",
                "len": 0,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Master Piece"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/750b20601690da9c2cff1ec1609432000675c20d3d1440a6f29f4491805a845f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 粉红 DDPAT",
                "target_box": "set_overpass",
                "itemid": 292,
                "list": "\nM4A1 消音型 | 神来之作",
                "len": 1,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "min": 0,
                "max": 1,
                "hash_name": "AWP | Pink DDPAT"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/bdd5881df6fd0f00e2c76fd6cb7b18879425456df80b0e2577b69817817ea4e0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 公路杀手",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 293,
                "list": "\nM4A1 消音型 | 神来之作",
                "len": 1,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Road Rash"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/196d364aaa9678f3582e2d56aa80b4d0c3edf480852431ba1b67c6a8a3f92f08.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 氮化处理",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_overpass",
                "itemid": 294,
                "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
                "len": 2,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Nitro"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4e1edfb6b56249ed13c2546f40675b56efb0757d24a6880be3ea8b7f1bbd5c61.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 蔚蓝多变迷彩",
                "min": 0,
                "max": 0.5,
                "target_box": "set_overpass",
                "itemid": 295,
                "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
                "len": 2,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | VariCamo Blue"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cd03a3d1a14a7aa15817ff47fd653a8f13c7ff3e5e995c6b34bb0ac5df5cb6da.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 迂回路线",
                "min": 0,
                "max": 0.43,
                "target_box": "set_overpass",
                "itemid": 296,
                "list": "\nAWP | 粉红 DDPAT\nUSP 消音版 | 公路杀手",
                "len": 2,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Detour"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/07f017dbee16a49814ab524b8338920626e98d25ecc7640b1588b9318a79cfb7.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "沙漠之鹰 | 都市 DDPAT",
                "target_box": "set_overpass",
                "itemid": 297,
                "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
                "len": 3,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "min": 0,
                "max": 1,
                "hash_name": "Desert Eagle | Urban DDPAT"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/a7c460111ef267bb712df3ce93b1d07ac41d5c53bd11a2032efa3d513bf3fad1.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP7 | 硝烟",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 298,
                "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
                "len": 3,
                "box_value": "set_overpass",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Gunsmoke"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/87f5b191126a37f7fcc6658294fe76ab6d9500a4ff1e8f015f75b9b41469591f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 噩梦之夜",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 299,
                "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
                "len": 3,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Night"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/fd7b435bc14fda453d5374bb8749faced37c5fb77e4307d028dcea292da76542.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 工业牧草",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 300,
                "list": "\nCZ75 | 氮化处理\nXM1014 | 蔚蓝多变迷彩\nSSG 08 | 迂回路线",
                "len": 3,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Grassland"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cb19e4de792ccad083c06c3483ee662de2f9df8861f741c923b2f7005fe4c10b.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 贤者涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 301,
                "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
                "len": 4,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Sage Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/09041355765803f42b26bacaa9f55a0afdb0bc02f619794630543a88a16c6d26.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 枯焦之色",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 302,
                "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
                "len": 4,
                "box_value": "set_overpass",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Scorched"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/eeeaaa9d27f8bff3ed5ed02761670dc038a41be73a61d8c9b5ec75212fce1471.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "M249 | 对比涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 303,
                "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
                "len": 4,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Contrast Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/253cdc41eedb5026c383ddc7175e0f894418006b99888a62c837db2850f742f3.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 暴风呼啸",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 304,
                "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
                "len": 4,
                "box_value": "set_overpass",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/e7659da15a9402404d08c7e4eaf15c006f92b99936be61a6308267861904d1c1.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 暴风呼啸",
                "min": 0,
                "max": 1,
                "target_box": "set_overpass",
                "itemid": 305,
                "list": "\n沙漠之鹰 | 都市 DDPAT\nMP7 | 硝烟\n格洛克 18 型 | 噩梦之夜\nP2000 | 工业牧草",
                "len": 4,
                "box_value": "set_overpass",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Storm"
            }
        ]
    },
    {
        "name": "办公室收藏品",
        "en_name": "The Office Collection",
        "cover": "https://static.mobi2077.com/market-csgo/10846f6761b4f74cc619f5478812f74acd07c75d2142efefec3c9a73511dd675.png",
        "type": 1,
        "box_value": "set_office",
        "children": [
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f4bf72a17de19a55942cc5a6ad70b77a78aa36e2a53176aba7118fdd28bdf057.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 银装素裹",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_office",
                "itemid": 306,
                "list": "",
                "len": 0,
                "box_value": "set_office",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Whiteout"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6dda53583d7b81684900d507667e2f7f904eed22002bacdc11d733a432cc1004.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 银质",
                "min": 0,
                "max": 0.08,
                "target_box": "set_office",
                "itemid": 307,
                "list": "",
                "len": 0,
                "box_value": "set_office",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Silver"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/095d01464a7610610336d055cd9ead07c5535d193204118b35d8568912bf0261.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M249 | 暴雪大理石纹",
                "min": 0,
                "max": 1,
                "target_box": "set_office",
                "itemid": 308,
                "list": "\nMP7 | 银装素裹\nP2000 | 银质",
                "len": 2,
                "box_value": "set_office",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Blizzard Marbleized"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/acdceaec460e1205fa066277946de3bcccd302c4a208073f812a8a91173606cf.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "G3SG1 | 极寒迷彩",
                "min": 0,
                "max": 1,
                "target_box": "set_office",
                "itemid": 309,
                "list": "\nMP7 | 银装素裹\nP2000 | 银质",
                "len": 2,
                "box_value": "set_office",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Arctic Camo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/1c9fa47cc9aae7bd0efe11102e308519d542d8bab462b45968e2f983f5e27a8b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "加利尔 AR | 冬之森林",
                "min": 0,
                "max": 1,
                "target_box": "set_office",
                "itemid": 310,
                "list": "\nMP7 | 银装素裹\nP2000 | 银质",
                "len": 2,
                "box_value": "set_office",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Winter Forest"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/b024728b63071daba909f702579a9c919108b36e88d2e0d00f53703e3c850194.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "法玛斯 | 对比涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_office",
                "itemid": 311,
                "list": "\nM249 | 暴雪大理石纹\nG3SG1 | 极寒迷彩\n加利尔 AR | 冬之森林",
                "len": 3,
                "box_value": "set_office",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Contrast Spray"
            }
        ]
    },
    {
        "name": "核子危机收藏品",
        "en_name": "The Nuke Collection",
        "cover": "https://static.mobi2077.com/market-csgo/43ddfd9f1572636cb4c53f064aba644871cafc62a33db25848c8951f332d2ac9.png",
        "type": 1,
        "box_value": "set_nuke",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/645a6f5143ccb96d6d0675e6310b2b46f69620c3099c763d0f5bd0d583cdd80f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 核子威慑",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_nuke",
                "itemid": 312,
                "list": "",
                "len": 0,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Nuclear Threat"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3662c49ad78679145ff7725646954d9b601663c049010a79b3ce37218ef11de0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 核子威慑",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_nuke",
                "itemid": 313,
                "list": "",
                "len": 0,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Nuclear Threat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6b5ac4f4b56920ee2253f47fb88469105e0aadf2b2b798bec58d3c1464ccd858.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 辐射危机",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 314,
                "list": "\nTec-9 | 核子威慑",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Radiation Hazard"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/85f82bae0ec36807a89c7276430b5f4d4dcf014a35fab6fcde58a1f51b845633.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "XM1014 | 辐射警告",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 315,
                "list": "\nM4A4 | 辐射危机",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Fallout Warning"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/3d40be9f531c0329baf206247845332fa6f30a051fc2fdc6f3876f1e2fc3e6c7.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "UMP-45 | 辐射警告",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 316,
                "list": "\nM4A4 | 辐射危机",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Fallout Warning"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/5771c52915ff2273311f037048565cb7535ce755cb9038bdcfcdf83087487748.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P90 | 辐射警告",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 317,
                "list": "\nM4A4 | 辐射危机",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Fallout Warning"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8e0f37582cdee5089167a5b716068ab2890bc0ee88acb1b2dfadd43fce8af958.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 辐照警报",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 318,
                "list": "\nXM1014 | 辐射警告",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Irradiated Alert"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8c030a4109d96b32b5d79737505a78b0b0ba9cc70b7dbcd47f87481a9e7a1ebc.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 辐照警报",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 319,
                "list": "\nXM1014 | 辐射警告",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Irradiated Alert"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/48f92686879d817568261a54fd9cef376bb255b4f322bf5f7afa69b9d1b5f248.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 辐照警报",
                "min": 0,
                "max": 1,
                "target_box": "set_nuke",
                "itemid": 320,
                "list": "\nXM1014 | 辐射警告",
                "len": 1,
                "box_value": "set_nuke",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Irradiated Alert"
            }
        ]
    },
    {
        "name": "荒漠迷城收藏品",
        "en_name": "The Mirage Collection",
        "cover": "https://static.mobi2077.com/market-csgo/cf7a8ddbab8406096ddfabf9488305cb944fcf1384164b871c2d802b3debc7c3.png",
        "type": 1,
        "box_value": "set_mirage",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3564b4269c4474744b3888ec00d6f11704529f7480306d7048db3f25c59f9d3d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 威吓者",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_mirage",
                "itemid": 321,
                "list": "",
                "len": 0,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Bulldozer"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5adb8945a1a5649d09b8375d573ea27081fa4d4761957aad9a05841fb379d5ce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 渐变琥珀",
                "min": 0,
                "max": 0.4,
                "target_box": "set_mirage",
                "itemid": 322,
                "list": "\nMAG-7 | 威吓者",
                "len": 1,
                "box_value": "set_mirage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Amber Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f9738a23d33554c5ffd1dc3e0bda7135e2fea40bfefa92e47714a72dc56dfe7a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 炽烈之炎",
                "min": 0,
                "max": 0.08,
                "target_box": "set_mirage",
                "itemid": 323,
                "list": "\nMAG-7 | 威吓者",
                "len": 1,
                "box_value": "set_mirage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bd38da3106dac0c133e0189a553fa3f11f317b92204175a54f02863fb57dfd7b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 赤红新星",
                "min": 0,
                "max": 0.08,
                "target_box": "set_mirage",
                "itemid": 324,
                "list": "\nMAG-7 | 威吓者",
                "len": 1,
                "box_value": "set_mirage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Hot Rod"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8ab1bb28ae39b9cdd22e8b95629a4a33e58f4152df99126de804d7b7b5ea8908.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "内格夫 | 加州迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_mirage",
                "itemid": 325,
                "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
                "len": 3,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | CaliCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/1bf41194d14be879b0624f9f6182e675b51d82f99492395ed8a9e1c9cc39c8e9.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SSG 08 | 热带风暴",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 326,
                "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
                "len": 3,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Tropical Storm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/f87128467fe634044a86707d9ccd8c5032bf95367fa31196d6efa901e005fe9f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SG 553 | 鳄鱼网格",
                "min": 0,
                "max": 0.6,
                "target_box": "set_mirage",
                "itemid": 327,
                "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
                "len": 3,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Gator Mesh"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/43661d6c024c6dfdf85be24528fd486b1b333b88dc19743bd7ea3b74b9941566.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 地下水",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 328,
                "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
                "len": 3,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Groundwater"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/bcb061355a1073ebd79c90c1eb3405d35b9ad087a2116e07b1bc7db3508b736a.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MP7 | 橘皮涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 329,
                "list": "\nMAC-10 | 渐变琥珀\nUMP-45 | 炽烈之炎\nMP9 | 赤红新星",
                "len": 3,
                "box_value": "set_mirage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Orange Peel"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/9a138b1ec336d7541e80dd26802299495857ca75781bd20bc32a61127db9edee.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 骸骨外罩",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 330,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Bone Mask"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/fec79b8d6138af12171ddd9d1d326acb29b5ee168ac302b2cefd4ca29e5736d9.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "FN57 | 雇佣兵",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 331,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Contractor"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/f2f61a85f9fe9c9e0c5898d38e2f75cff5152b266e60cb22fc692ae06981acae.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 殖民侵略者",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 332,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Colony"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/2177ed7b9e55a90f093a936e54ee0b77ebfb56b4dfc0a747e5561874ce361da4.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 狩猎网格",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 333,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Safari Mesh"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6f318a4e255acb4b2b9d99ec143474b6a476916e4debe4a99b38afaf95082dfa.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P90 | 枯焦之色",
                "min": 0,
                "max": 1,
                "target_box": "set_mirage",
                "itemid": 334,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Scorched"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/ecb32af994571bb302c41e2ea652cc7b2cfdce4317a1a5dac72255cb10309952.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "加利尔 AR | 隐蔽猎手",
                "min": 0,
                "max": 0.6,
                "target_box": "set_mirage",
                "itemid": 335,
                "list": "\n内格夫 | 加州迷彩\nSSG 08 | 热带风暴\nSG 553 | 鳄鱼网格\n格洛克 18 型 | 地下水\nMP7 | 橘皮涂装",
                "len": 5,
                "box_value": "set_mirage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Hunting Blind"
            }
        ]
    },
    {
        "name": "佣兵训练营收藏品",
        "en_name": "The Militia Collection",
        "cover": "https://static.mobi2077.com/market-csgo/10563c87bc4f6b85a744dbd753fc9f4414828903fc0cae796fb4b9448cf0f3de.png",
        "type": 1,
        "box_value": "set_militia",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/e5a39c7a5938e4664053f559c224b93819eff2f72e229c723e537f361b89bab8.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SCAR-20 | 溅射果酱",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_militia",
                "itemid": 336,
                "list": "",
                "len": 0,
                "box_value": "set_militia",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Splash Jam"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/27776682bc9b60376abb9eb453b112dc536b5f3fd4659a1981de0d032eebf42e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 现代猎手",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 337,
                "list": "\nSCAR-20 | 溅射果酱",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Modern Hunter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/29ac74264c111c372a8ad108b7057c87d0feb96f41190807493b98390b6f87b9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 现代猎手",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 338,
                "list": "\nM4A4 | 现代猎手",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Modern Hunter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/974c800975544a5c921dd0f6f270707379db99ccc2b03d4be572a67c5d3b8d6b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 现代猎手",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 339,
                "list": "\nM4A4 | 现代猎手",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Modern Hunter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/881d3aa1f6e9af507d2046a97c60f58f076481b17671acabec8578dd17f569c0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 火焰橙",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 340,
                "list": "\nM4A4 | 现代猎手",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Blaze Orange"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1f657f958b8d55c580ae41b02fce7130665e6a5e778eaaac0e48b79959b3c96f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 现代猎手",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 341,
                "list": "\nM4A4 | 现代猎手",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Modern Hunter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fd3f749026a0f6efcc0b90030cf6512403f4da841edf0cc60680d394692e7847.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 火焰橙",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 342,
                "list": "\nM4A4 | 现代猎手",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Blaze Orange"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/50f8aba2859d024b08edfe99ad511115f3add63feb2121de919cfbd0321f34e0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 草原落叶",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 343,
                "list": "\n新星 | 现代猎手\nXM1014 | 火焰橙",
                "len": 2,
                "box_value": "set_militia",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Grassland Leaves"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/6cebcce2a9c25a244edc696d494249f774a424306887d8da4a338f7d2008f37d.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 森林之叶",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 344,
                "list": "\nP2000 | 草原落叶",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Forest Leaves"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/56526e96e182849baf44ed37280ed99557e5c782fc5fa97086d02e2a0bd45389.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 狂哮飓风",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 345,
                "list": "\nP2000 | 草原落叶",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Tornado"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/1fe762559fe79e661869d67f5a2e758bd2d1490d474a88c090d609b57cc2d286.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 工业牧草",
                "min": 0,
                "max": 1,
                "target_box": "set_militia",
                "itemid": 346,
                "list": "\nP2000 | 草原落叶",
                "len": 1,
                "box_value": "set_militia",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Grassland"
            }
        ]
    },
    {
        "name": "湖畔激战收藏品",
        "en_name": "The Lake Collection",
        "cover": "https://static.mobi2077.com/market-csgo/d10358a9bd2561871f139c4f40d8f96d20d603ff92a60590f1d9a5394f69a814.png",
        "type": 1,
        "box_value": "set_lake",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a2ff0c5de885a2eba49055c59e1352f3712bfd36e268445e9a92962282516488.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 钴蓝石英",
                "min": 0,
                "max": 0.4,
                "target_box": "set_lake",
                "itemid": 347,
                "list": "",
                "len": 0,
                "box_value": "set_lake",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Cobalt Quartz"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7bf17bb42af78077b0bc27df1a4f4224b7005415443a7990673498168ea0225c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 午夜行动",
                "min": 0,
                "max": 0.6,
                "target_box": "set_lake",
                "itemid": 348,
                "list": "\n双持贝瑞塔 | 钴蓝石英",
                "len": 1,
                "box_value": "set_lake",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Night Ops"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d9c9ecb2713384d42f0c72def17bc32b538e1ce25ae8697365e52efa147cc94a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 摧枯拉朽",
                "min": 0,
                "max": 0.6,
                "target_box": "set_lake",
                "itemid": 349,
                "list": "\n双持贝瑞塔 | 钴蓝石英",
                "len": 1,
                "box_value": "set_lake",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Teardown"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a2a34849d0874384bc7f225d2e79733498854a9315f97319760ae2e355128b76.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 深蓝电镀处理",
                "min": 0,
                "max": 0.08,
                "target_box": "set_lake",
                "itemid": 350,
                "list": "\n双持贝瑞塔 | 钴蓝石英",
                "len": 1,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Anodized Navy"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ddc5f615dad8d20386a40f5adbd655654784c17ccea6d87100791c4eed11dea9.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "沙漠之鹰 | 泥地杀手",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 351,
                "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_lake",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Mudder"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/252c9e209de1708d6505d4d8e0457dd232e268a9be85aad84a649c0442f8a08b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AWP | 狩猎网格",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 352,
                "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Safari Mesh"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d47b2ad48721e0a5711f5a51075b03bec3f33759103016f9d17d64ab7a49c357.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "PP-野牛 | 午夜行动",
                "min": 0,
                "max": 0.6,
                "target_box": "set_lake",
                "itemid": 353,
                "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_lake",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Night Ops"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/a373321fdf8e1cc13433e35e7627796881ce99e52f605f9ed0e8489187f4f0b3.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "法玛斯 | 湖蓝涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 354,
                "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Cyanospatter"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/2d0f113309df362b6e85650fddc02bb460e600c10bd6109ce031a5331536676a.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "XM1014 | 蓝钢",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 355,
                "list": "\nUSP 消音版 | 午夜行动\nP90 | 摧枯拉朽\nSG 553 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_lake",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Blue Steel"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3f3a49f8b5ab285f63512b43d5ea27abc0c7222af5daa93b119a6b8d10e15da2.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 北方森林",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 356,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Boreal Forest"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/7da643084ee19c2d7123b64ab7875f7431aab7ae19829649f339efae2a7df688.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 蓝色云杉",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 357,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Blue Spruce"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/4362f754f7a64cc007a9e9e94740e91e79fb0dbe454cce393d5a381a4efa36ea.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 暴风呼啸",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 358,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/221dcc0128825cdeff070ad772868239f0b57adc7c72dfabea025a00dd72c135.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "加利尔 AR | 贤者涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 359,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Sage Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0eebdafb550cc81a693664e72062c12c0c4b627f2ee5416f149bb0966d79c34f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 浪花穿孔",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 360,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Waves Perforated"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/a544731821904fa889020e42172f5e4b883ea839e4df9193d7c6088f98c19f1c.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 丛林虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_lake",
                "itemid": 361,
                "list": "\n沙漠之鹰 | 泥地杀手\nAWP | 狩猎网格\nPP-野牛 | 午夜行动\n法玛斯 | 湖蓝涂装\nXM1014 | 蓝钢",
                "len": 5,
                "box_value": "set_lake",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Jungle Dashed"
            }
        ]
    },
    {
        "name": "意大利小镇收藏品",
        "en_name": "The Italy Collection",
        "cover": "https://static.mobi2077.com/market-csgo/9f387ac5f9f1fb73af423eab46fba2daad6c4bc1738e3cd8a7c26993cdf812e3.png",
        "type": 1,
        "box_value": "set_italy",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3749432a227bb3e56ec77a111d5b3edea289e00ddfd180114433fd8db9362e59.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 响尾蛇",
                "min": 0.08,
                "max": 0.5,
                "target_box": "set_italy",
                "itemid": 362,
                "list": "",
                "len": 0,
                "box_value": "set_italy",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Pit Viper"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/99605b694ab99ad6d22df89a0a0edc6f1f6ed46ca02ec7575732597823e45621.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 逮捕者",
                "min": 0,
                "max": 0.6,
                "target_box": "set_italy",
                "itemid": 363,
                "list": "\nAWP | 响尾蛇",
                "len": 1,
                "box_value": "set_italy",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Full Stop"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5a9b698e29e02d8bb4e52a7540ee993a9c18c5517103868633dbe9674346268e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 红苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_italy",
                "itemid": 364,
                "list": "\nAWP | 响尾蛇",
                "len": 1,
                "box_value": "set_italy",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Candy Apple"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cf78fa10b89f3fd7338dd9a988430022f50d20dda4e291df3bfadbe028cc47b2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 深蓝电镀处理",
                "min": 0,
                "max": 0.08,
                "target_box": "set_italy",
                "itemid": 365,
                "list": "\nAWP | 响尾蛇",
                "len": 1,
                "box_value": "set_italy",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Anodized Navy"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/576c8e68259253d0b3482f22a2c34bbb4594b2e9ea62cadd598eff6dc54fdd40.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "XM1014 | 加州迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_italy",
                "itemid": 366,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | CaliCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/9f7921c9c059fcf3360f954898d974fb193263238eb461dbf32226cde2f6c912.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A1 消音型 | 北方森林",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 367,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Boreal Forest"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/4ca656b33e5ccd64b7ca65b49a2ca661f546859607898ed4ad48062b251253a6.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "UMP-45 | 硝烟",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 368,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Gunsmoke"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/27f85741674a7c4ad321f5fbf9749a92d0c0c62265ba1a18f7765197b87c4ca3.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 坚毅大理石纹",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 369,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Granite Marbleized"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/e9309145222773e9a6c4e52fcb249253befe57f7775d48277deecddb8bde2a27.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "双持贝瑞塔 | 人工染色",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 370,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Stained"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/562443b979a50c51bbaf3f6484abb3b0f1e079cb271bc1e5beee4882755704f0.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "新星 | 红苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_italy",
                "itemid": 371,
                "list": "\n截短霰弹枪 | 逮捕者\n格洛克 18 型 | 红苹果\nMP7 | 深蓝电镀处理",
                "len": 3,
                "box_value": "set_italy",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Candy Apple"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3b0598e0494c36fbadcbe6b4a1580e4df2e4c2b7f3049f7214992367839c2cdf.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 地下水",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 372,
                "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
                "len": 6,
                "box_value": "set_italy",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Groundwater"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/06190a06a40e197c09b326d881d310444677086a73ac7d976913eb14a97fb7ed.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 雇佣兵",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 373,
                "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
                "len": 6,
                "box_value": "set_italy",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Contractor"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8fc88d209536bfd6a4e21d9b9404c2aced86c53cea235d44a4aa5b76ab6531f6.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "法玛斯 | 殖民侵略者",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 374,
                "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
                "len": 6,
                "box_value": "set_italy",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Colony"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/a3c35c0a446c4914c26354600809813dfe726866c4a8efe5875f0c9e28d13c15.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 沙丘之黄",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 375,
                "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
                "len": 6,
                "box_value": "set_italy",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Sand Dune"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cfa742525fe251a84be8d3aa9f80281aa0a3e496c282e8aa37c7eb7e1fd30821.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "PP-野牛 | 沙漠虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_italy",
                "itemid": 376,
                "list": "\nXM1014 | 加州迷彩\nM4A1 消音型 | 北方森林\nUMP-45 | 硝烟\nP2000 | 坚毅大理石纹\n双持贝瑞塔 | 人工染色\n新星 | 红苹果",
                "len": 6,
                "box_value": "set_italy",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Sand Dashed"
            }
        ]
    },
    {
        "name": "炼狱小镇收藏品",
        "en_name": "The Inferno Collection",
        "cover": "https://static.mobi2077.com/market-csgo/4f78cc64bc3b02bce323d9079583b8048856f3ccabc754517a26a6b091200573.png",
        "type": 1,
        "box_value": "set_inferno",
        "children": [
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7cd3ff663d32cde298321f9faa88f46693eac0f3c62455d95b4caab6d75e6bdc.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 黄铜",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno",
                "itemid": 377,
                "list": "",
                "len": 0,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Brass"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6d85ef208061f5c9ef5e9063c433218e40fb4f776ebea85c725371b6ae047b09.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 深蓝电镀处理",
                "min": 0,
                "max": 0.08,
                "target_box": "set_inferno",
                "itemid": 378,
                "list": "",
                "len": 0,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Anodized Navy"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/749a6d3efef71f32656942a505eea5d5ce47596f7bfcc4f0aa70aeca058aba46.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 狂哮飓风",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno",
                "itemid": 379,
                "list": "\nTec-9 | 黄铜\n双持贝瑞塔 | 深蓝电镀处理",
                "len": 2,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Tornado"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/42c589cc962fe4e254a1fe2bf061c82984f792b4923bddac337dd87fd591c7ce.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 硝烟",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno",
                "itemid": 380,
                "list": "\nTec-9 | 黄铜\n双持贝瑞塔 | 深蓝电镀处理",
                "len": 2,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Gunsmoke"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/c391ddc6a4936c988d1ba481773a0407408c80f6454138ecffa4df196c91185c.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 胡桃木",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno",
                "itemid": 381,
                "list": "\nM4A4 | 狂哮飓风\nP250 | 硝烟",
                "len": 2,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Walnut"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0ffea55beaec5c1e09ecb9b86b8a2409821002faa9a3fd140a178622af07b970.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 沙丘之黄",
                "min": 0,
                "max": 1,
                "target_box": "set_inferno",
                "itemid": 382,
                "list": "\nM4A4 | 狂哮飓风\nP250 | 硝烟",
                "len": 2,
                "box_value": "set_inferno",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Sand Dune"
            }
        ]
    },
    {
        "name": "神魔收藏品",
        "en_name": "The Gods and Monsters Collection",
        "cover": "https://static.mobi2077.com/market-csgo/f74444a29b524db50b620ef1543ac31b83a3728822ea592ad5d9e484fa51d189.png",
        "type": 1,
        "box_value": "set_gods_and_monsters",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/883faeb24aa67aa0a27d5276b8c0b56000d3dee142a129aa241a2d737d86e08b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 美杜莎",
                "min": 0,
                "max": 1,
                "target_box": "set_gods_and_monsters",
                "itemid": 383,
                "list": "",
                "len": 0,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Medusa"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5856184044643fb185af6e89723c32b0bfb2d7bc77a74f9a90f29bdafee05c70.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 波塞冬",
                "min": 0,
                "max": 0.33,
                "target_box": "set_gods_and_monsters",
                "itemid": 384,
                "list": "\nAWP | 美杜莎",
                "len": 1,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Poseidon"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9261ae6b9963e85ff53e4ef44c516733d3830832b4d30f2a079221c5ffd683f5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "G3SG1 | 柯罗诺斯",
                "min": 0,
                "max": 0.4,
                "target_box": "set_gods_and_monsters",
                "itemid": 385,
                "list": "\nM4A4 | 波塞冬",
                "len": 1,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Chronos"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/98fa97be7eab4930dd3fea89c376e5ff4a1ebd1a90953a9a1c864c5c78a84daf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 伊卡洛斯殒落",
                "min": 0,
                "max": 0.1,
                "target_box": "set_gods_and_monsters",
                "itemid": 386,
                "list": "\nM4A4 | 波塞冬",
                "len": 1,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Icarus Fell"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e3afd9fdabf53a0823792aac05860c73d407cdad90eca54dfe9a4efc070c43a2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 弥诺陶洛斯迷宫",
                "min": 0,
                "max": 0.39,
                "target_box": "set_gods_and_monsters",
                "itemid": 387,
                "list": "\nG3SG1 | 柯罗诺斯\nM4A1 消音型 | 伊卡洛斯殒落",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Minotaur's Labyrinth"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d92d7684b859a074d90ff6e7ca1b281f556c75f594068627a3b9b76285c7ce47.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 潘多拉魔盒",
                "min": 0,
                "max": 0.3,
                "target_box": "set_gods_and_monsters",
                "itemid": 388,
                "list": "\nG3SG1 | 柯罗诺斯\nM4A1 消音型 | 伊卡洛斯殒落",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Pandora's Box"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/44afbdd4b41ccd4db19c072d2aa4df3331fbb1561c282271d6a4f2e835994adf.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "Tec-9 | 哈迪斯",
                "min": 0,
                "max": 1,
                "target_box": "set_gods_and_monsters",
                "itemid": 389,
                "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Hades"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8cd601032ba968c3a15e35815cfa712e38078bd3be4a37cdb56e19ff49ff7d38.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 寻路者",
                "min": 0,
                "max": 0.35,
                "target_box": "set_gods_and_monsters",
                "itemid": 390,
                "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Pathfinder"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/9f4f5eb55f9b930f1134ea3df851ce918dab7f2830a76c4f1fa8432a0fa99721.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AWP | 狮子之日",
                "min": 0,
                "max": 0.5,
                "target_box": "set_gods_and_monsters",
                "itemid": 391,
                "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Sun in Leo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/1088349f135ed612234b91425beb129f3318e6734bfa3c9cff797fd7497f487d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M249 | 海滨预测者",
                "min": 0,
                "max": 0.5,
                "target_box": "set_gods_and_monsters",
                "itemid": 392,
                "list": "\nUMP-45 | 弥诺陶洛斯迷宫\nMP9 | 潘多拉魔盒",
                "len": 2,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Shipping Forecast"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/48c9212b96fed3999b4060c34776e626168f2d9ec4b13a5bfc3b9bfccf359013.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 星点",
                "min": 0,
                "max": 0.55,
                "target_box": "set_gods_and_monsters",
                "itemid": 393,
                "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
                "len": 4,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Asterion"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cbcd3f52d6fbaefe79ab265dfef6ba880030f101f7835fcd93f290282e1e0293.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "AUG | 代达罗斯之殇",
                "min": 0,
                "max": 0.55,
                "target_box": "set_gods_and_monsters",
                "itemid": 394,
                "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
                "len": 4,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Daedalus"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/542ab2c2258a62385f3d917d36a9d8fead404ff4f6c38594f7d36ce5f7550144.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 天秤之月",
                "min": 0,
                "max": 0.5,
                "target_box": "set_gods_and_monsters",
                "itemid": 395,
                "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
                "len": 4,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Moon in Libra"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3356826d02f75ebf1feb206c82ff8e4f4a35086c47624f485323037d79ffe43f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 天秤之月",
                "min": 0,
                "max": 0.5,
                "target_box": "set_gods_and_monsters",
                "itemid": 396,
                "list": "\nTec-9 | 哈迪斯\nP2000 | 寻路者\nAWP | 狮子之日\nM249 | 海滨预测者",
                "len": 4,
                "box_value": "set_gods_and_monsters",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Moon in Libra"
            }
        ]
    },
    {
        "name": "炙热沙城 II 收藏品",
        "en_name": "The Dust 2 Collection",
        "cover": "https://static.mobi2077.com/market-csgo/29dc6dbb16ab88d0496c0d6e846c845e05b3d6513dde73676719ddfcdc6fec67.png",
        "type": 1,
        "box_value": "set_dust_2",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d41cb0c79c72bfdb95fedf63917e27cb7e574065d2dc26d1035557d3177c90d2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "R8 左轮手枪 | 渐变琥珀",
                "min": 0,
                "max": 0.4,
                "target_box": "set_dust_2",
                "itemid": 397,
                "list": "",
                "len": 0,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Amber Fade"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ec36fd4bd595514151ca2d19cb93e30b6fee945c32bfbd860de6798b7db802e5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 渐变琥珀",
                "min": 0,
                "max": 0.4,
                "target_box": "set_dust_2",
                "itemid": 398,
                "list": "\nR8 左轮手枪 | 渐变琥珀",
                "len": 1,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Amber Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/46a7bda842ee3024ca1f42330e6ac2187d6ece28a83446a23f7d208f145cbcee.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 大马士革钢",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 399,
                "list": "\nP2000 | 渐变琥珀",
                "len": 1,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Damascus Steel"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2b97a573bb6fc677b19605f99dd9a379de8eafdc196fb1dedafe7aa025638ca9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 黄铜",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 400,
                "list": "\nP2000 | 渐变琥珀",
                "len": 1,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Brass"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3977683992a8818e364c1917b08373160af86f99007f9009b17646b48e827029.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A1 消音型 | 多变迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_dust_2",
                "itemid": 401,
                "list": "\nP2000 | 渐变琥珀",
                "len": 1,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | VariCamo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/38256691445cf4bad431b26121f8dea4bb743ebe46f71c660163496b3ddd5d9e.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "截短霰弹枪 | 蝮蛇迷彩",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 402,
                "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
                "len": 3,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Snake Camo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d200a52c516643d791cd8fc5acea6e6834462da6721e4a8e02c0c0a249348376.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AK-47 | 狩猎网格",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 403,
                "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
                "len": 3,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Safari Mesh"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b17602b469489fffd493018429c148e80dda010cacc6443325e7a013b83d1e37.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "FN57 | 橘皮涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 404,
                "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
                "len": 3,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Orange Peel"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/f38b13b5f04ff6fcd22e1d0981398150f347cce61684d63800f3943ca68e845f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAC-10 | 棕榈色",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 405,
                "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
                "len": 3,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Palm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/9f896f27167c2f465b4cfbac900507d42b537969081e35df16bbda7a7576b57e.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "Tec-9 | 多变迷彩",
                "min": 0,
                "max": 0.6,
                "target_box": "set_dust_2",
                "itemid": 406,
                "list": "\nSG 553 | 大马士革钢\nPP-野牛 | 黄铜\nM4A1 消音型 | 多变迷彩",
                "len": 3,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | VariCamo"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/9c66e312182905e291b85d433bb19ea6a86af081c16d935bac21655468c79bff.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 沙漠风暴",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 407,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Desert Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/ead7a2a1a9afbf425693f118774fad1f74c1ecb99f457febc1bc6b22d6fc5305.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P250 | 沙丘之黄",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 408,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Sand Dune"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/62608b10a2b626839e2d8b8a054cd429095942db63fc9a695b84cdb7a3505704.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SCAR-20 | 沙漠网格",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 409,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Sand Mesh"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/5b5496dd4ee18b1a8db5f3ba11f64682baca8db3cac61cce4d21dd692e3c5cab.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P90 | 沙漠涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 410,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Sand Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0e0f40db3ad1545db10dcc4ed6fec0b4c7721cb4598117edc97de36c680fdf7b.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 沙漠虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 411,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Sand Dashed"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/1c785fe747d9f09cfcb8f6a3745b481377c2b24846262957be94af844f0f0f06.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 捕食者",
                "min": 0,
                "max": 1,
                "target_box": "set_dust_2",
                "itemid": 412,
                "list": "\n截短霰弹枪 | 蝮蛇迷彩\nAK-47 | 狩猎网格\nFN57 | 橘皮涂装\nMAC-10 | 棕榈色\nTec-9 | 多变迷彩",
                "len": 5,
                "box_value": "set_dust_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Predator"
            }
        ]
    },
    {
        "name": "炙热沙城收藏品",
        "en_name": "The Dust Collection",
        "cover": "https://static.mobi2077.com/market-csgo/94b382e33328ce28a2b2fca19e2acc5786f9fb8e9b568f627853d95370421adc.png",
        "type": 1,
        "box_value": "set_dust",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d789553b1fc771a5403356cc2d8c6d7f0ac1f2736db08593d85c6aa4859db0ac.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 黄铜",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 413,
                "list": "",
                "len": 0,
                "box_value": "set_dust",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Brass"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/13bb0f6919894fe187f1da4546f5643c96ddbff15a2d08d7d38f4395f5f6e8f5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 致命红蝎",
                "min": 0,
                "max": 0.08,
                "target_box": "set_dust",
                "itemid": 414,
                "list": "",
                "len": 0,
                "box_value": "set_dust",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Scorpion"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/dc64fd6ebf7e2f7e91baeb44bf5748d15c1758f7974ad55aa112aff2e7d31c63.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 炽烈之炎",
                "min": 0,
                "max": 0.08,
                "target_box": "set_dust",
                "itemid": 415,
                "list": "",
                "len": 0,
                "box_value": "set_dust",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/efe3f1c385d9ee89469d1cc12613117b9665ee3db458a1277f6d5c597e09e7dc.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 镀铜",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 416,
                "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Copper"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cc75fbe77ab268872492a639de2758525740590a9bd7181ce87365d005b7291d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 铜斑蛇",
                "min": 0.12,
                "max": 0.38,
                "target_box": "set_dust",
                "itemid": 417,
                "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Copperhead"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/282d4e1c3ee8249b69a61e49bcd37b3d49742d6f7edb456d31a222326abfdcef.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AWP | 蝮蛇迷彩",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 418,
                "list": "\n格洛克 18 型 | 黄铜\nP2000 | 致命红蝎\n沙漠之鹰 | 炽烈之炎",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Snake Camo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c5690bab0ad89f7f26caf41214e3088a08b58da29c9928e88f9fb7d8476752ce.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AK-47 | 捕食者",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 419,
                "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Predator"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d47798aa8a056441f5f8cb2bdf0616ae2851de094133a92de818c0eb8b906518.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SCAR-20 | 棕榈色",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 420,
                "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Palm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/6dd4cdf5e4c25b559b40498e0e3bb62dd58195024d57ef95d4e3cbd30fca65b2.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 沙漠风暴",
                "min": 0,
                "max": 1,
                "target_box": "set_dust",
                "itemid": 421,
                "list": "\n截短霰弹枪 | 镀铜\nAUG | 铜斑蛇\nAWP | 蝮蛇迷彩",
                "len": 3,
                "box_value": "set_dust",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Desert Storm"
            }
        ]
    },
    {
        "name": "古堡激战收藏品",
        "en_name": "The Cobblestone Collection",
        "cover": "https://static.mobi2077.com/market-csgo/fb59b6819bdf5e2772c379f56c9ed8e5b8ab3638f85341597b239f5bfa9a95ae.png",
        "type": 1,
        "box_value": "set_cobblestone",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/3be5ab2c33c6723569f7a2189a237ae9c3e03966854106b213e844fc92a0d32b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 巨龙传说",
                "min": 0,
                "max": 0.7,
                "target_box": "set_cobblestone",
                "itemid": 422,
                "list": "",
                "len": 0,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Dragon Lore"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/029cc835ea1527ce39c456ff42113f6778e4d55d17fac7e63c832fd6f032588c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 骑士",
                "min": 0,
                "max": 0.1,
                "target_box": "set_cobblestone",
                "itemid": 423,
                "list": "\nAWP | 巨龙传说",
                "len": 1,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Knight"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6f611bb85d3aa13ca6f5e2f56d156da96aa2f1e3c2cb8dff92b8495330b3516b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 手上加农炮",
                "min": 0.01,
                "max": 0.7,
                "target_box": "set_cobblestone",
                "itemid": 424,
                "list": "\nM4A1 消音型 | 骑士",
                "len": 1,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Hand Cannon"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5109ecef0e4c7d5368d5e0b2f06e88305b48a3671c6bd5faa508015dd5402003.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 复古圣杯",
                "min": 0,
                "max": 0.1,
                "target_box": "set_cobblestone",
                "itemid": 425,
                "list": "\nM4A1 消音型 | 骑士",
                "len": 1,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Chalice"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1c0f459050b66fdf6e2d61660a441c05565ae4ecd0204f96d710ac2dbe64d2c0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 黑暗时代",
                "min": 0,
                "max": 0.22,
                "target_box": "set_cobblestone",
                "itemid": 426,
                "list": "\n沙漠之鹰 | 手上加农炮\nCZ75 | 复古圣杯",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Dark Age"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/73ca43dd5b0eb0c43969121613aa0db14afe7e0f865a8d1b3b9b1674d774b92b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 坚固链甲",
                "min": 0,
                "max": 0.22,
                "target_box": "set_cobblestone",
                "itemid": 427,
                "list": "\n沙漠之鹰 | 手上加农炮\nCZ75 | 复古圣杯",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Chainmail"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d6234f255e8d6f74e3ccaa9740cf360c416852771ff04dad670e1f3c79e311cb.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "USP 消音版 | 宝蓝之色",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_cobblestone",
                "itemid": 428,
                "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Royal Blue"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d19d2b05cfabe62c45e6071ec9612bd1e467c8bdff5026407032b055863727be.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "新星 | 绿苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_cobblestone",
                "itemid": 429,
                "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Green Apple"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/666782d8712ff0eb2081f10da6685d33b6ab6d9e48082bdb28f3cbedebd380a3.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAG-7 | 银质",
                "min": 0,
                "max": 0.08,
                "target_box": "set_cobblestone",
                "itemid": 430,
                "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Silver"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/e55ea3fb2f416be58e00161185cc4fa82cb5100cedc4c4e8048a1779313f4724.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "截短霰弹枪 | 外表生锈",
                "min": 0,
                "max": 1,
                "target_box": "set_cobblestone",
                "itemid": 431,
                "list": "\nMP9 | 黑暗时代\nP2000 | 坚固链甲",
                "len": 2,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Rust Coat"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/f68a8b2e4a49afccc709bb8515e01f16df92279c51d8a51c08791968dfd9637f.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "P90 | 暴风呼啸",
                "min": 0,
                "max": 1,
                "target_box": "set_cobblestone",
                "itemid": 432,
                "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
                "len": 4,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/b5f0b2cf88d03e57c1fc244f3ba9795153c800505f4f63dc19070d9e978e7e85.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 紫青之色",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_cobblestone",
                "itemid": 433,
                "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
                "len": 4,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Indigo"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0cf8fceae5113b52e024c08a79d69ed57ac760d5a3465204d820ffc415c4dad7.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAC-10 | 紫青之色",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_cobblestone",
                "itemid": 434,
                "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
                "len": 4,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Indigo"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3d63c133957c82b18383098bb345f2e0bbdce59735df1db8286d34cdbe3f889e.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SCAR-20 | 暴风呼啸",
                "min": 0,
                "max": 1,
                "target_box": "set_cobblestone",
                "itemid": 435,
                "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
                "len": 4,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Storm"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/32674e1e10cd782e5f4e6132107c6bb56874e152a6637a7659d4e29fc0d71453.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "双持贝瑞塔 | 蔷薇",
                "min": 0,
                "max": 0.22,
                "target_box": "set_cobblestone",
                "itemid": 436,
                "list": "\nUSP 消音版 | 宝蓝之色\n新星 | 绿苹果\nMAG-7 | 银质\n截短霰弹枪 | 外表生锈",
                "len": 4,
                "box_value": "set_cobblestone",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Briar"
            }
        ]
    },
    {
        "name": "解体厂收藏品",
        "en_name": "The Chop Shop Collection",
        "cover": "https://static.mobi2077.com/market-csgo/a30084fb7b30bbd23ea66f584b6a439f02a1041fc0c47be8c5aff62acaa326e4.png",
        "type": 1,
        "box_value": "set_chopshop",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/8012651188458e9f8ce64918b1c7d27b12079e5c2fc576a3c64f353f6f8b02cc.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "格洛克 18 型 | 暮光星系",
                "min": 0,
                "max": 0.2,
                "target_box": "set_chopshop",
                "itemid": 437,
                "list": "",
                "len": 0,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Twilight Galaxy"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/71d186b52fe66a32df75e9c37268545517e45b80575f0d70fb2b9599b5a88502.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 赤红新星",
                "min": 0,
                "max": 0.08,
                "target_box": "set_chopshop",
                "itemid": 438,
                "list": "",
                "len": 0,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Hot Rod"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/eb6c0856b7bf7baaf890b129f8c0363098389d55b5cf16a5ccc61101e583da8b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 威吓者",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_chopshop",
                "itemid": 439,
                "list": "\n格洛克 18 型 | 暮光星系\nM4A1 消音型 | 赤红新星",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Bulldozer"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0c4fbb739a6050084b39abeec8dcd4969b5ca4fa5ff3f9c2c4fb9f77d084a37a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 决斗家",
                "min": 0,
                "max": 1,
                "target_box": "set_chopshop",
                "itemid": 440,
                "list": "\n格洛克 18 型 | 暮光星系\nM4A1 消音型 | 赤红新星",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Duelist"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/08141d3ae5f77d3512e5d72ff001351eb2e202f696355d58bd9ca56aa0d28471.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 渐变之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_chopshop",
                "itemid": 441,
                "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Fade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8d9843578f881c288733a30361ae152d7603e8c73dc510f81e58bc88d07e02f8.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 银装素裹",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_chopshop",
                "itemid": 442,
                "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Whiteout"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6b8f8883f44f3c2952c439016e797f834f465e0595458d85261b18d54ef72813.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 逮捕者",
                "min": 0,
                "max": 0.6,
                "target_box": "set_chopshop",
                "itemid": 443,
                "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Full Stop"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/28356b65a48d135eee98f04b9980a9133bd9186cdfb65c40f96ec328c362cc86.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 氮化处理",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_chopshop",
                "itemid": 444,
                "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Nitro"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a1e060ed0d1e011e83306a7d5fbd304ecdc083536afdb37f4ca01c93548b994f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 翡翠色调",
                "min": 0,
                "max": 0.08,
                "target_box": "set_chopshop",
                "itemid": 445,
                "list": "\nSG 553 | 威吓者\n双持贝瑞塔 | 决斗家",
                "len": 2,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Emerald"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/be5737c28dbe4c87e93b9849b0a098181ac4d6315b78b58d531313968eddbd81.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "沙漠之鹰 | 噩梦之夜",
                "min": 0,
                "max": 1,
                "target_box": "set_chopshop",
                "itemid": 446,
                "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
                "len": 5,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Night"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c6e4c653edcf2bc274866805ff706dc53cd16e9b0c25151e6f9e3959e22d5693.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "加利尔 AR | 都市瓦砾",
                "min": 0,
                "max": 0.5,
                "target_box": "set_chopshop",
                "itemid": 447,
                "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
                "len": 5,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Urban Rubble"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/7381864a1b212b142e959add057f9047c6ad268e1f8519f411c1b4f36352d4c2.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "USP 消音版 | 绿色伞兵",
                "min": 0,
                "max": 0.8,
                "target_box": "set_chopshop",
                "itemid": 448,
                "list": "\nMAC-10 | 渐变之色\nP250 | 银装素裹\nMP7 | 逮捕者\nFN57 | 氮化处理\nCZ75 | 翡翠色调",
                "len": 5,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Para Green"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cef6f3fe99c76358212e058863c565264e13b9e472238437d49c9b9b6e2e95a0.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SCAR-20 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_chopshop",
                "itemid": 449,
                "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
                "len": 3,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/09d8aed9bc9e036c92fac414c3c3dfa7c3731ee552bafbdbb437204889cdcdf0.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "CZ75 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_chopshop",
                "itemid": 450,
                "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
                "len": 3,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0a0185aee3dd57d784740fec831d0c280845405d418e793d89e804bd8d61e51d.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "M249 | 冲击钻",
                "min": 0,
                "max": 1,
                "target_box": "set_chopshop",
                "itemid": 451,
                "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
                "len": 3,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Impact Drill"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/090fcffc69999ea5dc6ff325b3bfd8ff6f1b245e49313db77f5aee220d613e64.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MAG-7 | 海鸟",
                "min": 0,
                "max": 1,
                "target_box": "set_chopshop",
                "itemid": 452,
                "list": "\n沙漠之鹰 | 噩梦之夜\n加利尔 AR | 都市瓦砾\nUSP 消音版 | 绿色伞兵",
                "len": 3,
                "box_value": "set_chopshop",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Seabird"
            }
        ]
    },
    {
        "name": "死城之谜收藏品",
        "en_name": "The Cache Collection",
        "cover": "https://static.mobi2077.com/market-csgo/36b0b66b5185b7d7f88d6e6d87a7e15d589344d1e28669675fac3aab0feca5bb.png",
        "type": 1,
        "box_value": "set_cache",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/181a69ba092d0dad2d9bfd96abf4bc1ae126f41e1aeda49b748122dec20c1384.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 地狱看门犬",
                "min": 0,
                "max": 0.9,
                "target_box": "set_cache",
                "itemid": 453,
                "list": "",
                "len": 0,
                "box_value": "set_cache",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Cerberus"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/252f50314e36b412ced6027692e08c583254558ab8ab7938e041264268b2f0e7.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 冥界之憎",
                "min": 0,
                "max": 0.6,
                "target_box": "set_cache",
                "itemid": 454,
                "list": "",
                "len": 0,
                "box_value": "set_cache",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Styx"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/70e6bc583260ec27166794e54839cc08ea4667affd32b454a819516efcd5690a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 核子剧毒",
                "min": 0,
                "max": 0.7,
                "target_box": "set_cache",
                "itemid": 455,
                "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
                "len": 2,
                "box_value": "set_cache",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Toxic"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/838260b9451634c9c1d08ae8707149258b6a0dd0f9dbd5dc0b3ca05aa9d5a3a9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 核子反应",
                "min": 0,
                "max": 1,
                "target_box": "set_cache",
                "itemid": 456,
                "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
                "len": 2,
                "box_value": "set_cache",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Reactor"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a95c915db78381b8d6704511e88a0c485c1854ae439d67cd60e3701e9a9ed31e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 碾骨机",
                "min": 0,
                "max": 0.6,
                "target_box": "set_cache",
                "itemid": 457,
                "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
                "len": 2,
                "box_value": "set_cache",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Bone Machine"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fc659a9c7f355ee7aec297913c8dcd3ee3180f48bf7d5df5793e96fd78aecb1a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 核子花园",
                "min": 0,
                "max": 0.7,
                "target_box": "set_cache",
                "itemid": 458,
                "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
                "len": 2,
                "box_value": "set_cache",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Nuclear Garden"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/466ac1f3ba16dc9198da77843b90c84d6f74aaacae1e9f82845816d67bc1b4df.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 落日",
                "min": 0,
                "max": 1,
                "target_box": "set_cache",
                "itemid": 459,
                "list": "\n加利尔 AR | 地狱看门犬\n法玛斯 | 冥界之憎",
                "len": 2,
                "box_value": "set_cache",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Setting Sun"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/685ac9d1d28c1220084fb1a26a85ee41d40e90030ee71de7fc0516815bf975f2.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AUG | 辐射危机",
                "min": 0,
                "max": 0.55,
                "target_box": "set_cache",
                "itemid": 460,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Radiation Hazard"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/62a4e82dd96e86dffb016afd3088ef2b866fc1dd92995d39c69fdb9580f9718b.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "PP-野牛 | 化工之绿",
                "min": 0,
                "max": 0.9,
                "target_box": "set_cache",
                "itemid": 461,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Chemical Green"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/ff49a25598602a9f3740f41b34a487cfa825e1a59de589307bb21a7ff80acdf1.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "内格夫 | 核子废渣",
                "min": 0,
                "max": 0.4,
                "target_box": "set_cache",
                "itemid": 462,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Nuclear Waste"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/10411e22f3dfca52d1704bf8b99d4512947f473a3688f89d8ae5e80edda37ab5.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 核子污染",
                "min": 0,
                "max": 0.83,
                "target_box": "set_cache",
                "itemid": 463,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Contamination"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c2851a9f09d8fc1e5d1db9af2815f8acab7f35f04ae10a0bfec633160651775d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "FN57 | 热火朝天",
                "min": 0,
                "max": 1,
                "target_box": "set_cache",
                "itemid": 464,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Hot Shot"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b9336f9c6e705d4990b6d29bd97a5a5a990581c99d0e518cb75336f79883da04.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SG 553 | 辐射警告",
                "min": 0,
                "max": 0.8,
                "target_box": "set_cache",
                "itemid": 465,
                "list": "\nTec-9 | 核子剧毒\n格洛克 18 型 | 核子反应\nXM1014 | 碾骨机\nMAC-10 | 核子花园\nMP9 | 落日",
                "len": 5,
                "box_value": "set_cache",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Fallout Warning"
            }
        ]
    },
    {
        "name": "金库危机收藏品",
        "en_name": "The Bank Collection",
        "cover": "https://static.mobi2077.com/market-csgo/c46568fca3cf3802783a28b19205c4dccb8088d5cbf29b08ddc89a5ac607ad35.png",
        "type": 1,
        "box_value": "set_bank",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1b33146759766d7758b2959e4450175fd2176dcc4f89d8323079b1ec4411c246.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 富兰克林",
                "min": 0,
                "max": 0.4,
                "target_box": "set_bank",
                "itemid": 466,
                "list": "",
                "len": 0,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Franklin"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4befdd8a307e530305863a8b74e8cf408af21c5c93fecf4b65ddc97aecbff36e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 翡翠细条纹",
                "min": 0,
                "max": 1,
                "target_box": "set_bank",
                "itemid": 467,
                "list": "\nP250 | 富兰克林",
                "len": 1,
                "box_value": "set_bank",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Emerald Pinstripe"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/782a1ebb8e20f024a7afcbaef9ee7b55b54fac32e483da41c53dcf37aba1f5c0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 燕尾",
                "min": 0,
                "max": 0.8,
                "target_box": "set_bank",
                "itemid": 468,
                "list": "\nAK-47 | 翡翠细条纹",
                "len": 1,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Tuxedo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4b732a194b9f3d54f998132ce7bcc006e43e6776b2a554bd0e3700e182db319a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 陨星",
                "min": 0,
                "max": 0.18,
                "target_box": "set_bank",
                "itemid": 469,
                "list": "\nAK-47 | 翡翠细条纹",
                "len": 1,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Meteorite"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/605fedcb8357bd678ea0a0e3a20d24bb756126f2f69cb6ada5abc8a326b8de34.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 燕尾",
                "min": 0,
                "max": 0.8,
                "target_box": "set_bank",
                "itemid": 470,
                "list": "\nAK-47 | 翡翠细条纹",
                "len": 1,
                "box_value": "set_bank",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Tuxedo"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/134843cec1855323f14b27be831c96a104dcc01fefe4c2f5195d2f0b797be0bb.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "G3SG1 | 绿苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_bank",
                "itemid": 471,
                "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
                "len": 2,
                "box_value": "set_bank",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Green Apple"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/9dd7ba08031ef08290179562bd10e8ae10f39472616de53ad2080f9f7cb78cd8.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 夺命撼响",
                "min": 0.08,
                "max": 0.5,
                "target_box": "set_bank",
                "itemid": 472,
                "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
                "len": 2,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Death Rattle"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b738583f189b0ab5f7b9a06ef5d7028f8ae0831ed8af0d1cf1b63352a8f78258.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAC-10 | 银质",
                "min": 0,
                "max": 0.08,
                "target_box": "set_bank",
                "itemid": 473,
                "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
                "len": 2,
                "box_value": "set_bank",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Silver"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/802f162f232c88d4de8448239b139a6f4b984b4cda8b14a84b439fa871566999.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "新星 | 钢笼",
                "min": 0,
                "max": 0.2,
                "target_box": "set_bank",
                "itemid": 474,
                "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
                "len": 2,
                "box_value": "set_bank",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Caged Steel"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/3e91e9afd7d8e41d26ea6c7e0239b43f1eadcb083eeeaee8d4d4e296e065dd8d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "UMP-45 | 碳素纤维",
                "min": 0,
                "max": 0.12,
                "target_box": "set_bank",
                "itemid": 475,
                "list": "\nCZ75 | 燕尾\n沙漠之鹰 | 陨星",
                "len": 2,
                "box_value": "set_bank",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Carbon Fiber"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3dec85c328ba8602cbfae899ceb8f61e56d68748bfd148f7915528350c07f83b.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "R8 左轮手枪 | 骸骨外罩",
                "min": 0,
                "max": 1,
                "target_box": "set_bank",
                "itemid": 476,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Bone Mask"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8000c1d3bb6eebe8617e747047a466b9142fca983be13a5278fb040d2b72af88.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 森林 DDPAT",
                "target_box": "set_bank",
                "itemid": 477,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "min": 0,
                "max": 1,
                "hash_name": "MP7 | Forest DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0474dfe82f9f9914c71bf6376dbe40c98970a824caf59cd3b1ad526356f14968.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "内格夫 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_bank",
                "itemid": 478,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/8a4be69d67dd0b374c40109695f1ecafea1263be2552e2cb6d6f4e6083515302.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "截短霰弹枪 | 森林 DDPAT",
                "target_box": "set_bank",
                "itemid": 479,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "min": 0,
                "max": 1,
                "hash_name": "Sawed-Off | Forest DDPAT"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/0aecbbdc220ac2d13f116e8ff7345a5c81d0c87de4c14ff5a71cc80dda4a28d8.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 军队之辉",
                "min": 0,
                "max": 0.3,
                "target_box": "set_bank",
                "itemid": 480,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Army Sheen"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/bd2da27c3dc31bb9185f82c7e24f8b4e474948daa80868af6568815e789fd239.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 都市 DDPAT",
                "target_box": "set_bank",
                "itemid": 481,
                "list": "\nG3SG1 | 绿苹果\n格洛克 18 型 | 夺命撼响\nMAC-10 | 银质\n新星 | 钢笼\nUMP-45 | 碳素纤维",
                "len": 5,
                "box_value": "set_bank",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "min": 0,
                "max": 1,
                "hash_name": "Tec-9 | Urban DDPAT"
            }
        ]
    },
    {
        "name": "行李仓库收藏品",
        "en_name": "The Baggage Collection",
        "cover": "https://static.mobi2077.com/market-csgo/b8127b18112357886fd1e67689fddfea953ba23ae97d42a2bca444cf455c3759.png",
        "type": 1,
        "box_value": "set_baggage",
        "children": [
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d5b44700a5d7d651eaca3191f0f8e899b54b83c8d8f70571629e4f7b52068037.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 酷炫涂鸦皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 482,
                "list": "",
                "len": 0,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Jet Set"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1e4c9bd13a3dc40e221c6d9c066d2c25b3afc9f00f88a023814cc36ed4addb44.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 飞行员",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 483,
                "list": "\nAK-47 | 酷炫涂鸦皮革",
                "len": 1,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Pilot"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/013437ca988f56d25f30a82d638209a3138ed895a7739fe6af2f23051ca2ce81.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 至高皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 484,
                "list": "\nAK-47 | 酷炫涂鸦皮革",
                "len": 1,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | First Class"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/512d4f1f6ce6f92ecca9a7cce254642296a73fea9179b49de4fa2b0662938ec5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 至高皮革",
                "min": 0,
                "max": 0.5,
                "target_box": "set_baggage",
                "itemid": 485,
                "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
                "len": 2,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | First Class"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a645b633386c595536954e2c67f6d6da658064436910abc756cdf9fd93312f18.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 商业皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 486,
                "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
                "len": 2,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Business Class"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/87168e1d52633f686a54afe2c22a543d92e83cf33f6d2c9e924ad886e1629ba2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 红色皮革",
                "min": 0,
                "max": 0.56,
                "target_box": "set_baggage",
                "itemid": 487,
                "list": "\n沙漠之鹰 | 飞行员\nAK-47 | 至高皮革",
                "len": 2,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Red Leather"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/5eeed4c14cb435bd771a46bd65916dc323e18ab3729721e4cb025742f8462a60.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P90 | 棕色皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 488,
                "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
                "len": 3,
                "box_value": "set_baggage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Leather"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/4137926fabbc2b27242221d64fa8c456c31098c633e73f06490ed7a484a3e483.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "MAC-10 | 通勤者皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 489,
                "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
                "len": 3,
                "box_value": "set_baggage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Commuter"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/8b44354b6cf63c6178bfca6478404df168ca78bf1dcaa480b559ba4c1b1449c2.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P2000 | 廉价皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 490,
                "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
                "len": 3,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Coach Class"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/731777dc8e96bd8aa94456cff9b3ff3658791fe5960c593c1e5835fd10747d99.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SG 553 | 旅行者皮革",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 491,
                "list": "\n截短霰弹枪 | 至高皮革\nUSP 消音版 | 商业皮革\nXM1014 | 红色皮革",
                "len": 3,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Traveler"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/42890db7a1cd87cdbcaf818311e2fb23d8f54b2cbfd8e932ba5d3e15e4ff138d.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "G3SG1 | 雇佣兵",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 492,
                "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
                "len": 4,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Contractor"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/589e5fa10acccf3163c8bdd9a93564dc013cf48d129b14e5fa7a932c2ee573fd.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 橄榄格纹",
                "min": 0,
                "max": 0.58,
                "target_box": "set_baggage",
                "itemid": 493,
                "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
                "len": 4,
                "box_value": "set_baggage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Olive Plaid"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/34f7177a1ae26cccd9f4eeb5102de74f95fcd397b0e499fb759e31d9aa60895e.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "CZ75 | 绿色格纹",
                "min": 0,
                "max": 0.58,
                "target_box": "set_baggage",
                "itemid": 494,
                "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
                "len": 4,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Green Plaid"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/a247dc613d66074ee6fb494b20000c9a444305f428d6ea99d7a6cca296fb8e0e.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 绿色格纹",
                "min": 0,
                "max": 0.58,
                "target_box": "set_baggage",
                "itemid": 495,
                "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
                "len": 4,
                "box_value": "set_baggage",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Green Plaid"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/cfb7450dfaca98b7619adfb0565332118a31fbb0512e334e8c199bfa4a20a632.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 沙丘之黄",
                "min": 0,
                "max": 1,
                "target_box": "set_baggage",
                "itemid": 496,
                "list": "\nP90 | 棕色皮革\nMAC-10 | 通勤者皮革\nP2000 | 廉价皮革\nSG 553 | 旅行者皮革",
                "len": 4,
                "box_value": "set_baggage",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Sand Dune"
            }
        ]
    },
    {
        "name": "雨林遗迹收藏品",
        "en_name": "The Aztec Collection",
        "cover": "https://static.mobi2077.com/market-csgo/2d9109651ec72dae86b8f3b0e782d90f2f7b9b5cb7352dc91b0f4b5ceafc9146.png",
        "type": 1,
        "box_value": "set_aztec",
        "children": [
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/56a10e9ba6d64fd4c22a11cc17d00eec61b451ec1448ebeecf4ef121b8b2e541.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 骨化之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_aztec",
                "itemid": 497,
                "list": "",
                "len": 0,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Ossified"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c15cc7e9dde976b839ba9947ea7afaa3129e0ee58ee3c2d984384367b0d8d65e.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "M4A4 | 丛林虎",
                "min": 0,
                "max": 1,
                "target_box": "set_aztec",
                "itemid": 498,
                "list": "\nTec-9 | 骨化之色",
                "len": 1,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Jungle Tiger"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/4fb95a363279db99f9c45cd6661fc8fb7cfd05871ee4c860b951e9ad46727b9f.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "AK-47 | 丛林涂装",
                "min": 0,
                "max": 1,
                "target_box": "set_aztec",
                "itemid": 499,
                "list": "\nTec-9 | 骨化之色",
                "len": 1,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Jungle Spray"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d6f83c3e3ea13f5a56ffce112b2e44f12b252e4f1550dabe6eaa2d4d26d82bc2.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SSG 08 | 青苔虚线",
                "min": 0,
                "max": 1,
                "target_box": "set_aztec",
                "itemid": 500,
                "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
                "len": 2,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Lichen Dashed"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/1e39542042ffabffcddd096d00f362f8432fbc4bf8b9a763378949d93b22c073.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "FN57 | 狂野丛林",
                "min": 0,
                "max": 1,
                "target_box": "set_aztec",
                "itemid": 501,
                "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
                "len": 2,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Jungle"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/ad4c7803f2db28c85f9c2af0ed7fdf0dc22b887fd2d1c8345f63b1576e202654.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "新星 | 森林之叶",
                "min": 0,
                "max": 1,
                "target_box": "set_aztec",
                "itemid": 502,
                "list": "\nM4A4 | 丛林虎\nAK-47 | 丛林涂装",
                "len": 2,
                "box_value": "set_aztec",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Forest Leaves"
            }
        ]
    },
    {
        "name": "仓库突击收藏品",
        "en_name": "The Assault Collection",
        "cover": "https://static.mobi2077.com/market-csgo/de3c7eaa56cb3a0a7780f9f291209981e7f10872766f6041f3da0fdc24f34319.png",
        "type": 1,
        "box_value": "set_assault",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5eac6f9e347a8ae35a94c8d75d643c72381f139c801307344fe1a5d0cf3310ef.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 渐变之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_assault",
                "itemid": 503,
                "list": "",
                "len": 0,
                "box_value": "set_assault",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Fade"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/93e8b4a060713bea08f3642cbf7e2526846115c9ebbbaeda1e46f6faa42b323c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 威吓者",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_assault",
                "itemid": 504,
                "list": "",
                "len": 0,
                "box_value": "set_assault",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Bulldozer"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4c0866443cf4397e0956bea0a67377a09743a2571aebb1a8c607033873fed3a6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 赤红新星",
                "min": 0,
                "max": 0.08,
                "target_box": "set_assault",
                "itemid": 505,
                "list": "\n格洛克 18 型 | 渐变之色\nMP9 | 威吓者",
                "len": 2,
                "box_value": "set_assault",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Hot Rod"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3d1e9ff7012cc9b8430e2b4dfa9effcd328f04ab7baaffc94ac2c1fa25c35581.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 深蓝电镀处理",
                "min": 0,
                "max": 0.08,
                "target_box": "set_assault",
                "itemid": 506,
                "list": "\n格洛克 18 型 | 渐变之色\nMP9 | 威吓者",
                "len": 2,
                "box_value": "set_assault",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Anodized Navy"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/2ac0d97ae67dd3d0f9a77c21a999a749974e9d26b9a1e6330bceec0149c590f1.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "FN57 | 红苹果",
                "min": 0,
                "max": 0.3,
                "target_box": "set_assault",
                "itemid": 507,
                "list": "\nAUG | 赤红新星\n内格夫 | 深蓝电镀处理",
                "len": 2,
                "box_value": "set_assault",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Candy Apple"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/3d192ab13a175219ceb911c49205841b28322faf9c387253cfa54bab15f795e8.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "UMP-45 | 色如焦糖",
                "min": 0,
                "max": 1,
                "target_box": "set_assault",
                "itemid": 508,
                "list": "\nFN57 | 红苹果",
                "len": 1,
                "box_value": "set_assault",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Caramel"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/964882a82ef027d1ba38aafa310168ffd919e2c9cdbc37c5e68e4e10822c58bb.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "SG 553 | 狂哮飓风",
                "min": 0,
                "max": 1,
                "target_box": "set_assault",
                "itemid": 509,
                "list": "\nFN57 | 红苹果",
                "len": 1,
                "box_value": "set_assault",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Tornado"
            }
        ]
    },
    {
        "name": "无畏收藏品",
        "en_name": "The Alpha Collection",
        "cover": "https://static.mobi2077.com/market-csgo/9e1f7f78654c0866a330a574b1b2bf65d4c37e87bc21445f104526ade9a487ba.png",
        "type": 1,
        "box_value": "set_bravo_ii",
        "children": [
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4a417f912d57cf0df5bd821e8027c2a975968a3ea7d5add6dd78737d77607299.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SCAR-20 | 翡翠色调",
                "min": 0,
                "max": 0.08,
                "target_box": "set_bravo_ii",
                "itemid": 510,
                "list": "",
                "len": 0,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Emerald"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7db5d68525ea7b61d84277693d9cfb1e2141cb51b9b7f83ec2f947d2f7e0bed6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 喷焰者",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_bravo_ii",
                "itemid": 511,
                "list": "",
                "len": 0,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Spitfire"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b2390c3ee3c6e1d3c7a466329ca87ff70731f9ba2395a3faae974b29d95db9e3.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 深蓝电镀处理",
                "min": 0,
                "max": 0.08,
                "target_box": "set_bravo_ii",
                "itemid": 512,
                "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
                "len": 2,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Anodized Navy"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/464e3ee812742e7d6ea8b6351ac68ec08a283b8b4f7e44a882e2c249838ac94e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 外表生锈",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 513,
                "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
                "len": 2,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Rust Coat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/152dceb51dcd8bbc1cd944aeb8694fe7bc4318d9490b6236b14b6197f8bbcb50.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 危机色调",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 514,
                "list": "\nSCAR-20 | 翡翠色调\n法玛斯 | 喷焰者",
                "len": 2,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Hazard"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/b2fa25b38a9aab267b534aa450780b017fe00acbc126ea6b7999f20769b85708.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "P250 | 多面体",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 515,
                "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
                "len": 3,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Facets"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/c7fa504d2b0b8d82e20bfd21451dea140b13a0e29247d6e8f10b5711ea4618e8.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "截短霰弹枪 | 马赛克",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 516,
                "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
                "len": 3,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Mosaico"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/386ab607ecf945ba16cc91ec1bf19b6ebecd4e0aad0cc4e38c54334a1d3c8c18.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "内格夫 | 棕榈色",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 517,
                "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
                "len": 3,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Palm"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/1f6e7601f48c2ffe4578bfb90b85efb098f02417ab1f217d2134ceadce6fdb8d.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "SSG 08 | 玛雅之梦",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 518,
                "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
                "len": 3,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Mayan Dreams"
            },
            {
                "rarity": "工业级",
                "cover": "https://static.mobi2077.com/market-csgo/d77d086f260ccd06476ac9cac7c9deeb70db60c7ce8c9fbd297c14c4ac027100.png",
                "en_rarity": "Rarity_Uncommon_Weapon",
                "name": "格洛克 18 型 | 沙丘之黄",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 519,
                "list": "\nAUG | 深蓝电镀处理\nPP-野牛 | 外表生锈\nMAG-7 | 危机色调",
                "len": 3,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Sand Dune"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/ee3ccc07a2304526acec043670fd97fc2490248ae7092638643e9b87a226b693.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP7 | 地下水",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 520,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Groundwater"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/d9c6e0083e0eefc9ea2d2bbc9bc670ab5e8557503c51c459d207c3928c1f44bc.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "XM1014 | 狂野丛林",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 521,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Jungle"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/da267dc0e690003fa3897f3996c4c906f58a29a0f80c6dd6283c7156c9542b65.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "FN57 | 电镀青铜",
                "min": 0,
                "max": 0.08,
                "target_box": "set_bravo_ii",
                "itemid": 522,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Anodized Gunmetal"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/c44e8e3758a0efaf6e85578d06c9d907b60f75ea438cf3574976160ad0836618.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "MP9 | 干旱季节",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 523,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Dry Season"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/9752c1abbbcccf46e0195f99e10da4f38132043e81580b3b6d26c45a1dadb626.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "Tec-9 | 狂哮飓风",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_ii",
                "itemid": 524,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Tornado"
            },
            {
                "rarity": "消费级",
                "cover": "https://static.mobi2077.com/market-csgo/45ac628092956304c8aa5e7af206af048efc906fe513536257e42a535a01668b.png",
                "en_rarity": "Rarity_Common_Weapon",
                "name": "M249 | 丛林 DDPAT",
                "target_box": "set_bravo_ii",
                "itemid": 525,
                "list": "\nP250 | 多面体\n截短霰弹枪 | 马赛克\n内格夫 | 棕榈色\nSSG 08 | 玛雅之梦\n格洛克 18 型 | 沙丘之黄",
                "len": 5,
                "box_value": "set_bravo_ii",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "min": 0,
                "max": 1,
                "hash_name": "M249 | Jungle DDPAT"
            }
        ]
    },
    {
        "name": "蛇噬武器箱",
        "en_name": "Snakebite Case",
        "cover": "https://static.mobi2077.com/market-csgo/b1db16257f3a7dad5d5b4dca3076b4a84c006a9a64bd586d9f7e35d051e6adb1.png",
        "type": 2,
        "box_value": "set_community_28",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/f6fada9e8deca5038ce38dccc223527c14963a21191a7a88d635c67d113c1b39.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 活色生香",
                "min": 0,
                "max": 0.79,
                "target_box": "set_community_28",
                "itemid": 526,
                "list": "",
                "len": 0,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | In Living Color"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/3881f0d55b6f00b1de7e0716c5d5fd8d30f035d88801ec2a85a599881449b193.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "USP 消音版 | 倒吊人",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 527,
                "list": "",
                "len": 0,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | The Traitor"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/9e7e4b28728dde47dadf5e226ecff3286678df39ee4c441d0fbf8c982327d961.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "加利尔 AR | 迷人眼",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 528,
                "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
                "len": 2,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Chromatic Aberration"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/c3b304ca37058fb9a117e31e4d3daf0b1d9e711a3ec209ad9436d1a11e4ab943.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "XM1014 | 要抱抱",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_28",
                "itemid": 529,
                "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
                "len": 2,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | XOXO"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a7def8782c0a9e71728b6c6172f5e0c9bde4fa78aad81ac93e3adc48021e842b.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP9 | 爆裂食物链",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 530,
                "list": "\nM4A4 | 活色生香\nUSP 消音版 | 倒吊人",
                "len": 2,
                "box_value": "set_community_28",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Food Chain"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/97c45cadb0a0ea93d8bb60cf5149cb66513cba21ba7f7b247d219465ee3c1d9b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 墨岩",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 531,
                "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
                "len": 3,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Slate"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c8a36239b8c157110281b0106ff36f9aa159ef349ab8e4687de60a45a9563988.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 后发制人",
                "min": 0,
                "max": 0.97,
                "target_box": "set_community_28",
                "itemid": 532,
                "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
                "len": 3,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Trigger Discipline"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e94969e13c5b1ecd9f78204bd1cad50100f30ba819b6d46299d528822a815211.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 战争手柄",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 533,
                "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
                "len": 3,
                "box_value": "set_community_28",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Button Masher"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/649b2faa10fe80a7ad8c58809ba80cea4506de39ac4404bab40913c1d2371be7.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "内格夫 | 橙灰之名",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_28",
                "itemid": 534,
                "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
                "len": 3,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | dev_texture"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ffa28ce8ed5b431d1df8a7ec09d314246aab2bc19a8cd4f8fe81880dacb3eccf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 赛博先锋",
                "min": 0,
                "max": 0.85,
                "target_box": "set_community_28",
                "itemid": 535,
                "list": "\n加利尔 AR | 迷人眼\nXM1014 | 要抱抱\nMP9 | 爆裂食物链",
                "len": 3,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Cyber Shell"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/79e5d7b33550d0e5c0e8ac0c0fcb49c408206767728597d251bc28ae3ebbdf89.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 随风",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_28",
                "itemid": 536,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Windblown"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a7a75513aa99cfecdb30ab214646881311cb5183386fb3624fa5fe938d250cd2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 废物王",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 537,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Junk Yard"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9ff2172e73912fd8b07f3e3e9847f9acd0717ea98c3a4f61034b96fce5a88b66.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 动摇",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_28",
                "itemid": 538,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Oscillator"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/69b51d9ec5f3c0f8715e0b6eaee0efa4c9d686f6cf128b1969b949c7e632e2e7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 短趾雕",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_28",
                "itemid": 539,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Circaetus"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c53e4183fdf1e33a6977bd039489f1db2a311af5e984ec24243f8d6fadf86a41.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | O.S.I.P.R.",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_28",
                "itemid": 540,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | O.S.I.P.R."
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5278090e6069f7c71badc991e5342e45734977a04f4a17819164f3f878a0d5aa.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 一目了然",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 541,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Clear Polymer"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9ab89f1fd5aebd146b18f53929b49a3bacb6b6f13a77294fc64a45de5bbe664f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 重金属摇滚",
                "min": 0,
                "max": 1,
                "target_box": "set_community_28",
                "itemid": 542,
                "list": "\nAK-47 | 墨岩\n沙漠之鹰 | 后发制人\nMAC-10 | 战争手柄\n内格夫 | 橙灰之名\nP250 | 赛博先锋",
                "len": 5,
                "box_value": "set_community_28",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Heavy Metal"
            }
        ]
    },
    {
        "name": "“狂牙大行动”武器箱",
        "en_name": "Operation Broken Fang Case",
        "cover": "https://static.mobi2077.com/market-csgo/bee37092bd758869503e113f17200d8158e2d14b9339dde224089f99a21b50bf.png",
        "type": 2,
        "box_value": "set_community_27",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/724a77067df47420b68bb5b297fc0717ae5e19352d3018ccdaa1decff8f84d3b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "格洛克 18 型 | 黑色魅影",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 543,
                "list": "",
                "len": 0,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Neo-Noir"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/ac05c77acfb0cea493dee55556d33845e9af0f09a80d5603ec6176479a67af73.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 印花集",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "itemid": 1101,
                "list": "",
                "len": 0,
                "hash_name": "M4A1-S | Printstream"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/15d1bcf4cc21a01ab0d864edc9e2a309ad77c30d7cf19fe39008ff34e8c17c46.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 小绿怪",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_27",
                "itemid": 544,
                "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
                "len": 2,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Monster Mashup"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a1f2a3a4cf97f2955978d6a9d0ebbef1d3b4496eea5d0448765ac7d868b15bf3.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 赛博",
                "target_box": "set_community_27",
                "itemid": 545,
                "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
                "len": 2,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "min": 0,
                "max": 0.98,
                "hash_name": "M4A4 | Cyber Security"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f68a866f06f9ab92cd48d2d2d763738c1c0ae6cfbb57c0f36d82ec59b747a214.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "FN57 | 童话城堡",
                "min": 0.02,
                "max": 0.9,
                "target_box": "set_community_27",
                "itemid": 546,
                "list": "\n格洛克 18 型 | 黑色魅影\nM4A1 消音型 | 印花集",
                "len": 2,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Fairy Tale"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/dc91b05b14051eb3812e291a23227fe3ecfa1605eab1348e57db0e3410a4e54f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 金铋辉煌",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_27",
                "itemid": 547,
                "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
                "len": 3,
                "box_value": "set_community_27",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Gold Bismuth"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b8de8f85bb3a40fc1390b6889b7c806756508c5bcccc48cd449c5419300fe270.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SSG 08 | 抖枪",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 548,
                "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
                "len": 3,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Parallax"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/25d5a4b0b2c9eba70965fd3f99b7d55712fd7548f5a298f9767a312afb462751.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 一见青心",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 549,
                "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
                "len": 3,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Clear Polymer"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5b700620b7d898986e114b5d7cffcd858278490c44dbe1513d01e23158461adf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 灾难",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 550,
                "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
                "len": 3,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Dezastre"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/780c21ca1185bccaba7f77a9dec10de032939ecb6bddac43a086e36db1bdfffd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 亡灵之主",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 551,
                "list": "\nUSP 消音版 | 小绿怪\nM4A4 | 赛博\nFN57 | 童话城堡",
                "len": 3,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Exoskeleton"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/078acd5be2c3721ccf850fbb5d4781d641dabc0948b4406011badeee5463e3bc.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 零点行动",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 552,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Condition Zero"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b98435bf1e5253d73d86a5a3d8c812ca058727ab284755bb6f1f2a9ca4f0adb2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 等高线",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 553,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Deep Relief"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b55b6663775ee14a5bb0a57f8d84ac4eeb4a31ad4921fa9a6892cc0cd7215b69.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 污染物",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_27",
                "itemid": 554,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Contaminant"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e7faedb775925770bc1aa2224b04d8db3695d1a673aadb71bf93adc8131bd4db.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 破坏者",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 555,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Vandal"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9aefa026440dc1fe0b857e80ad023199d030d4cda21e5b0f47abc79ee7da0f76.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 血腥迷彩",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_27",
                "itemid": 556,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Digital Mesh"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1b062648969c9603b12151d895807dae55e38073de3f12ec3502da558fd88321.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 大怪兽RUSH",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_27",
                "itemid": 557,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Cocoa Rampage"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a9a7b200bf8cb17cc72ad49cd22f68097e4565232f2974f21ed9d7f18586e102.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 世仇",
                "min": 0,
                "max": 1,
                "target_box": "set_community_27",
                "itemid": 558,
                "list": "\nUMP-45 | 金铋辉煌\nSSG 08 | 抖枪\n新星 | 一见青心\n双持贝瑞塔 | 灾难\nAWP | 亡灵之主",
                "len": 5,
                "box_value": "set_community_27",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Vendetta"
            }
        ]
    },
    {
        "name": "裂空武器箱",
        "en_name": "Fracture Case",
        "cover": "https://static.mobi2077.com/market-csgo/dab4c5fabbd8e1e9ad8f2af504da3bc9e3d376cbd7b8dee883454c1685be049b.png",
        "type": 2,
        "box_value": "set_community_26",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/4c6bfe5963f31af93b3ff36421b1da1cad36b7d9350511b38d08b0d9f3ee3afc.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 阿努比斯军团",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_26",
                "itemid": 559,
                "list": "",
                "len": 0,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Legion of Anubis"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/9d3bb5a06de64bfba0ad5017fdaadf56e02175056ccfd4c68b5f4bbceac68bef.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "沙漠之鹰 | 印花集",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_26",
                "itemid": 560,
                "list": "",
                "len": 0,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Printstream"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ff3f2aba614c9639282f2fb9e5bd3ebac94d565ca7e7f81ca24bb8e595d1fea8.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "XM1014 | 埋葬之影",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_26",
                "itemid": 561,
                "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
                "len": 2,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Entombed"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/8e796a0cd241787ab43bb3d73db4dc7182e36c2a0005954002aad250f2d327b2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "格洛克 18 型 | 摩登时代",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_26",
                "itemid": 562,
                "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
                "len": 2,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Vogue"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a29758e2235f3bb881d375e371d9ca989f2db1806bdf9f172262aa2aa69f7b6d.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 齿仙",
                "min": 0,
                "max": 0.73,
                "target_box": "set_community_26",
                "itemid": 563,
                "list": "\nAK-47 | 阿努比斯军团\n沙漠之鹰 | 印花集",
                "len": 2,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Tooth Fairy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f2a86e648c197f671b387bfb8f7963ef661883116e1a0fd00978c55de3420d08.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP5-SD | 猛烈冲锋",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_26",
                "itemid": 564,
                "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
                "len": 3,
                "box_value": "set_community_26",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Kitbash"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9b9b297f795e1fcd5329e44513aa8e9c28ee3b47bbe9ae432cf20f10a1a4ae08.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 凤凰商号",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_26",
                "itemid": 565,
                "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
                "len": 3,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Connexion"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1399c35e784b4376e77e788ad34ad1c415c07c1b8fcd70e47e90b86130588fee.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 魅惑",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 566,
                "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
                "len": 3,
                "box_value": "set_community_26",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Allure"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7c9b0f67536a5a6ef66fa31ab8382799f55bea6d5458bed335aa6fc99d3d9bfd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 兄弟连",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 567,
                "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
                "len": 3,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Brother"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/38a9390f7610a477a6feba4952fa2538e4f979b3a218c09bea74309e0c42c36f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 北冥有鱼",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 568,
                "list": "\nXM1014 | 埋葬之影\n格洛克 18 型 | 摩登时代\nM4A4 | 齿仙",
                "len": 3,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Monster Call"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/20fc62d1462465a08119c0ff85f4b6ba4d11753d86712e6dfca1602a5dde03dc.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 神秘碑文",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 569,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Runic"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2d1f7e24702db8203d0f28a4e50c9793c259497d8d60104b75024b7e25c80c96.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 集装箱",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 570,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Freight"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3e491414a973092002e7f75b6acedbef62aa28e38a88826b6dd56e3860d6e9de.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 卡带",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_26",
                "itemid": 571,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Cassette"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/438f576cf73967423b1c1e23fe89f6e67b8d863ab5a754fcae2f9b6910d0462e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 主机001",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 572,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Mainframe 001"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/758460d2a9a691d7695d70c3f7cd3b60f96d79d245027cf468c58e0d9a366e2d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 锈蚀之刃",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_26",
                "itemid": 573,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Ol' Rusty"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4f90093eadd3f31d3c7a613c89598942085f763721e2ff730e63bc754fef5578.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 盘根错节",
                "min": 0,
                "max": 1,
                "target_box": "set_community_26",
                "itemid": 574,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Gnarled"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cbc3cf3671578efbb625a62fe3086e7b5e170420f228a5d87510de7742385cbf.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 飞羽",
                "min": 0,
                "max": 0.79,
                "target_box": "set_community_26",
                "itemid": 575,
                "list": "\nMP5-SD | 猛烈冲锋\n加利尔 AR | 凤凰商号\nMAC-10 | 魅惑\nTec-9 | 兄弟连\nMAG-7 | 北冥有鱼",
                "len": 5,
                "box_value": "set_community_26",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Ultralight"
            }
        ]
    },
    {
        "name": "棱彩2号武器箱",
        "en_name": "Prisma 2 Case",
        "cover": "https://static.mobi2077.com/market-csgo/d370b3b9cd8cbded40267114b9d5d003c932fd5200438d78188bab23c1fbcbff.png",
        "type": 2,
        "box_value": "set_community_25",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/a659410c66a2902a720fe2dcdeba7c5c0898020c1824c937c0fc07d19616297b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "格洛克 18 型 | 子弹皇后",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 576,
                "list": "",
                "len": 0,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Bullet Queen"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/70766b570da807039694ab4032feeec6860eb57cf07539305ef349d1dac1bad4.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 二号玩家",
                "min": 0,
                "max": 0.84,
                "target_box": "set_community_25",
                "itemid": 577,
                "list": "",
                "len": 0,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Player Two"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ca484e6063adf2b30225abcfdd376a66d45bfaf80edecda5ccf64e5570be91d2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAG-7 | 正义",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 578,
                "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
                "len": 2,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Justice"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a0fe494360f507a48e1f774ea583a6953948535a32187454d1180e824c7f03bf.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MAC-10 | 渐变迪斯科",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 579,
                "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
                "len": 2,
                "box_value": "set_community_25",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Disco Tech"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/c2f31d86add4ce2eaf8deb6767c2b8b8fab34e0cba483b187fab3c8e01f1fe2f.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 幻影破坏者",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_25",
                "itemid": 580,
                "list": "\n格洛克 18 型 | 子弹皇后\nM4A1 消音型 | 二号玩家",
                "len": 2,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Phantom Disruptor"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e06db3b421fb03cf3e2c04e09042284caff88512500e55e140b585dccfa14ebd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SSG 08 | 浮生如梦",
                "min": 0,
                "max": 0.72,
                "target_box": "set_community_25",
                "itemid": 581,
                "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
                "len": 3,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Fever Dream"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e36ed95146507e17fa596a385dc34fbd0e48e93ef53de4d550898ae44e59b1af.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 黯翼",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 582,
                "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
                "len": 3,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Darkwing"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/538d3668bfd1c01a27986cb186995cb797963a6730734b72ef1b3dcb5e9c779c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SCAR-20 | 执行者",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 583,
                "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
                "len": 3,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Enforcer"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7eb64cdbc2674defe936514c46a8767ccc1cd81c831f937a535744e1289b754a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "截短霰弹枪 | 启示录",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 584,
                "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
                "len": 3,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Apocalypto"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/aa68367d486a0d24c7bcd5f725b188958c0cbbfb3d7ffa63e79ce58698d0a25d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 酸蚀",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 585,
                "list": "\nMAG-7 | 正义\nMAC-10 | 渐变迪斯科\nAK-47 | 幻影破坏者",
                "len": 3,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Acid Etched"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/54df94e8a27e53b04aab02b7b82387700519a869b9027c695c822a238a27d545.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 骸骨锻造",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_25",
                "itemid": 586,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Bone Forged"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/549941d4633cab8a262e7e5fce788cd5121002398d390a54c19bacce4cb954f7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 原型机",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_25",
                "itemid": 587,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Prototype"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0745b78aacffaaa3a9b327022dddb2d40d701a09f0349c0f92627e422709fc6c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 沙漠精英",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 588,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Desert Strike"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d8141c8e48c6a79337eb7c0a6fdb650de07e4439f5fc57d4d5a6a170ff61736f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 蓝色层压板",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 589,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Blue Ply"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bcb6995983cf0eba7d79697f4054aca876bf3749ad0a93846bed22986d8063d0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 做旧手艺",
                "min": 0,
                "max": 1,
                "target_box": "set_community_25",
                "itemid": 590,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Distressed"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3f9025fa4dd8f6a08216d074d793115a911a4f560ef98aade989c2fe62747b2e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AWP | 毛细血管",
                "min": 0.05,
                "max": 0.7,
                "target_box": "set_community_25",
                "itemid": 591,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Capillary"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9fd229c2d641d250b533d3d3fba4f081c9acd8282405570ecc0d87a168728c93.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 汤姆猫",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_25",
                "itemid": 592,
                "list": "\nSSG 08 | 浮生如梦\nSG 553 | 黯翼\nSCAR-20 | 执行者\n截短霰弹枪 | 启示录\nP2000 | 酸蚀",
                "len": 5,
                "box_value": "set_community_25",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Tom Cat"
            }
        ]
    },
    {
        "name": "“裂网大行动”武器箱",
        "en_name": "Shattered Web Case",
        "cover": "https://static.mobi2077.com/market-csgo/4abcaf4192aff3ad18f77e58a7a90f459aed06f3b7c66292d47f07bef75fd79f.png",
        "type": 2,
        "box_value": "set_community_23",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/a84ab987a22cd14535fe6d35f5825d57306fd7fe183d00600b31ecbae38e4f48.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "MAC-10 | 潜行者",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 593,
                "list": "",
                "len": 0,
                "box_value": "set_community_23",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Stalker"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/32608882f7f2ef72b9c6b8493571eed5873c6901c70c3521bbfdedfa50e6da11.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 冲出重围",
                "target_box": "set_community_23",
                "itemid": 594,
                "list": "",
                "len": 0,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "min": 0,
                "max": 1,
                "hash_name": "AWP | Containment Breach"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/113588b8fd07c63ead0abd02c798c028820570c7a536a8308bbc2c66d79624bb.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SSG 08 | 喋血战士",
                "min": 0.14,
                "max": 0.6,
                "target_box": "set_community_23",
                "itemid": 595,
                "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
                "len": 2,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Bloodshot"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1812f38413e6a52cf3e9a93243ed902e9ee5f9fb2c3b6c3ad0435dfc921cadc8.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SG 553 | 四号栖息地",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 596,
                "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
                "len": 2,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Colony IV"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/e93f1885b4a9327e22bcf4201587ab60e46124fe7cd8af3ef9e84ef0c52fea1c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "Tec-9 | 屠杀者",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_23",
                "itemid": 597,
                "list": "\nMAC-10 | 潜行者\nAWP | 冲出重围",
                "len": 2,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Decimator"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/84b20a6048e53afb9451bd120900cb52b027aa92b945c7cb938b93e87254c5ca.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 黑曜石",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 598,
                "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
                "len": 3,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Obsidian"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/37fdecd177b56db5751ef3d6d6c8c5b449a84e95d2eb2f457db680a1b8907b35.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 七彩斑斓",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 599,
                "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
                "len": 3,
                "box_value": "set_community_23",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Neon Ply"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9bc4923e6e439b58b4c124261939b572d9ebd18e93896318e273427903c289c3.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 极地孤狼",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_23",
                "itemid": 600,
                "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
                "len": 3,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Arctic Wolf"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1d6c3053ace7cf0d2a85bd0d6536a229473aeb93d4a20a5195504eb957a7fe14.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 复古浪潮",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 601,
                "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
                "len": 3,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Rat Rod"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/68cc82b80d4267c525f15813f9909ad3393165030ec5531ff73e3e5084fcc99a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 路霸",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 602,
                "list": "\nSSG 08 | 喋血战士\nSG 553 | 四号栖息地\nTec-9 | 屠杀者",
                "len": 3,
                "box_value": "set_community_23",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Embargo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3569a22df5fb320a63fa125583a05694d43d6bb2121ffbd024fb201d86a16404.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 战隼",
                "min": 0.05,
                "max": 0.65,
                "target_box": "set_community_23",
                "itemid": 603,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Warbird"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c241ac7dc03887660d1804c03cfb721b64df4d4b812cfd25d9c4bf9fb649f471.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 撕起来",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_23",
                "itemid": 604,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Torn"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cf01820e85c2c90ca93c4cf9197f252eba7e348c28a8ee286aa239c3f7a602bc.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 左右开花",
                "min": 0,
                "max": 1,
                "target_box": "set_community_23",
                "itemid": 605,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Balance"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f40a10bece984c79cd722cb1807352d49ec97ad903c959349f60f99c626ec793.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 记忆碎片",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_23",
                "itemid": 606,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Memento"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d75f8d59be651b342e95ab1fe4b29f73b68831d92e99b7ac6e6b8878a9798dd6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 黑砂",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_23",
                "itemid": 607,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Black Sand"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4a783c2d3c8c93edd9f4dc4575ced2de70ade5f90afaaaa1ca15acb397baf31e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 风卷残云",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_23",
                "itemid": 608,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Plume"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/553b15667fadd70c50114a4bfc73bff7e10c54f370424a192364660aa4633590.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP5-SD | 鼻青脸肿",
                "min": 0,
                "max": 0.68,
                "target_box": "set_community_23",
                "itemid": 609,
                "list": "\nP2000 | 黑曜石\nMP7 | 七彩斑斓\nAUG | 极地孤狼\nAK-47 | 复古浪潮\nPP-野牛 | 路霸",
                "len": 5,
                "box_value": "set_community_23",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Acid Wash"
            }
        ]
    },
    {
        "name": "反恐精英20周年武器箱",
        "en_name": "CS20 Case",
        "cover": "https://static.mobi2077.com/market-csgo/ac557d7da7fbb65e9880715176589eb5348357b49e4e28f6df08e259029304bd.png",
        "type": 2,
        "box_value": "set_community_24",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/fd97557e36b74c76b796d07c066c55ab00ecfd901c626824bf8e0a2de7a16c63.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "法玛斯 | 纪念碑",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_24",
                "itemid": 610,
                "list": "",
                "len": 0,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Commemoration"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e2820dde1a8eecbcc378d05dcaae445b65c6c22ad5178b204ef2ec871051e7dd.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 野火",
                "min": 0.01,
                "max": 0.7,
                "target_box": "set_community_24",
                "itemid": 611,
                "list": "",
                "len": 0,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Wildfire"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/45f7a9cb0c7be01c81a52a659092235a8ae8c20b1e0435a5f3e44993b8201937.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 汪之萌杀",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_24",
                "itemid": 612,
                "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
                "len": 2,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Death by Puppy"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/63314fcb242432453a3261971c46ce6dcad2738140b215a8d8727bf0a1f8827a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 往日行动",
                "min": 0,
                "max": 0.57,
                "target_box": "set_community_24",
                "itemid": 613,
                "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
                "len": 2,
                "box_value": "set_community_24",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Nostalgia"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f95931af2dd27cefe414d966d0843b74d10dcd832bc549a63a8e9c00396479ab.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP9 | 九头蛇",
                "min": 0,
                "max": 1,
                "target_box": "set_community_24",
                "itemid": 614,
                "list": "\n法玛斯 | 纪念碑\nAWP | 野火",
                "len": 2,
                "box_value": "set_community_24",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Hydra"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/76adb302d51842f2fbea49c2953c94ab8ff962617533631fae0e1957eabaecfd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 塑胶炸弹",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_24",
                "itemid": 615,
                "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
                "len": 3,
                "box_value": "set_community_24",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Plastique"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8dc96c9dcf6527968477e9a018e5e0a200a53905eafdcc2da833306fb8777363.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 炼狱小镇",
                "min": 0,
                "max": 0.68,
                "target_box": "set_community_24",
                "itemid": 616,
                "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
                "len": 3,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Inferno"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/89c2aee562fcdfb75c11420e493a4d666f1c9adcf99cc3ccd7d44f2e3e7ba15a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 好兄弟",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_24",
                "itemid": 617,
                "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
                "len": 3,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Buddy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3ec7981aa5cb44283a2057832803522023643ec38da8a99d70605bc3ecbf844e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP5-SD | 探员",
                "min": 0,
                "max": 1,
                "target_box": "set_community_24",
                "itemid": 618,
                "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
                "len": 3,
                "box_value": "set_community_24",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Agent"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/02d93ea88cc821974fe8dc0d271006b78bd3bd86f029eb2b84d6e2b4ad100221.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M249 | 阿兹特克",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_24",
                "itemid": 619,
                "list": "\nAUG | 汪之萌杀\nP90 | 往日行动\nMP9 | 九头蛇",
                "len": 3,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Aztec"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8a86b73084bc4773f0f301f06781021a86a48afc9f36c96e9d38574a0a85423f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 烈焰天使",
                "min": 0,
                "max": 1,
                "target_box": "set_community_24",
                "itemid": 620,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Sacrifice"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/03dec41bacd632b1ed6b7ab2e2fb84718a11fc6d2a5ea75be31155eb7a1c216f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 退役",
                "min": 0,
                "max": 1,
                "target_box": "set_community_24",
                "itemid": 621,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Decommissioned"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/567b256f83d794798bf758014d19574eb90f08fe958905d2e660d400f76835d0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 仓库突击",
                "min": 0,
                "max": 0.62,
                "target_box": "set_community_24",
                "itemid": 622,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Assault"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b186628cc5d89ccc1f7f2456c3f0c94cd5de75b2c27c01544df99db271cc0f5a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 碰碰狗",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_24",
                "itemid": 623,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Popdog"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/82fe052da6332334763987153d1d0423614145b6c4e3d78447f716ebcfd70d37.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 板条箱",
                "min": 0,
                "max": 1,
                "target_box": "set_community_24",
                "itemid": 624,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Classic Crate"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bfdb7dd6aa909c9c0ae076cce187d7512d6d08c186b731bec52af5a96ebaccdf.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 闪光舞步",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_24",
                "itemid": 625,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Flash Out"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bdc566754f0e4c803d24f70009f6bb8e43bdbca5674737b4c3c77266940587d6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 1.6精英",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_24",
                "itemid": 626,
                "list": "\nUMP-45 | 塑胶炸弹\nP250 | 炼狱小镇\nFN57 | 好兄弟\nMP5-SD | 探员\nM249 | 阿兹特克",
                "len": 5,
                "box_value": "set_community_24",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Elite 1.6"
            }
        ]
    },
    {
        "name": "棱彩武器箱",
        "en_name": "Prisma Case",
        "cover": "https://static.mobi2077.com/market-csgo/378bbd84dc93e08b7558ddaaf5001a9c17bc8266d6d78fcc179e08c8e4df1dd5.png",
        "type": 2,
        "box_value": "set_community_22",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/ad94ebd7f6f90f49d859305109a5c0863d7279c49c4d5894394329b93e7c852f.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 皇帝",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_22",
                "itemid": 627,
                "list": "",
                "len": 0,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | The Emperor"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/4947563bd39073cfc34a6d0e8400c55e4d6086d2a455ae6a7bdc478289a0e6a7.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "FN57 | 怒氓",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_22",
                "itemid": 628,
                "list": "",
                "len": 0,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Angry Mob"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/177c994682208f0619565d2a12cb379c08b85d3e6c4c2e367ef706c8c00a06cf.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "XM1014 | 焚烬之鳄",
                "min": 0.14,
                "max": 0.65,
                "target_box": "set_community_22",
                "itemid": 629,
                "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
                "len": 2,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Incinegator"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/392ad433ea4ef376746c70dbaa7dd8f63ac46bf4772fff945e3a1d81ad2ced82.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 动量",
                "min": 0.05,
                "max": 1,
                "target_box": "set_community_22",
                "itemid": 630,
                "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
                "len": 2,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Momentum"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3e5cccf59f5ed885b7e8817ee3506fe1c704ee0763ea19dd365e6adca613a936.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "R8 左轮手枪 | 头骨粉碎者",
                "min": 0.25,
                "max": 0.8,
                "target_box": "set_community_22",
                "itemid": 631,
                "list": "\nM4A4 | 皇帝\nFN57 | 怒氓",
                "len": 2,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Skull Crusher"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c82c0681be6c8d260cc4e61ad15fd58e4d34b25cd7cfdea1b2a54fc6481ad01d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 城里的月光",
                "target_box": "set_community_22",
                "itemid": 632,
                "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
                "len": 3,
                "box_value": "set_community_22",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "min": 0,
                "max": 0.4,
                "hash_name": "UMP-45 | Moonrise"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8352b1cba591f091b950cd010219aa2e343a3653bbd4ce9e93b4a90f02c4a902.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP5-SD | 高斯",
                "min": 0,
                "max": 1,
                "target_box": "set_community_22",
                "itemid": 633,
                "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
                "len": 3,
                "box_value": "set_community_22",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Gauss"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ad79e2d85cd7c6640c04200bd90713632f524e263f5fa7dc8aa1335f73fdf322.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 轻轨",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_22",
                "itemid": 634,
                "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
                "len": 3,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Light Rail"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/34c998044fcec7217a0ca623b283d777cde14da3b311635bac4ad53b13715740.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 青竹伪装",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_22",
                "itemid": 635,
                "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
                "len": 3,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Bamboozle"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7c01be97d574ac68f145566ae1cd1f71fc8d0e6300f563c5c666bf879eee673b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 树蝰",
                "min": 0,
                "max": 1,
                "target_box": "set_community_22",
                "itemid": 636,
                "list": "\nXM1014 | 焚烬之鳄\nAUG | 动量\nR8 左轮手枪 | 头骨粉碎者",
                "len": 3,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Atheris"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/54bdb955f3828c7735034cc2a9f4519919e5265d2b041d45e1ad3ec8f8e02ded.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 异星世界",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_22",
                "itemid": 637,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Off World"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/845bc80231ddadc2f37de27aa91cb69ecf1cde86f004fafd3622c7a82f7afe3f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 铜绿",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_22",
                "itemid": 638,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Verdigris"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fdb2d1e25f4bd5d0bbf0ba2726790b111bab563c681f1a85e32fe1f6b09d4e9b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 恶作剧",
                "min": 0.1,
                "max": 1,
                "target_box": "set_community_22",
                "itemid": 639,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Mischief"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c00704f8dbcce76928b70d6b82fcd24434ec4ee8e7374189d9665eaed0d6843f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 战吼斑纹",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_22",
                "itemid": 640,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Akoben"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3e197441c78cbe288d480327d08836fbb4a33be67066d522968210b693b6964d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 白鲑鱼",
                "min": 0,
                "max": 1,
                "target_box": "set_community_22",
                "itemid": 641,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Whitefish"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/81757487ecf20d8d10aa18a25bf6e0564bc9d88cc3ead9447ae6afef27729e15.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AK-47 | 迷踪秘境",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_22",
                "itemid": 642,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Uncharted"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e0a62130d9792e78a2004f90c80b423275e11b3717b022c3a168785db7096e20.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 保护色",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_22",
                "itemid": 643,
                "list": "\nUMP-45 | 城里的月光\nMP5-SD | 高斯\n沙漠之鹰 | 轻轨\nTec-9 | 青竹伪装\nAWP | 树蝰",
                "len": 5,
                "box_value": "set_community_22",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Crypsis"
            }
        ]
    },
    {
        "name": "“头号特训”武器箱",
        "en_name": "Danger Zone Case",
        "cover": "https://static.mobi2077.com/market-csgo/fb8f5c70ab6c642163cfc03faa36d51c29969bc73cf4798ee0ac982e22b44a9e.png",
        "type": 2,
        "box_value": "set_community_21",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/79da639a06c07b24a3dc0b1f44e656f15c6136c4244e9a0dff2214de197d67cc.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 黑色魅影",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_21",
                "itemid": 644,
                "list": "",
                "len": 0,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Neo-Noir"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/9f7ea1e78e0b30470a39484dc0ca4dd8862c36166c77a6fa9c57301c1629f1a8.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 二西莫夫",
                "min": 0.05,
                "max": 0.7,
                "target_box": "set_community_21",
                "itemid": 645,
                "list": "",
                "len": 0,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Asiimov"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ed45fa8182dbaae12e27384e8aa5760c16727d1b8e216bf4e63264c98d3e9557.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP5-SD | 磷光体",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_21",
                "itemid": 646,
                "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_21",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp5sd",
                "hash_name": "MP5-SD | Phosphor"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/55aebefe10af77a971696006ff5967d11ad4c2d531ce1faeb9823d0f5c9dfc44.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 机械工业",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_21",
                "itemid": 647,
                "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Mecha Industries"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/dfdb26ef106681173cf45a7663e9fc022eec547e59ee7aceb16087c603202819.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "UMP-45 | 动量",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_21",
                "itemid": 648,
                "list": "\nAWP | 黑色魅影\nAK-47 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_21",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Momentum"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c967c6fdab56eb5757227d02d7b10823ecd88fbfcde18c0d408f4425ae3a191f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 闪回",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_21",
                "itemid": 649,
                "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
                "len": 3,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Flashback"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4c4736bcecf6fd2e835082e387c21616855392d12b4d09a9ad3261357a9570ad.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 影魔",
                "target_box": "set_community_21",
                "itemid": 650,
                "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
                "len": 3,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "min": 0,
                "max": 0.4,
                "hash_name": "P250 | Nevermore"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d070b34489cf90ec1e292414d9bb547ac350970e32bf80b6573261ab8aa1dbe9.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 销声",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_21",
                "itemid": 651,
                "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
                "len": 3,
                "box_value": "set_community_21",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Pipe Down"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/dcd9971da95044f6e10a3791ad50691acba86cca2cb9c3a4375f6e2b1f2a7a7a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 信号灯",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_21",
                "itemid": 652,
                "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
                "len": 3,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Signal"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/66c51a344c83fc8d7c4586b5d67700a05adef9466094e79a8b6ae7e1279eba43.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "G3SG1 | 净化者",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_21",
                "itemid": 653,
                "list": "\nMP5-SD | 磷光体\n沙漠之鹰 | 机械工业\nUMP-45 | 动量",
                "len": 3,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Scavenger"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3438257d1211f9aa12480cc0df1f80da608614a55b95d4326f21809aaacc3d88.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 破铜烂铁",
                "min": 0.14,
                "max": 1,
                "target_box": "set_community_21",
                "itemid": 654,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Fubar"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0f8f2537e458924aeb4ec59488b6b5aeb9103f01959a580640bcbd715772fe6f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 危险距离",
                "min": 0.02,
                "max": 0.8,
                "target_box": "set_community_21",
                "itemid": 655,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Danger Close"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6aaafe869d4823ccfc7882f42664e3eb539cde7a64fb6ee54f9110b008ab643c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 黑砂",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_21",
                "itemid": 656,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Black Sand"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fe34934748d4e3b4a43559717857d8abc7e310c8bcb20ee39cacda66273c4857.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 镁元素",
                "min": 0,
                "max": 1,
                "target_box": "set_community_21",
                "itemid": 657,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Magnesium"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4d08c0c431286018b39407121338d75af2a3023492c1290dff4228588b425bfb.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 灼木",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_21",
                "itemid": 658,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Wood Fired"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6d5856c1e8ae5475c0c91245984a0b25d1e1cd7149b727dd218b7654937b4da5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 锈蚀烈焰",
                "min": 0,
                "max": 0.85,
                "target_box": "set_community_21",
                "itemid": 659,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Oxide Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/30f685b06b69dc00d4fc7ef4b2d378141bac582eb35c2f046fc154e781cc849e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 中度威胁",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_21",
                "itemid": 660,
                "list": "\nUSP 消音版 | 闪回\nP250 | 影魔\nMAC-10 | 销声\n加利尔 AR | 信号灯\nG3SG1 | 净化者",
                "len": 5,
                "box_value": "set_community_21",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Modest Threat"
            }
        ]
    },
    {
        "name": "地平线武器箱",
        "en_name": "Horizon Case",
        "cover": "https://static.mobi2077.com/market-csgo/6447981851a99626e6ba9748b4894fa6523d8a6327915476bbf41e437e2ae87d.png",
        "type": 2,
        "box_value": "set_community_20",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/fab4e2a987f3a69db4e8d3116761e1ca1823a26511ee709d025d2fd713dc4146.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "沙漠之鹰 | 红色代号",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 661,
                "list": "",
                "len": 0,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Code Red"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/d6be8b51de096c041764903c3dc55374c005dc85df274df992201ca742992202.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 霓虹骑士",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_20",
                "itemid": 662,
                "list": "",
                "len": 0,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Neon Rider"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/9fb1c80b5aa9bdea7473026dd248fd98baf59824aad8a9ac7c9663274f6350da.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 雅典娜之眼",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_20",
                "itemid": 663,
                "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
                "len": 2,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Eye of Athena"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/331c651e04461f72668cdc62467c54c667494fb15f92b7d0c0cfffa510bc85bd.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "截短霰弹枪 | 吞噬",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_20",
                "itemid": 664,
                "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
                "len": 2,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Devourer"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b1e83497ec133fc05589e892bbb9147a810fce93e693dbd66043ddb37beaf2b6.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 梦魇",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 665,
                "list": "\n沙漠之鹰 | 红色代号\nAK-47 | 霓虹骑士",
                "len": 2,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Nightmare"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f6623db9f56cccc80e3ecfbe71537d563462f0e6aa27ea56e1584f7de7ec9a39.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 权力之心",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_20",
                "itemid": 666,
                "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
                "len": 3,
                "box_value": "set_community_20",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Powercore"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/04bf96419730e41c4e24f0eddd73835356c8c05f9de40af03e82e31fa6fb534b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 猫猫狗狗",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_20",
                "itemid": 667,
                "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
                "len": 3,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | PAW"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/cd72bad9dbc12b375d0a32d66110bda837eb530c1299f0a71889b09d404ed75b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 玩具士兵",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 668,
                "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
                "len": 3,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Toy Soldier"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/04fb5494d7c411cb2a60735485bf24edf2c03583dd2233db9ffeb7c4677b0898.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "G3SG1 | 公海",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_20",
                "itemid": 669,
                "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
                "len": 3,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | High Seas"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6e2ca5d868e16dd6266d981dcc98d9a378848a14a49bfe3c4c988d2890bb1c0c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 经济",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 670,
                "list": "\n法玛斯 | 雅典娜之眼\n截短霰弹枪 | 吞噬\nM4A1 消音型 | 梦魇",
                "len": 3,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Eco"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0a0a49cd9d93f6e93b92368d5e1ff92403670aa86733bc516f10cb1343e20288.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 蛇-9",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 671,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Snek-9"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/797f4b2e68809866c9dbaa3ebc03e499aadd5c4c1e455cfcb18f63494388df49.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 生存主义者",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_20",
                "itemid": 672,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Survivalist"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1b8d56dd76afae99fd243b017235f16e8dbe110ff29260e71b984efae9c752ee.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 牵引力",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 673,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Traction"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/435423a8be62ca4d56571453d711edf7f70974b8b2cfad4f0456f601e0c38697.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 毛细血管",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_20",
                "itemid": 674,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Capillary"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/4803946d38576006dd667940749d767493fa253c17f28a3c36fa306e9aaca640.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 战鹰",
                "min": 0,
                "max": 1,
                "target_box": "set_community_20",
                "itemid": 675,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Warhawk"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b6070941372847145fd38402e39672fd5c97477da368d2aa9d4edc832c03a626.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 碎片",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_20",
                "itemid": 676,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Shred"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/712772dcfb742b23e7617017b686dcf7dea364543011cff6c3d59b5630e02e17.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 琥珀冲流",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_20",
                "itemid": 677,
                "list": "\nMP7 | 权力之心\nAWP | 猫猫狗狗\n新星 | 玩具士兵\nG3SG1 | 公海\nCZ75 | 经济",
                "len": 5,
                "box_value": "set_community_20",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Amber Slipstream"
            }
        ]
    },
    {
        "name": "命悬—线武器箱",
        "en_name": "Clutch Case",
        "cover": "https://static.mobi2077.com/market-csgo/99ccb8338e8689ea690056713f5f27a9e28d62df1c93f276edbc69b2c6391877.png",
        "type": 2,
        "box_value": "set_community_19",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/0200622ffc9802ab51d263e351e7f3c749b0315d082659166f64e7ffdb76cf9b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "MP7 | 血腥运动",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_19",
                "itemid": 678,
                "list": "",
                "len": 0,
                "box_value": "set_community_19",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Bloodsport"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/5662391c2c7f01ff12966835044ee643ee69d6fe47863eff8b8f96a5e8e5e4b6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 黑色魅影",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_19",
                "itemid": 679,
                "list": "",
                "len": 0,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Neo-Noir"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/7f125c482a22708a86ad054f188590e20bf67a19566131456519d38323797e52.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 脑洞大开",
                "min": 0,
                "max": 1,
                "target_box": "set_community_19",
                "itemid": 680,
                "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
                "len": 2,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Cortex"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/290dbdfe56fb435f5887f637b52671e3dc95c7806222b6d95846e56243f2f900.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 死神",
                "min": 0,
                "max": 0.64,
                "target_box": "set_community_19",
                "itemid": 681,
                "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
                "len": 2,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Mortis"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/862661a6597f8be22be9556c65e0d82c50ff2493f0a9f970d8c583bc745029b2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 湖怪鸟",
                "min": 0,
                "max": 0.63,
                "target_box": "set_community_19",
                "itemid": 682,
                "list": "\nMP7 | 血腥运动\nM4A4 | 黑色魅影",
                "len": 2,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Stymphalian"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/827b357133f13d1b4cceaec0506072f1f0b0304d077c8deeec6065736b58f632.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 白狼",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_19",
                "itemid": 683,
                "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
                "len": 3,
                "box_value": "set_community_19",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Arctic Wolf"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/cd9fda6b798ee30bf47cd40db7e6082d96cde07e514377852ed4ca81886310af.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | SWAG-7",
                "min": 0,
                "max": 0.92,
                "target_box": "set_community_19",
                "itemid": 684,
                "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
                "len": 3,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | SWAG-7"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/37c7d730cbd57e3a06a86956693fcb6bf55a935329e73cb6c5b86aea50d69038.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 狂野六号",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_19",
                "itemid": 685,
                "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
                "len": 3,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Wild Six"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3ca8e362373e17f0c6fbecb26445cfdb78557914cc5771e295ec5d9de06daddb.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "内格夫 | 狮子鱼",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_19",
                "itemid": 686,
                "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
                "len": 3,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Lionfish"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2a8b60f70e563198436453796191f123c49c7550e3b06ba221e5fbd3a3fa524d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 城里的月光",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_19",
                "itemid": 687,
                "list": "\nUSP 消音版 | 脑洞大开\nAWP | 死神\nAUG | 湖怪鸟",
                "len": 3,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Moonrise"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5611cfba300e7ae9775fcb901f54eb0512b458ca71a8f6fd5fcbb02a4ceedbb4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 锈蚀烈焰",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_19",
                "itemid": 688,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Oxide Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/68a76ede2523431eb47bb5bdd3710260fc248f8636bb488fb3b82f4eb6772eb8.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 阿罗哈",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_19",
                "itemid": 689,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Aloha"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/181b8305222ab45d83f099c9354f665f9b61182a75b656b81a457523a99462d2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 稳",
                "min": 0,
                "max": 1,
                "target_box": "set_community_19",
                "itemid": 690,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Grip"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a62ad4ab8ddac952ddda7ae52465740f9b567b0f8e60adbab4b8eb3bd367736e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 都市危机",
                "min": 0,
                "max": 1,
                "target_box": "set_community_19",
                "itemid": 691,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Urban Hazard"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/72ed969c428ee69a167bb666a7df88dc808cd085a2a8ad3ffcd4767f36cc8fb4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 黑砂",
                "min": 0,
                "max": 1,
                "target_box": "set_community_19",
                "itemid": 692,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Black Sand"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/79001b0edfc4482e2584f0394a982d0eddd8fe72fe165731522c0b7b0b2f0b27.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 焰色反应",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_19",
                "itemid": 693,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Flame Test"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ab891ac966985c8fb86ec007c4a979d73efbd29a9c7f3775623849bd67f70af4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 黑夜暴乱",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_19",
                "itemid": 694,
                "list": "\nUMP-45 | 白狼\nMAG-7 | SWAG-7\n新星 | 狂野六号\n内格夫 | 狮子鱼\n格洛克 18 型 | 城里的月光",
                "len": 5,
                "box_value": "set_community_19",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Night Riot"
            }
        ]
    },
    {
        "name": "光谱 2 号武器箱",
        "en_name": "Spectrum 2 Case",
        "cover": "https://static.mobi2077.com/market-csgo/5bf19ad2e3e5d95cd3c8071f29fb9079b5183ce3c198a7b3a35511705396eb08.png",
        "type": 2,
        "box_value": "set_community_18",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e165228eb24647e27491598a13b1c004c21ece92bcc36ad7047e7b3f2ede6a2b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "P250 | 生化短吻鳄",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_18",
                "itemid": 695,
                "list": "",
                "len": 0,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | See Ya Later"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e0493efc502129ce099509adf18fa964b62e365ab93148dbdc4f3c7069b7ab9b.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 皇后",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 696,
                "list": "",
                "len": 0,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | The Empress"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a9a2b58aa6000fec9ef6c74784301c21d62fa85fb225aaa7c18cec4db76553a7.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "R8 左轮手枪 | 美洲驼炮",
                "min": 0.03,
                "max": 0.7,
                "target_box": "set_community_18",
                "itemid": 697,
                "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
                "len": 2,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Llama Cannon"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/40c1aef1a26a6eedc6035fe0161997a1a734aec2600afe35f80fc9c9fb047364.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 破碎铅秋",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_18",
                "itemid": 698,
                "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
                "len": 2,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Leaded Glass"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/449e06e681db4caaeded811be3a09cd6b27925ee2e813ed9ea01cb84dde24b77.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "PP-野牛 | 买定离手",
                "target_box": "set_community_18",
                "itemid": 699,
                "list": "\nP250 | 生化短吻鳄\nAK-47 | 皇后",
                "len": 2,
                "box_value": "set_community_18",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "min": 0,
                "max": 1,
                "hash_name": "PP-Bizon | High Roller"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c5078c7a9143df7c478190b857856745c08f8af3f4accab18188c266f4ae21db.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 五彩斑驳",
                "min": 0,
                "max": 0.72,
                "target_box": "set_community_18",
                "itemid": 700,
                "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
                "len": 3,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Ziggy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/75de342710a31ca3c93b854cd6ab0af8260c25e957a8ffaf01a3311f9a8eaa33.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 曝光",
                "target_box": "set_community_18",
                "itemid": 701,
                "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
                "len": 3,
                "box_value": "set_community_18",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "min": 0,
                "max": 0.55,
                "hash_name": "UMP-45 | Exposure"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8c07fec3749b9313854d074eb0c455f3664da5bfb67f4d17615ce4f94a5e8dc8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 战术高手",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_18",
                "itemid": 702,
                "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
                "len": 3,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Tacticat"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/387075f4bda7ddda2747e2772912aa5726bc79d51319e1d639dcb6a1f7d4825c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 幻影",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_18",
                "itemid": 703,
                "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
                "len": 3,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Phantom"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/da4340cc84c1db94d3e49a7ff44d18d4e23abc4cc4e796f7d94fdc62476ceb7b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 焦油缠绕",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_18",
                "itemid": 704,
                "list": "\nR8 左轮手枪 | 美洲驼炮\nM4A1 消音型 | 破碎铅秋\nPP-野牛 | 买定离手",
                "len": 3,
                "box_value": "set_community_18",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Goo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b3a6d90caafeb5bc6bbc688e3ee4953fa430bad56b87dc1b6d8e71d5772b03ce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 丛林滑流",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_18",
                "itemid": 705,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Jungle Slipstream"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a58abda1b532f0f2057ec609dcbcec151c1dac4e68e4b835349963d85e17ba51.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 碎蛋白石",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 706,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Cracked Opal"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e87e11465e85c352f338081e0147379ddad9a0d374989aa1ac51572c052a70c7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 海洋",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_18",
                "itemid": 707,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Oceanic"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/df640261dd930db7af16f870c2003ea19c6f930791e18f8d484921ee13a020be.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 异星世界",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 708,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Off World"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/bc8a1bf8caa18d942b337d4dd08f498eedb880c98b3ab9db3b484ea7142bb28d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 猎人",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 709,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Hunter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/941437c6239905417375b28f0d479d6338f7633f7595f28144538bceb1031cb6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 三角战术",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 710,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Triqua"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7e5880adf04898d176552d032a76788abc0e065ebddfb51bfb48355d69787455.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 夜百合",
                "min": 0,
                "max": 1,
                "target_box": "set_community_18",
                "itemid": 711,
                "list": "\nXM1014 | 五彩斑驳\nUMP-45 | 曝光\nCZ75 | 战术高手\nSG 553 | 幻影\nMP9 | 焦油缠绕",
                "len": 5,
                "box_value": "set_community_18",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Morris"
            }
        ]
    },
    {
        "name": "“九头蛇大行动”武器箱",
        "en_name": "Operation Hydra Case",
        "cover": "https://static.mobi2077.com/market-csgo/99bb0f9d8712c03be2ef5f17157cfd2603eb3adc2b7ab3f479733adfc821b3af.png",
        "type": 2,
        "box_value": "set_community_17",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/d19c4b36c8e4b5ea20bbf567e5dd5e357ee99d969e7ae334d75b37e063e0abc6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 鬼退治",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_17",
                "itemid": 712,
                "list": "",
                "len": 0,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Oni Taiji"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/ee707b5572a2c01171434785ba4dad7a7adf9cafcb095c4c26c50d5ac5af61fe.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "FN57 | 暴怒野兽",
                "min": 0,
                "max": 1,
                "target_box": "set_community_17",
                "itemid": 713,
                "list": "",
                "len": 0,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Hyper Beast"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/27dcd364b81667ac38410b5e96860c3dceb223bd763f2c48cf21c83601e41ff0.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 地狱烈焰",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_17",
                "itemid": 714,
                "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
                "len": 2,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Hellfire"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/828a24af8e66ba81f2c2d0efcdec562e810628b7918ec9d7ce5fc607cb5ac4d5.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "加利尔 AR | ~甜甜的~",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_17",
                "itemid": 715,
                "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
                "len": 2,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Sugar Rush"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3d75ab327e3d624c165edc3f317fedf2f6354c7d0b38632ecf5f4b5d30007777.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "双持贝瑞塔 | 毒蛇袭击",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_17",
                "itemid": 716,
                "list": "\nAWP | 鬼退治\nFN57 | 暴怒野兽",
                "len": 2,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Cobra Strike"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ec42f4064f75e77fe29945f516c1333a6f4521d1e949e0a96c80b9db15ff423b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SSG 08 | 鬼脸天蛾",
                "min": 0,
                "max": 0.51,
                "target_box": "set_community_17",
                "itemid": 717,
                "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
                "len": 3,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Death's Head"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2d9cefd1c5a2078b2abf6efd604aad1f4bf8ac9ac9ec800091521a3e7862ec29.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 死亡之握",
                "min": 0,
                "max": 1,
                "target_box": "set_community_17",
                "itemid": 718,
                "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
                "len": 3,
                "box_value": "set_community_17",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Death Grip"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ab30f751e666e3efb14d18dabf07dbff3cf4ccf39ae4dbdb256e45a01065ff6d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 红岩",
                "min": 0,
                "max": 1,
                "target_box": "set_community_17",
                "itemid": 719,
                "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
                "len": 3,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Red Rock"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2043d8e469038b00e0075fbb7222dd0b0cf6602a5f059dbe548730ce4d43e9d8.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 林间猎者",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_17",
                "itemid": 720,
                "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
                "len": 3,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Woodsman"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0531deac492ecc2e2dd79fd74bdfc15eb9c292f55435e80fdec54aafa7615a39.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 轨道 Mk01",
                "target_box": "set_community_17",
                "itemid": 721,
                "list": "\nM4A4 | 地狱烈焰\n加利尔 AR | ~甜甜的~\n双持贝瑞塔 | 毒蛇袭击",
                "len": 3,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "min": 0,
                "max": 0.55,
                "hash_name": "AK-47 | Orbit Mk01"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f00fb5232f8aa8a47724065dc2c373ad2eff8edb95af47ccf5c94a3fbc6b1af4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 合金绽放",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_17",
                "itemid": 722,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Metal Flowers"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/545637ab2aa09ce653a6fb43812268fa7a7217ee3570a920cb45f3b3a5624382.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 剪纸",
                "min": 0,
                "max": 1,
                "target_box": "set_community_17",
                "itemid": 723,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Cut Out"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0e3a96414ee8f22d09df44b46cdf16e5043a65bd08c436b9503f6a24eafe8b39.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 硬水",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_17",
                "itemid": 724,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Hard Water"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b81d71903d4e2cdba980459382543e0f8335a9fa17f93d6fe3807ddae2190027.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 阿罗哈",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_17",
                "itemid": 725,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Aloha"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/26743424d30aa41a58c323b24f5d075567e2004efbd9f70354bf6da63b5ddb5e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A1 消音型 | 简报",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_17",
                "itemid": 726,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Briefing"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/67c482335085552d145c784d9d9dd30cb3d497f85c2270021ccc90e63606af4b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 死亡之舞",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_17",
                "itemid": 727,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Macabre"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e6dbe6b9f598e4d1d1edc50b186e711cdf4c1e97df9e330cd52682c386ea7c2a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 蓝图",
                "min": 0,
                "max": 0.86,
                "target_box": "set_community_17",
                "itemid": 728,
                "list": "\nSSG 08 | 鬼脸天蛾\nP90 | 死亡之握\nP250 | 红岩\nP2000 | 林间猎者\nAK-47 | 轨道 Mk01",
                "len": 5,
                "box_value": "set_community_17",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Blueprint"
            }
        ]
    },
    {
        "name": "光谱武器箱",
        "en_name": "Spectrum Case",
        "cover": "https://static.mobi2077.com/market-csgo/175ddf804f9bed72fc1139c737d4371cfa542a052da0ac16cabcc6f98a34d8ce.png",
        "type": 2,
        "box_value": "set_community_16",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/deb7ad5853ca0bb6a882502044625db1dc7878e5a8ba5672804e2019c1332e8c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "USP 消音版 | 黑色魅影",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_16",
                "itemid": 729,
                "list": "",
                "len": 0,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Neo-Noir"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/77d7098db3ad25e59c52f48248449abfe95dd868933c6b7aae9da899c4cb7794.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 血腥运动",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_16",
                "itemid": 730,
                "list": "",
                "len": 0,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Bloodsport"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/747d1626179e419e958460e4597b81f287cdc5da81188cc35545437171f84f3a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 毁灭者 2000",
                "target_box": "set_community_16",
                "itemid": 731,
                "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
                "len": 2,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "min": 0,
                "max": 0.85,
                "hash_name": "M4A1-S | Decimator"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f05824308f88d454b0585068ab9fb8276a392af99d15c57733c2af82e40cb7b5.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "CZ75 | 相柳",
                "min": 0,
                "max": 0.56,
                "target_box": "set_community_16",
                "itemid": 732,
                "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
                "len": 2,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Xiangliu"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/9495e2cdcbe4adacb908843fcc49f1163d6aaa2a641dadb3e58b884da30c0b1d.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 浮生如梦",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_16",
                "itemid": 733,
                "list": "\nUSP 消音版 | 黑色魅影\nAK-47 | 血腥运动",
                "len": 2,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Fever Dream"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/bd4a5ead5bf10ad0652300332d3bc4a19a6215a13207b032816058e457041f06.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 四季",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_16",
                "itemid": 734,
                "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
                "len": 3,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Seasons"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/32fe9c2ea755a8e765dd81e482d424f277ce2965ff76ae628db4e7c4eadf5f25.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 支架",
                "min": 0,
                "max": 1,
                "target_box": "set_community_16",
                "itemid": 735,
                "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
                "len": 3,
                "box_value": "set_community_16",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Scaffold"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f49a644a5ae784f8aa4c3b7593c5a88ccd1a4237b972aeed47d844936ba20cec.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 绝界之行",
                "min": 0,
                "max": 0.52,
                "target_box": "set_community_16",
                "itemid": 736,
                "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
                "len": 3,
                "box_value": "set_community_16",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Last Dive"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2e05b5f9171a863e38fbadc9a6b60a01f581e176e53ad9bf64e48b463dbc0052.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M249 | 翠绿箭毒蛙",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_16",
                "itemid": 737,
                "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
                "len": 3,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Emerald Poison Dart"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ea593fc434818bdee9f9f4dd3e80cea81079ba75ff7999399e5faedaf997ea3e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 深红海啸",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_16",
                "itemid": 738,
                "list": "\nM4A1 消音型 | 毁灭者 2000\nCZ75 | 相柳\nAWP | 浮生如梦",
                "len": 3,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Crimson Tsunami"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6deaa8a3c14280db127ec7caa3a9fa765fc8fd9bf66c20675fb665aabb6c697f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 梭鲈",
                "min": 0.05,
                "max": 1,
                "target_box": "set_community_16",
                "itemid": 739,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Zander"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fd1751dbf1c71d9d5cefe29792d0fbc843bdae801fd744e642d6c325397d4821.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 涟漪",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_16",
                "itemid": 740,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Ripple"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cdd67878e71e2e3110e49773fe1dca5a77e00eb5930c3ef2bdc5428090802492.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 非洲部落",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_16",
                "itemid": 741,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Akoben"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d27f35860d28bc8d22a50c793a97107749964c612da360e2493ab2221bfb882d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 毛细血管",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_16",
                "itemid": 742,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Capillary"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/de3858f11a616c09d6cf0e3c28da30b1b73308645a91a9f6b475c1f413ad4e2a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 锈蚀烈焰",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_16",
                "itemid": 743,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Oxide Blaze"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ae0dbba7faaf4baab9251e12d15af369ae747b7ce6060f3d7b575285e452979b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 蓝图",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_16",
                "itemid": 744,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Blueprint"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/30bb1cbe9a27933d9c97c08d0e53721d935b4f4e0443f7c3ea38a3e390606566.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 丛林滑流",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_16",
                "itemid": 745,
                "list": "\nXM1014 | 四季\nUMP-45 | 支架\nMAC-10 | 绝界之行\nM249 | 翠绿箭毒蛙\n加利尔 AR | 深红海啸",
                "len": 5,
                "box_value": "set_community_16",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Jungle Slipstream"
            }
        ]
    },
    {
        "name": "手套武器箱",
        "en_name": "Glove Case",
        "cover": "https://static.mobi2077.com/market-csgo/de84be084b2b5ed3d0cb5f77ef58d671638cd938a98b512f7799826cc2ee0bbf.png",
        "type": 2,
        "box_value": "set_community_15",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/12150612f195bb8385394cdca52d74e0afc5271534d1f87c470a95db5105872a.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 喧嚣杀戮",
                "target_box": "set_community_15",
                "itemid": 746,
                "list": "",
                "len": 0,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "min": 0,
                "max": 0.7,
                "hash_name": "M4A4 | Buzz Kill"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/7738daf958c3ae0afd04e10bb012af060f769c09fb4fa2a82ba92dcc49d78ffd.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "SSG 08 | 炎龙之焰",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_15",
                "itemid": 747,
                "list": "",
                "len": 0,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Dragonfire"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/bea59c15ca3a39b6d5273c06ff8e298579aeb0fa7102a7474517b2f316a394c0.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "截短霰弹枪 | 荒野公主",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_15",
                "itemid": 748,
                "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
                "len": 2,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Wasteland Princess"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f970e280488e606afe0e08e05bc2837cd392ec9856e15b48f46c03cad5e05146.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 浅坟",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_15",
                "itemid": 749,
                "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
                "len": 2,
                "box_value": "set_community_15",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Shallow Grave"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b3a468484f0f08e1f3d18071e5cdf9b4724a2fd8b2bf9d2aa6ba89445484d82a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 机械工业",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_15",
                "itemid": 750,
                "list": "\nM4A4 | 喧嚣杀戮\nSSG 08 | 炎龙之焰",
                "len": 2,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Mecha Industries"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9f0534e2e7be174f04b34f765b91a8d698b0e79a76fca969579800af41105645.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 次时代",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_15",
                "itemid": 751,
                "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
                "len": 3,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Cyrex"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/fb29e3a48eea3ad46b244acbae6188eef8a1a2a6bd4f75bcbdb3339b18fc0ed4.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 毒蜥",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_15",
                "itemid": 752,
                "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
                "len": 3,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Gila"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8b69fa3127cb3e3377ad6e26b3de2f2f121a12099975279f3233cfc43a85d716.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 闪回",
                "min": 0,
                "max": 1,
                "target_box": "set_community_15",
                "itemid": 753,
                "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
                "len": 3,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Flashback"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8c19ff19e4598b28f06db7c8989f12fe85b739847ab6ce0434b8782fb850ec73.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "G3SG1 | 毒刺",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_15",
                "itemid": 754,
                "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
                "len": 3,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Stinger"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/00c287a9b349cc77b575532e85fd81b68b5314dafc3d226e0d2c6499368a8d42.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 皇室伴侣",
                "min": 0,
                "max": 1,
                "target_box": "set_community_15",
                "itemid": 755,
                "list": "\n截短霰弹枪 | 荒野公主\nP90 | 浅坟\n法玛斯 | 机械工业",
                "len": 3,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Royal Consorts"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c8da836d7e8ef8ac7c24baeac57bfbfe9a1ee04e3af80d08f19cb2de12cc7d78.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 草皮",
                "min": 0,
                "max": 1,
                "target_box": "set_community_15",
                "itemid": 756,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Turf"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/76124a7d73bf07d7173009de927ac3d4533b2b5dd32630692ec12459c948939f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 声纳",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_15",
                "itemid": 757,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Sonar"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c6b72ce737b450e380fddaea61f4e94adab6215743e19f0fdd1433d6392edfde.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 砂垢",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_15",
                "itemid": 758,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Sand Scale"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7df876d17f47291a655619faaf8392e0daa37798d7c54a4310a2852758bbd8a8.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 黑砂",
                "min": 0,
                "max": 1,
                "target_box": "set_community_15",
                "itemid": 759,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Black Sand"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/078057d8ed1d9111ddf8646a1437acf2c3547f773a781f073bb0599ca72eb1e5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 卷云",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_15",
                "itemid": 760,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Cirrus"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/59f49d7b314c531565259ccbfe1303a1ae8a736cfab0a242bcacf4dac71b17c7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 铁之作",
                "target_box": "set_community_15",
                "itemid": 761,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "min": 0,
                "max": 1,
                "hash_name": "Glock-18 | Ironwork"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1969f0d647f1fefa637faa238129724fff94cdcaf1c2b92a848fed31aed2760b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 聚合物",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_15",
                "itemid": 762,
                "list": "\nUSP 消音版 | 次时代\n新星 | 毒蜥\nM4A1 消音型 | 闪回\nG3SG1 | 毒刺\n双持贝瑞塔 | 皇室伴侣",
                "len": 5,
                "box_value": "set_community_15",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Polymer"
            }
        ]
    },
    {
        "name": "伽玛 2 号武器箱",
        "en_name": "Gamma 2 Case",
        "cover": "https://static.mobi2077.com/market-csgo/dd065c33f7f80e3fcc983c1cba81edad354d97a6ddc1a92d7c39f1fb42e859e8.png",
        "type": 2,
        "box_value": "set_gamma_2",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/1ad5e51e3cc2bcfe3f2b0ed9882515f4ff575c25025cf7aba9d5e03aba683e99.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "法玛斯 | 防滚架",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 763,
                "list": "",
                "len": 0,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Roll Cage"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/736517ccf145a08705f2115eb184afb3a051e83b54b082f40275239d98a4b8b2.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 霓虹革命",
                "min": 0,
                "max": 0.8,
                "target_box": "set_gamma_2",
                "itemid": 764,
                "list": "",
                "len": 0,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Neon Revolution"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/9c4b6217388036967513a2c71ef3b7d2554f32ad0cb8191b764eac9ec1c5b364.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "Tec-9 | 燃料喷射器",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 765,
                "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
                "len": 2,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Fuel Injector"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5f174646f43b6b32187a3ec6b7e8ef228f75f48640b6238d21d45da2b0e4ab1f.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP9 | 气密",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 766,
                "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
                "len": 2,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Airlock"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5a8b78b79a0cf36a34a945cb516561b18c2bf7912b7f0b173f0d36e0e8e0d0d9.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 席德.米德",
                "min": 0,
                "max": 0.8,
                "target_box": "set_gamma_2",
                "itemid": 767,
                "list": "\n法玛斯 | 防滚架\nAK-47 | 霓虹革命",
                "len": 2,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Syd Mead"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/e82fd6399e8459dd82ed96a83f19f1b0238d2c71cdd65efd58155c584a44c251.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 三巨头",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 768,
                "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
                "len": 3,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Triarch"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/337777902c6c2617b6230f22c3fed1cab74b04bf0a7f95be23fd2b8a1cafbbdc.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SCAR-20 | 权力之心",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 769,
                "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
                "len": 3,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Powercore"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/bfc2e250b956753513dfbe8abbcf2bddecadc10600eabfefeadfddfa770cccff.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 石雕",
                "min": 0,
                "max": 0.44,
                "target_box": "set_gamma_2",
                "itemid": 770,
                "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
                "len": 3,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Petroglyph"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/045816b0004d8735939d1b21beab1e1dfd70af3a8227a9d6ddd16348ab29f69c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 鼬鼠",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 771,
                "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
                "len": 3,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Weasel"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/35e79b65c9e95674d0da36fa1e67e11e739e59ada1e64fd0d502f502711aa0cf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 指挥",
                "min": 0.06,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 772,
                "list": "\nTec-9 | 燃料喷射器\nMP9 | 气密\nAUG | 席德.米德",
                "len": 3,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Directive"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8a08a4dcdf41cf0bf796914be8a6f2f8e066bbc852bb9c55d77d5eaadb0ade48.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 滑流",
                "min": 0,
                "max": 0.5,
                "target_box": "set_gamma_2",
                "itemid": 773,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Slipstream"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/86944186122afb7041a9cd460d52a3eb637e43a27124c6daab4dfecc9cb36c98.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 简报",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 774,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Briefing"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/506adaa21774ef0ca98733950f701f2bfbd8ab323bcafb1c1c50c0a6ef6ad15a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 冷血无情",
                "min": 0,
                "max": 0.8,
                "target_box": "set_gamma_2",
                "itemid": 775,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Grim"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/53f68919454b2374448f30f98c1699e46c63fdda7a8fed859f902d9186c7f739.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 眩目",
                "min": 0.1,
                "max": 0.65,
                "target_box": "set_gamma_2",
                "itemid": 776,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Dazzle"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3d040679cbb8ffe0400fdf23754615a161b8594633f8794f854464797c4fc4e4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 通风机",
                "min": 0,
                "max": 0.45,
                "target_box": "set_gamma_2",
                "itemid": 777,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Ventilator"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d1c5c9a6cb07ae56902712cdc34816de09a954c2af6378f8e73bf8bd0fbea381.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 斯康里娅",
                "target_box": "set_gamma_2",
                "itemid": 778,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "min": 0,
                "max": 1,
                "hash_name": "Five-SeveN | Scumbria"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/94343a51e7c5caaac82a00c97797e7c6da4fd42afc8f4a9701ee4afd35174508.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 印花板",
                "min": 0,
                "max": 1,
                "target_box": "set_gamma_2",
                "itemid": 779,
                "list": "\nSG 553 | 三巨头\nSCAR-20 | 权力之心\nMAG-7 | 石雕\n格洛克 18 型 | 鼬鼠\n沙漠之鹰 | 指挥",
                "len": 5,
                "box_value": "set_gamma_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Imprint"
            }
        ]
    },
    {
        "name": "伽玛武器箱",
        "en_name": "Gamma Case",
        "cover": "https://static.mobi2077.com/market-csgo/09dcc2ad1e7f6c383e948e487cd26c16906f562d53dfbe271dad50ff3dff37a8.png",
        "type": 2,
        "box_value": "set_community_13",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/e559c5b9f04f7fa8b1952a0d1c068ccf8648ae68fc9218bf29b06c69ee93bbab.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 机械工业",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_13",
                "itemid": 780,
                "list": "",
                "len": 0,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Mecha Industries"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/69904cb5810b5c10cdfec0906259bfb29690095095079daedc62eaece1aa02f4.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "格洛克 18 型 | 荒野反叛",
                "min": 0,
                "max": 0.54,
                "target_box": "set_community_13",
                "itemid": 781,
                "list": "",
                "len": 0,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Wasteland Rebel"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1402019baf625f812945a51a1bd79b299dc475049c7c0a2f293528fdd0c254ec.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SCAR-20 | 血腥运动",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_13",
                "itemid": 782,
                "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Bloodsport"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/2ab1d773950634dceb8b8afcfe8bff4fec6cad3001cade442c206dfbf78f44f0.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P2000 | 至尊威龙",
                "min": 0,
                "max": 0.63,
                "target_box": "set_community_13",
                "itemid": 783,
                "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Imperial Dragon"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3aae525bbdb4d233bba6ddff8c5bab0f53b214f552bff5f2089667a302f89f9a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 死寂空间",
                "min": 0,
                "max": 1,
                "target_box": "set_community_13",
                "itemid": 784,
                "list": "\nM4A1 消音型 | 机械工业\n格洛克 18 型 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Desolate Space"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/edb4643e70cd365a32fad6d43a64bc27c9f2ae1631db0777bd6e1277e915ad94.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "截短霰弹枪 | 聚光灯",
                "min": 0,
                "max": 1,
                "target_box": "set_community_13",
                "itemid": 785,
                "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
                "len": 3,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Limelight"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f0cd7c51fd4716d4fd45ae7b8277599fb42aa213a218c5aef0c8b094ae02f6d1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "R8 左轮手枪 | 重新启动",
                "min": 0,
                "max": 1,
                "target_box": "set_community_13",
                "itemid": 786,
                "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
                "len": 3,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Reboot"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/24e71354e4391b6aa09e33ddac39e964aada3be2758dbdcb0579c887209c4eeb.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 夺命器",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_13",
                "itemid": 787,
                "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
                "len": 3,
                "box_value": "set_community_13",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Chopper"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b66baa1946de2cc94b3fcd84d1aa1391ac2773d6e424ff4d95eed10bacbea130.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 火卫一",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_13",
                "itemid": 788,
                "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
                "len": 3,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Phobos"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4004e324f3185481b0c6eed3e59cb6f48ab146be8109767556f1e07e946181a5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 贵族",
                "min": 0,
                "max": 0.66,
                "target_box": "set_community_13",
                "itemid": 789,
                "list": "\nSCAR-20 | 血腥运动\nP2000 | 至尊威龙\nM4A4 | 死寂空间",
                "len": 3,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Aristocrat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f71f98f9a9e0961b252b87c3dabdc39e3e34c0f3c375bc9bcac1d93aeee02a53.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 冰冠",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_13",
                "itemid": 790,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Ice Cap"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/369d8afad57301a5d93777043815400d1c3eab7b2f5099178f02e029c8043a11.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 轻空",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_13",
                "itemid": 791,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Aerial"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/38b3e11181d14963699319deed46d26ff8a9a06a8f096771854fdb4fe22f56e3.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 收割机",
                "min": 0,
                "max": 1,
                "target_box": "set_community_13",
                "itemid": 792,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Harvester"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/715f07824fd85b5bf2d3b0a73b2eca325841f73949fc8ee62640b8423ca60c41.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 铠甲",
                "min": 0.05,
                "max": 0.8,
                "target_box": "set_community_13",
                "itemid": 793,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Iron Clad"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f4772ed52caa18cb225088c6131c724806e18b46aa5c990755308e8e4c6a6eb3.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | Exo",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_13",
                "itemid": 794,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Exo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/be5bc2741f38def4635624b4bf2c0d76d01156034efa8a4de63aa5bc903077e0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 捕猎者",
                "min": 0,
                "max": 1,
                "target_box": "set_community_13",
                "itemid": 795,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Carnivore"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7c6257e711c54f27890840b74dd2b5d5487cff08d880c3dca6bb8420095bac49.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 霸意大名",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_13",
                "itemid": 796,
                "list": "\n截短霰弹枪 | 聚光灯\nR8 左轮手枪 | 重新启动\nP90 | 夺命器\nAWP | 火卫一\nAUG | 贵族",
                "len": 5,
                "box_value": "set_community_13",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Violent Daimyo"
            }
        ]
    },
    {
        "name": "幻彩 3 号武器箱",
        "en_name": "Chroma 3 Case",
        "cover": "https://static.mobi2077.com/market-csgo/37275707edad1beb9bde627599466ceade2d30f23f4cd3f289b5f9432ece2fad.png",
        "type": 2,
        "box_value": "set_community_12",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/dd7467aa7ca1d4b9960f434503a786113c4c5f14dd5a0676b08577125c88a909.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 女火神之炽焰",
                "min": 0,
                "max": 0.99,
                "target_box": "set_community_12",
                "itemid": 797,
                "list": "",
                "len": 0,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Chantico's Fire"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/edf5cd192003b0ec3778443df97bc6d69162c9d71225fa64f76b1f21417aaa46.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "PP-野牛 | 阿努比斯之审判",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_12",
                "itemid": 798,
                "list": "",
                "len": 0,
                "box_value": "set_community_12",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Judgement of Anubis"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/c3738c4f9b98f22a97f36cb5e9353926c4c65659e20974c142494d8f391ee707.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "UMP-45 | 野蛮剑齿虎",
                "min": 0,
                "max": 0.77,
                "target_box": "set_community_12",
                "itemid": 799,
                "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
                "len": 2,
                "box_value": "set_community_12",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Primal Saber"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/72a320726a98e4ef6f527ad1de75bfdbe44bd3a7dc7ed77daed7007a8149e750.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 二西莫夫",
                "min": 0.1,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 800,
                "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
                "len": 2,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Asiimov"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f9856d5570751e408015d766671b1659aeb3eeaf6266f5d3c46a8d90aee5ddd5.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 燕群",
                "min": 0,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 801,
                "list": "\nM4A1 消音型 | 女火神之炽焰\nPP-野牛 | 阿努比斯之审判",
                "len": 2,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Fleet Flock"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/053eb57ec8beeacf243041fdf8253c02024a37067e342d66871cbaadb959714e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 西装革履",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_12",
                "itemid": 802,
                "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
                "len": 3,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Black Tie"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1dfc1ef5059a81c89039cffc288872df2d044440fa9f3e135beb964858e76586.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 战火重燃",
                "target_box": "set_community_12",
                "itemid": 803,
                "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
                "len": 3,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "min": 0,
                "max": 0.43,
                "hash_name": "Tec-9 | Re-Entry"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/89b4ab9fbdf61544af4e9a8a07621cab8d0b499f79d1dcd8839506427c0122c2.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SSG 08 | 幽灵战士",
                "min": 0,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 804,
                "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
                "len": 3,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Ghost Crusader"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d95b8f261ed54b2af678ba5d5540c43e94452e6ff7e406863372a0e6926e181a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 火线冲锋",
                "min": 0,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 805,
                "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
                "len": 3,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Firefight"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/dec2b3cfc58042ae5073766c2b1cd8f84e6fd036e0cff9fee9b2eb295ff9365f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 红鹰",
                "min": 0,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 806,
                "list": "\nUMP-45 | 野蛮剑齿虎\nP250 | 二西莫夫\nAUG | 燕群",
                "len": 3,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Red Astor"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/42b12103273f8c0f2c7b94ecdd517ba7c3c4c47adafc70328b1f3f744ae19635.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 擎天神",
                "min": 0,
                "max": 0.81,
                "target_box": "set_community_12",
                "itemid": 807,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Atlas"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d9a936cf20d3ec69c70e89955680dcf8ae26d9ca1050578dac4f511851e6afa1.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 破铜烂铁",
                "min": 0.4,
                "max": 1,
                "target_box": "set_community_12",
                "itemid": 808,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Fubar"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a62f381e8311247257552e7a8f0564d5366fbc9589192fa564b3df94729185f2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 海洋",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_12",
                "itemid": 809,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Oceanic"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/49895fbb60dcbe73cc788e582b3d331db4b68cfc54a10506883338ec1450cc8d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 生化泄漏",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_12",
                "itemid": 810,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Bioleak"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f2a86b3c399ab0faa812f5a6f12f5a69a1fb81ed82d10a855211de7ce401a0df.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 鬼影",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_12",
                "itemid": 811,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Spectre"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/95c82e5bd8b2435fcb0a287fb56dd949617d1bdb1efab121a116d1713fc970ac.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 橙光冲击",
                "min": 0,
                "max": 0.52,
                "target_box": "set_community_12",
                "itemid": 812,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Orange Crash"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a1e2f5661953b68e4d597523fd9e18dc4108750bfeff2bd25a9247ce21fa5bb0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 通风机",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_12",
                "itemid": 813,
                "list": "\nXM1014 | 西装革履\nTec-9 | 战火重燃\nSSG 08 | 幽灵战士\n加利尔 AR | 火线冲锋\nCZ75 | 红鹰",
                "len": 5,
                "box_value": "set_community_12",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Ventilators"
            }
        ]
    },
    {
        "name": "“野火大行动”武器箱",
        "en_name": "Operation Wildfire Case",
        "cover": "https://static.mobi2077.com/market-csgo/293f58330204acf1aa16d860edc4005ceaedc6fc9a5597d7402d2b4cd4196cc3.png",
        "type": 2,
        "box_value": "set_community_11",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/eb6105e93ff298b9d47235514773e68ded66688c6d6c41554ae619d56446ee3f.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 战场之星",
                "min": 0,
                "max": 0.64,
                "target_box": "set_community_11",
                "itemid": 814,
                "list": "",
                "len": 0,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | The Battlestar"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/1868afb4e698d11a950a1e250c84a2b51ba7c85d366e102ac0e42356bbd4a194.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 燃料喷射器",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 815,
                "list": "",
                "len": 0,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Fuel Injector"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/4a2a99a9116a5efe2d89d8f0a2dd023870e242abb9b3c32482d8f2f9f7f18479.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "新星 | 暴怒野兽",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_11",
                "itemid": 816,
                "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
                "len": 2,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Hyper Beast"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/2ea1930899d24adf17ff0e090bc4df03088353de60c5ff918aae896dee8b229b.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 大佬龙",
                "min": 0,
                "max": 0.76,
                "target_box": "set_community_11",
                "itemid": 817,
                "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
                "len": 2,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Kumicho Dragon"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/2b10ed284783631da75c98f53e8424380076644837924b71c00e485a18ec84fb.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 精英之作",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 818,
                "list": "\nM4A4 | 战场之星\nAK-47 | 燃料喷射器",
                "len": 2,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Elite Build"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d6af3302796ac2def630bb439be53dd9aa2a7154686f5e1912e8c5e27bec789b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 帝国",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_11",
                "itemid": 819,
                "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
                "len": 3,
                "box_value": "set_community_11",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Impire"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/cb83269ae423a9ca5f0f2f8bdd44c24521881c1d31187c5bfe8572cfc9ab481a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 禁卫军",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_11",
                "itemid": 820,
                "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
                "len": 3,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Praetorian"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a1bc7ed3320cefcb4611d8db1d011b9781c2d49f7dcc39feeecb5feec8723b6a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 皇家军团",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 821,
                "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
                "len": 3,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Royal Legion"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d81b730e1d384bbb7fe4c1989a1f9a66f15afe6fa94cf60ef5d8c7a92fa76da6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 三位一体",
                "target_box": "set_community_11",
                "itemid": 822,
                "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
                "len": 3,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "min": 0,
                "max": 0.61,
                "hash_name": "Five-SeveN | Triumvirate"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/736e42abda962dbe240308a962a43545bd2d96ca5ba12df66e6c15c603754646.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 元素轮廓",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_11",
                "itemid": 823,
                "list": "\n新星 | 暴怒野兽\n沙漠之鹰 | 大佬龙\nAWP | 精英之作",
                "len": 3,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Valence"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8184e1d6461f779277644304be761ac4f19bae107508556a69c31dca994c3326.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 铅管",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 824,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Lead Conduit"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7393af13e50b392363fb61a9ed1d762dbb19f9305cc590cb9f0951b855baa3c5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 贾姆比亚",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 825,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Jambiya"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/96ab25768c9293cd8fc632a04db68459f3161840ae9f617e3713775bfa45fb65.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 通灵者",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 826,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Necropos"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d788aec31e1d59e6d17f91ab1e040e3d18621b6e2fa408670d50bc12804b07af.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 青金鳄皮",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_11",
                "itemid": 827,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Lapis Gator"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e6fb42f8ad51f01777583277d1e0ecbdf5ff2ed2ea96e0d274a12c12eec7dd95.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 卡特尔",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 828,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Cartel"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2125eaa3109a322d1ae0bdf849a2de3216123764a912eeb1a869faf7f2300316.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 透光区",
                "min": 0,
                "max": 1,
                "target_box": "set_community_11",
                "itemid": 829,
                "list": "\nMP7 | 帝国\nMAG-7 | 禁卫军\n格洛克 18 型 | 皇家军团\nFN57 | 三位一体\n法玛斯 | 元素轮廓",
                "len": 5,
                "box_value": "set_community_11",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Photic Zone"
            }
        ]
    },
    {
        "name": "左轮武器箱",
        "en_name": "Revolver Case",
        "cover": "https://static.mobi2077.com/market-csgo/f213fb09b94496c3b3a88e7311691c61b21e296314c1b7099985f25d6ca23aab.png",
        "type": 2,
        "box_value": "set_community_10",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/9cb964057db143d329a6a52d4825df2a41aae8f408d1959e94a69cf31b142f5c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "R8 左轮手枪 | 渐变之色",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_10",
                "itemid": 830,
                "list": "",
                "len": 0,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Fade"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/3a3f7801bd78968fe902c3208f1904e3d32633da14043e3d2de6471f18b88a78.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 皇家圣骑士",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_10",
                "itemid": 831,
                "list": "",
                "len": 0,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Royal Paladin"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/bb5cf94b421e749112d3682e5e2fbac1203e458f996d0ea188d58c5481f4fdd6.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 精雕木刻",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 832,
                "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
                "len": 2,
                "box_value": "set_community_10",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Shapewood"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d70225e1ffb9dd63f79718e8611af2f926c7d97bb3e3dc0d0f5ef72664589017.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "G3SG1 | 行刑者",
                "min": 0.14,
                "max": 0.85,
                "target_box": "set_community_10",
                "itemid": 833,
                "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
                "len": 2,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | The Executioner"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/e7297212c7aee6fac1e85a66d15181010562e2c6b0eba839afedb9ba0a5f9221.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 混沌点阵",
                "min": 0,
                "max": 0.67,
                "target_box": "set_community_10",
                "itemid": 834,
                "list": "\nR8 左轮手枪 | 渐变之色\nM4A4 | 皇家圣骑士",
                "len": 2,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Point Disarray"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a8ccf0a5b30fc037a206122d69fcc8cd533c1c4a65ba00f1f949255e7732085e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 特克卢喷射者",
                "min": 0,
                "max": 0.65,
                "target_box": "set_community_10",
                "itemid": 835,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Teclu Burner"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d7c9de8d63e993c732b93edbce99c61c8030cdf35bda2e8554c8227ac47923d2.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 坍雪寒裘",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 836,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Avalanche"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/06d7fc9d7028543472af61c4c3d88cc92ba4543060097ec0b433dd15eec394a9.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 豹灯蛾",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 837,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Tiger Moth"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/dcc4ff56978e15b6a0a2bc35a995290a0caa9e6621a9503602b6a68c3692fdf2.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "内格夫 | 动力装载机",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 838,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Power Loader"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8a5951999d6c39e36c8a792dc12f33766f3afe7fa40782b2501520e793ff4361.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 朝枪夕拾",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 839,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Retrobution"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f231f8aa217fe4a3dcfd140e76d284b7de73e410f2e5a7e1cff1234f4242ea45.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 核燃料棒",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 840,
                "list": "\nP90 | 精雕木刻\nG3SG1 | 行刑者\nAK-47 | 混沌点阵",
                "len": 3,
                "box_value": "set_community_10",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Fuel Rod"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/87e6d548adf22ad59550bb646f146a06db7245d4ace406eaaec7b467d8e89a54.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 丛林爆发",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_10",
                "itemid": 841,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Outbreak"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/dd7e5e658b641664a900b01c5efbde474a37ce0ff0001dc68fd3930b7cc159f2.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 弄臣之颅",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 842,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Yorick"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f2056cae8a21e1f02f79ff1273b4981cf9c38c6e1fccbbe782141ba65331e22a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 至高帝皇",
                "min": 0,
                "max": 0.2,
                "target_box": "set_community_10",
                "itemid": 843,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Imperial"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ee702fc9d1c4c228b6be096124e30c90b36161f7edf1cc69a5d1186ff5346f49.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 科林斯遗产",
                "min": 0,
                "max": 0.44,
                "target_box": "set_community_10",
                "itemid": 844,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Corinthian"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/78392641b1771c40e48729cf1e7b3c61d37f2d590168a2afa0ca25553a486287.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 弹跳线条",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_10",
                "itemid": 845,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Ricochet"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0f2dd9314d2a9b49fe652c4352f69f48a78040db6765318354b178a415d6c3cd.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "R8 左轮手枪 | 深红之网",
                "min": 0,
                "max": 1,
                "target_box": "set_community_10",
                "itemid": 846,
                "list": "\nXM1014 | 特克卢喷射者\nTec-9 | 坍雪寒裘\nSG 553 | 豹灯蛾\n内格夫 | 动力装载机\nFN57 | 朝枪夕拾\nPP-野牛 | 核燃料棒",
                "len": 6,
                "box_value": "set_community_10",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_revolver",
                "hash_name": "R8 Revolver | Crimson Web"
            }
        ]
    },
    {
        "name": "暗影武器箱",
        "en_name": "Shadow Case",
        "cover": "https://static.mobi2077.com/market-csgo/a806b687e54f89271d6278668ed5972d8a7658d9995b89607af53741bd35700a.png",
        "type": 2,
        "box_value": "set_community_9",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/9c866dee1298d9756762990fe1040d91519ee80847a996485a570f7479acd852.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "USP 消音版 | 枪响人亡",
                "min": 0,
                "max": 1,
                "target_box": "set_community_9",
                "itemid": 847,
                "list": "",
                "len": 0,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Kill Confirmed"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/ba92f1fecbcb57f8aedcacd029552518e4ce22c7d3d5c313c164cec1b8da3809.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 金蛇缠绕",
                "min": 0,
                "max": 1,
                "target_box": "set_community_9",
                "itemid": 848,
                "list": "",
                "len": 0,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Golden Coil"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/975be2b5842e63d6d802eaf21f88f2f5ec116f2aca7b10592212b46a2564ddcf.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SSG 08 | 巨铁",
                "min": 0,
                "max": 0.64,
                "target_box": "set_community_9",
                "itemid": 849,
                "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
                "len": 2,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Big Iron"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b5406ce180aea5f76678657e23e874b99b44d90fa37cc34bfe48d4d1e171cc9b.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "G3SG1 | 弗卢克斯",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_9",
                "itemid": 850,
                "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
                "len": 2,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Flux"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/db7f29f2edd512ce2c2465f2a698a8b7b1b68828b0528dd0a093ecd87b06137f.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 前线迷雾",
                "min": 0.02,
                "max": 0.87,
                "target_box": "set_community_9",
                "itemid": 851,
                "list": "\nUSP 消音版 | 枪响人亡\nM4A1 消音型 | 金蛇缠绕",
                "len": 2,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Frontside Misty"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/8bf0d8bb77675c99c9af940386464522c842242e60627c84d0fab118f295aa93.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 翼击",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_9",
                "itemid": 852,
                "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
                "len": 3,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Wingshot"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6549d33d16c062abc5b5c33cbdd23c1e4c5f43528e3fc6f208ff7a60e39c1a5a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 速递",
                "min": 0,
                "max": 0.62,
                "target_box": "set_community_9",
                "itemid": 853,
                "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
                "len": 3,
                "box_value": "set_community_9",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Special Delivery"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7cb21a23cbdc821cd0309ad301cdca714479b411642f7d2cf0310756acb00c06.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M249 | 星云十字军",
                "min": 0,
                "max": 1,
                "target_box": "set_community_9",
                "itemid": 854,
                "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
                "len": 3,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Nebula Crusader"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/13bac5fc1d3f43e80de15f073c2ac6656b3cc079aff00721224570d95dca8ee0.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 冷石",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_9",
                "itemid": 855,
                "list": "\nSSG 08 | 巨铁\nG3SG1 | 弗卢克斯\nAK-47 | 前线迷雾",
                "len": 3,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Stone Cold"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3697c962df8eabac36a49031880a7554b544418674b425c7f303cc6eec9546f6.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 斯康里娅",
                "min": 0,
                "max": 1,
                "target_box": "set_community_9",
                "itemid": 856,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Scumbria"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/21161ce895f2c1beb7091a7658bef67528ca6a8fa83d3b10340a1adc4414d17c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 绿色陆战队",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_9",
                "itemid": 857,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Green Marine"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9109f70d9028ae2ec0aaf2aea0d20a354d8a2000251737973580f7ffc1d0592e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 钴核",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_9",
                "itemid": 858,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Cobalt Core"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2c8b29f8af493eee509250b0fb3f72f7638d844a1c631d0e548ee5d042a84d7b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 冉吉",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_9",
                "itemid": 859,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Rangeen"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ae2d7ec02694fc8f4172a7abf91daf2099e8e9af4a74e84cf08bd8f8b80bd548.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 幻影冥魂",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_9",
                "itemid": 860,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Wraiths"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1c671f9fda369fdfa90d7a85a5f103a3120fe4c9dcea4ef037f4599152c5a8eb.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 幸存者 Z",
                "target_box": "set_community_9",
                "itemid": 861,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "min": 0,
                "max": 0.6,
                "hash_name": "FAMAS | Survivor Z"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d96a3406efde6f62f58a5c09737f409357aeb55588df5528d91097720b77b3b1.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 龙之双子",
                "min": 0,
                "max": 1,
                "target_box": "set_community_9",
                "itemid": 862,
                "list": "\nP250 | 翼击\nMP7 | 速递\nM249 | 星云十字军\n加利尔 AR | 冷石",
                "len": 4,
                "box_value": "set_community_9",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Dualing Dragons"
            }
        ]
    },
    {
        "name": "弯曲猎手武器箱",
        "en_name": "Falchion Case",
        "cover": "https://static.mobi2077.com/market-csgo/ebda4ac1888672f55d81fb25279b711840a996e3abc1fb058fd76cdcb1ea8017.png",
        "type": 2,
        "box_value": "set_community_8",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/967ddc9ff9fcbacbb95b9e1b1126d5e37721461a3aa7f4fc5691850445be9c5c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 暴怒野兽",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 863,
                "list": "",
                "len": 0,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Hyper Beast"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/a97265768a0231abb4675ab4b338390f5d27e514d711695edb3ee7ad0734ebb7.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 深海复仇",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 864,
                "list": "",
                "len": 0,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Aquamarine Revenge"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d45304c4ad7e0b28c4a43392ce84d7e62db5d70c2c09891bac419172771e9d08.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SG 553 | 次时代",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 865,
                "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
                "len": 2,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Cyrex"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/a9b17b530a31fc6c33bb2c7b15ecb09ed6d0d1d8e10cf0e0e625e9877a3873b3.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "MP7 | 复仇者",
                "min": 0,
                "max": 0.32,
                "target_box": "set_community_8",
                "itemid": 866,
                "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
                "len": 2,
                "box_value": "set_community_8",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Nemesis"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/64b1b5483833b79031e84d28120b56b3f0eee9e963c5fe61a392b37eca3be511.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "CZ75 | 黄夹克",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 867,
                "list": "\nAWP | 暴怒野兽\nAK-47 | 深海复仇",
                "len": 2,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Yellow Jacket"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f3c1f1e6bf6eac68d3e24d14967f0ff425aa66c149f598e0dad3a84351920f57.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P2000 | 手炮",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 868,
                "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
                "len": 3,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Handgun"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7fe179eaa537c96908155842e6991ef1fad6d956047c67f95777cdcf58d61289.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "内格夫 | 大嘴巴",
                "min": 0.14,
                "max": 0.65,
                "target_box": "set_community_8",
                "itemid": 869,
                "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
                "len": 3,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Loudmouth"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/88eec601f7de7897d069cb6a0b641f798ab6165658339a991f460c5169957bdf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 红宝石毒镖",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_8",
                "itemid": 870,
                "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
                "len": 3,
                "box_value": "set_community_8",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Ruby Poison Dart"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ad0c1107b482d90afcb04485f3acb93fd0a37081f680e9b138087d3de3889a4d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 杀意大名",
                "target_box": "set_community_8",
                "itemid": 871,
                "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
                "len": 3,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "min": 0,
                "max": 0.52,
                "hash_name": "M4A4 | Evil Daimyo"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/1be775aa8e36a7e785de2ff0bc866cd47977957a5bce62dbbf879a573c451e0c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 神经网",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_8",
                "itemid": 872,
                "list": "\nSG 553 | 次时代\nMP7 | 复仇者\nCZ75 | 黄夹克",
                "len": 3,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Neural Net"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/26c3b9514e55031c7436ecf7198d8ede9cd37cbb2db0a69f966e9ed0bbdf3136.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 力矩",
                "min": 0,
                "max": 0.46,
                "target_box": "set_community_8",
                "itemid": 873,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Torque"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/92bfaa3cdc439217edcba5fc69818b4675fbd874ca50d7a2b8f91395d6b236c4.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 暴乱",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_8",
                "itemid": 874,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Riot"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f2e7f471fb35778aae6e49272c7c50e55230091050200c0f59723540bd546e24.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 精英之作",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 875,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Elite Build"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/872ffb27e8f095c47482f47979ca44f0fde37f3d215100b2e5b2a36049f94b3a.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 游侠",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 876,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Ranger"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/58745ce3c9964fe731ea3d0b936a763431cd0205ef763dad5ec8a090d905f64b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 本生灯",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_8",
                "itemid": 877,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Bunsen Burner"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0445cc64babfced848357e6149827040578908086cccc3aed06a2db673e4323e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 火箭冰棒",
                "min": 0,
                "max": 1,
                "target_box": "set_community_8",
                "itemid": 878,
                "list": "\nP2000 | 手炮\n内格夫 | 大嘴巴\nMP9 | 红宝石毒镖\nM4A4 | 杀意大名\n法玛斯 | 神经网",
                "len": 5,
                "box_value": "set_community_8",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Rocket Pop"
            }
        ]
    },
    {
        "name": "幻彩 2 号武器箱",
        "en_name": "Chroma 2 Case",
        "cover": "https://static.mobi2077.com/market-csgo/6375c2448cb6a7d723e0e4766d8ad52bb92b8b2d063f5402484c9f1300b1d22a.png",
        "type": 2,
        "box_value": "set_community_7",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/b0307543a56202e20bec05e2588d52d2fe14ef22addbc2566f0b0745980244ff.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "MAC-10 | 霓虹骑士",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_7",
                "itemid": 879,
                "list": "",
                "len": 0,
                "box_value": "set_community_7",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Neon Rider"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/5dfc73ce6100cb615ba3911261f868a7b3330d1df0bc3362502f0944ce5baa99.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 暴怒野兽",
                "min": 0,
                "max": 1,
                "target_box": "set_community_7",
                "itemid": 880,
                "list": "",
                "len": 0,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Hyper Beast"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d17de8c8090400c32f235da681b50a1a825ee1259c0a4eefa35a4553439687f7.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 灯神",
                "min": 0,
                "max": 1,
                "target_box": "set_community_7",
                "itemid": 881,
                "list": "\nMAC-10 | 霓虹骑士\nM4A1 消音型 | 暴怒野兽",
                "len": 2,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Djinn"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3657646e7a9cd972a9e2147c06fad95e885c2ee0591f6d0588ad3a63388e5b59.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "加利尔 AR | 经济",
                "target_box": "set_community_7",
                "itemid": 882,
                "list": "",
                "len": 0,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "min": 0.1,
                "max": 0.85,
                "hash_name": "Galil AR | Eco"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/596ebfa198aac5049e976bef257927f4daea38df74624a835b086ff450e830c1.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "FN57 | 耍猴把戏",
                "min": 0.1,
                "max": 0.9,
                "target_box": "set_community_7",
                "itemid": 883,
                "list": "\nMAC-10 | 霓虹骑士\nM4A1 消音型 | 暴怒野兽",
                "len": 2,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Monkey Business"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/039c1a41b11aed9007c6927ce5a5a82c17c7eda158fa82794f7aee83fc132526.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "UMP-45 | 车王",
                "min": 0.25,
                "max": 0.35,
                "target_box": "set_community_7",
                "itemid": 884,
                "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
                "len": 3,
                "box_value": "set_community_7",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Grand Prix"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/186c2a9712ec96a4b79fdc43060d6ca3912eecdb9dc77f608c78f4c8a835b439.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 先驱",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_7",
                "itemid": 885,
                "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
                "len": 3,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Pole Position"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c12512a6feaf0de7c73ce090fbcc0ac9efe0ef2b38ee42d36a21f0ddbcb0ab3b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAG-7 | 炽热",
                "min": 0,
                "max": 1,
                "target_box": "set_community_7",
                "itemid": 886,
                "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
                "len": 3,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Heat"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/129f24d29a8febd1f7c6df17e7d8b5e533aebb745469f41b7275cdc79301b8e6.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AWP | 蠕虫之神",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_7",
                "itemid": 887,
                "list": "\n法玛斯 | 灯神\n加利尔 AR | 经济\nFN57 | 耍猴把戏",
                "len": 3,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Worm God"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1e2f4d447b24bd2d1db92ae874622c3d90ef7ee4182827fc1f46aa97be1fb87e.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "截短霰弹枪 | 千纸鹤",
                "min": 0,
                "max": 0.55,
                "target_box": "set_community_7",
                "itemid": 888,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Origami"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b5622bec477706d7e397855d03177668eddaa784a441b747f5011dbcd595ea19.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 无畏战神",
                "min": 0.1,
                "max": 0.2,
                "target_box": "set_community_7",
                "itemid": 889,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Man-o'-war"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f6c98d936528e2876d0d60e642aaf800529a5544fcac78a7bb7fb217868c7546.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 元素轮廓",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_7",
                "itemid": 890,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Valence"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a99db206b27125566d38fad76f1a4e3e3ca7ac09c3b13a6381cea0591664305d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "沙漠之鹰 | 青铜装饰",
                "min": 0,
                "max": 0.46,
                "target_box": "set_community_7",
                "itemid": 891,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Bronze Deco"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/64c5f65a0f68c0ee06b64944a875645177c6d59e58c382f019406f878c3b42b8.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 装甲核心",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_7",
                "itemid": 892,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Armor Core"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7b6df330b1beca30256d3b91ae6df9cf74a9eb405c8dbad9573a000b559cbe18.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AK-47 | 精英之作",
                "min": 0,
                "max": 1,
                "target_box": "set_community_7",
                "itemid": 893,
                "list": "\nUMP-45 | 车王\nCZ75 | 先驱\nMAG-7 | 炽热\nAWP | 蠕虫之神",
                "len": 4,
                "box_value": "set_community_7",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Elite Build"
            }
        ]
    },
    {
        "name": "幻彩武器箱",
        "en_name": "Chroma Case",
        "cover": "https://static.mobi2077.com/market-csgo/fe29d5f7687b442107cd2b33dd1be636fece5c6d2691cada2badb92b26e57796.png",
        "type": 2,
        "box_value": "set_community_6",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/4fca8b881f96dcabc0f7dff05d991655559015e25b7684c4102e4e6603c1cbb7.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "加利尔 AR | 喧闹骷髅",
                "min": 0.35,
                "max": 0.85,
                "target_box": "set_community_6",
                "itemid": 894,
                "list": "",
                "len": 0,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Chatterbox"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/408d6e4e0d544f469562c55f2091c2a292eafbd16fa859f0eae71645e366b65f.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 无畏战神",
                "min": 0.1,
                "max": 0.2,
                "target_box": "set_community_6",
                "itemid": 895,
                "list": "",
                "len": 0,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Man-o'-war"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/fc1590de2a1521a42bdecb44bc8f572d38795c090a10a72fc261ebd3ae6667b0.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 死亡轮回",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_6",
                "itemid": 896,
                "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
                "len": 2,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Muertos"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/48fc6cf8e4e0c0bd1bb4126feac5c7049079d22ea1c1ee48b18541cab782d9a8.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A4 | 龙王",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_6",
                "itemid": 897,
                "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
                "len": 2,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | 龍王"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f802ef3307d38c8c4998ff9e048fca6ae85af7cfdd908a229685b7f3f6aa70ed.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 卡特尔",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_6",
                "itemid": 898,
                "list": "\n加利尔 AR | 喧闹骷髅\nAWP | 无畏战神",
                "len": 2,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Cartel"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4c6ae35165fd978b1d3d7b9e93b19f607c720d5876802f0de191f027d42ed37d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "截短霰弹枪 | 祥和之翼",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_6",
                "itemid": 899,
                "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
                "len": 3,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Serenity"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/292bb2f7bdf187a9280086683d3de176f67ecbc2efdaad03fe76e1824e9e7754.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 孔雀石",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_6",
                "itemid": 900,
                "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
                "len": 3,
                "box_value": "set_community_6",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Malachite"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/cd8a07e7a054628d5d475204f333a3d344b99ac83854159244c76e5ee235d1d5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 纳迦蛇神",
                "min": 0,
                "max": 1,
                "target_box": "set_community_6",
                "itemid": 901,
                "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
                "len": 3,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Naga"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/5f0628e241252c364d3a9dfb1f0c1696cda01d1a04b1c87df708d4d2e9552617.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 都市冲击",
                "min": 0,
                "max": 0.47,
                "target_box": "set_community_6",
                "itemid": 902,
                "list": "\nP250 | 死亡轮回\nM4A4 | 龙王\nAK-47 | 卡特尔",
                "len": 3,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Urban Shock"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c2cfd8980ac8100891414bea37e7ec0bba3e12c03bc7c01ca9c5b455e6e583f0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 剧毒水银",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_6",
                "itemid": 903,
                "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
                "len": 4,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Quicksilver"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2771be65e37c79948444e6f3652880a37a928ed58a485dccde58efaf2047725c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 蓝洞",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_6",
                "itemid": 904,
                "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
                "len": 4,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Grotto"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ab2783467287fc23d527ca5cc7bc9ae910b964121924ee2a564b670b9a9b9389.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 致命毒药",
                "min": 0,
                "max": 1,
                "target_box": "set_community_6",
                "itemid": 905,
                "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
                "len": 4,
                "box_value": "set_community_6",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Deadly Poison"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/15f3139eda1b49d5f40d5e05832f3a53522f459c8c4b97a07b521bc62d8fa752.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 系统锁定",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_6",
                "itemid": 906,
                "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
                "len": 4,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | System Lock"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e2dd91b91657a3cbd9712d8566b4e74a566d82405ae012b37688956697af1105.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 亡者之寝",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_6",
                "itemid": 907,
                "list": "\n截短霰弹枪 | 祥和之翼\nMAC-10 | 孔雀石\n沙漠之鹰 | 纳迦蛇神\n双持贝瑞塔 | 都市冲击",
                "len": 4,
                "box_value": "set_community_6",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Catacombs"
            }
        ]
    },
    {
        "name": "“先锋大行动”武器箱",
        "en_name": "Operation Vanguard Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/f3b3378828125f1313a0c27943eca5d82a283b7aac4a0304e418f468578756a7.png",
        "type": 2,
        "box_value": "set_community_5",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/5b77ba94846777c8266d83437699603ce24b4728d79994828aaa587072d46079.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "P2000 | 火灵",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_5",
                "itemid": 908,
                "list": "",
                "len": 0,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Fire Elemental"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/a79b084e50b900267cf5a8c9ae3f6a65a169a365e77cf2e730465025ca282cd6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 荒野反叛",
                "min": 0.05,
                "max": 0.7,
                "target_box": "set_community_5",
                "itemid": 909,
                "list": "",
                "len": 0,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Wasteland Rebel"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/4f88a76e6c89086775bf7962329e47ab2b9d4a1da061c251a2c8055dfe7df1d1.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "XM1014 | 宁静",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_5",
                "itemid": 910,
                "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Tranquility"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5f66613ae20770d0958102c4bd1d06139be2df10323b164b88ae436df140c0e9.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SCAR-20 | 心脏打击",
                "min": 0,
                "max": 1,
                "target_box": "set_community_5",
                "itemid": 911,
                "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Cardiac"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f597d716145a279c6e5652983890fda3c51db5774a2818cb6c5f0dc74618eea2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 卡特尔",
                "min": 0,
                "max": 0.75,
                "target_box": "set_community_5",
                "itemid": 912,
                "list": "\nP2000 | 火灵\nAK-47 | 荒野反叛",
                "len": 2,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Cartel"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/25227dc11d332c01835f054693565cd0cc8281b6b419ba113d93f652f7fcb2dd.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "截短霰弹枪 | 路霸",
                "min": 0,
                "max": 1,
                "target_box": "set_community_5",
                "itemid": 913,
                "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
                "len": 3,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | Highwayman"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/3b062941931e7427ad387f7509853b4c0a56ea2878a73be8dcaf9f7508dc9b9c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 狮鹫",
                "min": 0,
                "max": 0.8,
                "target_box": "set_community_5",
                "itemid": 914,
                "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
                "len": 3,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Griffin"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/d585b7cb4ea0509183363952bdd1ade0952849bb891a1940203ec8b6c86ef320.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 翼蜥",
                "min": 0,
                "max": 0.68,
                "target_box": "set_community_5",
                "itemid": 915,
                "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
                "len": 3,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Basilisk"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/216571e710555b551471d6b6824fae6648114e6611127b28048d2afafcef2c9b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 粉碎者",
                "min": 0.02,
                "max": 0.25,
                "target_box": "set_community_5",
                "itemid": 916,
                "list": "\nXM1014 | 宁静\nSCAR-20 | 心脏打击\nP250 | 卡特尔",
                "len": 3,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Grinder"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/43233bebf58832078acdabec418c3fc92350b0de5c21a2cd75d355aef4481dc7.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 迷幻",
                "min": 0.06,
                "max": 0.35,
                "target_box": "set_community_5",
                "itemid": 917,
                "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
                "len": 4,
                "box_value": "set_community_5",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Delusion"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c4f7925936fbcc00190cb2edfd669247d0a6bc39bec17a0014c4c2e766ee661b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 都市危机",
                "min": 0,
                "max": 0.25,
                "target_box": "set_community_5",
                "itemid": 918,
                "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
                "len": 4,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Urban Hazard"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7f9e744c5ba22a242f8022733bda6273923deaf9dd6bcf178f56a4433cd25332.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP9 | 飞驰",
                "min": 0,
                "max": 0.46,
                "target_box": "set_community_5",
                "itemid": 919,
                "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
                "len": 4,
                "box_value": "set_community_5",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Dart"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1558eb7c1422cff6facf60bde2ac975b4182f770876fc6d4531467f9a5e2fd72.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 灼烧之痕",
                "min": 0.06,
                "max": 0.49,
                "target_box": "set_community_5",
                "itemid": 920,
                "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
                "len": 4,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Firestarter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c600ac9ebe55ea610381a12600912a7f91ddbcae39d81a32d8ef05270e1760c0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 黑暗豹纹",
                "min": 0,
                "max": 0.25,
                "target_box": "set_community_5",
                "itemid": 921,
                "list": "\n截短霰弹枪 | 路霸\nM4A4 | 狮鹫\nM4A1 消音型 | 翼蜥\n格洛克 18 型 | 粉碎者",
                "len": 4,
                "box_value": "set_community_5",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Murky"
            }
        ]
    },
    {
        "name": "电竞 2014 夏季武器箱",
        "en_name": "eSports 2014 Summer Case",
        "cover": "https://static.mobi2077.com/market-csgo/4c2d1329cb679012c2385a30ec2a8965196c9043f8e81f87717bbce7ef5424a9.png",
        "type": 2,
        "box_value": "set_esports_iii",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/4b9c5a619d1a1f0131ed7fdc1b9c34174bb0d3655a1783646b528c42c58d1526.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 美洲猛虎",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 922,
                "list": "",
                "len": 0,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Jaguar"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/3ca9729cf6eeb7400680df37c96d7aabdabf07772ac44bb0dc5cc6c8309bbbc2.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 弹雨",
                "min": 0.02,
                "max": 0.46,
                "target_box": "set_esports_iii",
                "itemid": 923,
                "list": "",
                "len": 0,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Bullet Rain"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f882a4c57091f0819f7631de178b2ba879586c35b79a7abe373c7255da7acea6.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P2000 | 珊瑚树",
                "min": 0,
                "max": 0.3,
                "target_box": "set_esports_iii",
                "itemid": 924,
                "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
                "len": 2,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Corticera"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5912f7241ff1d64d04dd9a3523d0456200a940b878715bf8621996b0055d429f.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 珊瑚树",
                "min": 0,
                "max": 0.3,
                "target_box": "set_esports_iii",
                "itemid": 925,
                "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
                "len": 2,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Corticera"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f891a5a1ccedfb08d223549476caaeef0b5a1ab2ce12e61df4bd3b10b5ae01bf.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "新星 | 樱花之绚烂",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 926,
                "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
                "len": 2,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Bloomstick"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/d30e42474b4f2a959c88319a8c7c59f46b73aa12780cd778767b09ee194263be.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AUG | 孟加拉猛虎",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 927,
                "list": "\nAK-47 | 美洲猛虎\nM4A4 | 弹雨",
                "len": 2,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Bengal Tiger"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/03ad2abb89c4dacd46d5b0823f0b989d4d021a3e2d16709170bbd2609ccc391f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 深红之网",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 928,
                "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
                "len": 3,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Crimson Web"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c52fc0224957c395b91f32f3acc725112bf60d2ab8e2b5ef75ff6faf4d02405a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 钢铁禁锢",
                "min": 0,
                "max": 0.2,
                "target_box": "set_esports_iii",
                "itemid": 929,
                "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
                "len": 3,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Steel Disruption"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c76d7f7f795eea8d0d0a31beb8d2b4af509f0db7f2e9aea426a42c20a51db3ef.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP7 | 海之泡沫",
                "min": 0,
                "max": 0.08,
                "target_box": "set_esports_iii",
                "itemid": 930,
                "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
                "len": 3,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Ocean Foam"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/04b98bdffb613dc2f6cee38167b728dd3486c9bbd7335fdb81fac20acff9176d.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 病毒危机",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 931,
                "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
                "len": 3,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Virus"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/21102ae211052f6b3f473a53113e880cc5a82c60998fde4aa080c7649176d8ce.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 水蓝条纹",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 932,
                "list": "\nP2000 | 珊瑚树\n新星 | 樱花之绚烂\nAUG | 孟加拉猛虎",
                "len": 3,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Blue Streak"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e31ba5fac37c04eeb2421a91119c2085137ad02e71f5d09afb1afac32032955b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "XM1014 | 血红巨蟒",
                "min": 0.08,
                "max": 0.5,
                "target_box": "set_esports_iii",
                "itemid": 933,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Red Python"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/327c07e2abcb05733b14097b24d096fbb40a46c5fb5d8fa492fff9f3b52b9e58.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | *哒哒哒*",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 934,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Bratatat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a8299d6124b29172686d7cad5d8996ec9bf02cff42df97c457c89502bcaab04d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 蓝巢",
                "min": 0,
                "max": 0.4,
                "target_box": "set_esports_iii",
                "itemid": 935,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Hexane"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ed7772499c4245330c9adae8024c7152d8576cdb714498a3f64cc98d8735da41.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 血虎",
                "min": 0,
                "max": 0.3,
                "target_box": "set_esports_iii",
                "itemid": 936,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Blood Tiger"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/377a3172a22c4269acd8c49d4f2787470f7cae0d1de55dd2c788dfc9c4a0da5b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAC-10 | 致命紫罗兰",
                "min": 0,
                "max": 1,
                "target_box": "set_esports_iii",
                "itemid": 937,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Ultraviolet"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e72efc0f21d71d43e44ef63970f5426a6aa519b3643e89c6b843548d7342c3b1.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 黑水",
                "min": 0.1,
                "max": 0.26,
                "target_box": "set_esports_iii",
                "itemid": 938,
                "list": "\n沙漠之鹰 | 深红之网\n格洛克 18 型 | 钢铁禁锢\nMP7 | 海之泡沫\nP90 | 病毒危机\nPP-野牛 | 水蓝条纹",
                "len": 5,
                "box_value": "set_esports_iii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Dark Water"
            }
        ]
    },
    {
        "name": "“突围大行动”武器箱",
        "en_name": "Operation Breakout Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/58d412d21d88597e4a8d8a0884683868185df214bb2c7647002d6b11bb07752e.png",
        "type": 2,
        "box_value": "set_community_4",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/7c9d9399d9eee6654e65e713e62f91630119bedb0f55a6b2759e467c272124e5.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A1 消音型 | 次时代",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_4",
                "itemid": 939,
                "list": "",
                "len": 0,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Cyrex"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/1de56a133102e8dd36f69082d1f298cdd34d76b10a4db00c87b5301b823ab5d3.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "P90 | 二西莫夫",
                "min": 0,
                "max": 0.92,
                "target_box": "set_community_4",
                "itemid": 940,
                "list": "",
                "len": 0,
                "box_value": "set_community_4",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Asiimov"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/b3166213c5de749d1c9f4378b54ea14332a612862494f00bd2740582df95c97a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "格洛克 18 型 | 水灵",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_4",
                "itemid": 941,
                "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Water Elemental"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1c4b13241fd1bdd02b38d224897399783f81cf60ce32fca32e3a11ad2d9ee67a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "FN57 | 狩猎利器",
                "min": 0,
                "max": 1,
                "target_box": "set_community_4",
                "itemid": 942,
                "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Fowl Play"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/aa6d0c8fac011676fed915a661fa246e18bd5a36842afe4e9df333073643c43c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 阴谋者",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_4",
                "itemid": 943,
                "list": "\nM4A1 消音型 | 次时代\nP90 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Conspiracy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2b5babcb6c256d13a22e4cddfbe57d1b442abc0d62f8718767b37bfc3a5747fa.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 超新星",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_4",
                "itemid": 944,
                "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
                "len": 3,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Supernova"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6c49864fce11edf15217ee66f0cc5a5108e5f6ec6244ceeec0f9b1e076b2cc25.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 锦鲤",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_4",
                "itemid": 945,
                "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
                "len": 3,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Koi"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/70d2281d2a244322132e91b777c82e3f7d57cfa34fb29c14958eb79b49c1c4a5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 猛虎",
                "min": 0,
                "max": 1,
                "target_box": "set_community_4",
                "itemid": 946,
                "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
                "len": 3,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Tigris"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/755d40dce21c9d4e22facec3bfe2d877c4b123dd72f9b6178c1f15814c03203c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 死亡主宰者",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_4",
                "itemid": 947,
                "list": "\n格洛克 18 型 | 水灵\nFN57 | 狩猎利器\n沙漠之鹰 | 阴谋者",
                "len": 3,
                "box_value": "set_community_4",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Osiris"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/000e0d367d2d960b3fde5a3370780c4b54f46212b621f70629cb3ea8a7faa643.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 迷之宫",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_4",
                "itemid": 948,
                "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
                "len": 4,
                "box_value": "set_community_4",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Labyrinth"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/09ec9be89aeaf93d2283d95fdef5eed6865b4dd8529428116b81394a3e612ae9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 无尽深海",
                "min": 0,
                "max": 1,
                "target_box": "set_community_4",
                "itemid": 949,
                "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
                "len": 4,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Abyss"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f2eee2883f31012f7fb1f3d11f44dac458a6502eb8835b0abcaed6da84a4e946.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 乳白象牙",
                "min": 0,
                "max": 1,
                "target_box": "set_community_4",
                "itemid": 950,
                "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
                "len": 4,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Ivory"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7ce9137c0769a6a8b43e18eda479e7819089d2ec34088f1958cdc594587cb603.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 沙漠精英",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_4",
                "itemid": 951,
                "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
                "len": 4,
                "box_value": "set_community_4",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Desert-Strike"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/9517e99913db87e96d664a1d14a575bb4f4492dee4f22ace5b50184833e79a08.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 都市危机",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_4",
                "itemid": 952,
                "list": "\nP250 | 超新星\n新星 | 锦鲤\nCZ75 | 猛虎\nPP-野牛 | 死亡主宰者",
                "len": 4,
                "box_value": "set_community_4",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Urban Hazard"
            }
        ]
    },
    {
        "name": "猎杀者收藏品",
        "en_name": "Huntsman Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/bba87c046c285e5bfdbc59e044ffa3dc3920c6362e7dc37025e882833df1309e.png",
        "type": 2,
        "box_value": "set_community_3",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/b6b5618d574b1aeb1b64fe0b7f17ace5fde8fa63cbc4605120225fee0b1668ba.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 沙漠精英",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_3",
                "itemid": 953,
                "list": "",
                "len": 0,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Desert-Strike"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/97ff991bc1cf10f9c4988154e6c8d4d4f4567cf103c8914131f19a8e117dc04c.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 火神",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_3",
                "itemid": 954,
                "list": "",
                "len": 0,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Vulcan"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/f9fb6cc3a5ef1657e1aa6c6f1dfe5e65d09738f317db265e354f4f9dd455ad3d.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 猎户",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 955,
                "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
                "len": 2,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Orion"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/ceb597038f9a5e2039f8432124e1db1d6b9baea35a366deb6bbaf0a22f4b8f27.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 凯门鳄",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_3",
                "itemid": 956,
                "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
                "len": 2,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Caiman"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/3414b1c06abaa04b74f873406eac31d22e843d9f1ab89a9180ca8e69eb9ece76.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "SCAR-20 | 次时代",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 957,
                "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
                "len": 2,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Cyrex"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/758c6f2862e2f0a8878a8cdc87a854894e06cf3463b48b23216541ca8eb6640d.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 原子合金",
                "min": 0,
                "max": 0.9,
                "target_box": "set_community_3",
                "itemid": 958,
                "list": "\nM4A4 | 沙漠精英\nAK-47 | 火神",
                "len": 2,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Atomic Alloy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b4e7f92684266c21097bed7ac8034aa117e5a634cff31760ae1ca633951a51f1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 诅咒",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 1102,
                "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
                "len": 3,
                "box_value": "set_community_3",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Curse"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a3b5360f9cfb6dce84c18765a487720702eb7c8e8f72c929d5199090a3aa8991.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 烧尽",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 959,
                "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
                "len": 3,
                "box_value": "set_community_3",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Tatter"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0f38257faafa12db422905a66af11e5c01a8015cdfa3f3c27e2ce2bf15c7a1f1.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "XM1014 | 天空守卫",
                "min": 0.03,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 960,
                "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
                "len": 3,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_xm1014",
                "hash_name": "XM1014 | Heaven Guard"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/7af6aae03def77dee745074177a6a2e8b1c0eb9e02c6f26d5a713c1bdc817242.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "PP-野牛 | 古董枪",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 961,
                "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
                "len": 3,
                "box_value": "set_community_3",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Antique"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/70ab1eb8da677f60347b1c6dcfb9b0d478659f1832c8a9dc38b50baec96a7a78.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AUG | 力矩",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 962,
                "list": "\nUSP 消音版 | 猎户\nSCAR-20 | 次时代\nM4A1 消音型 | 原子合金",
                "len": 3,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Torque"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/5f0d58718df3e51ee81d1a25a52d5e588cdbb41b9cf6763c8b2d7b5d7dbcab97.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 电子脉冲",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_3",
                "itemid": 963,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Pulse"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/201abbcdc4bc96f6061cf4a77cc0e01ff45b8c6f12fe484f0a806eee048378ce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 深蓝组件",
                "min": 0,
                "max": 0.35,
                "target_box": "set_community_3",
                "itemid": 964,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Module"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6ab3eba4f58d427fde4f0ea5c246f1c9bcdc30456c74f404feed982731cffa7b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 螺形扭转",
                "min": 0,
                "max": 1,
                "target_box": "set_community_3",
                "itemid": 965,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Twist"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/be200378a679a5960f848dc6d7ec60c31d727135065944311b6d85aa9a6619ed.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 神祗",
                "min": 0,
                "max": 0.6,
                "target_box": "set_community_3",
                "itemid": 966,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Kami"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a4a27c7fa4cf65d618f110c8d06954bb2bfd3584efb5ead541cea95a918ed4d3.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SSG 08 | 裂痕",
                "min": 0.15,
                "max": 0.8,
                "target_box": "set_community_3",
                "itemid": 967,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Slashed"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/0a56e70661f855563c12c9c32372423f0b8a11ea22990b05d5b6ca2b879738ad.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 报应",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_3",
                "itemid": 968,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Retribution"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/42ba41b09cf3b23c3e2bd1b01c2524ddb8b42fe3a4fa483efdd4ea25c2903214.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P90 | 沙漠战争",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_3",
                "itemid": 969,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Desert Warfare"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/f5e1ea9967642f12859332eaf4c9a25b4f9891fd570381635695d99836229f03.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 毒镖",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_3",
                "itemid": 970,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Poison Dart"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/109b0a736f61da5d3f8e67792d977534252d5962cb936d66dda2b807828e9c2c.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 艾萨克",
                "min": 0,
                "max": 1,
                "target_box": "set_community_3",
                "itemid": 971,
                "list": "\nMAC-10 | 诅咒\nMAC-10 | 烧尽\nXM1014 | 天空守卫\nPP-野牛 | 古董枪\nAUG | 力矩",
                "len": 5,
                "box_value": "set_community_3",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Isaac"
            }
        ]
    },
    {
        "name": "“凤凰大行动”武器箱",
        "en_name": "Operation Phoenix Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/97c21a398b09bba49a1c782c71960a2f336d79d281110d10540548bbd4522e97.png",
        "type": 2,
        "box_value": "set_community_2",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/751c3b3639e9fb2a6d4f814d297306a6e412664a81a816097cac05527ae87dc1.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AUG | 变色龙",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_2",
                "itemid": 972,
                "list": "",
                "len": 0,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Chameleon"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/4fd4d7b52d2754b8485e7b8d86a4ab3102f8dbce504356a93501a5c9f3eb8bf6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 二西莫夫",
                "min": 0.18,
                "max": 1,
                "target_box": "set_community_2",
                "itemid": 973,
                "list": "",
                "len": 0,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Asiimov"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/97468bc9ddcb9dabd24f67b33e6672e5c114c301e239484138d24d58bad8cc93.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "新星 | 古董枪",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_2",
                "itemid": 974,
                "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
                "len": 2,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Antique"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/6a6bc530194aade9a6ae748fb6743e595838761ba848ece8aea9ebc13797c9de.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 三角",
                "min": 0.08,
                "max": 0.75,
                "target_box": "set_community_2",
                "itemid": 975,
                "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
                "len": 2,
                "box_value": "set_community_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Trigon"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5b61dd4538c2696391008762adfe5ee12279686f5ac949e2d266cb4cebfa5b42.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 红线",
                "min": 0.1,
                "max": 0.7,
                "target_box": "set_community_2",
                "itemid": 976,
                "list": "\nAUG | 变色龙\nAWP | 二西莫夫",
                "len": 2,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Redline"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/70bca39cd40e83da41a821ac0818a91062597ccbbc8177a20496f17d040f60d7.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 守护者",
                "min": 0,
                "max": 0.38,
                "target_box": "set_community_2",
                "itemid": 977,
                "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
                "len": 3,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Guardian"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/fe884c6ce8070c70d5c4c7d7305d3f2969775b385d4f75577b6b9e224663b31b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 中士",
                "min": 0.1,
                "max": 1,
                "target_box": "set_community_2",
                "itemid": 978,
                "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
                "len": 3,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Sergeant"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b42a3a0f2f15b4c8b4ea439b0d0ccdedd8198fbb144a75ee89ab2284c7c3e34e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "SG 553 | 电子脉冲",
                "min": 0.1,
                "max": 0.6,
                "target_box": "set_community_2",
                "itemid": 979,
                "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
                "len": 3,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Pulse"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/ed53719e70a2eea19fc1e6336550118bcddb389a2bc9ecf96983fc3bf5ede46e.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 炽热",
                "min": 0,
                "max": 1,
                "target_box": "set_community_2",
                "itemid": 980,
                "list": "\n新星 | 古董枪\nP90 | 三角\nAK-47 | 红线",
                "len": 3,
                "box_value": "set_community_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Heat"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8b54c8308723beef8ff33da5270427b34317aba20e04804a54d470693f51f449.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 天空守卫",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_2",
                "itemid": 981,
                "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
                "len": 4,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Heaven Guard"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c4adfe22482b8ac276f00c582e0b211c89554b593f792539316a2bd2062e3ec5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 沙尘暴",
                "min": 0.1,
                "max": 0.7,
                "target_box": "set_community_2",
                "itemid": 982,
                "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
                "len": 4,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Sandstorm"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c14bb709b292898837fa92033c59466b914991fc972dc43f84550f7385b2d819.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "内格夫 | 青绿地形",
                "min": 0,
                "max": 0.45,
                "target_box": "set_community_2",
                "itemid": 983,
                "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
                "len": 4,
                "box_value": "set_community_2",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_negev",
                "hash_name": "Negev | Terrain"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/aabe302d4702dca5b66fd6b3648c732f955e4257dd6cd78827ced0bca247e293.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 下士",
                "min": 0.05,
                "max": 0.75,
                "target_box": "set_community_2",
                "itemid": 984,
                "list": "\nUSP 消音版 | 守护者\n法玛斯 | 中士\nSG 553 | 电子脉冲\nMAC-10 | 炽热",
                "len": 4,
                "box_value": "set_community_2",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Corporal"
            }
        ]
    },
    {
        "name": "CS:GO 3 号武器箱",
        "en_name": "CS:GO Weapon Case 3",
        "cover": "https://static.mobi2077.com/market-csgo/a8f0390c98978f10e246869f52270f26f1bc4546e84746c9f44bb9d20ffe0853.png",
        "type": 2,
        "box_value": "set_weapons_iii",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/c58aa27cf84bfb2a20094947032646deb8ed7ccc7fb89eb36fe7ea45187dda30.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "CZ75 | 维多利亚",
                "min": 0,
                "max": 0.75,
                "target_box": "set_weapons_iii",
                "itemid": 985,
                "list": "",
                "len": 0,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Victoria"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/799d2634b244c4b374bac5f1ebd35213abae62764b555b4974e0412dce24c83e.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 暗潮",
                "min": 0,
                "max": 0.2,
                "target_box": "set_weapons_iii",
                "itemid": 986,
                "list": "\nCZ75 | 维多利亚",
                "len": 1,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Undertow"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/c0975aab16e8487e7623d91565cd7858e618868b94a0e644ee818776a4f942eb.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "CZ75 | 梅红时刻",
                "min": 0,
                "max": 0.4,
                "target_box": "set_weapons_iii",
                "itemid": 987,
                "list": "\nCZ75 | 维多利亚",
                "len": 1,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | The Fuschia Is Now"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/b0024ee1bea05b81aa27d1e6c93d83e226ad3180b205747cbb3210997d8ebb0a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 铜色星系",
                "min": 0,
                "max": 0.2,
                "target_box": "set_weapons_iii",
                "itemid": 988,
                "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
                "len": 2,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Copper Galaxy"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c9305c0c23cd0cb7473ed3657cb4db2fad69f8a067d8520cc2feafb54b06e1af.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "沙漠之鹰 | 遗产",
                "min": 0,
                "max": 0.8,
                "target_box": "set_weapons_iii",
                "itemid": 989,
                "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
                "len": 2,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Heirloom"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/272644e58d4305d3cad44acf6f7209e8c36d0228d6c497debd9da3946e2b8934.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "Tec-9 | 钛片",
                "min": 0,
                "max": 0.2,
                "target_box": "set_weapons_iii",
                "itemid": 990,
                "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
                "len": 2,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Titanium Bit"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/949d5b064766238107bac27287d57da84a128d6984145207c1a223354cd15834.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "CZ75 | 花纹钢板",
                "min": 0,
                "max": 0.2,
                "target_box": "set_weapons_iii",
                "itemid": 991,
                "list": "\nP250 | 暗潮\nCZ75 | 梅红时刻",
                "len": 2,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Tread Plate"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/62b07405646f02347b20b1129a30fe3c8f25abf17152f49e5d25df7ab045548b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "格洛克 18 型 | 蓝色裂纹",
                "min": 0.06,
                "max": 0.58,
                "target_box": "set_weapons_iii",
                "itemid": 992,
                "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
                "len": 4,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Blue Fissure"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/2a98b453cb42538dfdd5650e5774176699a77165d750f27879241a69f249292d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "USP 消音版 | 不锈钢",
                "min": 0,
                "max": 0.8,
                "target_box": "set_weapons_iii",
                "itemid": 993,
                "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
                "len": 4,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Stainless"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/56dd14c56c6d989fdeb25db452ba41ffb51daf125c850e3a7e29e7b7183be285.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 黑豹",
                "min": 0,
                "max": 0.58,
                "target_box": "set_weapons_iii",
                "itemid": 994,
                "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
                "len": 4,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Panther"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/241640b816ebb1d217a2ad5684d328ef4f4514da2dc4330162df4fddb8e17cd1.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P2000 | 红色碎片迷彩",
                "min": 0,
                "max": 0.7,
                "target_box": "set_weapons_iii",
                "itemid": 995,
                "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
                "len": 4,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Red FragCam"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7b740b8a948a18a73e660ff3d1684ae969543013e7821941f2111832c81ffd36.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "CZ75 | 深红之网",
                "min": 0,
                "max": 1,
                "target_box": "set_weapons_iii",
                "itemid": 996,
                "list": "\nFN57 | 铜色星系\n沙漠之鹰 | 遗产\nTec-9 | 钛片\nCZ75 | 花纹钢板",
                "len": 4,
                "box_value": "set_weapons_iii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_cz75a",
                "hash_name": "CZ75-Auto | Crimson Web"
            }
        ]
    },
    {
        "name": "冬季攻势武器箱",
        "en_name": "Winter Offensive Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/0b31fd87f353afb7de4785e4346f6891084dd609ffd40bdcd7f85704fa71ef69.png",
        "type": 2,
        "box_value": "set_community_1",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/3af8fe3e09982990a006907ee4d2edd46a5c266533d02004cbbc6213c5510221.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "截短霰弹枪 | 北海巨妖",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_1",
                "itemid": 997,
                "list": "",
                "len": 0,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "hash_name": "Sawed-Off | The Kraken"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/bb79a8ad8dd9d191eeeeb9e7e476c20c97466973f37abc023fb9cae16ecf68b6.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | 二西莫夫",
                "min": 0.18,
                "max": 1,
                "target_box": "set_community_1",
                "itemid": 998,
                "list": "",
                "len": 0,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Asiimov"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/6a384991021b29900191a742dd2057e41979a4e9b2402e6a971adb4623f5fbec.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 红线",
                "min": 0.1,
                "max": 0.4,
                "target_box": "set_community_1",
                "itemid": 999,
                "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Redline"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/68b8b1b9b3143357741a4bb3fc93a1e28b88cd1eb48435e9c71d7abfcf897c4a.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P250 | 曼海蒂",
                "min": 0,
                "max": 1,
                "target_box": "set_community_1",
                "itemid": 1000,
                "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Mehndi"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/57dce3b6cfb954fb53e816a903ba6916153a3555c554226ac13057760299270e.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "M4A1 消音型 | 守护者",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_1",
                "itemid": 1001,
                "list": "\n截短霰弹枪 | 北海巨妖\nM4A4 | 二西莫夫",
                "len": 2,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Guardian"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f5859a02ce62a2c4c681d815a55c7a53ab41b16e0c3264d674c97eeb04f68002.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 惊惧骷髅",
                "min": 0,
                "max": 0.5,
                "target_box": "set_community_1",
                "itemid": 1002,
                "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
                "len": 3,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Rising Skull"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/0145a643be6c67945f068792995aa7dac53566239523af3efcb51176c2c29f7c.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 铁血玫瑰",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_1",
                "itemid": 1003,
                "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
                "len": 3,
                "box_value": "set_community_1",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Rose Iron"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/fa708a99ecc06ed87d9e2ad8a81b8019f822f6c62d175b11b31a48045ad1be3b.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 海斗士",
                "min": 0.05,
                "max": 0.5,
                "target_box": "set_community_1",
                "itemid": 1004,
                "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
                "len": 3,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Marina"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/941be5278e0894005c376fa0ab0be56d872e173b8d873449b11a747b9bed4385.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "法玛斯 | 电子脉冲",
                "min": 0,
                "max": 0.4,
                "target_box": "set_community_1",
                "itemid": 1005,
                "list": "\nAWP | 红线\nP250 | 曼海蒂\nM4A1 消音型 | 守护者",
                "len": 3,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Pulse"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/e8b49f65af56aaea9aafd05ab9cddc10b9c29db2b1c95c26f67963168212c27d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 钻蓝半调",
                "min": 0.05,
                "max": 0.45,
                "target_box": "set_community_1",
                "itemid": 1006,
                "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
                "len": 4,
                "box_value": "set_community_1",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Cobalt Halftone"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c87b4380d481e611e168aa6bf4e2d258e5b6fb18400eb3009b1b4bb9bee990ab.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M249 | 岩浆",
                "min": 0,
                "max": 0.7,
                "target_box": "set_community_1",
                "itemid": 1007,
                "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
                "len": 4,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Machinegun",
                "weapon": "weapon_m249",
                "hash_name": "M249 | Magma"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/576a153ddf2707123c0276c167e912abd3d79ccf2cfe4c7c9d146a1f7cacee25.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 神祗",
                "min": 0,
                "max": 0.3,
                "target_box": "set_community_1",
                "itemid": 1008,
                "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
                "len": 4,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Kami"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8fe477b9cb18e6b0fd6b6b9cf092e8843d8e92e831003a5b1f3c1972402cd7c5.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 沙尘暴",
                "min": 0.1,
                "max": 0.6,
                "target_box": "set_community_1",
                "itemid": 1009,
                "list": "\n新星 | 惊惧骷髅\nMP9 | 铁血玫瑰\n双持贝瑞塔 | 海斗士\n法玛斯 | 电子脉冲",
                "len": 4,
                "box_value": "set_community_1",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Sandstorm"
            }
        ]
    },
    {
        "name": "电竞 2013 冬季武器箱",
        "en_name": "eSports 2013 Winter Case",
        "cover": "https://static.mobi2077.com/market-csgo/f4e9cf20e2a693526fd77f978247ab96b3a480a81183a5fe63d3e343b4d67f7b.png",
        "type": 2,
        "box_value": "set_esports_ii",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/bb82b10298e75983fd70bf8491443860eee301232cccf5e5cbc32b2ead75566d.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "M4A4 | X 射线",
                "target_box": "set_esports_ii",
                "itemid": 1010,
                "list": "",
                "len": 0,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "min": 0,
                "max": 0.3,
                "hash_name": "M4A4 | X-Ray"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1adaf79a434c813e9af608cc3504b9dd61ee884ba4cb014f84a7e9addd973d53.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 钴蓝禁锢",
                "min": 0,
                "max": 0.2,
                "target_box": "set_esports_ii",
                "itemid": 1011,
                "list": "\nM4A4 | X 射线",
                "len": 1,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Cobalt Disruption"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/fa2fec040aa6250436abbb3e0a60e26912778d63949be2541968ddc413fcfa88.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 电子蜂巢",
                "min": 0,
                "max": 0.4,
                "target_box": "set_esports_ii",
                "itemid": 1012,
                "list": "\nM4A4 | X 射线",
                "len": 1,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Electric Hive"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/71ef95a8fd6d3cf852a6992b7296646d1a11fe75113df0535addc32e03dddcad.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "法玛斯 | 残影",
                "min": 0.02,
                "max": 0.4,
                "target_box": "set_esports_ii",
                "itemid": 1013,
                "list": "\nM4A4 | X 射线",
                "len": 1,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Afterimage"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/69c1d8d31620673f52ea8dddd1d376a931e862493fb536e8542b16a01215101a.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P90 | 盲点",
                "min": 0,
                "max": 0.5,
                "target_box": "set_esports_ii",
                "itemid": 1014,
                "list": "\n沙漠之鹰 | 钴蓝禁锢\nAWP | 电子蜂巢\n法玛斯 | 残影",
                "len": 3,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Blind Spot"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a79d8c097ca4ce65a007e39abcd4068cc8a0c56ef7ca43331ecc2f70e3146d5f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "AK-47 | 蓝色层压板",
                "min": 0.02,
                "max": 0.4,
                "target_box": "set_esports_ii",
                "itemid": 1015,
                "list": "\n沙漠之鹰 | 钴蓝禁锢\nAWP | 电子蜂巢\n法玛斯 | 残影",
                "len": 3,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Blue Laminate"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b1731d8862c508c5a0390a264ab306fa90fc05c043d14ae1ee6928b5025ed3ce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 钢铁禁锢",
                "min": 0,
                "max": 0.2,
                "target_box": "set_esports_ii",
                "itemid": 1016,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Steel Disruption"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/1b4f723fca344e9eabcb5778383ea53e6a0656093ca5e3b59cc69b11fae7ab38.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 碧蓝斑纹",
                "min": 0,
                "max": 0.28,
                "target_box": "set_esports_ii",
                "itemid": 1017,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Azure Zebra"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/8d1595725129b5a764ac8877a6b5cb699cbe5baf9d47df5bca8cedb423eeb8fa.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 幽灵迷彩",
                "min": 0,
                "max": 0.4,
                "target_box": "set_esports_ii",
                "itemid": 1018,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Ghost Camo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/828cec3860825bf220f11719b389869c1ade136594f872fa4fb389c831c29fa9.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "PP-野牛 | 水纹之印",
                "min": 0,
                "max": 0.5,
                "target_box": "set_esports_ii",
                "itemid": 1019,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_bizon",
                "hash_name": "PP-Bizon | Water Sigil"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d9969422143371ae8e5c0fb0307caade070ca9ad19872481aa7b630d22b16ccd.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "FN57 | 夜影",
                "min": 0,
                "max": 0.5,
                "target_box": "set_esports_ii",
                "itemid": 1020,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Nightshade"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/7aab3f0e10c46cc62ec82c39389afb78144f1327671b21487a52d52ff2768031.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 蓝钛",
                "min": 0,
                "max": 0.04,
                "target_box": "set_esports_ii",
                "itemid": 1021,
                "list": "\nP90 | 盲点\nAK-47 | 蓝色层压板",
                "len": 2,
                "box_value": "set_esports_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Blue Titanium"
            }
        ]
    },
    {
        "name": "CS:GO 2 号武器箱",
        "en_name": "CS:GO Weapon Case 2",
        "cover": "https://static.mobi2077.com/market-csgo/494b23ce97ce6d03c410a3a6dfcd8f843439906cc3df73d179094e694c261186.png",
        "type": 2,
        "box_value": "set_weapons_ii",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/6938f6440f40149c89ae5ce96301a2fb7d2cf8204b154961a4ec136e8aaa3439.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "SSG 08 | 水中之血",
                "min": 0.06,
                "max": 0.2,
                "target_box": "set_weapons_ii",
                "itemid": 1022,
                "list": "",
                "len": 0,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ssg08",
                "hash_name": "SSG 08 | Blood in the Water"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/fe5367b5fdf87edd4bf0c3ad03a6518cdfcfc0d38e941830204fa385ef9192f2.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "USP 消音版 | 血清",
                "min": 0,
                "max": 0.25,
                "target_box": "set_weapons_ii",
                "itemid": 1023,
                "list": "\nSSG 08 | 水中之血",
                "len": 1,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Serum"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/5ffc1ee0501a1142d75ee50328562e0ea9195e3b5eb05cb8b48b1742a821ef1c.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 冷血杀手",
                "target_box": "set_weapons_ii",
                "itemid": 1024,
                "list": "\nSSG 08 | 水中之血",
                "len": 1,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "min": 0,
                "max": 0.08,
                "hash_name": "P90 | Cold Blooded"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/34ab99f3e031a062bbd9ae3f577553a5bf5a0c7d4e7a85405a343799b1daaeaf.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "双持贝瑞塔 | 血红蛋白",
                "min": 0,
                "max": 0.2,
                "target_box": "set_weapons_ii",
                "itemid": 1025,
                "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
                "len": 2,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Hemoglobin"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/641bb21efddd7bfa1bff512f2aa0223a08a972579d15aeaf19a6961573ea7448.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "新星 | 石墨黑",
                "min": 0,
                "max": 0.12,
                "target_box": "set_weapons_ii",
                "itemid": 1026,
                "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
                "len": 2,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Graphite"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a9e0e771bce13f8567c94b6fa0690c7f609480aab7ae9cf1c1f408a66de59208.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MP9 | 蛊惑之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_weapons_ii",
                "itemid": 1027,
                "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
                "len": 2,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp9",
                "hash_name": "MP9 | Hypnotic"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f6d5e517fed63d7d439ff092f730a805378d17d868b16a33ccbdbb0ac23e8b61.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "FN57 | 表面淬火",
                "min": 0,
                "max": 1,
                "target_box": "set_weapons_ii",
                "itemid": 1028,
                "list": "\nUSP 消音版 | 血清\nP90 | 冷血杀手",
                "len": 2,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_fiveseven",
                "hash_name": "Five-SeveN | Case Hardened"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3817e500c036cf98d5b8499acd5506c67ce7f448fe2458c58dab855881dc189b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SCAR-20 | 深红之网",
                "min": 0,
                "max": 1,
                "target_box": "set_weapons_ii",
                "itemid": 1029,
                "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
                "len": 4,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_scar20",
                "hash_name": "SCAR-20 | Crimson Web"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/31006fb3af46df1e22f0e1ae2c4231ce2287d61171ad50d08dc6980664a018f8.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "P250 | 红巢",
                "min": 0,
                "max": 0.3,
                "target_box": "set_weapons_ii",
                "itemid": 1030,
                "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
                "len": 4,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Hive"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/d2cd25523c5f94313f557123eb59224616108728a58b9e780b224eeecc9e03b0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 蓝巢",
                "min": 0,
                "max": 0.4,
                "target_box": "set_weapons_ii",
                "itemid": 1031,
                "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
                "len": 4,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Hexane"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/966c6f1af679bf75336b6c17afd24bde9a758621b5f237840a7f6192de2d791f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A1 消音型 | 血虎",
                "min": 0,
                "max": 0.3,
                "target_box": "set_weapons_ii",
                "itemid": 1032,
                "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
                "len": 4,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Blood Tiger"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/cf065f6bb42c9ba5e6d1248c274aa08684e47f6d6c0a05f7c57b68f31b9dcb87.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "Tec-9 | 蓝钛",
                "min": 0,
                "max": 0.04,
                "target_box": "set_weapons_ii",
                "itemid": 1033,
                "list": "\n双持贝瑞塔 | 血红蛋白\n新星 | 石墨黑\nMP9 | 蛊惑之色\nFN57 | 表面淬火",
                "len": 4,
                "box_value": "set_weapons_ii",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_tec9",
                "hash_name": "Tec-9 | Blue Titanium"
            }
        ]
    },
    {
        "name": "“英勇大行动”武器箱",
        "en_name": "Operation Bravo Case",
        "cover": "https://static.mobi2077.com/market-csgo/ff3a4e1b522750a4cf10dc41249df3677bb1450233c8e4bc0c96fdb98f8fe5fe.png",
        "type": 2,
        "box_value": "set_bravo_i",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/178047207ef9daeb09612a61325ed69e390840c0511d4c144f8e33423e2c7855.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "沙漠之鹰 | 黄金锦鲤",
                "min": 0,
                "max": 0.12,
                "target_box": "set_bravo_i",
                "itemid": 1034,
                "list": "",
                "len": 0,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Golden Koi"
            },
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/f65cf5bccfc004e60194bb36ddd0516ba50ced870537190f057e64959bcf9356.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AK-47 | 火蛇",
                "min": 0.06,
                "max": 0.76,
                "target_box": "set_bravo_i",
                "itemid": 1035,
                "list": "",
                "len": 0,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Fire Serpent"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/1225ab30507a5a46e2c5045e0fd9e6fba165a6c72638ced0072c95f367d9301e.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | 石墨黑",
                "min": 0,
                "max": 0.12,
                "target_box": "set_bravo_i",
                "itemid": 1036,
                "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
                "len": 2,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Graphite"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/84dd73ec6dbe282d39d7f0ac6a5a04a7494469baf5de86a629e561634a723e89.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P2000 | 海之泡沫",
                "min": 0,
                "max": 0.12,
                "target_box": "set_bravo_i",
                "itemid": 1037,
                "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
                "len": 2,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_hkp2000",
                "hash_name": "P2000 | Ocean Foam"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/4be1043ff7694f22ac5331057b827f310c0b93d075909147ab0d06efea17371d.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "P90 | 翡翠之龙",
                "min": 0,
                "max": 0.52,
                "target_box": "set_bravo_i",
                "itemid": 1038,
                "list": "\n沙漠之鹰 | 黄金锦鲤\nAK-47 | 火蛇",
                "len": 2,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Emerald Dragon"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/22d340a9430f86179ca3108f7b5b712e2b381245e42821b5b9b0d76da7eea5da.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 澄澈之水",
                "min": 0.1,
                "max": 0.22,
                "target_box": "set_bravo_i",
                "itemid": 1039,
                "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
                "len": 3,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Bright Water"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/a7b886a2858d0e7605b8ac36a9575b11e690446e9bcda8305e8ef2623f0d1a6f.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "MAC-10 | 不可磨灭",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1040,
                "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
                "len": 3,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mac10",
                "hash_name": "MAC-10 | Graven"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/6fb3f7adb02d9d4cbae13c223388b915e979215ab53f2b23d1ea812eec455b31.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A4 | 星级",
                "min": 0,
                "max": 0.42,
                "target_box": "set_bravo_i",
                "itemid": 1041,
                "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
                "len": 3,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Zirka"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/c70523f7f15ad216a7ec7d908301877da3f40f6d5be7079dfe5731cfa279a2da.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 疯狂蔓延",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1042,
                "list": "\nAWP | 石墨黑\nP2000 | 海之泡沫\nP90 | 翡翠之龙",
                "len": 3,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Overgrowth"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c4ac7ea0a6c831404852b53a858f31d6dbb5191b65ac7d40b259a9e17ee9ca77.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "G3SG1 | 丰饶女神",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1043,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_g3sg1",
                "hash_name": "G3SG1 | Demeter"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c88a946056ca45c050b42f4e5f0ce708a657964265eb9287794fc0f36b6ed216.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "UMP-45 | 白骨之堆",
                "min": 0,
                "max": 0.34,
                "target_box": "set_bravo_i",
                "itemid": 1044,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_ump45",
                "hash_name": "UMP-45 | Bone Pile"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/ca3ce3d08b2b4cad82a5f344d88956cd23665f95db6b646167086bc02a46cfd0.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "加利尔 AR | 支离破碎",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1045,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "hash_name": "Galil AR | Shattered"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/b7b81ddde14f2fa14c4b81e090bfe5578f81dc5d6c54492060d1a01b746c8d3b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "新星 | 兴风作浪",
                "min": 0,
                "max": 0.22,
                "target_box": "set_bravo_i",
                "itemid": 1046,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_nova",
                "hash_name": "Nova | Tempest"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/a0580aaa7998fd27488f1a93bbf9a0737bb7b980ed100460da27dd2fc67a8cce.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "双持贝瑞塔 | 黑榄仁木",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1047,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_elite",
                "hash_name": "Dual Berettas | Black Limba"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/543a73855b1a7cfa3495388d63e665d3b4364e67110b15074595d5578bc9df71.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 浪花喷漆",
                "min": 0,
                "max": 1,
                "target_box": "set_bravo_i",
                "itemid": 1048,
                "list": "\nM4A1 消音型 | 澄澈之水\nMAC-10 | 不可磨灭\nM4A4 | 星级\nUSP 消音版 | 疯狂蔓延",
                "len": 4,
                "box_value": "set_bravo_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Wave Spray"
            }
        ]
    },
    {
        "name": "电竞 2013 武器箱",
        "en_name": "eSports 2013 Case",
        "cover": "https://static.mobi2077.com/market-csgo/f725d574033978dc547331d9ae89627e1e233846caabc2cc7293dfbf030a0fc6.png",
        "type": 2,
        "box_value": "set_esports",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/0189c5f1a5933c1796a35d6e3143373cc596ac11a8d4c110da1c43dade320934.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "P90 | 喵之萌杀",
                "min": 0.08,
                "max": 0.32,
                "target_box": "set_esports",
                "itemid": 1049,
                "list": "",
                "len": 0,
                "box_value": "set_esports",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_p90",
                "hash_name": "P90 | Death by Kitty"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/152ff6ba2eb69e886a5997a85eb72de13c504e84d771a9eed36777060fdad1ae.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AWP | *嘣*",
                "min": 0.06,
                "max": 0.28,
                "target_box": "set_esports",
                "itemid": 1050,
                "list": "\nP90 | 喵之萌杀",
                "len": 1,
                "box_value": "set_esports",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | BOOM"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/4241013f899fd1c168c725de9d1d4c7fa0a49f55b5699ffc6ca319fe4c0c6d47.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 红色层压板",
                "min": 0.06,
                "max": 0.8,
                "target_box": "set_esports",
                "itemid": 1051,
                "list": "\nP90 | 喵之萌杀",
                "len": 1,
                "box_value": "set_esports",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Red Laminate"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/17e273defbcd70a6dbbfe0a9c0f39d1b4837b96528c268f5dc53ba441caf13f7.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "P250 | 狂野飞溅",
                "min": 0,
                "max": 0.18,
                "target_box": "set_esports",
                "itemid": 1052,
                "list": "\nAK-47 | 红色层压板",
                "len": 1,
                "box_value": "set_esports",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_p250",
                "hash_name": "P250 | Splash"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/9c2c8bf72473108bef5338b9ceaba5d65e53146c894c380d26172b4663b0ebd5.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "截短霰弹枪 | 橙黄 DDPAT",
                "target_box": "set_esports",
                "itemid": 1053,
                "list": "\nAK-47 | 红色层压板",
                "len": 1,
                "box_value": "set_esports",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_sawedoff",
                "min": 0,
                "max": 1,
                "hash_name": "Sawed-Off | Orange DDPAT"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/2fd76f377239391141aa6de3b22496b5aa8d0ead660d6668607ac300583ebd41.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "加利尔 AR | 橙黄 DDPAT",
                "target_box": "set_esports",
                "itemid": 1054,
                "list": "\nAK-47 | 红色层压板",
                "len": 1,
                "box_value": "set_esports",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_galilar",
                "min": 0,
                "max": 1,
                "hash_name": "Galil AR | Orange DDPAT"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/fd7d9a28bf2c4f4ea713d151c49f07d420cbe42f16b4f6b48649392399941498.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "法玛斯 | 厄运之喵",
                "min": 0.08,
                "max": 0.22,
                "target_box": "set_esports",
                "itemid": 1055,
                "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
                "len": 2,
                "box_value": "set_esports",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_famas",
                "hash_name": "FAMAS | Doomkitty"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c2f18c5af2347a00190dea5fd92ce62f4d9045498e7e301ac49a3a75fe47290d.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MAG-7 | 记忆碎片",
                "min": 0.02,
                "max": 0.18,
                "target_box": "set_esports",
                "itemid": 1056,
                "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
                "len": 2,
                "box_value": "set_esports",
                "type": "CSGO_Type_Shotgun",
                "weapon": "weapon_mag7",
                "hash_name": "MAG-7 | Memento"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/dd2898407e28e00185ae2b3f4b88e1a22446bc1e341ae1951cdb05add2a4e40b.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "M4A4 | 渐变斑纹",
                "min": 0,
                "max": 1,
                "target_box": "set_esports",
                "itemid": 1057,
                "list": "\nP250 | 狂野飞溅\n截短霰弹枪 | 橙黄 DDPAT",
                "len": 2,
                "box_value": "set_esports",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1",
                "hash_name": "M4A4 | Faded Zebra"
            }
        ]
    },
    {
        "name": "CS:GO 武器箱",
        "en_name": "CS:GO Weapon Case",
        "cover": "https://static.mobi2077.com/market-csgo/67c66758e2e7a0e22d702a947d3ec37776b4653ed1aa52bd338b75f92ce87ee1.png",
        "type": 2,
        "box_value": "set_weapons_i",
        "children": [
            {
                "rarity": "隐秘",
                "cover": "https://static.mobi2077.com/market-csgo/debb065e954292db77a4aeae50f183d5004d6e6668aaa129e5981afbc03c0b7a.png",
                "en_rarity": "Rarity_Ancient_Weapon",
                "name": "AWP | 雷击",
                "min": 0,
                "max": 0.08,
                "target_box": "set_weapons_i",
                "itemid": 1058,
                "list": "",
                "len": 0,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_awp",
                "hash_name": "AWP | Lightning Strike"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/539b3202f2351d6d512fca0fb78856f95acbd0153557729ebba70267a66a2756.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "沙漠之鹰 | 蛊惑之色",
                "min": 0,
                "max": 0.08,
                "target_box": "set_weapons_i",
                "itemid": 1059,
                "list": "\nAWP | 雷击",
                "len": 1,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_deagle",
                "hash_name": "Desert Eagle | Hypnotic"
            },
            {
                "rarity": "保密",
                "cover": "https://static.mobi2077.com/market-csgo/6de69fd9e4d1f2f9af4ba12de86488085b979de147451d2d5a19e08589fe8413.png",
                "en_rarity": "Rarity_Legendary_Weapon",
                "name": "AK-47 | 表面淬火",
                "min": 0,
                "max": 1,
                "target_box": "set_weapons_i",
                "itemid": 1060,
                "list": "\nAWP | 雷击",
                "len": 1,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_ak47",
                "hash_name": "AK-47 | Case Hardened"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/094b6ad4e253cce32d88cccd5a44e5f0697700b68720dc2fdd3cbb07c846aa99.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "M4A1 消音型 | 黑水",
                "min": 0.1,
                "max": 0.26,
                "target_box": "set_weapons_i",
                "itemid": 1061,
                "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
                "len": 2,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_m4a1_silencer",
                "hash_name": "M4A1-S | Dark Water"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/4231b473acd41c3d72ab199cb7fc3c44c1787c0d0124653f041cabd5caaf3155.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "USP 消音版 | 黑水",
                "min": 0.1,
                "max": 0.26,
                "target_box": "set_weapons_i",
                "itemid": 1062,
                "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
                "len": 2,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_usp_silencer",
                "hash_name": "USP-S | Dark Water"
            },
            {
                "rarity": "受限",
                "cover": "https://static.mobi2077.com/market-csgo/f49fda722cae0422dc3cd9264f82732d1a78ebae0e77173f233c4e9c5a09cb88.png",
                "en_rarity": "Rarity_Mythical_Weapon",
                "name": "格洛克 18 型 | 黑龙纹身",
                "min": 0,
                "max": 0.08,
                "target_box": "set_weapons_i",
                "itemid": 1063,
                "list": "\n沙漠之鹰 | 蛊惑之色\nAK-47 | 表面淬火",
                "len": 2,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Pistol",
                "weapon": "weapon_glock",
                "hash_name": "Glock-18 | Dragon Tattoo"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/c77e5468c2a225e557de1efbc011ebcfdad2e79e7a10ceab66beb907bce37f42.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "SG 553 | 致命紫罗兰",
                "min": 0,
                "max": 1,
                "target_box": "set_weapons_i",
                "itemid": 1064,
                "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
                "len": 3,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_sg556",
                "hash_name": "SG 553 | Ultraviolet"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/6528b637fc96e9739c12169769c6dbda0fe21b95631b5591060e532452bef189.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "AUG | 鹰翼",
                "min": 0,
                "max": 0.14,
                "target_box": "set_weapons_i",
                "itemid": 1065,
                "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
                "len": 3,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_Rifle",
                "weapon": "weapon_aug",
                "hash_name": "AUG | Wings"
            },
            {
                "rarity": "军规级",
                "cover": "https://static.mobi2077.com/market-csgo/3d0742fa94c4c7b4e5ad8797078995ea53af1859c9864a6652f0319c4c9be50f.png",
                "en_rarity": "Rarity_Rare_Weapon",
                "name": "MP7 | 死亡骷髅",
                "min": 0.1,
                "max": 0.26,
                "target_box": "set_weapons_i",
                "itemid": 1066,
                "list": "\nM4A1 消音型 | 黑水\nUSP 消音版 | 黑水\n格洛克 18 型 | 黑龙纹身",
                "len": 3,
                "box_value": "set_weapons_i",
                "type": "CSGO_Type_SMG",
                "weapon": "weapon_mp7",
                "hash_name": "MP7 | Skulls"
            }
        ]
    }
]

export { allCustomItemData, allCustomItemDataTree }
