import React, { useEffect, useState } from "react";
import './index.scss'
const matchList = [
  { token_type: 'sm_transaction', matchProperty: 'all_sale_amount', tip: '销售额' },
  { token_type: 'ma_transaction', matchProperty: 'all_sale_amount', tip: '销售额' },
]

function BonusTips(props) {
  const { userData, issuance } = props;
  const [tipList, setTipList] = useState([]);
  const [isInt, setIsInt] = useState(false);

  const getQuotaData = (token_type) => {
    let result = issuance.filter((item) => item.token_type === token_type)[0];
    return result;
  };

  useEffect(() => {
    if (isInt) {
      let arr = [];
      matchList.forEach(item => {
        let tipPre = getQuotaData(item.token_type);
        let needNum = tipPre?.to_start - ((userData?.[item.matchProperty] * 100) %
          (tipPre?.to_start * 100)) / 100;
        let reward = tipPre?.quantity;
        arr.push({ needNum: needNum.toFixed(2), reward, tip: item.tip })
      })
      setTipList(arr);
    } else {
      setIsInt(true);
    }
    //eslint-disable-next-line
  }, [issuance, userData]);

  return (
    Object.keys(userData).length !== 0 && <div className='bonus-tip-container'>
      {
        tipList?.[0] && tipList.map((item, index) => {
          if (!isNaN(item.needNum)) {
            return <p className='bonus-tip' key={index}>还需要完成￥{item.needNum}的{item.tip}即可获得{item.reward}个奖励代币</p>
          } else { return "" }
        })
      }
    </div>
  );
}

export default BonusTips;


