import React, { useEffect } from 'react';
import Balance from './Balance';
import PurseNav from './PurseNav'
import './index.scss';
import { connect } from "react-redux";

function MyPurse(props) {
    const { userInfo } = props
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
    }, [ userInfo ])
    return (
        <div className="my-purse">
            <Balance/>
            <PurseNav/>
        </div>
    );
    
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(MyPurse);
