import React, { $api, $login, $tools, antdUI, useEffect, useState } from 'react';
import PrizeItems from './detail/prizeItems.js'
import './index.scss'
import { connect } from "react-redux";
import { setUserSignIn, upDateUserAssets } from '@/store/actions/userInfo';

function RollRoomContent({ userInfo, userAssets, upDateUserAssets }) {
    const [ roomDetailData, setRoomData ] = useState({})
    const [ rollRoomItems, setRollRoomItems ] = useState([])
    const [ itemsTotal, setItemsTotal ] = useState(0)
    const [ joinLoading, setJoinLoading ] = useState(false)
    const [ curPage, setCurPage ] = useState(1)
    
    const [ bottomCurPage, setBtmCurPage ] = useState(1)
    const [ btmNavData, setBtmNavData ] = useState([
        { text: '参与人员', value: 'join' },
        { text: '中奖名单', value: 'prize' }
    ])
    const [ btmNavDataTotal, setBtmNavDataTotal ] = useState({ "join": 0, "prize": 0, "my": 0 })
    const [ btmInfoData, setBtnInfoData ] = useState([])
    const [ btmInfoTotal, setBtmInfoTotal ] = useState(0)
    
    const [ btmCur, setBtmCur ] = useState('join')
    
    const [ joinConditions, setJoinConditions ] = useState('')
    
    const [ countDay, setCountDay ] = useState(null)
    const [ countdownInfo, setCountdownInfo ] = useState({ hours: "0", minutes: "0", seconds: "0" })
    
    const [ inputMobiModalView, setModalShow ] = useState(false)
    const [ cost, setCost ] = useState()
    const [ modalBtnLoading, setModalBtnLoading ] = useState(false)
    
    const pageSize = 200
    const joinPageSize = 20
    const rollRoomId = $tools.getUrlQueryValue('roomID')
    if (! rollRoomId) window.location.href = '/home'
    const getDetailData = async () => {
        const result = await $api.rollRoom.getRollRoomDetail({ rollRoomId })
        if ($tools.checkResult(result)) {
            const data = result.data
            setRoomData(data)
            setJoinConditions(`${ data.minInstantBalance > 0 ? `当前持有MOE最小数量 ${ data.minInstantBalance }` : "" }${ data.promoterId ? `，限定推广人` : "" }`)
            
            if ([ 1, 2 ].indexOf(data.state) === -1) return
            
            const endDate = new Date(data.endTime?.replace(/-/g, '/')).getTime()
            let curDate = new Date().getTime();
            const day = ~~((endDate - curDate) / 1000 / 60 / 60 / 24)
            if ((endDate - curDate) <= 0) return setCountDay(-1)
            setCountDay(day)
            let timer
            
            function _countdown() {
                curDate = new Date().getTime();
                const countdown = (endDate - curDate) / 1000
                if (countdown <= 0) clearInterval(timer)
                const hours = String(Math.floor(countdown / 60 / 60 % 24))
                const minutes = String(Math.floor(countdown / 60 % 60))
                const seconds = String(Math.floor(countdown % 60))
                setCountdownInfo({ hours, minutes, seconds })
            }
            
            if (day === 0) {
                _countdown()
                timer = setInterval(_ => _countdown(), 1000)
            }
        }
    }
    
    const getRoomItems = async curPage => {
        setRollRoomItems([])
        const result = await $api.rollRoom.getRollRoomDetailItems({ rollRoomId, curPage, pageSize })
        if ($tools.checkResult(result)) {
            setRollRoomItems(result.data.data)
            setItemsTotal(result.data.total)
        }
    }
    
    const getRollRoomDetailJoin = async (bottomCurPage, btmCur) => {
        setBtnInfoData([])
        let tApiTarget
        switch (btmCur) {
            case 'join':
                tApiTarget = $api.rollRoom.getRollRoomDetailJoin;
                break;
            case 'prize':
                tApiTarget = $api.rollRoom.getRollRoomDetailPrize;
                break;
            case 'my':
                tApiTarget = $api.rollRoom.getRollRoomDetailMyPrize;
                break;
            default:
                return;
        }
        const result = await tApiTarget({
            rollRoomId,
            curPage: bottomCurPage,
            pageSize: joinPageSize
        })
        if ($tools.checkResult(result)) {
            setBtnInfoData(result.data.data)
            setBtmInfoTotal(result.data.total)
            let _btmNavDataTotal = JSON.parse(JSON.stringify(btmNavDataTotal))
            _btmNavDataTotal[btmCur] = result.data.total
            setBtmNavDataTotal(_btmNavDataTotal)
        }
    }
    
    useEffect(() => {
        getDetailData().then(r => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(_ => {
        getRoomItems(curPage).then(r => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ curPage ])
    
    useEffect(_ => {
        getRollRoomDetailJoin(bottomCurPage, btmCur).then(r => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bottomCurPage, btmCur ])
    
    useEffect(_ => {
        if (userInfo.uid) {
            ! btmNavData.filter(_ => _.value === 'my')[0] && setBtmNavData([ ...btmNavData,
                { text: '我的中奖', value: 'my' }
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userInfo ])
    
    const handleRoomStateText = (data) => {
        let text = ''
        const { state } = data
        switch (Number(state)) {
            case 0:
                text = <span className="roll-state" style={ {
                    color: '#fff', backgroundColor: "rgba(255, 255, 255, 0.41)",
                    border: '1px solid rgba(255, 255, 255, 1)'
                } }>未开始</span>;
                break;
            case 1:
                text =
                    <span className="roll-state" style={ {
                        color: '#fff',
                        backgroundColor: "rgba(0, 255, 126, 0.41)",
                        border: '1px solid rgba(0, 255, 126, 1)'
                    } }>已开始</span>;
                break;
            case 2:
                text = <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(141, 80, 66, 0.41)",
                    border: '1px solid rgba(141, 80, 66, 1)'
                } }>已满</span>;
                break;
            case 3:
                text = <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(221, 61, 27, 0.41)",
                    border: '1px solid rgba(221, 61, 27, 1)'
                } }>已失效</span>;
                break;
            case 4:
                text = <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(221, 61, 27, 0.41)",
                    border: '1px solid rgba(221, 61, 27, 1)'
                } }>已取消</span>;
                break;
            case 5:
                text = <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(255, 140, 0, 0.41)",
                    border: '1px solid rgba(255, 140, 0, 1)'
                } }>已结束</span>;
                break;
            default:
                text = <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(255, 140, 0, 0.41)",
                    border: '1px solid rgba(255, 140, 0, 1)'
                } }>未知</span>;
                break;
        }
        
        return text
    }
    
    const joinRoom = async () => {
        setJoinLoading(true)
        const result = await $api.rollRoom.joinRollRoom({ rollRoomId: roomDetailData.id })
        if ($tools.checkResult(result)) {
            antdUI.message.success('加入成功！')
            getDetailData().then(r => {})
        }
        setJoinLoading(false)
    }
    const joinWelfareRoom = async () => {
        if (! Number.isInteger(cost) || cost <= 0) return antdUI.message.warning('输入的代币值有误！')
        if (userAssets.mobi_tokens < cost) return antdUI.message.warning('账户MOE数量不足！')
        setModalBtnLoading(true)
        let tApi = roomDetailData.isJoin === 1 ? $api.rollRoom.inputWelfareRollRoom : $api.rollRoom.joinWelfareRollRoom
        const result = await tApi({ rollRoomId: roomDetailData.id, cost })
        if ($tools.checkResult(result)) {
            antdUI.message.success(`${ roomDetailData.isJoin === 1 ? '投入' : '加入' }成功！`)
            setModalShow(false)
            getDetailData().then(r => {})
            upDateUserAssets()
            setCost('')
        }
        setModalBtnLoading(false)
    }
    
    const WELFARE = require('@/images/rollRoom/welfare.png')
    const MOBI_UNLOCK_QUALIFIED = require('@/images/rollRoom/mobi_unlock_qualified.png')
    return (
        <div className="roll-room-content">
            <div className="roll-room-detail-container">
                <h3>ROLL房详情</h3>
                <div className="detail-title-box">
                    <div className="left-info">
                        <div className="avatar"
                             style={ { backgroundImage: `url(${ roomDetailData?.AdminAccount?.avatarfull })` } }></div>
                        <div className="room-title"
                             style={ { position: 'relative', top: roomDetailData.categories === 'welfare' && '25px' } }>
                            <h2>{ roomDetailData.name }</h2>
                            <div className="roll-title-info-box">
                                { handleRoomStateText(roomDetailData) }
                                <span className="roll-id">房间号：{ roomDetailData.id }</span>
                                
                                { roomDetailData.categories !== 'welfare' && <p className="join-conditions">
                                    <span>房间可加入人数 <span
                                        style={ { color: "rgba(0, 255, 126, 1)" } }>{ roomDetailData.minPeopleNumber }</span> ~
                                        <span
                                            style={ { color: "rgba(255, 140, 0, 1)" } }> { roomDetailData.maxPeopleNumber }</span>
                                    </span>
                                </p> }
                                { roomDetailData.categories !== 'welfare' ? joinConditions &&
                                    <p className="join-conditions flex">加入条件:
                                        <span className="text-ov1"
                                              style={ { display: 'block', width: "85%" } }>{ joinConditions }</span>
                                    </p> : "" }
                                <h4 style={ { marginTop: roomDetailData.categories === 'welfare' && '18px' } }>
                                    
                                    
                                    { roomDetailData.categories === 'welfare' ? <>购物金<span> ¥{ roomDetailData.bonusTotal }</span></> :
                                        <>共<span>{ roomDetailData?.roomItemCount || 0 }</span>件奖品<span></span></>
                                    }
                                </h4>
                                <div className="describe-box">
                                    <p className={ [ roomDetailData.categories === 'welfare' ? "text-ov2" : 'text-ov1' ] }>房间说明：{ roomDetailData.describe }</p>
                                    <antdUI.Popover
                                        placement="right"
                                        content={ (
                                            <div className="apikey-box-text" style={ {
                                                padding: '14px', width: '260px', backgroundColor: '#191919',
                                                boxShadow: `0px 0px 6px 1px #191919`
                                            } }>
                                                <p className="title" style={ {
                                                    fontSize: '14px', color: '#8c9298', marginBottom: '8px'
                                                } }>房间说明：</p>
                                                <p style={ {
                                                    fontSize: '14px', color: '#fff',
                                                } }> { roomDetailData.describe }</p>
                                            </div>
                                        ) }
                                    >
                                        <i style={ { color: "#999999" } } className="iconfont icon-xiaoxi"></i>
                                    </antdUI.Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-info">
                        <div className="room-countdown" style={ { justifyContent: "end" } }>
                            {
                                roomDetailData.categories !== 'welfare' && roomDetailData.endTime ? countDay >= 1 ?
                                    <h2>距离房间开奖还剩{ countDay }天</h2> : countDay === 0 &&
                                    <div className="countdown-box flex ait">
                                        <div
                                            className="date-line">{ countdownInfo.hours.length > 1 ? countdownInfo.hours[0] : 0 }</div>
                                        <div
                                            className="date-line">{ countdownInfo.hours.length > 1 ? countdownInfo.hours[1] : countdownInfo.hours[0] }</div>
                                        <p className="date-colon">:</p>
                                        <div
                                            className="date-line">{ countdownInfo.minutes.length > 1 ? countdownInfo.minutes[0] : 0 }</div>
                                        <div
                                            className="date-line">{ countdownInfo.minutes.length > 1 ? countdownInfo.minutes[1] : countdownInfo.minutes[0] }</div>
                                        <p className="date-colon">:</p>
                                        <div
                                            className="date-line">{ countdownInfo.seconds.length > 1 ? countdownInfo.seconds[0] : 0 }</div>
                                        <div
                                            className="date-line">{ countdownInfo.seconds.length > 1 ? countdownInfo.seconds[1] : countdownInfo.seconds[0] }</div>
                                    </div> : ''
                            }
                            
                            {
                                roomDetailData.categories === 'welfare' && roomDetailData.mainCost &&
                                <div className="flex ait" style={ { color: "#fff" } }>
                                    <h2 className="flex jtyct ait"
                                        style={ {
                                            color: "#fff", padding: "0 12px",
                                            marginLeft: "10px", fontWeight: "bold",
                                            height: "44px", border: "1px solid #929292",
                                            background: "linear-gradient(3deg, #272726 0%, #3E3D3D 100%)",
                                            borderRadius: "4px"
                                        } }
                                    >你已投入MOB数：{ roomDetailData.mainCost }</h2>
                                </div>
                            }
                        </div>
                        
                        <p className="room-activity-time">
                            {
                                (roomDetailData.categories !== 'welfare' || roomDetailData.type !== 'MOBI解锁额度限定普通房') ?
                                    <> 活动时间：{ roomDetailData.startTime } —— { roomDetailData.endTime }</> :
                                    <> 活动开始时间：{ roomDetailData.startTime }</>
                            }
                        </p>
                        <div className="join-btn flex"
                             style={ { justifyContent: 'end' } }>
                            { roomDetailData?.id && <antdUI.Button
                                onClick={ _ => {
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo.uid) return $login()
                                    if (roomDetailData.categories === "welfare") {
                                        setModalShow(true)
                                    } else {
                                        joinRoom().then()
                                    }
                                } }
                                loading={ joinLoading }
                                disabled={
                                    roomDetailData.state !== 1
                                    || (roomDetailData.categories !== 'welfare' ?
                                        roomDetailData.isJoin === 1 : Number(roomDetailData.allInput) === Number(roomDetailData.mobiJoinTotal))
                                }
                                style={ {
                                    borderRadius: '8px', width: '283px',
                                    height: '67px', fontSize: "24px", fontWeight: 'bold'
                                } }
                                type="primary"> { roomDetailData.isJoin === 1 ?
                                roomDetailData.categories !== 'welfare' ?
                                    "已参与" : Number(roomDetailData.allInput) === Number(roomDetailData.mobiJoinTotal) ? "已投满" : "继续投入MOE"
                                : "立即参与" }
                            </antdUI.Button> }
                        </div>
                    </div>
                    
                    { roomDetailData.categories === 'welfare' && <div
                        style={ { backgroundImage: `url(${ WELFARE })` } }
                        className="progress-logo-box WELFARE flex">
                        <div className="progress-line flex" style={ { marginRight: "14px" } }>
                            <span>玩家投入MOE总数</span>
                            <div className="progress-bar-container flex ait">
                                { (() => {
                                    const bl = roomDetailData.allInput / roomDetailData.mobiJoinTotal * 100
                                    return <div className="progress-bar">
                                        
                                        <div style={ {
                                            width: `${ bl }%`,
                                            backgroundColor: Number(roomDetailData.allInput) === Number(roomDetailData.mobiJoinTotal) && "#4CC04F"
                                        } } className='bar-main'></div>
                                    </div>
                                })() }
                                <span className="progress-bar-val"
                                      style={ { color: Number(roomDetailData.allInput) === Number(roomDetailData.mobiJoinTotal) && "#fff" } }>
                                    { roomDetailData.allInput }/{ roomDetailData.mobiJoinTotal }
                                </span>
                            </div>
                        </div>
                        <div className="progress-line flex" style={ { marginRight: "14px" } }>
                            <span>网站已解锁MOE</span>
                            <div className="progress-bar-container flex ait">
                                { (() => {
                                    const bl = roomDetailData.allUnlockToken / roomDetailData.unlockMobiTotal * 100
                                    return <div className="progress-bar">
                                        <div style={ {
                                            width: `${ bl }%`,
                                            backgroundColor: Number(roomDetailData.allUnlockToken) >= Number(roomDetailData.unlockMobiTotal) && "#4CC04F"
                                        } } className='bar-main'></div>
                                    </div>
                                })() }
                                <span className="progress-bar-val"
                                      style={ { color: Number(roomDetailData.allUnlockToken) >= Number(roomDetailData.unlockMobiTotal) && "#fff" } }>
                                    { roomDetailData.allUnlockToken }/{ roomDetailData.unlockMobiTotal }
                                </span>
                            </div>
                        </div>
                        <img className="mobi-icon" src={ require("@/images/rollRoom/mobi bi@2x.png") } alt=""/>
                    </div> }
                    
                    {
                        roomDetailData.type === 'MOBI解锁额度限定普通房' && <div
                            style={ { backgroundImage: `url(${ MOBI_UNLOCK_QUALIFIED })` } }
                            className="progress-logo-box MOBI_UNLOCK_QUALIFIED flex">
                            <div className="progress-line flex" style={ { marginRight: "14px" } }>
                                <span>网站已解锁MOE</span>
                                <div className="progress-bar-container flex ait">
                                    { (() => {
                                        const bl = roomDetailData.allUnlockToken / roomDetailData.unlockMobiTotal * 100
                                        return <div className="progress-bar"
                                                    style={ {
                                                        width: `${ bl }%`,
                                                        borderRadius: bl < 20 && "9px 0 0 9px",
                                                        backgroundColor: Number(roomDetailData.allUnlockToken) >= Number(roomDetailData.unlockMobiTotal) && "#4CC04F"
                                                    } }></div>
                                    })() }
                                    <span className="progress-bar-val"
                                          style={ { color: Number(roomDetailData.allUnlockToken) >= Number(roomDetailData.unlockMobiTotal) && "#fff" } }>
                                    { roomDetailData.allUnlockToken }/{ roomDetailData.unlockMobiTotal }
                                </span>
                                </div>
                            </div>
                            <img className="mobi-icon" src={ require("@/images/rollRoom/mobi bi@2x.png") } alt=""/>
                        </div>
                    }
                </div>
                
                <h3>奖品列表</h3>
                { roomDetailData?.categories === 'welfare' &&
                    <div style={ { backgroundImage: `url(${ require('@/images/rollRoom/xianjinjiangli@2x.png') })` } }
                         className="welfare-info-container">
                        <div className="bonus-total-container flex jtyct">
                            <h3>¥</h3>
                            <h1>{ roomDetailData.bonusTotal }</h1>
                        </div>
                    </div> }
                
                { roomDetailData?.categories !== 'welfare' && rollRoomItems[0] &&
                    <PrizeItems prizeData={ rollRoomItems }></PrizeItems> }
                
                { itemsTotal > 0 &&
                    <div className="rollRoom-items-pagination flex jtyct ait" style={ { marginTop: '30px' } }>
                        <antdUI.Pagination
                            hideOnSinglePage={ true }
                            onChange={ (currentPage, pageSize) => setCurPage(currentPage) }
                            showSizeChanger={ false } current={ curPage } defaultCurrent={ 1 }
                            total={ itemsTotal } pageSize={ pageSize }/>
                    </div> }
            </div>
            
            <div className="roll-room-detail-bottom-info">
                <div className="bottom-info-nav">
                    {
                        btmNavData.map(_ => {
                            return (
                                <p onClick={ () => setBtmCur(_.value) }
                                   className={ [ btmCur === _.value && 'current' ] }
                                   key={ _.value }>{ _.text }{ btmNavDataTotal[_.value] > 0 && `(${ btmNavDataTotal[_.value] })` }</p>
                            )
                        })
                    }
                </div>
                <div className="bottom-info-container"
                     style={ { padding: btmCur === 'join' && '12px', background: btmCur === 'join' && '#fff' } }>
                    {
                        (_ => {
                            switch (btmCur) {
                                case 'join':
                                    return <div className="bottom-info-item-box bottom-info-join">
                                        { btmInfoData[0] && btmInfoData.map(({ User, uid }, i) => {
                                            return <div key={ i } className="bottom-info-item">
                                                <img
                                                    src={ User?.UserSteam?.avatarfull || require('@/images/home/moren touxiang@2x.png') }
                                                    alt=""/>
                                                <p className="text-ov1">{ User?.nickname }</p>
                                            </div>
                                        }) }
                                    </div>
                                case 'prize':
                                    return <> { btmInfoData[0] &&
                                        <PrizeItems prizeData={ btmInfoData } type={ "prize" }></PrizeItems> }</>
                                case 'my':
                                    return <> { btmInfoData[0] &&
                                        <PrizeItems prizeData={ btmInfoData } type={ "my" }></PrizeItems> }</>
                                default:
                                    break;
                            }
                        })()
                    } { btmInfoTotal > 0 &&
                    <div className="rollRoom-items-pagination flex jtyct ait"
                         style={ { marginTop: '20px', paddingBottom: btmCur === 'prize' && '20px' } }>
                        <antdUI.Pagination
                            hideOnSinglePage={ true }
                            onChange={ (currentPage, pageSize) => setBtmCurPage(currentPage) }
                            showSizeChanger={ false } current={ bottomCurPage } defaultCurrent={ 1 }
                            total={ btmInfoTotal } pageSize={ joinPageSize }/>
                    </div> }
                </div>
            </div>
            
            <antdUI.Modal
                title={ `投入MOE (当前持有MOE数量: ${ userAssets.mobi_tokens })` }
                visible={ inputMobiModalView }
                onCancel={ _ => {
                    setCost('')
                    setModalShow(false)
                } }
                footer={ [
                    <antdUI.Button key={ 1 } onClick={ _ => {
                        setCost('')
                        setModalShow(false)
                    } }>
                        取消
                    </antdUI.Button>,
                    <antdUI.Button key={ 2 } type="primary"
                                   loading={ modalBtnLoading }
                                   onClick={ _ => joinWelfareRoom().then() }>
                        确认投入
                    </antdUI.Button>
                ] }
                maskClosable={ false }>
                <antdUI.Input
                    style={ { marginTop: '10px' } }
                    value={ cost }
                    onChange={ e => {
                        let val = e.target.value.replace(/\D/g, "")
                        setCost(Number(val))
                    } }
                    placeholder={ `请输入投入MOE数量` }/>
                <h3 style={ { marginTop: '20px' } }>投入MOE数量必须为整数。福利房现金分配比例与投入MOE数量成正比，用户投入更多MOE就可以获得更多现金奖励。</h3>
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
            userAssets: state.user.userAssets
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
            upDateUserAssets: () => disatch(upDateUserAssets()),
        }
    }
)(RollRoomContent);

