import React, { $api, $tools, antdUI, Component } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';

class BasicInfo extends Component {
    state = {
        visible: false,
        newName: ''
    }
    showModal = () => {
        this.setState({
            visible: true,
            newName: ''
        });
    };
    handleOk = async () => {
        const newName = this.state.newName;
        if (newName.length < 1 || newName.length > 14) return antdUI.message.warning("昵称长度需要在1到14位之间！");
        let res = await $api.user.updateNickname({ nickname: this.state.newName });
        if ($tools.checkResult(res)) {
            res.status === 200 && antdUI.message.success("修改成功！")
            this.props.setUserSignIn();
            this.setState({
                visible: false,
            });
            
        }
    };
    delayHandleOk = $tools.debounce(this.handleOk, 2000, true);
    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    inputChange = (e) => {
        // eslint-disable-next-line
        let name = e.target.value.replace(/[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g, "");
        this.setState({
            newName: name
        })
    }
    
    updateAvatar = async () => {
        let res = await $api.steam.updateSteamAvatar()
        if ($tools.checkResult(res)) {
            this.props.setUserSignIn();
        }
    }
    
    render() {
        const nickname = this.props.userInfo.nickname || this.props.userInfo?.steam?.personaname;
        const userLevel = this.props.userInfo?.level
        return (
            <div className="basic-info">
                <div className="title">
                    <h1>账号基本设置</h1>
                </div>
                
                <div className="content">
                    <div className="avatar">
                        { this.props.userInfo?.steam?.steamid ?
                            <antdUI.Avatar shape="square" size={ 165 }
                                // onClick={ _ => this.updateAvatar() }
                                           src={ this.props.userInfo?.steam?.avatarfull }/> :
                            <antdUI.Avatar shape="square" size={ 165 }
                                           src={ require('@/images/home/moren touxiang@2x.png') }/>
                        }
                    </div>
                    <div className="main-info">
                        <div className="user-name">
                            <antdUI.Popconfirm
                                title={ nickname } icon={ false }
                            ><p> { nickname }</p>
                            </antdUI.Popconfirm>
                            
                            <i onClick={ _ => {
                                if (! this.props.userInfo.uid) return antdUI.message.error('请先绑定手机号')
                                this.showModal()
                            } } style={ { cursor: 'pointer', color: 'orange' } } className="iconfont icon-pen-solid"/>
                        </div>
                        <p style={ { fontSize: '16px', display: "inline-block" } }>用户等级</p>
                        <img src={ $tools.levelIcon(userLevel?.levelVal) } alt=""/>
                        <div className="grade">
                            <div className="user-level-box">
                                <p className="user-level">LV { userLevel?.levelVal || 0 }</p>
                                <p style={ { paddingLeft: '5px' } }> { $tools.levelText(userLevel?.levelVal) }</p>
                            </div>
                            <div className="experience">
                                <div className="exper-bar">
                                  <span
                                      style={ { width: `${ this.props.userInfo?.level?.start_level / this.props.userInfo?.level?.end_level * 100 }%` } }
                                      className="exper-val">
                                  </span>
                                </div>
                                {/*{ (this.props.userInfo.uid || this.props.userInfo.steam.steamid) ?*/ }
                                {/*    <p style={ { color: '#000' } }>{ `${ (this.props.userInfo?.level?.start_level / this.props.userInfo?.level?.end_level * 100).toFixed(2) }%` }</p>*/ }
                                {/*    : '' }*/ }
                                { (this.props.userInfo.uid || this.props.userInfo.steam.steamid) ?
                                    <p style={ { color: '#000' } }>  { userLevel && Math.round(userLevel?.experience) }/{ Math.round(Number(userLevel?.end_level) + Number(userLevel?.experience)) }</p>
                                    : '' }
                            </div>
                        </div>
                        
                        <div className="more-title ait">
                            { this.props.userInfo?.reputationPoints >= -1 &&
                                <img src={ $tools.getCredibilityIcon(this.props.userInfo?.reputationPoints) } alt=""/> }
                            {/* {`信誉: ${this.props.userInfo.reputationPoints ? this.props.userInfo?.reputationPoints : 0}分`} */ }
                        </div>
                    </div>
                </div>
                
                <antdUI.Modal
                    title="修改用户名"
                    visible={ this.state.visible }
                    onOk={ this.delayHandleOk }
                    onCancel={ this.handleCancel }
                    okText="确认"
                    bodyStyle={ { minHeight: '180px' } }
                    cancelText="取消"
                    maskClosable={ false }
                >
                    <antdUI.Input
                        autoFocus
                        value={ this.state.newName }
                        onKeyDown={ _ => _.keyCode === 13 && this.delayHandleOk() }
                        onChange={ this.inputChange }
                        minLength={ 2 }
                        maxLength={ 14 }
                        placeholder="请输入用户名"/>
                    <br/><br/>
                    <p style={ { color: '#2DAF61' } }>昵称必须在1到14个字符以内，可以包含数字、英文、中文，三个月内只能修改一次。(请勿带有辱骂、广告、诱导等其他违反法规的词汇，违者帐号将被封禁处理)</p>
                </antdUI.Modal>
            </div>
        )
    }
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(BasicInfo);
