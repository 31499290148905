import React, { antdUI } from "react";
import './customItemBox.scss'
import CustomItemBox from './customItemBox.js'
import './index.scss'

function ReplacingAnalogCustomItem(props) {
    const {
        allCustomItemDataTree,
        replacAnaData,
        quality,
        materialOp: { materialData, setMaterialData },
    } = props
    
    return (
        <div id="ReAnCustomItem-Box">
            {
                replacAnaData[0] && replacAnaData.map((_, i) => {
                    const targetBox = allCustomItemDataTree.filter(_ti => {
                        return _ti.box_value === _.target_box
                    })?.[0]
                    // const targetBoxTopData =
                    //     targetBox?.children.filter(el => el.en_rarity === levelControl[_.en_rarity])
                    // let list = '', listName = '', len = 0;
                    // targetBoxTopData && targetBoxTopData.forEach(_ => {
                    //     listName = (quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name)
                    //     if (! list.match(listName)) {
                    //         list += '\n' + listName;
                    //         len += 1;
                    //     }
                    // })
                    return (
                        <div key={ i } className="reAnCustom-item" onClick={ () => {
                            if (_.len === 0) return antdUI.message.warning('该物品无法用于汰换！')
                            if (materialData[0] && _.en_rarity !== materialData[0].en_rarity) return antdUI.message.warning(`您当前只能选择"${ materialData[0].rarity }"品质的饰品进行汰换模拟！`)
                            if (materialData.length >= 10) return antdUI.message.warning('最多选择10件物品进行汰换！')
                            let _tData = JSON.parse((JSON.stringify(materialData)));
                            // if (! materialData[0]) setRarity([ _.en_rarity ])
                            setMaterialData([ ..._tData, {
                                ...JSON.parse(JSON.stringify(_)),
                                targetBoxName: targetBox?.name,
                                wearVal: (1 + _.min * 1000) / 1000
                            } ])
                        } }>
                            <CustomItemBox materialOp={ { materialData, setMaterialData } } quality={ quality }
                                           itemData={ _ }
                                           targetBox={ { targetBoxName: targetBox?.name, list: _.list, len: _.len } }/>
                            <p title={ quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                               className="reAnCustom-item-name">
                                { quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ReplacingAnalogCustomItem
