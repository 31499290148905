import React, { $api, $tools, antdUI, $copy, useEffect, useState } from 'react';
import './index.scss'

const weaponType = {
    CSGO_Type_Knife: "匕首",
    CSGO_Type_Pistol: "手枪",
    CSGO_Type_Rifle: "步枪",
    CSGO_Type_SniperRifle: "狙击步枪",
    CSGO_Type_SMG: "微型冲锋枪",
    CSGO_Type_Shotgun: "散弹枪",
    CSGO_Type_Machinegun: "机枪",
    Type_Hands: "手套",
    CSGO_Tool_Sticker: "印花"
}
const rarityType = {
    Rarity_Mythical: "卓越",
    Rarity_Ancient_Weapon: "隐秘",
    Rarity_Legendary_Weapon: "保密",
    Rarity_Mythical_Weapon: "受限",
    Rarity_Rare: "高级",
    Rarity_Ancient: "非凡",
    Rarity_Rare_Weapon: "军规级",
    Rarity_Uncommon_Weapon: "工业级",
    Rarity_Common_Weapon: "消费级",
    Rarity_Legendary: "奇异",
    Rarity_Common: "普通级",
    Rarity_Contraband: "违禁",
    Type_CustomPlayer: "探员品质"
}
const qualityType = {
    normal: "普通",
    strange: "StatTrak™",
    unusual: "★",
    unusual_strange: "★ StatTrak™",
    tournament: "纪念品"
}
const knifeStyle = {
    front: `rotateZ(-90deg) scale(2.5)`,
    back: `rotateZ(90deg) scale(2.5)`
}

const TalonStyle = {
    front: `rotateZ(-68deg) scale(2.5)`,
    back: `rotateZ(68deg) scale(2.5)`
}

function getScrollOffset() {
    const oModal = document.getElementsByClassName('ant-modal-wrap')[0]
    return { left: oModal?.scrollLeft, top: oModal?.scrollTop }
}

function CsgoItems2DModal({ view = false, setView, data, marketData }) {
    const img = false
    const { type, quality, rarity, market_name, weapon } = marketData || data
    const { floatvalue, paintindex, paintseed, stickers } = marketData || data?.market_item_detail?.msg
    const [ scaleVal, setScaleVal ] = useState(2.25)
    let { back, front, full } = marketData?.screenshot || data?.market_item_detail?.msg?.screenshot?.result
    const options = { width: 150, height: 150, borderRadius: false }
    let coverOpW, knifeR, TalonR;
    if ([ 'weapon_bizon', 'weapon_nova', 'weapon_xm1014', 'weapon_sawedoff', 'weapon_m249', 'weapon_negev', 'weapon_m4a1_silencer', 'weapon_ak47',
        'weapon_awp', 'weapon_ssg08', 'weapon_scar20', 'weapon_g3sg1' ].includes(weapon)) coverOpW = true;
    if (type === 'CSGO_Type_Knife' && weapon !== 'weapon_knife_push') knifeR = true
    if ([ 'weapon_knife_widowmaker', 'weapon_knife_karambit' ].includes(weapon)) TalonR = true
    
    const againResolve = async _ => {
        const result = await $api.steam.againMapsViews({
            url: data?.actions?.[0]?.link || marketData?.actionsLink, view: 2
        })
        if ($tools.checkResult(result)) {
            antdUI.message.success('检视图生成中，请耐心等待!')
            setTimeout(_ => setView(false), 700)
        }
    }
    
    useEffect(() => {
        if (img) return
        setTimeout(_ => {
            const oImg = document.querySelector('.csgo-items-2D-Modal-container'),
                oCoverC = oImg.querySelector('.cover-container-2d'),
                scale = document.querySelector('.csgo-items-2D-Modal-container .scale'),
                sImg = document.querySelector('.csgo-items-2D-Modal-container .scale .csgo-items-2D-Modal-box');
            oCoverC.addEventListener('mouseover', function (e) {
                let nT = e.clientY - oImg.getElemDocPosition().top + getScrollOffset().top - options.height / 2,
                    nL = e.clientX - oImg.getElemDocPosition().left + getScrollOffset().left - options.width / 2;
                scale.className = "scale s";
                scale.style.top = nT + "px";
                scale.style.left = nL + "px";
                sImg.style.top = -nT + "px";
                sImg.style.left = -nL + "px";
                document.addEventListener('mousemove', scales, false);
            }, false)
            
            function scales(e) {
                let nT = e.clientY - oImg.getElemDocPosition().top + getScrollOffset().top,
                    nL = e.clientX - oImg.getElemDocPosition().left + +getScrollOffset().left,
                    tT = nT - options.height / 2,
                    tL = nL - options.width / 2;
                scale.style.top = tT + "px";
                scale.style.left = tL + "px";
                sImg.style.top = -tT + "px";
                sImg.style.left = -tL + "px";
                // 690 含印花
                if (nT < 65 || nT > 585 || nL < 0 || nL > 751) scale.className = "scale";
            }
        }, 300)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            { img ? <antdUI.Image
                style={ { display: 'none' } } src={ full } preview={ {
                visible: view, onVisibleChange: (value) => setView(value)
            } }
            /> : <antdUI.Modal
                className="modal-2d" title="2D检视图"
                visible={ view } onCancel={ _ => setView(false) } width={ '751px' }
                maskClosable={ false } footer={ null }>
                <div className="csgo-items-2D-Modal-container">
                    <div className="csgo-items-2D-Modal-box">
                        <div className="slider-scale">
                            <antdUI.Slider min={ 150 } max={ 500 } value={ scaleVal * 100 }
                                           onChange={ value => setScaleVal(value / 100) }
                                           tipFormatter={ value => `${ value / 100 }倍` }/>
                        </div>
                        <antdUI.Button type={ "primary" } onClick={ $tools.debounce(againResolve, 2000, true) }
                                       className={ "again-resolve" }>重新解析
                        </antdUI.Button>
                        <div className="cover-container-2d">
                            <div className="front-cover" style={ { marginTop: TalonR && '50px' } }>
                                <img style={ {
                                    width: coverOpW && '100%',
                                    height: coverOpW && 'auto',
                                    transform: (TalonR && TalonStyle.front) || (knifeR && knifeStyle.front)
                                } }
                                     className="weapon-cover"
                                     src={ front } alt=""/>
                            </div>
                            <div className="back-cover">
                                <img style={ {
                                    width: coverOpW && '100%',
                                    height: coverOpW && 'auto',
                                    transform: (TalonR && TalonStyle.back) || (knifeR && knifeStyle.back)
                                } }
                                     className="weapon-cover"
                                     src={ back } alt=""/>
                            </div>
                            
                            { stickers && stickers[0] && (
                                <div className="sticker sticker-2d">
                                    { stickers.map((item, idx) => {
                                        return (
                                            <div
                                                title={ `${ item.name }，磨损：${ item.wear ? `${ Number(~~((1 - item.wear) * 10000) / 100).toFixed(2) }%` : '100%' }` }
                                                key={ idx } className="sticket-icon">
                                                <img
                                                    style={ { filter: "contrast(0.756504)" } }
                                                    src={ `https://www.csgola.com/Public/img/csgo_stickers/${ item.material }.png` }
                                                    alt=""
                                                />
                                            </div>
                                        );
                                    }) }
                                </div>
                            ) }
                        </div>
                        <div className="bottom-info-box">
                            <h2 style={ { marginBottom: '24px' } }>{ market_name }</h2>
                            <div className="info-box">
                                <div className="att-val">
                                    <div className="att"><p>磨</p><p>损</p><p>值</p></div>
                                    <p className="val">{ floatvalue }</p>
                                </div>
                                <div className="att-val">
                                    <div className="att"><p>类</p><p>型</p></div>
                                    <p className="val">{ weaponType[type] }</p>
                                </div>
                                <div className="att-val">
                                    <div className="att"><p>类</p><p>别</p></div>
                                    <p className="val">{ qualityType[quality] }</p>
                                </div>
                                <div className="att-val">
                                    <div className="att"><p>品</p><p>质</p></div>
                                    <p className="val">{ rarityType[rarity] }</p>
                                </div>
                                <div className="att-val">
                                    <div className="att"><p>皮</p><p>肤</p><p>编</p><p>号</p></div>
                                    <p className="val">{ paintindex }</p>
                                </div>
                                <div className="att-val">
                                    <div className="att"><p>图</p><p>案</p><p>模</p><p>版</p></div>
                                    <p className="val">{ paintseed }</p>
                                </div>
                            </div>
                            <div className="down-load">
                                <div className="qrcode-box">
                                    <img src={ require('@/images/home/mobiDownLoadLogo.png') } alt=""/>
                                    <p onClick={ _ => $copy(full, 'noTips') }
                                       style={ { color: "#999999", textAlign: 'center' } }>下载Mobi App</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="scale" style={ {
                        width: `${ options.width }px`,
                        height: `${ options.height }px`,
                        borderRadius: options.borderRadius ? '50%' : '',
                        transform: scaleVal && `scale(${ scaleVal })`
                    } }>
                        <div className="csgo-items-2D-Modal-box">
                            <div className="cover-container-2d">
                                <div className="front-cover" style={ { marginTop: TalonR && '50px' } }>
                                    <img style={ {
                                        width: coverOpW && '100%',
                                        height: coverOpW && 'auto',
                                        transform: (TalonR && TalonStyle.front) || (knifeR && knifeStyle.front)
                                    } }
                                         className="weapon-cover"
                                         src={ front } alt=""/>
                                </div>
                                <div className="back-cover">
                                    <img style={ {
                                        width: coverOpW && '100%',
                                        height: coverOpW && 'auto',
                                        transform: (TalonR && TalonStyle.back) || (knifeR && knifeStyle.back)
                                    } }
                                         className="weapon-cover"
                                         src={ back } alt=""/>
                                </div>
                                
                                { stickers && stickers[0] && (
                                    <div className="sticker sticker-2d">
                                        { stickers.map((item, idx) => {
                                            return (
                                                <div
                                                    title={ `${ item.name }，磨损：${ item.wear ? `${ Number(~~((1 - item.wear) * 10000) / 100).toFixed(2) }%` : '100%' }` }
                                                    key={ idx } className="sticket-icon">
                                                    <img
                                                        style={ { filter: "contrast(0.756504)" } }
                                                        src={ `https://www.csgola.com/Public/img/csgo_stickers/${ item.material }.png` }
                                                        alt=""
                                                    />
                                                </div>
                                            );
                                        }) }
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </antdUI.Modal>
            }
        </>
    )
}

export default CsgoItems2DModal
