import React, { useEffect, useState, $api, $tools } from 'react';
import './index.scss'
import { FormulaSquareNav, FormulaSquareContainer, FormulaDetail } from '@/components/formulaSquare'
import { allCustomItemData } from '../../data/replacingAnalogData.js'
import { connect } from "react-redux";

function FormulaSquare(props) {
    const { userInfo } = props
    let nav = window.location?.hash.substr(1) || 'replacingAnalog'
    if (nav && nav.indexOf('?') !== -1) (nav = nav.slice(0, nav.indexOf('?')))
    const [ currentNav, setNav ] = useState(nav);
    const [ curHash, setCurHash ] = useState('')
    const [ itemType, setItemType ] = useState([]),
        [ rarity, setRarity ] = useState([]),
        [ exterior, setExterior ] = useState([]),
        [ collection, setCollection ] = useState([]),
        [ quality, setQuality ] = useState('normal'),
        [ classification, setClassification ] = useState([ 'latest' ]),
        [ searchVal, setSearchVal ] = useState('');
    
    const seItCur = window.location.hash.match(/selectItemCur=([^]*)/i)?.[1] || 'custom'
    const [ selectItemCur, setSelectItemCur ] = useState(seItCur)
    
    const [ replacAnaDataPageSize, setReplacAnaDataPageSize ] = useState(0);
    const [ repCurData, setRepCurData ] = useState([]),
        [ replacAnaData, setReAnaData ] = useState([]),
        [ replacAnaTotal, setReAnaTotal ] = useState(0),
        [ repCurPage, setRepCurPage ] = useState(1),
        [ formulaCollectionData, setForColData ] = useState([]);
    const repChangePage = (page) => {
        setReAnaData([])
        setRepCurPage(page)
        switch (selectItemCur) {
            case 'custom':
                setTimeout(_ => setReAnaData(repCurData.slice((page - 1) * replacAnaDataPageSize, page * replacAnaDataPageSize)))
                break;
            case 'main':
                break;
            default:
                break;
        }
    }
    const updateCollectionData = async () => {
        const result = await $api.formula.formulaCollectionList({ type: 'all' })
        if ($tools.checkResult(result)) setForColData(result?.data)
    }
    useEffect(_ => {
        initFilterVal()
        setNav(nav)
    }, [ nav ])
    useEffect(_ => {
        (() => updateCollectionData())()
        setCurHash(window.location?.hash.substr(1))
    }, [])
    useEffect(_ => {
        const mobi_uid = localStorage.getItem('mobi_uid')
        if ([ 'main', 'square', 'collection' ].indexOf(currentNav) !== -1) {
            if (! mobi_uid) return window.location.href = '/home#login'
            if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentNav ])
    const initFilterVal = () => {
        setItemType([])
        setRarity([])
        setExterior([])
        setCollection([])
        setQuality('normal')
        setClassification([ 'latest' ])
        setSearchVal('')
    }
    
    function formulaSquareFilter() {
        let newData, searchValue = searchVal, searchList;
        switch (currentNav) {
            case 'replacingAnalog':
                switch (selectItemCur) {
                    case 'custom':
                        newData = allCustomItemData.filter(_ => _.en_rarity !== 'Rarity_Ancient_Weapon')
                        if (itemType[0]) {
                            newData = newData.filter(_ => _.type === itemType[0])
                            if (itemType[1]) (newData = newData.filter(_ => _.weapon === itemType[1]))
                        }
                        if (rarity[0] && rarity?.[0] !== 'unlimited') (newData = newData.filter(_ => _.en_rarity === rarity[0]))
                        if (collection[1]) (newData = newData.filter(_ => _.target_box === collection[1]))
                        if (searchValue) {
                            searchValue = searchValue.replace('|', '')
                            newData = newData.filter(_ => {
                                if (quality === 'strange') {
                                    searchList = _.name.replace(' |', '（StatTrak™） ').toUpperCase().match(searchValue.toUpperCase())
                                } else {
                                    searchList = _.name.replace(' |', ' ').toUpperCase().match(searchValue.toUpperCase())
                                }
                                return searchList
                            })
                        }
                        break;
                    case 'main':
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        if (newData) {
            setRepCurData(newData)
            setReAnaTotal(newData.length)
        }
    }
    
    useEffect(_ => {
        formulaSquareFilter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ itemType, rarity, exterior, collection, quality, classification, searchVal ])
    
    useEffect(_ => {
        repChangePage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ repCurData ])
    
    useEffect(_ => {
        let _data
        switch (selectItemCur) {
            case 'custom':
                initFilterVal()
                setReplacAnaDataPageSize(20)
                _data = allCustomItemData.filter(_ => _.en_rarity !== 'Rarity_Ancient_Weapon')
                setRepCurData(_data)
                setReAnaData(_data.slice(0, replacAnaDataPageSize))
                setReAnaTotal(_data.length)
                break;
            case 'main':
                initFilterVal()
                setRepCurData([])
                setReAnaData([])
                setReAnaTotal(0)
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectItemCur ])
    return (
        <div className="formula-square">
            <img
                style={ { position: 'absolute', top: 0 } }
                className="tranding-market-banner"
                src={ require("@/images/tradingMarket/banner2@2x.png") }
                alt=""
            />
            <div className="formulaSquare-Container">
                {
                    currentNav === 'detail' ?
                        <FormulaDetail formulaCollectionData={ formulaCollectionData }
                                       updateCollectionData={ updateCollectionData }></FormulaDetail> : <>
                            <FormulaSquareNav
                                nav={ { currentNav, setNav } } navFilterValue={ {
                                itemType, rarity, exterior, collection, quality, classification, searchVal
                            } } navFilterChange={ {
                                setItemType,
                                setRarity,
                                setExterior,
                                setCollection,
                                setQuality,
                                setClassification,
                                setSearchVal,
                            } } initFilterVal={ initFilterVal }></FormulaSquareNav>
                            <FormulaSquareContainer
                                updateCollectionData={ updateCollectionData }
                                formulaCollectionData={ formulaCollectionData }
                                nav={ { currentNav, setNav } }
                                selectItem={ { selectItemCur, setSelectItemCur } }
                                navFilterChange={ { setRarity } }
                                replacAna={ {
                                    replacAnaData,
                                    replacAnaTotal,
                                    repCurPage,
                                    replacAnaDataPageSize,
                                    repChangePage
                                } }
                                navFilterValue={ {
                                    itemType,
                                    rarity,
                                    exterior,
                                    collection,
                                    quality,
                                    classification,
                                    searchVal
                                } }
                            ></FormulaSquareContainer></>
                }
            </div>
            { curHash === 'detail' ? '' : '' }
        </div>
    );
    
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(FormulaSquare);

