import { post, get } from "@/utils/request.js";

export default {
  //获取饰品市场
  getMarketData(data) {
    return post({ url: "/market/items/all/", data });
  },
  //获取饰品列表页
  getAccessoriesList(params) {
    return get({ url: "/market/items/type/", params });
  },
  //获取饰品详情
  getAccDetail(params) {
    return get({ url: "/market/items/type/detail/", params });
  },
  // 交易市场物品购买
  itemBuy(data) {
    return post({ url: "/market/item/buy/", data });
  },
  //修改发起报价方式
  updateDeliverMethod(data) {
    return post({ url: '/market/order/delivery/update/', data })
  },
  storeOnline(data) {
    return post({ url: '/market/store/online/', data })
  },
  getPriceTrend(params) {
    return get({ url: '/market/items/priceTrend', params })
  },
  //供应交易市场物品
  supplyItems(data) {
    return post({ url: '/market/item/supply/', data })
  },
  // 检测卖家上架物品是否存在库存
  checkItemStock(params) {
    return get({ url: '/market/check/seller/inventory/', params })
  }
};
