import React, { antdUI, useEffect, useState, $api, $tools } from 'react';
import './index.scss';
import { setUserSignIn } from '@/store/actions/userInfo'
import { connect } from 'react-redux';

let titleP = { fontSize: '16px', color: '#222222', fontWeight: 'Bold', transition: '.3s' },
    titleTime = { color: '#9A9A9A', fontSize: '14px', position: 'absolute', right: '20px', top: '15px' },
    contentP = { paddingLeft: 18, color: '#222222', };

function ProfileNews(props) {
    const [ cur, setCur ] = useState("")
    const [ newsData, setNewsData ] = useState([])
    const [ totalDo, setTotalDo ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ newsTips, setNewsTips ] = useState({ news_trade: 0, news_system: 0 })
    const [ isInt, setIsInt ] = useState(true)
    const [ load, setLoad ] = useState(true);
    const pageSize = 8;
    const { setUserSignIn, userInfo } = props
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
    }, [ userInfo ])
    const getUserMessage = async (currentPage) => {
        currentPage && setCurrentPage(currentPage)
        setLoad(true)
        let data = await $api.user.getUserMessage({ type: cur, pageSize, page: currentPage })
        setLoad(false)
        if ($tools.checkResult(data)) {
            setTotalDo(data.data.total)
            let _newsData = []
            data.data.message.forEach((item, idx) => {
                _newsData.push({
                    create_time: item.create_time,
                    title: (
                        <div className="title">
                            <p className="message-title" style={ titleP }>{ item.title }</p>
                            <span style={ titleTime } className="time">{ item.create_time }</span>
                        </div>
                    ),
                    content: (
                        <div style={ contentP }><p>{ item.message }</p></div>
                    )
                })
            })
            setNewsData(_newsData)
        }
    }
    useEffect(() => {
        let loc_hash = window.location.hash, type;
        if (loc_hash) {
            loc_hash = loc_hash.substr(1);
            if (loc_hash === "system") {
                setCur(2);
                type = 2
            }
            if (loc_hash === `trade`) {
                setCur(1);
                type = 1
            }
            if (! userInfo.uid) return
            $api.user.getUserMessageState().then(res => {
                setNewsTips({ ...res.data })
                if ((type === 1 && res.data.news_trade) || (type === 2 && res.data.news_system)) {
                    $api.user.updateUserMessageState({ type }).then(res => {
                        setNewsTips({ ...res.data });
                        setUserSignIn()
                    });
                }
            })
        } else {
            setCur(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    
    useEffect(() => {
        if (isInt) {
            setIsInt(false)
        } else {
            window.location.href = `/profile/news#${ cur === 1 ? `trade` : cur === 2 ? 'system' : "" }`;
            getUserMessage().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ cur ])
    return (
        <div className="profile-news">
            <div className="title">
                <h1 onClick={ _ => {
                    setTotalDo(0)
                    setNewsData([])
                    setCur(1);
                    if (userInfo.uid && newsTips.news_trade) {
                        $api.user.updateUserMessageState({ type: 1 }).then(res => {
                            setNewsTips({ ...res.data });
                            setUserSignIn()
                        })
                    }
                } } className={ [ cur === 1 ?
                    `${ newsTips.news_trade ? 'tips' : '' } cur` :
                    newsTips.news_trade ? 'tips' : '' ] }>交易消息</h1>
                <h1 onClick={ _ => {
                    setTotalDo(0)
                    setNewsData([])
                    setCur(2);
                    if (userInfo.uid && newsTips.news_system) {
                        $api.user.updateUserMessageState({ type: 2 }).then(res => {
                            setNewsTips({ ...res.data });
                            setUserSignIn()
                        })
                    }
                } } className={ [ cur === 2 ?
                    `${ newsTips.news_system ? 'tips' : '' } cur` :
                    newsTips.news_system ? 'tips' : '' ] }>系统消息</h1>
            </div>
            
            { load ? <antdUI.Skeleton active/> : <antdUI.Collapse
                bordered={ false } accordion={ true }
            >
                { cur === 2 ? newsData.map((v, i) => {
                    return (
                        <antdUI.Collapse.Panel key={ i } header={ v.title } className="site-collapse-custom-panel">
                            { v.content }
                        </antdUI.Collapse.Panel>
                    )
                }) : newsData.map((v, i) => {
                    return (
                        <div style={ {
                            marginBottom: "20px", display: 'flex',
                            backgroundColor: '#f3f3f3', padding: '8px 0',
                            borderBottom: '1px solid #e9e9e9'
                        } } key={ i }>
                            <div style={ { width: '80%', paddingRight: "30px" } }>{ v.content }</div>
                            <div className="flex ait"
                                 style={ { width: '20%', color: '#959595' } }>{ v.create_time }</div>
                        </div>
                    )
                }) }
            </antdUI.Collapse> }
            
            
            <div className="flex jtyct" style={ { marginTop: '20px' } }>
                <antdUI.Pagination
                    onChange={ (currentPage, pageSize) => getUserMessage(currentPage) }
                    hideOnSinglePage={ true }
                    showSizeChanger={ false } current={ currentPage } defaultCurrent={ 1 }
                    total={ totalDo } pageSize={ pageSize }/>
            </div>
        </div>
    )
}


export default connect(function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(ProfileNews);
