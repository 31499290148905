import React, { antdUI, useState } from 'react';
import './prompt.scss'

export default function Promtp(props) {
    let { onShelfType, onSuccess } = props
    const [ protocol, setProtocol ] = useState(false)
    let flowChart;
    
    switch (onShelfType.toLowerCase()) {
        case 'onsale':
            flowChart = require('@/images/onShelf/zishoushangjia@2x.png');
            break;
        case 'intermediary':
            flowChart = require('@/images/onShelf/zhongjieshangjia@2x.png');
            break;
        default:
            break;
    }
    
    return (
        <div className="on-shelf-prompt">
            <div className="head">
                <i className="prompt-icon"></i>
                <p>小心:CSGO有很多骗子，请在平台交易，手续费不高</p>
            </div>
            <div className="flow-chart">
                <img src={ flowChart } alt=""/>
            </div>
            
            
            <div className="btn" onClick={ _ => onSuccess({ type: onShelfType, protocol }) }> 我已阅读
            </div>
            <div className="flex ait jtyct" style={ { marginBottom: '20px' } }>
                <antdUI.Checkbox style={ { margin: '0' } } onChange={ (e) => setProtocol(e.target.checked) }
                                 defaultChecked={ protocol }>
                    <span style={ { paddingLeft: '0px' } }>不再显示</span>
                </antdUI.Checkbox>
            </div>
        </div>
    )
}
