import React, {
    antdUI,
    $api, $login, $toBindSteam, $bindSteamLink, $bindSteamApiKey,
    // $tools
} from 'react';
import './sass/mainPop.scss'
import { connect } from "react-redux";

function mainPop(props) {
    const {
        userInfo, userAssets,
        setSettingView
        // setUserSignIn
    } = props;
    // const { checkResult } = $tools
    const signOut = async () => {
        // const date = new Date();
        // date.setTime(date.getTime() - 10000);
        // const keys = document.cookie.match(/[^ =;]+(?==)/g);
        // if (keys) {
        //   for (var i = keys.length; i--;) {
        //     if (keys[i] === 'authorization') {
        //       document.cookie = `${keys[i]}=; expire=${date.toGMTString()}; path=/`
        //     }
        //   }
        // }
        await $api.user.signOut();
        window.localStorage.setItem('token', '')
        setTimeout(_ => window.location.href = '/home', 100)
    }
    const storeOnline = async storeSwitchVal => {
        if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
        if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
        if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
        // if (userInfo.real_name_status !== 1) return $toReal()
        if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
        if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
        setSettingView(true)
        // let res = await $api.market.storeOnline({ storeSwitchVal })
        // if (checkResult(res)) setUserSignIn();
    }
    const balance = userAssets.alipay_amount || 0
    return (
        <div className="main-pop">
            <div className="user-head">
                <div className="avatar">
                    { userInfo.steam.avatarfull ?
                        <img className="avatar-icon" src={ userInfo.steam.avatarfull } alt=""/> :
                        <img className="avatar-icon" src={ require('@/images/home/moren touxiang@2x.png') } alt=""/>
                    }
                </div>
                <div className="nickname-logout" style={ { height: '65px' } }>
                    <p className="nickname text-ov2" style={ { width: '160px' } }>{ userInfo.nickname ||
                    userInfo.steam?.personaname }</p>
                    <p className="logout" onClick={ _ => signOut() }>注销</p>
                </div>
            </div>
            <div className="user-balance ">
                <div className="balance-val flex jtyct ait">
                    <span style={ { color: '#fff', marginRight: '3px' } }>店铺</span>
                    <antdUI.Switch size="small" checked={ userInfo.isOnline === 1 }
                        // defaultChecked={userInfo.isOnline === 1}
                                   onClick={ storeOnline }/>
                    <p style={ { marginLeft: '10px' } }>余额
                        <span style={ { fontSize: '18px', color: '#fc9a2b' } }>
              ￥{ balance }</span></p>
                </div>
                <div className="balance-btn">
                    <div className="recharge" onClick={ () => {
                        window.location.href = '/profile/wallet?type=recharge;'
                    } }>充值
                    </div>
                    <div className="withdraw" onClick={ () => {
                        window.location.href = '/profile/wallet?type=withdraw;'
                    } }>提现
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(mainPop);
