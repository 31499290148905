import React, {antdUI} from 'react';
import CsBox from '../itemBox/csBox';
import Dota2Box from "../itemBox/dota2Box";

const style = {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridGap: '15px',
  marginBottom: '20px',
}

function InitiateAPurchase(props) {
  const { itemData, currentGame } = props;

  return (
    <antdUI.Spin style={{minHeight: '520px'}} spinning={!itemData.length}>
      <div style={style}>
        {currentGame === "730"
          ? itemData.map((item, index) => {
            return (
              <CsBox
                key={index}
                style={{ marginLeft: index % 5 ? "0.5%" : "" }}
                item={item}
              ></CsBox>
            );
          })
          : itemData.map((item, index) => {
            return (
              <Dota2Box
                key={index}
                style={{ marginLeft: index % 5 ? "1.5%" : "" }}
                item={item}
              ></Dota2Box>
            );
          })}
      </div>
    </antdUI.Spin>

  );
}
export default InitiateAPurchase;
