import React, {antdUI, $tools, useRef, useState} from 'react';
import './priceFilter.scss'
function PriceFilter(props){

  const {setMinPrice,setMaxPrice} = props;
  const [minP,setMinP] = useState(),
    [maxP,setMaxP] = useState();



  const { checkInputValNumber,debounce} = $tools;


  const priceFilter = (minP,maxP)=>{
    setMaxPrice(maxP);
    setMinPrice(minP);
  }

  const delayPriceFilter = useRef(debounce(priceFilter,1000,true)).current;


  return(
    <div className='price-filter'>
      <antdUI.Input
        prefix="￥"
        // size={"small"}
        value={minP}
        allowClear
        onChange={(_) => setMinP(checkInputValNumber(_.target.value))}
        style={{ width: "90px" }}
      ></antdUI.Input>
      <span style={{ color: "black" }}>—</span>
      <antdUI.Input
        prefix="￥"
        value={maxP}
        allowClear
        onChange={(_) => setMaxP(checkInputValNumber(_.target.value))}
        // size={"small"}
        style={{ width: "90px" }}
      ></antdUI.Input>

      <antdUI.Button
        onClick={()=>delayPriceFilter(minP,maxP)}
        className="price-filter-btn"
        // size={"small"}
      >
        过滤
      </antdUI.Button>
    </div>


  )
}



export default PriceFilter
