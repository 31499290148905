import React, { antdUI } from 'react';
import './RollRoomBoxContainer.scss'
import { Link } from "react-router-dom";

const handleRoomStateText = (data) => {
    let text = ''
    const { state } = data
    switch (Number(state)) {
        case 0:
            text = <span className="roll-state" style={ {
                color: '#fff', backgroundColor: "rgba(255, 255, 255, 0.41)",
                border: '1px solid rgba(255, 255, 255, 1)'
            } }>未开始</span>;
            break;
        case 1:
            text =
                <span className="roll-state" style={ {
                    color: '#fff',
                    backgroundColor: "rgba(0, 255, 126, 0.41)",
                    border: '1px solid rgba(0, 255, 126, 1)'
                } }>已开始</span>;
            break;
        case 2:
            text = <span className="roll-state" style={ {
                color: '#fff',
                backgroundColor: "rgba(141, 80, 66, 0.41)",
                border: '1px solid rgba(141, 80, 66, 1)'
            } }>已满</span>;
            break;
        case 3:
            text = <span className="roll-state" style={ {
                color: '#fff',
                backgroundColor: "rgba(221, 61, 27, 0.41)",
                border: '1px solid rgba(221, 61, 27, 1)'
            } }>已失效</span>;
            break;
        case 4:
            text = <span className="roll-state" style={ {
                color: '#fff',
                backgroundColor: "rgba(221, 61, 27, 0.41)",
                border: '1px solid rgba(221, 61, 27, 1)'
            } }>已取消</span>;
            break;
        case 5:
            text = <span className="roll-state" style={ {
                color: '#fff',
                backgroundColor: "rgba(255, 140, 0, 0.41)",
                border: '1px solid rgba(255, 140, 0, 1)'
            } }>已结束</span>;
            break;
        default:
            text = <span className="roll-state" style={ {
                color: '#fff',
                backgroundColor: "rgba(255, 140, 0, 0.41)",
                border: '1px solid rgba(255, 140, 0, 1)'
            } }>未知</span>;
            break;
    }
    
    return text
}

function RollRoomBoxContainer({ rollRoomData, rollNav }) {
    const cls = rollNav === "welfare" ? "rollRoom-content-box-container welfare" : "rollRoom-content-box-container"
    return <div className={ cls }>
        {
            rollRoomData.map((item, idx) => {
                if (rollNav === 'welfare') {
                    return <div key={ idx } className="rollRoom-item">
                        <div className="top-info welfare">
                            <div className="avatar"
                                 style={ { backgroundImage: `url(${ item?.AdminAccount?.avatarfull })` } }></div>
                            <div className="room-title">
                                <h2 style={ { width: "430px" } } className="text-ov1">{ item.name }</h2>
                                <div className="flex ait">
                                    { handleRoomStateText(item) }
                                    <p style={ { marginLeft: '10px' } }>参与人数: { item.joinCount }</p>
                                </div>
                            </div>
                        </div>
                        <div className="detail-info" style={ { paddingTop: "0px" } }>
                            <div className="progress-line flex ait">
                                <span>用户投入: </span>
                                <div className="progress-bar-container flex ait">
                                    { (() => {
                                        const bl = item.allInput / item.mobiJoinTotal * 100
                                        return <div className="progress-bar">
                                            <div style={ {
                                                width: `${ bl }%`,
                                                backgroundColor: Number(item.allInput) === Number(item.mobiJoinTotal) && "#4CC04F"
                                            } } className='bar-main'></div>
                                        </div>
                                    })() }
                                    <span className="progress-bar-val"
                                          style={ { color: Number(item.allInput) === Number(item.mobiJoinTotal) && "#fff" } }>
                                        { item.allInput }/{ item.mobiJoinTotal }
                                    </span>
                                </div>
                            </div>
                            <div className="progress-line flex ait" style={ { marginBottom: "20px" } }>
                                <span>网站解锁: </span>
                                <div className="progress-bar-container flex ait">
                                    { (() => {
                                        const bl = item.allUnlockToken / item.unlockMobiTotal * 100
                                        return <div className="progress-bar">
                                            <div style={ {
                                                width: `${ bl }%`,
                                                backgroundColor: Number(item.allUnlockToken) >= Number(item.unlockMobiTotal) && "#4CC04F"
                                            } } className='bar-main'></div>
                                        </div>
                                    })() }
                                    <span className="progress-bar-val"
                                          style={ { color: Number(item.allUnlockToken) >= Number(item.unlockMobiTotal) && "#fff" } }>
                                    { item.allUnlockToken }/{ item.unlockMobiTotal }
                                </span>
                                </div>
                            </div>
                            <div className="bottom-info">
                                <div className="flex"
                                     style={ { alignItems: "end" } }><p
                                    style={ { position: 'relative', top: "-4px" } }>MOBI购物金:</p> <h1
                                    style={ { color: "#FFB10B", marginLeft: "20px" } }>¥{ item.bonusTotal }</h1></div>
                                {/*<p>房号: { item.id }</p>*/ }
                            </div>
                            <div className="room-box-btn">
                                <antdUI.Button style={ { borderRadius: '4px', width: '105px', height: '38px' } }
                                               type="primary">
                                    <Link target={ '_blank' }
                                          to={ { pathname: '/rollRoom/detail', search: `?roomID=${ item.id }` } }>
                                        查看</Link>
                                </antdUI.Button>
                            </div>
                        </div>
                    </div>
                } else {
                    return <div key={ idx } className="rollRoom-item">
                        <div className="top-info">
                            <div className="avatar"
                                 style={ { backgroundImage: `url(${ item?.AdminAccount?.avatarfull })` } }></div>
                            <div className="room-title">
                                { handleRoomStateText(item) }
                                <h2 className="text-ov1">{ item.name }</h2>
                                <p>活动时间 <span>{ item.startTime }</span></p>
                            </div>
                        </div>
                        <div className="detail-info">
                            <h2 className="item-price">
                                {/*总价值：<span>¥1089.61</span>*/ }
                                (共{ item.roomItemCount }件)</h2>
                            
                            <div className="more-item">
                                {
                                    item.roomItem[0] && item.roomItem.slice(0, 4).map((_item, i) => {
                                        let itemSrc
                                        let attribute = String(_item.attribute)
                                        if (attribute === 'items') {
                                            itemSrc = _item?.CsgoItem?.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ _item?.CsgoItem?.img_name }.png`
                                                : "https://steamcommunity-a.akamaihd.net/economy/image/" + (_item?.CsgoItem?.icon_url || _item?.Dota2Item?.icon_url)
                                        } else if (attribute === 'token') {
                                            itemSrc = require('@/images/rollRoom/mobi xiao@2x.png')
                                        } else if (attribute === 'money') {
                                            itemSrc = require('@/images/rollRoom/buketixianyue xiao@2x.png')
                                        }
                                        return <img key={ i } src={ itemSrc } alt=""/>
                                    })
                                }
                            </div>
                            
                            <div className="bottom-info">
                                <p>参与人数: { item.joinCount }</p>
                                <p>房号: { item.id }</p>
                            </div>
                            <div className="room-box-btn">
                                <antdUI.Button style={ { borderRadius: '4px', width: '105px', height: '38px' } }
                                               type="primary">
                                    <Link target={ '_blank' }
                                          to={ { pathname: '/rollRoom/detail', search: `?roomID=${ item.id }` } }>
                                        查看</Link>
                                </antdUI.Button>
                            </div>
                        </div>
                    </div>
                }
            })
        }
    </div>
}

export default RollRoomBoxContainer;
