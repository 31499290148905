import React, { $api, $login, $tools, antdUI, useEffect, useState } from "react";
import './index.scss'
import ReplacingAnalogCustomItem from './items/index.js'
import CustomItemBox from './items/customItemBox.js'
import AnalogResultBox from './items/analogReBox.js'
import { allCustomItemData, allCustomItemDataTree } from '@/data/replacingAnalogData.js'
import { connect } from "react-redux";
import { InventoryContainer } from '@/components/myInventory'
import FormulaContainsItems from '../../ContainsItems'


const levelControl = {
    "Rarity_Common_Weapon": "Rarity_Uncommon_Weapon",
    "Rarity_Uncommon_Weapon": "Rarity_Rare_Weapon",
    "Rarity_Rare_Weapon": "Rarity_Mythical_Weapon",
    "Rarity_Mythical_Weapon": "Rarity_Legendary_Weapon",
    "Rarity_Legendary_Weapon": "Rarity_Ancient_Weapon"
}

function ReplacingAnalog(props) {
    const wearData = React.$wearData2
    const { getUrlQueryValue, checkResult, debounce, averageWear } = $tools
    const locaHref = window.location.href.match(/\?id=(\S+)/g)?.[0]
    const formulaId = getUrlQueryValue('id', locaHref)
    const {
        selectItem: { setSelectItemCur },
        replacAna: { replacAnaData, replacAnaTotal, repCurPage, replacAnaDataPageSize, repChangePage },
        navFilterValue: { quality },
        navFilterChange: { setRarity },
        userInfo
    } = props
    
    const [ materialData, setMaterialData ] = useState([])
    const [ analogResult, setAnalogResult ] = useState([])
    const [ formulaCover, setFormulaCover ] = useState({})
    const [ forNameShow, setForNameShow ] = useState(0)
    const [ formulaName, setFormulaName ] = useState('')
    const [ formulaUpdate, setFormulaUpdate ] = useState(false)
    const [ successTipsShow, setSuTipsShow ] = useState(false)
    const [ myInvenShow, setMyInvenShow ] = useState(false)
    const { Collapse } = antdUI, { Panel } = Collapse;
    const [ FormulaId, setFormulaId ] = useState()
    const [ containsItemsShow, setConItemsShow ] = useState(false)
    const [ boxValue, setBoxValue ] = useState('')
    useEffect(_ => {
        let swn = 0;
        if (materialData[0] && ! materialData[1]) setRarity([ materialData[0].en_rarity ])
        materialData[0] && localStorage.setItem('formulaMaterialData', JSON.stringify(materialData))
        if (materialData.length !== 10) {
            setAnalogResult([])
            setFormulaCover({})
        }
        if (materialData.length === 10) {
            setMyInvenShow(false)
            materialData.forEach(item => {
                if (item.wearVal && item.wearVal !== 0 && item.wearVal !== 1) swn += 1
            })
            if (swn !== 10) {
                if (analogResult[0]) setAnalogResult([])
                return
            }
            let list = [], index, boxItem, allNums = 0;
            materialData.forEach(item => {
                index = list.map(_ => _.box_value).indexOf(item.target_box)
                if (index === -1) {
                    boxItem = allCustomItemDataTree.filter(boxI => boxI.box_value === item.target_box)[0]
                    boxItem = JSON.parse((JSON.stringify(boxItem)))
                    boxItem.nums = 1
                    boxItem.averageWearVal = averageWear(materialData.map(wear => Number(wear.wearVal)), 1, 0)
                    boxItem.children = boxItem.children.filter(it => it.en_rarity === levelControl[materialData[0].en_rarity])
                    boxItem.children.forEach(_ => {
                        _.wearValue = averageWear(materialData.map(wear => Number(wear.wearVal)), _.max, _.min)
                    })
                    list.push(boxItem)
                } else {
                    list[index].nums += 1;
                }
            })
            list.forEach(_ => {allNums += _.children.length * _.nums})
            list.forEach(_ => {_.chance = (_.nums / allNums * 100).toFixed(2)})
            setAnalogResult(list)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ materialData ])
    useEffect(_ => {
        if (! formulaId) {
            let formulaMaterialData = localStorage.getItem('formulaMaterialData')
            if (formulaMaterialData) {
                formulaMaterialData = JSON.parse(formulaMaterialData)
                formulaMaterialData[0] && setMaterialData(formulaMaterialData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(_ => {
        (async () => {
            if (userInfo.uid && formulaId) {
                const result = await $api.formula.getFormulaDetail({ id: formulaId, type: 'update' })
                if (checkResult(result)) {
                    setFormulaUpdate(true)
                    const { formulaMaterial, } = result.data
                    setMaterialData(formulaMaterial.map(item => {
                        return item = {
                            ...allCustomItemData.filter(_ => Number(item.id) === _.itemid)?.[0],
                            wearVal: item.value
                        }
                    }))
                    setFormulaName(result.data.formulaName)
                }
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userInfo ])
    
    useEffect(_ => {
        if (! analogResult[0] && formulaCover.name) {
            setFormulaCover({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ analogResult ])
    
    useEffect(_ => {
        if (quality === 'strange') {
            if (formulaCover.name) setFormulaCover({ ...formulaCover, quality: "strange" })
        } else if (quality === 'normal') {
            if (formulaCover.name) setFormulaCover({ ...formulaCover, quality: "normal" })
        }
        materialData[0] && setMaterialData(materialData.map(_ => {
            if (quality === 'strange') {
                _.list = _.list.replace(/ \|/g, '（StatTrak™） |')
            } else {
                _.list = _.list.replace(/（StatTrak™） \|/g, ' |')
            }
            return _
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ quality ])
    
    const showName = n => {
        if (! localStorage.getItem('mobi_uid')) return $login()
        if (userInfo.steam.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
        if (! materialData[0]) return antdUI.message.warning('必须填入10件饰品，少于10件系统不会生成结果')
        if (n === 2 && ! materialData[9]) return antdUI.message.warning('必须填入10件饰品，少于10件系统不会生成结果')
        if (materialData[9] && ! formulaCover.name) return antdUI.message.warning('请先在汰换模拟结果中选择一个饰品作为配方的封面')
        setForNameShow(n)
    }
    
    const emptyFormula = () => {
        setRarity([]);
        setMaterialData([])
        localStorage.setItem('formulaMaterialData', '[]')
    }
    
    const saveFormula = async release => {
        let result
        if (! formulaName.replace(/\s+/g, '')) return antdUI.message.warning('请输入正确的配方名！')
        let formulaCoverData = JSON.parse(JSON.stringify(formulaCover))
        const targetItem = allCustomItemData.filter(_ => _.name === formulaCoverData.name)[0]
        formulaCoverData.type = targetItem?.type
        formulaCoverData.weapon = targetItem?.weapon
        formulaCoverData.rarity = targetItem?.en_rarity
        formulaCoverData.itemSet = targetItem?.target_box
        let formulaMaterialData = materialData.map(_ => [ _.itemid, _.wearVal ])
        let formulaAnalogResult = analogResult.map(_ => {
            return {
                box_value: _.box_value,
                chance: _.chance,
                children: [ ..._.children.map(_ => [ _.itemid, _.wearValue ]) ]
            }
        })
        let coverWearVal, tWear, li;
        formulaAnalogResult.forEach(res => {
            li = res.children.filter(_ => _[0] === formulaCover.itemid)?.[0]?.[1]
            li && ! coverWearVal && (coverWearVal = li)
        })
        wearData.forEach((item, idx, arr) => {
            if (Number(coverWearVal) <= item.ratio && idx === 0) tWear = item
            if (Number(coverWearVal) >= item.ratio && Number(coverWearVal) <= arr[idx + 1].ratio) tWear = arr[idx + 1]
        })
        const formulaData = {
            ...formulaCoverData, formulaName, id: formulaId,
            wearValue: coverWearVal, exterior: tWear?.wear,
            formulaMaterial: JSON.stringify(formulaMaterialData),
            formulaAnalogResult: JSON.stringify(formulaAnalogResult),
            release: release === 2 ? 1 : 0
        }
        if (formulaUpdate) {
            result = await $api.formula.updateFormula({ ...formulaData, id: formulaId })
        } else {
            result = await $api.formula.saveFormula(formulaData)
        }
        if (checkResult(result)) {
            setFormulaId(result?.data?.formulaId)
            antdUI.message.success(`保存${ release === 2 ? '并发布' : '' }配方成功！`)
            setForNameShow(0)
            setFormulaName('')
            setSuTipsShow(true)
        }
    }
    return (
        <div className="replacing-analog">
            <div className="replacing-analog-select-items">
                <div className="replacing-analog-select-items-nav">
                    <div onClick={ () => {setSelectItemCur('custom')} }
                         className="replacing-analog-select-items-navItem current"> 自定义
                    </div>
                    <div onClick={ () => {
                        if (! localStorage.getItem('mobi_uid')) return $login()
                        if (userInfo.steam.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                        if (userInfo.uid && ! userInfo.steam.steamid) return antdUI.message.warning('请先完善steam绑定！')
                        setMyInvenShow(true)
                    } } className="replacing-analog-select-items-navItem">从我的库存中选择
                    </div>
                
                </div>
                <div className="replacing-analog-select-items-box">
                    <ReplacingAnalogCustomItem
                        allCustomItemDataTree={ allCustomItemDataTree }
                        setRarity={ setRarity }
                        materialOp={ { materialData, setMaterialData } }
                        replacAnaData={ replacAnaData } quality={ quality }
                    ></ReplacingAnalogCustomItem>
                </div>
                <div className="flex jtyct" style={ { marginTop: '15px' } }>
                    <antdUI.Pagination
                        onChange={ (currentPage, pageSize) => repChangePage(currentPage, pageSize) }
                        current={ repCurPage } hideOnSinglePage={ true }
                        showSizeChanger={ false } defaultCurrent={ 1 }
                        total={ replacAnaTotal } pageSize={ replacAnaDataPageSize }/>
                </div>
            </div>
            <div className="replacing-analog-result">
                <div className="analog-result-btn">
                    <button className="empty" onClick={ _ => emptyFormula() }>清空
                    </button>
                    <button className="save"
                            onClick={ _ => showName(1) }
                    >保存配方
                    </button>
                    <button className="save-release" onClick={ _ => showName(2) }>保存并发布配方</button>
                </div>
                <div className="analog-result-container">
                    <Collapse defaultActiveKey={ [ '1', '2' ] } bordered={ false }>
                        <Panel header={ <h4 className="material-t">材料（{ materialData.length }/10）
                            <i className="iconfont icon-arrow-left"></i>
                        </h4> } showArrow={ false } key="1">
                            <div className="material-container">
                                {
                                    ! materialData[0] && (
                                        <div className="replacing-material-prompt">
                                            <h2>汰换合同模拟器</h2>
                                            <h4>您可以从左侧窗口选择10件<span>相同品质</span>、<span>相同类别</span>的饰品，
                                                进入材料窗口后填入磨损值（不填的话系统会自动填入一个数值）。
                                                10件饰品选择完毕，并且磨损值都填入后，系统会根据填入信息，<span>自动模拟</span>计算得到汰换结果的饰品种类、磨损和概率分布。
                                            </h4>
                                            <p>注意: 1、<span>必须</span>填入10件饰品，少于10件系统不会生成结果</p>
                                            <p style={ { textIndent: '35px' } }>2、每件饰品的磨损值<span>不能为空</span>，且需要在该饰品磨损范围内
                                            </p>
                                            <p style={ { textIndent: '35px' } }>3、本功能仅为模拟参考使用，不提供任何实际汰换效果，如果对模拟数据有任何意见或建议可以加群联系客服</p>
                                        </div>
                                    )
                                }
                                {
                                    (() => {
                                        let list = [], index;
                                        materialData.forEach((item, key) => {
                                            index = list.map(_ => _.box_value).indexOf(item.target_box)
                                            if (index === -1) {
                                                list.push({
                                                    box_value: item.target_box,
                                                    name: item.targetBoxName || allCustomItemDataTree.filter(treeI => treeI.box_value === item.target_box)?.[0]?.name,
                                                    children: [ { ...item, key } ]
                                                })
                                            } else {
                                                list[index].children.push({ ...item, key })
                                            }
                                        })
                                        return <>{
                                            list.map((item, id) => {
                                                return (
                                                    <div className="material-box-item" key={ id }>
                                                        <h3 className="flex ait" onClick={ () => {
                                                            setBoxValue(item.box_value)
                                                            setConItemsShow(true)
                                                        } }
                                                            value={ item.box_value }>{ item.name }（{ item.children.length }）
                                                            <i className="iconfont icon-arrow-right"></i>
                                                        </h3>
                                                        <div className="material-box-item-container">
                                                            { item.children.map(_ => {
                                                                const minr = String(_.min).split(''),
                                                                    maxr = String(_.max).split('');
                                                                return (
                                                                    <div style={ { marginBottom: '8px' } }
                                                                         key={ _.key }>
                                                                        <CustomItemBox
                                                                            materialOp={ {
                                                                                materialData,
                                                                                setMaterialData
                                                                            } }
                                                                            quality={ quality } itemData={ _ }
                                                                            targetBox={ {
                                                                                targetBoxName: item.name,
                                                                                list: _.list,
                                                                                len: _.len
                                                                            } }
                                                                            type={ "material" }
                                                                        />
                                                                        <div className="reAnCustom-item-box">
                                                                            <p
                                                                                title={ quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                                className="reAnCustom-item-name">
                                                                                { quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                            </p>
                                                                            <input className="reAnCustom-item-wear"
                                                                                   placeholder="请输入磨损值"
                                                                                   value={ _.wearVal }
                                                                                   onChange={ t => {
                                                                                       let swv;
                                                                                       const wearVal = String(t.target.value).replace(/[^0-9.]/g, "");
                                                                                       const wearValAr = wearVal.split('');
                                                                                       wearValAr.forEach((item, i, arr) => {
                                                                                           if (i === 0 && Number(item) !== 0) swv = true;
                                                                                           if (i === 1 && item !== '.') swv = true;
                                                                                           if (i === 2) {
                                                                                               if (minr[i] && item < minr[i]) swv = true;
                                                                                               if (maxr[i] && item > maxr[i]) swv = true;
                                                                                               if (! maxr[i + 1] && item >= maxr[i]) swv = true;
                                                                                           }
                                                                                           if (i === 3) {
                                                                                               if (minr[i] && minr[i - 1] >= arr[i - 1] && item < minr[i]) swv = true;
                                                                                               if (maxr[i] && maxr[i - 1] === arr[i - 1] && item >= maxr[i]) swv = true;
                                                                                           }
                                                                                           if (i >= 20) swv = true;
                                                                                       })
                                                                                       if (swv) return antdUI.message.warning(`请输入 ${ _.min } - ${ _.max } 之间的磨损值`)
                                                                                       let _tData = JSON.parse((JSON.stringify(materialData)));
                                                                                       _tData[_.key].wearVal = wearVal
                                                                                       setMaterialData([ ..._tData ])
                                                                                   } }
                                                                                   onBlur={ t => {
                                                                                       const wearVal = Number(t.target.value);
                                                                                       let _tData;
                                                                                       if (wearVal >= _.max || wearVal <= _.min || String(t.target.value).length >= 21) {
                                                                                           antdUI.message.warning(`请输入 ${ _.min } - ${ _.max } 之间的磨损值`)
                                                                                           t.target.value = ''
                                                                                           _tData = JSON.parse((JSON.stringify(materialData)));
                                                                                           _tData[_.key].wearVal = ''
                                                                                           setMaterialData([ ..._tData ])
                                                                                       } else {
                                                                                           _tData = JSON.parse((JSON.stringify(materialData)));
                                                                                           _tData[_.key].wearVal = wearVal
                                                                                           setMaterialData([ ..._tData ])
                                                                                       }
                                                                                   } }/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) }</div>
                                                    </div>
                                                )
                                            })
                                        }</>
                                    })()
                                }
                            </div>
                        </Panel>
                        <Panel header={ <h4 className="analog-result-t">汰换模拟结果
                            <i className="iconfont icon-arrow-left"></i>
                        </h4> } showArrow={ false } key="2">
                            <div className="analog-result-container-box">
                                {
                                    ! analogResult[0] && materialData[0] && (
                                        <div className="replacing-material-prompt">
                                            <h2>汰换合同模拟器</h2>
                                            <h4>您可以从左侧窗口选择10件<span>相同品质</span>、<span>相同类别</span>的饰品，
                                                进入材料窗口后填入磨损值（不填的话系统会自动填入一个数值）。
                                                10件饰品选择完毕，并且磨损值都填入后，系统会根据填入信息，<span>自动模拟</span>计算得到汰换结果的饰品种类、磨损和概率分布。
                                            </h4>
                                            <p>注意: 1、<span>必须</span>填入10件饰品，少于10件系统不会生成结果</p>
                                            <p style={ { textIndent: '35px' } }>2、每件饰品的磨损值<span>不能为空</span>，且需要在该饰品磨损范围内
                                            </p>
                                            <p style={ { textIndent: '35px' } }>3、本功能仅为模拟参考使用，不提供任何实际汰换效果，如果对模拟数据有任何意见或建议可以加群联系客服</p>
                                        </div>
                                    )
                                }
                                {
                                    analogResult[0] &&
                                    analogResult.map((item, i) => {
                                        return (
                                            <div className="material-box-item" key={ i }>
                                                <h3 className="flex ait" onClick={ () => {
                                                    setBoxValue(item.box_value)
                                                    setConItemsShow(true)
                                                } }
                                                    value={ item.box_value }>{ item.name }（{ item.children.length }）
                                                    <i className="iconfont icon-arrow-right"></i>
                                                </h3>
                                                <div className="material-box-item-container">
                                                    { item.children.map((_, i) => {
                                                        let tWear
                                                        wearData.forEach((item, idx, arr) => {
                                                            if (Number(_.wearValue) <= item.ratio && idx === 0) tWear = item
                                                            if (Number(_.wearValue) >= item.ratio && Number(_.wearValue) <= arr[idx + 1].ratio) tWear = arr[idx + 1]
                                                        })
                                                        return (
                                                            <div
                                                                className={ [ formulaCover.name === _.name ? 'analog-result-box-b current' : 'analog-result-box-b' ] }
                                                                onClick={ () => {
                                                                    setFormulaCover({
                                                                        name: _.name,
                                                                        wearValue: _.wearValue,
                                                                        exterior: tWear.wear,
                                                                        itemid: _.itemid,
                                                                        chance: item.chance, quality
                                                                    })
                                                                } }
                                                                style={ { marginBottom: '8px' } } key={ i }>
                                                                <AnalogResultBox
                                                                    quality={ quality }
                                                                    itemData={ { ..._, ...tWear, chance: item.chance } }
                                                                />
                                                                <div className="reAnCustom-item-box"
                                                                     style={ { margin: '2px auto' } }>
                                                                    <p
                                                                        title={ quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                        className="reAnCustom-item-name">
                                                                        { quality === 'strange' ? _.name.replace(' |', '（StatTrak™） |') : _.name }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) }</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <antdUI.Modal
                title={ `保存${ forNameShow === 2 ? '并发布' : '' }配方` } maskClosable={ false }
                visible={ forNameShow } okText="保存" cancelText="取消"
                onOk={ debounce(saveFormula.bind(null, forNameShow), 2000, true) }
                onCancel={ () => {
                    setForNameShow(0)
                    ! formulaUpdate && setFormulaName('')
                } } width="500px"
            >
                { ! formulaCover.name &&
                    <p className="flex jtyct" style={ { marginBottom: '15px', color: '#FFA726' } }>
                        当前配方材料少于10件，将会自动保存至草稿配方内！
                    </p> }
                <div className="flex jtyct" style={ { paddingBottom: "10px" } }>
                    <antdUI.Input style={ { width: '80%' } } type="text" placeholder="请输入配方名"
                                  value={ formulaName }
                                  onChange={ _ => setFormulaName(_.target.value) }/>
                </div>
            </antdUI.Modal>
            
            <antdUI.Modal
                title={ `提示` } visible={ successTipsShow } okText={ "前往查看" } cancelText="重新模拟"
                onOk={ _ => {
                    if (formulaCover.name) {
                        window.location.href = `/formulaSquare#detail?id=${ FormulaId }&type=main`
                    } else {
                        window.location.href = `/formulaSquare#main?nav=2`
                    }
                } }
                onCancel={ () => {
                    
                    emptyFormula()
                    setSuTipsShow(false)
                    window.location.href = '/formulaSquare#replacingAnalog'
                } } width="500px"
            >
                <div className="flex jtyct">
                    <h2 style={ { fontWeight: '700', padding: "35px 0" } }>保存配方成功！</h2>
                </div>
            </antdUI.Modal>
            <antdUI.Modal
                title={ `我的库存` } visible={ myInvenShow } footer={ null } maskClosable={ false }
                width="1248px" destroyOnClose={ true } onCancel={ _ => setMyInvenShow(false) }
            >
                <InventoryContainer type="replacing"
                                    setMaterialData={ setMaterialData } materialData={ materialData }
                ></InventoryContainer>
            </antdUI.Modal>
            
            
            { containsItemsShow ? <FormulaContainsItems
                boxValue={ boxValue }
                showOp={ {
                    show: containsItemsShow,
                    setShow: setConItemsShow
                } }/> : '' }
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(ReplacingAnalog);
