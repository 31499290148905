import React, { $api, $tools, antdUI, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo'

function CheckUnBind(props) {
    const { setUserSignIn, setUnBindCheck } = props;
    const [ visible, setVisible ] = useState(props.visible)
    const [ btnLoading, setLoading ] = useState(false)
    return (
        <div className="P-code-check-BdSteam">
            <antdUI.Modal
                title="解绑确认" visible={ visible }
                footer={ [
                    <antdUI.Button onClick={ () => {
                        setVisible(false);
                        setUnBindCheck(false)
                    } }>
                        取消
                    </antdUI.Button>,
                    <antdUI.Button type="primary" loading={ btnLoading } onClick={ async () => {
                        setLoading(true)
                        const result = await $api.user.unbindSteam()
                        setLoading(false)
                        setVisible(false);
                        setUnBindCheck(false)
                        if ($tools.checkResult(result)) {
                            setUserSignIn();
                            localStorage.setItem(`csgoInventoryCacheTime`, "")
                            localStorage.setItem(`dota2InventoryCacheTime`, "")
                        }
                    } }>
                        确定
                    </antdUI.Button>
                ] }
                onCancel={ () => {
                    setVisible(false);
                    setUnBindCheck(false)
                } }
                width="393px" maskClosable={ false } destroyOnClose={ true } centered={ true }
            >
                <div><p>请再次确认是否解绑您的steam，该次成功解绑后，您下次解绑steam必须等待一段时间。</p></div>
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
        }
    }
)(CheckUnBind);
