import React, { antdUI, useState, $api, $tools } from 'react';

function Comments({ data, setData, target, cur, _open, drawerData, comTotal }) {
    const { setChangeTarget } = target || {}
    const { curIdx, setCurIdx } = cur || {}
    const { open, setOpen } = _open || {}
    const { commentChild, setCommentChild } = drawerData || {}
    const { commentsTotal, setCommentsTotal } = comTotal || {}
    
    const changeUser = target => {
        if (curIdx === `${ target.fCid }${ target.fCCid ? `-${ target.fCCid }` : '' }`) {
            setCurIdx("")
            setChangeTarget({})
        } else {
            setCurIdx(`${ target.fCid }${ target.fCCid ? `-${ target.fCCid }` : '' }`)
            setChangeTarget(target)
        }
    }
    
    const showDrawer = (child) => {
        setCommentChild(child)
        setCurIdx("")
        setChangeTarget({})
        setOpen(true);
    };
    
    const onClose = () => {
        setCommentChild([])
        setCurIdx("")
        setChangeTarget({})
        setOpen(false);
    };
    const [ operateData, setOperateData ] = useState({})
    const [ operateMShow, setOperateMShow ] = useState(false)
    const [ sureOperateLoad, setSureOperateLoad ] = useState(false)
    const len = 3
    const { Menu, Dropdown, Space, Drawer, Modal, message, Image } = antdUI
    const menu = data => <Menu>
        <Menu.Item onClick={ () => {
            setOperateData({ ...data, value: "举报" })
            setTimeout(_ => setOperateMShow(true), 300)
        } }>举报</Menu.Item>
    </Menu>
    const menuDel = data => <Menu>
        {/*<Menu.Item onClick={ () => {*/ }
        {/*    setOperateData({ ...data, value: "举报" })*/ }
        {/*    setTimeout(_ => setOperateMShow(true), 300)*/ }
        {/*} }>举报</Menu.Item>*/ }
        <Menu.Item onClick={ () => {
            setOperateData({ ...data, value: "删除" })
            setTimeout(_ => setOperateMShow(true), 300)
        } }>删除</Menu.Item>
    </Menu>
    return <div className="component-comments-section">
        { data[0] &&
        data.map(({ avatar, nickname, createTime, content, picture, isMe, id: fCid, commentsChild }) => {
            return <div key={ fCid } className="one-line flex">
                <antdUI.Avatar size={ 42 } src={ avatar }/>
                <div className="content">
                    <h3 onClick={ () => changeUser({ nickname, fCid }) }
                        className={ [ curIdx === String(fCid) ? "change nickname" : "nickname" ] }>
                        <span className="reply">回复: </span>
                        <p className="text-ov1">{ nickname }</p>
                        <span className="date"> { createTime }</span>
                    </h3>
                    <p>{ content }</p>
                    <div className="formula-comments-line-operate">
                        <Dropdown
                            overlay={ () =>
                                isMe ? menuDel({ content, picture, fCid })
                                    : menu({ content, picture, fCid })
                            } trigger={ [ 'click' ] }>
                            <Space style={ { cursor: "pointer" } }>...</Space>
                        </Dropdown>
                    </div>
                    { picture &&
                    <Image width={ 100 } style={ { marginTop: '6px', cursor: "pointer" } }
                           src={ picture.match("data:") ? picture : `${ React.StaticBaseUrl }/${ picture }` }
                           alt=""/>
                    }
                    {
                        commentsChild?.[0] && <div className="tow-line-box">
                            {
                                commentsChild.length > len &&
                                <p className="title flex" onClick={ () => showDrawer(commentsChild) }>
                                    查看全部{ commentsChild.length }条回复<i className="iconfont icon-xiangxia"></i>
                                </p>
                            }
                            
                            <div className="component-comments-section">
                                { commentsChild.slice(0, len).map((
                                    {
                                        avatar, nickname, createTime, content,
                                        isMe, id: fCCid, target, targetIsDel
                                    }) => {
                                    return <div key={ `${ fCid }-${ fCCid }` } className="one-line flex">
                                        <antdUI.Avatar size={ 42 } src={ avatar }/>
                                        <div className="content">
                                            <h3 onClick={ () => changeUser({ nickname, fCid, fCCid }) }
                                                className={ [ curIdx === `${ fCid }-${ fCCid }` ? "change nickname" : "nickname" ] }>
                                                <span className="reply">回复:</span>
                                                <p className="text-ov1">{ nickname }</p>
                                                {
                                                    target?.nickname && <div className="nickname-comment-target flex">
                                                        <i style={ {
                                                            fontSize: "12px", margin: '0 8px', fontWeight: "bold",
                                                            color: targetIsDel && 'red'
                                                        } } className="iconfont icon-arrow-right"/>
                                                        <p className="text-ov1"
                                                           style={ { maxWidth: "160px" } }>{ target.nickname }</p>
                                                    </div>
                                                }
                                                <span className="date"> { createTime }</span>
                                            </h3>
                                            <p>{ content }</p>
                                            <div className="formula-comments-line-operate">
                                                <Dropdown
                                                    overlay={ () =>
                                                        isMe ? menuDel({ content, fCid, fCCid })
                                                            : menu({ content, fCid, fCCid })
                                                    } trigger={ [ 'click' ] }>
                                                    <Space style={ { cursor: "pointer" } }>...</Space>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                }) }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }) }
        
        <Drawer className="formula-comments" title="评论详情" zIndex={ 500 }
                placement="right" onClose={ onClose } width={ "80%" }
                visible={ open } maskClosable={ true }>
            {
                commentChild?.[0] && <div className="tow-line-box">
                    <div className="component-comments-section">
                        { commentChild.map((
                            { avatar, nickname, createTime, fCid, content, isMe, id: fCCid, target, targetIsDel }) => {
                            return <div key={ `${ fCid }_${ fCCid }` } className="one-line flex">
                                <antdUI.Avatar size={ 42 } src={ avatar }/>
                                <div className="content">
                                    <h3 onClick={ () => changeUser({ nickname, fCid, fCCid }) }
                                        className={ [ curIdx === `${ fCid }-${ fCCid }` ? "change nickname" : "nickname" ] }>
                                        <span className="reply">回复:</span>
                                        <p className="text-ov1">{ nickname }</p>
                                        {
                                            target?.nickname && <div className="nickname-comment-target flex">
                                                <i style={ {
                                                    fontSize: "12px", margin: '0 8px', fontWeight: "bold",
                                                    color: targetIsDel && 'red'
                                                } } className="iconfont icon-arrow-right"/>
                                                <p className="text-ov1"
                                                   style={ { maxWidth: "160px" } }>{ target.nickname }</p>
                                            </div>
                                        }
                                        <span className="date"> { createTime }</span></h3>
                                    <p>{ content }</p>
                                    <div className="formula-comments-line-operate">
                                        <Dropdown
                                            overlay={ () =>
                                                isMe ? menuDel({ content, fCid, fCCid })
                                                    : menu({ content, fCid, fCCid })
                                            } trigger={ [ 'click' ] }>
                                            <Space style={ { cursor: "pointer" } }>...</Space>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                </div>
            }
        </Drawer>
        
        <Modal
            title="提示" visible={ operateMShow }
            footer={ [
                <antdUI.Button key={ 1 } onClick={ () => setOperateMShow(false) }>取消</antdUI.Button>,
                <antdUI.Button key={ 2 } type="primary" loading={ sureOperateLoad } onClick={ async () => {
                    let result, op = {}
                    operateData.fCid && (op.fCid = operateData.fCid)
                    if (operateData.fCCid) {
                        delete op.fCid
                        op.fCCid = operateData.fCCid
                    }
                    setSureOperateLoad(true)
                    if (operateData.value === '删除') {
                        result = await $api.formula.delComment(op)
                        setSureOperateLoad(false)
                        if ($tools.checkResult(result)) {
                            message.success('删除成功！')
                            const _data = JSON.parse(JSON.stringify(data))
                            _data.forEach((item, idx) => {
                                if (operateData.fCid === item.id) {
                                    if (operateData.fCCid) {
                                        item.commentsChild.forEach((_, i) => {
                                            if (operateData.fCCid === _.id)
                                                data[idx].commentsChild.splice(i, 1)
                                        })
                                        setCommentsTotal(commentsTotal - 1)
                                    } else {
                                        setCommentsTotal(commentsTotal -
                                            (data[idx]?.commentsChild?.[0] ? data[idx]?.commentsChild?.length + 1 : 1)
                                        )
                                        data.splice(idx, 1)
                                    }
                                }
                            })
                            setData(data)
                        }
                    } else if (operateData.value === '举报') {
                        result = await $api.formula.reportComment(op)
                        setSureOperateLoad(false)
                        if ($tools.checkResult(result)) message.success('举报成功！')
                    }
                    setOperateMShow(false)
                    setOperateData({})
                    
                } }>
                    确定
                </antdUI.Button>
            ] }
            onCancel={ () => setOperateMShow(false) }
            bodyStyle={ { minHeight: '180px' } }
            maskClosable={ false }>
            <h3>您确定要 <span style={ { color: "red" } }>"{ operateData.value }"</span> 这条评论吗？</h3>
            <p style={ {
                color: "#959595", marginTop: '10px', padding: '10px 20px'
            } }>{ operateData.content }{ operateData.picture && "[图片]" }</p>
        </Modal>
    </div>
}

export default Comments;
