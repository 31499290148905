import React from "react";
import './index.scss';
import MainFormula from './mainFormula'
import ReplacingAnalog from './replacingAnalog'
import Square from "./square";
import FormulaCollection from "./formulaCollection";

function FormulaSquareContainer(props) {
    const {
        updateCollectionData,
        nav: { currentNav, setNav },
        selectItem: { selectItemCur, setSelectItemCur },
        replacAna: { replacAnaData, replacAnaTotal, repCurPage, replacAnaDataPageSize, repChangePage },
        navFilterValue: { itemType, rarity, exterior, collection, quality, classification, searchVal },
        navFilterChange: { setRarity }
    } = props
    return (
        <div className="formula-square-container">
            {
                (_ => {
                    switch (currentNav) {
                        case 'main':
                            return <MainFormula
                                nav={ { currentNav, setNav } }
                                navFilterValue={ {
                                    itemType,
                                    rarity,
                                    exterior,
                                    collection,
                                    quality,
                                    classification,
                                    searchVal
                                } }
                            />
                        case 'replacingAnalog':
                            return <ReplacingAnalog
                                replacAna={ {
                                    replacAnaData,
                                    replacAnaTotal,
                                    repCurPage,
                                    replacAnaDataPageSize,
                                    repChangePage
                                } }
                                navFilterChange={ { setRarity } }
                                selectItem={ { selectItemCur, setSelectItemCur } }
                                navFilterValue={ {
                                    itemType,
                                    rarity,
                                    exterior,
                                    collection,
                                    quality,
                                    classification,
                                    searchVal
                                } }
                            />
                        case 'square':
                            return <Square
                                nav={ { currentNav, setNav } }
                                navFilterValue={ {
                                    itemType,
                                    rarity,
                                    exterior,
                                    collection,
                                    quality,
                                    classification,
                                    searchVal
                                } }
                            />
                        case 'collection':
                            return <FormulaCollection
                                updateCollectionData={ updateCollectionData }
                                nav={ { currentNav, setNav } }
                                navFilterValue={ {
                                    itemType,
                                    rarity,
                                    exterior,
                                    collection,
                                    quality,
                                    classification,
                                    searchVal
                                } }
                            />
                        default:
                            return '错误的页面'
                    }
                })
                ()
            }
        </div>
    )
}

export default FormulaSquareContainer
