import React, { $api, $tools, antdUI, useEffect, useRef, useState, } from "react";
import "./index.scss";
import { CsgoFilter, Dota2Filter } from "@/components/itemFilter";
import BuyingItemContainer from "./buyingItemContainer";
import Sort from './filter/sort';
import PriceFilter from './filter/priceFilter';

const tabData = [
    { label: "正在求购", key: 2 },
    { label: "求购记录", key: 1 },
    { label: "发布求购", key: 3 },
];

function BuyingContainer(props) {
    const { currentGame } = props;
    const { getUrlQueryValue } = $tools
    const game = getUrlQueryValue('game') || '730'
    const nav = getUrlQueryValue('nav') || 'onSale'
    const buyingNav = getUrlQueryValue('buyingNav') || 2
    const [ currentPage, setCurrentPage ] = useState(1),
        [ total, setTotal ] = useState(0),
        [ buyingData, setBuyingData ] = useState([]),
        [ curNav ] = useState(Number(buyingNav)),
        [ issuanceDialog, setIssuanceDialog ] = useState(false),
        [ sort, setSort ] = useState(0),
        [ minPrice, setMinPrice ] = useState(),
        [ maxPrice, setMaxPrice ] = useState(),
        [ configData, setConfigData ] = useState({});
    
    // const [spiLoading, setSpiLoading] = useState(true);
    
    const pageSize = 35;
    const childRef = useRef();
    const { checkResult } = $tools;
    
    function closeHandle() {
        setIssuanceDialog(false)
    }
    
    
    const handleGetItemsOp = ({
                                  dQuality,
                                  dRarity,
                                  hero,
                                  rarity,
                                  exterior,
                                  itemType,
                                  quality,
                                  sort,
                                  weapon,
                                  collection,
                                  searchContent,
                                  minPrice,
                                  maxPrice,
                                  currentPage
                              }) => {
        let postData = {};
        minPrice && (postData.minPrice = minPrice);
        maxPrice && (postData.maxPrice = maxPrice);
        weapon && (postData.weapon = weapon);
        searchContent && (postData.searchContent = searchContent);
        
        
        if (rarity && rarity[0] && rarity[0] !== "unlimited") {
            if (rarity[0] === "Type_CustomPlayer") {
                postData.rarity = rarity[1];
            } else {
                postData.rarity = rarity[0];
            }
        }
        exterior &&
        exterior[0] &&
        exterior[0] !== "unlimited" &&
        (postData.exterior = exterior[0]);
        quality &&
        quality[0] &&
        quality[0] !== "unlimited" &&
        (postData.quality = quality[0]);
        if (itemType?.[1]) {
            postData.type = itemType[0];
            postData.weapon = itemType[1];
        } else {
            itemType?.[0] && (postData.type = itemType[0])
        }
        itemType && itemType[0] && (postData.type = itemType[0]);
        hero && hero[1] && (postData.hero = hero[1]);
        dQuality && (postData.quality = dQuality);
        dRarity && (postData.rarity = dRarity);
        postData.curPage = currentPage;
        sort && (postData.priceSort = sort);
        collection?.[1] && (postData.itemSet = collection?.[1]);
        currentPage ? setCurrentPage(currentPage) : setCurrentPage(1);
        return postData;
    };
    
    const getData = async (config = {}, type = 'init') => {
        setBuyingData([]);
        let _configData = type === 'init' ? {} : configData;
        let postConfig = { ..._configData, ...config };
        let postData = handleGetItemsOp(postConfig);
        setConfigData(postConfig);
        // setSpiLoading(true)
        setTotal(0);
        let res = await $api.market.getMarketData({
            buying: 1,
            appid: currentGame,
            pageSize,
            ...postData,
        });
        // setSpiLoading(false)
        if (checkResult(res)) {
            setTotal(res.data.total);
            setTimeout(() => {
                setBuyingData(res.data.items);
            }, 100);
        }
    };
    
    useEffect(() => {
        if (curNav === 3) {
            setConfigData({});
            getData({}).then();
        }
        // eslint-disable-next-line
    }, [ currentGame, curNav ]);
    
    
    const backHead = () => {
        if (currentGame === "730") {
            return (
                <CsgoFilter sort={ sort } minPrice={ minPrice } maxPrice={ maxPrice } type="onSalePut" cRef={ childRef }
                            filterChange={ getData }/>
            );
        } else if (currentGame === "570") {
            return (
                <Dota2Filter sort={ sort } minPrice={ minPrice } maxPrice={ maxPrice } type="onSalePut"
                             cRef={ childRef } filterChange={ getData }/>
            );
        }
    };
    return (
        <div className="buyingContainer">
            <div className="pur-container-head">
                {
                    curNav === 3 &&
                    <div className="item-filter" style={ { padding: "6px 0 22px 0" } }>
                        { backHead() }
                    </div>
                }
                
                <div className="buyingContainer-type">
                    <div className="on-sale-nav">
                        { tabData.map((item) => {
                            return (
                                <span
                                    key={ item.key }
                                    onClick={ () => {
                                        window.location.href = `/myPur?game=${ game }&nav=${ nav }&buyingNav=${ item.key }`
                                    } }
                                    className={ curNav === item.key ? "btn" : "active" }
                                >
                  { item.label }
                </span>
                            );
                        }) }
                    </div>
                    { curNav === 3 &&
                        <div className="on-sale-handle-box">
                            <Sort sort={ sort } setSort={ setSort }></Sort>
                            <PriceFilter setMinPrice={ setMinPrice } setMaxPrice={ setMaxPrice }></PriceFilter>
                        </div>
                    }
                </div>
            </div>
            {/*<antdUI.Spin*/ }
            {/*  tip="正在加载 ..."*/ }
            {/*  style={{ margin: '150px auto 0 auto', width: '100%' }}*/ }
            {/*  size="large" spinning={spiLoading} />*/ }
            <BuyingItemContainer
                itemData={ buyingData }
                curNav={ curNav }
                currentGame={ currentGame }
            ></BuyingItemContainer>
            
            {
                total > 0 && buyingData?.[0] &&
                <div className="pur-pagination">
                    { total && curNav === 3 ? <antdUI.Pagination
                        onChange={ (currentPage, pageSize) => {
                            getData({ currentPage });
                            setCurrentPage(currentPage)
                        } }
                        showSizeChanger={ false } current={ currentPage } defaultCurrent={ 1 } total={ total }
                        pageSize={ pageSize }/> : '' }
                </div>
            }
            
            <antdUI.Modal
                visible={ issuanceDialog }
                onCancel={ closeHandle }
            >
            
            </antdUI.Modal>
        </div>
    );
}

export default BuyingContainer
