import React, { $api, $copy, $tools, antdUI, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';

const promotedLink = "https://www.mobi2077.com/#promotedCode="

function Preferences(props) {
    const { userInfo, setUserSignIn } = props;
    const [ visible, setVisible ] = useState(false);
    const [ newCode, setNewCode ] = useState('');
    const [ newPerson, setNewPerson ] = useState('');
    const [ changeType, setChangeType ] = useState('code');    //code 推广码   person 推广人
    
    const changePromotion = async () => {
        let res = {};
        if (changeType === 'code') {
            res = await $api.user.setPromotionCode({ promotion_code: newCode })
        } else {
            res = await $api.user.setPromotionMan({ promotion_code: newPerson })
        }
        if ($tools.checkResult(res)) {
            antdUI.message.success('修改成功!');
            setUserSignIn();
            setVisible(false);
        }
    }
    
    const delayChangePromotionCode = $tools.debounce(changePromotion, 1000, true)
    
    const inputChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9A-Z]/g, '').slice(0, 6);
        changeType === 'code' ? setNewCode(value)
            : setNewPerson(value);
    }
    
    const openVisible = (type) => {
        setVisible(true);
        setChangeType(type);
        setNewPerson('');
        setNewCode('');
    }
    
    function getTip() {
        if (changeType === 'code') {
            return '此项为修改用户自己的推广码，推广码必须6个字符，只能包含数字和大写英文字母，三个月内只能修改一次。'
        } else if (changeType === 'person') {
            return '此项为更换用户的推广人，更换成功之后之前的推广关系就会改变，请谨慎更换。用户推广人的推广码必须6个字符，只能包含数字和大写英文字母，三个月内只能更换一次。'
        } else {
        
        }
    }
    
    const changeOnlyBuySendoffer = async (type) => {
        let res = await $api.user.updatePreferences({ onlyBuyerSendoffer: Number(! type) })
        if ($tools.checkResult(res)) setUserSignIn()
    }
    
    const changeAllowACounteroffer = async (type) => {
        let res = await $api.user.updatePreferences({ allowACounteroffer: Number(! type) })
        if ($tools.checkResult(res)) setUserSignIn()
    }
    return (
        <div className="preferences">
            <div className="title">
                <h1>偏好设置</h1>
            </div>
            <div className="dividing-line"></div>
            <div className="send-offers">
                <antdUI.Checkbox
                    onClick={ changeOnlyBuySendoffer.bind(null, userInfo.onlyBuyerSendoffer) }
                    checked={ userInfo.onlyBuyerSendoffer }
                    style={ { marginRight: '10px' } }
                />
                选择只让买家向我发送报价
            </div>
            <div className="dividing-line"></div>
            <div className="send-offers">
                <antdUI.Checkbox
                    onClick={ changeAllowACounteroffer.bind(null, userInfo.allowACounteroffer) }
                    checked={ userInfo.allowACounteroffer }
                    style={ { marginRight: '10px' } }
                />
                允许买家还价
            </div>
            
            <div className="dividing-line"></div>
            <div className="promotion-code">
                <p>推广码：</p>
                <span className="code-text">{ userInfo?.promotion_code }</span>
                <antdUI.Button className="antd-button-sm o" type="primary"
                               onClick={ () => $copy(userInfo?.promotion_code) }>复制推广码
                </antdUI.Button>
                <antdUI.Button className="antd-button-sm" type="primary"
                               onClick={ () => openVisible('code') }>修改推广码
                </antdUI.Button>
            </div>
            <div className="dividing-line"></div>
            <div style={ { width: 691 } } className="promotion-code">
                <p>我的推广链接：</p>
                <span className="code-text">{ promotedLink }{ userInfo.promotion_code }</span>
                <antdUI.Button className="antd-button-sm" type="primary"
                               onClick={ () => $copy(`${ promotedLink }${ userInfo.promotion_code }`) }>复制推广链接
                </antdUI.Button>
            </div>
            <div className="dividing-line"></div>
            <div className="promotion-person">
                更换推广人
                <antdUI.Button className="antd-button-sm" type="primary"
                               onClick={ () => openVisible('person') }>更换
                </antdUI.Button>
            </div>
            
            <antdUI.Modal
                title={ changeType === 'code' ? "修改推广码" : "更换推广人" }
                visible={ visible }
                onOk={ delayChangePromotionCode }
                onCancel={ () => setVisible(false) }
                okText="确认"
                bodyStyle={ { minHeight: '180px' } }
                cancelText="取消"
                maskClosable={ false }
            >
                <antdUI.Input
                    autoFocus
                    value={ changeType === 'code' ? newCode : newPerson }
                    onKeyDown={ _ => _.keyCode === 13 && delayChangePromotionCode }
                    onChange={ inputChange } minLength={ 2 } maxLength={ 14 }
                    placeholder={ changeType === 'code' ? "请输入新推广码" : "请输入新推广人推广码" }/>
                <br/><br/>
                <p style={ { color: '#2DAF61' } }>{ getTip() }</p>
            </antdUI.Modal>
        </div>
    
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(Preferences);
