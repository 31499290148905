import React, { antdUI } from "react";
import './customItemBox.scss'

const rarityData = [
    {
        "label": "隐秘",
        "value": "Rarity_Ancient_Weapon",
        "color": "#EB4B4B"
    },
    {
        "label": "保密",
        "value": "Rarity_Legendary_Weapon",
        "color": "#D32CE6"
    },
    {
        "label": "受限",
        "value": "Rarity_Mythical_Weapon",
        "color": "#8847FF"
    },
    {
        "label": "军规级",
        "value": "Rarity_Rare_Weapon",
        "color": "#4B69FF"
    },
    {
        "label": "工业级",
        "value": "Rarity_Uncommon_Weapon",
        "color": "#5E98D9"
    }
]


function ReplacingAnalogCustomItem(props) {
    const wearData = React.$wearData1
    const {
        itemData: _,
        quality,
        targetBox: { targetBoxName, list, len },
        type,
        materialOp: { materialData = [], setMaterialData = () => {} }
    } = props;
    
    return (
        <div
            className="replacing-analog-custom-item"
            onLoad={ (e) => (e.currentTarget.style.opacity = 1) }
        >
            <div className="item-quality" style={ { alignItems: "start" } }>
                <p
                    value={ _.en_rarity }
                    style={ {
                        color: `${ rarityData.filter(e => e.value === _.en_rarity)?.[0]?.color }`,
                        padding: "0 2px", height: "18px", lineHeight: "18px"
                    } }
                    className="item_quality"
                >
                    { _.rarity }
                </p>
                { quality === 'strange' && <p
                    value="strange" className="item_quality"
                    style={ {
                        color: "rgb(207, 106, 50)",
                        padding: "0 2px", height: "18px", lineHeight: "18px"
                    } }
                >ST</p> }
            </div>
            { type === 'material' && <h4 className="material-item-btn-box">
                <i className="iconfont icon-delete" onClick={ () => {
                    const _tData = JSON.parse(JSON.stringify(materialData))
                    _tData.splice(_.key, 1)
                    if (! _tData[0]) localStorage.setItem('formulaMaterialData', '[]')
                    setMaterialData(_tData)
                } } title={ "删除" }></i>
                <i className="iconfont icon-copy" onClick={ () => {
                    const _tData = JSON.parse(JSON.stringify(materialData))
                    if (_tData.length >= 10) return antdUI.message.warning('最多选择10件物品进行汰换！')
                    setMaterialData([ ..._tData, JSON.parse((JSON.stringify(_tData[_.key]))) ])
                } } title={ "复制" }></i>
            </h4> }
            <div className="reAnCustom-item-img">
                <img src={ `${ _.cover }` } alt={ _.name }/>
            </div>
            <div className="reAnCustom-item-belongs" title={ `可能出现的上级物品  ${ list }` }>
                <h6>{ targetBoxName }({ len })</h6>
            </div>
            <div className="Wear-range">
                <h6 className="wear-range-v">
                    磨损范围：{ _.min } - { _.max }
                </h6>
                <div className="wear-show">
                    <div className="wear_el-start"
                         style={ {
                             width: `${ _.min * 100 }%`,
                             backgroundColor: '#fff'
                         } }
                         title={ _.min }></div>
                    { wearData.map((item, index) => {
                        return (
                            <div className="wear_el" key={ index }
                                 style={ {
                                     width: `${ item.ratio }%`,
                                     backgroundColor: item._bcc
                                 } }
                                 title={ item.title }></div>
                        )
                    }) }
                    
                    <div className="wear_el-end"
                         style={ {
                             width: `${ (1 - _.max) * 100 }%`,
                             backgroundColor: '#fff'
                         } }
                         title={ _.max }></div>
                </div>
            </div>
        </div>
    )
}

export default ReplacingAnalogCustomItem
