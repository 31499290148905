import React, { useState } from "react";
import CsD2Box from "./cs-d2Box";
import CsgoItems2DModal from '@/components/csgoItems2D'

function BoxContainer({ saleData, set_onShelfData, onShelfData, currentGame }) {
    const [ CsgoItems2DModalData, setModalData ] = useState({})
    const [ CsgoItems2DModalView, setView ] = useState(false)
    const changeItem = (cItem, type) => {
        let data = JSON.parse(JSON.stringify(onShelfData)); //如果不转将引用变量原地址而导致监听不到变化
        if (type === "cancel") {
            data.forEach((item, index, arr) => {
                if (item.assetid === cItem.assetid) {
                    arr.splice(index, 1);
                }
            });
        } else if (type === "select") {
            data.push(cItem);
        }
        set_onShelfData(JSON.parse(JSON.stringify((data))))
    };
    
    
    const style = {
        display: "flex",
        flexWrap: "wrap",
    };
    return (
        <>
            <div>
                <div style={ style }>
                    { saleData[0] && saleData.map((item, index) => {
                        return (
                            <CsD2Box
                                currentGame={ currentGame } itemSelect={ onShelfData } changeItem={ changeItem }
                                selected={ onShelfData.map((item) => item.assetid).includes(item.assetid) }
                                key={ index } style={ { marginLeft: index % 5 ? "0.5%" : "" } }
                                index={ index + 1 } csData={ item }
                                d2Config={ { setModalData, setView } }
                            />
                        );
                    }) }
                </div>
                { CsgoItems2DModalView &&
                    <CsgoItems2DModal marketData={ CsgoItems2DModalData }
                                      view={ CsgoItems2DModalView }
                                      setView={ setView }></CsgoItems2DModal> }
            </div>
        </>
    );
}

export default BoxContainer;
