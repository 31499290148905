import React from 'react';
import "./index.scss";
import InitiateAPurchase from './navPage/initiateAPurchase'
import IsBuying from './navPage/isBuying';
import Record from './navPage/record'

function BuyingItemContainer(props) {
    const { itemData, selectData, curNav, currentGame } = props;
    
    const getNavPage = (curNav) => {
        switch (curNav) {
            case 3:
                return <InitiateAPurchase currentGame={ currentGame } itemData={ itemData }
                                          selectData={ selectData }></InitiateAPurchase>;
            case 2:
                return <IsBuying></IsBuying>;
            case 1:
                return <Record/>;
            default:
                return
        }
    }
    
    return (
        <div>
            { getNavPage(curNav) }
        </div>
    );
}

export default BuyingItemContainer;
