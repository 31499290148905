import React, { useRef, useEffect, antdUI, useState } from 'react';
import { InventoryNav, InventoryContainer, BackpackContainer } from '@/components/myInventory'
import './index.scss'
import { connect } from "react-redux";

function MyInventory(props) {
    const cacheIType = localStorage.getItem('cacheIType') || 'steam'
    const cacheGame = localStorage.getItem('cacheGame') || '730'
    const { userInfo } = props
    const [ currentNav, setNav ] = useState(cacheIType);
    const [ currentGame, setCurGame ] = useState(cacheGame)
    const childRef = useRef();
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
        if (userInfo.uid && ! userInfo.steam.steamid) {
            antdUI.message.warning('请先完善Steam绑定！')
        }
    }, [ userInfo ])
    return (
        <div className="myInventory">
            <img className="myInventory-banner" src={ require('@/images/tradingMarket/banner2@2x.png') } alt=""/>
            <InventoryNav game={ { currentGame, setCurGame } } nav={ { currentNav, setNav } }
                          navChange={ type => childRef?.current ? childRef.current.setFilter(type) : '' }/>
            { currentNav === 'steam' && <InventoryContainer type="myInventory" cRef={ childRef }/> }
            { currentNav === 'backpack' && <BackpackContainer game={ { currentGame, setCurGame } }/> }
        </div>
    )
    
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(MyInventory);
