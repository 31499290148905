import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './index.scss'
import { connect } from "react-redux";

const getInventoorySetting = (
    <div className="apikey-box-text" style={ {
        padding: '14px', width: '260px', backgroundColor: '#191919',
        boxShadow: `0px 0px 6px 1px #191919`
    } }>
        <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>获取步骤：</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>1.点击前往获取；</p>
        <p style={ {
            color: '#fff',
            fontSize: '14px'
        } }>2.进入steam库存隐私设置页面，需要将隐私设置中的“我的个人资料”和“库存”设置为公开；</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>3.资料的公开可能会存在延迟。</p>
    </div>
)
const titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
const columns = [
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '40%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>数量</p>,
        dataIndex: 'planNum',
        key: 'planNum',
        align: 'center',
        width: '15%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>取回状态</p>,
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        width: '22.5%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>取回成功时间</p>,
        dataIndex: 'retrieveTime',
        key: 'retrieveTime',
        align: 'center',
        width: '22.5%',
        render: text => text
    }
]
const navData = [
    {
        title: 'steam库存',
        icon: require('@/images/inventory/steam@2x.png'),
        type: 'steam'
    },
    {
        title: 'MOBI 背包',
        icon: require('@/images/inventory/mobi backpack.png'),
        type: 'backpack'
    }
]

const gameCategory = [
    {
        title: 'CSGO饰品',
        icon: require('@/images/home/csgoweixuanzhong@2x.png'),
        select_icon: require('@/images/profile/csgo_c.png'),
        type: '730'
    },
    {
        title: 'DOTA2饰品',
        icon: require('@/images/home/dota2.png'),
        select_icon: require('@/images/profile/dota2_c.png'),
        type: '570'
    }
]

const backpackCategory = [
    {
        title: 'CSGO饰品',
        icon: require('@/images/home/csgoweixuanzhong@2x.png'),
        select_icon: require('@/images/profile/csgo_c.png'),
        type: '730'
    },
    {
        title: 'DOTA2饰品',
        icon: require('@/images/home/dota2.png'),
        select_icon: require('@/images/profile/dota2_c.png'),
        type: '570'
    },
    {
        title: '购物金（不可提现）',
        icon: require('@/images/profile/balancei.png'),
        select_icon: require('@/images/profile/select_balancei.png'),
        type: '88'
    },
    {
        title: 'MOE代币',
        icon: require('@/images/profile/mobi_token.png'),
        select_icon: require('@/images/profile/select_mobi_token.png'),
        type: '77'
    }
]

const recordNavData = [
    { title: 'CSGO 饰品', val: 730 },
    { title: 'DOTA2 饰品', val: 570 },
    { title: '购物金（不可提现）', val: 88 },
    { title: 'MOE代币', val: 77 }
]


function InventoryNav(props) {
    const { Tabs } = antdUI
    const { userInfo, nav: { currentNav, setNav }, game: { currentGame, setCurGame } } = props
    const [ retrieveRecordModalView, setModalShow ] = useState(false)
    const [ recordNav, setRecordNav ] = useState(0)
    const [ retrieveRecordData, setRetrieveRecordData ] = useState([])
    const [ recordLoading, setRecordLoading ] = useState(false)
    const [ recordTotal, setRecordTotal ] = useState(0)
    const [ activeOpen, setActiveOpen ] = useState(0);
    const [ page, setPage ] = useState(1)
    const icon_url_config = {
        88: require('@/images/rollRoom/buketixianyue xiao@2x.png'),
        77: require('@/images/rollRoom/mobi xiao@2x.png')
    }
    const pageSize = 10
    const getRecordData = async () => {
        setRecordLoading(true)
        setRecordTotal(0)
        const result = await $api.backpack.retrieveItemRecord({ appid: recordNav, pageSize, curPage: page })
        setRecordLoading(false)
        if ($tools.checkResult(result)) {
            setRetrieveRecordData(result.data.data.map(_ => {
                if (recordNav === 730 || recordNav === 570) {
                    _.market_name = _.OnSalePutItemDota2s?.[0]?.market_name || _.OnSalePutItemCsgos?.[0]?.market_name
                    _.retrieveItem = _.OnSalePutItemDota2s || _.OnSalePutItemCsgos
                } else {
                    _.market_name = recordNav === 77 ? 'MOBI 代币' : recordNav === 88 ? '购物金（不可提现）' : ''
                }
                return _
            }))
            setRecordTotal(result.data.total)
        }
    }
    
    function expansionHandle(item) {
        if (activeOpen !== item.id) {
            setActiveOpen(item.id);
        } else {
            setActiveOpen(0);
        }
    }
    
    useEffect(_ => {
        recordNav !== 0 && getRecordData().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recordNav, page ])
    
    useEffect(() => {
        props.navChange(currentGame)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame ])
    
    return (
        <div className="inventory-nav">
            <div className="inventory-nav-container">
                { navData.map((v, i) => {
                    const cls = v.type === currentNav ? 'nav-item current' : 'nav-item'
                    return (
                        <div key={ i }
                             onClick={ () => {
                                 localStorage.setItem('cacheIType', v.type)
                                 setNav(v.type)
                                 if (v.type === 'steam' && [ "77", "88" ].indexOf(currentGame) !== -1) {
                                     localStorage.setItem('cacheGame', "730")
                                     setCurGame("730")
                                 }
                             } }
                             className={ cls }>
                            <img className="inventory-nav-icon" src={ v.icon } alt=""/>
                            { v.title }
                        </div>
                    )
                }) }
                { currentNav === 'steam' && <div className="inventory-setting">
                    <div className="content" style={ { margin: '0' } }>
                        <p className="get-trading"
                           onClick={ () => {
                               if (userInfo.steam.steamid) {
                                   window.open(`https://steamcommunity.com/profiles/${ userInfo.steam.steamid }/edit/settings/`)
                               }
                           } }
                        >前往公开Steam库存</p>
                        <antdUI.Popover
                            placement="right"
                            content={ getInventoorySetting }
                        >
                            <i className="iconfont icon-wenhao"></i>
                        </antdUI.Popover>
                    </div>
                </div> }
                { currentNav === 'backpack' && <div className="retrieve-record">
                    <div className="content" style={ { margin: '0' } }>
                        <i style={ { fontSize: "18px", marginRight: "3px" } } className="iconfont icon-message"></i>
                        <p className="get-trading"
                           onClick={ () => {
                               setRecordNav(730)
                               setModalShow(true)
                           } }
                        >背包取回记录</p>
                    </div>
                </div> }
            </div>
            <div className="inventory-game-container">
                { (currentNav === 'steam' ? gameCategory : backpackCategory).map((v, i) => {
                    let _cur = v.type === currentGame,
                        _cn = _cur ? 'nav-item current' : 'nav-item';
                    return (<div key={ i }
                                 style={ { marginLeft: i === 0 && '15px' } }
                                 onClick={ () => {
                                     localStorage.setItem('cacheGame', v.type)
                                     setCurGame(v.type)
                                 } }
                                 className={ _cn }>
                        <div className="inventory-game-box">
                            <img style={ { position: i === 0 && 'relative', left: i === 0 && '2.5px' } }
                                 className="inventory-game-icon" src={ _cur ? v.select_icon : v.icon } alt=""/>
                        </div>
                        <p className="game-name">{ v.title }</p>
                    </div>)
                }) }
            </div>
            
            <antdUI.Modal
                title={ `背包取回记录` }
                visible={ retrieveRecordModalView }
                bodyStyle={ { paddingTop: "0" } }
                width={ 1000 }
                keyboard={ true } destroyOnClose={ true }
                onCancel={ _ => {
                    setRetrieveRecordData([])
                    setPage(1)
                    setRecordTotal(0)
                    setActiveOpen(0)
                    setRecordNav(0)
                    setModalShow(false)
                } }
                footer={ false } maskClosable={ false }
            >
                <div className="backpack-retrieve-record-modal-container">
                    <Tabs onChange={ _ => {
                        setRetrieveRecordData([])
                        setRecordNav(Number(_))
                        setPage(1)
                        setRecordTotal(0)
                        setActiveOpen(0)
                    } } defaultActiveKey={ recordNav }>
                        {
                            recordNavData.map(({ title, val },) => {
                                return <Tabs.TabPane tab={ title } key={ val }>
                                    <div className='table-header' style={ { padding: "10px 0 10px 0" } }>
                                        {
                                            columns.map(item => {
                                                return <div
                                                    key={ item.key } style={ { width: item.width } }
                                                    className='header-item'>{ item.title }
                                                </div>
                                            })
                                        }
                                    </div>
                                    
                                    { recordLoading ? <antdUI.Spin
                                            style={ {
                                                display: "block",
                                                transform: "scale(1.5)",
                                                margin: '50px auto'
                                            } }/> :
                                        <div style={ { minHeight: "200px" } } className='table-main'>
                                            {
                                                retrieveRecordData?.[0] && retrieveRecordData.map((item, index) => {
                                                    return (
                                                        <div className='table-line' key={ item.id }>
                                                            <div className='main'>
                                                                <div style={ { width: columns[0].width } }
                                                                     className='table-item-record item-info'>
                                                                    <div className='icon-img'
                                                                         style={ { backgroundImage: recordNav !== 730 && 'none' } }>
                                                                        { (_ => {
                                                                            const t = item?.CsgoItem?.img_name || item?.Dota2Item?.img_name
                                                                            let src = t ? `${ React.StaticBaseUrl }/market-csgo/${ t }.png`
                                                                                : item.retrieveItem?.[0]?.icon_url ? $tools.BASE_STEAM_IMG + item.retrieveItem?.[0]?.icon_url : ''
                                                                            icon_url_config[recordNav] && (src = icon_url_config[recordNav])
                                                                            return <img className="item-img" src={ src }
                                                                                        alt=""/>
                                                                        })() }
                                                                    </div>
                                                                    <div>
                                                                        <p className='acc-name'>{ item.market_name }</p>
                                                                        {
                                                                            item.retrieveItem?.[0] &&
                                                                            <p onClick={ () => expansionHandle(item) }
                                                                               style={ {
                                                                                   color: '#EEA20E',
                                                                                   cursor: 'pointer'
                                                                               } }>
                                                                                { item.id === activeOpen ? '收起' : '展开' }
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                
                                                                </div>
                                                                <div style={ { width: columns[1].width } }
                                                                     className='table-item-record unit-price'>{ (Number(item.mobiMoney) && `¥${ item.mobiMoney }`) || Number(item.mobiTokens) || 1 }</div>
                                                                <div style={ {
                                                                    width: columns[2].width,
                                                                    display: 'block',
                                                                    textAlign: 'center'
                                                                } }
                                                                     className='table-item-record state'>
                                                                    <p>{ item.state === 1 ?
                                                                        <span
                                                                            style={ { color: "#ea9f6a" } }>取回中...</span> : item.state === 2 ?
                                                                            <span
                                                                                style={ { color: "#2eb062" } }>取回成功</span> : '' }</p>
                                                                    { (_ => {
                                                                        if (recordNav === 730 || recordNav === 570) {
                                                                            const tradeofferid = item.retrieveItem.filter(_ => _.orderItemsStatus === 8)[0]?.tradeofferid
                                                                            if (tradeofferid) {
                                                                                return <antdUI.Button
                                                                                    onClick={ _ => window.open(`https://steamcommunity.com/tradeoffer/${ tradeofferid }/`) }
                                                                                    style={ { marginTop: '10px' } }
                                                                                    type={ 'primary' }>前往Steam接受报价
                                                                                </antdUI.Button>
                                                                            } else {
                                                                                return <></>
                                                                            }
                                                                            
                                                                        } else {
                                                                            return <></>
                                                                        }
                                                                    })() }
                                                                </div>
                                                                <div style={ {
                                                                    width: columns[3].width,
                                                                    display: 'block',
                                                                    textAlign: "center"
                                                                } }
                                                                     className='table-item-record create-time'>
                                                                    <p>{ item.retrieveTime }</p>
                                                                
                                                                </div>
                                                            </div>
                                                            
                                                            {
                                                                item.id === activeOpen &&
                                                                <div className='expansion'>
                                                                    {
                                                                        item?.retrieveItem.map((item, index) => {
                                                                            return (
                                                                                <div key={ index }
                                                                                     style={ { padding: "0 20px 0 20px" } }
                                                                                     className='table-line-complete'>
                                                                                    <div style={ { width: '36%' } }
                                                                                         className='table-item item-info'>
                                                                                        <div className='icon-img'>
                                                                                            <img
                                                                                                src={ $tools.BASE_STEAM_IMG + item.icon_url }
                                                                                                alt=""/>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-ov1"
                                                                                               style={ { width: '198px' } }>{ item.market_name }</p>
                                                                                            { recordNav === 730 &&
                                                                                                <p style={ {
                                                                                                    fontSize: '12px',
                                                                                                    color: '#999'
                                                                                                } }>磨损：{ item.floatvalue }</p> }
                                                                                        </div>
                                                                                    
                                                                                    </div>
                                                                                    
                                                                                    <div className='create-time'
                                                                                         style={ {
                                                                                             width: "20%",
                                                                                             margin: "0 20px",
                                                                                         } }>
                                                                                        <p>报价发送时间</p>
                                                                                        <p>{ item?.offSendTime }</p>
                                                                                    </div>
                                                                                    
                                                                                    <div className='create-time'
                                                                                         style={ { width: '20%' } }>
                                                                                        <p>交易识别号</p>
                                                                                        <p>{ item.orderNumber }</p>
                                                                                    </div>
                                                                                    <div style={ {
                                                                                        width: "20%",
                                                                                        textAlign: 'center'
                                                                                    } }
                                                                                         className='create-time'>
                                                                                        <div dangerouslySetInnerHTML={ {
                                                                                            __html: $tools.orderStatusMessage({
                                                                                                type: 'sale',
                                                                                                status: item.orderItemsStatus,
                                                                                                orderMsg: item.orderMessage,
                                                                                                deliveryMethod: item.deliveryMethod,
                                                                                                cancelStatus: item.cancelStatus,
                                                                                                main: 'buyer',
                                                                                            }).replace('购买', '取回')
                                                                                        } }/>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <div className="flex jtyct">
                                        <antdUI.Pagination current={ page } onChange={ (current) => setPage(current) }
                                                           total={ recordTotal } pageSize={ pageSize }
                                                           hideOnSinglePage={ true }
                                        />
                                    </div>
                                
                                </Tabs.TabPane>
                            })
                        }
                    </Tabs>
                </div>
            </antdUI.Modal>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(InventoryNav);
