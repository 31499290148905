import React, { antdUI, $api, useState, useEffect, $tools, $login, $CodeLogin } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';
import CheckUnbind from './checkUnbind'

const iconOk = <i className="iconfont icon-gou"
                  style={ { fontSize: '28px', marginRight: "20px", color: '#2DAF61' } }></i>
const iconNo = <i className="iconfont icon-cha"
                  style={ { fontSize: '24px', marginRight: "22px", color: '#FF4C20' } }></i>
const InpStyle = { width: '450px', fontSize: '14px', height: '43px' }
const atApiKeyText = (
    <div className="apikey-box-text" style={ {
        padding: '14px', width: '240px', backgroundColor: '#191919',
        boxShadow: `0px 0px 6px 1px #191919`
    } }>
        <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>关于API key</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>创建API key需要Steam账号消费超过5美金。</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>卖家直发交易需要卖家提供API key用于交易报价检测。 API
            key可查询和取消交易报价,但无法创建和接受交易报价。</p>
    </div>
)
const getInventoorySetting = (
    <div className="apikey-box-text" style={ {
        padding: '14px', width: '260px', backgroundColor: '#191919',
        boxShadow: `0px 0px 6px 1px #191919`
    } }>
        <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>获取步骤：</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>1.点击前往获取；</p>
        <p style={ {
            color: '#fff',
            fontSize: '14px'
        } }>2.进入steam库存隐私设置页面，需要将隐私设置中的“我的个人资料”和“库存”设置为公开；</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>3.资料的公开可能会存在延迟。</p>
    </div>
)
const getApiKeyText = (
    <div className="apikey-box-text" style={ {
        padding: '14px', width: '260px', backgroundColor: '#191919',
        boxShadow: `0px 0px 6px 1px #191919`
    } }>
        <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>获取步骤：</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>1.点击前往获取；</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>2.如果当前没有创建API key，随意输入一个域名，然后点击注册；如果当前已经有API
            key但是并非自己注册的，建议注销掉，重新注册；</p>
        <p style={ { color: '#fff', fontSize: '14px' } }>3.把API key复制粘贴到输入框，点击保存。</p>
    </div>
)

function TradingInfo(props) {
    const { userInfo, setUserSignIn } = props
    const [ transactionLink, setTransactionLink ] = useState(''),
        [ unbindCheck, setUnBindCheck ] = useState(false),
        [ apiKey, setApiKey ] = useState('');
    const [ linkLoading, setLinkLoading ] = useState(false),
        [ keyLoading, setKeyLoading ] = useState(false);
    const { checkResult, debounce } = $tools
    const saveSteamInfo = async (type) => {
        let val, result;
        if (! props.userInfo.uid) return $login()
        if (! props.userInfo.steam.steamid) return antdUI.message.error('请先绑定steam账号')
        switch (type) {
            case 'transactionLink':
                (val = transactionLink);
                if (! val) return antdUI.message.error('您输入的信息有误');
                setLinkLoading(true)
                result = await $api.steam.upDateSteamTransactionLink({ transactionLink: val })
                break;
            case 'apiKey':
                (val = apiKey);
                if (! val) return antdUI.message.error('您输入的信息有误');
                if (val.length !== 32) return antdUI.message.error('您输入的 ApiKey 格式有误');
                let _val = String(userInfo.steam?.apiKey).split('')
                String(val).split('').forEach((item, idx) => {
                    if (item.match(/[0-9A-Z]/)) {
                        _val[idx] = item
                    }
                })
                setKeyLoading(true)
                result = await $api.steam.upDateSteamApiKey({ apiKey: _val.join('') })
                break;
            default:
                break;
        }
        setLinkLoading(false)
        setKeyLoading(false)
        if (checkResult(result)) {
            if (result.data.error) {
                antdUI.message.error('无效的 ApiKey!')
                setUserSignIn()
            } else if (result.data.apiKey || result.data.transactionLink) {
                antdUI.message.success('设置成功')
                setUserSignIn()
            }
        }
    }
    
    const steamLogin = async () => {
        await $api.user.bindSteamAp({ type: 'bind' })
        window.open(
            $api.steam.steamBind(),
            "_blank",
            `top=0,left=0,width=800,height=1000,menubar=no,resizable=no,toolbar=no,status=no,scrollbars=no`
        );
    }
    const unbindSteam = async () => {
        if (! userInfo.uid) return antdUI.message.warning('请直接注销登录！')
        if (userInfo?.loginType !== 'code') {
            antdUI.message.warning('您必须使用手机验证码登录，才可进行当前操作！')
            return $CodeLogin()
        }
        setUnBindCheck(true)
    }
    
    useEffect(() => {
        setTransactionLink(userInfo.steam.transactionLink)
        setApiKey(userInfo.steam.apiKey ? String(userInfo.steam.apiKey).replace(/[A-Z\d]/g, '*') : '')
    }, [ userInfo ])
    
    return (
        <div className="trading-info">
            { unbindCheck && <CheckUnbind visible={ unbindCheck } setUnBindCheck={ setUnBindCheck }/> }
            <div className="title flex">
                <h1>交易信息设置</h1>
                <h3 style={ {
                    marginLeft: '10px',
                    fontSize: '14px',
                    color: '#989898'
                } }>(如访问页面没有正常加载，请先下载加速器后重试)</h3>
            </div>
            <div className="steamid">
                <p className="title">STEAM17ID</p>
                <div className="bind">
                    <div className="status">
                        { userInfo.steam.steamid ? iconOk : iconNo }
                        { userInfo.steam.steamid ? <p>已绑定</p> : <p style={ { color: '#FF4C20' } }>未绑定</p> }
                    </div>
                    { userInfo.steam.steamid && <div className="content">
                        <p className="steamid-text" onClick={ _ => window.open(userInfo.steam.profileurl) }>
                            { userInfo.steam.steamid }</p>
                    </div> }
                    {
                        (userInfo.uid || userInfo.steam.steamid) ? userInfo.steam.steamid ?
                            <antdUI.Button className="antd-button-sm" type="primary"
                                           onClick={ debounce(unbindSteam, 2000, true) }>解绑
                            </antdUI.Button>
                            : <antdUI.Button className="antd-button-sm" type="primary"
                                             onClick={ () => steamLogin() }>绑定
                            </antdUI.Button> : ''
                    }
                </div>
            </div>
            
            <div className="trading-link">
                <p className="title">交易链接</p>
                <div className="bind">
                    <antdUI.Input
                        value={ transactionLink }
                        style={ InpStyle }
                        onChange={ e => setTransactionLink(e.target.value.replace(/\s+/g, '')) }
                        placeholder="请输入交易链接"/>
                    <div className="content">
                        <p className="get-trading"
                           onClick={ () => {
                               if (userInfo.steam.steamid) {
                                   window.open(`https://steamcommunity.com/profiles/${ userInfo.steam.steamid }/tradeoffers/privacy`)
                               } else {
                                   antdUI.message.warning('请先绑定steam账号')
                               }
                           } }
                        >前往Steam获取</p>
                    </div>
                    
                    <antdUI.Button className="antd-button-sm" type="primary" loading={ linkLoading }
                                   onClick={ debounce(saveSteamInfo.bind(null, 'transactionLink'), 2000, true) }>保存
                    </antdUI.Button>
                </div>
            </div>
            
            <div className="api-key">
                <p className="title" style={ {
                    display: 'flex', alignItems: 'center'
                } }
                >API KEY
                    <antdUI.Popover
                        placement="right"
                        content={ atApiKeyText }
                    ><i className="iconfont icon-wenhao"></i>
                    </antdUI.Popover>
                </p>
                <div className="bind">
                    <antdUI.Input
                        value={ apiKey }
                        style={ InpStyle }
                        onChange={ e => setApiKey(e.target.value.replace(/\s+/g, '')) }
                        placeholder="请输入您的STEAM API KEY"/>
                    <div className="content">
                        <p className="get-trading"
                           onClick={ () => { window.open(`https://steamcommunity.com/dev/apikey`) } }
                        >前往Steam获取</p>
                        <antdUI.Popover
                            placement="right"
                            content={ getApiKeyText }
                        ><i className="iconfont icon-wenhao"></i>
                        </antdUI.Popover>
                        {/* <p className="bind-steam-api">STEAM API绑定</p> */ }
                    </div>
                    <antdUI.Button className="antd-button-sm" type="primary" loading={ keyLoading }
                                   onClick={ debounce(saveSteamInfo.bind(null, 'apiKey'), 2000, true) }>保存
                    </antdUI.Button>
                </div>
            </div>
            <div className="api-key" style={ { paddingBottom: '0' } }>
                <div className="bind">
                    <div className="content" style={ { margin: '0' } }>
                        <p className="get-trading"
                           onClick={ () => {
                               if (userInfo.steam.steamid) {
                                   window.open(`https://steamcommunity.com/profiles/${ userInfo.steam.steamid }/edit/settings/`)
                               }
                           } }
                        >前往公开Steam库存</p>
                        <antdUI.Popover
                            placement="right"
                            content={ getInventoorySetting }
                        >
                            <i className="iconfont icon-wenhao"></i>
                        </antdUI.Popover>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(TradingInfo);
