import React, { $api, $tools, antdUI, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setUserSignIn, upDateUserAssets } from '@/store/actions/userInfo'
import { upDateFavoritesData } from '@/store/actions/favoritesData'
import CountryCode from '@/data/countryCode.js'
import Script from 'react-load-script';

function MobiLoginContainer(props) {
    const [ phone, setPhone ] = useState(props.phone),
        [ phoneCode, setPhoneCode ] = useState(),
        [ password, setPassword ] = useState(),
        [ selectVal, setSelectVal ] = useState('+86'),
        [ btnText, setBtnText ] = useState('发送验证码'),
        [ loginCurrent, setLoginCurrent ] = useState('verif'),
        [ remeberme, setRemeberme ] = useState(false),
        [ codeLoa, setCodeLoa ] = useState(false),
        [ loginLoad, setLoginLoad ] = useState(false),
        [ protocol, setProtocol ] = useState(false),
        [ ticket, setTicket ] = useState(''),
        [ randstr, setRandstr ] = useState('');
    
    const [ sure, setSure ] = useState(false)
    const [ scriptLoaded, setScriptLoaded ] = useState(false);
    
    const { setUserSignIn, upDateUserAssets, upDateFavoritesData, userInfo = {} } = props;
    const { countryCode, phone: signInPhone } = userInfo
    const { signUpSendPhoneCode, sendPhoneCode, signIn: mobiSignIn, codeCheckSignIn } = $api.user;
    const { debounce, inputValueLimit, checkResult, md5Encryption } = $tools;
    
    const sendCode = async () => {
        if (btnText !== '发送验证码') return
        let countdown = 60, timer = null;
        let result
        if (props.title === '手机验证码登录认证') {
            setCodeLoa(true)
            result = await sendPhoneCode({ type: 'confirmSignIn' });
        } else {
            if (! phone) return antdUI.message.warning('您输入的手机号有误')
            setCodeLoa(true)
            result = await signUpSendPhoneCode({
                phone, ticket, randstr, type: props.type,
                countryCode: selectVal.replace('+', '')
            });
            if (result?.message === "请尝试重新拖动滑块进行验证！") setSure(false)
        }
        setCodeLoa(false)
        if (! checkResult(result)) return
        antdUI.message.success("发送成功");
        setBtnText(`重新发送(${ countdown }s)`);
        timer = setInterval(() => {
            countdown--
            if (countdown === 0) {
                clearInterval(timer)
                setBtnText('发送验证码');
            } else {
                setBtnText(`重新发送(${ countdown }s)`);
            }
        }, 1000)
    }
    
    const steamLogin = async () => {
        await $api.user.bindSteamAp({ type: 'web' })
        window.open(
            $api.steam.steamLogin(),
            "_blank",
            `top=0,left=0,width=800,height=1000,menubar=no,resizable=no,toolbar=no,status=no,scrollbars=no`
        );
    }
    
    
    const loginSwitch = () => {
        switch (loginCurrent) {
            case 'verif':
                return (
                    props.title === '手机验证码登录认证' ? '' :
                        <div className="login-type" style={ { justifyContent: 'space-between' } }>
                            <div onClick={ () => {
                                if (selectVal !== '+86') return antdUI.message.warning('当前地区用户暂时无法使用密码登录！')
                                setLoginCurrent('pass');
                                setPassword(null)
                                setSure(false)
                                setTicket("")
                                setRandstr("")
                            } } className="pass">
                                <img src={ require('@/images/login/suo@2x.png') } alt=""/> 使用密码验证登录
                            </div>
                            <div onClick={ () => steamLogin() } className="steam">
                                <img src={ require('@/images/login/steam@2x.png') } alt=""/> steam登录
                            </div>
                        </div>);
            case 'pass':
                return (
                    <div className="login-type" style={ { justifyContent: 'space-between' } }>
                        <div onClick={ () => {
                            setLoginCurrent('verif');
                            setPhoneCode(null)
                            setSure(false)
                            setTicket("")
                            setRandstr("")
                        } } className="verif">
                            <img src={ require('@/images/login/duanxin@2x.png') } alt=""/> 短信登录
                        </div>
                        <div onClick={ () => steamLogin() } className="steam">
                            <img src={ require('@/images/login/steam@2x.png') } alt=""/> steam登录
                        </div>
                        <div className="forget-pass" onClick={ () => props.setTitle('updatePass') }> 忘记密码?</div>
                    </div>);
            default:
                break;
        }
    }
    
    const signInSuccess = (result) => {
        setLoginLoad(false)
        if (result?.message === "请尝试重新拖动滑块进行验证！") setSure(false)
        if (! checkResult(result)) return
        antdUI.message.success('登录成功');
        props.setLoginM(false)
        setUserSignIn()
        upDateUserAssets()
        upDateFavoritesData()
        localStorage.setItem('token', result.data.token)
        // setTimeout(() => { window.location.href = window.location.pathname }, 100)
    }
    
    const signIn = async () => {
        if (props.title !== '手机验证码登录认证' && ! phone) return antdUI.message.warning('您输入的手机号有误');
        let result;
        switch (loginCurrent) {
            case 'verif':
                if (! phoneCode || ! /[0-9]{6}/.test(phoneCode)) return antdUI.message.error('您输入的验证码有误');
                if (! protocol) return antdUI.message.warning('请先同意用户协议和隐私政策');
                //  验证码登录 ↓↓↓
                setLoginLoad(true);
                
                if (props.title === '手机验证码登录认证') {
                    result = await codeCheckSignIn({ code: phoneCode, remeberme, userAgent: 1 })
                } else {
                    result = await mobiSignIn({
                        phone, code: phoneCode, remeberme,
                        countryCode: selectVal.replace('+', '')
                    });
                }
                signInSuccess(result);
                break;
            case 'pass':
                if (! password) return antdUI.message.error('请先输入用户密码');
                if (! protocol) return antdUI.message.warning('请先同意用户协议和隐私政策');
                if (! sure) return antdUI.message.warning('请尝试重新拖动滑块进行验证！');
                
                //  用户密码登录 ↓↓↓
                setLoginLoad(true)
                result = await mobiSignIn({
                    phone, password: md5Encryption(password), remeberme, ticket, randstr,
                    countryCode: selectVal.replace('+', '')
                });
                signInSuccess(result);
                break;
            default:
                break;
        }
    }
    
    const handleScriptError = () => {
        antdUI.message.error("组件加载失败")
    }
    
    const handleScriptLoad = () => {
        setScriptLoaded(true)
    }
    const showView = () => {
        const captcha1 = new window.TencentCaptcha('195039738', function (res) {
            // res（用户主动关闭验证码）= {ret: 2, ticket: null}
            // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
            if (res.ret === 0) {
                setSure(true)
                // console.log(res.ticket)   // 票据
                // console.log(res.randstr)   // 票据
                setTicket(res.ticket)
                setRandstr(res.randstr)
            }
        });
        captcha1.show(); // 显示验证码
    }
    return (
        <div className="modal-login-container"
             style={ { gridTemplateRows: props.title === '手机验证码登录认证' && "64px 0px 64px 20px" } }>
            { props.title === '手机验证码登录认证' ?
                <p style={ { fontWeight: 'bold', fontSize: '16px' } }>向
                    +{ countryCode } { signInPhone } 发送验证码</p> :
                <antdUI.Input.Group size="large" compact>
                    <antdUI.Select onChange={ val => {
                        setSelectVal(`+${ val }`)
                        if (val !== 86) {
                            setLoginCurrent('verif');
                            setPhoneCode('')
                        }
                    } } dropdownMatchSelectWidth={ 200 } style={ { width: '90px', fontSize: '16px' } }
                                   value={ selectVal }>
                        { CountryCode.map(({ countryCN, countryCode }, idx) => {
                            return <antdUI.Select.Option key={ idx } value={ countryCode }
                            > { countryCN } +{ countryCode }
                            </antdUI.Select.Option>
                        }) }
                    </antdUI.Select>
                    <antdUI.Input
                        value={ phone } style={ { width: '238px' } }
                        onChange={ (e) => setPhone(inputValueLimit(e.target.value, 11)) }
                        placeholder="请输入手机号"
                    />
                </antdUI.Input.Group> }
            
            { loginCurrent === 'pass' && <antdUI.Input
                size="large" value={ password } type="password" placeholder="请输入账号密码"
                onChange={ (e) => setPassword(inputValueLimit(e.target.value, 16)) }
                prefix={ <img alt="" style={ { width: '20px' } } src={ require('@/images/login/suo@2x.png') }/> }
            /> }
            
            { props.title === '手机验证码登录认证' ? <div></div> : (scriptLoaded ?
                <antdUI.Button disabled={ sure }
                               onClick={ _ => showView() }>{ sure ?
                    <p><i style={ { color: 'rgb(45, 175, 97)' } } className="iconfont icon-gou"></i>通过
                    </p> : "人机滑块验证" }</antdUI.Button> : '') }
            
            <Script
                url="https://turing.captcha.qcloud.com/TCaptcha.js"
                onError={ handleScriptError }
                onLoad={ handleScriptLoad }
            />
            
            
            { loginCurrent === 'verif' && <antdUI.Input.Search
                placeholder="请输入验证码" value={ phoneCode } size="large"
                onChange={ (e) => setPhoneCode(inputValueLimit(e.target.value, 6)) }
                enterButton={
                    <antdUI.Button style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                   loading={ codeLoa }>{ btnText }</antdUI.Button> }
                onSearch={ debounce(sendCode, 2000, true) }
            /> }
            
            
            { loginSwitch(loginCurrent) }
            
            <antdUI.Button type="primary" disabled={ ! protocol } className="mobi-sign-in-btn"
                           style={ { lineHeight: '0px' } }
                           loading={ loginLoad }
                           onClick={ debounce(signIn, 1000, true) }>登录
            </antdUI.Button>
            {/* <div className="mobi-sign-in-btn" onClick={debounce(signIn, 1000, true)}>登录</div> */ }
            { props.title === '手机验证码登录认证' ? '' :
                <div className="mobi-sign-up-btn" onClick={ () => props.setTitle('signUp') }>注册</div> }
            <div className="mobi-login-checkbox"
                 style={ { marginTop: props.title === '手机验证码登录认证' ? '50px' : '25px' } }>
                <antdUI.Checkbox style={ { display: "flex", alignItems: 'center' } }
                                 onChange={ (e) => setRemeberme(e.target.checked) } defaultChecked={ remeberme }>
                    <p>保留7天登录状态</p>
                </antdUI.Checkbox>
                <antdUI.Checkbox style={ { margin: '0' } } onChange={ (e) => setProtocol(e.target.checked) }
                                 defaultChecked={ protocol }>
                    <p>我同意</p>
                </antdUI.Checkbox>
                <p>
                    <a href="https://www.mobi2077.com/public/help/privacyPolicy.html" rel="noopener noreferrer"
                       target="_blank">《隐私政策》</a>和
                    <a href="https://www.mobi2077.com/public/help/userAgreement.html" rel="noopener noreferrer"
                       target="_blank">《用户协议》</a>
                </p>
            </div>
        
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
            upDateUserAssets: () => disatch(upDateUserAssets()),
            upDateFavoritesData: () => disatch(upDateFavoritesData())
        }
    }
)(MobiLoginContainer);
