import React from "react";
import Wear from '@/components/itemContainer/itemWear/wear.js'
import './customItemBox.scss'

const rarityData = [
    {
        "label": "隐秘",
        "value": "Rarity_Ancient_Weapon",
        "color": "#EB4B4B"
    },
    {
        "label": "保密",
        "value": "Rarity_Legendary_Weapon",
        "color": "#D32CE6"
    },
    {
        "label": "受限",
        "value": "Rarity_Mythical_Weapon",
        "color": "#8847FF"
    },
    {
        "label": "军规级",
        "value": "Rarity_Rare_Weapon",
        "color": "#4B69FF"
    },
    {
        "label": "工业级",
        "value": "Rarity_Uncommon_Weapon",
        "color": "#5E98D9"
    }
]

function ReplacingAnalogCustomItem(props) {
    const { itemData: _, quality, type } = props
    return (
        <div
            className="replacing-analog-custom-item" style={ { padding: '10px 0 25px 0' } }
            onLoad={ (e) => (e.currentTarget.style.opacity = 1) }
        >
            <div className="item-quality" style={ { alignItems: "start" } }>
                <p
                    value={ _.en_rarity }
                    style={ {
                        color: `${ rarityData.filter(e => e.value === _.en_rarity)?.[0]?.color }`,
                        padding: "0 2px", height: "18px", lineHeight: "18px"
                    } }
                    className="item_quality"
                >
                    { _.rarity }
                </p>
                <p
                    style={ {
                        color: '#fff', backgroundColor: `${ _?._bcc }`,
                        padding: "0 2px", height: "18px", lineHeight: "18px"
                    } }
                    className="item_quality"
                >
                    { _?.title }
                </p>
                { quality === 'strange' && <p
                    value="strange" className="item_quality"
                    style={ {
                        color: "rgb(207, 106, 50)",
                        padding: "0 2px", height: "18px", lineHeight: "18px"
                    } }
                >ST</p> }
            </div>
            { type === 'detail' ? '' : <h4 className="replacing-chance">
                { _.chance } %
            </h4> }
            <div className="reAnCustom-item-img">
                <img src={ `${ _.cover }` } alt={ _.name }/>
            </div>
            <div className="item-wear-container">
                <Wear size={ "mini" } wearValue={ _.wearValue || 0 }/>
            </div>
        </div>
    )
}

export default ReplacingAnalogCustomItem
