import React, { antdUI, useEffect, useState } from 'react';
import './index.scss'
import { allCustomItemDataTree } from '@/data/replacingAnalogData.js'

const itemSortingData = [
    {
        "label": "隐秘",
        "value": "Rarity_Ancient_Weapon",
        "color": "#EB4B4B"
    },
    {
        "label": "保密",
        "value": "Rarity_Legendary_Weapon",
        "color": "#D32CE6"
    },
    {
        "label": "受限",
        "value": "Rarity_Mythical_Weapon",
        "color": "#8847FF"
    },
    {
        "label": "军规级",
        "value": "Rarity_Rare_Weapon",
        "color": "#4B69FF"
    },
    {
        "label": "工业级",
        "value": "Rarity_Uncommon_Weapon",
        "color": "#5E98D9"
    },
    {
        "label": "消费级",
        "value": "Rarity_Common_Weapon",
        "color": "#B0C3D9"
    }
]
const navData = [
    { title: '武器箱收藏品', val: 2 },
    { title: '地图收藏品', val: 1 }
]

function FormulaContainsItems({ showOp: { show, setShow }, boxValue }) {
    const [ targetBoxData, setTargetBoxData ] = useState()
    const [ box_value, setBoxValue ] = useState(boxValue)
    const [ navCur, setNavCur ] = useState(2)
    useEffect(_ => {
        setTargetBoxData(allCustomItemDataTree.filter(_ => _.box_value === box_value)[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ box_value ])
    return (
        <antdUI.Modal title={ "包含物品" } visible={ show } footer={ null } maskClosable={ false }
                      width="700px" destroyOnClose={ true } onCancel={ _ => setShow(false) }>
            <div className="formula-contains-items">
                {
                    targetBoxData ? <div className="box-de">
                        <div className="title flex ait">
                            <div className="img-box"><img onLoad={ e => e.target.style.opacity = 1 }
                                                          src={ targetBoxData.cover } alt=""/></div>
                            <div className="name">
                                <h2>{ targetBoxData.name }</h2>
                                <p>{ targetBoxData.en_name }</p>
                            </div>
                            <antdUI.Button className="btn" type="primary" onClick={ () => setBoxValue('') }>收藏品总览
                            </antdUI.Button>
                        </div>
                        <p className="info-text">此武器箱包含以下物品</p>
                        <div className="item-contains">
                            { (() => {
                                let listData = [], li;
                                itemSortingData.forEach(_ => {
                                    li = targetBoxData.children.filter(item => {
                                        if (item.en_rarity === _.value) {
                                            item.color = _.color
                                            return item
                                        } else {
                                            return null
                                        }
                                    })
                                    li[0] && (listData.push(li))
                                })
                                return listData.map((list, idx) => {
                                    return (<div className="item-list-box" key={ idx }>
                                        <h3 className="title" style={ { backgroundColor: list[0].color } }>
                                            { list[0].rarity }
                                        </h3>
                                        { list.map((item, index) => {
                                            return (<div key={ item.itemid } style={ { border: index === 0 && 'none' } }
                                                         className="item-box flex ait">
                                                <div className="img-box">
                                                    <img onLoad={ e => e.target.style.opacity = 1 } src={ item.cover }
                                                         alt=""/>
                                                </div>
                                                <div className="name"><h2>{ item.name }</h2></div>
                                            </div>)
                                        }) }
                                    </div>)
                                })
                            })() }
                        </div>
                    </div> : <>
                        <div className="box">
                            <div className="nav">
                                { navData.map(_ => {
                                    const cls = navCur === _.val ? 'nav-item current' : 'nav-item'
                                    return <button onClick={ () => setNavCur(_.val) } key={ _.val }
                                                   className={ cls }>{ _.title }</button>
                                }) }
                            </div>
                            <div className="box-list-container">
                                {
                                    allCustomItemDataTree.filter(_ => _.type === navCur).map(_ => {
                                        return <div onClick={ () => setBoxValue(_.box_value) } key={ _.box_value }
                                                    className="box-item">
                                            <div className="box-img">
                                                <img onLoad={ e => e.target.style.opacity = 1 } src={ _.cover } alt=""/>
                                            </div>
                                            <div className="box-nam">
                                                <h3>{ _.name }</h3>
                                                <p className="text-ov1">{ _.en_name }</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </antdUI.Modal>
    )
}

export default FormulaContainsItems;
