import React, {antdUI} from 'react';
import './sort.scss'


function Sort(props){
  const {setSort, sort} =props;
  const {Select} = antdUI;
  const {Option} =Select;

  const selectOptions=[
    {value: 0, label: '默认排序'},
    {value: 1,label:<span>价格<i className="iconfont icon-cs-jt-xs des"></i></span>},
    {value: 2,label:<span>价格<i className="iconfont icon-cs-jt-xs asc"></i></span>},
  ]

  const onChange=(value)=>{
    setSort(value)
  }

  return(
    <>
      <Select defaultValue={1} value={sort} style={{ width: 105 }} onChange={onChange}>
        {
          selectOptions.map((item,index)=>{
            return <Option key={index} value={item.value}>{item.label}</Option>
          })
        }
      </Select>
    </>
  )
}

export default Sort
