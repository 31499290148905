import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './index.scss';
import { Link } from "react-router-dom";
import OrderDetail from './orderDetail'
import ItemDetailPopover from '@/components/itemDetailPopover'
import SaleItemContainer from '../saleItemContainer'

const getPageType = (quality) => {
    if (quality === "strange" || quality === "unusual_strange") {
        return 2
    } else {
        return 1
    }
}

function OrderContainer(props) {
    const { saleData, getData, currentNav, onSaleCurNav, onShelfData, set_onShelfData, currentGame } = props;
    const [ orderDetailView, setOrderDetailView ] = useState(false);
    const [ allPrice, setAllPrice ] = useState(0);
    const [ orderDetailData, setOrderDetailData ] = useState([]);
    const [ countDownList, setCountDownList ] = useState({});
    const {
        checkResult, debounce, BASE_STEAM_IMG,
        orderStatusMessage, countDown, calculationTargetTime, obj2strUrl
    } = $tools
    const cancelOrder = async (orderNumber) => {
        let result = await $api.order.cancelOrder({ orderNumber });
        checkResult(result) && getData()
    }
    const orderCountDown = (targData, timer) => {
        const time = targData.map((_, index) => [ index, calculationTargetTime(_) ]);
        let list = {}
        time.forEach(_ => list[_[0]] = countDown({ time: _[1], type: 'order' }))
        setCountDownList({ ...list })
        timer = setInterval(() => {
            time.forEach(_ => {
                if (_[1] === " ") {
                    list[_[0]] = ""
                } else if (_[1] === "暂无时间信息") {
                    list[_[0]] = "暂无时间信息"
                } else {
                    list[_[0]] = countDown({ time: _[1], type: 'order' })
                }
            })
            setCountDownList({ ...list })  // 得浅克隆区分地址
        }, 1000)
    }
    
    useEffect(() => {
        let timer
        orderCountDown(saleData, timer)
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const checkSaleOrderTime = orderItem => {
        const { offSendTime, duetime, orderItemsStatus, suspendedTime, orderType } = orderItem
        const tHTime = _ => _ * 60 * 60 * 1000;
        const curTime = new Date().getTime()
        const _duetime = new Date(duetime?.replace(/-/g, '/')).getTime()
        const _offSendTime = new Date(offSendTime?.replace(/-/g, '/')).getTime()
        const _suspendedTime = new Date(suspendedTime).getTime()
        switch (orderItemsStatus) {
            case 4:
                return curTime > (_duetime + tHTime(orderType === 'sale' ? 12 : 0.5) + 35 * 60 * 1000);
            case 6:
                return curTime > (_duetime + tHTime(orderType === 'sale' ? 12 : 0.5) + 5 * 60 * 1000);
            case 7:
                return curTime > (_offSendTime + tHTime(12) + 5 * 60 * 1000);
            case 8:
                return curTime > (_offSendTime + tHTime(24) + 5 * 60 * 1000);
            case 10:
                return curTime > (_offSendTime + tHTime(12) + 5 * 60 * 1000);
            case 11:
                if (! suspendedTime) return false
                return curTime > _suspendedTime
            default:
                return false
        }
    }
    
    const manualCheckSaleOrderBtnOnChange = async ({ appid, orderNumber }) => {
        const result = await $api.tradeoffer.manualCheckSaleOrder({ appid, orderNumber })
        if (checkResult(result)) {
            if (result?.data?.message) {
                antdUI.message.success(result?.data?.message)
            } else {
                antdUI.message.success('订单效验成功！')
            }
            getData();
        }
    }
    
    let dataSource = [], columns;
    if (saleData[0] !== 'no') {
        switch (currentNav) {
            case 'onSale':
                columns = onSaleColumns;
                saleData.forEach((item, index) => {
                    item.market_item_detail = {
                        error: 0,
                        msg: {
                            floatvalue: item.floatvalue,
                            stickers: item.stickers || [],
                            paintseed: item.paintseed,
                            paintindex: item.paintindex,
                            customname: item.customname
                        }
                    }
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber }
                                 className="order-numbers">{ item.orderNumber }{ item.offerCode ? `-(${ item.offerCode })` : "" }</div>
                        ),
                        itemInfo: (
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: item.appid,
                                            curType: getPageType(item?.CsgoItem?.quality),
                                        }, '?')
                                    }
                                }
                                target='_blank'
                            >
                                <div className="orderTable item-info-box">
                                    <antdUI.Popover placement="rightTop"
                                                    content={ <ItemDetailPopover type="onSale" csData={ item }/> }>
                                        <div style={ favoritesItemStyle } className="favorites-item">
                                            <img style={ tabImg }
                                                 src={ item?.CsgoItem?.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ item?.CsgoItem?.img_name }.png`
                                                     : BASE_STEAM_IMG + item.icon_url } alt=""
                                            />
                                        </div>
                                    </antdUI.Popover>
                                    {
                                        Number(currentGame) === 730 ? <div className="on-shelf-item-name">
                                            <p className="item-name one-text-flow">{ item.market_name }</p>
                                            { item.floatvalue !== '0' &&
                                                <p className="floatval text-ov1">{ item.floatvalue && `磨损:${ item.floatvalue }` }</p> }
                                        </div> : <div className="on-shelf-item-name">
                                            <p className="item-name one-text-flow"
                                               style={ { margin: '10px 0' } }>{ item.market_name }</p>
                                        </div>
                                    }
                                </div>
                            </Link>
                        ),
                        sellingPrice: (
                            <div style={ { ...tabBox, fontSize: '16px', color: '#2DAF61' } }
                                 className="on-shelf-item-name">
                                ￥{ item.sellingPrice }
                            </div>
                        ),
                        buyer: <div style={ tabBox }>{ item.buyerid ? item?.User?.nickname : '暂无' }</div>,
                        duetime: <div style={ tabBox }>{ item.duetime ? item.duetime : '暂无' }</div>,
                        orderItemsStatus: <>
                            <div style={ { ...tabBox, width: '180px', margin: '0 auto' } }
                                 dangerouslySetInnerHTML={ {
                                     __html: orderStatusMessage({
                                         type: 'sale', cancelStatus: item.cancelStatus,
                                         status: item.orderItemsStatus, orderMsg: item.orderMessage,
                                         deliveryMethod: item.deliveryMethod
                                     })
                                 } }
                            />
                            <div className="count-down">{
                                countDownList[index]
                            }</div>
                            { checkSaleOrderTime(item) &&
                                <antdUI.Button type="primary"
                                               style={ { display: 'block', margin: '4px auto' } }
                                               onClick={ () => manualCheckSaleOrderBtnOnChange(item) }>卖家报价验证
                                </antdUI.Button> }
                        </>
                    })
                })
                break;
            case 'intermediary':
                columns = intColumns;
                saleData.forEach((item, index) => {
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber } className="order-numbers">{ item.orderNumber }</div>
                        ),
                        itemTotal: (
                            <div style={ tabBox } className="on-shelf-item-name">
                                共{ item.itemTotal }件
                            </div>
                        ),
                        price: <div style={ { ...tabBox, color: '#2eb062' } }>￥<h1
                            style={ { ...tabH, color: '#2eb062' } }>{ item.totalPrice }</h1></div>,
                        buyer: <div style={ tabBox }>{ item.buyerid ? item?.User?.nickname : '暂无' }</div>,
                        handlingFee: <div style={ { ...tabBox, color: '#2eb062' } }>￥<h2
                            style={ { ...tabH, color: '#2eb062' } }>{ item.handlingFee }</h2></div>,
                        createTime: <div style={ tabBox }>{ item.create_time }</div>,
                        orderStatus: <div style={ { ...tabBox } }
                                          dangerouslySetInnerHTML={ {
                                              __html: orderStatusMessage({
                                                  type: 'int',
                                                  status: item.orderStatus
                                              })
                                          } }
                        />,
                        // orderLink: <div style={tabBox}>{`${window.location.host}/myRequest?orderLink=${item.orderNumber}`}</div>,
                        operating: <div style={ { ...tabBox, } }>
                            <antdUI.Button type="primary" style={ { marginRight: '4px' } } onClick={ () => {
                                setAllPrice(item.totalPrice)
                                setOrderDetailData(item.IntermediaryListings)
                                setOrderDetailView(true)
                            } }>查看详情
                            </antdUI.Button>
                            { item.orderStatus === '1' || item.orderStatus === '2' ? <antdUI.Button
                                    onClick={ debounce(cancelOrder, 1000, true).bind(null, item.orderNumber) }>取消订单</antdUI.Button>
                                : <antdUI.Button disabled>取消订单</antdUI.Button>
                            }
                        </div>
                    })
                })
                break;
            case 'record':
                columns = onSaleColumns;
                saleData.forEach((item, index) => {
                    item.market_item_detail = {
                        error: 0,
                        msg: {
                            floatvalue: item.floatvalue,
                            stickers: item.stickers || [],
                            paintseed: item.paintseed,
                            paintindex: item.paintindex,
                            customname: item.customname
                        }
                    }
                    dataSource.push({
                        key: index,
                        orderNumber: (
                            <div style={ orderNumber }
                                 className="order-numbers">{ item.orderNumber }{ item.offerCode ? `-(${ item.offerCode })` : "" }</div>
                        ),
                        itemInfo: (
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: item.appid,
                                            curType: getPageType(item?.CsgoItem?.quality)
                                        }, '?')
                                    }
                                }
                                target='_blank'
                            >
                                <div className="orderTable item-info-box">
                                    <antdUI.Popover placement="rightTop"
                                                    content={ <ItemDetailPopover type="onSale" csData={ item }/> }>
                                        <div style={ favoritesItemStyle } className="favorites-item">
                                            <img style={ tabImg }
                                                 src={ item?.CsgoItem?.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ item?.CsgoItem?.img_name }.png`
                                                     : BASE_STEAM_IMG + item.icon_url } alt=""
                                            />
                                        </div>
                                    </antdUI.Popover>
                                    <div className="on-shelf-item-name">
                                        <p className="item-name one-text-flow">{ item.market_name }</p>
                                        { item.floatvalue !== '0' &&
                                            <p className="floatval text-ov1">{ item.floatvalue && `磨损:${ item.floatvalue }` }</p> }
                                    </div>
                                </div>
                            </Link>
                        ),
                        sellingPrice: (
                            <div style={ { ...tabBox, fontSize: '16px', color: '#2DAF61' } }
                                 className="on-shelf-item-name">
                                ￥{ item.sellingPrice }
                            </div>
                        ),
                        buyer: <div style={ tabBox }>{ item.buyerid ? item?.User?.nickname : '暂无' }</div>,
                        duetime: <div style={ tabBox }>{ item.duetime ? item.duetime : '暂无' }</div>,
                        orderItemsStatus: <div style={ { ...tabBox } }
                                               dangerouslySetInnerHTML={ {
                                                   __html: orderStatusMessage({
                                                       type: 'sale',
                                                       main: 'seller',
                                                       orderMsg: item.orderMessage,
                                                       status: item.orderItemsStatus
                                                   })
                                               } }
                        />
                    })
                })
                break;
            default:
                break;
        }
    }
    
    return (
        <div className="order-container">
            { currentNav === 'onSale' ?
                onSaleCurNav === 'sale' ?
                    (saleData[0] ? <SaleItemContainer currentGame={ currentGame }
                                                      saleData={ saleData } set_onShelfData={ set_onShelfData }
                                                      onShelfData={ onShelfData }></SaleItemContainer>
                        : <antdUI.Empty></antdUI.Empty>)
                    : onSaleCurNav === 'ship' ?
                        <antdUI.Table
                            pagination={ false }
                            dataSource={ dataSource }
                            // locale={{ emptyText: '暂无数据' }}
                            columns={ columns }
                        >
                        </antdUI.Table>
                        : ''
                : <antdUI.Table
                    pagination={ false }
                    dataSource={ dataSource }
                    // locale={{ emptyText: '暂无数据' }}
                    columns={ columns }
                >
                </antdUI.Table>
            }
            
            { orderDetailView && <OrderDetail orderDetailView={ orderDetailView }
                                              setOrderDetailView={ setOrderDetailView }
                                              orderDetailData={ orderDetailData } allPrice={ allPrice }/> }
        </div>
    )
}

let tabBox = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        color: '#000'
    },
    tabH = {
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgb(240, 147, 41)',
    },
    orderNumber = {
        color: '#000',
        textAlign: 'center'
    },
    favoritesItemStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        transform: 'scale(1.3)',
        backgroundImage: `url(${ require('@/images/items/item_bg.png') })`
    },
    tabImg = {
        width: '65px'
    };
let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
let onSaleColumns = [
    {
        title: <p style={ titleStyle }>订单号(验证码)</p>,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: 'itemInfo',
        key: 'itemInfo',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>销售价格</p>,
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>买家昵称</p>,
        dataIndex: 'buyer',
        key: 'buyer',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>购买时间</p>,
        dataIndex: 'duetime',
        key: 'duetime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易状态</p>,
        dataIndex: 'orderItemsStatus',
        key: 'orderItemsStatus',
        render: text => text
    }
];
let intColumns = [
    {
        title: <p style={ titleStyle }>订单号</p>,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>数量</p>,
        dataIndex: 'itemTotal',
        key: 'itemTotal',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易价格</p>,
        dataIndex: 'price',
        key: 'price',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>买家昵称</p>,
        dataIndex: 'buyer',
        key: 'buyer',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>手续费</p>,
        dataIndex: 'handlingFee',
        key: 'handlingFee',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>创建时间</p>,
        dataIndex: 'createTime',
        key: 'createTime',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>交易状态</p>,
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        render: text => text
    },
    // {
    //   title: <p style={titleStyle}>商品交易链接</p>,
    //   dataIndex: 'orderLink',
    //   key: 'orderLink',
    //   render: text => text
    // },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        render: text => text
    }
];


export default OrderContainer;
