import React, { antdUI, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { upDateUserAssets } from '@/store/actions/userInfo'

let balanceData = [
    { title: '余额', icon: require("@/images/profile/balance.png"), type: 'alipay_amount' },
    // { title: '其他余额', icon: require("@/images/profile/qitazhifuyue@2x.png"), type: 'other_amount' },
    { title: '求购锁定余额', icon: require("@/images/profile/qiugousuodingyue@2x.png"), type: 'buying_lock_amount' },
    { title: '持有MOE数', icon: require("@/images/profile/chiyoudaibishu@2x.png"), type: 'mobi_tokens' }
]

function Balance(props) {
    const { userAssets, upDateUserAssets } = props;
    const [ userBalance, setUserBalance ] = useState([])
    useEffect(() => {
        setUserBalance([
            { val: userAssets.alipay_amount || 0 },
            // { val: userAssets.other_amount || 0 },
            { val: userAssets.buying_lock_amount || 0 },
            { val: userAssets.mobi_tokens } ])
    }, [ userAssets ])
    
    useEffect(_ => {
        upDateUserAssets()
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="wallet-balance">
            <div className="title">
                <h1>我的钱包</h1>
            </div>
            <div className="balance-container">
                { userBalance.map((v, i) => {
                    return (
                        <div key={ i }
                            // style={ { borderLeft: i % 4 && '1px solid #E5E5E5' } }
                             className="balance-item">
                            <div className="balance-icon">
                                <img src={ balanceData[i].icon } alt=""/>
                            </div>
                            <div className="content">
                                <div className="balance-title flex ait">{ balanceData[i].title }
                                    { i === 0 && <>&nbsp;<h2 style={ { width: "180px" } }
                                                             className="balance-val">￥{ v.val }</h2></> }
                                </div>
                                <antdUI.Popconfirm
                                    title={ `￥${ v.val }` }
                                    okText=" "
                                    cancelText=" "
                                    okButtonProps={ { type: "text" } }
                                    cancelButtonProps={ { type: "text" } }>
                                    { i !== 0 && <h2 style={ { width: "140px" } }
                                                     className="balance-val">{ i !== 2 && "￥" }{ v.val }</h2> }
                                    { i === 0 && <div>
                    <span>可提现:<span
                        style={ { color: "#2DAF61" } }>&nbsp;¥{ Number(userAssets.withdrawalAmount) > userAssets.alipay_amount ?
                        userAssets.alipay_amount : userAssets.withdrawalAmount } </span></span> &nbsp;&nbsp;
                                        <span>不可提现:<span style={ { color: "#FF5745" } }>&nbsp;¥{
                                            userAssets.alipay_amount - userAssets.withdrawalAmount < 0 ? 0 :
                                                (Math.round((userAssets.alipay_amount - userAssets.withdrawalAmount) * 100) / 100)
                                        }</span></span>
                                    </div> }
                                </antdUI.Popconfirm>
                            
                            </div>
                            { i !== 2 && <div className="line"></div> }
                        </div>
                    )
                }) }
            </div>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(Balance);
