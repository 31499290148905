import React, { useEffect, $api, useState, antdUI, $tools, useRef } from 'react';
import './index.scss'
import CsgoBox from './csgoBox.js'
import { CsgoFilter } from './itemFilter.js';

function BackpackContainer({ game: { currentGame } }) {
    const { checkResult, debounce } = $tools
    const [ total, setTotal ] = useState(0)
    const [ backpackData, setBackPackData ] = useState([])
    const [ curPage, setCurPage ] = useState(1)
    const [ itemType, setItemType ] = useState([])
    const [ quality, setQuality ] = useState([])
    const [ exterior, setExterior ] = useState([])
    const [ rarity, setRarity ] = useState([])
    const [ itemSet, setItemSet ] = useState([])
    const [ searchContent, setSearchContent ] = useState('')
    
    const pageSize = 20
    const getData = async (searchContent) => {
        setBackPackData([])
        const data = {}
        if (itemType[0]) {
            itemType[0] && (data.type = itemType[0])
            itemType[1] && (data.weapon = itemType[1])
        }
        if (rarity[0] && rarity[0] !== 'unlimited') {
            rarity[0] && (data.rarity = rarity[0])
            rarity[1] && (data.rarity = rarity[1])
        }
        if (exterior[0] && exterior[0] !== 'unlimited') data.exterior = exterior[0]
        if (quality[0] && quality[0] !== 'unlimited') data.quality = quality[0]
        if (itemSet[0] && itemSet[0] !== 'unlimited') itemSet[1] && (data.itemSet = itemSet[1])
        searchContent && (data.searchContent = searchContent)
        const result = await $api.backpack.getBackpackData({
            appid: currentGame, curPage, pageSize, ...data
        })
        if (checkResult(result)) {
            setBackPackData(result.data.data)
            setTotal(result.data.total)
        }
    }
    const changePage = async (currentPage) => {
        setCurPage(currentPage)
        await getData()
    }
    useEffect(_ => {
        (async _ => { await getData() })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame, itemType, quality, exterior, rarity, itemSet ])
    const delaySearchChange = useRef(debounce(getData, 1500, true)).current
    return (
        <div className="my-backpack-container">
            { [ 570, 730 ].indexOf(Number(currentGame)) !== -1 &&
            <div className="backpack-container-head" style={ { padding: currentGame === '570' && '16px 0' } }>
                <div className="item-filter">
                    {
                        currentGame === '730' ?
                            <CsgoFilter fv={ { itemType, quality, exterior, rarity, itemSet } }
                                        setFv={ { setItemType, setQuality, setExterior, setRarity, setItemSet } }/> : ''
                    }
                    <div className="filter-search"
                         style={ { margin: '10px 0', bottom: currentGame === '570' && '0px' } }>
                        <antdUI.Input
                            onKeyDown={ _ => _.keyCode === 13 && delaySearchChange(searchContent.toUpperCase()) }
                            value={ searchContent }
                            onChange={ e => setSearchContent(e.target.value) }
                            placeholder={ "请输入物品名称" }/>
                        <div onClick={ () => delaySearchChange(searchContent.toUpperCase(), currentGame) }
                             className="search-btn">搜索
                        </div>
                    </div>
                </div>
            </div> }
            
            <div className="item-container">
                { backpackData.map((item, key) => <CsgoBox key={ key } itemData={ item }/>) }
            </div>
            
            { total > 0 && <div className="backpack-pagination">
                <antdUI.Pagination
                    onChange={ (currentPage, pageSize) => changePage(currentPage, pageSize) }
                    showSizeChanger={ false } current={ curPage } defaultCurrent={ 1 }
                    total={ total } pageSize={ pageSize }/>
            </div> }
        </div>
    )
}

export default BackpackContainer
