import React, { $api, $tools, useEffect, useState } from 'react';
import './introduction.scss'
import { Link } from "react-router-dom";

const contentBoxData = [
    {
        icon: require('@/images/home/zhuce@2x.png'),
        title: '注册',
        text: '完成可参与ROLL房',
        hovIcon: require('@/images/home/zhuce @2x.png'),
        hovText: [ "完成MOBI平台注册", "可无条件参与一次ROLL房" ],
        btn: { link: "/profile/userConfig", text: "注册" }
    },
    {
        icon: require('@/images/home/renzheng@2x.png'),
        title: '认证',
        text: "完善相关资料",
        hovIcon: require('@/images/home/renzheng @2x.png'),
        hovText: [ "完成相关资料绑定", "用于购买出售提现" ],
        btn: { link: "/profile/userConfig", text: "绑定" }
    },
    {
        icon: require('@/images/home/huodong@2x.png'),
        title: '活动',
        text: "百万奖励等你拿",
        hovIcon: require('@/images/home/huodong @2x.png'),
        hovText: [ "MOBI春节前有三个超级活动", "总奖励达￥1,000,000" ],
        btn: { link: "/rollRoom", text: "参与" }
    },
    {
        icon: require('@/images/home/shangjia@2x.png'),
        title: '上架',
        text: "交易费率低至0.75%",
        hovIcon: require('@/images/home/shangjia @2x.png'),
        hovText: [ "为了享受低费率并尽快售出", "你的饰品，在定价上可以参考", "友商平台的底价，感谢支持，", "交易前记得阅读" ],
        btn: { link: "/help", text: "防骗指南" }
    },
    {
        icon: require('@/images/home/tixian@2x.png'),
        title: '提现',
        text: "超低费率",
        hovIcon: require('@/images/home/tixian @2x.png'),
        hovText: [ "早鸟期，元旦，春节期间，", "提现手续费低至0%" ],
        btn: { link: "/profile/wallet", text: "前往提现" }
    }
]
const multiLogo = [
    { src: require('@/images/home/pc@2x.png') },
    { src: require('@/images/home/android@2x.png') },
    { src: require('@/images/home/ios@2x.png') }
];

//
function Introduction() {
    const [ count, setCount ] = useState(0);
    const [ sucAmount, setSucAmount ] = useState(0);
    const [ putTotal, setPutTotal ] = useState(0);
    const getSignUpCount = async () => {
        const result = await $api.siteSetting.getSignUpCount()
        if ($tools.checkResult(result)) {
            let { signUpCount, csgoPutTotal, csgoSucAmount, dota2PutTotal, dota2SucAmount } = result?.data || {}
            setCount(signUpCount || 0)
            csgoSucAmount = isNaN(Number(csgoSucAmount)) ? 0 : Number(csgoSucAmount)
            dota2SucAmount = isNaN(Number(dota2SucAmount)) ? 0 : Number(dota2SucAmount)
            setSucAmount(Math.round((csgoSucAmount * 100) + (dota2SucAmount * 100)) / 100)
            setPutTotal((csgoPutTotal || 0) + (dota2PutTotal || 0))
        }
    }
    
    useEffect(_ => {
        getSignUpCount().then()
    }, [])
    return (
        <div className="home-introduction">
            <div className="content">
                <h2 className="title">新手引导</h2>
                <div className="content-box">
                    {
                        contentBoxData.map((item, key) => {
                            return (
                                <div key={ key } className="content-box-item-box">
                                    <div className="content-box-item"
                                         style={ { borderLeft: key !== 0 ? 'none' : "1px solid #ECECEC" } }>
                                        <div className="img-icon-box">
                                            <img className="icon" src={ item.icon } alt=""/>
                                        </div>
                                        <h2>{ item.title }</h2>
                                        <p>{ item.text }</p>
                                    </div>
                                    
                                    <div className="content-box-item-hover">
                                        <div className="img-icon-box flex ait jtyct">
                                            <img className="icon" src={ item.hovIcon } alt=""/>
                                            <h2>{ item.title }</h2>
                                        </div>
                                        <div className="text-list-box"
                                             style={ { paddingTop: key !== 3 ? "18px" : "" } }>
                                            {
                                                Object.prototype.toString.call(item.hovText) === '[object Array]' ?
                                                    item.hovText.map((_, i) => {
                                                        return <p key={ i }>{ _ }</p>
                                                    }) : <p>{ item.text }</p>
                                            }
                                        </div>
                                        { item.title === "提现" ?
                                            <button className="btn"
                                                    onClick={ () => {
                                                        window.localStorage.setItem("PurseNavType", "withdraw");
                                                        window.location.href = item.btn.link
                                                    } }
                                                    style={ { marginTop: key !== 3 ? key === 4 ? "14px" : "26px" : "" } }>
                                                { item.btn.text }
                                            </button> : <Link to={ {
                                                pathname: item.btn.link,
                                                search: item.title === "上架" && "#fraud_prevention"
                                            } }>
                                                <button className="btn"
                                                        style={ { marginTop: key !== 3 ? key === 4 ? "14px" : "26px" : "" } }>
                                                    { item.btn.text }
                                                </button>
                                            </Link> }
                                    
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <h2 className="title">热门活动</h2>
                <div className="content-img">
                    <Link to="/rollRoom">
                        <img style={ { cursor: "pointer" } }
                             src={ require('@/images/home/huodong2@2x.png') } alt=""/></Link>
                    <Link to="/rollRoom">
                        <img style={ { cursor: "pointer" } }
                             src={ require('@/images/home/huodong1@2x.png') } alt=""/></Link>
                
                </div>
            </div>
            
            <div className="introduction">
                <div className="multi-end">
                    <p> 随时随地进行交易</p>
                    <p>覆盖WEB端、Android、Ios多个平台</p>
                    
                    <div className="logo">
                        { multiLogo.map((v, i) => <img src={ v.src } key={ i } alt=""/>) }
                    </div>
                </div>
                
                <div className="introduction-right">
                    <div className="t-1">
                        <p>MOBI平台，上线至今已有 <span>{ count }</span> 用户加入使用</p>
                        {
                            false && <> <p>目前在架饰品总数量 <span>{ putTotal }</span> 件</p>
                                <p>累计成交金额 <span>{ sucAmount }</span> 元</p></>
                        }
                    </div>
                    <div className="t-2">
                        <p>在这，玩家可自由分享，还可通过交易推广赚到钱，同时在早鸟期，春节和元旦，交易手续费低至0.75%，免提现费，一来一回手续费优惠接近3%，都是底价可多赚近30元</p>
                    </div>
                    
                    <div className="t-3">
                        <p> 推荐指数:</p>
                        <img style={ { marginRight: "6px" } } src={ require('@/images/home/tuijianzhishu@2x.png') }
                             alt=""/>
                        <img style={ { marginRight: "6px" } } src={ require('@/images/home/tuijianzhishu@2x.png') }
                             alt=""/>
                        <img style={ { marginRight: "6px" } } src={ require('@/images/home/tuijianzhishu@2x.png') }
                             alt=""/>
                        <img style={ { marginRight: "6px" } } src={ require('@/images/home/tuijianzhishu@2x.png') }
                             alt=""/>
                        <img src={ require('@/images/home/tuijianzhishu@2x.png') } alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;
